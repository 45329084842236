import React, { useState } from "react";

const PopupContext = React.createContext();

export const PopupProvider = ({ children }) => {
    // ONBOARDING COMPLETED POPUPS
    const [showStorePOSConfirmConnect, setShowStorePOSConfirmConnect] = useState(false);
    const [showStoreAndPOSOnboardingCompleted, setShowStoreAndPOSOnboardingCompleted] = useState(false);
    const [showStoreWithoutPOSOnboardingCompleted, setShowStoreWithoutPOSOnboardingCompleted] = useState(false);

    // TODO: OTHER PAGES POPUPS ADD COMMENT HERE

    return (
        <PopupContext.Provider
            value={{
                showStorePOSConfirmConnect,
                setShowStorePOSConfirmConnect,
                showStoreAndPOSOnboardingCompleted,
                setShowStoreAndPOSOnboardingCompleted,
                showStoreWithoutPOSOnboardingCompleted,
                setShowStoreWithoutPOSOnboardingCompleted
            }}>
            {children}
        </PopupContext.Provider>
    );
};

export default PopupContext;
