/**
 *
 * @param {String} text - The text to be displayed.
 * @param {Object} colors - The colors object containing the color classes and their corresponding values.
 * @param {String} notFoundDefault - The default color class to be used.
 * @param {String} textEmptyDefault - The default text to be displayed if the text is empty.
 * @returns {JSX.Element} The span element with the color class.
 *
 * @example
 * const colors = [
 *    {
 *      colorClass: "text-green-500",
 *      forValues: ["Active", "Available"]
 *   },
 *   {
 *     colorClass: "text-red-500",
 *     forValues: ["Inactive", "Unavailable"]
 *   }];
 *
 * const span = getSpanWithColor("Active", colors, notFoundDefault);
 *
 */
export const getSpanWithColor = (
    text,
    colors = defaultStatusColors,
    notFoundDefault = "text-gray-200",
    textEmptyDefault = "--"
) => {
    try {
        if (typeof text === "boolean") {
            text = text ? "Yes" : "No";
        }
        if (!text || text === "" || text === undefined || text === null) {
            return <span className={notFoundDefault}>{textEmptyDefault}</span>;
        }

        let colorValue = notFoundDefault;
        let found = false;
        for (const color of colors) {
            for (const value of color.forValues) {
                if (value?.toLowerCase() === text?.toLowerCase()) {
                    found = true;
                    colorValue = color.colorClass;
                    break;
                }
            }
            if (found) break;
        }

        return <span className={colorValue}>{text}</span>;
    } catch (error) {
        console.error("getSpanWithColor", text);
        return <span className={notFoundDefault}>{textEmptyDefault}</span>;
    }
};

export const getOpenHoursSpan = text => {
    if (text === "" || text === undefined || text === null || typeof text !== "boolean") {
        return <span className="text-gray-100">--</span>;
    }

    let colorValue = "text-orange-500";
    if (text === true) {
        text = "Open";
        colorValue = "text-green-500";
    } else {
        text = "Closed";
    }

    return <span className={colorValue}>{text}</span>;
};

export const defaultStatusColors = [
    {
        colorClass: "text-blue-600",
        forValues: ["Active", "Fulfilling", "Connected", "Affiliated", "Accepted"]
    },
    {
        colorClass: "text-green-500",
        forValues: [
            "Available",
            "Exported to POS",
            "Exported",
            "Invited",
            "Invite-Resent",
            "Completed",
            //"Requested",
            "Yes",
            "Enrolling",
            "Enrolled",
            "Pre-Enrolled",
            "Activating",
            "Connecting"
        ]
    },
    {
        colorClass: "text-orange-500",
        forValues: [
            "Suspended",
            "Pending",
            "N/A",
            "Request",
            "Requested",
            "Not affiliated",
            "Suspending",
            "Removing",
            "Not fulfilling",
            "Not active"
        ]
    },
    {
        colorClass: "text-red-500",
        forValues: ["Inactive", "Unavailable", "Not connected", "Failed", "Cancelled", "Declined", "No", "Voided"]
    },
    {
        colorClass: "text-gray-300",
        forValues: ["--"]
    }
];

export const accountStatusColors = [
    {
        colorClass: "text-blue-500",
        forValues: ["Active"]
    },
    {
        colorClass: "text-green-500",
        forValues: ["Enrolled", "Pre-Enrolled", "Enrolling", "Activating"]
    }
];

export const getStatusValue = (row, statusFromPath) => {
    const fromPath = statusFromPath ?? "status";
    const status = getArrayFieldValue(row, fromPath.split(".") || "Unknown");
    //console.log("status", status);
    return status;
};

const getArrayFieldValue = (row, array) => {
    if (!Array.isArray(array)) return "Unknown";

    let res = row;
    for (let i = 0; i < array.length; i++) {
        res = res?.[array[i]];
    }
    return res;
};
