import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import InfinityGridCompanyCards from "../../common/cardContainers/InfinityGridCompanyCards";
import "./../../../assets/css/components/CheckboxDark.css";
import { useNavigate, useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { AppQueryClient } from "../../../api/queryClient";
import { defaultStatusColors } from "../../../utils/CardUtils";
import { formatDefaultDate, formatDefaultDateTime } from "../../../utils/Formatter";
import { hasUserCompletedOnboarding } from "../../../utils/navigationHelper";
import MainWindowHeader from "../../common/mainApp/mainWindowHeaders/MainWindowHeader";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    &.page-no-scroll {
        overflow-y: hidden;
        height: 100%;
    }
`;
/* #endregion */

const infiniteGridQuerykey = "CompaniesGrid";
const listQueryKey = "CompaniesList";

export default function CompaniesPage() {
    document.title = `${APP_TITLE} - Companies`;

    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);
    const navigate = useNavigate();

    const [listNumRows, setListNumRows] = useState(20);

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData,
        navigations: navigations
    };

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    //const [searchValue, setSearchValue] = useState(""); // TODO: search is not yet implemented
    /* #endregion */

    /* #region Methods */
    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };
    const onSearch = searchValue => {
        //TODO: Implement search
        //setSearchValue(searchValue);
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!props?.layoutType) return;
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props, onClickRefreshAction, onSearch, hasUserCompletedOnboarding);
    }, [props?.layoutType]);
    /* #endregion */

    const listColumns = [
        {
            name: "Company",
            selector: row => row?.name ?? "--"
        },
        {
            name: "Created by",
            selector: row => row?.createdBy ?? "--"
        },
        {
            name: "Created on",
            selector: row => formatDefaultDateTime(row?.createdOn) ?? "--"
        },
        {
            name: "Registration nr.",
            selector: row => row?.registrationNumber ?? "--"
        },
        {
            name: "Registration date",
            selector: row => formatDefaultDate(row?.registrationDate) ?? "--"
        },
        {
            name: "Tax Nr.",
            selector: row => row?.taxNr ?? "--"
        },
        {
            name: "Brands",
            selector: row => row?.nrOfBrands ?? "--"
        },
        {
            name: "Stores",
            selector: row => row?.nrOfStores ?? "--"
        }
    ];

    return (
        <StyledPage className={viewLayoutStyle === "Grid" ? "page-no-scroll" : ""}>
            {viewLayoutStyle === "Grid" && (
                <InfinityGridCompanyCards minColumnWidth="370px" queryKey={infiniteGridQuerykey} />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="Companies"
                    columns={listColumns}
                    api={{
                        useCommonPostRequest: true,
                        commonContext: "Company",
                        endpoint: "getCompaniesData",
                        formData: {
                            organizationID: localStorage.getItem("currentOrganizationID"),
                            limit: listNumRows
                        }
                    }}
                    pageLimit={listNumRows}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        includeActions: true,
                        onClickEdit: row => {
                            navigate(`/companies/edit/${row?._id}`);
                        },
                        extraActions: [
                            {
                                title: "Details"
                            },
                            {
                                title: "Add Users"
                                /* onClick: () => {
                                    navigate("/companies/users");
                                } */
                            }
                        ]
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    rightCardTopComponent={<RightComponent />}
                    onChangeRowsPerPage={rowsPerPage => setListNumRows(rowsPerPage)}
                />
            )}
        </StyledPage>
    );
}
/* region Small components */
const RightComponent = () => {
    return (
        <div
            style={{
                display: "Flex",
                gap: "16px",
                fontSize: "10px",
                textTransform: "none",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <div>Total FFcenters to Replay: --</div>
            <div>Last update: --</div>
        </div>
    );
};
/* endregion */

function setupHeaders(props, onRefreshAction, onSearch, hasUserCompletedOnboarding) {
    const createMode = props?.navigations?.navigationAccesses?.companies?.createModeOnly;

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Company", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/companies", active: true, disabled: false, title: "Companies" },
        { to: "/companies/kyc-policy", active: false, disabled: true, title: "KYC POLICY" },
        {
            to: "/companies/users",
            active: false,
            disabled: true,
            title: "Users"
        }
    ]);

    if (createMode) {
        props.setControlBarRightBtns([
            { to: "#", active: true, disabled: false, title: "View", icon: "eye" },
            { to: "/companies/create", active: false, disabled: false, title: "Add", icon: "plus" },
            { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
        ]);
    } else {
        props.setControlBarRightBtns([
            { to: "#", active: true, disabled: false, title: "View", icon: "eye" },
            { to: "#", active: false, disabled: true, title: "Add", icon: "plus" },
            { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
        ]);
    }

    props.setFilterHeaderProperties({
        customContentHeader: (
            <MainWindowHeader
                key={window.location.pathname}
                visible={true}
                onRefreshClick={onRefreshAction}
                layoutTypeOptions={{
                    gridEnabled: true,
                    listEnabled: true
                }}
                firstOrganize={{
                    visible: true,
                    disabled: true
                }}
                secondOrganize={{
                    visible: true,
                    disabled: true
                }}
                search={{
                    searchPlaceholder: "Search"
                    //onSearch: onSearch
                }}
            />
        )
    });
}
