import React, { useEffect, useState } from "react";
import InfinityGridRetailersInvitationsCards from "../../common/cardContainers/InfinityGridRetailersInvitationsCards";
import { APP_TITLE } from "../../../config/constant";
import { useOutletContext } from "react-router";
import ListComponent from "../../common/lists/ListComponent";
import { useSelector } from "react-redux";
import * as Library from "../../../utils/Library";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import { AppQueryClient } from "../../../api/queryClient";
import { maskEmail, maskName } from "../../../utils/valuesMasking";
import { defaultStatusColors } from "../../../utils/CardUtils";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import MainWindowHeader from "../../common/mainApp/mainWindowHeaders/MainWindowHeader";

export default function RetailersInvitationsPage() {
    document.title = `${APP_TITLE} - Retailers Invitations`;

    // #region VARS
    const [isLoading, setIsLoading] = useState(false);
    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);

    const [listNumRows, setListNumRows] = useState(20);

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState({
        show: false,
        inviteID: "",
        brandName: "",
        wasAccepted: false
    });

    const [showVoidConfirmationDialog, setShowVoidConfirmationDialog] = useState({
        show: false,
        inviteID: "",
        brandName: "",
        wasAccepted: false
    });

    const listColumns = [
        {
            name: "Retail Brand",
            selector: row => row?.retailBrand?.name || "--"
        },
        {
            name: "To Brand",
            selector: row => row?.labelBrand?.name || "--"
        },
        {
            name: "Invited on",
            selector: row => formatDefaultDateTime(row?.createdAt) || "--"
        },
        {
            name: "Accepted on",
            selector: row => formatDefaultDateTime(row?.acceptedOn) || "--"
        },
        {
            name: "Contact person",
            selector: row =>
                row?.status !== "Accepted" && row?.retailBrand?._id
                    ? maskName(row?.contactFirstName)
                    : row?.contactFirstName || "--"
        },
        {
            name: "Contact email",
            selector: row =>
                row?.status !== "Accepted" && row?.retailBrand?._id
                    ? maskEmail(row?.contactEmail)
                    : row?.contactEmail || "--"
        },
        {
            name: "Max Stores",
            selector: row => (row?.retailBrand?._id ? row?.maxStores : "--")
        },
        {
            name: "Retailer Active Stores",
            selector: row => (row?.retailBrand?._id ? row?.activeStores : "--")
        }
    ];

    //---------- Header Filters
    const [allConnectedRetailBrandsList, setAllConnectedRetailBrandsList] = useState([]);
    const [allRetailersList, setAllRetailersList] = useState([]);
    const [filteredRetailers, setFilteredRetailers] = useState([]);
    //-----
    const [allLabelBrandsList, setAllLabelBrandsList] = useState([]);
    const [filteredLabelBrand, setFilteredLabelBrand] = useState([]);
    //-----
    const [requestEnabled, setRequestsEnabled] = useState(false);

    const filters = {
        filterLabelBrandIDs: filteredLabelBrand?.map(item => item?.value),
        filterRetailBrandIDs: filteredRetailers?.map(item => item?.value)
    };

    const infiniteGridQuerykey = ["RetailersInvitationsGrid", filters];
    const listQueryKey = ["RetailersInvitationsList", filters];

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    // #endregion */

    // #region METHODS
    const onLabelBrandChange = selection => {
        setFilteredLabelBrand([selection]);

        const currentLabelBrandID = selection?.value;
        filterConnectedRetailers(allConnectedRetailBrandsList, currentLabelBrandID);
    };

    const onRetailersChange = (selections, dropdownDataWithSelections) => {
        setFilteredRetailers(selections);
        setAllRetailersList(dropdownDataWithSelections);
    };

    const onFiltersLoaded = data => {
        let currentLabelBrandID = null;
        if (data?.labelBrands?.length) {
            const labelBrands = data?.labelBrands?.map((item, index) => {
                return {
                    name: item?.name,
                    value: item?._id,
                    selected: index === 0
                };
            });

            setAllLabelBrandsList(labelBrands);
            setFilteredLabelBrand([labelBrands[0]]);
            currentLabelBrandID = labelBrands[0]?.value;
        }

        if (data?.connectedRetailBrands?.length) {
            const connectedRetailBrands = data?.connectedRetailBrands?.map(item => {
                return {
                    name: item?.name,
                    value: item?._id,
                    selected: true,
                    labelBrandID: item?.labelBrandID
                };
            });

            setAllConnectedRetailBrandsList(connectedRetailBrands);
            setFilteredRetailers(connectedRetailBrands?.filter(item => item?.selected));

            filterConnectedRetailers(connectedRetailBrands, currentLabelBrandID);
        }

        setRequestsEnabled(true);
    };

    const filterConnectedRetailers = async (allRetailBrands, currentLabelBrandID) => {
        let res = allRetailBrands?.filter(item => {
            return item?.labelBrandID === currentLabelBrandID;
        });

        // filter out any duplicated retailers
        res = res?.filter((item, index) => {
            return res?.findIndex(item2 => item2?.value === item?.value) === index;
        });

        setAllRetailersList(res);
        setFilteredRetailers([]);
    };

    const handleResendInvitation = async inviteID => {
        const res = await Library.makePostRequest(
            "resendInviteToRetailer",
            {
                inviteID: inviteID
            },
            false,
            setIsLoading
        );

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                type: "success",
                title: "Resend Invitation",
                message: "Invitation has been resent successfully."
            });

            onClickRefreshAction();
        }
    };

    const handleVoidInvitation = async (inviteID, brandName, wasAccepted) => {
        setShowVoidConfirmationDialog({
            show: true,
            inviteID: inviteID,
            brandName: brandName,
            wasAccepted: wasAccepted
        });
    };

    const handleVoidInvitationRequest = async inviteID => {
        const res = await Library.makeCommonPostRequest(
            "Invites",
            "updateInvite",
            {
                inviteID: inviteID,
                BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
                status: "Voided"
            },
            false,
            setIsLoading
        );

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                type: "success",
                title: "Invitation voided!",
                message: "This invitation is no longer valid."
            });

            onClickRefreshAction();
        }
    };

    const handleDeleteInvitation = async (inviteID, brandName, wasAccepted) => {
        setShowDeleteConfirmationDialog({
            show: true,
            inviteID: inviteID,
            brandName: brandName,
            wasAccepted: wasAccepted
        });
    };

    const handleDeleteInvitationRequest = async inviteID => {
        const res = await Library.makeCommonPostRequest(
            "Invites",
            "hideInvite",
            {
                inviteID: inviteID,
                BOstagesAccountID: localStorage.getItem("BOstagesAccountID")
            },
            false,
            setIsLoading
        );

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                type: "success",
                title: "Invitation deleted!",
                message: "Invitation deleted successfully!"
            });

            onClickRefreshAction();
        }
    };

    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };
    // #endregion METHODS

    // #region EFFECTS
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(
            props,
            onClickRefreshAction,
            navigations,
            //----
            allRetailersList,
            allLabelBrandsList,
            onLabelBrandChange,
            onRetailersChange,
            onFiltersLoaded,
            requestEnabled,
            filteredLabelBrand,
            filteredRetailers
        );
    }, [
        props?.layoutType,
        navigations,
        allRetailersList,
        allLabelBrandsList,
        filteredLabelBrand,
        filteredRetailers,
        requestEnabled
    ]);
    // #endregion EFFECTS

    // #region RENDER
    return (
        <>
            <FullScreenLoader isLoading={isLoading} />

            <FullScreenOverlay show={showDeleteConfirmationDialog.show}>
                <DialogBoxComponent
                    heading="ATTENTION"
                    headingColor="var(--text-icons-red-500)"
                    title="Ready to remove?"
                    message={
                        showDeleteConfirmationDialog?.wasAccepted
                            ? `Do you want to remove ${showDeleteConfirmationDialog?.brandName} invitation card? This will not remove the connection with the retailer. You can find this retailer in the retailers menu, selecting CONNECTIONS in the control bar.`
                            : `Do you want to remove ${showDeleteConfirmationDialog?.brandName} invitation card?`
                    }
                    onClickYes={() => {
                        handleDeleteInvitationRequest(showDeleteConfirmationDialog?.inviteID);
                        setShowDeleteConfirmationDialog({ show: false, inviteID: "", brandName: "" });
                    }}
                    loading={isLoading}
                    loadingHeading={"Removing..."}
                    onClickNo={() => {
                        setShowDeleteConfirmationDialog({ show: false, inviteID: "", brandName: "" });
                    }}
                    cancelText="No"
                    confirmText="Yes"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showVoidConfirmationDialog.show}>
                <DialogBoxComponent
                    heading="JUST CHECKING"
                    headingColor="var(--text-icons-red-500)"
                    title="Ready to void invitation?"
                    message="Do you want the invitation to be canceled? The retailer will receive a 'This invitation is no longer valid' notification."
                    onClickYes={() => {
                        handleVoidInvitationRequest(showVoidConfirmationDialog?.inviteID);
                        setShowVoidConfirmationDialog({ show: false, inviteID: "", brandName: "" });
                    }}
                    loading={isLoading}
                    loadingHeading={"Voiding..."}
                    onClickNo={() => {
                        setShowVoidConfirmationDialog({ show: false, inviteID: "", brandName: "" });
                    }}
                    cancelText="No"
                    confirmText="Yes"
                />
            </FullScreenOverlay>

            {viewLayoutStyle === "Grid" && (
                <InfinityGridRetailersInvitationsCards
                    minColumnWidth="370px"
                    limit={10}
                    queryKey={infiniteGridQuerykey}
                    // statusFromPath="status"
                    onResendInvitation={handleResendInvitation}
                    onDeleteInvitation={handleDeleteInvitation}
                    onVoidInvitation={handleVoidInvitation}
                    requestEnabled={requestEnabled}
                    filters={filters}
                />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="RETAILERS"
                    //title2="(LAST 10 INVITED)"
                    columns={listColumns}
                    api={{
                        endpoint: "getInvitedRetailers",
                        useCommonPostRequest: false,
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
                            limit: listNumRows,
                            ...filters
                        }
                    }}
                    pageLimit={listNumRows}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        includeActions: true,
                        extraActions: [
                            {
                                title: "Void",
                                onClick: row => {
                                    if (row?.status === "Invited" || row?.status === "Invited-Resent")
                                        handleVoidInvitation(
                                            row?._id,
                                            row?.retailBrand?.name,
                                            row?.status === "Accepted"
                                        );
                                    return undefined;
                                },
                                isDisabled: row => {
                                    return row?.status !== "Invited" && row?.status !== "Invited-Resent";
                                }
                            },
                            {
                                title: "Resend",
                                onClick: row => {
                                    if (row?.status === "Invited" || row?.status === "Invited-Resent")
                                        handleResendInvitation(row?._id);
                                    return undefined;
                                },
                                isDisabled: row => {
                                    return row?.status !== "Invited" && row?.status !== "Invited-Resent";
                                }
                            }
                        ],
                        editIsVisible: false,
                        suspendIsVisible: false,
                        onClickRemove: row => {
                            handleDeleteInvitation(row?._id, row?.retailBrand?.name, row?.status === "Accepted");
                        },
                        removeIsDisabled: row => {
                            return ["Accepted", "Declined", "Voided"].includes(row?.status);
                        }
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    onChangeRowsPerPage={rowsPerPage => setListNumRows(rowsPerPage)}
                />
            )}
        </>
    );
    // #endregion RENDER
}

// #region FUNCTIONS
function setupHeaders(
    props,
    onClickRefreshAction,
    navigations,
    allRetailersList,
    allLabelBrandsList,
    onLabelBrandChange,
    onRetailersChange,
    onFiltersLoaded,
    requestEnabled,
    filteredLabelBrand,
    filteredRetailers
) {
    const createMode = navigations?.navigationAccesses?.retailers?.createModeOnly;

    props.setControlBarLeftBtns([
        //{ to: "#", disabled: true, title: "Retailers", icon: "fa-magnifying-glass", isSearchToogle: false }
    ]);
    props.setControlBarCenterBtns([
        { to: "/retailers/connections", active: false, disabled: false, title: "connections" },
        { to: "/retailers/invitations", active: true, disabled: false, title: "Invitations" },
        { to: "/retailers/requests", active: false, disabled: false, title: "Requests" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: createMode, title: "View", icon: "eye" },
        { to: "/retailers/invite", active: false, disabled: false, title: "Invite", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <MainWindowHeader
                key={window.location.pathname}
                visible={true}
                layoutTypeOptions={{
                    gridEnabled: true,
                    listEnabled: true
                }}
                onRefreshClick={onClickRefreshAction}
                firstOrganize={{
                    visible: true,
                    dropdownData: allLabelBrandsList,
                    onDropdownChange: onLabelBrandChange,
                    disabled: false,
                    type: "singleSelect",
                    selectedValue: filteredLabelBrand?.[0]?.name
                }}
                secondOrganize={{
                    visible: true,
                    dropdownData: allRetailersList,
                    onDropdownChange: onRetailersChange,
                    type: "multiSelect",

                    multidropdownOptions: {
                        useDoneButton: true,
                        useSelectAll: true,
                        selectAllText: "Select all retailers",
                        selectAllPlaceholderFunction: arrayItems => {
                            if (!arrayItems?.length) return "No retailers";

                            const nonLockedRetailers = allRetailersList?.filter(item => !item?.locked);
                            const areAllSelected = arrayItems?.length === nonLockedRetailers?.length;
                            if (areAllSelected) return `All retailers (${nonLockedRetailers?.length})`;

                            let firstTwoItems = arrayItems
                                ?.slice(0, 2)
                                ?.map(item => item?.name)
                                ?.join(", ");
                            if (arrayItems?.length > 2) {
                                firstTwoItems = firstTwoItems + ", and more";
                            }

                            return `Retailers (${arrayItems?.length}): ${firstTwoItems}`;
                        }
                    }
                }}
                pageFilters={{
                    useFilters: true,
                    filters: {
                        useLabelBrands: true,
                        useConnectedRetailBrands: true
                    },
                    onFiltersLoaded: onFiltersLoaded
                }}
            />
        )
    });
}
// #endregion FUNCTIONS
