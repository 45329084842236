import React, { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router";
import styled from "styled-components";
import { APP_TITLE } from "../../config/constant";

//Assets
import image404 from "../../assets/images/404.svg";

const StyledPage = styled.div`
    & {
        width: 100%;
        height: 100%;
        height: calc(100% - 24px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--backgrounds-lines-n-600, #10141b);
    }

    .page-404-container {
        /* background: var(--backgrounds-lines-n-600, #10141b); */
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 24px;
    }

    .btn-large {
        margin-top: 32px;
    }
    .title1 {
        font-size: 36px;
        font-weight: 400;
    }
    .title2 {
        font-size: 24px;
        font-weight: 400;
    }

    .reportBTN {
        cursor: pointer;
        color: #ff3636;
        font-size: 18px;
        font-weight: 400;
        text-decoration: underline;
        transition: color 0.2s;
    }
    .reportBTN:hover {
        color: #ff5a5a;
    }

    .bottonContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
`;

export default function Page404() {
    document.title = `${APP_TITLE} - 404 Not Found`;

    /* #region VARS */
    const navigate = useNavigate();
    const { MainLayoutProps, layoutType } = useOutletContext();

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    /* #endregion */

    /* #region METHODS */
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setupHeaders(props);
    }, [props?.layoutType]);

    /* #endregion */

    return (
        <StyledPage className="pageTemplateF">
            <div className="page-404-container">
                <img src={image404} alt="404" />
                <div className="bottonContainer">
                    <div className="title1">Oops! You’re lost.</div>
                    <div className="title2">The page you are looking for was not found</div>
                    <div className="reportBTN" onClick={() => navigate("/")}>
                        Go to homepage
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}

function setupHeaders(props) {
    props.setControlBarLeftBtns([]);
    props.setControlBarCenterBtns([{ to: "", active: true, disabled: false, title: "Not found", icon: "plus" }]);
    props.setControlBarRightBtns([]);
    props.setFilterHeaderProperties({
        visible: false
    });
}

/* #region SMALL COMPONENTS */

/* #endregion */
