import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import styled from "styled-components";
import { APP_TITLE } from "../../config/constant";
import AppContext from "../../AppContext";
import * as Library from "../../utils/Library";
import FullScreenOverlay from "../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../common/dialogs/DialogBoxComponent";
import BOStageLogo from "../../assets/images/footer-logologin.png";
import FormCard from "../common/forms/FormCard";
import FormContainer from "../common/forms/FormContainer";
import BtnLargeSolid from "../common/buttons/BtnLargeSolid";
import SimpleGridContainer from "../common/forms/SimpleGridContainer";
import CircularSpinner from "../common/loaders/CircularSpinner";

const StyledPage = styled.div`
    // this class has & because for styled components the parent needs it to reference itself
    &.page-template {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
    }
    .container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        background-color: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 8px;
        box-shadow: 0 0 8px 0 var(--shadow-n-600, #000000);
    }
`;

export default function PageTemplate() {
    document.title = `${APP_TITLE} - Page Template`;

    /* #region VARS */
    const navigate = useNavigate();
    const { MainLayoutProps, layoutType } = useOutletContext();
    const { isLoadingApp } = React.useContext(AppContext);
    const [viewLayoutStyle, setViewLayoutStyle] = useState("grid");

    const [isLoadingRequest, setIsLoadingRequest] = useState(false);
    const [showFirstTimePopup, setShowFirstTimePopup] = useState(false);

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    /* #endregion */

    /* #region METHODS */
    const onPageLoad = async () => {
        console.log("PageTemplate - onPageLoad");

        // REQUEST EXAMPLE
        /*
        const formData = {
            subscriptionPlanSetID: "123"
        };

        const res = await Library.makeCommonPostRequest(
            "Organization",
            "getSubscriptionPlanSet",
            formData,
            false,
            setIsLoadingRequest
        );

        if (res.data.status !== 200) return;
        const data = res.data?.data;
        console.log("data:", data);
        */
    };

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props);
    }, [props?.layoutType]);

    useEffect(() => {
        onPageLoad();
    }, []);
    /* #endregion */

    return (
        <StyledPage className="page-template">
            <FullScreenOverlay show={showFirstTimePopup}>
                <DialogBoxComponent
                    title="Thank you for<br /> pre-enrolling on BOtag platform"
                    message={`<div style='font-size: 14px; padding-top: 34px; margin: 0 -5px;'>
                        This is a page template!</div>`}
                    onClickYes={() => {
                        setShowFirstTimePopup(false);
                    }}
                    headingColor="var(--text-icons-green-500)"
                    confirmText="CONTINUE"
                    showCancel={false}
                    useImageHeader={{ src: BOStageLogo, width: "165px" }}
                />
            </FullScreenOverlay>

            <FormContainer title="Form Card Template" showDefaultRightLegends={true}>
                <SimpleGridContainer type="grid" columns={2} gap={16}>
                    <BtnLargeSolid
                        title="SHOW SUCCESS TOAST"
                        className="w-100"
                        onClick={() => {
                            Library.showToastMessage({
                                type: "success",
                                title: "Success",
                                description: "This is a success message!"
                            });
                        }}
                    />

                    <BtnLargeSolid
                        title="SHOW WARNING TOAST (SINGLE TEXT)"
                        className="w-100"
                        onClick={() => {
                            Library.showToastMessage({
                                type: "warning",
                                title: "This is a warning message!"
                            });
                        }}
                        normalColor="#ff9900"
                        activeColor="#d37e00"
                        hoverColor="#e48900"
                        textColor="#ffffff"
                    />

                    <BtnLargeSolid
                        title="SHOW ERROR TOAST"
                        className="w-100"
                        onClick={() => {
                            Library.showToastMessage({
                                type: "error",
                                title: "Error",
                                description: "This is an error message!"
                            });
                        }}
                        normalColor="#ff2a2a"
                        activeColor="#ff2a2a"
                        hoverColor="#df2828"
                        textColor="#ffffff"
                    />

                    <BtnLargeSolid
                        title="SHOW INFO TOAST"
                        className="w-100"
                        onClick={() => {
                            Library.showToastMessage({
                                type: "info",
                                title: "Info",
                                description: "This is an info message!"
                            });
                        }}
                        normalColor="#0060cc"
                        activeColor="#0155b3"
                        hoverColor="#0059bd"
                        textColor="#ffffff"
                    />

                    <BtnLargeSolid
                        title="SHOW DIALOG"
                        className="w-100"
                        onClick={() => {
                            setShowFirstTimePopup(true);
                        }}
                    />
                </SimpleGridContainer>
            </FormContainer>
        </StyledPage>
    );
}

// THIS IS A SETUP HEADERS EXAMPLE
function setupHeaders(props) {
    props.setControlBarLeftBtns([]);
    props.setControlBarCenterBtns([{ to: "/billing", active: true, disabled: false, title: "BILLING" }]);
    props.setControlBarRightBtns([
        { to: "/billing", active: true, disabled: false, title: "Plans", icon: "file-check-01" },
        { to: "/billing/invoices", active: false, disabled: true, title: "Invoices", icon: "file-check-02" },
        { to: "/billing/methods", active: false, disabled: true, title: "Methods", icon: "credit-card-check" }
    ]);

    props.setFilterHeaderProperties({
        visible: false,
        gridEnabled: true,
        listEnabled: true,
        onRefreshAction: undefined
    });
}

/* #region SMALL COMPONENTS */

/* #endregion */
