import React, { useEffect, useState } from "react";
import styled from "styled-components";

/**
 * @param {Object} props - The component props.
 * @param {string} props.className - Additional CSS class name for the component.
 * @param {string} props.style - Additional CSS style for the component.
 * @param {number} props.progress - The progress percentage. (0 to 100).
 * @param {string} props.color - The color of the loader.
 * @param {number} props.delay - The delay for the animation in milliseconds.
 * @returns {JSX.Element} The ContainerLoader component.
 * @example
 */

const CircularProgress = ({ progress, style, className, color = "#0B0", delay = 500 }) => {
    const [displayedProgress, setDisplayedProgress] = useState(0);
    const radius = 31;
    const stroke = 3;
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (displayedProgress / 100) * circumference;

    useEffect(() => {
        let start = null;
        const duration = delay; // Numbers animation duration in ms

        const animateProgress = timestamp => {
            if (!start) start = timestamp;
            const progressTime = timestamp - start;
            const progressValue =
                Math.min(progressTime / duration, 1) * (progress - displayedProgress) + displayedProgress;
            setDisplayedProgress(progressValue);

            if (progressTime < duration) {
                requestAnimationFrame(animateProgress);
            }
        };

        requestAnimationFrame(animateProgress);
    }, [progress]);

    return (
        <StyledLoader style={style} className={className} delay={delay}>
            <svg height={radius * 2} width={radius * 2}>
                {/* Background circle */}
                <circle
                    className="background-circle"
                    stroke="#262E3E"
                    fill="transparent"
                    strokeWidth={stroke}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                {/* Progress circle */}
                <circle
                    className="progress-circle"
                    stroke={color}
                    fill="transparent"
                    strokeWidth={stroke}
                    strokeDasharray={circumference + " " + circumference}
                    style={{ strokeDashoffset }}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
            </svg>
            <div className="progress-text">{`${displayedProgress.toFixed(0)}%`}</div>
        </StyledLoader>
    );
};

export default CircularProgress;

/* #region  SCOPED CSS */
const StyledLoader = styled.div`
    & {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    svg {
        transform: rotate(0deg);
    }

    .background-circle {
        transition: stroke-dashoffset ${({ delay }) => delay / 1000}s;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }

    .progress-circle {
        transition: stroke-dashoffset ${({ delay }) => delay / 1000}s;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        stroke-linecap: round;
    }

    .progress-text {
        position: absolute;
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
/* #endregion */
