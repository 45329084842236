import styled from "styled-components";

//Assets
import upload from "../../../assets/icons/upload-010.svg";
import { useState } from "react";

//Utils
import resizeImage from "../../../utils/resizeImage";

/**
 *TODO
 * @component
 * @param {Object} props - The component props.
 * @param {Function} [props.onSetImages] - The function to set the images.
 * @param {Function} [props.onNewImagesDrop] - The function to handle new images drop.
 * @param {boolean} [props.removeExtension=false] - Whether to remove the file extension from the returned filenames.
 * @param {Object} [props.style={}] - Additional inline styles to apply to the container.
 * @param {boolean} [props.getImageState=false] - Whether to show the state of the image.
 * @param {string} props.maxSize - (Optional) The size of the image to upload. | maxSize = "300kb"
 * @returns
 */

export default function DragDropImages({
    onSetImages,
    onNewImagesDrop,
    removeExtension = false,
    style,
    getImageState,
    maxSize = 1000 // 1000 default
}) {
    /* #region STATES */
    const [isDragging, setIsDragging] = useState(false);
    /* #endregion */

    /* #region METHODS */
    const handleDrop = event => {
        event.preventDefault();

        if (onNewImagesDrop) {
            handleDropBase64(event);
            return;
        }

        setIsDragging(false);
        const files = Array.from(event.dataTransfer.files);
        const imageFiles = files.filter(file => file.type.startsWith("image/"));

        if (imageFiles.length > 0) {
            const imageUrls = imageFiles.map(file => URL.createObjectURL(file));
            if (onSetImages) onSetImages(prevImages => [...prevImages, ...imageUrls]);
        }
    };

    const removeFilenameExt = fileName => {
        if (!fileName) return "";
        return fileName?.replace(/\.[^/.]+$/, "") ?? "";
    };

    //handle drop of base64 images also with filenames
    const handleDropBase64 = async event => {
        event.preventDefault();
        setIsDragging(false);
        const files = Array.from(event.dataTransfer.files);
        const imageFiles = files.filter(file => file.type.startsWith("image/"));

        if (imageFiles.length > 0) {
            const arrayOfImages = [];
            const seenImages = new Set();

            const resizePromises = imageFiles.map(file => {
                return new Promise(resolve => {
                    if (file.size > maxSize * 1024) {
                        resizeImage(file, maxSize, resizedBase64 => {
                            let state = "found";

                            if (!resizedBase64 || !file.type.startsWith("image/")) {
                                state = "notFound";
                            } else if (seenImages.has(resizedBase64)) {
                                state = "duplicated";
                            } else {
                                seenImages.add(resizedBase64);
                            }

                            arrayOfImages.push({
                                name: removeExtension ? removeFilenameExt(file.name) : file.name,
                                base64: resizedBase64,
                                ...(getImageState && { state: state })
                            });

                            resolve();
                        });
                    } else {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);

                        reader.onload = () => {
                            let state = "found";

                            if (!reader.result || !file.type.startsWith("image/")) {
                                state = "notFound";
                            } else if (seenImages.has(reader.result)) {
                                state = "duplicated";
                            } else {
                                seenImages.add(reader.result);
                            }

                            arrayOfImages.push({
                                name: removeExtension ? removeFilenameExt(file.name) : file.name,
                                base64: reader.result,
                                ...(getImageState && { state: state })
                            });

                            resolve();
                        };
                    }
                });
            });

            await Promise.all(resizePromises);

            if (onNewImagesDrop) onNewImagesDrop(arrayOfImages);
        }
    };

    const handleDragOver = event => {
        event.preventDefault();
        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            const file = event.dataTransfer.items[0].getAsFile();
            if (file && file.type.startsWith("image/")) {
                const img = new Image();
                img.src = URL.createObjectURL(file);
                img.onload = () => {
                    event.dataTransfer.setDragImage(img, img.width / 2, img.height / 2);
                };
            }
        }
    };

    const handleDragEnter = () => {
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };
    /* #endregion */

    return (
        <StyledComponent
            style={{ ...style }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            isDragging={isDragging}>
            <img className="dragdrop-icon" alt={upload} src={upload} />
            <div className="dragdrop-title">Drag and drop images here to upload</div>
        </StyledComponent>
    );
}
/* #region SCOPED CSS */
const StyledComponent = styled.div`
    & {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: dashed;
        border-color: var(--text-and-icons-blue-500, #00aeef);
        border-width: 1px;
        padding: 35px;
        display: flex;
        flex-direction: column;
        gap: 9px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 100%;
        max-height: 430px;
        width: 100%;
        position: relative;
        transition: background-color 0.3s, border-color 0.3s;

        ${({ isDragging }) =>
            isDragging &&
            `
            background-color: var(--backgrounds-and-lines-n-700, #1a1f2b);
            border-color: var(--text-and-icons-blue-700, #007bb5);
        `}
    }

    .dragdrop-title {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: var(--subtitle-1-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        position: relative;
    }
    .dragdrop-icon {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
`;
/* #endregion */

//------------------| Usage |------------------//

//
// const [productImages, setProductImages] = useState([]);
//
// This will set like this -> {"blob:http://localhost:3000/1e7b1c7e-7b7b-4b7b-8b7b-7b7b7b7b7b7b"}
// <DragDropImages onSetImages={setProductImages} />;
//
// ---------------- or ----------------
//
// This will set like this -> {name: "image1", base64: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABKklEQVRIS+2VwQ3CMAxF…"}
// <DragDropImages onNewImagesDrop={setProductImagesBase64} />
//
