import React from "react";

/**
 * Renders a dialog box component.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.headingColor - The color of the heading.
 * @param {string} props.heading - The heading text.
 * @param {string} props.title - The title text.
 * @param {boolean} props.showCloseButton - Indicates whether to display a close button.
 * @param {string} props.message - The message text.
 * @param {boolean} props.useAlternativeLayout - Indicates whether to use an alternative layout.
 * @param {function} props.onClickYes - The function to be called when the "YES" button is clicked.
 * @param {function} props.onClickNo - The function to be called when the "NO" button is clicked.
 * @param {string} props.loading - Indicates whether the component is in a loading state.
 * @param {string} props.loadingHeading - The heading text to be displayed when the component is in a loading state.
 * @param {string} props.cancelText - The text to be displayed on the "NO" button.
 * @param {string} props.confirmText - The text to be displayed on the "YES" button.
 * @param {string} props.onClickCloseBtn - The function to be called when the "X" button is clicked.
 * @param {boolean} props.showCancel - Indicates whether the "NO" button should be displayed.
 * @param {boolean} props.showConfirm - Indicates whether the "YES" button should be displayed.
 * @param {object} props.useImageHeader - The image to be used as the header.
 * @param {string} props.useImageHeader.src - The image source.
 * @param {string} props.useImageHeader.width - The image width
 * @param {string} props.buttonWidth - The width of the buttons.
 * @param {string} props.buttonFontSize - The font size of the buttons.
 * @param {string} props.cancelState - The state colors of the "NO" button. Can be "primary", "secondary" or "disabled".
 * @param {string} props.confirmState - The state color of the "YES" button. Can be "primary", "secondary" or "disabled".
 * @param {string} props.customLoader - The custom loader component.
 * @param {boolean} props.showTopButton - Indicates whether the top button should be displayed.
 * @param {string} props.topButtonText - The text to be displayed on the top button.
 * @param {string} props.topButtonState - The state color of the top button. Can be "primary", "secondary" or "disabled".
 * @param {function} props.onClickTopButton - The function to be called when the top button is clicked.
 *
 * @returns {JSX.Element} The rendered dialog box component.
 */
const DialogBoxComponent = ({
    headingColor = "var(--text-icons-blue-500)",
    heading = "JUST CHECKING...",
    headingFontSize,
    title,
    message,
    useAlternativeLayout = undefined,
    onClickYes,
    onClickNo,
    loading,
    loadingHeading,
    cancelText = "NO",
    confirmText = "YES",
    cancelState = "secondary",
    confirmState = "primary",
    showCancel = true,
    showConfirm = true,
    useImageHeader = {
        src: undefined,
        width: "100%"
    },
    buttonWidth,
    confirmWidth,
    cancelWidth,
    buttonFontSize,
    showCloseButton = false,
    onClickCloseBtn = () => {},
    customLoader,
    showTopButton = false,
    topButtonText = "",
    topButtonState = "primary",
    onClickTopButton = () => {},
    width = "360px"
}) => {
    const buttonState = state => {
        switch (state) {
            case "primary":
                return "CardBox-button-b";
            case "secondary":
                return "CardBox-button-a";
            case "disabled":
                return "CardBox-button-disabled";
            default:
                return "CardBox-button-a";
        }
    };

    return (
        <>
            <div className="CardBox">
                <div className="CardBox-container" style={{ width: width }}>
                    {!useImageHeader?.src ? (
                        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                            <h4
                                className="CardBox-heading dbox"
                                style={{
                                    color: headingColor,
                                    textTransform: "uppercase",
                                    marginLeft: "24px",
                                    marginRight: "24px",
                                    paddingLeft: showCloseButton ? "40px" : "8px",
                                    textOverflow: "ellipsis",
                                    textWrap: "nowrap"
                                }}>
                                {loading && loadingHeading ? loadingHeading : heading}
                            </h4>
                            {showCloseButton && !loading && (
                                <div
                                    className="XClose cursor-pointer"
                                    style={{
                                        width: "24px",
                                        height: " 17px",
                                        position: "relative",
                                        marginRight: "8px"
                                    }}
                                    onClick={onClickCloseBtn}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none">
                                        <path
                                            d="M6 6L18 18M18 6L6 18"
                                            stroke="#D1D1D1"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            )}
                        </div>
                    ) : (
                        <img
                            src={useImageHeader?.src}
                            alt="header"
                            className="CardBox-image-header"
                            style={{ width: useImageHeader?.width || "100%" }}
                        />
                    )}

                    <div
                        className="CardBox-container-hr2"
                        style={{
                            marginTop: useImageHeader?.src ? "16px" : "16px"
                        }}></div>

                    {loading === "true" || loading === true ? (
                        customLoader ? (
                            customLoader
                        ) : (
                            <div
                                style={{
                                    height: "130px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                // className="CardBox-container-body"
                            >
                                <div className="loader" style={{ height: "100%" }}>
                                    <div className="dot dot1"></div>
                                    <div className="dot dot2"></div>
                                    <div className="dot dot3"></div>
                                    <div className="dot dot4"></div>
                                </div>
                            </div>
                        )
                    ) : (
                        <div className="CardBox-container-body">
                            {typeof title === "string" ? (
                                <h3
                                    className="ready-delete"
                                    dangerouslySetInnerHTML={{ __html: title }}
                                    style={{ fontSize: headingFontSize || "18px" }}></h3>
                            ) : (
                                title
                            )}

                            {useAlternativeLayout ? (
                                useAlternativeLayout
                            ) : (
                                <p
                                    style={{ fontSize: "14px" }}
                                    className="Delete-CardBox-pregraf"
                                    dangerouslySetInnerHTML={{ __html: message }}></p>
                            )}
                            {showTopButton && (
                                <div className="CardBox-buttons w-100" style={{ marginBottom: "13px" }}>
                                    <button
                                        disabled={topButtonState === "disabled"}
                                        className={buttonState(topButtonState)}
                                        onClick={onClickTopButton}
                                        style={{
                                            width: "100%",
                                            fontSize: buttonFontSize || "14px"
                                        }}>
                                        {topButtonText}
                                    </button>
                                </div>
                            )}
                            <div
                                className="CardBox-buttons button-marginb w-100"
                                style={{ display: "flex", justifyContent: "center" }}>
                                {showCancel && (
                                    <button
                                        disabled={cancelState === "disabled"}
                                        className={buttonState(cancelState)}
                                        onClick={onClickNo}
                                        style={{
                                            width: buttonWidth ? buttonWidth : cancelWidth ? cancelWidth : "100%",
                                            fontSize: buttonFontSize || "14px"
                                        }}>
                                        {cancelText}
                                    </button>
                                )}
                                {showConfirm && (
                                    <button
                                        disabled={confirmState === "disabled"}
                                        className={buttonState(confirmState)}
                                        onClick={onClickYes}
                                        style={{
                                            width: buttonWidth ? buttonWidth : confirmWidth ? confirmWidth : "100%",
                                            fontSize: buttonFontSize || "14px"
                                        }}>
                                        {confirmText}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DialogBoxComponent;
