import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import InfinityGridProductsCards from "../../common/cardContainers/InfinityGridProductsCards";
import { useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { AppQueryClient } from "../../../api/queryClient";
import { defaultStatusColors } from "../../../utils/CardUtils";
import { DeleteAllProductsFromCompany } from "./ProductsUtils";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import * as Library from "../../../utils/Library";
import MainWindowHeader from "../../common/mainApp/mainWindowHeaders/MainWindowHeader";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    &.hideOverflow {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
/* #endregion */

export default function ProductPage() {
    document.title = `${APP_TITLE} - Products`;

    /* #region STATES */
    const prevSelectedRows = useRef([]);
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);

    const [listNumRows, setListNumRows] = useState(20);

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    const [searchValue, setSearchValue] = useState("");

    const [deleteDialog, setDeleteDialog] = useState({
        show: false,
        GTINsFound: "--",
        labelBrandsFound: "--",
        productsFound: "--"
    });
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const [readyToSavePopup, setReadyToSavePopup] = useState(false);
    const [popupTitle, setPopupTitle] = useState("");
    const [popupDescription, setPopupDescription] = useState("");
    const [status, setStatus] = useState("");
    const [productIDs, setProductIDs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [data, setData] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    //const [labelBrandsDropdownData, setLabelBrandsDropdownData] = useState([]);

    //---------- Header Filters
    const [allCollectionsList, setAllCollectionsList] = useState([]);
    const [filteredCollections, setFilteredCollections] = useState([]);
    //-----
    const [allLabelBrandsList, setAllLabelBrandsList] = useState([]);
    const [filteredLabelBrand, setFilteredLabelBrand] = useState([]);
    //-----
    const [requestEnabled, setRequestsEnabled] = useState(false);

    const filters = {
        brandIds: filteredLabelBrand?.map(item => item?.value),

        //TODO: these filters are not being used in the API
        filterLabelBrandIDs: filteredLabelBrand?.map(item => item?.value),
        //filterCollectionNames: filteredCollections?.map(item => item?.name)
        filterCollectionNames: []
    };

    const infiniteGridQuerykey = ["ProductsArchiveGrid", filters];
    const listQueryKey = ["ProductsArchiveList", filters];

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    /* #endregion */

    /* #region Methods */
    const onLabelBrandChange = (selections, dropdownDataWithSelections) => {
        setFilteredLabelBrand(selections);
        setAllLabelBrandsList(dropdownDataWithSelections);
    };

    const onCollectionChange = (selections, dropdownDataWithSelections) => {
        setFilteredCollections(selections);
        setAllCollectionsList(dropdownDataWithSelections);
    };

    const onFiltersLoaded = data => {
        // console.log("data", data);
        if (data?.labelBrands?.length) {
            const labelBrands = data?.labelBrands?.map(item => {
                return {
                    name: item?.name,
                    value: item?._id,
                    selected: true
                };
            });

            setAllLabelBrandsList(labelBrands);
            setFilteredLabelBrand(labelBrands);
        }

        if (data?.collections?.length) {
            const collections = data?.collections?.map(item => {
                return {
                    name: item?.name,
                    value: item?.name,
                    selected: true
                };
            });

            setAllCollectionsList(collections);
            setFilteredCollections(collections?.filter(item => item?.selected));
        }

        setRequestsEnabled(true);
    };
    //--------------------

    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };

    /*const resetInfiniteGrid = () => {
        AppQueryClient.clear();
    };*/

    const onSearch = searchValue => {
        setSearchValue(searchValue);
    };

    const onDeleteClick = async () => {
        setIsDeleteLoading(true);
        setDeleteDialog({ show: true, GTINsFound: "--", labelBrandsFound: "--", productsFound: "--" });
        const res = await DeleteAllProductsFromCompany({
            companyObjID: localStorage.getItem("currentCompanyID"),
            debugMode: true
        }).finally(() => setIsDeleteLoading(false));
        if (res?.data?.status !== 200) return;

        const resData = res?.data?.data;
        const { GTINsFound, labelBrandsFound, productsFound } = resData;

        if (GTINsFound === 0 && productsFound === 0) {
            Library.showToastMessage({
                type: "warning",
                title: "No products found",
                message: "No products and GTINs found to delete."
            });
            setDeleteDialog({ show: false, GTINsFound: "--", labelBrandsFound: "--", productsFound: "--" });
            return;
        }

        setDeleteDialog({ show: true, GTINsFound, labelBrandsFound, productsFound });
    };

    const deleteRequest = async () => {
        setIsDeleteLoading(true);
        const res = await DeleteAllProductsFromCompany({
            companyObjID: localStorage.getItem("currentCompanyID"),
            debugMode: false
        }).finally(() => {
            setIsDeleteLoading(false);
            setDeleteDialog({ show: false, GTINsFound: "--", labelBrandsFound: "--", productsFound: "--" });
        });
        if (res?.data?.status !== 200) return;

        const resData = res?.data?.data;
        const { productsDeleted, GTINsDeleted } = resData;

        Library.showToastMessage({
            type: "success",
            title: "All products deleted",
            message: `Successfully deleted ${productsDeleted?.deletedCount} products and ${GTINsDeleted?.deletedCount} GTINs.`
        });

        onClickRefreshAction();
    };

    const handleRestore = row => {
        setStatus("Active");
        setPopupTitle("RESTORE PRODUCT?");
        setPopupDescription(
            "Do you want to restore selected products? Selected products will become visible for shopper."
        );
        setReadyToSavePopup(true);
        setProductIDs([row?.productID]);
    };

    const handleSuspend = row => {
        if (row?.status === "Active") {
            setStatus("Suspended");
            setPopupTitle("SUSPEND PRODUCT?");
            setPopupDescription(
                "You are about to suspend this product. Shoppers won't be able to buy or view this product."
            );
        } else if (row?.status === "Suspended") {
            setStatus("Active");
            setPopupTitle("REACTIVATE PRODUCT?");
            setPopupDescription(
                "You are about to reactivate this product. Shoppers will be able to buy or view this product again."
            );
        }
        setReadyToSavePopup(true);
        setProductIDs([row?.productID]);
    };

    const handleClearSelectedProducts = () => {
        setSelectedProducts([]);
        setSelectAll(false);
    };

    const hanldeUpdateStatus = () => {
        const jsonData = {
            productIDs: productIDs,
            status: status
        };
        Library.makePostRequest("updateProductsStatus", jsonData, false, setIsLoading)
            .then(res => {
                const { /*data,*/ message, status } = res?.data;
                if (status === 200) {
                    Library.showToastMessage({
                        type: "success",
                        title: "Product status updated",
                        message: message
                    });
                    onClickRefreshAction();
                    setSelectAll(false);
                    setSelectedProducts([]);
                    // Call the API to refresh the products
                }
                setReadyToSavePopup(false);
            })
            .catch(err => {
                console.log(err);
                setReadyToSavePopup(false);
            });
    };

    const handleSelectAll = selectAll => {
        if (selectAll) {
            let allProductIDs =
                data?.pages?.flatMap(page => page?.data?.result?.map(product => product?.productID)) ?? []; //TODO:  //Review this code if can be improved
            if (props?.layoutType === "List" && Array.isArray(data)) {
                allProductIDs = data?.map(product => product.productID);
            }
            setSelectedProducts(allProductIDs);
            setSelectAll(true);
        } else {
            setSelectedProducts([]);
            setSelectAll(false);
        }
    };

    const handleSuspendAll = () => {
        setStatus("Suspended");
        setPopupTitle("SUSPEND PRODUCT?");
        setPopupDescription(
            `You are about to suspend ${selectedProducts.length} products. Shoppers won't be able to buy or view these products`
        );
        setReadyToSavePopup(true);
        setProductIDs(selectedProducts);
    };

    const handleRestoreAll = () => {
        setStatus("Active");
        setPopupTitle("RESTORE PRODUCT?");
        setPopupDescription(
            `You're about to restore ${selectedProducts.length} products. Shoppers will be able to buy or view these products.`
        );
        setReadyToSavePopup(true);
        setProductIDs(selectedProducts);
    };

    const handleSelectedRowsChange = selectedRows => {
        const selectedProductIDs = selectedRows.map(row => row.productID);
        if (JSON.stringify(prevSelectedRows.current) !== JSON.stringify(selectedProductIDs)) {
            prevSelectedRows.current = selectedProductIDs;
            setSelectedProducts(selectedProductIDs);
        }
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(
            props,
            onClickRefreshAction,
            navigations,
            onSearch,
            //onDeleteClick,
            handleSelectAll,
            selectedProducts,
            handleClearSelectedProducts,
            /* searchValue,
            setSearchValue,
            labelBrandsDropdownData,
            handleSuspendAll,
            handleRestoreAll,*/
            //----
            onCollectionChange,
            onLabelBrandChange,
            onFiltersLoaded,
            allLabelBrandsList,
            allCollectionsList,
            selectAll
        );
        let allProductIDs = data?.pages?.flatMap(page => page?.data?.result?.map(product => product.productID)) ?? [];
        if (props?.layoutType === "List" && Array.isArray(data)) {
            allProductIDs = data?.map(product => product.productID);
        }
        if (
            typeof allProductIDs !== "undefined" &&
            typeof selectedProducts !== "undefined" &&
            allProductIDs?.length === selectedProducts?.length &&
            allProductIDs?.length > 0
        ) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [props?.layoutType, selectedProducts, data, selectAll, allLabelBrandsList, allCollectionsList]);

    /* useEffect(() => {
        const labelBrands = JSON.parse(localStorage.getItem("labelBrands")) || [];
        const dropdownData = labelBrands.map((brand, index) => ({
            value: brand?._id,
            name: brand?.name,
            selected: index === 0 // Select the first brand
        }));
        setCurrentLabelBrandID(dropdownData[0].value);

        //setLabelBrandsDropdownData(dropdownData);
    }, []); */
    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "BOtag ID",
            selector: row => row?.botagID ?? "--"
        },
        {
            name: "Brand",
            selector: row => row?.brand ?? "--"
        },
        {
            name: "Style name",
            selector: row => row?.styleName ?? "--"
        },
        {
            name: "Reference",
            selector: row => row?.reference ?? "--"
        },
        {
            name: "Colors",
            selector: row => row?.colorNames?.join(", ") ?? "--"
        },
        {
            name: "Sizes",
            selector: row => row?.sizes?.join(", ") ?? "--"
        },
        {
            name: "Category",
            selector: row => row?.category ?? "--"
        }
    ];
    /* #endregion */

    return (
        <StyledPage className={viewLayoutStyle === "Grid" ? "hideOverflow" : ""}>
            <FullScreenOverlay show={deleteDialog?.show}>
                <DialogBoxComponent
                    loading={isDeleteLoading}
                    loadingHeading="PREPARING..."
                    heading="Warning"
                    headingColor="var(--text-icons-orange-500)"
                    title="Ready to delete products?"
                    onClickYes={deleteRequest}
                    onClickNo={() => setDeleteDialog({ show: false })}
                    cancelText="NO"
                    confirmText="YES"
                    message={`We found ${deleteDialog?.GTINsFound} GTINs from ${deleteDialog?.productsFound} products, belonging to
                     ${deleteDialog?.labelBrandsFound} label brands. Are you sure you want to delete all these Products and GTINs?`}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={readyToSavePopup}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-blue-500)"
                    title={popupTitle}
                    message={popupDescription}
                    onClickYes={() => {
                        hanldeUpdateStatus();
                    }}
                    loading={isLoading}
                    loadingHeading={"SUBSCRIBING.."}
                    onClickNo={() => {
                        setReadyToSavePopup(false);
                    }}
                    cancelText="No"
                    confirmText="Yes"
                />
            </FullScreenOverlay>

            {viewLayoutStyle === "Grid" && (
                <InfinityGridProductsCards
                    className="grid"
                    minColumnWidth="294px"
                    queryKey={infiniteGridQuerykey}
                    barcode={searchValue}
                    setReadyToSavePopup={setReadyToSavePopup}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    setData={setData}
                    status="Archived"
                    hideActionBtn={true}
                    primaryButton={{
                        title: "RESTORE",
                        onClick: handleRestore
                    }}
                    secondaryButton={{
                        title: "REMOVE"
                    }}
                    requestEnabled={requestEnabled}
                    filters={filters}
                />
            )}

            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="Products"
                    columns={listColumns}
                    api={{
                        endpoint: "getProductsData",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            barcode: searchValue,
                            status: "Archived",
                            limit: listNumRows,
                            ...filters
                            //searchName: searchValue
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={listNumRows}
                    currentPage={1}
                    queryKey={listQueryKey}
                    setData={setData}
                    selectedProducts={selectedProducts}
                    handleSelectedRowsChange={handleSelectedRowsChange}
                    setSelectedProducts={setSelectedProducts}
                    actions={{
                        editIsVisible: false,
                        includeActions: true,
                        suspendIsVisible: false,
                        removeIsVisible: true,
                        restoreIsVisible: true,
                        onClickRestore: handleRestore
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    requestEnabled={requestEnabled}
                    onChangeRowsPerPage={rowsPerPage => setListNumRows(rowsPerPage)}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(
    props,
    onRefreshAction,
    navigations,
    onSearch,
    //onDeleteClick,
    handleSelectAll,
    selectedProducts,
    handleClearSelectedProducts,
    /* searchValue,
    setSearchValue,
    labelBrandsDropdownData,
    handleSuspendAll,
    handleRestoreAll,*/
    //----
    onCollectionChange,
    onLabelBrandChange,
    onFiltersLoaded,
    allLabelBrandsList,
    allCollectionsList,
    selectAll
) {
    const createMode = navigations?.navigationAccesses?.products?.createModeOnly;

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Products", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/products", active: false, disabled: false, title: "Products" },
        /*{ to: "#", active: false, disabled: true, title: "Stock" },*/
        { to: "/products/archive", active: true, disabled: false, title: "Archive" }
    ]);
    props.setControlBarRightBtns([
        { to: "/products/archive", active: true, disabled: createMode, title: "View", icon: "eye" }
    ]);
    props.setFilterHeaderProperties({
        customContentHeader: (
            <MainWindowHeader
                key={window.location.pathname}
                selections={{
                    enabled: true,
                    allSelected: selectAll,
                    selectedCount: selectedProducts?.length,
                    onClearSelection: handleClearSelectedProducts,
                    onSelectAll: handleSelectAll,
                    //onDeleteClick: handleRemoveAll,
                    //onSuspendClick: handleSuspendAll,
                    onUploadClick: undefined
                }}
                search={{
                    searchPlaceholder: "Product name, ean code"
                    //onSearch: onSearch
                }}
                //------
                visible={true}
                layoutTypeOptions={{
                    gridEnabled: true,
                    listEnabled: true
                }}
                onRefreshClick={onRefreshAction}
                firstOrganize={{
                    visible: true,
                    dropdownData: allLabelBrandsList,
                    onDropdownChange: onLabelBrandChange,
                    disabled: false,
                    type: allLabelBrandsList?.length < 2 ? "singleSelect" : "multiSelect",

                    multidropdownOptions: {
                        useDoneButton: true,
                        useSelectAll: true,
                        selectAllText: "Select all Label Brands",
                        selectAllPlaceholderFunction: arrayItems => {
                            if (!arrayItems?.length) return "No Label Brands";

                            const nonLockedLabelBrands = allLabelBrandsList?.filter(item => !item?.locked);
                            const areAllSelected = arrayItems?.length === nonLockedLabelBrands?.length;
                            if (areAllSelected) return `All Label Brands (${nonLockedLabelBrands?.length})`;

                            let firstTwoItems = arrayItems
                                ?.slice(0, 2)
                                ?.map(item => item?.name)
                                ?.join(", ");
                            if (arrayItems?.length > 2) {
                                firstTwoItems = firstTwoItems + ", and more";
                            }

                            return `Label Brands (${arrayItems?.length}): ${firstTwoItems}`;
                        }
                    }
                }}
                secondOrganize={{
                    visible: true,
                    disabled: true
                    /* dropdownData: allCollectionsList,
                    onDropdownChange: onCollectionChange,
                    type: "multiSelect",

                    multidropdownOptions: {
                        useDoneButton: true,
                        useSelectAll: true,
                        selectAllText: "Select all collections",
                        selectAllPlaceholderFunction: arrayItems => {
                            if (!arrayItems?.length) return "No collections";

                            const nonLockedCollections = allCollectionsList?.filter(item => !item?.locked);
                            const areAllSelected = arrayItems?.length === nonLockedCollections?.length;
                            if (areAllSelected) return `All collections (${nonLockedCollections?.length})`;

                            let firstTwoItems = arrayItems
                                ?.slice(0, 2)
                                ?.map(item => item?.name)
                                ?.join(", ");
                            if (arrayItems?.length > 2) {
                                firstTwoItems = firstTwoItems + ", and more";
                            }

                            return `Collections (${arrayItems?.length}): ${firstTwoItems}`;
                        }
                    } */
                }}
                pageFilters={{
                    useFilters: true,
                    filters: {
                        useLabelBrands: true,
                        useProductsCollections: true
                    },
                    onFiltersLoaded: onFiltersLoaded
                }}
            />
        )
    });
}
