import styled from "styled-components";
import { useEffect } from "react";

//Components
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import FormField from "../../common/forms/FormField";
import InputInfo from "./InputInfo";

export default function ProductPageAddHeader(props) {
    /* #region STATES */
    const {
        setSelectedBrand,
        selectedBrand,
        selectedFile,
        labels,
        isErrorInvalidFormat,
        setIsDialogImportOpen,
        fileInputRef,
        setOpenPreview,
        isImportBTNEnabled,
        labelBrands
    } = props;

    const isBrandSelected = selectedBrand ? false : true;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (labelBrands.length === 1) {
            setSelectedBrand(labelBrands[0]);
        }
    }, [labelBrands]);

    /* #endregion */

    /* #region METHODS */

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    /* #endregion */

    return (
        <StyledPage className="page-add-header-container">
            <div className="header-left-content">
                <FormField
                    style={{
                        maxWidth: "380px",
                        width: "100%"
                    }}
                    className="input-selector-inverted"
                    styling={{ backgroundColor: "#1E2631" }}
                    type="dropdown"
                    placeholder="Select brand"
                    dropdownData={labelBrands}
                    value={selectedBrand?.name}
                    onTextChange={value => setSelectedBrand(value)}
                    varName="brand"
                    requiredType={labelBrands.length === 1 ? "locked" : "mandatory"}
                    isDropdownMultiSelect={false}
                    isDropdownSearchable={true}
                />
            </div>
            <div className="header-middle-content">
                <InputInfo
                    onClick={handleButtonClick}
                    value={selectedFile || ""}
                    disabled={isBrandSelected}
                    isError={isErrorInvalidFormat}
                />
                <BtnLargeSolid
                    text="UPLOAD FILE"
                    disabled={isBrandSelected}
                    style={{ width: "110px" }}
                    onClick={handleButtonClick}
                />

                <BtnLargeSolid text="SERVER UPLOAD" disabled={true} style={{ width: "110px" }} />
            </div>
            <div className="header-right-content">
                <BtnLargeSolid
                    text="FILE PREVIEW"
                    disabled={!labels}
                    style={{ width: "110px" }}
                    onClick={() => setOpenPreview(true)}
                />
                <BtnLargeSolid
                    text="IMPORT"
                    disabled={!isImportBTNEnabled} //Unlock the button when the last tab is unlocked
                    style={{ width: "110px" }}
                    onClick={() => setIsDialogImportOpen(true)}
                />
            </div>
        </StyledPage>
    );
}

/* #region SCOPED CSS */
const StyledPage = styled.div`
    &.page-add-header-container {
        display: flex;
        padding: 15px 24px;
        width: 100%;
        gap: 50px;
    }

    .header-left-content {
        display: flex;
        flex: 1;
    }
    .header-middle-content {
        display: flex;
        flex: 1;
        gap: 10px;
        justify-content: center;
        align-items: center;
        margin-bottom: -5px; //TODO: Remove this when the buttons/inputs are aligned
    }
    .header-right-content {
        display: flex;
        flex: 1;
        gap: 10px;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: -5px; //TODO: Remove this when the buttons/inputs are aligned
    }
    .input-selector-inverted {
        width: 348px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 38px;
        position: relative;
    }
`;
/* #endregion */
