import React, { useEffect, useState } from "react";
//import QrCode from "../../assets/images/qrcode.png";
import styled from "styled-components";
import * as Library from "../../utils/Library";
//import QRCode from "react-qr-code";
import QRCode from "qrcode.react";

const StyledPage = styled.div`
    .bottom-navigation {
        width: 100%;
        background-color: #141922;
        display: flex;
        justify-content: space-around;
        border-radius: 4px;
        padding: 4px;
        height: 35px;
    }

    .nav-item {
        color: white;
        text-decoration: none;
        width: 98px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        outline: none;
    }

    /* .nav-item:hover {
    background-color: #555;
    } */

    .selected {
        background-color: #1e2631;
        border: 1px solid #00bb00;
        border-radius: 4px;
    }
    .publish-popup-container {
        width: 400px;
        padding: 24px;
        background-color: #1e2631;
        border-radius: 4px;
    }
    .publish-header {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 24px;
    }
    .copyUrlBtn.disabled {
        background-color: #1e2631;
        cursor: not-allowed;
    }

    .copyUrlBtn.disabled span {
        color: var(--text-icons-gray-300);
    }

    .qrcode-canvas {
        width: 105px !important;
        height: 105px !important;
        border-radius: 6px;
    }
`;

const PublishPopUp = ({ setPublishPopUp, BOimageID }) => {
    const [selectedItem, setSelectedItem] = useState("Home");
    const [publisherUrl, setPublisherUrl] = useState("--");
    const [qrcodeID, setQrcodeID] = useState("");

    useEffect(() => {
        if (!BOimageID) return;
        const urlPrefix = process.env.REACT_APP_BOTHEBOT_URL;
        setQrcodeID(BOimageID);
        setPublisherUrl(`${urlPrefix}${BOimageID}`);
    }, [BOimageID]);

    const handleItemClick = item => {
        setSelectedItem(item);
    };

    return (
        <StyledPage>
            <div className="publish-popup-container">
                <Header setPublishPopUp={setPublishPopUp} />
                <div className="bottom-navigation">
                    <button
                        className={`nav-item ${selectedItem === "Home" ? "selected" : ""}`}
                        onClick={() => handleItemClick("Home")}>
                        <p style={{ fontSize: "12px", fontWeight: 500, cursor: "pointer" }}>
                            <i className="fa-light fa-rocket-launch mr-4px"></i> PUBLISH
                        </p>
                    </button>
                    <button
                        disabled={true}
                        style={{ cursor: "not-allowed" }}
                        className={`nav-item ${selectedItem === "About" ? "selected" : ""}`}
                        onClick={() => handleItemClick("About")}>
                        <p
                            style={{
                                fontSize: "12px",
                                fontWeight: 500,
                                cursor: "not-allowed",
                                color: "var(--text-icons-gray-300)"
                            }}>
                            <i className="fa-solid fa-code-simple mr-4px"></i> EMBED
                        </p>
                    </button>
                    <button
                        className={`nav-item ${selectedItem === "Services" ? "selected" : ""}`}
                        onClick={() => handleItemClick("Services")}>
                        <p style={{ fontSize: "12px", fontWeight: 500, cursor: "pointer" }}>
                            <i className="fa-solid fa-arrow-down-to-bracket mr-4px"></i> QR CODE
                        </p>
                    </button>
                </div>

                <div className="content-below">
                    {selectedItem === "Home" && <HomeContent publisherUrl={publisherUrl} />}
                    {selectedItem === "About" && <AboutContent publisherUrl={publisherUrl} />}
                    {selectedItem === "Services" && <ServicesContent publisherUrl={publisherUrl} qrcodeID={qrcodeID} />}
                </div>
            </div>
        </StyledPage>
    );
};

function HomeContent(props) {
    const [publisherUrl, setPublisherUrl] = useState("--");
    const [copyEnabled, setCopyEnabled] = useState(false);

    useEffect(() => {
        if (!props?.publisherUrl) {
            setCopyEnabled(false);
            setPublisherUrl("--");
            return;
        }
        setPublisherUrl(props.publisherUrl);
        setCopyEnabled(true);
    }, [props.publisherUrl]);

    const copyToClipboard = () => {
        if (!copyEnabled) return;
        navigator.clipboard.writeText(publisherUrl);
        Library.showSuccessMessage("Copied to clipboard");
    };

    return (
        <div style={{ display: "inline" }}>
            <h4 style={{ float: "inline-start", fontSize: "12px", fontWeight: 400, margin: "16px 0px 10px 0px" }}>
                Publisher URL:
            </h4>
            <div
                style={{
                    backgroundColor: "#141922",
                    borderRadius: "4px",
                    width: "100%",
                    height: "34px",
                    padding: "4px 4px 4px 8px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                <p
                    style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#7C868B",
                        overflowX: "scroll",
                        whiteSpace: "nowrap",
                        marginRight: "8px",
                        scrollbarWidth: "none"
                    }}>
                    {publisherUrl}
                </p>
                <div
                    className={`copyUrlBtn ${!copyEnabled ? "disabled" : ""}`}
                    onClick={copyToClipboard}
                    style={{
                        width: "68px",
                        height: "26px",
                        borderRadius: "4px",
                        backgroundColor: "#1E2631",
                        cursor: "pointer",
                        flexShrink: 0
                    }}>
                    <span
                        style={{
                            fontSize: "12px",
                            lineHeight: "26px",
                            fontWeight: 500
                        }}>
                        <i className="fa-regular fa-clone mr-4px"></i> COPY
                    </span>
                </div>
            </div>
        </div>
    );
}

function AboutContent() {
    return <div>About Content Goes Here</div>;
}

function ServicesContent(props) {
    const downloadQRcode = () => {
        const canvas = document.querySelector("canvas");
        if (!canvas) {
            Library.showErrorMessage("There was an error while downloading the QR code. Please try again later.");
            return;
        }
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        const downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = props?.qrcodeID ? `BOImage_${props.qrcodeID}.png` : "BOImage.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div
            style={{
                display: "flex",
                backgroundColor: "#141922",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                marginTop: "16px",
                padding: "24px"
            }}>
            <div
                style={{
                    backgroundColor: "#1E2631",
                    borderRadius: "8px",
                    alignItems: "center",
                    padding: "8px",
                    cursor: "pointer"
                }}
                onClick={() => downloadQRcode()}>
                <QRCode className="qrcode-canvas" value={props.publisherUrl} size={1024} includeMargin={true} />
                <p
                    style={{
                        marginTop: "10px",
                        width: "100%",
                        height: "26px",
                        backgroundColor: "#10141B",
                        fontSize: "12px",
                        fontWeight: 500,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "4px",
                        display: "flex"
                    }}>
                    <i
                        style={{ marginRight: "4px", top: "0px", right: "-11px" }}
                        className="fa-regular fa-arrow-down-to-line"
                    />
                    DOWNLOAD
                </p>
            </div>
        </div>
    );
}

function Header({ setPublishPopUp }) {
    return (
        <div style={{ position: "relative" }}>
            <p className="publish-header">Share</p>
            <i
                className="fa-regular fa-x"
                style={{
                    position: "absolute",
                    top: "0px",
                    right: "-11px",
                    cursor: "pointer",
                    fontSize: "11px",
                    fontWeight: 300
                }}
                onClick={() => {
                    setPublishPopUp(false);
                }}></i>
        </div>
    );
}
export default PublishPopUp;
