import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import { AppQueryClient } from "../../../api/queryClient";
import { useSearchParams } from "react-router-dom";
import LayoutContext from "../../../LayoutContext";
import { useSelector } from "react-redux";
import { defaultStatusColors } from "../../../utils/CardUtils";
import StockExpandedComponent from "./StockExpandedComponent";
import { Tooltip } from "antd";
import { formatColorHex, formatDefaultDateTime } from "../../../utils/Formatter";
import ContainerLoader from "../../common/loaders/ContainerLoader";
import AppContext from "../../../AppContext";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import { ReactComponent as LightningIcon } from "../../../assets/icons/lightning-01.svg";
import { ReactComponent as TurtleIcon } from "../../../assets/icons/Turtle_1_.svg";
import { ReactComponent as ZapFastIcon } from "../../../assets/icons/zap-fast.svg";
import MainWindowHeader, { OrganizeBtnMenu } from "../../common/mainApp/mainWindowHeaders/MainWindowHeader";
import * as Library from "../../../utils/Library";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
import FormField from "../../common/forms/FormField";

// #region  SCOPED CSS
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .ant-tooltip-inner {
        background-color: #4369aa !important;
    }

    .rdt_ExpanderRow {
        background: rgb(16, 20, 27);
    }

    .frame-1000011360,
    .frame-1000011360 * {
        box-sizing: border-box;
    }
    .frame-1000011360 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011324 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .image {
        border-radius: 4px;
        flex-shrink: 0;
        width: 28px;
        height: 28px;
        position: relative;
        object-fit: cover;
    }
    .frame-111 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    .regulator-parka {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }

    //TOOLTIP INNER
    &.frame-1000011326 {
        //background: rgba(255, 255, 255, 0.1);
        background: #4f535a;
        margin: 0px;
        padding: 4px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
        backdrop-filter: blur(5px);
        width: 130px;
        height: 130px;
        border-radius: 4px;
    }
    .rectangle-256 {
        border-radius: 4px;
        flex-shrink: 0;
        width: 120px;
        height: 121px;
        position: relative;
        object-fit: cover;
    }
    .frame-1000011327 {
        flex-shrink: 0;
        width: 120px;
        height: 120px;
        position: absolute;
        left: -7px;
        top: 53px;
        overflow: visible;
        //round corners
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-radius: 7px;
    }

    //select checkbox styles
    .table-structure.expandable-rows .rdt_TableHeadRow > div:first-child,
    .table-structure.expandable-rows .rdt_TableRow > div:first-child,
    .table-structure.expandable-rows .rdt_ExpanderRow .nested-row > .nested-col:first-child {
        width: 20px !important;
        flex: 0 0 20px !important;
        min-width: 20px !important;
        margin-left: 8px !important;
    }

    //2 nth child:
    .table-structure.expandable-rows .rdt_TableHeadRow > div:nth-child(2),
    .table-structure.expandable-rows .rdt_TableRow > div:nth-child(2),
    .table-structure.expandable-rows .rdt_ExpanderRow .nested-row > .nested-col:nth-child(2) {
        width: 24px !important;
        flex: 0 0 24px !important;
        min-width: 24px !important;
        margin-left: 0px !important;
    }

    //2 nth child button:
    .table-structure.expandable-rows .rdt_TableHeadRow > div:nth-child(2) > button,
    .table-structure.expandable-rows .rdt_TableRow > div:nth-child(2) > button,
    .table-structure.expandable-rows .rdt_ExpanderRow .nested-row > .nested-col:nth-child(2) > button {
        padding-left: 0px !important;
        padding-right: 4px !important;
    }

    //2 nth table header:
    .table-structure.expandable-rows .rdt_TableHeadRow > div:nth-child(2) {
        padding-left: 0px !important;
        padding-right: 4px !important;
        width: 24px !important;
    }
`;

const StockPopupStyle = styled.div`
    .box-container {
        display: flex;
        flex-direction: column;
        padding: 16px;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-radius: 5px;
        background: var(--backgrounds-lines-n-300, #1a222d);
    }
    .input-line {
        display: flex;
        align-items: center;
    }
    .input-label {
        width: 72px;
        color: var(--text-icons-gray-100, #d1d1d1);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        align-items: center;
    }
    .input-label-2 {
        color: var(--text-icons-gray-100, #d1d1d1);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        align-items: center;
    }

    .box-container-title {
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        gap: 8px;
        width: 100%;
        align-items: center;
    }
    .divider {
        width: 452px;
        height: 1px;
        background: #262e3e;
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .popup-title {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 8px 0px 8px 0px;
    }

    .popup-message {
        color: var(--text-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .popup-secondary-title {
        color: #fff;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 8px;
        text-align: left;
    }

    .popup-secondary-title-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .box-container-tag {
        color: var(--backgrounds-lines-n-300, #1a222d);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 2px;

        display: flex;
        width: 16px;
        height: 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    .number-input {
    }
`;

const StockPopupInputStyle = styled.div`
    & {
        display: flex;
        width: 187px;
        height: 27px;
        padding: 6px 8px;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        background: var(--backgrounds-lines-n-600, #10141b);
        color: var(--text-icons-green-500, #0b0);
        margin: 0px 8px 0px 8px;
    }
    //TODO: Disable all default styles
    input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        padding-right: 20px;

        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        appearance: textfield;
        -moz-appearance: textfield;
    }
    .disabled {
        color: var(--text-and-icons-gray-100, rgb(177, 177, 177));
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
    }
`;

const StockRotationStyled = styled.div`
    &.stock-rotation-container,
    &.stock-rotation-container * {
        box-sizing: border-box;
    }

    &.stock-rotation-container {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
        width: 196px;
    }

    .frame-1000011361 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 90px;
        position: relative;
    }
    .zap-fast {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
        aspect-ratio: 1;
    }
    .frame-111 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .fast {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }

    .frame-1000011403 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 90px;
        position: relative;
    }
    .frame-313375 {
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        padding: 4px 8px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        //width: 65px;
        position: relative;
    }
    .reorders {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
    }
    .ellipse-12 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 50%;
        flex-shrink: 0;
        width: 5px;
        height: 5px;
        position: absolute;
        right: 4px;
        top: 4px;
    }
`;
// #endregion

export default function StockPage() {
    document.title = `${APP_TITLE} - Products Stock`;

    // #region STATES
    const layoutCtx = React.useContext(LayoutContext);
    const { layoutType } = layoutCtx;
    const { isLoadingApp } = useContext(AppContext);
    const navigations = useSelector(state => state.navigationAccesses);

    const [searchParams] = useSearchParams();
    const storeID = searchParams.get("storeID");
    const storeName = searchParams.get("storeName");

    const [listNumRows, setListNumRows] = useState(10);

    const [loadingStoreFilters, setLoadingStoreFilters] = useState(false); //TODO: Check should we remove this?
    const [listComponentIsLoading, setListComponentIsLoading] = useState(false);

    const [isStockPopupVisible, setIsStockPopupVisible] = useState(false);
    const [isStockPopupLoading, setIsStockPopupLoading] = useState(false);
    const [stockPopupData, setStockPopupData] = useState({});
    const [loadingLabelBrandForPopup, setLoadingLabelBrandForPopup] = useState(false);

    //TODO: REVIEW THIS SELECTED LABEL BRAND FOR POPUP
    const [selectedLabelBrand, setSelectedLabelBrand] = useState(null);
    const labelBrands = JSON.parse(localStorage.getItem("labelBrands"))?.map(it => ({
        value: it?._id,
        name: it?.name
    }));

    //---------- Header Filters
    const [allConnectedStoresList, setAllConnectedStoresList] = useState([]);
    const [allStoresList, setAllStoresList] = useState([]);
    const [filteredStores, setFilteredStores] = useState([]);
    //-----
    const [allLabelBrandsList, setAllLabelBrandsList] = useState([]);
    const [filteredLabelBrand, setFilteredLabelBrand] = useState([]);
    //-----
    const [requestEnabled, setRequestsEnabled] = useState(false);

    const filters = {
        filterLabelBrandIDs: filteredLabelBrand?.map(item => item?.value),
        filterStoreIDs: filteredStores?.map(item => item?.value)
    };

    const listQueryKey = ["StockPageList", filters];
    // #endregion

    // #region EFFECTS
    useEffect(() => {
        if (!selectedLabelBrand) return;
        getLabelBrandForPopup();
    }, [selectedLabelBrand]);

    useEffect(() => {
        if (!layoutType) return;

        const sortedStores = sortStores(allStoresList);

        setupHeaders({
            layoutCtx,
            onClickRefreshAction,
            navigations,
            allStoresList: sortedStores,
            allLabelBrandsList,
            onLabelBrandChange,
            onFiltersLoaded,
            setIsStockPopupVisible,
            onStoresChange
        });
    }, [
        layoutType,
        navigations,
        allLabelBrandsList,
        allStoresList,
        filteredLabelBrand,
        filteredStores,
        requestEnabled
    ]);
    // #endregion

    // #region LIST COMPONENT PROPS
    const listColumns = [
        {
            name: "Style Name",
            //selector: row => row?.productName ?? "--"
            selector: row => ProductWithImage({ name: row?.productName, image: row?.image }),
            width: "266px"
        },
        {
            name: "Reference",
            selector: row => row?.reference || "--",
            width: "126px"
        },
        {
            name: <div style={{ whiteSpace: "pre-wrap" }}>Stock levels</div>,
            selector: row => (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "16px",
                        height: "16px",
                        borderRadius: "2px",
                        backgroundColor: !row?.stockLevelCriteria
                            ? "#4f535a"
                            : row?.stockLevelCriteria === "High"
                            ? "#0b0"
                            : row?.stockLevelCriteria === "Moderate"
                            ? "#F90"
                            : "#FF3636",
                        color: "#1A222D",
                        justifyContent: "center"
                    }}>
                    {row?.stockLevelCriteria ? row?.stockLevelCriteria[0] : "--"}
                </div>
            ),
            width: "65px"
        },
        {
            name: <div style={{ whiteSpace: "pre-wrap", width: "65px" }}>Stock rotation</div>,
            selector: row => StockRotation({ stock: row?.stockRotationCriteria ?? "--" }),
            width: "212px"
        },
        {
            name: "Stock",
            selector: row => row?.stockSum ?? "--",
            width: "88px"
        },
        {
            name: "Stores",
            selector: row => row?.totalStoresWithStock ?? "--",
            width: "56px"
        },
        {
            name: "Product Type",
            selector: row => row?.productType || "--",
            width: "111px"
        },
        {
            name: "Average RP",
            selector: row => row?.averagePrice ?? "--"
        },
        {
            name: "Wholesale P",
            selector: row => row?.wholesalePrice ?? "--"
        },
        {
            name: "Gross Margin",
            selector: row => row?.grossMargin ?? "--"
        },
        {
            name: "Collection/year",
            selector: row => row?.collection || "--"
        },
        {
            name: "Gender",
            selector: row => row?.gender || "--"
        }

        /*   {
            name: "Colors",
            selector: row => (row?.colors?.length ? getMultipleColors(row?.colors) : "--")
        } */
    ];
    // #endregion

    // #region METHODS
    const onLabelBrandChange = selection => {
        setFilteredLabelBrand([selection]);

        const currentLabelBrandID = selection?.value;
        filterConnectedStores(allConnectedStoresList, currentLabelBrandID);
    };

    const onStoresChange = (selections, dropdownDataWithSelections) => {
        setFilteredStores(selections);
        setAllStoresList(dropdownDataWithSelections);
    };

    const onFiltersLoaded = data => {
        let currentLabelBrandID = null;
        if (data?.labelBrands?.length) {
            const labelBrands = data?.labelBrands?.map((item, index) => {
                return {
                    name: item?.name,
                    value: item?._id,
                    selected: index === 0
                };
            });

            setAllLabelBrandsList(labelBrands);
            setFilteredLabelBrand([labelBrands[0]]);
            currentLabelBrandID = labelBrands[0]?.value;
        }

        if (data?.connectedStores?.length) {
            const connectedStores = data?.connectedStores?.map(item => {
                const itemLocked = !item?.ffcID;

                return {
                    name: item?.name,
                    value: item?._id,
                    description: item?.ffcID
                        ? `Last update: ${formatDefaultDateTime(item?.updatedAt, "--")}`
                        : "Not affiliated",
                    locked: itemLocked,
                    selected: itemLocked ? false : storeID ? item?._id === storeID : true,
                    labelBrandID: item?.labelBrandID
                };
            });

            setAllConnectedStoresList(connectedStores);
            setFilteredStores(connectedStores?.filter(item => item?.selected));

            filterConnectedStores(connectedStores, currentLabelBrandID);
        }
        setRequestsEnabled(true);
    };

    const filterConnectedStores = async (allStores, currentLabelBrandID) => {
        let res = allStores?.filter(item => {
            return item?.labelBrandID === currentLabelBrandID;
        });

        // filter out any duplicated retailers
        res = res?.filter((item, index) => {
            return res?.findIndex(item2 => item2?.value === item?.value) === index;
        });

        setAllStoresList(res);
        setFilteredStores(res);
    };

    const onClickRefreshAction = () => {
        AppQueryClient.invalidateQueries(listQueryKey);
    };

    const mapListData = data => {
        const result = data?.result?.map(item => {
            let stockTotal = 0;
            let firstImage = "";
            //let totalPrice = 0;
            let itemsCount = 0;

            return {
                ...item,
                expandItems: item?.expandItems?.map(expandItem => {
                    stockTotal += expandItem?.stock?.reduce((acc, item) => acc + item, 0);
                    if (!firstImage) firstImage = expandItem?.images?.[0]?.URL;
                    //totalPrice = totalPrice + (expandItem?.averagePrice ?? 0);
                    itemsCount = itemsCount + 1;

                    return {
                        ...expandItem,
                        BOtagID: item?.BOtagID,
                        productName: item?.productName,
                        reference: item?.reference
                    };
                }),
                stockSum: stockTotal,
                image: firstImage //,
                //averagePrice: totalPrice / itemsCount
            };
        });

        return {
            ...data,
            result: result
        };
    };

    const getSelectedStoresForQuery = () => {
        //if "All Stores" is selected, then return all stores except "All Stores" and locked stores
        //else return only selected stores except "All Stores" and locked stores
        let result = [];

        if (filteredStores?.find(item => item?.value === "selectAll")) {
            result = allStoresList
                ?.filter(item => item?.value !== "selectAll" && !item?.locked)
                ?.map(item => item?.value);
        } else {
            result = filteredStores?.filter(item => item?.selected)?.map(item => item?.value);
        }

        return result;
    };

    const getLabelBrandForPopup = async () => {
        const labelBrandID = selectedLabelBrand?.value;
        if (!labelBrandID) return;

        const payloadData = {
            labelBrandID: labelBrandID
        };
        Library.makePostRequest("getLabelBrand", payloadData, false, setLoadingLabelBrandForPopup).then(res => {
            if (res?.status === 200) {
                const saleSpeedConfiguration = res?.data?.data?.saleSpeedConfiguration?.[0];
                if (!saleSpeedConfiguration) {
                    setStockPopupData({
                        FastRotationPercentage: "",
                        FastRotationDays: "",
                        SlowRotationPercentage: "",
                        SlowRotationDays: "",
                        StockLevelLow: "",
                        StockLevelHigh: ""
                    });
                    return;
                }
                setStockPopupData({
                    FastRotationPercentage: saleSpeedConfiguration?.fastRotation?.percentage,
                    FastRotationDays: saleSpeedConfiguration?.fastRotation?.days,
                    SlowRotationPercentage: saleSpeedConfiguration?.slowRotation?.percentage,
                    SlowRotationDays: saleSpeedConfiguration?.slowRotation?.days,
                    StockLevelLow: saleSpeedConfiguration?.stockLevels?.percentage,
                    StockLevelHigh: saleSpeedConfiguration?.stockLevels?.days
                });
            }
        });
    };

    const handleClosePopup = () => {
        setIsStockPopupVisible(false);
        setSelectedLabelBrand(null);
        setStockPopupData({
            FastRotationPercentage: "",
            FastRotationDays: "",
            SlowRotationPercentage: "",
            SlowRotationDays: "",
            StockLevelLow: "",
            StockLevelHigh: ""
        });
    };

    const handleSaveSettings = async () => {
        setIsStockPopupLoading(true);
        const payloadData = {
            labelBrandID: selectedLabelBrand?.value,
            configuration: [
                {
                    fastRotation: {
                        percentage: stockPopupData?.FastRotationPercentage,
                        days: stockPopupData?.FastRotationDays
                    },
                    slowRotation: {
                        percentage: stockPopupData?.SlowRotationPercentage,
                        days: stockPopupData?.SlowRotationDays
                    },
                    stockLevels: {
                        high: stockPopupData?.StockLevelLow,
                        low: stockPopupData?.StockLevelHigh
                    }
                }
            ]
        };

        Library.makePostRequest(
            "updateLabelBrandSaleSpeedConfiguration",
            payloadData,
            false,
            setIsStockPopupLoading
        ).then(res => {
            if (res?.status === 200) {
                onClickRefreshAction();
            }
        });

        handleClosePopup();
    };

    const handleStockPopupData = (fieldKey, value) => {
        setStockPopupData(prevState => {
            return {
                ...prevState,
                [fieldKey]: value
            };
        });
    };

    const areAllFormFieldsFilled = () => {
        return (
            !!stockPopupData?.FastRotationPercentage &&
            !!stockPopupData?.FastRotationDays &&
            !!stockPopupData?.SlowRotationPercentage &&
            !!stockPopupData?.SlowRotationDays &&
            !!stockPopupData?.StockLevelLow &&
            !!stockPopupData?.StockLevelHigh &&
            selectedLabelBrand
        );
    };

    const sortStores = stores => {
        return stores.sort((a, b) => {
            if (a.description === "Not affiliated" && b.description !== "Not affiliated") {
                return 1;
            }
            if (a.description !== "Not affiliated" && b.description === "Not affiliated") {
                return -1;
            }
            return 0;
        });
    };

    /* #endregion */

    // #region RENDER
    return (
        <StyledPage>
            <FullScreenOverlay show={isStockPopupVisible}>
                <DialogBoxComponent
                    heading="SALE SPEED SETTINGS"
                    loading={isStockPopupLoading}
                    useAlternativeLayout={
                        <StockPopupComponent
                            handleStockPopupData={handleStockPopupData}
                            stockPopupData={stockPopupData}
                            labelBrands={labelBrands}
                            setSelectedLabelBrand={setSelectedLabelBrand}
                            selectedLabelBrand={selectedLabelBrand}
                        />
                    }
                    cancelText="CANCEL"
                    confirmText="SAVE SETTINGS"
                    cancelState="secondary"
                    confirmState={areAllFormFieldsFilled() ? "secondary" : "disabled"}
                    onClickYes={handleSaveSettings}
                    onClickNo={handleClosePopup}
                    width="520px"
                />
            </FullScreenOverlay>

            <FullScreenLoader isLoading={loadingLabelBrandForPopup} />

            <ContainerLoader
                isLoading={!isLoadingApp && (loadingStoreFilters || listComponentIsLoading)}
                className="w-100"
                isLoadingOverlay={true}
                addBlurFilters={false}>
                <ListComponent
                    className="w-100"
                    tableClassName="expandedRowsCentered"
                    title="Products Stock"
                    columns={listColumns}
                    selectableRows={true}
                    showLoader={false}
                    onListLoading={loading => setListComponentIsLoading(loading)}
                    api={{
                        useCommonPostRequest: true,
                        commonContext: "Stock",
                        endpoint: "getStocksData",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            limit: listNumRows,
                            ...filters
                        },
                        onSuccess: mapListData
                    }}
                    pageLimit={listNumRows}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        includeActions: true,
                        editIsVisible: false,
                        suspendIsVisible: false,
                        removeIsVisible: false,
                        extraActions: [
                            {
                                title: "Details"
                            }
                        ]
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    expandableRows={true}
                    customExpandedComponent={item => {
                        return StockExpandedComponent({
                            data: item?.data
                        });
                    }}
                    requestEnabled={requestEnabled}
                    onChangeRowsPerPage={rowsPerPage => setListNumRows(rowsPerPage)}
                />
            </ContainerLoader>
        </StyledPage>
    );
    // #endregion
}

// #region SMALL COMPONENTS
function setupHeaders({
    layoutCtx,
    onClickRefreshAction,
    navigations,
    allStoresList,
    allLabelBrandsList,
    onLabelBrandChange,
    onFiltersLoaded,
    setIsStockPopupVisible,
    onStoresChange
}) {
    const createMode = navigations?.navigationAccesses?.fulfillmentCenters?.createModeOnly;

    const {
        setLayoutType,
        setControlBarLeftBtns,
        setControlBarCenterBtns,
        setControlBarRightBtns,
        setFilterHeaderProperties
    } = layoutCtx;
    if (!setFilterHeaderProperties) return;

    setControlBarLeftBtns([{ to: "#", disabled: true, title: "Products", icon: "fa-magnifying-glass" }]);
    setControlBarCenterBtns([{ to: "/stock", active: true, disabled: createMode, title: "STOCK" }]);
    setControlBarRightBtns([{ to: "#", active: true, disabled: false, title: "View", icon: "eye" }]);

    setLayoutType("List");

    setFilterHeaderProperties({
        customContentHeader: (
            <MainWindowHeader
                key={window.location.pathname}
                visible={true}
                layoutTypeOptions={{
                    gridEnabled: false,
                    listEnabled: true
                }}
                onRefreshClick={onClickRefreshAction}
                firstOrganize={{
                    visible: true,
                    dropdownData: allLabelBrandsList,
                    onDropdownChange: onLabelBrandChange,
                    disabled: false,
                    type: "singleSelect"
                }}
                secondOrganize={{
                    visible: true,
                    dropdownData: allStoresList,
                    onDropdownChange: onStoresChange,
                    type: "multiSelect",

                    multidropdownOptions: {
                        useDoneButton: true,
                        useSelectAll: true,
                        selectAllText: "Select all stores",
                        selectAllPlaceholderFunction: arrayItems => {
                            if (!arrayItems?.length) return "No stores";

                            const nonLockedStores = allStoresList?.filter(item => !item?.locked);
                            const areAllSelected = arrayItems?.length === nonLockedStores?.length;
                            if (areAllSelected) return `All stores (${nonLockedStores?.length})`;

                            if (arrayItems?.length < 3) {
                                const firstTwoItems = arrayItems
                                    ?.slice(0, 2)
                                    ?.map(item => item?.name)
                                    ?.join(", ");

                                return firstTwoItems;
                            }

                            return `${arrayItems?.length} selected`;
                        }
                    }
                }}
                organizeButton={{
                    enabled: true,
                    layoutComponent: <OrganizeBtnMenuComponent onStockClick={() => setIsStockPopupVisible(true)} />
                }}
                pageFilters={{
                    useFilters: true,
                    filters: {
                        useLabelBrands: true,
                        useConnectedStores: true
                    },
                    onFiltersLoaded: onFiltersLoaded
                }}
            />
        )
    });
}

const getColorSquare = color => {
    let hexColor = color?.colorHexCode ?? "#000";
    const colorName = color?.colorName ?? "--";
    if (!hexColor?.includes("#")) hexColor = "#" + hexColor;

    return (
        <Tooltip title={colorName} placement="top" color="rgba(255, 255, 255, 0.1)">
            <div
                alt="colors"
                style={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: formatColorHex(hexColor),
                    border: `1px solid ${formatColorHex(hexColor)}`,
                    borderRadius: "2px",
                    marginRight: "4px"
                }}
            />
        </Tooltip>
    );
};

// eslint-disable-next-line no-unused-vars
const getMultipleColors = colors => {
    //filter colors only first 3 max
    const colorsArray = colors.slice(0, 3);
    const colorsExtraCount = colors.length - 3;
    const extraColorsNames = colors.slice(3).map(color => color?.colorName);

    const resultDiv = (
        <div style={{ display: "flex", alignItems: "start" }}>
            {colorsArray.map((color, index) => {
                return getColorSquare(color);
            })}
            {colors?.length > 3 && (
                <Tooltip
                    title={`+${colorsExtraCount} Colors: ${extraColorsNames.join(", ")}`}
                    placement="top"
                    color="rgba(255, 255, 255, 0.1)">
                    <div
                        style={{
                            width: "16px",
                            height: "16px",
                            backgroundColor: "var(--backgrounds-lines-n-500, #141922)",
                            border: "1px solid var(--backgrounds-lines-n-500, #141922)",
                            borderRadius: "2px",
                            marginRight: "4px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "8px"
                        }}>
                        +{colorsExtraCount}
                    </div>
                </Tooltip>
            )}
        </div>
    );

    return resultDiv;
};

const StockRotation = ({ stock }) => {
    const SVGIcon = stock === "Fast" ? ZapFastIcon : stock === "Slow" ? TurtleIcon : LightningIcon;
    const SVGColor = stock === "Fast" ? "#0b0" : stock === "Slow" ? "#FF3636" : "#F90";

    return (
        <StockRotationStyled className="stock-rotation-container">
            <div className="frame-1000011361">
                <SVGIcon className="zap-fast" style={{ color: SVGColor }} />
                <div className="frame-111">
                    <div className="fast">{stock}</div>
                </div>
            </div>

            <div class="frame-1000011403">
                <div class="frame-313375  cursor-not-allowed">
                    <div class="reorders text-inactive">Reorders</div>
                    <div class="ellipse-12 d-none"></div>
                </div>
            </div>
        </StockRotationStyled>
    );
};

const ProductWithImage = ({ name, image }) => {
    if (image?.length > 0) {
        if (!image?.startsWith("http")) {
            image = `https://${image}`;
        }
    }

    return (
        <StyledPage>
            <div className="frame-1000011360">
                <div className="frame-1000011324">
                    {image ? (
                        <Tooltip
                            title={<ImageTooltipInner image={image} />}
                            placement="right"
                            color="rgba(255, 255, 255, 0.1)"
                            //trigger={["click"]}
                            style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}>
                            <img className="image" src={image} alt="" />
                        </Tooltip>
                    ) : (
                        <div className="image" style={{ backgroundColor: "#4f535a" }} />
                    )}
                </div>
                <div className="frame-111">
                    <Tooltip title={name} placement="top" color="rgba(255, 255, 255, 0.1)">
                        <div className="regulator-parka text-one-line">{name}</div>
                    </Tooltip>
                </div>
            </div>
        </StyledPage>
    );
};

const ImageTooltipInner = ({ image }) => {
    return (
        <StyledPage className="frame-1000011326">
            <a href={image} target="_blank" rel="noreferrer">
                <img className="rectangle-256" src={image} alt="product" />
            </a>
        </StyledPage>
    );
};

const OrganizeBtnMenuComponent = props => {
    const { onStockClick } = props;

    return (
        <OrganizeBtnMenu
            items={[
                {
                    name: "Set stock rotation criteria",
                    value: "stock"
                }
            ]}
            onItemClick={id => {
                if (id === "stock") {
                    onStockClick();
                }
            }}
        />
    );
};
// #endregion

// #region POPUP COMPONENTS
const StockPopupComponent = props => {
    const { handleStockPopupData, stockPopupData, labelBrands, setSelectedLabelBrand, selectedLabelBrand } = props;

    const dropdownData = labelBrands;

    return (
        <StockPopupStyle>
            <div className="popup-title">Set sale speed & stock rules</div>
            <div className="popup-message">Set sale speed limits and stock alerts to track inventory efficiently.</div>
            <div style={{ display: "flex", flexDirection: "column", gap: "8px", margin: "32px 0px 32px 0px" }}>
                <div className="popup-secondary-title-header">
                    <div className="popup-secondary-title">Define thresholds</div>
                    <FormField
                        type="dropdown"
                        requiredType="mandatory"
                        dropdownData={dropdownData}
                        varName="Label Brand"
                        style={{ width: "240px", textAlign: "left" }}
                        onDropdownChange={selections => {
                            setSelectedLabelBrand(selections);
                        }}
                        value={selectedLabelBrand?.name}
                        isDropdownSearchable={true}
                        placeholder="Select Label Brand"
                    />
                </div>

                <div className="box-container">
                    <div className="box-container-title" style={{ color: "var(--text-icons-green-500, #0b0)" }}>
                        <ZapFastIcon /> FAST ROTATION IF:
                    </div>
                    <div className="input-line">
                        <div className="input-label">More than </div>
                        <StockPopupInput
                            disabled={selectedLabelBrand ? false : true}
                            placeholder="e.g. 70"
                            rightContent="%"
                            value={stockPopupData?.FastRotationPercentage}
                            onChange={e => handleStockPopupData("FastRotationPercentage", e.target.value)}
                        />
                        <div className="input-label-2">of an item stock is sold,</div>
                    </div>
                    <div className="input-line">
                        <div className="input-label">in less than</div>
                        <StockPopupInput
                            disabled={selectedLabelBrand ? false : true}
                            placeholder="e.g. 3"
                            value={stockPopupData?.FastRotationDays}
                            onChange={e => handleStockPopupData("FastRotationDays", e.target.value)}
                        />
                        <div className="input-label-2">days after arrival.</div>
                    </div>
                </div>

                <div className="box-container">
                    <div className="box-container-title" style={{ color: "var(--text-icons-red-500, #FF3636)" }}>
                        <TurtleIcon /> SLOW ROTATION IF:
                    </div>
                    <div className="input-line">
                        <div className="input-label">If less than </div>
                        <StockPopupInput
                            disabled={selectedLabelBrand ? false : true}
                            placeholder="e.g. 10"
                            rightContent="%"
                            value={stockPopupData?.SlowRotationPercentage}
                            onChange={e => handleStockPopupData("SlowRotationPercentage", e.target.value)}
                        />
                        <div className="input-label-2">of an item stock is sold,</div>
                    </div>
                    <div className="input-line">
                        <div className="input-label">Within</div>
                        <StockPopupInput
                            disabled={selectedLabelBrand ? false : true}
                            placeholder="e.g. 3"
                            value={stockPopupData?.SlowRotationDays}
                            onChange={e => handleStockPopupData("SlowRotationDays", e.target.value)}
                        />
                        <div className="input-label-2">days after arrival.</div>
                    </div>
                </div>
                <div className="divider" />

                <div className="box-container">
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div className="box-container-title" style={{ color: "var(--text-icons-orange-500, #F90)" }}>
                            <LightningIcon /> MODERATE
                        </div>
                        <div style={{ width: "100%", textAlign: "right", fontStyle: "italic" }}>
                            (in between above set limit)
                        </div>
                    </div>
                </div>
                <div className="divider" />

                <div className="popup-secondary-title">Stock level criteria</div>

                <div className="box-container">
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div className="box-container-title" style={{ color: "var(--text-icons-green-500, #0b0)" }}>
                            <div className="box-container-tag" style={{ background: "#0b0" }}>
                                H
                            </div>{" "}
                            HIGH
                        </div>
                        <div style={{ width: "100%", textAlign: "right", fontStyle: "italic" }}>
                            <StockPopupInput
                                disabled={selectedLabelBrand ? false : true}
                                rightContent="%"
                                placeholder="e.g. 70"
                                style={{ marginLeft: "auto", marginRight: "0px" }}
                                value={stockPopupData?.StockLevelHigh}
                                onChange={e => handleStockPopupData("StockLevelHigh", e.target.value)}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div className="box-container-title" style={{ color: "var(--text-icons-red-500, #FF3636)" }}>
                            <div className="box-container-tag" style={{ background: "#FF3636" }}>
                                L
                            </div>{" "}
                            LOW
                        </div>
                        <div style={{ width: "100%", textAlign: "right", fontStyle: "italic" }}>
                            <StockPopupInput
                                disabled={selectedLabelBrand ? false : true}
                                rightContent="%"
                                placeholder="e.g. 10"
                                style={{ marginLeft: "auto", marginRight: "0px" }}
                                value={stockPopupData?.StockLevelLow}
                                onChange={e => handleStockPopupData("StockLevelLow", e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="divider" />

                <div className="box-container">
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div className="box-container-title" style={{ color: "var(--text-icons-orange-500, #F90)" }}>
                            <div className="box-container-tag" style={{ background: "#F90" }}>
                                M
                            </div>{" "}
                            MODERATE
                        </div>
                        <div style={{ width: "100%", textAlign: "right", fontStyle: "italic" }}>
                            (in between above set limit)
                        </div>
                    </div>
                </div>
            </div>
        </StockPopupStyle>
    );
};

const StockPopupInput = props => {
    const { value, onChange, style, className, placeholder, rightContent, disabled } = props;

    return (
        <StockPopupInputStyle style={style} className={className}>
            <input
                type="number"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={disabled ? "disabled" : ""}
            />
            {rightContent}
        </StockPopupInputStyle>
    );
};
// #endregion
