import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import FormContainer from "./FormContainer";
import XClose0 from "../../../assets/icons/x-close0.svg";
import Upload from "../../../assets/icons/upload-010.svg";
import ImageUploading from "./ImageUploading";
import SimpleGridContainer from "./SimpleGridContainer";

/* #region SCOPED CSS */
const UploadFieldStyles = styled.div`
    &.UploadField {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }

    .UploadFieldTitleContainer {
        display: flex;
        gap: 4px;
        align-items: center;
        margin-bottom: 5px;
    }

    .UploadFieldTitleContainer .Icon {
        font-size: 9px;
        color: var(--text-icons-gray-200);
        font-weight: 500;
        position: relative;
        top: -1px;
    }

    .UploadFieldTitle {
        font-size: 12px;
        color: var(--text-icons-gray-200);
        font-weight: 500;
    }

    .UploadFieldImage {
        background-color: #10141b;
        width: 100%;
        height: 259px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        position: relative;
    }

    .frame-197 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const DefaultNoImageStyles = styled.div`
    &.frame-288 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        width: 100%;
        height: 100%;
    }
    .frame-199 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        text-align: center;
    }
    .frame-197 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .browse-files-from-device {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-size: var(--subtitle-2-font-size, 14px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-size: 12px;
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
`;
const StyledImagePreview = styled.div`
    &.upload-card,
    &.upload-card * {
        box-sizing: border-box;
    }
    &.upload-card {
        display: flex;
        flex-direction: column;
        gap: 4px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-51 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .store-facade {
        color: var(--text-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .frame-197 {
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-lines-n-500, #141922);
        border-width: 0px 0px 0px 5px;
        display: flex;
        flex-direction: column;
        gap: 9px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 100%;
        position: relative;
    }
    .x-close-container {
        width: 6.667px;
        height: 6.667px;
        flex-shrink: 0;
        position: static;
        z-index: 8888;
    }
    .ellipse-5 {
        background: var(--text-icons-red-500, #ff3636);
        border-radius: 50%;
        width: 14px;
        height: 14px;
        position: absolute;
        //left: 766px;
        right: 12px;
        top: 12px;
    }
    .x-close {
        width: 12px;
        height: 12px;
        position: absolute;
        //left: 770px;
        right: 13px;
        top: 13px;
        overflow: visible;
        z-index: 8888;
        cursor: pointer;
    }
`;
/* #endregion */

/**
 * A card component that allows the user to upload multiple logos.
 *
 * @param {Object} props - The component props.
 * @param {Object[]} [props.logoFields=[]] - The logo fields to render.
 * @param {string} [props.cardTitle=""] - The title of the card.
 * @param {string} [props.cardClassName=""] - Additional CSS class names to apply to the card.
 * @param {boolean} [props.showDefaultRightLegends=true] - Whether to show the default right legends: (recommended, mandatory, locked).
 * @param {Function} [props.onLogoFieldsChanged] - The function to call when the logo fields change.
 * @param {Object} [props.afterTitleComponent] - The component to render after the title.
 *
 * logoFields Example = [
 * {
 *     "label": "Main logo",
 *     "varName": "storeInterior",
 *     "requiredType": "mandatory",
 *     "imageData": {
 *         "url": "https://via.placeholder.com/150" // this is the image url that it received from the API
 *         or
 *         "base64": "data:image;base64,base64string" // this is the structure when it will be uploaded de base64 + name
 *         "name": "storeInterior.png"
 *     },
 *     "gridColumnSize": 2
 * }
 * @returns {JSX.Element} The rendered card component.
 */
const UploadLogosCardStandard = forwardRef((props, ref) => {
    const {
        logoFields = [],
        cardTitle = "",
        cardClassName = "",
        showDefaultRightLegends = true,
        onLogoFieldsChanged,
        afterTitleComponent
    } = props;

    /* #region VARS */
    const [logoFieldsData, setLogoFieldsData] = useState([]);
    const [changedFromOutside, setChangedFromOutside] = useState(false);
    const [highlightNotValidFields, setHighlightNotValidFields] = useState(false);
    /* #endregion */

    /* #region METHODS */
    const handleOnImageUpload = (type, data) => {
        const newLogoFieldsData = [...logoFieldsData];
        const index = newLogoFieldsData.findIndex(field => field.varName === type);
        newLogoFieldsData[index].imageData = data;
        setLogoFieldsData(newLogoFieldsData);
    };

    const allFieldsAreValid = highlightValidation => {
        let allFieldsValid = true;
        for (let i = 0; i < logoFieldsData.length; i++) {
            const field = logoFieldsData[i];
            if (field.requiredType === "mandatory" && !field.imageData) {
                if (highlightValidation) {
                    setHighlightNotValidFields(true);
                }
                allFieldsValid = false;
                break;
            }
        }
        return allFieldsValid;
    };

    const getAllFieldValues = () => {
        const fieldValues = {};
        for (let i = 0; i < logoFieldsData.length; i++) {
            const field = logoFieldsData[i];
            fieldValues[field.varName] = field?.imageData;
        }
        return fieldValues;
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!logoFields || logoFields.length === 0) return;
        setChangedFromOutside(true);
        setLogoFieldsData(logoFields);
    }, [logoFields]);

    useEffect(() => {
        if (!logoFieldsData || logoFieldsData.length === 0) return;

        if (changedFromOutside) {
            setChangedFromOutside(false);
            return;
        }

        if (onLogoFieldsChanged) {
            const imageFields = logoFieldsData.map(field => ({
                varName: field.varName,
                imageData: field?.imageData
            }));
            onLogoFieldsChanged(imageFields);
        }
    }, [logoFieldsData]);

    /* #endregion */

    useImperativeHandle(ref, () => ({
        getAllFieldValues,
        allFieldsAreValid
    }));

    return (
        <div className={cardClassName}>
            <FormContainer
                title={cardTitle}
                showDefaultRightLegends={showDefaultRightLegends}
                numOfFieldsPerLine={4}
                afterTitleComponent={afterTitleComponent}>
                <SimpleGridContainer gap="16px" numOfFieldsPerLine={4}>
                    {!logoFieldsData || logoFieldsData.length === 0 ? (
                        <div>No logo fields provided!</div>
                    ) : (
                        logoFieldsData.map((field, index) => {
                            return (
                                <UploadField
                                    key={index}
                                    handleOnImageUpload={data => handleOnImageUpload(field.varName, data)}
                                    fieldData={field}
                                    setImageData={data => {
                                        const newLogoFieldsData = [...logoFieldsData];
                                        newLogoFieldsData[index].imageData = data;
                                        setLogoFieldsData(newLogoFieldsData);
                                    }}
                                    highlightNotValidFields={highlightNotValidFields}
                                    style={{
                                        gridColumn: field.gridColumnSize ? `span ${field.gridColumnSize}` : "span 1"
                                    }}
                                />
                            );
                        })
                    )}
                </SimpleGridContainer>
            </FormContainer>
        </div>
    );
});

/* #region SMALL COMPONENTS */
const UploadField = ({ handleOnImageUpload, fieldData, setImageData, highlightNotValidFields, style = {} }) => {
    /* #region COMPUTED VARS */
    const { imageData, requiredType, label } = fieldData;
    const isLocked = requiredType === "locked";
    /* #endregion */

    /* #region METHODS */
    const hasImage = () => {
        return imageData && (imageData?.url || imageData?.base64) ? true : false;
    };

    const isValid = () => {
        return (requiredType === "mandatory" && hasImage()) || requiredType !== "mandatory";
    };

    const getTextColor = () => {
        return highlightNotValidFields && !isValid()
            ? "var(--text-icons-red-500)"
            : isLocked
            ? "var(--text-icons-gray-300)"
            : "var(--text-icons-gray-200)";
    };

    const getFieldColor = () => {
        if (highlightNotValidFields && !isValid()) return "5px solid var(--text-icons-red-500)";
        if (isLocked) return "5px solid var(--text-icons-gray-300)";
        if (hasImage() || requiredType === "simple") return "5px solid var(--backgrounds-lines-n-500)";

        return requiredType === "mandatory"
            ? "5px solid var(--text-icons-orange-500)"
            : requiredType === "recommended"
            ? "5px solid var(--text-icons-blue-500)"
            : "5px solid var(--text-icons-gray-300)";
    };

    const removeImage = () => {
        if (isLocked) return;
        setImageData(null);

        handleOnImageUpload(null);
    };
    /* #endregion */

    return (
        <UploadFieldStyles className="UploadField" style={{ ...style }}>
            <div className="UploadFieldTitleContainer pl-5px pr-5px">
                <i className="Icon fas fa-info-circle" aria-hidden="true" style={{ color: getTextColor() }}></i>
                <div className="UploadFieldTitle" style={{ color: getTextColor() }}>
                    {label}
                </div>
            </div>
            <div className="UploadFieldImage" style={{ borderLeft: getFieldColor() }}>
                {imageData ? (
                    <ImagePreview
                        imageBase64={imageData?.base64}
                        imageURL={imageData?.url}
                        onCloseClicked={removeImage}
                        imgHasPadding={true}
                        objectFit="contain"
                    />
                ) : (
                    <div className="frame-197">
                        {requiredType !== "locked" ? (
                            <ImageUploading onImageUpload={handleOnImageUpload}>
                                <DefaultNoImageBrowse logoScale="16:9" />
                            </ImageUploading>
                        ) : (
                            <div style={{ width: "100%", height: "100%", cursor: "not-allowed" }}>
                                <DefaultNoImageBrowse logoScale="16:9" />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </UploadFieldStyles>
    );
};

const DefaultNoImageBrowse = ({ logoScale = "16:9", maxSize = "300kb" }) => {
    return (
        <DefaultNoImageStyles className="frame-288">
            <img className="upload-012" src={Upload} alt="" />
            <div className="frame-199">
                <div className="browse-files-from-device">Browse files from device</div>
                <div className="logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb">
                    Max file size accepted is {maxSize}
                </div>
            </div>
        </DefaultNoImageStyles>
    );
};

const ImagePreview = ({ imageURL, onCloseClicked, imageBase64, imgHasPadding = false, objectFit = "cover" }) => {
    /* #region COMPUTED VARS */
    let imageSource = imageURL;
    if (!imageURL && imageBase64)
        imageSource = imageBase64.startsWith("data:") ? imageBase64 : `data:image;base64,${imageBase64}`;
    /* #endregion */

    return (
        <StyledImagePreview className="upload-card" style={{ width: "100%", height: "100%", position: "relative" }}>
            <img
                className="frame-197"
                style={{
                    //background: `url(${imageSource}) center / cover no-repeat`,
                    border: "0px",
                    minWidth: "0px",
                    maxWidth: "100%",
                    objectFit: objectFit,
                    padding: imgHasPadding ? "30px" : "0px"
                }}
                alt=""
                src={imageSource}></img>
            {onCloseClicked && (
                <div className="x-close-container">
                    <div className="ellipse-5"></div>
                    <img className="x-close" src={XClose0} alt="" onClick={onCloseClicked} />
                </div>
            )}
        </StyledImagePreview>
    );
};
/* #endregion */

export default UploadLogosCardStandard;
export { UploadField, DefaultNoImageBrowse, ImagePreview };
