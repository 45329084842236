import React, { useEffect } from "react";
import AppContext from "../../../AppContext";
import ProductCardSidePanelUI from "./ProductCardSidePanelUI";
import * as Library from "../../../utils/Library";

/**
 * Renders a side panel for a product card.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.productID - The product ID from the sidePanelProducts state in AppContext.
 * @param {Object} props.number - The BO tag number.
 * @param {Object} props.selectedColor - The selected color.
 * @param {Object} props.selectedSize - The selected size.
 * @param {boolean} props.isSelected - The flag to indicate if the product is selected.
 * @returns {JSX.Element} The rendered side panel component.
 */
export default function SidePanelLinkingCard(props) {
    /* #region USE STATES */
    const [productID, setProductID] = React.useState(undefined);
    const [product, setProduct] = React.useState(undefined);
    const [isSelected, setIsSelected] = React.useState(false);

    const {
        setSidePanelBOtagProductLink,
        sidePanelProducts,
        sidePanelSelectedProductID,
        setSidePanelSelectedProductID,
        sidePanelSelectedProductNumber,
        setSidePanelSelectedProductNumber
    } = React.useContext(AppContext);
    /* #endregion */

    /* #region COMPUTED PRODUCT VALUES */
    //const prodID = product?.productID;
    const prodName = product?.productName;
    const prodCategory = product?.category;
    const colors = product?.gtins?.map(gtin => gtin.colorCode);
    const uniqueColors = [...new Set(colors)];
    const colorNames = product?.gtins?.map(gtin => gtin.colorName);
    const uniqueColorNames = [...new Set(colorNames)];
    const sizes = product?.gtins?.map(gtin => gtin.size);
    const uniqueSizes = [...new Set(sizes)];
    const images = product?.gtins?.map(gtin => gtin.images?.[0]?.URL);
    /* #endregion */

    /* #region USE EFFECTS */
    // UPDATES THE product AND productID INTERNAL STATES, when the productID prop changes (OK)
    useEffect(() => {
        if (!props?.productID) return;
        const tmpProduct = sidePanelProducts?.find(product => product.productID === props?.productID);
        setProduct(tmpProduct ?? {});
        setProductID(props?.productID);
    }, [props?.productID]);

    // UPDATES THE product INTERNAL STATE, when the sidePanelProducts state changes
    useEffect(() => {
        if (!productID) return;
        const tmpProduct = sidePanelProducts.find(product => product.productID === productID);
        setProduct(tmpProduct);
    }, [sidePanelProducts]);

    // HANDLES LINK ENABLED STATE FOR THE LINK BUTTON, when the selectedColor or selectedSize changes
    useEffect(() => {
        if (props?.selectedColor) setProduct({ ...product, selectedColor: props?.selectedColor });
        if (props?.selectedSize) setProduct({ ...product, selectedSize: props?.selectedSize });
    }, [props?.selectedColor, props?.selectedSize]);

    // HANDLES SET TO UNSELECTED, when the sidePanelSelectedProductID is different than the productID
    useEffect(() => {
        if (!isSelected) return;
        if (sidePanelSelectedProductID !== productID) {
            //When we are selecting a different product color or size, then we have to unselect the previous selections
            handleUnselectedChoices();
        }
    }, [sidePanelSelectedProductID]);

    // HANDLES SET TO UNSELECTED, when the sidePanelSelectedProductNumber is changed to undefined
    useEffect(() => {
        if (sidePanelSelectedProductNumber) return;
        if (isSelected && sidePanelSelectedProductNumber === undefined) {
            handleSetUnselected();
        }
    }, [sidePanelSelectedProductNumber]);
    /* #endregion */

    /* #region METHODS */
    const handleColorChange = color => {
        if (!sidePanelSelectedProductNumber) return;
        handleSetSelected();

        if (product.selectedColor === color) {
            setProduct({ ...product, selectedColor: "" });
            return;
        }

        setProduct({ ...product, selectedColor: color });
    };

    const handleSizeChange = size => {
        if (!sidePanelSelectedProductNumber) return;
        handleSetSelected();

        if (product.selectedSize === size) {
            setProduct({ ...product, selectedSize: "" });
            return;
        }

        setProduct({ ...product, selectedSize: size });
    };

    const handleSetSelected = () => {
        setIsSelected(true);
        setSidePanelSelectedProductID(productID);
    };

    const handleSetUnselected = () => {
        setIsSelected(false);
        setProduct({ ...product, selectedColor: "", selectedSize: "" });
        setSidePanelSelectedProductID(undefined);
        setSidePanelSelectedProductNumber(undefined);
    };

    const handleUnselectedChoices = () => {
        setIsSelected(false);
        setProduct({ ...product, selectedColor: "", selectedSize: "" });
    };

    const handleTagLink = () => {
        if (!sidePanelSelectedProductNumber || !product?.selectedColor || !product?.selectedSize) return;

        const barcode = product?.gtins?.find(
            gtin => gtin?.colorCode === product?.selectedColor && gtin?.size === product?.selectedSize
        )?.barcode;

        if (!barcode) {
            Library.showToastMessage({
                type: "error",
                title: "Product not found",
                message: "We didn't found a Barcode for the selected color and size, please try another combination."
            });
            return;
        }

        const productWithTag = {
            ...product,
            tagNumber: sidePanelSelectedProductNumber,
            category: product?.category,
            productName: product?.productName,
            colors: uniqueColors,
            colorNames: uniqueColorNames,
            sizes: uniqueSizes,
            image: images?.[0],
            GTINbarcode: barcode
        };

        setSidePanelBOtagProductLink(productWithTag);

        // Reset the product color and size
        handleSetUnselected();
    };

    const allowChanges = () => {
        return isSelected;
    };
    /* #endregion */

    return (
        <ProductCardSidePanelUI
            number={sidePanelSelectedProductNumber}
            image={images?.[0]}
            category={prodCategory}
            productName={prodName}
            colors={uniqueColors}
            colorNames={uniqueColorNames}
            selectedColor={product?.selectedColor}
            onColorChange={handleColorChange}
            sizes={uniqueSizes}
            selectedSize={product?.selectedSize}
            onSizeChange={handleSizeChange}
            buttonText="Link"
            onButtonClick={handleTagLink}
            isCompletedCard={false}
            allowChanges={allowChanges()}
            productData={product}
        />
    );
}
