import React from "react";
import styled from "styled-components";
import ContainerLoader from "../loaders/ContainerLoader";
import Bell02 from "@untitled-ui/icons-react/build/esm/Bell02";

const StyledPage = styled.div`
    & {
        display: flex;
        width: 320px;
        padding: 12px 8px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;

        border-radius: 4px;
        background: var(--backgrounds-lines-n-500, #141922);

        /* drop-shadow-100 */
        box-shadow: 0px 0px 10px 0px #111620;
    }

    .NotificationItemsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
    }

    .Box {
        width: 26px;
        height: 16px;
        position: relative;
    }

    .Icon {
        width: 13.33px;
        height: 12px;
        left: 1.33px;
        top: 2px;
        position: absolute;
        border: 1px #d1d1d1 solid;
    }

    .NotificationItem {
        align-self: stretch;
        padding: 8px;
        border-radius: 4px;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        display: inline-flex;
        border-radius: 0;

        border-bottom: 1px solid rgba(60, 72, 90, 0.26);
    }

    .NotificationItem:last-child {
        border-bottom: none;
    }

    .NotificationItemTexts {
        flex: 1 1 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        display: inline-flex;
    }

    .TitleText {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .DescriptionText {
        color: var(--text-icons-gray-200, #7c868b);
        text-align: right;
        width: 65px;
        /* Label/XS-Lowercase */
        font-family: Roboto;
        font-size: 0.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .NotReadedEllipse {
        width: 8px;
        height: 8px;
        background: #ff9900;
        border-radius: 9999px;
        position: absolute;
        left: 13px;
        top: -2px;
    }

    .NotificationItem:hover {
        background: #1e2631;
    }

    .BtnLargeSolid {
        align-self: stretch;
        height: 35px;
        padding: 10px;
        background: #1e2631;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        display: inline-flex;
        cursor: pointer;
    }

    .Button {
        color: #d1d1d1;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.12px;
        word-wrap: break-word;
    }
    .text-gray-100 {
        position: absolute;
    }
`;

/**
 * Component for displaying a dropdown of notifications.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.NotificationItems - The array of notification items to display.
 * @param {String} props.NotificationItems.title - The title of the notification.
 * @param {String} props.NotificationItems.description - The description of the notification.
 * @param {Boolean} props.NotificationItems.unread - The flag to indicate if the notification is unread.
 * @param {Boolean} props.isLoadingNotifications - The flag to indicate if the notifications are loading.
 * @param {Object} props.style - The inline style object for the component.
 * @returns {JSX.Element} - The rendered component.
 */
export default function NotificationsDropdown(props) {
    const { NotificationItems } = props;

    return (
        <StyledPage className={props?.className ?? ""} style={props?.style}>
            {props?.isLoadingNotifications ? (
                <ContainerLoader isLoading={props?.isLoadingNotifications} />
            ) : (
                <div className="NotificationItemsContainer">
                    {(!NotificationItems || NotificationItems?.length === 0) && (
                        <div className="NotificationItem">
                            <div className="NotificationItemTexts">
                                <div className="TitleText">No new notifications</div>
                            </div>
                        </div>
                    )}
                    {NotificationItems?.map((item, index) => (
                        <div key={index} className="NotificationItem hover-not-allowed">
                            <div className="Box">
                                <Bell02 className="text-gray-100" height="16px" />
                                <div className="NotReadedEllipse"></div>
                            </div>
                            <div className="NotificationItemTexts">
                                <div className="TitleText">{item?.title}</div>
                                <div className="DescriptionText">{item?.description}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div
                className="BtnLargeSolid"
                onClick={() => {
                    props?.setShowNotificationPanel(true);
                    props?.setShowNotificationDropdown(false);
                    props?.getUnreadNotifications();
                }}>
                <div className="Button">Show all notifications</div>
            </div>
        </StyledPage>
    );
}
