import React, { useEffect, useState } from "react";
import InfinityGridOrdersCards from "../../common/cardContainers/InfinityGridOrdersCards";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import moment from "moment/moment";
import OrderPreview from "../../pages/orders/OrderPreview";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import { useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { defaultStatusColors } from "../../../utils/CardUtils";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
/* #endregion */

export default function OrdersPage() {
    document.title = `${APP_TITLE} - Orders`;

    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");

    const [listNumRows, setListNumRows] = useState(20);

    const isMobile = window.innerWidth <= 992;
    const [orderDetails, setOrderDetails] = useState();
    const [shorOrderPreview, setShowOrderPreview] = useState(false);

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isMobile ? "85%" : "calc(100% - 440px)",
        bgcolor: "#1C2530",
        border: "2px solid #000",
        boxShadow: 24,
        height: "90%",
        p: 4,
        overflow: "scroll",
        padding: "0px 32px"
    };
    /* #endregion */

    /* #region METHODS */
    const handleShowOrderPreview = item => {
        setOrderDetails(item);
        setShowOrderPreview(true);
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props, navigations);
    }, [props?.layoutType]);
    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "ID",
            selector: row => row?.botagID ?? "--"
        },
        {
            name: "Order nr",
            selector: row => row?.orderNR ?? "--"
        },
        {
            name: "From brand",
            selector: row => row?.fromBrand ?? "--"
        },
        {
            name: "To store",
            selector: row => row?.toStore ?? "--"
        },
        {
            name: "Qty",
            selector: row => row?.quantity ?? "--"
        },
        {
            name: "Total",
            selector: row => row?.totalPrice ?? "--"
        },
        {
            name: "Season",
            selector: row => row?.season ?? "--"
        },
        {
            name: "Order date",
            selector: row => formatDefaultDateTime(row?.orderDate)
        },
        {
            name: "Delivery window",
            selector: row => row?.deliveryWindow ?? "--"
        }
    ];
    /* #endregion */

    return (
        <StyledPage>
            {viewLayoutStyle === "Grid" && (
                <InfinityGridOrdersCards
                    minColumnWidth="238px"
                    limit="12"
                    primaryButton={{
                        title: "Preview",
                        onClick: item => {
                            handleShowOrderPreview(item);
                        }
                    }}
                />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="Orders"
                    columns={listColumns}
                    api={{
                        endpoint: "getOrdersData",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            limit: listNumRows
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={listNumRows}
                    currentPage={1}
                    queryKey={"OrdersList"}
                    actions={{
                        includeActions: true,
                        editIsVisible: false,
                        suspendIsVisible: false,
                        removeIsVisible: false,
                        extraActions: [
                            {
                                title: "Preview",
                                onClick: item => {
                                    handleShowOrderPreview(item);
                                }
                            }
                        ]
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    onChangeRowsPerPage={rowsPerPage => setListNumRows(rowsPerPage)}
                />
            )}

            <Modal
                open={shorOrderPreview}
                onClose={() => setShowOrderPreview(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={modalStyle}>
                    <OrderPreview onCloseClick={() => setShowOrderPreview(false)} orderDetails={orderDetails} />
                </Box>
            </Modal>
        </StyledPage>
    );
}

function setupHeaders(props, navigations) {
    const createMode = navigations?.navigationAccesses?.orders?.createModeOnly;

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Orders", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([{ to: "/orders", active: true, disabled: false, title: "Orders" }]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: createMode, title: "View", icon: "eye" },
        { to: "#", active: false, disabled: true, title: "calendar", icon: "fa-calendar" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        visible: true,
        gridEnabled: true,
        listEnabled: true,
        onRefreshAction: undefined
    });
}
