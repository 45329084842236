import React from "react";
import styled from "styled-components";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        display: flex;
        width: 120px;
        height: 35px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;

        border-radius: 4px;
        //background: var(--Buttons-Green-Active);
        background: ${props => props.normalColor};

        // Added Border to fix small issue
        //border: 1px solid var(--Buttons-All-Platforms-Gray-300, #1e2631);
    }

    .Button-Text-Small {
        //color: var(--text-icons-gray-100, #d1d1d1);
        color: ${props => props.textColor};

        /* Button-Text-Small */
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.125px;
    }

    // HOVER CHANGES
    &.enabled:hover {
        //background: var(--Buttons-Green-Hover) !important;
        background: ${props => props.hoverColor} !important;
        cursor: pointer;
    }

    // ACTIVE CHANGES
    &.enabled.active {
        //background: var(--Buttons-Green-Active);
        background: ${props => props.activeColor};
    }

    // DISABLED CHANGES
    &.disabled {
        //background: var(--Buttons-All-Platforms-Gray-300);
        background: ${props => props.disabledColor};
        cursor: not-allowed;
    }

    &.disabled .Button-Text-Small {
        //color: var(--Buttons-All-Platforms-Gray-200, #4f5b6d);
        color: ${props => props.textDisabledColor};
        cursor: not-allowed;
    }
`;
/* #endregion */

/**
 * Button large Solid component.
 *
 * @component
 * @example
 * // Usage:
 * <BtnLargeSolid text="Click me" disabled="false" />
 *
 * @param {Object} props - The component props.
 * @param {string} props.text - The text to display on the button.
 * @param {string} props.isActive - Whether the button is active or not.
 * @param {string} props.disabled - Whether the button is disabled or not.
 * @param {string} [props.className] - Additional CSS class name for the component.
 * @param {string} [props.style] - Additional CSS style for the component.
 * @param {string} [props.textStyle] - Additional CSS style for the component.
 * @param {function} [props.onClick] - The function to call when the button is clicked.
 * @param {string} [props.normalColor] - The normal color of the button.
 * @param {string} [props.hoverColor] - The hover color of the button.
 * @param {string} [props.activeColor] - The active color of the button.
 * @param {string} [props.disabledColor] - The disabled color of the button.
 * @param {string} [props.textColor] - The text color of the button.
 * @param {string} [props.textDisabledColor] - The text disabled color of the button.
 * @param {boolean} [props.useGrayVariant] - Whether to use the gray variant or not.
 *
 * @returns {JSX.Element} The rendered BtnLargeSolid component.
 */
export default function BtnLargeSolid(props) {
    const {
        text,
        title,
        isActive,
        disabled,
        className,
        style,
        textStyle,
        onClick,
        children,
        useGrayVariant = false
    } = props;

    // Props that will change when useGrayVariant is true
    let {
        normalColor = "var(--Buttons-Green-Active)",
        hoverColor = "var(--Buttons-Green-Hover)",
        activeColor = "var(--Buttons-Green-Active)",
        disabledColor = "var(--Buttons-All-Platforms-Gray-300)",
        textColor = "var(--text-icons-gray-100, #d1d1d1)",
        textDisabledColor = "var(--Buttons-All-Platforms-Gray-200, #4f5b6d)"
    } = props;

    const title2 = text || title;

    if (useGrayVariant) {
        normalColor = "var(--backgrounds-lines-n-400, #1E2631)";
        hoverColor = "var(--backgrounds-lines-n-200, #262e3e)";
        activeColor = "var(--backgrounds-lines-n-400, #1E2631)";
        disabledColor = "var(--Buttons-All-Platforms-Gray-200, #4f5b6d)";
        textColor = "var(--text-icons-gray-100, #d1d1d1)";
        textDisabledColor = "var(--Buttons-All-Platforms-Gray-100, #d1d1d1)";
    }

    return (
        <StyledPage
            className={`btn-large ${disabled ? "disabled" : "enabled"} ${className ?? ""} ${isActive ? "active" : ""}`}
            onClick={disabled ? undefined : onClick}
            style={style}
            normalColor={normalColor}
            hoverColor={hoverColor}
            activeColor={activeColor}
            disabledColor={disabledColor}
            textColor={textColor}
            textDisabledColor={textDisabledColor}>
            <label className="Button-Text-Small text-one-line" style={textStyle}>
                {title2 || children || "--"}
            </label>
        </StyledPage>
    );
}
