import "../../../assets/css/components/UnitSold.css";
import unitSoldShirt from "../../../assets/images/unit-sold-shirt.png";
import { formatPrice } from "../../../utils/Formatter";

const UnitSold = ({ data }) => {
    /*const exampleData = [
        {
            image: unitSoldShirt,
            name: "Luan J Shirt 14826",
            price: "1500",
            sold: 78
        },
        {
            image: unitSoldShirt,
            name: "Luan J Shirt 14826",
            price: "1500",
            sold: 78
        },
        {
            image: unitSoldShirt,
            name: "Luan J Shirt 14826",
            price: "1500",
            sold: 78
        }
    ];*/

    return (
        <div className="unit-sold-container">
            <div className="header">
                <p>
                    TOP PRODUCTS BY UNITS SOLD &nbsp; <i className="fa-light fa-circle-info"></i>
                </p>
                <p>SEE MORE...</p>
            </div>
            <div className="unit-sold-main-data">
                {data?.map((item, index) => {
                    return (
                        <div key={"unit-sold-" + index} className="unit-sold-single-data">
                            <div className="unit-sold-img-portion">
                                <div className="unit-sold-img-holder">
                                    <img src={unitSoldShirt} alt="" width={"100%"} height={"100%"} />
                                </div>
                                <div className="unit-sold-single-brand-data">
                                    <p>{item?.name ?? "--"}</p>
                                    <p>{formatPrice(item?.price, "", 0, true)} kr</p>
                                </div>
                            </div>
                            <div className="unit-sold-single-last-portion">
                                <p>78</p>
                                <p>sold</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default UnitSold;
