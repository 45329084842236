import FFCImporter from "../components/pages/private/FFCImporter";
import StoresImporter from "../components/pages/private/StoresImporter";

const accessesList = ["joao@botag.no", "mikkee@botag.no", "rob@botag.no", "rob@trade2simple.com"];

const routes = [
    {
        path: "/private/storesimporter",
        element: <StoresImporter accessesList={accessesList} />
    },
    {
        path: "/private/ffcsimporter",
        element: <FFCImporter accessesList={accessesList} />
    }
];
export default routes;
