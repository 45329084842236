import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

/**
 * Renders a skeleton card grid component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.minColumnWidth] - The minimum column width of the card.
 * @param {string} props.height - The height of the card.
 * @param {string} [props.imageHeight="174px"] - The height of the card image.
 * @param {boolean} props.hasBorders - If the card has borders.
 * @returns {JSX.Element} - The rendered skeleton card grid component.
 */
export default function CardGridSkeleton(props) {
    return (
        <SkeletonTheme baseColor="#141922" highlightColor="#262E3E" borderRadius="4px">
            <div
                style={{
                    backgroundColor: "var(--backgrounds-lines-n-400)",
                    padding: "8px",
                    minWidth: props?.minColumnWidth,
                    height: props?.height,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    borderRadius: "4px",
                    border: props?.hasBorders ? "1px solid var(--backgrounds-lines-n-100)" : "none"
                }}>
                <div>
                    <Skeleton
                        style={{
                            backgroundColor: "var(--backgrounds-lines-n-500)",
                            height: props?.imageHeight || "174px"
                        }}
                        borderRadius="4px"
                    />
                </div>
                <div
                    style={{
                        height: "80px",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        alignSelf: "stretch"
                    }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                        <Skeleton
                            style={{
                                backgroundColor: "var(--backgrounds-lines-n-500)",
                                width: "130px",
                                height: "10px"
                            }}
                            borderRadius="4px"
                        />

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                                gap: "8px"
                            }}>
                            <Skeleton
                                circle
                                style={{
                                    backgroundColor: "var(--backgrounds-lines-n-500)",
                                    height: "16px",
                                    width: "16px"
                                }}
                            />

                            <Skeleton
                                circle
                                style={{
                                    backgroundColor: "var(--backgrounds-lines-n-500)",
                                    height: "16px",
                                    width: "16px"
                                }}
                            />
                            <Skeleton
                                circle
                                style={{
                                    backgroundColor: "var(--backgrounds-lines-n-500)",
                                    height: "16px",
                                    width: "16px"
                                }}
                            />
                        </div>
                    </div>

                    <div>
                        <Skeleton
                            style={{
                                backgroundColor: "var(--backgrounds-lines-n-500)",
                                height: "10px",
                                width: "52px"
                            }}
                            borderRadius="4px"
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            alignSelf: "stretch",
                            overflow: "hidden"
                        }}>
                        <Skeleton
                            style={{
                                backgroundColor: "var(--backgrounds-lines-n-500)",
                                height: "10px",
                                width: "52px"
                            }}
                            borderRadius="4px"
                        />
                        <Skeleton
                            style={{
                                backgroundColor: "var(--backgrounds-lines-n-500)",
                                height: "2px",
                                width: "240px",
                                display: "flex",
                                alignSelf: "stretch"
                            }}
                            borderRadius="4px"
                        />
                    </div>
                </div>
                <div
                    style={{
                        height: "100px",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column"
                    }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Skeleton
                            style={{
                                backgroundColor: "var(--backgrounds-lines-n-500)",
                                height: "10px",
                                width: "105px"
                            }}
                            borderRadius="4px"
                        />
                        <Skeleton
                            style={{
                                backgroundColor: "var(--backgrounds-lines-n-500)",
                                height: "10px",
                                width: "36px"
                            }}
                            borderRadius="4px"
                        />
                    </div>
                    <div>
                        <Skeleton
                            style={{
                                backgroundColor: "var(--backgrounds-lines-n-500)",
                                height: "10px",
                                width: "74px"
                            }}
                            borderRadius="4px"
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Skeleton
                            style={{
                                backgroundColor: "var(--backgrounds-lines-n-500)",
                                height: "10px",
                                width: "119px"
                            }}
                            borderRadius="4px"
                        />
                        <Skeleton
                            style={{
                                backgroundColor: "var(--backgrounds-lines-n-500)",
                                height: "10px",
                                width: "36px"
                            }}
                            borderRadius="4px"
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Skeleton
                            style={{
                                backgroundColor: "var(--backgrounds-lines-n-500)",
                                height: "10px",
                                width: "83px"
                            }}
                            borderRadius="4px"
                        />
                        <Skeleton
                            style={{
                                backgroundColor: "var(--backgrounds-lines-n-500)",
                                height: "10px",
                                width: "36px"
                            }}
                            borderRadius="4px"
                        />
                    </div>
                </div>

                <div>
                    <Skeleton
                        style={{
                            backgroundColor: "var(--backgrounds-lines-n-500)",
                            height: "35px"
                        }}
                        borderRadius="4px"
                    />
                </div>
            </div>
        </SkeletonTheme>
    );
}
