import styled from "styled-components";
import MagnifierIcon from "../../../assets/icons/magnifierIcon.png";
import { useEffect, useRef, useState } from "react";
import { ReplaceStringWithColor } from "../forms/ControlbarSearch";
import Checkbox from "../checkbox/Checkbox";
import check0 from "../../../assets/icons/globalDropdownCheckbox.png";
import BtnLargeSolid from "../buttons/BtnLargeSolid";

//TODO: SHOULD WE RENAME THIS CONST TO "DropdownTypes"
export const OverlayType = {
    MULTI_SELECT: "MULTI_SELECT",
    UNI_SELECT: "UNI_SELECT"
};

/* #region styles */
const DropdownOverlayStyles = styled.div`
    & {
        position: relative;
        width: 100%;
        height: 0px;
    }

    .property-1-variant-3 {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px;
        padding: 12px 8px 12px 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        position: relative;
        box-shadow: var(--drop-shadow-100-box-shadow, 0px 0px 10px 0px rgba(17, 22, 32, 1));
    }
    .frame-74 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: absolute;
        width: 100%;
    }
    .frame-76 {
        border-radius: 4px;

        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    .small-components-holder {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        flex: 1;
        width: 100%;
    }
    .dropdownlineitem-container {
        /* max-height: 224px; //will be changed */
        overflow: auto;
        margin-top: 10px;
        padding-right: 4px;
    }
    .dropdownlineitem-container::-webkit-scrollbar {
        width: 3px;
    }

    .dropdownlineitem-container::-webkit-scrollbar-thumb {
        background-color: #3c485a;
        border-radius: 10px;
    }

    .dropdownlineitem-container::-webkit-scrollbar-track {
        background: transparent;
        box-shadow: none;
    }

    .dropdownlineitem-container::-webkit-scrollbar-corner {
        display: none;
    }
    .nothing-to-show-container {
        color: var(--Buttons-All-Platforms-Gray-100);
        font-size: 14px;
        font-weight: 400;
        margin: 5px 0px;
    }

    .frame-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        margin-top: 8px;
    }
    .separator {
        width: 100%;
        height: 1px;
        background-color: #1a222d;
    }
    .btn-large-solid {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 62px;
        height: 35px;
        position: relative;
    }
    .button {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--button-text-small-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 10px);
        letter-spacing: var(--button-text-small-letter-spacing, 0.0125em);
        font-weight: var(--button-text-small-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
`;

const SearchComponentStyles = styled.div`
    .frame-48 {
        background: #10141b !important;
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        padding: 6px 8px 6px 8px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        color: var(--text-icons-gray-300, #4f5b6d);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .search-sm {
        flex-shrink: 0;
        width: 15px;
        height: 15px;
        position: relative;
        overflow: visible;
    }
    .search-list {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .search-input {
        border: none;
        outline: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 400;
        color: var(--text-icons-gray-300, #4f5b6d);
        width: 100%;
    }
`;
const Selector = styled.div`
    &.frame-75 {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        width: 100%;
        position: relative;
        overflow: hidden;
        margin: 0;
        padding: 0;
        height: 36px;
    }
    .affiliated-color {
        color: var(--text-icons-gray-300, #4f5b6d);
    }
    .locked {
        color: var(--text-icons-gray-300, #4f5b6d) !important;
        cursor: not-allowed;
    }
    .dropdown-text {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        flex: 1;
        padding: 8px;
        border-radius: 4px;
    }
    &.dropdown-item {
        padding-right: 4px !important;
    }
    &.dropdown-item:hover {
        background-color: #1e2631;
    }
    .replay-ss-001-span {
        color: var(--text-and-icons-green-500, #00bb00);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
    .dropdown-description {
        color: var(--text-icons-gray-300, #4f5b6d);
        text-align: right;
        font-family: Roboto;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;

        height: 32px;
        line-height: 32px;
    }
    .dropdown-description.enabled {
        color: var(--text-and-icons-gray-100, #d1d1d1);
    }
`;
/* #endregion */

/** DropdownOverlay component that supports multi-select and uni-select.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {boolean} [props.isSearchable=true] - Whether the dropdown should include a search bar.
 * @param {string} [props.overlayType=OverlayType.UNI_SELECT] - Type of overlay, can be "MULTI_SELECT" and "UNI_SELECT".
 * @param {Array} [props.data=[]] - Array of items to display in the dropdown. Each item should have `value` and `name` properties.
 * @param {Function} [props.onSingleItemClick=() => {}] - Function to handle clicking a single item.
 * @param {boolean} [props.isChangeColor=false] - Whether to change the color of the matching text in search results.
 * @param {Function} [props.multiSelectData=() => {}] - Function to return selected items in multi-select mode.
 * @param {string} [props.overrideSearchValue] - The value to override the search input with.
 * @param {Object} [props.style] - The style object to apply to the component.
 * @param {boolean} [props.noSearchResultsHideMessage=false] - Whether to hide the message when there are no search results.
 * @param {Array} [props.checkedItemNames=[]] - Array of item names to be checked in multi-select mode.
 * @param {string} [props.className] - The class name to apply to the component.
 * @param {number} [props.scrollableSize=224] - The maximum height of the dropdown.
 * @param {boolean} [props.addSelectAllOption=false] - Whether to add a "Select All" option to the dropdown. This select all will add a select all item to the dropdown.
 * @param {boolean} [props.hasDescriptionFields=false] - Whether the data has description fields. Field names will be "description".
 * @param {boolean} [props.hasLockedFields=false] - Whether the data has locked fields. Field names will be "locked".
 * @param {string} [props.widthPercentage="100%"] - The width of the dropdown.
 * @param {Object} [props.multidropdownOptions] - The options for the multi-select dropdown.
 * @param {boolean} [props.multidropdownOptions.useSelectAll=false] - Whether to use the select all option.
 * @param {Function} [props.multidropdownOptions.selectAllPlaceholderFunction] - The function to call when the select all placeholder is clicked.
 * @param {string} [props.multidropdownOptions.selectAllText="Select All"] - The text to display for the select all option.
 * @returns {JSX.Element} The rendered DropdownOverlay component.
 *
 * @example //!IMPORTANT - Data should be always like this:
 * const testArr = [{value: "value", name: "name", selected: true}];
 *
 * <DropdownOverlay
 *      isSearchable={true}
 *      data={getDropdownData("countryOptions")}
 *      onSingleItemClick={singleItemData => {
 *          console.log(singleItemData); //this is single Item data when we click on a single Item
 *      }}
 *      isChangeColor={true}
 *      overlayType={OverlayType?.MULTI_SELECT}
 *      multiSelectData={data => {
 *         console.log(data); //this is data with the selected options with checkbox
 *      }}
 * />
 */
const DropdownOverlay = ({
    isSearchable = true,
    overlayType = OverlayType.UNI_SELECT,
    data = "",
    isVisible = true,
    onSingleItemClick = () => {},
    isChangeColor = false,
    multiSelectData = () => {},
    overrideSearchValue,
    style,
    noSearchResultsHideMessage = false,
    checkedItemNames = [],
    className = "",
    scrollableSize = 224,
    addSelectAllOption = false,
    hasDescriptionFields = false,
    hasLockedFields = false,
    widthPercentage = "100%",
    multidropdownOptions = {
        useSelectAll: false,
        selectAllText: "Select All",
        selectAllPlaceholderFunction: undefined,
        useDoneButton: false,
        onDoneClick: () => {}
    }
}) => {
    /* #region states */
    const searchInputRef = useRef(null);

    const [searchedData, setSearchedData] = useState(data);
    const [searchedValue, setSearchedValue] = useState("");
    const [checkedItems, setCheckedItems] = useState({});
    /* #endregion */

    /* #region effects */
    useEffect(() => {
        if (addSelectAllOption && overlayType === OverlayType.MULTI_SELECT) {
            setSearchedData([{ value: "selectAll", name: "Select All", selected: false }, ...data]);
        } else setSearchedData(data);
    }, [data]);

    useEffect(() => {
        if (overrideSearchValue) {
            setSearchedValue(overrideSearchValue);
            onChange({ target: { value: overrideSearchValue } });
        }
    }, [overrideSearchValue]);

    useEffect(() => {
        if (overlayType === OverlayType.MULTI_SELECT) {
            const selectedItems = data?.filter(item => item.selected);
            setCheckedItems(
                selectedItems?.reduce((acc, item) => {
                    acc[item.value] = true;
                    return acc;
                }, {})
            );
        }
    }, []);

    useEffect(() => {
        if (overlayType !== OverlayType.MULTI_SELECT) return;
        const selectedData = data?.map(item => {
            const selected = checkedItems[item.value] ?? false;

            return {
                ...item,
                selected
            };
        });

        if (multiSelectData) multiSelectData(selectedData);
    }, [checkedItems]);

    useEffect(() => {
        if (overlayType !== OverlayType.MULTI_SELECT) return;

        if (checkedItemNames.length === 0) {
            setCheckedItems({});
            return;
        }

        const items = data?.reduce((acc, item) => {
            acc[item.value] = checkedItemNames.includes(item.name);
            return acc;
        }, {});

        setCheckedItems(items);
    }, [checkedItemNames]);

    useEffect(() => {
        if (isSearchable && searchInputRef.current && isVisible) {
            searchInputRef.current.focus();
        }
    }, [isSearchable, isVisible]);
    /* #endregion */

    /* #region methods */
    const onChange = e => {
        if (!e || !e.target || !e?.target?.value === undefined || !e?.target?.value?.toLowerCase) return;
        try {
            const searchedValue = e?.target?.value?.toLowerCase();
            setSearchedValue(searchedValue);
            const filteredData = data?.filter(item => {
                return item?.name?.toLowerCase()?.includes(searchedValue);
            });
            setSearchedData(filteredData);
        } catch (e) {
            console.error(e);
        }
    };

    const handleCheckboxClick = itemClicked => {
        // && !onSingleItemClick
        if (itemClicked.value === "selectAll") {
            const areAllChecked = searchedData?.every(item => item?.locked || checkedItems[item.value]);

            const newCheckedItems = searchedData?.reduce((acc, item) => {
                if (item?.locked) {
                    acc[item.value] = false;
                    return acc;
                }

                acc[item.value] = !areAllChecked;
                return acc;
            }, {});

            setCheckedItems(newCheckedItems);
            return;
        }

        setCheckedItems(prevState => ({
            ...prevState,
            [itemClicked.value]: !prevState[itemClicked.value]
        }));
    };

    const isDropdownVisible = () => {
        return isVisible && !(searchedData?.length === 0 && noSearchResultsHideMessage);
    };
    /* #endregion */

    //Remove multi dropdown options if the overlay type is uni select
    if (overlayType === OverlayType.UNI_SELECT) {
        multidropdownOptions = {
            useSelectAll: false,
            selectAllText: "Select All",
            selectAllPlaceholderFunction: undefined,
            useDoneButton: false,
            onDoneClick: () => {}
        };
    }

    return (
        <DropdownOverlayStyles
            className={`dropdown-overlay ${className}`}
            style={{ ...style, display: isDropdownVisible() ? "block" : "none", width: widthPercentage }}>
            <div className="frame-74">
                <div className="property-1-variant-3">
                    <div className="frame-76">
                        <div className="small-components-holder">
                            {isSearchable ? <SearchComponent onChange={onChange} inputRef={searchInputRef} /> : null}

                            {multidropdownOptions?.useSelectAll && (
                                <div className="frame-container">
                                    <DropdownLineItem
                                        overlayType={overlayType}
                                        isAffiliated={false}
                                        item={{ value: "selectAll", name: multidropdownOptions.selectAllText }}
                                        isChecked={searchedData?.every(
                                            item => item?.locked || checkedItems[item.value]
                                        )}
                                        onCheckboxClick={() => handleCheckboxClick({ value: "selectAll" })}
                                    />
                                    <div className="separator"></div>
                                </div>
                            )}

                            <div
                                className="dropdownlineitem-container"
                                style={{
                                    marginTop: isSearchable ? "10px" : "0px",
                                    maxHeight: `${scrollableSize}px`
                                }}>
                                {searchedData?.length ? (
                                    searchedData?.map(item => (
                                        <DropdownLineItem
                                            key={item.value}
                                            overlayType={overlayType}
                                            isAffiliated={false}
                                            onSingleItemClick={item => {
                                                onSingleItemClick(item, checkedItems);
                                            }}
                                            item={item}
                                            searchedValue={searchedValue}
                                            isChecked={checkedItems[item.value] || false}
                                            onCheckboxClick={() => handleCheckboxClick(item)}
                                            isChangeColor={isChangeColor}
                                            hasDescriptionFields={hasDescriptionFields}
                                            hasLockedFields={hasLockedFields}
                                        />
                                    ))
                                ) : (
                                    <div className="nothing-to-show-container">There’s nothing to show here...</div>
                                )}
                            </div>

                            {multidropdownOptions?.useDoneButton && (
                                <div className="frame-container">
                                    <div className="separator"></div>

                                    <BtnLargeSolid
                                        className="button"
                                        onClick={multidropdownOptions.onDoneClick}
                                        text="DONE"
                                        style={{
                                            width: "65px"
                                        }}
                                        useGrayVariant={true}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </DropdownOverlayStyles>
    );
};

export default DropdownOverlay;

/* #region small components */
const SearchComponent = ({ onChange = () => {}, inputRef }) => {
    return (
        <SearchComponentStyles>
            <div className="frame-48">
                <img className="search-sm" src={MagnifierIcon} alt="Search Icon" />
                <input
                    type="text"
                    className="search-input"
                    placeholder="Search List"
                    onChange={onChange}
                    ref={inputRef}
                />
            </div>
        </SearchComponentStyles>
    );
};

const DropdownLineItem = ({
    overlayType = OverlayType.UNI_SELECT,
    isAffiliated = false,
    onSingleItemClick = () => {},
    item = {},
    searchedValue = "",
    isChecked = false,
    onCheckboxClick = () => {},
    isChangeColor = false,
    hasDescriptionFields = false,
    hasLockedFields = false
}) => {
    return (
        <Selector
            className={`frame-75 dropdown-item ${
                isAffiliated ? "hover-not-allowed" : item?.locked ? "hover-not-allowed" : "cursor-pointer"
            }`}
            onClick={() => {
                if (hasLockedFields && item?.locked) return;

                const itemTmp = { ...item, selected: !isChecked };
                onSingleItemClick(itemTmp);
                if (overlayType === OverlayType.MULTI_SELECT) {
                    onCheckboxClick();
                }
            }}>
            <div className={"dropdown-text text-one-line "}>
                <div className={`align-items-center gap-8px ${isAffiliated ? "affiliated-color" : ""}`}>
                    {overlayType === OverlayType.MULTI_SELECT && !isAffiliated && (
                        <Checkbox
                            disabled={hasLockedFields && item?.locked}
                            isChecked={isChecked}
                            onCheckboxClick={() => {}}
                            uncheckedSrc={check0}
                        /> //in our case oncheckbox click is handled in the above onclick
                    )}
                    <div
                        className={`flex justify-space-between text-one-line d-block w-100 ${
                            isAffiliated ? "affiliated-color" : ""
                        } ${item?.locked ? "locked" : ""}`}>
                        {isChangeColor ? (
                            <ReplaceStringWithColor searchText={searchedValue} text={item?.name} />
                        ) : (
                            item?.name
                        )}
                        {isAffiliated ? <i>Affiliated</i> : null}
                    </div>
                </div>
            </div>

            {hasDescriptionFields && (
                <div
                    className={`text-one-line dropdown-description ${isChecked ? "enabled" : ""}`}
                    style={{ flexShrink: 0, width: "auto", paddingRight: "6px" }}>
                    {item?.description}
                </div>
            )}
        </Selector>
    );
};
/* #endregion */
