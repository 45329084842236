import styled from "styled-components";
import { useState } from "react";

//Assets
import alertTriangle from "../../../../../assets/icons/alert-triangle.svg";
import dotsGridGrey from "../../../../../assets/icons/dots-grid-grey.svg";
import errorTringle from "../../../../../assets/icons/error-triangle.svg";
import checkCircle from "../../../../../assets/icons/check-circle.svg";
import dotsGrid from "../../../../../assets/icons/dots-grid.svg";

import greenExcel from "../../../../../assets/images/green-excel.svg";
import redExcel from "../../../../../assets/images/red-excel.svg";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    .list-main-container {
        width: 250px;
        height: 100%;
        position: relative;
    }
    .list-header {
        background: var(--backgrounds-and-lines-n-500, #141922);
        padding: 8px 16px 8px 16px;
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        height: 38px;
        position: relative;
    }

    .header-lb-alert {
        text-align: left;
        font-family: var(--label-small-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-small-uppercase-font-size, 10px);
        line-height: var(--label-small-uppercase-line-height, 5px);
        letter-spacing: var(--label-small-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-small-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }

    .header-lb-error {
        text-align: left;
        font-family: var(--label-small-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-small-uppercase-font-size, 10px);
        line-height: var(--label-small-uppercase-line-height, 5px);
        letter-spacing: var(--label-small-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-small-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }

    .list-body {
        display: flex;
        flex-direction: column;
        background: #10141b;
        height: 100%;
        padding: 8px 16px 8px 16px;
        gap: 16px;
        flex: 1;
        align-self: stretch;
        overflow-y: auto;
    }
    .frame-288 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        margin: auto;
    }
    .excel-1 {
        flex-shrink: 0;
        width: 28px;
        height: 28px;
        position: relative;
        overflow: hidden;
    }
    .group {
        height: auto;
        position: absolute;
        left: 0.93px;
        top: 0px;
        overflow: visible;
    }
    .frame-199 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .browse-a-file-from-device {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .file-should-be-xlsx-format {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
`;
/* #endregion */

export default function LabelsList({ labels, setIsDragging, isErrorInvalidFormat, formData }) {
    return (
        <StyledPage>
            <div className="list-main-container">
                <div
                    className="list-header"
                    style={{ backgroundColor: labels && !isErrorInvalidFormat && "rgba(0, 187, 0, 0.09)" }}>
                    <img
                        src={isErrorInvalidFormat ? errorTringle : labels ? checkCircle : alertTriangle}
                        alt="iconTriangle"
                    />
                    <div
                        className={isErrorInvalidFormat ? "header-lb-error" : "header-lb-alert"}
                        style={{ color: isErrorInvalidFormat ? "#ff3636" : labels ? "#00BB00" : "#ff9900" }}>
                        {isErrorInvalidFormat ? "ERROR IN FILE" : labels ? "DETECTED FIELDS" : "STANDING BY FOR DATA"}
                    </div>
                </div>
                <div className="list-body">
                    {labels && !isErrorInvalidFormat ? (
                        <ListComponent labels={labels} setIsDragging={setIsDragging} formData={formData} />
                    ) : (
                        <div className="frame-288">
                            <div className="excel-1">
                                <img
                                    className="group"
                                    src={isErrorInvalidFormat ? redExcel : greenExcel}
                                    alt="imageExcel"
                                />
                            </div>
                            <div className="frame-199">
                                {isErrorInvalidFormat ? (
                                    <div style={{ color: "#ff3636", fontSize: "12px" }}>File not valid</div>
                                ) : (
                                    <>
                                        <div className="browse-a-file-from-device">Browse a file from device</div>
                                        <div className="file-should-be-xlsx-format">File should be .xlsx format</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </StyledPage>
    );
}

const ListComponent = ({ labels, setIsDragging, formData }) => {
    const [draggingIndex, setDraggingIndex] = useState(null);

    const handleDragStart = (index, label) => e => {
        setDraggingIndex(index);
        setIsDragging(true);
        e.dataTransfer.setData("text/plain", label);
    };

    const handleDragEnd = () => {
        setDraggingIndex(null);
        setIsDragging(false);
    };

    return (
        <StyledList>
            <div className="lc-container">
                <div className="list-text">
                    {labels.length === 0 ? "No fields finded!" : "You can drag fields below to a box on the right"}
                </div>
                <div className="list-container">
                    {labels.length > 0 &&
                        labels.map((label, index) => {
                            if (label === "") return null; //Hide labels with empty TITLE
                            const isBeingUsedLabel = (formData, label) => {
                                const values = Object.values(formData);
                                return values.includes(label);
                            };

                            const isBeingUsed = isBeingUsedLabel(formData, label);

                            return (
                                <div
                                    key={index}
                                    className={`item-label ${isBeingUsed && "item-label-used"}`}
                                    draggable={!isBeingUsed}
                                    onDragStart={handleDragStart(index, label)}
                                    onDragEnd={handleDragEnd}
                                    style={{
                                        borderColor: draggingIndex === index ? "#00AEEF" : "#1a222d"
                                    }}>
                                    <img
                                        className="dots-grid"
                                        src={isBeingUsed ? dotsGridGrey : dotsGrid}
                                        alt="gridDots"
                                    />
                                    <div className={`brand-name ${isBeingUsed && "brand-name-used"}`}>{label}</div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </StyledList>
    );
};
/* #region SCOPED CSS */
const StyledList = styled.div`
    .lc-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 218px;
        height: 100%;
    }
    .list-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        max-width: 218px;
        overflow-y: auto;
        padding-bottom: 45px;
    }
    .list-text {
        color: var(--text-icons-gray-100, #d1d1d1);
        leading-trim: both;
        text-edge: cap;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    //----------------

    .item-label {
        display: flex;
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-300, #1a222d);
        border-width: 1px;
        padding: 8px;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        flex-direction: row;
        cursor: pointer;
        z-index: 1;
    }

    .item-label-used {
        cursor: not-allowed;
        color: "#7c868b";
    }

    .brand-name {
        color: #ffffff;
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        position: relative;
        flex: 1;
    }
    .brand-name-used {
        color: #4f5b6d;
    }
`;
/* #endregion */
