import React, { useState, useRef } from "react";
import styled from "styled-components";
import upload from "../../../../assets/icons/upload-010.svg";

/**
 * Button large component.
 *
 * @component
 * @example
 * // Usage:
 * <LogoInput onUpload={setProfileLogo} value={profileLogo} />
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onUpload - The function to call when a file is uploaded.
 * @param {String} props.value - The value of the input.
 *
 * @returns {JSX.Element} The rendered BtnLarge component.
 */

export default function LogoInput({ onUpload, value, isRequiredAsterisk }) {
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    const handleDragOver = event => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = event => {
        event.preventDefault();
        setIsDragging(false);
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            const reader = new FileReader();
            reader.onload = e => {
                onUpload(e.target.result);
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const handleFileChange = event => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                onUpload(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    /* #region SCOPED CSS */
    const StyledPage = styled.div`
        .frame-313334 * {
            box-sizing: border-box;
        }
        .frame-313334 {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            justify-content: flex-start;
            align-self: stretch;
            flex-shrink: 0;
            position: relative;
        }
        .field-profile-photo-uploader-sm {
            border-radius: 99999px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            position: relative;
            padding: 50px;
            border: 1px dashed ${isDragging ? "#f0f0f0" : "#3c485a"};
            background-image: ${value ? `url(${value})` : "none"};
            background-size: cover;
            background-position: center;
            cursor: pointer;
            transition: 300ms;
        }
        .field-profile-photo-uploader-sm:hover {
            background-color: #3c485a50;
        }
        .rectangle-8 {
            border-radius: 0px;
            flex-shrink: 0;
            width: 100px;
            height: 100px;
            position: relative;
            overflow: visible;
        }
        .upload-01 {
            flex-shrink: 0;
            width: 26px;
            height: 26px;
            position: absolute;
            left: 50%;
            translate: -50% -50%;
            top: 50%;
            overflow: visible;
            display: ${value ? "none" : "block"};
        }
        .upload-profile-photo {
            display: flex;
            gap: 3px;
            color: #7c868b;
            text-align: left;
            font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
            font-size: var(--subtitle-2-font-size, 12px);
            font-weight: var(--subtitle-2-font-weight, 400);
            position: relative;
        }
        .file-input {
            display: none;
        }
    `;
    /* #endregion */

    return (
        <StyledPage>
            <div
                className="frame-313334"
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleClick}>
                <div className="field-profile-photo-uploader-sm">
                    <img className="upload-01" src={upload} alt="upload" />
                    <input
                        type="file"
                        className="file-input"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept="image/*"
                    />
                </div>
                <div className="upload-profile-photo">
                    Upload profile photo{" "}
                    {isRequiredAsterisk && (
                        <div className={`mandatory-fields-span2 ${value ? "" : "text-orange-500"}`}>*</div>
                    )}
                </div>
            </div>
        </StyledPage>
    );
}
