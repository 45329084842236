import CircularProgress from "../../common/loaders/CircularSpinner";
import styled from "styled-components";

/* #region  SCOPED CSS */
const CustomLoader = styled.div`
    & {
        padding: 36px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .loader-title {
        color: var(--text-icons-gray-100, #d1d1d1);
        text-align: center;

        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .loader-sub-title {
        color: var(--text-icons-gray-200, #7c868b);
        text-align: center;
        leading-trim: both;
        text-edge: cap;

        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
    }

    .titles-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
`;
/* #endregion */

export default function CustomLoaderProductsPage({ process, fileName }) {
    return (
        <CustomLoader>
            <CircularProgress progress={process} />
            <div className="titles-container">
                <div className="loader-title">Importing products</div>
                <div className="loader-sub-title">{fileName || "filename.csv"}</div>
            </div>
        </CustomLoader>
    );
}
