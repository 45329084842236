import React, { useEffect, useState } from "react";
import packageJson from "../../../../package.json";
import styled from "styled-components";
import footerlogo from "../../../assets/images/footer-logologin.png";
import preenrollmentLeftImage from "../../../assets/images/preenrollment-left-image.png";
//import loginLeftBg from "../../../assets/images/login-left-bg.png";
import loginTopImg from "../../../assets/images/login-top-img.png";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import * as Library from "../../../utils/Library";
import ContainerLoader from "../../common/loaders/ContainerLoader";
import { APP_TITLE, PLATFORM_NAME } from "../../../config/constant";
import moment from "moment/moment";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import AppContext from "../../../AppContext";
import { useParams } from "react-router";
import FormField from "../../common/forms/FormField";
import TermsAndPrivacyPanel from "../../common/termsandConditions/TermsAndPrivacyPanel";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    /*.loading-filters {
        opacity: 0.5;
        filter: blur(4px);
    }*/

    & {
        height: 100dvh;
        overflow: hidden;
        min-width: 1024px;
        background: var(--backgrounds-lines-n-600);
        display: flex;
    }

    .FrameLeft {
        display: flex;
        flex: 1 1 0;
        place-content: center;

        /*
        //background-image: $ url({loginLeftBg});
        background-image: url({loginLeftBg}?${new Date().getTime()});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom left; // Adjusted the background position to bottom left.
        */
    }

    .FrameRight {
        display: flex;
        flex: 1 1 0;
        justify-content: center;
        align-items: center;
        justify-items: center;
    }

    .Footer {
        position: absolute;
        bottom: 40px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
    }

    .TermsConditions {
        color: white;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .PrivacySecurity {
        color: white;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .BotagNorgeAs2025 {
        color: #7c868b;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .AppVersion120 {
        color: #7c868b;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .LoginTopImg {
        width: 132px;
        height: 42px;
        flex-shrink: 0;
        display: flex;
        position: absolute;
        top: 59px;
        left: 54px;
    }

    .LeftContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        //padding-left: 96px;
    }

    .group-1000011088 {
    }

    .LeftImg {
        display: flex;
        width: 100%;
        height: auto;
        object-fit: contain;
        position: relative;
        top: -1%;
        left: 7%;
    }

    .LeftTitle {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 4rem;
        font-style: normal;
        font-weight: 100;
        line-height: normal;
        letter-spacing: -0.06rem;

        display: flex;
        align-self: center;
    }

    .LeftTitle strong {
        display: contents;
        color: #fff;
        font-family: Roboto;
        font-size: 4rem;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.06rem;
    }

    .Frame313264 {
        width: 394px;
        flex-direction: column;
        gap: 30px;
        display: flex;
        align-self: center;
        justify-content: center;
        align-items: center;
    }

    .Image1 {
        width: 208px;
        height: 51px;
        background: url(${footerlogo}) 0% 0% / contain no-repeat;
    }

    .Frame313326 {
        align-self: stretch;
        height: 290px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        display: flex;
    }

    .Frame313266 {
        align-self: stretch;
        height: 262px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
        display: flex;
    }

    .Frame313263 {
        align-self: stretch;
        height: 23px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        display: flex;
    }

    .LoginWithYourCredentials {
        align-self: stretch;
        text-align: center;
        color: white;
        font-size: 20px;
        font-family: Roboto;
        font-weight: 300;
        word-wrap: break-word;
    }

    .Frame313260 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        display: flex;
        align-self: stretch;
    }

    .InputField {
        height: 50px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: flex;
        align-self: stretch;
    }

    .EmailAddress {
        align-self: stretch;
        color: var(--text-icons-gray-200);
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .Frame313324 {
        align-self: stretch;
        width: 100%;
        height: 28px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 14px;
        display: flex;
        background: var(--backgrounds-lines-n-600, #10141b);
        outline: none;
        border: 0;

        color: var(--text-icons-green-500);

        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input.Frame313324 {
        height: 14px;
    }

    .Line2 {
        align-self: stretch;
        height: 0px;
        width: 100%;
        border-top: 1px #3c485a solid;
    }

    .Password {
        align-self: stretch;
        color: #7c868b;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .Frame313261 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }

    .Frame313327 {
        width: 393px;
        height: 28px;
        position: relative;
    }

    .EyeOff {
        width: 16px;
        height: 16px;
        top: 0px;
        position: absolute;
        right: 8px;
    }

    .Icon {
        width: 16px;
        height: 16px;
        position: absolute;
    }

    .ForgotPassword {
        align-self: stretch;
        text-align: right;
        color: var(--text-icons-gray-200);
        font-size: 12px;
        font-family: Roboto;
        font-weight: 500;
        letter-spacing: normal;
        word-wrap: break-word;
        cursor: pointer;
    }

    .Share {
        color: #4f5b6d;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.15px;
        word-wrap: break-word;
    }

    .loginTermsPrivacyPolicies {
        align-self: stretch;
        text-align: center;
        color: #d1d1d1;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .Error {
        width: 100%;
        height: 100%;
        padding: 16px;
        background: rgba(255, 54, 54, 0.1);
        border-radius: 4px;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        display: inline-flex;
    }

    .EmailInvalidSection {
        flex: 1 1 0;
        text-align: center;
        color: #ff3636;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 300;
        word-wrap: break-word;
        transition: all 0.3s ease;
    }

    .DialogPregraf {
        width: 296px;
        font-size: 12px;
        align-items: center;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        margin-bottom: 16px;
        font-style: normal;
        line-height: normal;
    }

    .DialogLayout {
        margin-bottom: 28px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: inset 0 0 20px 20px transparent;
    }
`;
/* #endregion */

export default function LoginPage() {
    document.title = `${APP_TITLE} - Login`;

    /* #region STATES */
    const { inviteID } = useParams();
    const { setGetUserDataToken } = React.useContext(AppContext);

    const [showPassword, setShowPassword] = useState(false);
    const [loginIsInvalid, setLoginIsInvalid] = useState(false);
    const [emailIsInvalid, setEmailIsInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDialogLoading, setIsDialogLoading] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [showTAC, setShowTAC] = useState({
        termsAndConditions: false,
        privacyPolicy: false
    });
    const [email, setEmail] = useState("");
    const [resetEmail, setResetEmail] = useState("");

    const [password, setPassword] = useState("");
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [resetPasswordSent, setResetPasswordSent] = useState(false);
    /* #endregion */

    /* #region METHODS */

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleEmail = e => {
        const em = e.target.value.trim();
        setLoginIsInvalid(false);
        setEmail(em);
    };

    const handlePassword = e => {
        setLoginIsInvalid(false);
        const pwd = e.target.value.trim();
        setPassword(pwd);
    };

    const validateEmail = email => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleValidation = () => {
        if (buttonDisable) return false;
        let formIsValid = true;
        if (!email) {
            formIsValid = false;
            setLoginIsInvalid(true);
        } else if (isNaN(email)) {
            if (!validateEmail(email)) {
                formIsValid = false;
                setEmailIsInvalid(true);
            } else setEmailIsInvalid(false);
        }
        if (!password) {
            formIsValid = false;
            setLoginIsInvalid(true);
        }
        return formIsValid;
    };

    const handleLoginSuccess = data => {
        setIsLoading(true);
        localStorage.setItem("token", data?.userID);
        localStorage.setItem("isLoggedIn", data?.isLoggedIn);

        //Set the token in the context will trigger the user data fetch and redirect to homepage
        setGetUserDataToken(data?.userID);
    };

    const handleSubmit = e => {
        setEmailIsInvalid(false);
        setLoginIsInvalid(false);
        setErrorMessage("");
        e.preventDefault();
        if (handleValidation()) {
            const formData = {
                username: email,
                password: password,
                type: "BOstageB"
            };
            setIsLoading(true);

            Library.makeCommonPostRequest("Accounts", "loginBOstages", formData, false, setIsLoading).then(res => {
                if (res.data.status !== 200) {
                    if (res.data?.data?.error_type === "username") setLoginIsInvalid(true);
                    if (res.data?.data?.error_type === "password") setLoginIsInvalid(true);
                    return;
                }

                if (res.data?.data?.error_type) {
                    //console.log("", res.data?.data?.error_type, res.data?.error_message);
                    if (res.data?.data?.error_type === "groups") setErrorMessage(res.data?.message);
                    setLoginIsInvalid(true);
                    return;
                }

                setLoginIsInvalid(false);
                const resData = res.data?.data;
                handleLoginSuccess(resData);
            });
        }
    };

    const handleKeyPress = e => {
        if (e.key === "Enter") {
            handleSubmit(e);
        }
    };

    const getInviteData = inviteID => {
        Library.makeCommonPostRequest("Invites", "getInvite", { inviteID: inviteID }, false).then(res => {
            if (res.data.status !== 200) {
                return;
            }
            const data = res.data.data;

            if (data?.status?.status?.toLowerCase() === "pre-enrolled") {
                updateInviteRequest(inviteID, "Enrolling");
            }
            if (data?.contactEmail) {
                setEmail(data?.contactEmail);
                setPassword("");
            }
        });
    };

    const updateInviteRequest = (inviteID, status) => {
        Library.makeCommonPostRequest("Invites", "updateInvite", { inviteID: inviteID, status: status }, false).then(
            res => {
                if (res.data.status !== 200) {
                    return;
                }
                console.log("Invite updated successfully.");
            }
        );
    };

    const handleResetPassword = () => {
        setIsDialogLoading(true);
        if (validateEmail(resetEmail))
            Library.makeCommonPostRequest(
                "Emails",
                "requestNewPassword",
                { email: resetEmail, platform: PLATFORM_NAME },
                false,
                setIsDialogLoading
            )
                .then(res => {
                    if (res.data.status !== 200) return;

                    setResetPasswordSent(true);
                })
                .catch(err => {
                    console.log("Error:", err);
                });
    };

    const handleCloseResetPassword = () => {
        if (resetPasswordSent) {
            setShowResetPassword(false);
            setResetPasswordSent(false);
            setResetEmail("");
            return;
        }
        setShowResetPassword(false);
        setResetEmail("");
    };

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (email && password) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    }, [email, password]);

    useEffect(() => {
        if (window.location.pathname === "/login") {
            localStorage.clear();
        }
    }, []);

    useEffect(() => {
        if (inviteID) {
            getInviteData(inviteID);
        }
    }, [inviteID]);
    /* #endregion */

    /* #region COMPONENTS */
    const getEyeIcon = () => {
        if (showPassword)
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path
                        d="M7.66196 3.39488C7.9329 3.35482 8.2124 3.33333 8.50028 3.33333C11.9036 3.33333 14.1369 6.33656 14.8871 7.52455C14.9779 7.66833 15.0233 7.74023 15.0488 7.85112C15.0678 7.93439 15.0678 8.06578 15.0487 8.14905C15.0233 8.25993 14.9776 8.3323 14.8861 8.47705C14.6862 8.79343 14.3814 9.23807 13.9777 9.7203M4.98288 4.47669C3.5415 5.45447 2.56297 6.81292 2.11407 7.52352C2.02286 7.66791 1.97725 7.74011 1.95183 7.85099C1.93273 7.93426 1.93272 8.06563 1.95181 8.14891C1.97722 8.25979 2.02262 8.33168 2.11342 8.47545C2.86369 9.66344 5.09694 12.6667 8.50028 12.6667C9.87255 12.6667 11.0546 12.1784 12.0259 11.5177M2.50028 2L14.5003 14M7.08606 6.58579C6.72413 6.94772 6.50028 7.44772 6.50028 8C6.50028 9.10457 7.39571 10 8.50028 10C9.05256 10 9.55256 9.77614 9.91449 9.41421"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        else
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path
                        d="M2.11342 8.47562C2.02262 8.33186 1.97723 8.25998 1.95182 8.14911C1.93273 8.06583 1.93273 7.9345 1.95182 7.85122C1.97723 7.74035 2.02262 7.66847 2.11341 7.52471C2.86369 6.33672 5.09693 3.3335 8.50027 3.3335C11.9036 3.3335 14.1369 6.33672 14.8871 7.52471C14.9779 7.66847 15.0233 7.74035 15.0487 7.85122C15.0678 7.9345 15.0678 8.06583 15.0487 8.14911C15.0233 8.25998 14.9779 8.33186 14.8871 8.47562C14.1369 9.6636 11.9036 12.6668 8.50027 12.6668C5.09693 12.6668 2.86369 9.6636 2.11342 8.47562Z"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8.50027 10.0002C9.60484 10.0002 10.5003 9.10473 10.5003 8.00016C10.5003 6.89559 9.60484 6.00016 8.50027 6.00016C7.3957 6.00016 6.50027 6.89559 6.50027 8.00016C6.50027 9.10473 7.3957 10.0002 8.50027 10.0002Z"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
    };

    /* #endregion */

    return (
        <StyledPage>
            <FullScreenOverlay show={showResetPassword}>
                <DialogBoxComponent
                    loading={isDialogLoading}
                    loadingHeading="Sending..."
                    heading={resetPasswordSent ? "SUCCESS!" : "JUST CHECKING..."}
                    headingColor="var(--text-icons-green-500)"
                    title={resetPasswordSent ? "Check your email" : "Resetting password?"}
                    onClickYes={handleResetPassword}
                    onClickNo={handleCloseResetPassword}
                    confirmText="RESET"
                    cancelText="CLOSE"
                    useAlternativeLayout={
                        <DialogLayout
                            setResetEmail={setResetEmail}
                            resetEmail={resetEmail}
                            resetPasswordSent={resetPasswordSent}
                        />
                    }
                    showConfirm={!resetPasswordSent}
                    buttonWidth={resetPasswordSent ? "30%" : "100%"}
                    cancelState="primary"
                    confirmState={validateEmail(resetEmail) ? "primary" : "disabled"}
                />
            </FullScreenOverlay>
            {showTAC.termsAndConditions && (
                <TermsAndPrivacyPanel
                    type="terms"
                    onCloseClicked={() =>
                        setShowTAC({
                            termsAndConditions: false,
                            privacyPolicy: false
                        })
                    }
                />
            )}
            {showTAC.privacyPolicy && (
                <TermsAndPrivacyPanel
                    type="privacy"
                    onCloseClicked={() =>
                        setShowTAC({
                            termsAndConditions: false,
                            privacyPolicy: false
                        })
                    }
                />
            )}
            <div className="FrameLeft">
                <img src={loginTopImg} className="LoginTopImg" alt="BOtag Technology" />

                <div className="LeftContainer">
                    <img src={preenrollmentLeftImage} className="LeftImg" alt="BOtag Technology" />

                    <label className="LeftTitle d-none">
                        THE LABEL BRAND STAGE <br />
                        FOR <strong>BO</strong>TAG TECHNOLOGY
                    </label>
                </div>
            </div>

            <div className="FrameRight">
                <div className={"Frame313264"}>
                    <div className="Image1" />
                    <div className="Frame313326">
                        <div className="Frame313266">
                            <div className="Frame313263">
                                <div className="LoginWithYourCredentials">LOGIN WITH YOUR CREDENTIALS</div>
                            </div>
                            <div
                                className={`Error ${loginIsInvalid || emailIsInvalid || errorMessage ? "" : "hidden"}`}>
                                <div className="EmailInvalidSection">
                                    {errorMessage
                                        ? errorMessage
                                        : emailIsInvalid
                                        ? "The email address is invalid."
                                        : loginIsInvalid
                                        ? "The email address or password is incorrect. Please try again."
                                        : ""}
                                </div>
                            </div>
                            <ContainerLoader isLoading={isLoading} isLoadingOverlay={true} addBlurFilters={true}>
                                <div className="Frame313260">
                                    <div className={`InputField ${isLoading ? "loading-filters" : ""}`}>
                                        <div className="EmailAddress">Email address</div>
                                        <input
                                            type="email"
                                            autoComplete="off"
                                            className="Frame313324"
                                            value={email}
                                            onKeyUp={handleKeyPress}
                                            onChange={handleEmail}
                                        />
                                        <div className="Line2" style={{ marginTop: "6px" }} />
                                    </div>

                                    <div className={`Frame313261 ${isLoading ? "loading-filters" : ""}`}>
                                        <div className="InputField">
                                            <div className="Password">Password</div>
                                            <div className="Frame313327">
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    autoComplete="new-password"
                                                    value={password}
                                                    onChange={handlePassword}
                                                    onKeyUp={handleKeyPress}
                                                    className="Frame313324"
                                                />
                                                <div className="EyeOff">
                                                    <div className="Icon text-gray-200" onClick={handleShowPassword}>
                                                        {getEyeIcon()}
                                                    </div>
                                                </div>
                                                <div className="Line2" style={{ marginTop: "8px" }} />
                                            </div>
                                        </div>
                                        <div className="ForgotPassword" onClick={() => setShowResetPassword(true)}>
                                            Forgot password?
                                        </div>
                                    </div>
                                    <BtnLargeSolid
                                        className={`w-100 ${isLoading ? "loading-filters" : ""}`}
                                        disabled={isLoading || buttonDisable}
                                        onClick={handleSubmit}
                                        textStyle={{ fontSize: "0.75rem" }}
                                        style={{ height: "45px" }}
                                        activeColor="var(--App-Accent-Outline)"
                                        normalColor="var(--App-Accent-Active)"
                                        hoverColor="var(--App-Accent-Hover)">
                                        LOGIN
                                    </BtnLargeSolid>
                                    <div className={`loginTermsPrivacyPolicies ${isLoading ? "loading-filters" : ""}`}>
                                        <span>By continuing, you agree to BOtag </span>
                                        <span
                                            className="underline cursor-pointer"
                                            onClick={() => {
                                                setShowTAC({
                                                    termsAndConditions: true,
                                                    privacyPolicy: false
                                                });
                                            }}>
                                            Terms
                                        </span>
                                        <span> and </span>
                                        <span
                                            className="underline cursor-pointer"
                                            onClick={() => {
                                                setShowTAC({
                                                    termsAndConditions: false,
                                                    privacyPolicy: true
                                                });
                                            }}>
                                            Privacy Policy
                                        </span>
                                        <span>.</span>
                                    </div>
                                </div>
                            </ContainerLoader>
                        </div>
                    </div>
                </div>
            </div>

            <div className="Footer">
                <div
                    className="TermsConditions cursor-pointer"
                    onClick={() => {
                        setShowTAC({
                            termsAndConditions: true,
                            privacyPolicy: false
                        });
                    }}>
                    Terms & Conditions
                </div>
                <div
                    className="PrivacySecurity cursor-pointer"
                    onClick={() => {
                        setShowTAC({
                            termsAndConditions: false,
                            privacyPolicy: true
                        });
                    }}>
                    Privacy & Security
                </div>
                <div className="BotagNorgeAs2025">BOtag Norge AS © {moment().format("yyyy")}</div>
                <div className="AppVersion120">App Version {packageJson.version}</div>
            </div>
        </StyledPage>
    );
}

const DialogLayout = props => {
    const { setResetEmail, resetEmail, resetPasswordSent } = props;
    return (
        <div className="DialogLayout">
            <label className="DialogPregraf">
                {resetPasswordSent
                    ? "You’ll find a link to reset your password."
                    : "Insert your email. If the email has an account you’ll get the instructions to reset your password"}
            </label>
            <FormField
                type="email"
                requiredType={resetPasswordSent ? "locked" : "mandatory"}
                assistiveTextColorOverride="#00bb00"
                varName="email"
                value={resetEmail}
                onTextChange={e => setResetEmail(e)}
            />
        </div>
    );
};
