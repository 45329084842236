import noImage from "../../../assets/images/common/no-image-products-placeholder.png";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BtnLargeSolidInverted02 from "../../common/buttons/BtnLargeSolidInverted02";
import Tooltips from "../../common/tooltip/Tooltips";
import Checkbox from "../../common/checkbox/Checkbox";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .UiCardProduct {
        position: relative;
        display: flex;
        /* width: 311px; */
        padding: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex-shrink: 0;

        border-radius: 4px;
        background: var(--backgrounds-lines-n-400, #1e2631);
    }

    .Image5 {
        background: var(--backgrounds-lines-n-500, #141922);
        border-radius: 4px;
        height: 465px;
        align-self: stretch;
        width: 100%;
    }

    .Frame264 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        //position: relative;
    }

    .Frame158 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;
    }

    .Frame161 {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        width: 100%;
        overflow: hidden;
    }

    .StyleNameLeft {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Label/Small - Uppercase */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 140% */
        letter-spacing: 0.125px;
        text-transform: uppercase;

        flex-shrink: 0;
    }

    .StyleNameRight {
        color: var(--text-icons-blue-500, #00aeef);

        /* Label/Small - Uppercase */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.125px;
        text-transform: uppercase;

        //text in on line
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Frame163 {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
    }

    .Pencil01 {
        /* width: 16px;
        height: 16px; */
        cursor: pointer;
    }

    .BotagId {
        display: flex;
        align-self: stretch;
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Label/Small - Uppercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 140% */
        letter-spacing: 0.125px;
        text-transform: uppercase;
    }

    .BotagId .StyleNameLeft,
    .BotagId .StyleNameRight {
        font-size: 0.625rem;
    }

    .Frame171 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .Frame144 {
        display: flex;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .Details {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Label/XS - Uppercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.1px;
        text-transform: uppercase;
    }

    .Line1 {
        flex-grow: 1;

        height: 1px;
        background: var(--backgrounds-lines-n-100);
    }

    .Frame169 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
    }

    .FrameDetailLines {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        height: 12px;
    }

    .FrameDetailLines.Boxes {
        height: 16px;
    }

    .Brand {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .TheProduct {
        flex: 1 0 0;

        color: var(--text-icons-blue-500, #00aeef);
        text-align: right;

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        //text in on line
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Frame172 {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .Reference {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .ReferenceValue {
        flex: 1 0 0;

        color: var(--text-icons-blue-500, #00aeef);
        text-align: right;

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        //text in on line
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Frame166 {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .Colors {
        flex: 1 0 0;

        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame264-2 {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .RectangleBoxes {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        cursor: pointer;
    }

    .Frame383 {
        display: flex;
        width: 16px;
        height: 16px;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        border-radius: 2px;
        background: var(--backgrounds-lines-n-500, #141922);
    }

    .Counter5 {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Label/XS-Lowercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame167 {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .Sizes {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame385 {
        flex: 1 0 0;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        display: flex;
    }

    .size {
        color: var(--text-icons-blue-500, #00aeef);

        /* Label/XS-Lowercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .SizeBoxes {
        border-radius: 2px;
        background: var(--backgrounds-lines-n-500, #141922);

        display: flex;
        height: 16px;
        padding: 4px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .Counter3 {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Label/XS-Lowercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame168 {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .Category {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Tops {
        flex: 1 0 0;
        text-align: right;
        color: var(--text-icons-blue-500, #00aeef);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        //text in on line
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Frame164 {
        display: flex;
        align-items: center;
        gap: 4px;
        align-self: stretch;
    }

    .Frame263 {
        display: flex;
        padding: 8px;
        align-items: flex-start;
        gap: 10px;

        position: absolute;
        right: 24px;
        top: 24px;

        border-radius: 500px;
        background: var(--backgrounds-lines-n-600, #10141b);

        /* drop-shadow-100 */
        box-shadow: 0px 0px 10px 0px #111620;
    }

    .Tag03 {
        width: 16px;
        height: 16px;
    }
    .Frame263-left {
        position: absolute;
        top: 15px;
        left: 15px;
    }
    .card-selected {
        border: 1px solid #00bb00;
        padding: 7px !important;
    }
    .card-suspended {
        border: 1px solid #ff9900;
        padding: 7px !important;
    }
    .card-new {
        border: 1px solid #0060cc;
        padding: 7px !important;
    }
    .Frame263-left-status-label {
        position: absolute;
        top: 40px;
        background-color: #d37e00;
        padding-left: 10px;
        padding-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 15px;
        font-size: 10px;
        border-radius: 0px 2px 2px 0px;
    }
    .Frame263-left-status-label.new {
        background-color: #0060cc;
    }
    .RectangleBoxes.selected {
        border: 1px solid #fff;
    }
    .main-image-container {
        position: relative;
        height: 465px;
        background: var(--backgrounds-lines-n-500, #141922);
        width: 100%;
        border-radius: 4px;
    }
    .image-carousel-main {
        height: 55px;
        width: 100%;
        background: rgba(20, 25, 34, 0.8);
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        gap: 10px;
    }
    .image-carousel {
        height: 39px;
        width: 25px;
        background: #141922;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 7px;
    }
    .image-carousel img {
        height: 39px;
        width: 25px;
        border-radius: 4px;
        object-fit: cover;
        cursor: pointer;
    }
`;
/* #endregion */

/**
 * Renders a product card component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The class name for the component.
 * @param {string} props.image - The image URL for the product.
 * @param {string} props.title - The title of the product.
 * @param {Object} props.values - The values object containing product details.
 * @param {Object} props.values.key - The key of the value.
 * @param {Object} props.values.value - The value of the key.
 * @param {string} props.id - The product ID.
 * @param {Function} props.tagOnclick - The tag click event.
 * @param {Object} props.editIcon - The edit icon object.
 * @param {Function} props.editIcon.onClick - The edit icon click event.
 * @param {Object} props.primaryButton - The primary button object.
 * @param {string} props.primaryButton.title - The primary button title.
 * @param {Function} props.primaryButton.onClick - The primary button click event.
 * @param {Object} props.secondaryButton - The secondary button object.
 * @param {string} props.secondaryButton.title - The secondary button title.
 * @param {Function} props.secondaryButton.onClick - The secondary button click event.
 *
 * @returns {JSX.Element} The rendered product card component.
 */
export default function ProductCardComponent(props) {
    const { hideActionBtn = false } = props;
    /* #region VARS */
    const brand = getValues(props?.values, "Brand") || "--";
    const reference = getValues(props?.values, "Reference") || "--";
    const category = getValues(props?.values, "Category") || "--";
    const colors = getValues(props?.values, "Colors");
    const sizes = getValues(props?.values, "Sizes");
    const [isChecked, setIsChecked] = useState(props.isSelected);
    const [selectedColor, setSelectedColor] = useState(null);

    useEffect(() => {
        setIsChecked(props.isSelected);
    }, [props.isSelected]);
    /* #endregion */
    /* region METHODS */
    const handleCheckboxClick = () => {
        setIsChecked(!isChecked);
        props.onCheckboxClick(props?.productID, !isChecked);
    };
    const handleColorClick = color => {
        setSelectedColor(color);
    };
    const isNewCard = () => {
        const createdAt = new Date(props?.createdAt);
        const currentDate = new Date();
        const diffTime = Math.abs(currentDate - createdAt);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays < 7;
    };
    /* endregion */
    return (
        <StyledPage className={`${props?.className || ""}`}>
            <div
                className={`UiCardProduct ${
                    isChecked
                        ? "card-selected"
                        : props?.status?.toLowerCase() === "suspended"
                        ? "card-suspended"
                        : isNewCard()
                        ? "card-new"
                        : ""
                }`}>
                <div className="main-image-container">
                    <img
                        className="Image5"
                        src={props?.image || noImage}
                        alt={props?.title || "Grid main image"}
                        style={{ objectFit: props?.image ? "cover" : "contain" }}
                    />
                    {props?.isShowCarousel && props?.images?.length && (
                        <div className="image-carousel-main">
                            {props?.images?.slice(0, 7).map((image, index) => (
                                <div className="image-carousel" key={index}>
                                    <img src={image || noImage} alt={`carousel ${index}`} />
                                </div>
                            ))}
                            {props?.images.length > 7 && (
                                <div className="image-carousel">{props?.images.length - 7}</div>
                            )}
                        </div>
                    )}
                </div>
                <div className="Frame264">
                    <div className="Frame158">
                        <div className="Frame161">
                            <div style={{ display: "inline-flex", flexShrink: 1, overflow: "hidden" }}>
                                <span
                                    className={`StyleNameLeft ${
                                        props?.isCardEnabled ? "text-gray-100" : "text-gray-300"
                                    }`}>
                                    STYLE NAME:&nbsp;
                                </span>
                                <span
                                    className={`StyleNameRight ${
                                        props?.isCardEnabled ? "text-blue-500" : "text-gray-300"
                                    }`}>
                                    {props?.title || "--"}
                                </span>
                            </div>
                            <div
                                className="Frame163"
                                style={{ flexShrink: 0, display: hideActionBtn ? "none" : "flex" }}>
                                <Tooltips
                                    title="Edit"
                                    onClick={props?.editIcon?.onClick}
                                    children={
                                        <div
                                            className={`Pencil01 ${
                                                props?.editIcon?.onClick ? "" : "hover-not-allowed"
                                            }`}>
                                            <svg
                                                width="15"
                                                height="15"
                                                viewBox="0 0 15 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.666504 14.3329L4.36602 12.91C4.60264 12.819 4.72096 12.7735 4.83165 12.7141C4.92997 12.6613 5.0237 12.6004 5.11186 12.532C5.21112 12.4549 5.30075 12.3653 5.48002 12.186L12.9999 4.66622C13.7362 3.92984 13.7362 2.73593 12.9999 1.99955C12.2635 1.26317 11.0696 1.26317 10.3332 1.99955L2.81336 9.51936C2.63409 9.69864 2.54445 9.78827 2.46743 9.88752C2.39902 9.97569 2.3381 10.0694 2.28533 10.1677C2.22591 10.2784 2.1804 10.3967 2.08939 10.6334L0.666504 14.3329ZM0.666504 14.3329L2.03858 10.7655C2.13677 10.5102 2.18586 10.3826 2.27006 10.3241C2.34365 10.273 2.43471 10.2537 2.5227 10.2705C2.62339 10.2898 2.72009 10.3865 2.91349 10.5799L4.41955 12.0859C4.61295 12.2793 4.70965 12.376 4.72888 12.4767C4.74568 12.5647 4.72636 12.6558 4.67527 12.7293C4.6168 12.8135 4.48916 12.8626 4.23388 12.9608L0.666504 14.3329Z"
                                                    stroke={
                                                        props?.isCardEnabled && props?.editIcon?.onClick
                                                            ? "#00BB00"
                                                            : "var(--text-icons-gray-300)"
                                                    }
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    }
                                />
                                <Tooltips
                                    title={props?.status?.toLowerCase() === "suspended" ? "Re-Active" : "Suspend"}
                                    children={
                                        <div
                                            onClick={props?.suspendIcon?.onClick}
                                            className={`Pencil01 ${
                                                props?.suspendIcon?.onClick ? "" : "hover-not-allowed"
                                            }`}>
                                            {props?.status?.toLowerCase() === "suspended" ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="17"
                                                    height="16"
                                                    viewBox="0 0 17 16"
                                                    fill="none">
                                                    <path
                                                        d="M4.56072 4.23023C5.5267 3.26359 6.85966 2.66683 8.33326 2.66683C11.2788 2.66683 13.6666 5.05464 13.6666 8.00016C13.6666 10.9457 11.2788 13.3335 8.33326 13.3335C5.90277 13.3335 3.8504 11.7072 3.20831 9.482C3.10623 9.12824 2.7367 8.92422 2.38294 9.0263C2.02919 9.12838 1.82516 9.49791 1.92724 9.85166C2.72961 12.6322 5.29296 14.6668 8.33326 14.6668C12.0152 14.6668 14.9999 11.6821 14.9999 8.00016C14.9999 4.31826 12.0152 1.3335 8.33326 1.3335C6.49167 1.3335 4.82341 2.08109 3.61759 3.28775C3.19354 3.71209 2.7372 4.23864 2.33333 4.72972V2.66683C2.33333 2.29864 2.03486 2.00016 1.66667 2.00016C1.29848 2.00016 1 2.29864 1 2.66683V6.66683C1 7.03502 1.29848 7.3335 1.66667 7.3335H5.66667C6.03486 7.3335 6.33333 7.03502 6.33333 6.66683C6.33333 6.29864 6.03486 6.00016 5.66667 6.00016H3.02049C3.47655 5.42779 4.05123 4.74008 4.56072 4.23023Z"
                                                        fill="var(--text-icons-blue-500)"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    width="13"
                                                    height="16"
                                                    viewBox="0 0 13 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M8.33334 2.33301V7.33301V2.99967C8.33334 2.44739 8.78105 1.99967 9.33334 1.99967C9.88562 1.99967 10.3333 2.44739 10.3333 2.99967V7.33301V4.99967C10.3333 4.44739 10.7811 3.99967 11.3333 3.99967C11.8856 3.99967 12.3333 4.44739 12.3333 4.99967V10.6663C12.3333 12.8755 10.5425 14.6663 8.33334 14.6663H7.58179C6.56555 14.6663 5.58741 14.2795 4.84603 13.5845L1.5403 10.4854C1.06243 10.0374 1.05026 9.28275 1.51344 8.81957C1.96626 8.36675 2.70042 8.36675 3.15323 8.81957L4.33334 9.99967V4.33301C4.33334 3.78072 4.78105 3.33301 5.33334 3.33301C5.88562 3.33301 6.33334 3.78072 6.33334 4.33301V7.33301V2.33301C6.33334 1.78072 6.78105 1.33301 7.33334 1.33301C7.88562 1.33301 8.33334 1.78072 8.33334 2.33301Z"
                                                        stroke={
                                                            props?.isCardEnabled
                                                                ? "#FF9900"
                                                                : "var(--text-icons-gray-300)"
                                                        }
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                    }
                                />
                                <Tooltips
                                    title="Remove"
                                    children={
                                        <div
                                            onClick={props?.removeIcon?.onClick}
                                            className={`Pencil01 ${
                                                props?.removeIcon?.onClick ? "" : "hover-not-allowed"
                                            }`}>
                                            <svg
                                                width="14"
                                                height="16"
                                                viewBox="0 0 14 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9.66667 3.99967V3.46634C9.66667 2.7196 9.66667 2.34624 9.52134 2.06102C9.39351 1.81014 9.18954 1.60616 8.93865 1.47833C8.65344 1.33301 8.28007 1.33301 7.53333 1.33301H6.46667C5.71993 1.33301 5.34656 1.33301 5.06135 1.47833C4.81046 1.60616 4.60649 1.81014 4.47866 2.06102C4.33333 2.34624 4.33333 2.7196 4.33333 3.46634V3.99967M5.66667 7.66634V10.9997M8.33333 7.66634V10.9997M1 3.99967H13M11.6667 3.99967V11.4663C11.6667 12.5864 11.6667 13.1465 11.4487 13.5743C11.2569 13.9506 10.951 14.2566 10.5746 14.4484C10.1468 14.6663 9.58677 14.6663 8.46667 14.6663H5.53333C4.41323 14.6663 3.85318 14.6663 3.42535 14.4484C3.04903 14.2566 2.74307 13.9506 2.55132 13.5743C2.33333 13.1465 2.33333 12.5864 2.33333 11.4663V3.99967"
                                                    stroke={
                                                        props?.isCardEnabled
                                                            ? "#FF3636"
                                                            : props?.removeIcon?.onClick
                                                            ? "#FF3636"
                                                            : "var(--text-icons-gray-300)"
                                                    }
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        <div className="BotagId">
                            <span
                                className={`StyleNameLeft ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                BOTAG ID:&nbsp;
                            </span>
                            <span
                                className={`StyleNameRight ${
                                    props?.isCardEnabled ? "text-blue-500" : "text-gray-300"
                                }`}>
                                {props?.id || "--"}
                            </span>
                        </div>
                    </div>
                    <div className="Frame171">
                        <div className="Frame144">
                            <div
                                className={`Details ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}
                                style={{ height: "9px" }}>
                                DETAILS
                            </div>
                            <div className="Line1"></div>
                        </div>
                        <div className="Frame169">
                            <div className="FrameDetailLines">
                                <div
                                    className={`Reference ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                    Reference:&nbsp;
                                </div>
                                <div
                                    className={`ReferenceValue ${
                                        props?.isCardEnabled ? "text-blue-500" : "text-gray-300"
                                    }`}>
                                    {reference}
                                </div>
                            </div>
                            <div className="FrameDetailLines">
                                <div
                                    className={`Reference ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                    Collection/Year:&nbsp;
                                </div>
                                <div
                                    className={`ReferenceValue ${
                                        props?.isCardEnabled ? "text-blue-500" : "text-gray-300"
                                    }`}>
                                    {props?.collectionName || "--"}
                                </div>
                            </div>

                            <div className="FrameDetailLines Boxes">
                                <div className={`Colors ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                    Colors:&nbsp;
                                </div>
                                <div className="Frame264-2">
                                    {colors?.slice(0, 10).map((color, index) => {
                                        return (
                                            <div
                                                className={`RectangleBoxes ${
                                                    selectedColor === color ? "selected" : ""
                                                }`}
                                                style={{ background: getHexColor(color) }}
                                                key={index}
                                                onClick={() => handleColorClick(color)}
                                            />
                                        );
                                    }) ?? (
                                        <div className={`${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                            --
                                        </div>
                                    )}
                                    {colors?.length > 10 && (
                                        <div className="Frame383">
                                            <div className="Counter5">+{colors?.length - 10}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="FrameDetailLines Boxes">
                                <div className={`Sizes ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                    Sizes:&nbsp;
                                </div>
                                <div className="Frame385">
                                    {sizes?.slice(0, 6).map((size, index) => {
                                        return (
                                            <div className="SizeBoxes" key={index}>
                                                <div className="size">{size}</div>
                                            </div>
                                        );
                                    }) ?? (
                                        <div className={`${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                            --
                                        </div>
                                    )}
                                    {sizes?.length > 6 && (
                                        <div className="SizeBoxes">
                                            <div className="Counter3">+{sizes?.length - 6}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="FrameDetailLines">
                                <div className={`Brand ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                    Stores:&nbsp;
                                </div>
                                <div
                                    className={`TheProduct ${
                                        props?.isCardEnabled ? "text-blue-500" : "text-gray-300"
                                    }`}>
                                    {props?.totalStoresWithStock || "--"}{" "}
                                    {props?.lastStockCheck ? `( ${props?.lastStockCheck} )` : ""}
                                </div>
                            </div>
                            <div className="FrameDetailLines">
                                <div className={`Brand ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                    Stock:&nbsp;
                                </div>
                                <div
                                    className={`TheProduct ${
                                        props?.isCardEnabled ? "text-blue-500" : "text-gray-300"
                                    }`}>
                                    {props?.totalStockInStores || "--"}{" "}
                                    {props?.lastStockCheck ? `( ${props?.lastStockCheck} )` : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Frame164">
                        {
                            <BtnLargeSolidInverted02
                                disabled={props?.primaryButton?.onClick === undefined}
                                text={props?.primaryButton?.title ?? "DETAILS"}
                                onClick={props?.primaryButton?.onClick}
                                style={{ flexGrow: "1" }}
                            />
                        }
                        {
                            <BtnLargeSolidInverted02
                                disabled={props?.secondaryButton?.onClick === undefined}
                                text={props?.secondaryButton?.title ?? "BOIMAGE"}
                                onClick={props?.secondaryButton?.onClick}
                                style={{ flexGrow: "1" }}
                            />
                        }
                    </div>
                </div>
                <div className={"Frame263-left"}>
                    <Checkbox isChecked={isChecked} onCheckboxClick={handleCheckboxClick} />
                </div>
                {props?.status?.toLowerCase() === "suspended" || isNewCard() ? (
                    <div
                        className={`Frame263-left-status-label ${
                            props?.status?.toLowerCase() !== "suspended" && isNewCard() ? "new" : ""
                        }`}>
                        {props?.status?.toLowerCase() !== "suspended" && isNewCard() ? "New" : props?.status}
                    </div>
                ) : (
                    ""
                )}

                <div className={`Frame263 ${props?.tagOnclick ? "cursor-pointer" : ""}`} onClick={props?.tagOnclick}>
                    <svg
                        className={`Tag03 ${props?.tagOnclick ? "" : "hover-not-allowed"}`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none">
                        <path
                            d="M14 7.3335L8.93726 2.27075C8.59135 1.92485 8.4184 1.7519 8.21657 1.62822C8.03762 1.51856 7.84254 1.43775 7.63846 1.38876C7.40829 1.3335 7.16369 1.3335 6.67452 1.3335L4 1.3335M2 5.80016L2 7.11651C2 7.44263 2 7.60569 2.03684 7.75914C2.0695 7.89519 2.12337 8.02525 2.19648 8.14454C2.27894 8.2791 2.39424 8.3944 2.62484 8.625L7.82484 13.825C8.35286 14.353 8.61687 14.617 8.92131 14.716C9.1891 14.803 9.47757 14.803 9.74536 14.716C10.0498 14.617 10.3138 14.353 10.8418 13.825L12.4915 12.1753C13.0195 11.6473 13.2835 11.3833 13.3825 11.0789C13.4695 10.8111 13.4695 10.5226 13.3825 10.2548C13.2835 9.95037 13.0195 9.68636 12.4915 9.15834L7.62484 4.29167C7.39424 4.06107 7.27894 3.94577 7.14438 3.86331C7.02508 3.7902 6.89502 3.73633 6.75898 3.70367C6.60553 3.66683 6.44247 3.66683 6.11634 3.66683H4.13333C3.3866 3.66683 3.01323 3.66683 2.72801 3.81215C2.47713 3.93999 2.27316 4.14396 2.14532 4.39484C2 4.68006 2 5.05343 2 5.80016Z"
                            stroke={props?.tagOnclick ? "var(--text-icons-gray-100)" : "var(--text-icons-gray-300)"}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </div>
        </StyledPage>
    );
}

const getValues = (arrayValues, key) => {
    return arrayValues?.find(it => it?.key === key)?.value;
};

const getHexColor = color => {
    return "#" + color.replace("#", "");
};
