import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { GoogleMap, InfoBox, Marker } from "@react-google-maps/api";
import styled from "styled-components";
import Map from "../../../assets/icons/map-010.svg";
import axios from "axios";
import { fetchAddressFromCoordinates, getLatLngUsingPlusCode } from "../../../utils/LocationLib";
import FormField from "./FormField";
import FormCard from "./FormCard";
import useDebounce from "../../../utils/hooks/useDebounce";
import { triggeredTypes } from "../../../utils/utilHelpers";

/* #region SCOPED CSS */
const StyledStoreLocation = styled.div`
    &.input-field-card-layout-3,
    &.input-field-card-layout-3 * {
        box-sizing: border-box;
    }
    &.input-field-card-layout-3 {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 4px;
        padding: 16px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }

    //FIX MINOR HEIGHT ISSUE
    .BO-card-component {
        //height: 343px;
    }

    .BO-card-body {
        position: relative;
        top: -1px;
    }

    .Icon {
        width: 10px;
        height: 10px;
        font-size: 9px;
        font-weight: 500;
        color: var(--text-icons-gray-200);
        position: relative;
        top: -1px;
    }
    .frame-248 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .title {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 12px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .frame-49 {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 38px;
        position: relative;
        overflow: hidden;
    }
    .map {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 1;
        width: 393px;
        height: calc(100% - 12px);
        position: relative;
    }
    .frame-241 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle10 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .on-map {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 12px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .frame-483 {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        overflow: hidden;
    }
    .frame-244 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-178 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .map-01 {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        position: relative;
        overflow: visible;
    }
    .disabled {
        cursor: not-allowed;
        //pointer-events: none; //TODO: SHOULD THIS EXIST? IT MAKES THE CURSOR NOT CHANGE WHEN HOVERING
    }
    // _____ new map styles

    .gmnoprint .gm-style-mtc {
        display: none;
    }
    .gm-svpc {
        display: none;
    }
    .gmnoprint button.gm-control-active {
        background: #212121 !important;
        border-radius: 10px !important;
        margin: 0px 0 4px !important;
    }

    .gmnoprint button.gm-control-active img {
        display: none;
    }
    .gmnoprint button.gm-control-active:first-child::after {
        content: "\\2b";
        font-family: "Font Awesome 6 Pro";
        color: #fff;
        font-size: 18px;
        font-weight: 100;
    }
    .gmnoprint button.gm-control-active:last-child::after {
        content: "\\f068";
        font-family: "Font Awesome 6 Pro";
        color: #fff;
        font-size: 18px;
        font-weight: 100;
    }
    .gm-control-active.gm-fullscreen-control {
        background-color: #212121 !important;
        border-radius: 10px !important;
    }

    .gm-control-active.gm-fullscreen-control::after {
        content: "\\f31d";
        font-family: "Font Awesome 6 Pro";
        color: #fff;
        font-size: 18px;
        font-weight: 100;
    }
    .gm-control-active.gm-fullscreen-control img {
        display: none;
    }
    .gm-style-cc {
        display: none !important;
    }
    .gmnoprint > div > div {
        background: transparent !important;
        box-shadow: none !important;
    }
    .infoBox {
        margin-top: -40px;
        margin-left: 18px;
    }
`;
/* #endregion SCOPED CSS */

/**
 * AddressFormCardStandard component renders a form for entering and displaying address details.
 * It includes fields for Google Plus Code, country, city, postal code, address lines, latitude, and longitude.
 * The component also integrates with Google Maps for location selection and adjustment.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} [props.cardTitle=""] - Title of the card.
 * @param {string} [props.cardClassName=""] - Additional class names for the card.
 * @param {boolean} [props.useGoogleMapsDarkMode=true] - Flag to use Google Maps dark mode.
 * @param {string} [props.pinLabel] - Label for the pin on the map.
 * @param {string} [props.unnamedPinLabel="Unnamed location"] - Default label for unnamed pin.
 * @param {Function} props.onLocationDetailsChange - Callback function to handle changes in location details.
 * @param {Function} props.onFormChanged - Callback function to handle changes in the form.
 * @param {Object} props.locationDetails - The details of the location.
 * @param {string} props.locationDetails.googlePlusCode - The Google Plus Code of the location.
 * @param {string} props.locationDetails.country - The country of the address.
 * @param {string} props.locationDetails.city - The city of the address.
 * @param {string} props.locationDetails.postalCode - The postal code of the address.
 * @param {string} props.locationDetails.addressOne - The first line of the address.
 * @param {string} props.locationDetails.addressTwo - The second line of the address.
 * @param {number} props.locationDetails.latitude - The latitude of the location.
 * @param {number} props.locationDetails.longitude - The longitude of the location.
 * @param {boolean} [props.hasKYCAssistiveText=false] - This Flag should only be used in Company create page! Flag to show KYC assistive text
 * @param {boolean} [props.usesShoppingCenter=false] - Flag to indicate if the location is a shopping center.
 * @param {React.ReactNode} [props.afterTitleComponent] - Component to render after the title.
 * @param {React.ReactNode} [props.handleGetOpenHours] - Method to get the opening hours from google.
 * @param {React.ReactNode} [props.handleTextInputBlur] - Method to check an Input blur.
 * @param {Object} [props.style] - The CSS style for the component.
 * @returns {JSX.Element} The rendered AddressFormCardStandard component.
 */
const AddressFormCardStandard = forwardRef((props, ref) => {
    const {
        cardTitle = "",
        cardClassName = "",
        useGoogleMapsDarkMode = true,
        pinLabel,
        unnamedPinLabel = "Unnamed location",
        locationDetails,
        onLocationDetailsChange,
        onFormChanged,
        hasKYCAssistiveText = false,
        usesShoppingCenter = false,
        afterTitleComponent,
        showDefaultRightLegends = true,
        hideFormContainer = false,
        style,
        handleGetOpenHours = () => {},
        handleTextInputBlur = () => {}
    } = props;
    /* #region VARS */
    const formCardRef = useRef();

    //TODO: FOR NOW THE locationDetails and onLocationDetailsChange ARE REQUIRED. Refactor to make them optional.
    const {
        //initialized, -> this will prevent the component from updating the locationDetails when it is not initialized
        googlePlusCode,
        country,
        city,
        postalCode,
        addressOne,
        addressTwo,
        latitude,
        longitude,
        shoppingCenter,
        shoppingCenterName,
        shoppingCenterStoreNumber,
        shoppingCenterFloor
    } = locationDetails;

    const [locationData, setLocationData] = useState(locationDetails);
    const [changedFromOutside, setChangedFromOutside] = useState(false);
    const [googlePlusCodeValue, setGooglePlusCodeValue] = useState("");
    const debouncedText = useDebounce(googlePlusCodeValue, 1000);

    const [showShoppingCenterFields, setShowShoppingCenterFields] = useState(false);
    /* #endregion */

    /* #region METHODS */
    const onGooglePlusCodeChange = e => {
        setLocationData({
            ...locationData,
            googlePlusCode: e,
            initialized: true
        });
        fetchData(e);
        setGooglePlusCodeValue(e);
    };

    async function fetchData(googlePlusCode) {
        if (googlePlusCode) {
            const res = await getLatLngUsingPlusCode(googlePlusCode);
            if (res) {
                fetchAddressFromCoordinates(res.lat, res.lng).then(res2 => {
                    setLocationData(prev => ({
                        ...prev,
                        latitude: res?.lat,
                        longitude: res?.lng,
                        addressOne: res2?.street || "",
                        addressTwo: res2?.streetNumber || "",
                        postalCode: res2?.postCode || "",
                        country: res2?.country || "",
                        city: res2?.city || ""
                    }));
                });
            } else {
                setLocationData(prev => ({
                    ...prev,
                    latitude: "",
                    longitude: "",
                    addressOne: "",
                    addressTwo: "",
                    postalCode: "",
                    country: "",
                    city: ""
                }));
            }
        }
    }

    const allFieldsAreValid = (highlightValidation = true) => {
        const isValid = formCardRef.current.allFieldsAreValid(highlightValidation);
        return isValid;
    };

    const getAllFieldValues = () => {
        return formCardRef.current.getAllFieldValues();
    };

    const checkRequireType = field => {
        if (!country || !city || !latitude || !longitude) return "mandatoryLocked";

        if (!googlePlusCode) {
            if (field === "postalCode") return "mandatoryLocked";
            if (field === "addressOne") return "mandatoryLocked";
            if (field === "addressTwo") return "locked";
        }

        if (field === "postalCode") return !postalCode ? "mandatory" : "mandatoryLocked";
        else if (field === "addressOne") return !addressOne ? "mandatory" : "mandatoryLocked";
        else if (field === "addressTwo") return !addressTwo ? "simple" : "locked";
    };

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!locationDetails || !locationDetails?.initialized) return;

        setChangedFromOutside(true);
        setLocationData(locationDetails);
    }, [locationDetails]);

    useEffect(() => {
        if (!onLocationDetailsChange) return;
        if (!locationData || !locationData?.initialized) return;

        if (changedFromOutside) {
            setChangedFromOutside(false);
            return;
        }
        onLocationDetailsChange(locationData);
    }, [locationData]);

    useEffect(() => {
        if (debouncedText?.trim() !== "") {
            handleGetOpenHours({ triggeredFrom: triggeredTypes.FORM_INPUT });
        }
    }, [debouncedText]);

    useEffect(() => {
        if (shoppingCenter === "true" || shoppingCenter === true) {
            setShowShoppingCenterFields(true);
        } else {
            setShowShoppingCenterFields(false);
        }
    }, [shoppingCenter]);
    /* #endregion */

    /* #region FORM FIELDS */

    const formFields1Refs = [];
    const FormCardFields1 = [
        <FormField
            type="text"
            title="Address or Google Plus Code"
            tooltip="Enter the address or Google Plus Code of the location"
            requiredType="mandatory"
            value={googlePlusCode}
            onTextChange={text => onGooglePlusCodeChange(text)}
            varName="googlePlusCode"
            ref={el => (formFields1Refs.googlePlusCode = el)}
            onTextBlur={() => {
                handleTextInputBlur();
            }}
        />,
        usesShoppingCenter ? (
            <FormField
                type="dropdown"
                title="Shopping Center?"
                tooltip="Shopping Center?"
                requiredType="mandatory"
                dropdownData={[
                    { name: "No", value: "false", selected: shoppingCenter !== "true" && shoppingCenter !== true },
                    { name: "Yes", value: "true", selected: shoppingCenter === "true" || shoppingCenter === true }
                ]}
                varName="shoppingCenter"
                ref={el => (formFields1Refs.shoppingCenter = el)}
                onDropdownChange={item => {
                    if (item?.value === "true") {
                        setShowShoppingCenterFields(true);
                    } else {
                        setShowShoppingCenterFields(false);
                    }

                    setLocationData(prev => ({
                        ...prev,
                        shoppingCenter: item?.value
                    }));

                    if (onFormChanged) onFormChanged(false);
                }}
            />
        ) : undefined,
        <FormField
            type="dropdown"
            title="Country"
            tooltip="Enter the country of the address"
            requiredType="mandatoryLocked"
            dropdownData={[{ name: country, value: country, selected: true }]}
            assistiveText={hasKYCAssistiveText ? "KYC" : ""}
            varName="country"
            ref={el => (formFields1Refs.country = el)}
        />,
        <FormField
            type="dropdown"
            title="City"
            tooltip="Enter the city of the address"
            requiredType="mandatoryLocked"
            //value={city}
            dropdownData={[{ name: city, value: city, selected: true }]}
            assistiveText={hasKYCAssistiveText ? "KYC" : ""}
            varName="city"
            ref={el => (formFields1Refs.city = el)}
        />,
        <FormField
            type="text"
            title="Postal Code"
            tooltip="Enter the postal code of the address"
            requiredType={checkRequireType("postalCode")}
            value={postalCode}
            assistiveText={hasKYCAssistiveText ? "KYC" : ""}
            varName="postalCode"
            ref={el => (formFields1Refs.postalCode = el)}
        />,
        <FormField
            type="text"
            title="Address 1"
            tooltip="Enter the first line of the address"
            requiredType={checkRequireType("addressOne")}
            value={addressOne}
            assistiveText={hasKYCAssistiveText ? "KYC" : ""}
            varName="addressOne"
            ref={el => (formFields1Refs.addressOne = el)}
        />,
        <FormField
            type="text"
            title="Address 2"
            tooltip="Enter the second line of the address"
            requiredType={checkRequireType("addressTwo")}
            value={addressTwo}
            varName="addressTwo"
            ref={el => (formFields1Refs.addressTwo = el)}
        />,
        <FormField
            type="text"
            title="Latitude"
            tooltip="Enter the latitude of the location"
            requiredType="mandatoryLocked"
            value={latitude}
            varName="latitude"
            ref={el => (formFields1Refs.latitude = el)}
        />,
        <FormField
            type="text"
            title="Longitude"
            tooltip="Enter the longitude of the location"
            requiredType="mandatoryLocked"
            value={longitude}
            varName="longitude"
            ref={el => (formFields1Refs.longitude = el)}
        />,
        showShoppingCenterFields ? (
            <FormField
                type="text"
                title="Shopping center"
                tooltip="Enter the shopping center of the location"
                requiredType="recommended"
                value={shoppingCenterName}
                varName="shoppingCenterName"
                ref={el => (formFields1Refs.shoppingCenterName = el)}
            />
        ) : undefined,
        showShoppingCenterFields ? (
            <FormField
                type="number"
                title="Store Number"
                tooltip="Enter the store number"
                requiredType="recommended"
                value={shoppingCenterStoreNumber}
                varName="shoppingCenterStoreNumber"
                ref={el => (formFields1Refs.shoppingCenterStoreNumber = el)}
            />
        ) : undefined,
        showShoppingCenterFields ? (
            <FormField
                type="text"
                title="Floor"
                tooltip="Enter the floor"
                requiredType="recommended"
                value={shoppingCenterFloor}
                varName="shoppingCenterFloor"
                ref={el => (formFields1Refs.shoppingCenterFloor = el)}
            />
        ) : undefined
    ];

    /* #endregion */

    useImperativeHandle(ref, () => ({
        getAllFieldValues,
        allFieldsAreValid
    }));

    return (
        <StyledStoreLocation className={cardClassName}>
            <FormCard
                ref={formCardRef}
                title={cardTitle}
                numOfFieldsPerLine={3}
                showDefaultRightLegends={showDefaultRightLegends}
                hideFormContainer={hideFormContainer}
                style={style}
                formFields={FormCardFields1.filter(item => item)}
                formFieldRefs={formFields1Refs}
                rightContent={
                    <RightContentMapHandler
                        pinLabel={pinLabel}
                        unnamedPinLabel={unnamedPinLabel}
                        locationData={locationData}
                        setLocationData={setLocationData}
                        useGoogleMapsDarkMode={useGoogleMapsDarkMode}
                    />
                }
                onFormChanged={() => {
                    allFieldsAreValid(false);
                    if (onFormChanged) onFormChanged(false);
                }}
                showUnsavedChangesPopup={true}
                afterTitleComponent={afterTitleComponent}
            />
        </StyledStoreLocation>
    );
});

export default AddressFormCardStandard;

/* #region SMALL COMPONENTS */
const RightContentMapHandler = ({
    pinLabel,
    unnamedPinLabel,
    locationData,
    setLocationData,
    useGoogleMapsDarkMode
}) => {
    const { latitude, longitude } = locationData;

    return (
        <div className="map" key="map" style={{ gap: "2px" }}>
            <div className="frame-241 pl-5px">
                <i className={"Icon fas fa-info-circle"} aria-hidden="true"></i>
                <div className="on-map">On map (drag pin to adjust)</div>
            </div>
            {!longitude || !latitude ? (
                <div className="frame-483">
                    <div className="frame-244">
                        <div className="frame-178">
                            <img className="map-01" src={Map} alt="" />
                        </div>
                    </div>
                </div>
            ) : (
                <GoogleMapsStore
                    locationData={locationData}
                    setLocationData={setLocationData}
                    unnamedPinLabel={pinLabel || unnamedPinLabel}
                    useGoogleMapsDarkMode={useGoogleMapsDarkMode}
                />
            )}
        </div>
    );
};

const GoogleMapsStore = ({ unnamedPinLabel, locationData, setLocationData, useGoogleMapsDarkMode }) => {
    /* #region VARS */
    const { latitude, longitude } = locationData;

    const location = { lat: latitude, lng: longitude };
    const options = { closeBoxURL: "", enableEventPropagation: false };
    /* #endregion */

    /* #region METHODS */
    const handleDragMarker = async e => {
        const center = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        };

        const newLocationData = {
            ...locationData,
            latitude: center.lat,
            longitude: center.lng
        };

        const res1 = await getPlusCodeUsingLatLng(center.lat, center.lng);
        if (res1) newLocationData.googlePlusCode = res1;

        const res = await fetchAddressFromCoordinates(center.lat, center.lng);
        if (res) {
            newLocationData.addressOne = res?.street;
            newLocationData.addressTwo = res?.streetNumber;
            newLocationData.postalCode = res?.postCode;
            newLocationData.country = res?.country;
            newLocationData.city = res?.city;
        }

        setLocationData(newLocationData);
    };

    const getPlusCodeUsingLatLng = async (lat, lng) => {
        const result = await axios.get("https://plus.codes/api?address=" + lat + "," + lng);

        if (result.data?.status === "OK") {
            return result.data?.plus_code?.global_code;
        }
        return null;
    };
    /* #endregion */

    return (
        <GoogleMap
            mapContainerStyle={{
                width: "100%",
                height: "100%",
                borderRadius: "4px"
            }}
            center={location}
            zoom={10}
            clickableIcons={false}
            options={{
                disableDefaultUI: true,
                zoomControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                keyboardShortcuts: false,
                streetViewControlOptions: false,
                styles: useGoogleMapsDarkMode ? GoogleMapsDarkStyles : [],
                draggable: true
            }}>
            <Marker onDragEnd={handleDragMarker} position={location} draggable={true} />
            <InfoBox options={options} position={location}>
                <div style={{ width: "140px", opacity: 1, paddingTop: "9px" }}>
                    <div
                        style={{
                            fontSize: "12px",
                            width: "140px",
                            color: "white",
                            padding: "0px",
                            fontWeight: 700
                        }}>
                        {unnamedPinLabel || "Unnamed location"}
                    </div>
                </div>
            </InfoBox>
        </GoogleMap>
    );
};

const GoogleMapsDarkStyles = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    {
        elementType: "labels.text.stroke",
        stylers: [{ color: "#242f3e" }]
    },
    {
        elementType: "labels.text.fill",
        stylers: [{ color: "#909eb2" }]
    },
    {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d3d9e1" }]
    },
    {
        featureType: "landscape",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ffffff" }]
    },
    {
        featureType: "poi",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#154043" }]
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }]
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#42566d" }]
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }]
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }]
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#42566d" }]
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#000000" }]
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ffffff" }]
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }, { visibility: "off" }]
    },
    {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }]
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#000000" }]
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#8ab4f8"
            }
        ]
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#8ab4f8" }]
    },
    {
        selector: ":focus",
        stylers: [{ outline: "none" }]
    },
    {
        selector: "*:focus",
        stylers: [{ outline: "none" }]
    }
];
/* #endregion */
