import StockPage from "../components/pages/stock/StockPage";

const routes = [
    {
        path: "/stock",
        element: <StockPage />
    }
];

export default routes;
