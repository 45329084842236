import React, { useEffect, useRef, useState } from "react";
import AppContext from "../../../AppContext";
import styled from "styled-components";
import CloseIcon from "../../../assets/icons/close-btn.svg";

const StyledBeacon = styled.div`
    @keyframes pulse-white {
        0% {
            transform: scale(1.2);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
        }

        100% {
            transform: scale(0.9);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
    }

    &.BeaconContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        position: absolute;
        user-select: none;
        touch-action: none;
        //cursor: pointer;
    }

    .BotagBeacon {
        position: relative;
        background-color: #0000004d;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: pulse-white 1.5s infinite;
    }

    .BotagBeacon .BotagBeaconInner {
        background-color: #ffffff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
    }

    .product-label {
        background-color: rgba(16, 20, 27, 0.3);
        border-radius: 20px;
        color: #fff;
        padding: 8px 15px;
        position: absolute;
        top: 45px;
        white-space: nowrap;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

/**
 * Renders an image with draggable tags.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The class name for the component.
 * @param {Object} props.style - The inline styles for the component.
 * @param {string} props.src - The source URL of the image.
 * @param {string} props.alt - The alt text for the image.
 * @param {boolean} [props.allowEdit=true] - A flag to allow editing the tags.
 * @param {number} [props.imageExtraScale=1] - The extra scale for the image.
 * @param {boolean} [props.useLayout2=false] - A flag to use the second layout.
 * @param {boolean} [props.allowScroll=false] - A flag to allow scrolling.
 * @returns {JSX.Element} The rendered ImageTags component.
 */
export default function ImageTags(props) {
    const {
        allowEdit = true,
        imageExtraScale = 1,
        useLayout2 = false,
        reloadScale,
        setReloadScale,
        showImageRemoveButton = false,
        onImageRemove
    } = props;

    /* #region SCOPED STYLES */
    const Rectangle302 = {
        width: "fit-content",
        height: "100%",
        objectFit: "contain"
    };

    const ImageTagsContainer = {
        position: "relative",
        flex: "1 1 0",
        background: "#10141b",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "9px",
        display: "flex",
        width: "fit-content",
        height: "100%"
    };
    /* #endregion */

    /* #region VARS */
    const {
        BOTagsList,
        setBOTagsList,
        setSidePanelVisible,
        setSidePanelSection,
        setSidePanelSelectedProductID,
        setSidePanelSelectedProductNumber,

        sidePanelVisible
    } = React.useContext(AppContext);
    const imageRef = useRef(null);
    const imageTagsContainerRef = useRef(null);

    const [isDragging, setIsDragging] = useState(undefined);
    const [currentTagDragging, setCurrentTagDragging] = useState(null);
    const [currentTagDidMove, setCurrentTagDidMove] = useState(false);
    const [imageScale, setImageScale] = useState(1);

    const [imageOffsetX, setImageOffsetX] = useState(0);
    const [imageOffsetY, setImageOffsetY] = useState(0);

    const [animationKey, setAnimationKey] = useState(Math.random() * 1000);
    const [previousBOTagsCount, setPreviousBOTagsCount] = useState(0);
    /* #endregion */

    /* #region EFFECTS */

    // LISTEN TO MOUSE UP EVENT EVERYWHERE, when the component mounts
    useEffect(() => {
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, []);

    // CALCULATES THE IMAGE SCALE, when the image loads
    useEffect(() => {
        if (imageRef.current) {
            imageRef.current.addEventListener("load", reloadScaleValue);
        }

        return () => {
            if (imageRef.current) {
                imageRef.current.removeEventListener("load", reloadScaleValue);
            }
        };
    }, [imageRef.current?.src, imageRef.current?.naturalWidth, BOTagsList]);

    // RELOAD THE BOTAGS SCALING, when the image scale changes
    useEffect(() => {
        if (!BOTagsList || BOTagsList.length === 0) return;

        reloadBOTagsScaling();
    }, [imageScale]);

    // SELECTS THE PRODUCT, when the dragging stops and the tag did not move
    useEffect(() => {
        if (isDragging !== false) return;

        if (!currentTagDidMove) {
            handleSelectProduct(currentTagDragging);
        } else {
            setCurrentTagDidMove(false);
        }
    }, [isDragging]);

    useEffect(() => {
        if (reloadScale) {
            setReloadScale(false);
            reloadScaleValue();
            reloadBOTagsScaling();
        }
    }, [reloadScale]);

    useEffect(() => {
        if (!useLayout2) return;

        const currentBOTagsIDs = BOTagsList.length;
        if (currentBOTagsIDs === previousBOTagsCount) return;

        setPreviousBOTagsCount(currentBOTagsIDs);
        const newAnimationKey = Math.random() * 1000;
        setAnimationKey(newAnimationKey);
    }, [BOTagsList]);
    /* #endregion */

    /* #region METHODS */
    const handleImageClick = event => {
        if (!allowEdit) return;
        event.preventDefault();

        if (sidePanelVisible) {
            return;
        }

        const rect = event.target.getBoundingClientRect();
        const x = event.clientX - rect.left; // x position within the element.
        const y = event.clientY - rect.top; // y position within the element.

        //calculate the number of the beacon by fetching the maximum number and adding 1, if there are no beacons, the number will be 1
        const number = BOTagsList.length > 0 ? Math.max(...BOTagsList.map(tag => tag.number)) + 1 : 1;

        const newTag = tagObject({ x, y, number, naturalX: x / imageScale, naturalY: y / imageScale, selected: false });

        //add new tag to the list and unselects all
        setBOTagsList(BOTagsList.map(tag => ({ ...tag, selected: false })).concat(newTag));

        setSidePanelSelectedProductID(undefined);
        setSidePanelSelectedProductNumber(undefined);
    };

    const handleMouseMove = event => {
        if (!allowEdit) return;
        const rect = event.target.getBoundingClientRect();
        let x = event.clientX;
        let y = event.clientY;

        //if event.target is not the image, return
        if (event.target.tagName === "IMG") {
            x = event.clientX - rect.left; // x position within the element.
            y = event.clientY - rect.top; // y position within the element.
            setImageOffsetX(rect.left);
            setImageOffsetY(rect.top);
        } else {
            x = event.clientX - imageOffsetX; // x position within the element.
            y = event.clientY - imageOffsetY; // y position within the element.
        }

        if (!isDragging) return;
        setCurrentTagDidMove(true);

        setBOTagsList(
            BOTagsList.map(tag => {
                if (tag.number !== currentTagDragging) return tag;
                else {
                    const currentTag = tag;

                    tag.x = x;
                    tag.y = y;
                    tag.naturalX = x / imageScale;
                    tag.naturalY = y / imageScale;
                    tag.data.beaconXposition = tag.naturalX;
                    tag.data.beaconYposition = tag.naturalY;

                    return currentTag;
                }
            })
        );
    };

    const handleMouseDown = (event, number) => {
        if (!allowEdit) return;
        event.preventDefault();
        setCurrentTagDragging(number);
        setIsDragging(true);

        const selectedTag = BOTagsList.find(tag => tag.number === number);
        if (selectedTag.completed) {
            setBOTagsList(BOTagsList.map(tag => ({ ...tag, selected: false })));
            setSidePanelSelectedProductNumber(undefined);
            setSidePanelSection("Linked Products");
            return;
        }
        setSidePanelSection("Products");
    };

    const handleMouseUp = event => {
        if (!allowEdit) return;
        event.preventDefault();

        if (!isDragging) {
            setIsDragging(false);
            return;
        }
        setIsDragging(false);
    };

    const onRemoveBeacon = (event, number) => {
        if (!allowEdit) return;
        event.preventDefault();

        const newBotagsList = BOTagsList.filter(tag => tag.number !== number).map((tag, index) => ({
            ...tag,
            number: index + 1
        }));
        setBOTagsList(newBotagsList);
    };

    const reloadScaleValue = () => {
        const { width } = imageRef.current.getBoundingClientRect();
        const naturalWidth = imageRef.current.naturalWidth;
        const scale = width / naturalWidth;
        setImageScale(scale);
    };

    const reloadBOTagsScaling = () => {
        const newBOTagsList = BOTagsList.map(tag => ({
            ...tag,
            x: tag.naturalX * imageScale,
            y: tag.naturalY * imageScale
        }));
        setBOTagsList(newBOTagsList);
    };

    const handleSelectProduct = number => {
        if (!allowEdit) return;
        const selectedTag = BOTagsList.find(tag => tag.number === number);
        let newBOTags = BOTagsList;
        if (selectedTag?.completed) {
            setSidePanelSection("Linked Products");
            newBOTags = newBOTags.map(tag => ({ ...tag, selected: false }));
            setSidePanelSelectedProductNumber(undefined);
        } else {
            setSidePanelSection("Products");
            const selectNewState = selectedTag?.selected ? false : true;
            newBOTags = newBOTags.map(tag =>
                tag.number === number ? { ...tag, selected: selectNewState } : { ...tag, selected: false }
            );
            if (selectNewState) setSidePanelSelectedProductNumber(number);
            else setSidePanelSelectedProductNumber(undefined);
        }

        setBOTagsList(newBOTags);
        if (number) setSidePanelVisible(true);
    };
    /* #endregion */

    return (
        <div
            className={`ImageTagsContainer ${props?.className ?? ""}`}
            style={{ ...ImageTagsContainer, ...props?.style }}
            ref={imageTagsContainerRef}
            onMouseMove={handleMouseMove}>
            {showImageRemoveButton && <CloseButton onClick={onImageRemove} />}
            <img
                className="TagsContainerElement"
                ref={imageRef}
                style={Rectangle302}
                src={props?.src}
                onClick={handleImageClick}
                draggable="false"
                alt={props?.alt ?? "Canvas"}
            />
            {BOTagsList.map(
                (tag, index) =>
                    tag?.x &&
                    tag?.y &&
                    tag?.number &&
                    (!useLayout2 ? (
                        <TagElement
                            key={index}
                            className="TagsContainerElement"
                            x={tag.x * imageExtraScale}
                            y={tag.y * imageExtraScale}
                            number={tag.number}
                            isSelected={tag.selected}
                            isCompleted={tag.completed}
                            onRemoveBeacon={onRemoveBeacon}
                            onMouseDown={handleMouseDown}
                            allowEdit={allowEdit}
                        />
                    ) : (
                        <Beacon
                            key={index + animationKey}
                            className="TagsContainerElement"
                            x={tag.x * imageExtraScale}
                            y={tag.y * imageExtraScale}
                            label={tag?.data?.category}
                        />
                    ))
            )}
        </div>
    );
}

/**
 * Represents a tag element on the image.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.x - The x-coordinate of the tag element.
 * @param {number} props.y - The y-coordinate of the tag element.
 * @param {number} props.number - The number associated with the tag element.
 * @param {Function} props.onRemoveBeacon - The function to remove the tag element.
 * @param {Function} props.onMouseDown - The function to handle the mouse down event.
 * @param {Function} props.onMouseMove - The function to handle the mouse move event.
 * @param {Function} props.onMouseUp - The function to handle the mouse up event.
 * @param {boolean} [props.isSelected=false] - A flag to indicate if the tag element is selected.
 * @returns {JSX.Element} The rendered tag element.
 */
const TagElement = ({
    x,
    y,
    number,
    isSelected = false,
    isCompleted = false,
    onRemoveBeacon,
    onMouseDown,
    allowEdit
}) => {
    /* #region SCOPED STYLES */
    const BotagBeacon = {
        display: "flex",
        width: "35px",
        height: "35px",
        padding: "10px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "58px",
        background: isSelected
            ? "var(--text-icons-green-500)"
            : isCompleted
            ? "var(--Buttons-All-Platforms-btn-action-blue)"
            : "var(--text-icons-orange-500)",
        outline: isSelected
            ? "10px rgba(0, 187, 0, 0.2) solid"
            : isCompleted
            ? "10px rgba(0, 96, 204, 0.10) solid"
            : "10px rgba(255, 153, 0, 0.2) solid",
        position: "absolute",
        cursor: allowEdit ? "grab" : "default",
        userSelect: "none",
        touchAction: "none"
    };

    const Close = {
        width: "15.5px",
        height: "15.5px",
        position: "absolute",
        right: "-4px",
        top: "-4px"
    };

    const Ellipse5 = {
        width: "15.5px",
        height: "15.5px",
        left: "0px",
        top: "0px",
        position: "absolute",
        background: "#ff3636",
        borderRadius: "9999px"
    };

    const XClose = {
        width: "10.33px",
        height: "10.33px",
        left: "3px",
        top: "2.58px",
        display: "flex",
        position: "relative",
        justifyContent: "center",
        alignItems: "center"
    };

    const BeaconNumber = {
        color: "white",
        fontSize: "20px",
        fontFamily: "Roboto",
        fontWeight: "400",
        letterSpacing: "0.08px",
        wordWrap: "break-word",
        width: "35px",
        textAlign: "center"
    };
    /* #endregion */

    /* #region METHODS */
    const handleMouseDown = event => {
        event.preventDefault();
        onMouseDown(event, number);
    };
    /* #endregion */

    return (
        <div
            className={`BotagBeacon TagsContainerElement ${isSelected ? "beaconSelected" : ""}`}
            data-botag-number={number}
            style={{ ...BotagBeacon, top: y - 17, left: x - 17 }}>
            <div className={`Close TagsContainerElement ${allowEdit ? "" : "hidden"}`} style={Close}>
                <div className="Ellipse5 TagsContainerElement" style={Ellipse5}></div>
                <div
                    className="XClose cursor-pointer TagsContainerElement"
                    style={XClose}
                    onClick={event => onRemoveBeacon(event, number)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                        <path
                            d="M8.13605 3.47182C8.30419 3.30368 8.30419 3.03107 8.13605 2.86292C7.9679 2.69478 7.69529 2.69478 7.52715 2.86292L5.24826 5.14181L2.96938 2.86292C2.80124 2.69478 2.52862 2.69478 2.36048 2.86292C2.19234 3.03107 2.19234 3.30368 2.36048 3.47182L4.63937 5.75071L2.36048 8.02959C2.19234 8.19773 2.19234 8.47035 2.36048 8.63849C2.52862 8.80663 2.80124 8.80663 2.96938 8.63849L5.24826 6.3596L7.52715 8.63849C7.69529 8.80663 7.9679 8.80663 8.13605 8.63849C8.30419 8.47035 8.30419 8.19773 8.13605 8.02959L5.85716 5.75071L8.13605 3.47182Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </div>
            <div
                className="BeaconNumber TagsContainerElement"
                style={BeaconNumber}
                onMouseDown={handleMouseDown}
                //onMouseMove={onMouseMove}
                //onMouseUp={onMouseUp}>
            >
                <span className="TagsContainerElement">{number}</span>
            </div>
        </div>
    );
};

export const tagObject = ({ x, y, number, naturalX, naturalY, selected = false, completed = false, data = {} }) => {
    return { x, y, number, naturalX, naturalY, selected, completed, data };
};

/**
 * Represents a tag element on the image.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.x - The x-coordinate of the tag element.
 * @param {number} props.y - The y-coordinate of the tag element..
 * @param {string} props.label - The label associated with the tag element.
 * @param {number} props.number - The number associated with the tag element.
 * @returns {JSX.Element} The rendered tag element.
 */
const Beacon = ({ x, y, label, onClick }) => {
    const dimensionsNotSet = !x || !y;
    x = x ?? 0;
    y = y ?? 0;

    return (
        <StyledBeacon
            className="BeaconContainer"
            onClick={() => (onClick ? onClick() : null)}
            style={{ top: y - 17, left: x - 17, display: dimensionsNotSet ? "none" : "flex" }}>
            <div className="BotagBeacon">
                <div className="BotagBeaconInner"></div>
            </div>
            {label && <div className="product-label">{label}</div>}
        </StyledBeacon>
    );
};

const CloseButton = ({ onClick }) => {
    const StyledCloseButton = {
        borderRadius: "5px",
        display: "flex",
        flexDirection: "row",
        gap: "0px",
        alignItems: "center",
        justifyContent: "center",
        width: "30px",
        height: "30px",
        position: "absolute",
        overflow: "visible",
        top: "16px",
        right: "16px",
        cursor: "pointer"
    };

    return <img className="frame-29057" src={CloseIcon} style={StyledCloseButton} onClick={onClick} alt="" />;
};
