import React, { useContext, useEffect, useRef, useState } from "react";
import { APP_TITLE } from "../../../../config/constant";
import { useNavigate, useOutletContext } from "react-router";
import styled from "styled-components";
import FormCard from "../../../common/forms/FormCard";
import FormField from "../../../common/forms/FormField";
import * as Library from "../../../../utils/Library";
import FormFieldTextAutocomplete from "../../../common/forms/FormFieldTextAutocomplete";
import FormFieldImageUpload from "../../../common/forms/FormFieldImageUpload";
import AppContext from "../../../../AppContext";
import FormFieldTagsDropdown from "../../../common/forms/FormFieldTagsDropdown";
import BtnLargeSolid from "../../../common/buttons/BtnLargeSolid";
import FormFieldTagsAutocomplete from "../../../common/forms/FormFieldTagsAutocomplete";

/* #region SCOPED CSS */
const StyledPage = styled.div``;

const StyledPageHeader = styled.div`
    &.content-header,
    &.content-header * {
        box-sizing: border-box;
    }
    &.content-header {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        padding: 15px 24px 15px 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        height: 66px;
        position: relative;
    }
    .frame-40 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
`;
/* #endregion */

export default function FormsTemplatePage() {
    /* #region DEFAULT PAGE SETTINGS */
    document.title = `${APP_TITLE} - Forms Templates`;

    // VARS
    const { MainLayoutProps, layoutType } = useOutletContext();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    // END VARS
    /* #endregion */

    /* #region TEMPLATE VARS */
    const formCardRef = useRef();
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
    const [overrideSaveButtonEnabled] = useState(false);
    const [formIsLoading, setFormIsLoading] = useState(false);
    const navigate = useNavigate();
    const { setHasUnsavedChanges } = useContext(AppContext);

    const [dropDownData] = useState([
        { value: "1", name: "Portugal", selected: true },
        { value: "2", name: "Spain" },
        { value: "3", name: "France" },
        { value: "4", name: "Germany" },
        { value: "5", name: "Italy" },
        { value: "6", name: "Netherlands" },
        { value: "7", name: "Norway" },
        { value: "8", name: "Sweden" },
        { value: "9", name: "Denmark" },
        { value: "10", name: "Finland" },
        { value: "11", name: "Iceland" },
        { value: "12", name: "United Kingdom" }
    ]);

    const [defaultTagValues] = useState(["Portugal", "Spain"]);

    const formFields1Refs = []; //TODO: CHECK IF WE CAN MAKE FORM FIELDS REFS DYNAMIC

    const FormCardFields1 = [
        <FormField
            type="text"
            title="First Name"
            placeholder="Enter First Name"
            requiredType="mandatory"
            assistiveText="Assistive Text"
            varName="firstName"
            ref={el => (formFields1Refs.firstName = el)}
        />,
        <FormField
            type="text"
            title="Instagram URL"
            requiredType="recommended"
            assistiveText="Assistive Text"
            varName="lastName"
            placeholder="https://www.instagram.com/"
            ref={el => (formFields1Refs.lastName = el)}
        />,
        <FormField
            type="text"
            title="Email"
            placeholder="Enter Email"
            requiredType="locked"
            value="test@botag.no"
            leftContent={<span className="fa fa-envelope"></span>}
            assistiveText="Assistive Text"
            varName="email"
            ref={el => (formFields1Refs.email = el)}
        />,
        <FormField
            type="phoneNumber"
            title="Phone"
            phoneNumberCountryCode="+351"
            placeholder="Enter Phone"
            requiredType="mandatory"
            assistiveText="Assistive Text"
            varName="phone"
            ref={el => (formFields1Refs.phone = el)}
        />,
        <FormField
            type="text"
            title="Length between 5 and 10"
            varName="lengthtest"
            ref={el => (formFields1Refs.lengthtest = el)}
            minLength={5}
            maxLength={10}
        />,
        <FormField
            type="date"
            title="Date example"
            leftContent={<span className="fa fa-calendar"></span>}
            varName="date"
            ref={el => (formFields1Refs.date = el)}
        />,
        <FormField
            type="datetime-local"
            title="DateTime example"
            leftContent={<span className="fa fa-calendar"></span>}
            varName="datetime"
            ref={el => (formFields1Refs.datetime = el)}
        />,
        <FormField
            type="taxNumber"
            title="Tax example"
            leftContent="NO"
            rightContent="MVA"
            taxNumberCountryCode="NO"
            varName="tax"
            ref={el => (formFields1Refs.tax = el)}
        />,
        <FormField
            type="droppable"
            title="Droppable"
            varName="droppable"
            ref={el => (formFields1Refs.droppable = el)}
        />,
        <FormField
            type="droppable"
            title="Dragged"
            requiredType="mandatory"
            value="Dropped field"
            varName="dragged"
            ref={el => (formFields1Refs.dragged = el)}
        />,
        <FormField
            type="text"
            multiLine={{
                isMultiLine: true,
                autoExpand: true,
                maxLines: 6
            }}
            title="Multi-line auto-expand (6 lines max)"
            varName="multiline1"
            ref={el => (formFields1Refs.multiline1 = el)}
            formFieldColumSize={1}
        />,
        <FormField
            type="text"
            multiLine={{
                isMultiLine: true,
                numberOfLines: 3,
                maxLines: 6
            }}
            title="Multi-line example (6 lines max)"
            varName="multiline2"
            ref={el => (formFields1Refs.multiline2 = el)}
            formFieldColumSize={1}
            onTextChange={data => console.log("Multiline2 changed:", data)}
        />,

        <FormField
            type="dropdown"
            title="Dropdown single item"
            //IMPORTANT: dropdownData should always come from a state variable or else it will trigger
            //a useEffect of data was changed on every view refresh and cause the selected item to be reset.
            dropdownData={dropDownData}
            varName="dropdownSingleItem"
            isDropdownMultiselect={false}
            isDropdownSearchable={true}
            ref={el => (formFields1Refs.dropdownSingleItem = el)}
        />,
        <FormField
            type="dropdown"
            title="Dropdown multi item"
            dropdownData={dropDownData}
            varName="dropdownSingleItem"
            isDropdownMultiselect={true}
            isDropdownSearchable={true}
            multidropdownOptions={{
                useDoneButton: true,
                useSelectAll: true,
                selectAllText: "Select all stores",
                selectAllPlaceholderFunction: arrayItems => {
                    if (!arrayItems?.length) return "No items selected";
                    const areAllSelected = arrayItems?.length === dropDownData?.length;
                    if (areAllSelected) return `All selected (${arrayItems?.length})`;

                    if (arrayItems?.length < 3) {
                        const firstTwoItems = arrayItems
                            ?.slice(0, 2)
                            ?.map(item => item?.name)
                            ?.join(", ");

                        return firstTwoItems;
                    }

                    return `${arrayItems?.length} selected`;
                }
            }}
            useDropdownPlaceholderAsTag={true}
            ref={el => (formFields1Refs.dropdownSingleItem = el)}
        />,
        <FormFieldTextAutocomplete
            type="text"
            title="Text autocomplete (clears value if not selected from dropdown)"
            requiredType="mandatory"
            autoCompleteStaticData={dropDownData}
            varName="textAutocomplete"
            itemNotSelectedWillClear={true}
            noSearchResultsHideMessage={false}
            ref={el => (formFields1Refs.textAutocomplete = el)}
        />,
        <FormFieldTextAutocomplete
            type="text"
            title="Text autocomplete by API. (Using BOImageSearch as example)"
            requiredType="simple"
            varName="textAutocomplete"
            noSearchResultsHideMessage={true}
            itemNotSelectedWillClear={true}
            autoCompleteAPIData={{
                apiName: "searchBOimages",
                apiSearchFieldName: "searchText",
                returnArrayName: "data",
                returnFieldIDName: "_id",
                returnFieldValueName: "name",
                includeInAPIFormData: {
                    companyID: localStorage.getItem("currentCompanyID")
                }
                // IF is a BOCommon Endpoint also add the following properties (as an example):
                // useBOCommon: true,
                // BOCommonContext: "Accounts"
            }}
            ref={el => (formFields1Refs.textAutocomplete = el)}
        />,
        <FormFieldTextAutocomplete
            type="text"
            title="Autocomplete (retains value if not selected, also hide not found msg)"
            requiredType="simple"
            autoCompleteStaticData={dropDownData}
            varName="textAutocompleteRetained"
            ref={el => (formFields1Refs.textAutocompleteRetained = el)}
            itemNotSelectedWillClear={false}
            noSearchResultsHideMessage={true}
        />,
        <FormField
            type="tags"
            title="Tags"
            requiredType="mandatory"
            //TO SET VALUE USE array of strings with prop value={["Tag 1", "Tag 2"]} or tagName objects with prop tags={[{ tagName: "Tag 1", bgColor: "#0060cc" }, { tagName: "Tag 2", bgColor: "#0060cc" }]}
            varName="tags"
            ref={el => (formFields1Refs.tags = el)}
            formFieldColumSize={1}
            value={defaultTagValues}
        />,
        <FormFieldTagsDropdown
            title="Tags Dropdown"
            formFieldColumSize={1}
            autoCompleteStaticData={dropDownData}
            varName="dropdownWithTags"
            value={defaultTagValues}
            ref={el => (formFields1Refs.dropdownWithTags = el)}
            allowNewTags={true}
            noSearchResultsHideMessage={true}
            requiredType="mandatory"
        />,
        <FormFieldTagsAutocomplete
            title="Tags with AutoComplete"
            formFieldColumSize={1}
            autoCompleteStaticData={dropDownData}
            varName="tagsAutocomplete"
            value={defaultTagValues}
            ref={el => (formFields1Refs.tagsAutocomplete = el)}
            allowNewTags={true}
            noSearchResultsHideMessage={true}
            requiredType="mandatory"
        />,
        <FormFieldTagsAutocomplete
            title="Tags with AutoComplete by API"
            formFieldColumSize={1}
            //autoCompleteStaticData={dropDownData}
            varName="tagsAutocompleteAPI"
            //value={defaultTagValues}
            ref={el => (formFields1Refs.tagsAutocompleteAPI = el)}
            allowNewTags={true}
            noSearchResultsHideMessage={true}
            requiredType="mandatory"
            autoCompleteAPIData={{
                apiName: "searchBOimages",
                apiSearchFieldName: "searchText",
                returnArrayName: "data",
                returnFieldIDName: "_id",
                returnFieldValueName: "name",
                includeInAPIFormData: {
                    companyID: localStorage.getItem("currentCompanyID")
                }
                // IF is a BOCommon Endpoint also add the following properties (as an example):
                // useBOCommon: true,
                // BOCommonContext: "Accounts"
            }}
        />,
        <div className="empty-space" style={{ gridColumn: "span 3" }} />,
        <FormFieldImageUpload
            title="Image Upload 1 already with an image"
            requiredType="mandatory"
            ref={el => (formFields1Refs.imageUpload1 = el)}
            varName="imageUpload1"
            gridColumnSize={2}
            imageURL="https://www.w3schools.com/w3images/lights.jpg"
            //onImageChange={data => console.log("Image1 changed:", data)}
        />,
        <FormFieldImageUpload
            title="Image Upload 2 no image"
            requiredType="mandatory"
            ref={el => (formFields1Refs.imageUpload2 = el)}
            varName="imageUpload2"
            gridColumnSize={2}
            //onImageChange={data => console.log("Image2 changed:", data)}
        />
    ];

    /* #endregion */

    /* #region TEMPLATE METHODS */

    // if false -> don't highlight invalid fields
    const checkFormIsValid = (highlightValidation = false) => {
        const allFieldsAreValid = formCardRef.current.allFieldsAreValid(highlightValidation); // false -> don't highlight invalid fields
        //console.log("Checking form is valid:", allFieldsAreValid);

        setSaveButtonEnabled(allFieldsAreValid);
        return allFieldsAreValid;
    };

    const handleSaveClicked = () => {
        if (!formCardRef) {
            Library.showErrorMessage("FormCard references not found.");
            return;
        }

        // Check if all fields are valid
        const allFieldsAreValid = checkFormIsValid(true);
        if (!allFieldsAreValid) {
            Library.showErrorMessage("Please verify all fields.");
            return;
        }

        // Get all field values
        const fieldValues = formCardRef.current.getAllFieldValues();
        console.log("fieldValues:", fieldValues);

        //NOTE: DONT FORGET TO SET UNSAVED CHANGES TO FALSE OR ELSE IT WILL KEEP ASKING TO SAVE ON ROUTE CHANGE!
        setHasUnsavedChanges(false);
        setFormIsLoading(true);

        // DO SOMETHING WITH THE FIELD VALUES
        setTimeout(() => {
            try {
                Library.showSuccessMessage("Form saved successfully.");
                navigate("/companies");
                setFormIsLoading(false);
            } catch (error) {
                setFormIsLoading(false);
                Library.showErrorMessage("Error saving form.");
                //NOTE: IF THE REQUEST FAILS, WE SHOULD SET THE hasUnsavedChanges TO TRUE
                setHasUnsavedChanges(true);
            }
        }, 2000);
    };

    const logDataClicked = () => {
        if (!formCardRef) {
            Library.showErrorMessage("FormCard references not found.");
            return;
        }

        // Get all field values
        const fieldValues = formCardRef.current.getAllFieldValues();
        console.log("fieldValues:", fieldValues);
    };

    const allValidClicked = () => {
        if (!formCardRef) {
            Library.showErrorMessage("FormCard references not found.");
            return;
        }

        // Check if all fields are valid
        const allFieldsAreValid = checkFormIsValid(true);
        console.log("All fields are valid?", allFieldsAreValid);
    };

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        // ATENTION: FOR THE SETUPHEADER DONT FORGET TO UPDATE THE DEPENDENCIES OR IT WON'T REACT TO CHANGES
        setupHeaders(
            props,
            saveButtonEnabled || overrideSaveButtonEnabled,
            handleSaveClicked,
            logDataClicked,
            allValidClicked
        );
    }, [props?.layoutType, saveButtonEnabled, overrideSaveButtonEnabled]);
    /* #endregion */

    return (
        <StyledPage>
            <FormCard
                ref={formCardRef}
                title="Forms Template"
                numOfFieldsPerLine={4}
                showDefaultRightLegends={true}
                formFields={FormCardFields1}
                formFieldRefs={formFields1Refs}
                onFormChanged={checkFormIsValid}
                showUnsavedChangesPopup={true}
                setIsloading={formIsLoading}
            />
        </StyledPage>
    );
}

/* #region DEFAULT PAGE CONFIG */
function setupHeaders(props, saveButtonEnabled, handleSaveClicked, logDataClicked, allValidClicked) {
    props.setControlBarLeftBtns([]);
    props.setControlBarCenterBtns([{ to: "#", active: true, disabled: false, title: "Forms" }]);
    props.setControlBarRightBtns([]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleHeader
                btnTitle="SAVE"
                btnEnabled={saveButtonEnabled}
                onClick={handleSaveClicked}
                logDataClicked={logDataClicked}
                allValidClicked={allValidClicked}
            />
        )
    });
}

const SimpleHeader = props => {
    const { onClick, btnTitle, btnEnabled, logDataClicked, allValidClicked } = props;

    return (
        <StyledPageHeader className="content-header simple-button-content-header">
            <div className="frame-40">
                <div className="btn-large-solid flex">
                    <BtnLargeSolid className="ml-10px" text={"LOG DATA"} onClick={logDataClicked} />
                    <BtnLargeSolid className="ml-10px" text={"ALL VALID"} onClick={allValidClicked} />
                    <BtnLargeSolid className="ml-10px" text={btnTitle} disabled={!btnEnabled} onClick={onClick} />
                </div>
            </div>
        </StyledPageHeader>
    );
};
/* #endregion */
