import Announcement01 from "@untitled-ui/icons-react/build/esm/Announcement01";
import Box from "@untitled-ui/icons-react/build/esm/Box";
import Building07 from "@untitled-ui/icons-react/build/esm/Building07";
import Speedometer04 from "@untitled-ui/icons-react/build/esm/Speedometer04";
import Tag03 from "@untitled-ui/icons-react/build/esm/Tag03";
import Dataflow01 from "@untitled-ui/icons-react/build/esm/Dataflow01";
import UserSquare from "@untitled-ui/icons-react/build/esm/UserSquare";
import { ReactComponent as FFCIcon } from "../assets/icons/ffcenter-icon.svg";
import { ReactComponent as Shop1 } from "../assets/icons/shop-1.svg";
import { ReactComponent as Shirt } from "../assets/icons/tshirt-linear.svg";
import { ReactComponent as id } from "../assets/icons/id.svg";
import { hasNavigationAccess, hasUserCompletedOnboarding } from "../utils/navigationHelper";

export const navigationBar = (path, navigations) => {
    const navigation = navigations?.navigationAccesses;

    const hasDashboardAccess = hasNavigationAccess(navigation, "dashboard", false);
    const hasBOImagesAccess = hasNavigationAccess(navigation, "boimages", false);
    const hasFullfillmentCentersAccess = hasNavigationAccess(navigation, "fulfillmentCenters", false);
    const hasStockAccess = hasUserCompletedOnboarding(navigation);
    const hasStoresAccess = hasNavigationAccess(navigation, "stores", false);
    const hasRetailersAccess = hasNavigationAccess(navigation, "retailers", false);
    const hasOrdersAccess = false; //hasNavigationAccess(navigation, "orders", false);
    const hasProductsAccess = hasNavigationAccess(navigation, "products", false);
    const hasLabelBrandsAccess = hasNavigationAccess(navigation, "labelBrands", false);
    const hasPromotersAccess = false; //hasNavigationAccess(navigation, "promoters", false);
    const hasCompaniesAccess = hasNavigationAccess(navigation, "companies", false);
    const hasOrganizationAccess = hasNavigationAccess(navigation, "organization", false);

    const createModeOnlyBOImages = navigation?.BOimages?.createModeOnly;
    const createModeOnlyFullfillmentCenters = navigation?.fulfillmentCenters?.createModeOnly;
    const createModeOnlyStock = navigation?.stock?.createModeOnly;
    const createModeOnlyStores = navigation?.stores?.createModeOnly;
    const createModeOnlyRetailers = navigation?.retailers?.createModeOnly;
    const createModeOnlyOrders = navigation?.orders?.createModeOnly;
    const createModeOnlyProducts = navigation?.products?.createModeOnly;
    const createModeOnlyLabelBrands = navigation?.labelBrands?.createModeOnly;
    const createModeOnlyPromoters = navigation?.promoters?.createModeOnly;
    const createModeOnlyCompanies = navigation?.companies?.createModeOnly;
    const createModeOnlyOrganization = navigation?.organization?.createModeOnly;

    return [
        {
            to: "/dashboard",
            enabled: hasDashboardAccess,
            title: "Dashboard",
            iconSVG: Speedometer04,
            isActive: path.toLocaleLowerCase().startsWith("/dashboard")
        },
        {
            to: createModeOnlyBOImages ? "/boimages/create" : "/boimages",
            enabled: hasBOImagesAccess,
            title: "BOimages",
            iconSVG: UserSquare,
            isActive: path.toLocaleLowerCase().startsWith("/boimages")
        },
        {
            to: createModeOnlyFullfillmentCenters ? "/ffcenters?layoutType=list" : "/ffcenters?layoutType=list", //temp
            enabled: hasFullfillmentCentersAccess,
            title: "Fulfillment Centers",
            iconSVG: FFCIcon,
            isActive: path.toLocaleLowerCase().startsWith("/ffcenters")
        },
        {
            to: createModeOnlyRetailers ? "/retailers/invite" : "/retailers/connections", //temp
            enabled: hasRetailersAccess,
            title: "Retailers",
            iconSVG: Dataflow01,
            isActive: path.toLocaleLowerCase().startsWith("/retailers")
        },
        {
            to: createModeOnlyStock ? "/stock" : "/stock", //temp
            enabled: hasStockAccess,
            title: "Stock",
            iconSVG: Shirt,
            isActive: path.toLocaleLowerCase().startsWith("/stock")
        },
        {
            to: createModeOnlyStores ? "/stores/create" : "/stores",
            enabled: hasStoresAccess,
            title: "Stores",
            iconSVG: Shop1,
            isActive: path.toLocaleLowerCase().startsWith("/stores")
        },

        {
            to: createModeOnlyOrders ? "/orders/create" : "/orders", //temp
            enabled: hasOrdersAccess,
            title: "Orders",
            iconSVG: Box,
            isActive: path.toLocaleLowerCase().startsWith("/orders")
        },
        {
            to: createModeOnlyProducts ? "/products/add" : "/products",
            enabled: hasProductsAccess || path.startsWith("/products"), //TODO: PATH INCLUDES PRODUCTS IN TEMPORARY SOLUTION
            title: "Products",
            iconSVG: Shirt,
            isActive: path.toLocaleLowerCase().startsWith("/products")
        },
        {
            to: createModeOnlyPromoters ? "/promoters/create" : "/promoters", //temp
            enabled: hasPromotersAccess,
            title: "Promoters",
            iconSVG: Announcement01,
            isActive: path.toLocaleLowerCase().startsWith("/promoters")
        },
        {
            to: createModeOnlyLabelBrands ? "/brands/create" : "/brands",
            enabled: hasLabelBrandsAccess,
            title: "Brands",
            iconSVG: Tag03,
            isActive: path.toLocaleLowerCase().startsWith("/brands")
        },

        {
            to: createModeOnlyCompanies ? "/companies/create" : "/companies",
            enabled: hasCompaniesAccess,
            title: "Companies",
            iconSVG: Building07,
            isActive: path.toLocaleLowerCase().startsWith("/companies")
        },
        {
            to: createModeOnlyOrganization ? "/account/create" : "/account",
            enabled: hasOrganizationAccess,
            title: "Account",
            iconSVG: id,
            isActive: path.toLocaleLowerCase().startsWith("/account")
        }
    ];
};
