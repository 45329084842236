import styled from "styled-components";
import CardGridSkeleton from "../gridCards/animations/CardGridSkeleton";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useRef, useState } from "react";
import ProductCardComponent from "../../pages/products/ProductCardComponent";
import * as Library from "../../../utils/Library";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: scroll;
        height: 100%;
        margin-right: -24px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 17px;
    }
`;
/* #endregion */

export default function InfinityGridCompanyCards(props) {
    const gridLayoutStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${props?.minColumnWidth || "395px"}, 1fr))`,
        gap: "24px"
    };
    /* #region STATES */
    const scrollContainerRef = useRef(null);
    const [scroll, setScroll] = useState(true);
    const [pageLimit] = useState(10);

    const [minimumCardsFill, setminimumCardsFill] = useState(1); //props?.minimumCardsFill || pageLimit
    const requestEnabled = props?.requestEnabled ?? true;

    const [queryKey, setQueryKey] = useState(props?.queryKey ?? uuidv4());

    useEffect(() => {
        if (props?.queryKey) setQueryKey(props?.queryKey);
    }, [props?.queryKey]);

    const filters = props?.filters;

    const {
        data,
        isFetching: isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = Library.usePostInfiniteQuery(
        queryKey,
        "getProductsData",
        {
            companyID: localStorage.getItem("currentCompanyID"),
            limit: pageLimit,
            searchName: props?.searchName,
            barcode: props?.barcode,
            status: props?.status,
            ...filters
        },
        () => {
            setScroll(true);
        },
        false,
        undefined,
        false,
        requestEnabled
    );

    const pageList = data?.pages;
    //const totalCount = data?.pages[0]?.data?.totalCount;
    const nextDataCount = data?.pages[data?.pages.length - 1].data?.nextDataCount;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        handleScroll();

        setminimumCardsFill(getTotalCards() + 1);
    }, [data?.pages]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && !isLoading && scroll && !isFetchingNextPage) {
            //console.log("scroll event added");
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            //console.log("scroll event removed");
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [scrollContainerRef, isLoading, pageList, scroll, isFetchingNextPage, data?.pages]);

    useEffect(() => {
        props.setData(data); // Update the data state in the parent component
    }, [data]);
    /* #endregion */

    /* #region METHODS */
    const handleScroll = () => {
        //console.log("scrolling");
        const scrollContainer = scrollContainerRef.current;

        const isAtBottom =
            Math.floor(scrollContainer.scrollHeight - scrollContainer.scrollTop) <= scrollContainer.clientHeight;

        if (isAtBottom && !isLoading && hasNextPage && !isFetchingNextPage) {
            setScroll(false);
            //console.log("fetching next page");
            fetchNextPage();
        }
    };

    const getTotalCards = () => {
        return pageList?.reduce((acc, it) => {
            return acc + it?.data?.result?.length;
        }, 0);
    };

    const handleCheckboxClick = (id, isSelected) => {
        if (isSelected) {
            props.setSelectedProducts([...props.selectedProducts, id]);
        } else {
            props.setSelectedProducts(props.selectedProducts.filter(productId => productId !== id));
        }
    };
    /* #endregion */
    return (
        <StyledPage className={`${props?.className || ""}`} ref={scrollContainerRef}>
            {isLoading && !isFetchingNextPage ? (
                <div style={gridLayoutStyle}>
                    {Array.from({ length: pageLimit }, () => (
                        <CardGridSkeleton key={uuidv4()} height="725px" imageHeight="465px" />
                    ))}
                </div>
            ) : (
                <div className="mb-24px" style={gridLayoutStyle}>
                    {pageList?.map(it => {
                        return it?.data?.result?.map(item => {
                            return (
                                <ProductCardComponent
                                    key={item?.botagID}
                                    title={item?.styleName}
                                    image={item?.image}
                                    images={item?.images}
                                    isShowCarousel={false}
                                    id={item?.botagID}
                                    productID={item?.productID}
                                    status={item?.status}
                                    createdAt={item?.createdAt}
                                    collectionName={item?.collectionName}
                                    lastStockCheck={item?.lastStockCheck}
                                    totalStockInStores={item?.totalStockInStores}
                                    totalStoresWithStock={item?.totalStoresWithStock}
                                    isCardEnabled={item?.status?.toLowerCase() !== "suspended"} //TODO: check if this is needed
                                    //isCardSuspended={item?.status?.toLowerCase() === "suspended"} //TODO: check if this is needed
                                    // tagOnclick={() => console.log("tag clicked")}
                                    //editIcon={{ onClick: "clickable" }}
                                    hideActionBtn={props?.hideActionBtn ? props?.hideActionBtn : false}
                                    removeIcon={{ onClick: () => props?.onClickRemove(item) }}
                                    suspendIcon={{ onClick: () => props?.onClickSuspend(item) }}
                                    primaryButton={{
                                        title: props?.primaryButton?.title ? props?.primaryButton?.title : "DETAILS",
                                        onClick: props?.primaryButton?.onClick
                                            ? () => props?.primaryButton?.onClick(item)
                                            : undefined
                                    }}
                                    secondaryButton={{
                                        title: props?.secondaryButton?.title ? props?.secondaryButton?.title : "BOIMAGE"
                                    }}
                                    values={[
                                        {
                                            key: "Brand",
                                            value: item?.brand
                                        },
                                        {
                                            key: "Reference",
                                            value: item?.reference
                                        },
                                        {
                                            key: "Colors",
                                            value: item?.colors
                                        },
                                        {
                                            key: "Sizes",
                                            value: item?.sizes
                                        },
                                        {
                                            key: "Category",
                                            value: item?.category
                                        }
                                    ]}
                                    isSelected={props.selectedProducts?.includes(item?.productID) || false}
                                    onCheckboxClick={handleCheckboxClick}
                                />
                            );
                        });
                    })}

                    {!isFetchingNextPage &&
                        minimumCardsFill - getTotalCards() > 0 &&
                        Array.from({ length: minimumCardsFill - getTotalCards() }, (_, index) => (
                            <ProductCardComponent
                                key={index}
                                isCardEnabled={false}
                                hideActionBtn={props?.hideActionBtn ? props?.hideActionBtn : false}
                            />
                        ))}

                    {isFetchingNextPage &&
                        Array.from({ length: nextDataCount }, (_, index) => (
                            <CardGridSkeleton key={index} values={[]} height="725px" imageHeight="465px" />
                        ))}
                </div>
            )}
        </StyledPage>
    );
}
