import styled from "styled-components";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .inverted-field {
        gap: 4px;
    }

    .input-selector-inverted {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 38px;
        position: relative;
    }

    .frame-50 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 37px;
        position: relative;
        overflow: hidden;
    }

    .search {
        gap: 8px;
        height: 38px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .frame-48 {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1; /* Allow the element to grow */
        height: 37px;
        position: relative;
        overflow: hidden;
        color: var(--text-icons-green-500, #0b0);
    }
`;
/* #endregion */

export default function InputInfo({ value, disabled, onClick, isError }) {
    return (
        <StyledPage
            className="inverted-field"
            onClick={disabled ? null : onClick}
            style={{
                cursor: disabled ? "not-allowed" : "pointer"
                // width: "100%",
                // maxWidth: "380px",
                // minWidth: "270px"
            }}>
            <div className="input-selector-inverted">
                <div
                    className="frame-50"
                    style={{
                        backgroundColor: disabled ? "#4F5B6D" : isError ? "#141922" : value ? "#141922" : "#ff9900"
                    }}></div>
                <div className="search">
                    <div className="frame-48" style={{ color: isError && "#FF3636" }}>
                        {value}
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}
