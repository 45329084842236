import info from "../../../assets/icons/info-circle-white.svg";
import ImageUploading from "../../common/forms/ImageUploading";
import Upload from "../../../assets/icons/upload-010.svg";
import XClose0 from "../../../assets/icons/x-close0.svg";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Globe01 } from "../../../assets/icons/globe-01.svg";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import * as Library from "../../../utils/Library";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import { useDispatch, useSelector } from "react-redux";
import { setNavigations } from "../../../utils/redux/navigationAccessSlice";
import LayoutContext from "../../../LayoutContext";
import { APP_TITLE } from "../../../config/constant";
import FormCard from "../../common/forms/FormCard";
import FormField from "../../common/forms/FormField";
import { getDropdownData } from "../../../utils/dropdownData";
import SimpleButtonContentHeader from "../companies/createCompanyComponents/SimpleButtonContentHeader";
import UploadLogosCardStandard from "../../common/forms/UploadLogosCardStandard";
import AppContext from "../../../AppContext";
import { userIsOnBoardingInPage } from "../../../utils/navigationHelper";

/* #region SCOPED CSS */
const OrganizationAddPageStyles = styled.div`
    .frame-288 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        width: 100%;
        height: 100%;
    }
    .frame-199 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        text-align: center;
    }
    .frame-197 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .browse-files-from-device {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-size: var(--subtitle-2-font-size, 14px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-size: var(--subtitle-3-font-size, 12px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
`;
const StyledImagePreview = styled.div`
    .upload-card,
    .upload-card * {
        box-sizing: border-box;
    }
    .upload-card {
        display: flex;
        flex-direction: column;
        gap: 4px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-51 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .store-facade {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .frame-197 {
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-500, #141922);
        border-width: 0px 0px 0px 5px;
        display: flex;
        flex-direction: column;
        gap: 9px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 100%;
        position: relative;
    }
    .x-close-container {
        width: 6.667px;
        height: 6.667px;
        flex-shrink: 0;
        position: static;
        z-index: 8888;
    }
    .ellipse-5 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 50%;
        width: 14px;
        height: 14px;
        position: absolute;
        //left: 766px;
        right: 12px;
        top: 12px;
    }
    .x-close {
        width: 12px;
        height: 12px;
        position: absolute;
        //left: 770px;
        right: 13px;
        top: 13px;
        overflow: visible;
        z-index: 8888;
        cursor: pointer;
    }
`;
/* #endregion */

const AccountEditPage = () => {
    const location = useLocation();
    document.title = `${APP_TITLE} - Account ${location.pathname === "/account/create" ? "Create" : "Edit"}`;

    /* #region VARS  */
    const formCardRef = useRef();
    const formAccountLogosRef = useRef();
    const { MainLayoutProps, layoutType } = useOutletContext();
    const { setShowIsAccountSavedPopup } = React.useContext(LayoutContext);
    const { setHasUnsavedChanges } = React.useContext(AppContext);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [showDialogBox, setShowDialogBox] = useState(false);
    const navigations = useSelector(state => state.navigationAccesses);
    const userAppData = useSelector(state => state.userData.userAppData);
    const dispatch = useDispatch();

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData,
        navigations: navigations
    };

    const [isEditPage, setIsEditPage] = useState(false);
    const [hasCreationAccessVerified, setHasCreationAccessVerified] = useState(false);
    const [countryOptions] = useState(getDropdownData("countryOptions"));
    const [timeZoneOptions] = useState(getDropdownData("timeZoneOptions"));

    const lastUserNameLockedCheck = name => {
        if (!!name && name !== "-") return true;
        return false;
    };

    const [lastUserNameLocked, setLastUserNameLocked] = useState(
        lastUserNameLockedCheck(localStorage.getItem("userLastName"))
    );

    const [OrganizationDetails, setOrganizationDetails] = useState({
        country: "",
        timeZone: "",
        phoneNumber: "",
        callingCode: "",
        accountOwnerName: localStorage.getItem("userFirstName"),
        lastName: localStorage.getItem("userLastName") !== "-" ? localStorage.getItem("userLastName") : "",
        accountName: "",
        email: localStorage.getItem("userEmail")
    });

    const [profileFacadeData, setProfileFacadeData] = useState(null);
    const [logoFieldsState, setLogoFieldsState] = useState(null);
    const [isRefresh, setIsRefresh] = useState(false);
    /* #endregion */

    /* #region METHODS */

    const handleUpdateOrganization = (fieldValues, logoFieldValues) => {
        const formData = {
            organizationID: localStorage.getItem("currentOrganizationID"),
            BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
            organizationName: fieldValues?.accountName,
            countryID: fieldValues?.country?.value,
            timeZoneID: "", //fieldValues?.timeZone?.name,
            phoneNumberCountryID: fieldValues?.phoneNumber?.split(" ")[0]?.replace("+", ""),
            PhoneNumber: fieldValues?.phoneNumber?.split(" ")[1],
            profilePhotoData: profileFacadeData,
            mainLogoData: logoFieldValues?.mainLogoData,
            compactLogoData: logoFieldValues?.compactLogoData,
            lastName: fieldValues?.lastName
        };

        setHasUnsavedChanges(false);

        Library.makeCommonPostRequest("Organization", "updateOrganization", formData, false, setIsLoading).then(res => {
            if (res?.data?.status === 200) {
                const updatedNavigations = {
                    ...navigations.navigationAccesses,
                    organization: {
                        ...navigations.navigationAccesses.organization,
                        createModeOnly: false
                    },
                    companies: {
                        ...navigations.navigationAccesses.companies,
                        navigationPanel: true,
                        createModeOnly: true
                    }
                };
                localStorage.setItem("currentOrganizationName", fieldValues?.accountName);
                if (res?.data?.data?.mainImageUrl) {
                    localStorage.setItem("currentOrganizationLogo", res?.data?.data?.mainImageUrl);
                }

                if (!isEditPage) {
                    localStorage.setItem("tempCountryValue", fieldValues?.country?.value);
                    localStorage.setItem("tempPhoneNumber", fieldValues?.phoneNumber);
                    navigate("/companies/create");
                    dispatch(setNavigations({ navigationAccesses: updatedNavigations }));
                    setShowIsAccountSavedPopup(true);
                } else {
                    if (navigations.navigationAccesses.companies.createModeOnly)
                        localStorage.setItem("tempCountryValue", fieldValues?.country?.value);
                    localStorage.setItem("tempPhoneNumber", fieldValues?.phoneNumber);
                    setShowDialogBox(false);

                    //NOTE: ALL EDIT PAGES SHOULD CALL THIS METHOD AFTER UPDATE SUCCESS
                    Library.showUpdateMessageAndGoBack(navigate);
                }
            } else setHasUnsavedChanges(true);
        });
    };

    const selectTimezoneAndPhoneNumber = data => {
        const countryId = data?.id;
        const countryName = data?.name;

        const timeZoneOptions = getDropdownData("timeZoneOptions");
        const phoneCountryCodes = getDropdownData("countryCodeOptions");

        const selectedTimeZone = timeZoneOptions.find(timezone => timezone.id === countryId);
        const selectedPhoneCountryCode = phoneCountryCodes.find(phoneCC => phoneCC.id === countryId);

        const selectedTimeZoneValue = selectedTimeZone?.name;
        const selectedPhoneCountryCodeValue = selectedPhoneCountryCode?.value;

        setOrganizationDetails({
            ...OrganizationDetails,
            country: countryName,
            timeZone: selectedTimeZoneValue,
            callingCode: selectedPhoneCountryCodeValue
        });
    };

    const checkFormIsValid = (highlightValidation = false) => {
        const allFieldsAreValid = formCardRef.current.allFieldsAreValid(highlightValidation);
        const allLogosAreValid = formAccountLogosRef.current.allFieldsAreValid(highlightValidation);

        setSaveButtonEnabled(allFieldsAreValid && allLogosAreValid);
        return allFieldsAreValid;
    };

    const handleSaveClicked = () => {
        if (!formCardRef.current || !formAccountLogosRef.current) {
            Library.showErrorMessage("FormCard references not found.");
            return;
        }

        const allFieldsAreValid = checkFormIsValid(true);
        if (!allFieldsAreValid) {
            Library.showErrorMessage("Please verify all fields.");
            return;
        }

        // Get all field values
        const fieldValues = formCardRef.current.getAllFieldValues();
        const logoFieldValues = formAccountLogosRef.current.getAllFieldValues();

        handleUpdateOrganization(fieldValues, logoFieldValues);
    };

    const generateLogoFields = (largeLogoImageData, smallLogoImageData) => {
        const logosState = [
            {
                label: "Main logo",
                varName: "mainLogoData",
                requiredType: "mandatory",
                imageData: largeLogoImageData,
                gridColumnSize: 2
            },
            {
                label: "Compact logo",
                varName: "compactLogoData",
                requiredType: "recommended",
                imageData: smallLogoImageData,
                gridColumnSize: 1
            }
        ];

        setLogoFieldsState(logosState);
    };
    /* #endregion */

    /* #region FORM FIELDS */
    //const { profileFacadeData, largeLogoFacadeData, smallLogoFacadeData } = OrganizationDetails;

    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
    const formFields1Refs = []; //TODO: CHECK IF WE CAN MAKE FORM FIELDS REFS DYNAMIC
    const FormCardFields1 = [
        <FormField
            type="text"
            title="Account owner name"
            tooltip="Enter account owner name"
            requiredType="locked"
            varName="accountOwnerName"
            value={OrganizationDetails?.accountOwnerName}
            ref={el => (formFields1Refs.accountOwnerName = el)}
        />,
        <FormField
            type="text"
            title="Last name"
            tooltip="Enter last name"
            requiredType={lastUserNameLocked ? "locked" : "mandatory"}
            varName="lastName"
            value={OrganizationDetails?.lastName}
            ref={el => (formFields1Refs.lastName = el)}
        />,
        <FormField
            type="text"
            title="Account name"
            tooltip="Enter account name"
            requiredType="mandatory"
            varName="accountName"
            value={OrganizationDetails?.accountName}
            ref={el => (formFields1Refs.accountName = el)}
        />,
        <FormField
            type="email"
            title="Email"
            tooltip="Enter email"
            requiredType="locked"
            assistiveText="VERIFIED"
            assistiveTextColorOverride="#00bb00"
            value={OrganizationDetails?.email}
            varName="email"
            ref={el => (formFields1Refs.email = el)}
        />,
        <FormField
            type="dropdown"
            dropdownData={countryOptions}
            title="Country"
            isDropdownSearchable={true}
            tooltip="Select country"
            requiredType="mandatoryLocked"
            varName="country"
            leftContent={<Globe01 className="flex text-gray-200" />}
            value={OrganizationDetails.country}
            ref={el => (formFields1Refs.country = el)}
            onDropdownChange={selectTimezoneAndPhoneNumber}
        />,
        <FormField
            type="dropdown"
            dropdownData={timeZoneOptions}
            title="Time zone"
            tooltip="Select time zone"
            requiredType="locked"
            varName="timeZone"
            value={OrganizationDetails.timeZone}
            placeholder="Not available"
            ref={el => (formFields1Refs.timeZone = el)}
        />,
        <FormField
            type="phoneNumber"
            title="Phone number"
            tooltip="Enter phone number"
            requiredType="mandatory"
            varName="phoneNumber"
            assistiveText="NOT VERIFIED"
            assistiveTextColorOverride="#FF9900"
            ref={el => (formFields1Refs.phoneNumber = el)}
            value={OrganizationDetails?.phoneNumber}
            phoneNumberCountryCode={OrganizationDetails?.callingCode}
        />,
        <FormField
            type="text"
            title="Two factor authentication (2FA)"
            tooltip="Two factor authentication (2FA) is not available yet"
            requiredType="locked"
            varName="twoFactorAuthentication"
            placeholder="Not available"
            ref={el => (formFields1Refs.twoFactorAuthentication = el)}
        />
    ];
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        checkFormIsValid(false);
    }, [OrganizationDetails]);

    useEffect(() => {
        setupHeaders(props, location, saveButtonEnabled, setShowDialogBox, navigations);
    }, [props?.layoutType, saveButtonEnabled]);

    useEffect(() => {
        if (!userAppData) return;

        // ON PAGE LOAD - IF IS CREATE PAGE, SET COUNTRY, TIMEZONE, PHONE NUMBER COUNTRY CODE BASED ON USER DATA
        if (!isEditPage) {
            const countryValue = localStorage.getItem("currentOrganizationCountry");
            if (!countryValue) return;
            const countrySelectedID = countryOptions.find(country => country.value === countryValue)?.id;
            selectTimezoneAndPhoneNumber({ id: countrySelectedID, name: countryValue });
        }
    }, [userAppData]);

    useEffect(() => {
        setIsEditPage(location.pathname === "/account/edit");
        if (isEditPage) {
            // FETCH ORGANIZATION DETAILS
            Library.makeCommonPostRequest(
                "Organization",
                "getOrganizationAccount",
                {
                    id: localStorage.getItem("token")
                },
                false,
                setIsLoading
            ).then(res => {
                if (res?.data?.status === 200) {
                    const organizationDetails = res?.data?.data?.[0];
                    const OwnerAccount = organizationDetails?.OrganizationOwnerAccount?.BOstagesAccountID;
                    const OrganizationInfo = organizationDetails?.OrganizationInfo;

                    const orgTemp = {
                        accountOwnerName: OwnerAccount?.firstName,
                        lastName: OwnerAccount?.lastName,
                        accountName: OrganizationInfo?.name,
                        email: OwnerAccount?.email,
                        country: OrganizationInfo?.countryValue,
                        timeZone: OrganizationInfo?.timeZoneValue,
                        phoneNumber: `${OwnerAccount?.phoneNumber}`,
                        callingCode: OwnerAccount?.phoneNumberCountryCode
                    };
                    setOrganizationDetails(orgTemp);
                    setProfileFacadeData(OwnerAccount?.profilePhotoUrl ? { url: OwnerAccount?.profilePhotoUrl } : null);
                    setLastUserNameLocked(lastUserNameLockedCheck(OwnerAccount?.lastName));

                    const largeLogoImageData = OrganizationInfo?.mainImageUrl
                        ? { url: OrganizationInfo?.mainImageUrl }
                        : null;
                    const smallLogoImageData = OrganizationInfo?.squareImageUrl
                        ? { url: OrganizationInfo?.squareImageUrl }
                        : null;

                    generateLogoFields(largeLogoImageData, smallLogoImageData);
                } else {
                    Library.showErrorMessage("Failed to fetch organization details.");
                }
            });
        } else {
            generateLogoFields(null, null);
        }
    }, [isEditPage, navigate, location.pathname, isRefresh]);

    useEffect(() => {
        // Check only once if user has access to create. On update, it should not check again or will be redirected to 404
        if (hasCreationAccessVerified) return;
        if (location.pathname !== "/account/create") return;
        if (!navigations || !navigations.navigationAccesses) return;

        const createAccess = navigations.navigationAccesses.organization.createModeOnly;

        const companyCreateAccess = navigations.navigationAccesses.companies.createModeOnly;
        setHasCreationAccessVerified(true);

        if (!createAccess && companyCreateAccess) {
            setIsRefresh(true);
            setIsEditPage(true);
            navigate("/account/edit");
        } else if (!createAccess) {
            navigate("/404");
        }

        /*if (!createAccess && !Library.isLocalhost("Account Create Page")) navigate("/account/edit"); */
    }, [navigations]);
    /* #endregion */

    return (
        <OrganizationAddPageStyles>
            <FullScreenOverlay show={showDialogBox}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-blue-500)"
                    title="Ready to save?"
                    message="You can still change it later. No worries:)"
                    onClickYes={() => {
                        handleSaveClicked();
                    }}
                    loading={isLoading}
                    loadingHeading={"SUBSCRIBING.."}
                    onClickNo={() => {
                        setShowDialogBox(false);
                    }}
                    cancelText="No"
                    confirmText="Yes"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={isLoading}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-green-500)"
                    heading="SAVING..."
                    showCancel={false}
                    showConfirm={false}
                    loading={isLoading}
                />
            </FullScreenOverlay>

            <FormCard
                ref={formCardRef}
                title="Account INFO"
                numOfFieldsPerLine={4}
                showDefaultRightLegends={true}
                formFields={FormCardFields1}
                formFieldRefs={formFields1Refs}
                onFormChanged={checkFormIsValid}
                rightContent={ProfilePhotoComponent({
                    profileFacadeData,
                    setProfileFacadeData
                })}
                showUnsavedChangesPopup={true}
            />

            <UploadLogosCardStandard
                key="accountLogos"
                ref={formAccountLogosRef}
                cardTitle="ACCOUNT LOGO"
                logoFields={logoFieldsState}
                onLogoFieldsChanged={() => checkFormIsValid(false)}
            />
        </OrganizationAddPageStyles>
    );
};

export default AccountEditPage;

/* #region SMALL COMPONENTS */
const ImagePreview = ({ imageURL, onCloseClicked, imageBase64, imgHasPadding = false, objectFit = "cover" }) => {
    let imageSource = imageURL;
    if (!imageURL && imageBase64)
        imageSource = imageBase64.startsWith("data:") ? imageBase64 : `data:image;base64,${imageBase64}`;

    return (
        <StyledImagePreview className="upload-card" style={{ width: "100%", height: "100%", position: "relative" }}>
            <img
                className="frame-197"
                style={{
                    //background: `url(${imageSource}) center / cover no-repeat`,
                    border: "0px",
                    minWidth: "0px",
                    maxWidth: "100%",
                    objectFit: objectFit,
                    padding: imgHasPadding ? "30px" : "0px"
                }}
                alt=""
                src={imageSource}></img>
            {onCloseClicked && (
                <div className="x-close-container">
                    <div className="ellipse-5"></div>
                    <img className="x-close" src={XClose0} alt="" onClick={onCloseClicked} />
                </div>
            )}
        </StyledImagePreview>
    );
};

const ProfilePhotoComponent = props => {
    const { profileFacadeData, setProfileFacadeData } = props;

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "120px"
            }}>
            <div
                style={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                    marginBottom: "5px"
                }}>
                <img src={info} alt="" />
                <div style={{ fontSize: "10px" }}>Profile Photo</div>
            </div>
            <div
                style={{
                    backgroundColor: "#10141B",
                    width: "120px",
                    height: "126px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    border: "1px dashed #3C485A"
                }}>
                {profileFacadeData ? (
                    <ImagePreview
                        onCloseClicked={() => {
                            setProfileFacadeData(null);
                        }}
                        imageBase64={profileFacadeData?.base64}
                        imageURL={profileFacadeData?.url}
                    />
                ) : (
                    <div className="frame-197">
                        <ImageUploading onImageUpload={data => setProfileFacadeData(data)}>
                            <div className="frame-288">
                                <img className="upload-012" src={Upload} alt="" />
                            </div>
                        </ImageUploading>
                    </div>
                )}
            </div>
        </div>
    );
};
/* #endregion */

const setupHeaders = (props, location, saveButtonEnabled, setShowDialogBox, navigations) => {
    const isOnboarding = userIsOnBoardingInPage(navigations, "organization");

    props.setControlBarCenterBtns([
        { to: "", active: true, disabled: false, title: "ACCOUNT" },
        { to: "", active: false, disabled: true, title: "USERS" }
    ]);

    const createModeRightBtn = [
        {
            to: "",
            active: true,
            disabled: false,
            title: "Add",
            icon: "plus"
        },
        {
            to: "/account",
            active: false,
            disabled: true,
            title: "Details",
            icon: "microscope"
        }
    ];

    const editModeRightBtn = [
        { to: "/account", active: false, disabled: false, title: "Back", icon: "chevron-left" },
        { to: "", active: true, disabled: false, title: "Edit", icon: "pencil-line" }
    ];

    const createMode = location.pathname === "/account/create";
    props.setControlBarRightBtns(createMode ? createModeRightBtn : editModeRightBtn);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader
                btnTitle={createMode ? "SAVE" : "UPDATE"}
                btnEnabled={saveButtonEnabled}
                onClick={() => {
                    setShowDialogBox(true);
                }}
            />
        )
    });
};
