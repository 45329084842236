import dashboardMap from "../../../assets/images/dashboard-map.png";
import norwayFlag from "../../../assets/images/flag-norway.png";
import swedenFlag from "../../../assets/images/flag-sweden.png";
import denmarkFlag from "../../../assets/images/flag-denmark.png";
import finlandFlag from "../../../assets/images/flag-finland.png";
import { ReactComponent as InfoCircle } from "../../../assets/icons/info-circle0.svg";
import styled from "styled-components";
import { useState } from "react";

const StyledPage = styled.div`
    &.dashboard-map-container {
        width: 395px;
        height: 427px;
        background-color: #1e2631;
        border-radius: 4px;
        padding: 16px;
        position: relative;
    }
    .frame-313288,
    .frame-313288 * {
        box-sizing: border-box;
    }
    .frame-313288 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-313293 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-313292 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313291 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3132912 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .heading-4-total-visitors {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .info-circle {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        position: relative;
        overflow: visible;
        top: -2px;
    }
    .frame-144 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
    }
    .frame-313331 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-19 {
        padding: 0px 32px 0px 32px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .map-1 {
        align-self: stretch;
        flex: 1;
        position: relative;
        object-fit: cover;
        width: 295px;
        height: 261px;
    }
    .frame-313304 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313330 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313299 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313296 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313294 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-313298 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .div-widget-img {
        background: linear-gradient(to left, #2d353c, #2d353c);
        border-radius: 4px;
        flex-shrink: 0;
        width: 36.5px;
        height: 20px;
        position: relative;
        overflow: hidden;
        object-fit: cover;
    }
    .norway {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-313295 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    ._60-20 {
        color: var(--buttons-all-platforms-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-313302 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .image {
        background: linear-gradient(to left, #2d353c, #2d353c);
        border-radius: 4px;
        flex-shrink: 0;
        width: 36.5px;
        height: 20px;
        position: relative;
        overflow: hidden;
        object-fit: cover;
    }
    .denmark {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    ._39-60 {
        color: var(--buttons-all-platforms-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-313329 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-3133042 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .sweden {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-313300 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .finland {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
`;

const MapComponent = () => {
    const [salesData] = useState([
        {
            country: "Norway",
            percentage: "50.20%",
            flag: norwayFlag
        },
        {
            country: "Denmark",
            percentage: "15.60%",
            flag: denmarkFlag
        },
        {
            country: "Sweden",
            percentage: "12.20%",
            flag: swedenFlag
        },
        {
            country: "Finland",
            percentage: "22.00%",
            flag: finlandFlag
        }
    ]);

    return (
        <StyledPage className="dashboard-map-container">
            <div className="frame-313288">
                <div className="frame-313293">
                    <div className="frame-313292">
                        <div className="frame-313291">
                            <div className="frame-3132912">
                                <div className="heading-4-total-visitors">SALES BY COUNTRY</div>
                                <InfoCircle className="info-circle" />
                            </div>
                        </div>
                        <div className="frame-144"></div>
                    </div>
                    <div className="frame-313331">
                        <div className="frame-19">
                            <img className="map-1" src={dashboardMap} alt="Dashboard Map" />
                        </div>
                        <div className="frame-313304">
                            <div className="frame-313330">
                                <div className="frame-313299">
                                    <div className="frame-313296">
                                        <div className="frame-313294">
                                            <div className="frame-313298">
                                                <img
                                                    className="div-widget-img"
                                                    src={salesData[0]?.flag}
                                                    alt="Norway Flag"
                                                />
                                                <div className="norway">{salesData[0]?.country}</div>
                                            </div>
                                        </div>
                                        <div className="frame-313295">
                                            <div className="_60-20">{salesData[0]?.percentage}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-313302">
                                    <div className="frame-313296">
                                        <div className="frame-313294">
                                            <div className="frame-313298">
                                                <img className="image" src={salesData[1]?.flag} alt="Denmark Flag" />
                                                <div className="denmark">{salesData[1]?.country}</div>
                                            </div>
                                        </div>
                                        <div className="frame-313295">
                                            <div className="_39-60">{salesData[1]?.percentage}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-313329">
                                <div className="frame-3133042">
                                    <div className="frame-313296">
                                        <div className="frame-313294">
                                            <div className="frame-313298">
                                                <img
                                                    className="div-widget-img"
                                                    src={salesData[2]?.flag}
                                                    alt="Sweden Flag"
                                                />
                                                <div className="sweden">{salesData[2]?.country}</div>
                                            </div>
                                        </div>
                                        <div className="frame-313295">
                                            <div className="_39-60">{salesData[2]?.percentage}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-313300">
                                    <div className="frame-313296">
                                        <div className="frame-313294">
                                            <div className="frame-313298">
                                                <img className="image" src={salesData[3]?.flag} alt="Finland Flag" />
                                                <div className="finland">{salesData[3]?.country}</div>
                                            </div>
                                        </div>
                                        <div className="frame-313295">
                                            <div className="_39-60">{salesData[3]?.percentage}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
};

export default MapComponent;
