import React, { useState } from "react";

const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
    /* #region Main App */

    const [getUserDataToken, setGetUserDataToken] = useState(null); // Handles the token to fetch user data
    const [refreshUserData, setRefreshUserData] = useState(false); // When true, it will refresh the user data
    const [isLoadingApp, setIsLoadingApp] = useState(false); // Handles the loading state of the app
    const [redirectURL, setRedirectURL] = useState(null); // Handles the redirect URL after the user logs in

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // Handles the state when there are unsaved changes
    const [showUnsavedChangesPopup, setShowUnsavedChangesPopup] = useState(false); // Handles the visibility of the unsaved changes popup
    const [userData, setUserData] = useState(null); // Handles the user data that is received from the "getUserData"
    /* #endregion */

    /* #region BOImages */
    const [sidePanelIsLoading, setSidePanelIsLoading] = useState(false); // Handles the loading state of the side panel
    const [sidePanelVisible, setSidePanelVisible] = useState(false); // Handles the visibility of the side panel
    const [sidePanelSection, setSidePanelSection] = useState("Products"); // Handles the section to be displayed in the side panel
    const [sidePanelLabelBrandID, setSidePanelLabelBrandID] = useState(null); // Handles the labelBrandID to fetch products for the side panel
    const [sidePanelProducts, setSidePanelProducts] = useState([]); // Handles the products to be displayed in the side panel
    const [sidePanelProductSearchName, setSidePanelProductSearchName] = useState(""); // Handles the search name to fetch products for the side panel
    const [sidePanelBOtagProductLink, setSidePanelBOtagProductLink] = useState(null); // Handles the product link to be linked to a BOtag
    const [BOTagsList, setBOTagsList] = useState([]); // Handles the list of BOtags
    const [sidePanelHasNewLinkedProducts, setSidePanelHasNewLinkedProducts] = useState(false); // Handles the visibility of the new linked products dot
    const [sidePanelSelectedProductID, setSidePanelSelectedProductID] = useState(undefined); // Handles the selected product ID
    const [sidePanelSelectedProductNumber, setSidePanelSelectedProductNumber] = useState(undefined); // Handles the selected product number
    const [brandNotSelectedShowError, setBrandNotSelectedShowError] = useState(false); // Handles the visibility of the brand not selected error
    const [sidePanelHasNoProducts, setSidePanelHasNoProducts] = useState(false); // Handles the state when there are no products to display
    const [showAddProductsPopup, setShowAddProductsPopup] = useState(false); // Handles the visibility of the add products popup
    const [boImageProductsImporting, setBOImageProductsImporting] = useState(false); // Handles the state when the products are being imported before returning to the BOImages page
    /* #endregion */

    return (
        <AppContext.Provider
            value={{
                getUserDataToken,
                setGetUserDataToken,
                refreshUserData,
                setRefreshUserData,
                isLoadingApp,
                setIsLoadingApp,
                redirectURL,
                setRedirectURL,

                showUnsavedChangesPopup,
                setShowUnsavedChangesPopup,
                hasUnsavedChanges,
                setHasUnsavedChanges,

                userData,
                setUserData,

                sidePanelIsLoading,
                setSidePanelIsLoading,
                sidePanelVisible,
                setSidePanelVisible,
                sidePanelSection,
                setSidePanelSection,
                sidePanelLabelBrandID,
                setSidePanelLabelBrandID,
                sidePanelProducts,
                setSidePanelProducts,
                sidePanelProductSearchName,
                setSidePanelProductSearchName,
                sidePanelBOtagProductLink,
                setSidePanelBOtagProductLink,
                BOTagsList,
                setBOTagsList,
                sidePanelHasNewLinkedProducts,
                setSidePanelHasNewLinkedProducts,
                sidePanelSelectedProductID,
                setSidePanelSelectedProductID,
                sidePanelSelectedProductNumber,
                setSidePanelSelectedProductNumber,
                brandNotSelectedShowError,
                setBrandNotSelectedShowError,
                sidePanelHasNoProducts,
                setSidePanelHasNoProducts,
                showAddProductsPopup,
                setShowAddProductsPopup,
                boImageProductsImporting,
                setBOImageProductsImporting
            }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;
