import React from "react";
import styled from "styled-components";

const StyledElems = styled.div`
    &.category-tag-main {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        max-width: 100%;
        overflow: hidden;
    }
    .category-tag-container {
        display: flex;
        gap: 5px;
        background-color: #013774;
        padding: 5px;
        border-radius: 5px;
        align-items: center;
        height: 25px;
        width: max-content;
        max-width: 100%;
    }
    .category-tag-input {
        background: transparent;
        border: none;
    }
    .category-tag-input {
        display: flex;
        align-items: flex-start;
        flex: 1;
    }
    .category-tag-input input {
        background-color: transparent;
        flex: 1;
        outline: none;
        border: 0;
    }
    .category-tag-input input:focus {
        border: none;
        background-color: transparent;
        border-width: 0px;
        border-color: transparent;
    }
    .tags-input {
        &:focus-within {
            box-shadow: rgba(0, 174, 239, 0.1) 0px 0px 2px 1px, rgb(54 74 103) 0px 0px 8px 2px;
        }
    }
    .remove-button {
        cursor: pointer;
    }

    &.rounded .category-tag-container {
        border-radius: 9999px !important;
        padding: 0px 16px 0px 16px;
        gap: 10px;
    }
`;

/**
 * Renders a tag component.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.className=""] - The class name for the tag component.
 * @param {number} props.index - The index of the tag.
 * @param {string} props.bgColor - The background color of the tag.
 * @param {string} props.tagName - The name of the tag.
 * @param {function} props.onRemoveClick - The function to call when the tag is removed.
 * @param {boolean} [props.hideRemoveButton=false] - Whether to hide the remove button.
 * @param {string} [props.textColor="var(--text-icons-gray-100)"] - The text color of the tag.
 * @param {string} [props.xTextColor="var(--text-icons-gray-100)"] - The text color of the remove button.
 * @param {boolean} [props.useRoundedVariant=false] - Whether to use the rounded variant.
 *
 * @returns {JSX.Element} The rendered tag component.
 */
const TagComponent = ({
    className = "",
    index,
    bgColor,
    tagName,
    onRemoveClick,
    hideRemoveButton = false,
    textColor = "var(--text-icons-gray-100)",
    xTextColor = "var(--text-icons-gray-100)",
    useRoundedVariant = false
}) => {
    const handleOnRemoveClick = e => {
        e.stopPropagation();
        onRemoveClick(index);
    };

    return (
        <StyledElems className={`category-tag-main ${className} ${useRoundedVariant ? "rounded" : ""}`}>
            <div
                className="category-tag-container"
                key={index}
                style={{
                    backgroundColor: bgColor
                }}>
                <div
                    className="text-one-line"
                    style={{ fontSize: "12px", flexShrink: 1, color: textColor, display: "block" }}>
                    {tagName}
                </div>

                {!hideRemoveButton && (
                    <div onClick={handleOnRemoveClick} style={{ zIndex: 999 }}>
                        <i
                            className="remove-button fa-solid fa-xmark"
                            style={{
                                fontSize: "13px",
                                fontWeight: "500",
                                position: "relative",
                                top: "1px",
                                color: xTextColor
                            }}></i>
                    </div>
                )}
            </div>
        </StyledElems>
    );
};

export default TagComponent;
