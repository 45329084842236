import axios from "axios";

/**
 * Fetches the address from the given latitude and longitude coordinates.
 * @param {number} latitude - The latitude coordinate.
 * @param {number} longitude - The longitude coordinate.
 * @returns {Promise<Object|null>} - A promise that resolves to an object containing the fetched address information,
 * or null if an error occurred.
 */
export const fetchAddressFromCoordinates = async (latitude, longitude, city, country, postalCode) => {
    try {
        const res = await axios.post(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_MAP_KEY}`
        );

        const newUserAddress = {
            street: undefined,
            streetNumber: undefined,
            city: undefined,
            country: undefined,
            postCode: undefined
        };
        if (res) {
            let resultToUse = "";

            //TODO: This is was an attempt to get the street address + number + postal code. But may giving not the most accurante result.
            //So we continue using the first result for now.
            /* for (let i = 0; i < res?.data?.results.length; i++) {
                const currentResult = res?.data?.results[i];

                const currentStreet = currentResult?.address_components?.find(tmp =>
                    tmp?.types?.includes("route")
                )?.long_name;
                const currentStreetNumber = currentResult?.address_components?.find(tmp =>
                    tmp?.types?.includes("street_number")
                )?.long_name;
                const currentPostalCode = currentResult?.address_components?.find(tmp =>
                    tmp?.types?.includes("postal_code")
                )?.long_name;
                //console.log(`Result[${i}]: ${currentStreet}, ${currentStreetNumber}, ${currentPostalCode}`);

                if (currentStreet && currentStreetNumber && currentPostalCode) {
                    resultToUse = currentResult;
                    console.log("Found result!", currentResult);
                    break;
                }
            } */

            if (!resultToUse) {
                //if no result was found, use the first one
                //console.log("No result found, using first one!");
                resultToUse = res?.data?.results[0];
            }

            newUserAddress.street =
                resultToUse?.address_components?.find(tmp => tmp?.types?.includes("route"))?.long_name || "";
            newUserAddress.streetNumber =
                resultToUse?.address_components?.find(tmp => tmp?.types?.includes("street_number"))?.long_name || "";
            newUserAddress.city =
                resultToUse?.address_components?.find(tmp => tmp?.types?.includes("locality"))?.long_name ||
                resultToUse?.address_components?.find(tmp => tmp?.types?.includes("postal_town"))?.long_name ||
                resultToUse?.address_components?.find(tmp => tmp?.types?.includes("administrative_area_level_2"))
                    ?.long_name ||
                resultToUse?.address_components?.find(tmp => tmp?.types?.includes("administrative_area_level_1"))
                    ?.long_name ||
                city ||
                "";
            newUserAddress.country =
                resultToUse?.address_components?.find(tmp => tmp?.types?.includes("country"))?.long_name ||
                country ||
                "";
            newUserAddress.postCode =
                resultToUse?.address_components?.find(tmp => tmp?.types?.includes("postal_code"))?.long_name ||
                postalCode ||
                "";

            return newUserAddress;
        }

        return null;
    } catch (error) {
        console.error("Error while fetching user address", error);
        return null;
    }
};

/**
 * Fetches the latitude and longitude coordinates from the given address.
 * @param {string} plusCode - The address to fetch the coordinates for.
 * @returns {Promise<Object|null>} - A promise that resolves to an object containing the fetched coordinates information,
 * or null if an error occurred.
 */

export const getLatLngUsingPlusCode = async plusCode => {
    const res = await axios.get(
        "https://plus.codes/api?address=" + encodeURIComponent(plusCode) + "&ekey=" + process.env.REACT_APP_MAP_KEY
    );

    if (res.data?.status !== "OK") return null;
    const { lat, lng } = res.data?.plus_code?.geometry?.location;

    return { lat, lng };
};

/**
 * Retrieves the user's location based on their IP address.
 * @returns {Promise<{latitude: number, longitude: number}>} The latitude and longitude of the user's location.
 * @throws {Error} If there is an error while fetching the user's location by IP.
 */
export const getLocationByIP = async () => {
    try {
        const res = await axios.get("https://geolocation-db.com/json/");

        if (res) {
            const { latitude, longitude } = res.data;

            return { latitude, longitude };
        } else throw new Error("Failed to fetch user location by IP");
    } catch (error) {
        console.error("Error while fetching user location by IP", error);
        return null;
    }
};

/**
 * Retrieves the user's location based on their IP address.
// recieve a callback function in which data is stored
 */

export const getUserLocationByIP = async callback => {
    try {
        const response = await axios.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IP_TOKEN}`);
        const loc = response?.data?.loc;
        const [lat, lon] = loc.split(",");
        const address = response?.data;

        if (response?.status === 200) {
            callback({
                latitude: Number(lat),
                longitude: Number(lon),
                country: address?.country,
                city: address?.city,
                postalCode: address?.postal
            });
        }
    } catch (error) {
        console.error(error);
    }
};
