import { hexToRgb, hexToRgbSplit, rgbToHex } from "../../../utils/ColorConverter";

const removeEmptyFields = obj => {
    return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] && typeof obj[key] === "object" && !Array.isArray(obj[key])) {
            if (
                obj[key].hasOwnProperty("value") &&
                (obj[key].value === undefined || obj[key].value === null || obj[key].value === "")
            ) {
                return acc; // Skip this object
            }
            const filteredObj = removeEmptyFields(obj[key]);
            if (Object.keys(filteredObj).length > 0) {
                acc[key] = filteredObj;
            }
        } else if (obj[key] !== undefined && obj[key] !== null && obj[key] !== "") {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
};

export const createProductData = (
    product,
    filteredFormData,
    labelBrandID,
    productImagesBase64,
    checkboxState,
    customFormData,
    customFieldNames
) => {
    const imageKeys = Array.from({ length: 12 }, (_, i) => `image${i + 1}`);

    //---- Product Images Link ----//

    const filteredImagesLink = imageKeys
        .map(key => product[filteredFormData[key]])
        .filter(image => image !== undefined)
        .map(image => ({ name: "-", URL: image }));

    //---- Product Images Base64 ----//

    const filteredImages = imageKeys.map(key => product[filteredFormData[key]]).filter(image => image !== undefined);
    const filteredImagesBase64 = productImagesBase64
        .filter(imageObj => filteredImages.includes(imageObj.name))
        .map(imageObj => ({ name: imageObj.name, base64: imageObj.base64 }));

    //-----
    const imagesData = checkboxState ? { images: filteredImagesLink } : { imageBase64: filteredImagesBase64 };

    //---- Custom Fields ----//
    const customFieldsArray = Object.entries(customFormData)
        .map(([key, value]) => ({
            fieldName: customFieldNames[key] || key,
            value: product[value],
            showInBOtag: false
        }))
        .filter(field => field.value !== undefined && field.value !== null && field.value !== "");

    //---- Product Color ----//

    const color1Hexacode = product[filteredFormData.color1Hexacode];
    const color2Hexacode = product[filteredFormData.color2Hexacode];
    const color3Hexacode = product[filteredFormData.color3Hexacode];
    const color4Hexacode = product[filteredFormData.color4Hexacode];

    const rgbColor1 = hexToRgbSplit(color1Hexacode);
    const rgbColor2 = hexToRgbSplit(color2Hexacode);
    const rgbColor3 = hexToRgbSplit(color3Hexacode);
    const rgbColor4 = hexToRgbSplit(color4Hexacode);

    const productData = {
        productID: {
            fieldName: customFieldNames.productID || "Product ID",
            value: product[filteredFormData.productID],
            showInBOtag: false
        },
        specifications: {
            fieldName: customFieldNames.specifications || "Specifications",
            value: product[filteredFormData.specifications],
            showInBOtag: true
        },
        features: {
            fieldName: customFieldNames.features || "Features",
            value: product[filteredFormData.features],
            showInBOtag: true
        },
        qualityDescription: {
            fieldName: customFieldNames.qualityDescription || "Quality Description",
            value: product[filteredFormData.qualityDescription],
            showInBOtag: true
        },
        assortment: {
            fieldName: customFieldNames.assortment || "Assortment",
            value: product[filteredFormData.assortment],
            showInBOtag: false
        },
        assortmentCode: {
            fieldName: customFieldNames.assortmentCode || "Assortment Code",
            value: product[filteredFormData.assortmentCode],
            showInBOtag: false
        },
        comments: {
            fieldName: customFieldNames.comments || "Comments",
            value: product[filteredFormData.comments],
            showInBOtag: true
        },

        // Materials & Care
        // Materials
        composition: product[filteredFormData.material1], //TODO: Legacy variable to be removed later
        materials: [
            {
                fieldName: customFieldNames.material1 || "Material 1",
                value: product[filteredFormData.material1],
                showInBOtag: true
            },
            {
                fieldName: customFieldNames.material2 || "Material 2",
                value: product[filteredFormData.material2],
                showInBOtag: true
            },
            {
                fieldName: customFieldNames.material3 || "Material 3",
                value: product[filteredFormData.material3],
                showInBOtag: true
            },
            {
                fieldName: customFieldNames.material4 || "Material 4",
                value: product[filteredFormData.material4],
                showInBOtag: true
            }
        ].filter(field => field.value !== undefined && field.value !== null && field.value !== ""),
        // Care

        maintenance: product[filteredFormData.care1], //TODO: Legacy variable to be removed later
        cares: [
            {
                fieldName: customFieldNames.care1 || "Care 1",
                value: product[filteredFormData.care1],
                showInBOtag: true
            },
            {
                fieldName: customFieldNames.care2 || "Care 2",
                value: product[filteredFormData.care2],
                showInBOtag: true
            },
            {
                fieldName: customFieldNames.care3 || "Care 3",
                value: product[filteredFormData.care3],
                showInBOtag: true
            },
            {
                fieldName: customFieldNames.care4 || "Care 4",
                value: product[filteredFormData.care4],
                showInBOtag: true
            }
        ].filter(field => field.value !== undefined && field.value !== null && field.value !== ""),

        // Transparency
        factoryOrigin: product[filteredFormData.factoryOrigin],
        productionLocations: product[filteredFormData.productionLocations],
        productionDates: product[filteredFormData.productionDates],
        rawMaterialSources: product[filteredFormData.rawMaterialSources],
        batchOrLotNumber: product[filteredFormData.batchOrLotNumber],
        storageInformation: product[filteredFormData.storageInformation],
        certificationsAndStandards: product[filteredFormData.certificationsAndStandards],
        laborPractices: product[filteredFormData.laborPractices],

        // Sustainability
        carbonFootprint: product[filteredFormData.carbonFootprint],
        waterUsage: product[filteredFormData.waterUsage],
        energyConsumption: product[filteredFormData.energyConsumption],
        waterManagement: product[filteredFormData.waterManagement], //TODO: Wrong name in Backend (waterManagement -> wasteManagement)
        chemicalUsage: product[filteredFormData.chemicalUsage],
        sustainableSourcing: product[filteredFormData.sustainableSourcing],
        packaging: product[filteredFormData.packaging],
        environmentalCertifications: product[filteredFormData.environmentalCertifications],

        // Other fields
        tariffCode: {
            fieldName: customFieldNames.tariffCode || "Tariff Code",
            value: product[filteredFormData.tariffCode],
            showInBOtag: false
        },

        // ===========| Custom Fields |==========
        otherCustomFields: customFieldsArray,

        labelBrandID: labelBrandID,
        //Mandatory fields
        name: product[filteredFormData.name],
        shortDescription: product[filteredFormData.shortDescription],
        description: product[filteredFormData.description],
        countryOfOrigin: product[filteredFormData.countryOfOrigin],
        referenceCode: product[filteredFormData.referenceCode],

        //Optional fields
        productType: product[filteredFormData.productType],
        category: product[filteredFormData.category],
        digitalProductPassport: product[filteredFormData.digitalProductPassport],
        collectionName: product[filteredFormData.collectionName],
        gender: product[filteredFormData.gender],

        // GTIN Data
        GTINData: {
            barcode: String(product[filteredFormData.barcode]),
            labelBrandID: labelBrandID,
            productObjID: product[filteredFormData.productObjID],

            // Mandatory fields
            size: product[filteredFormData.size1],
            sizes: [
                {
                    fieldName: customFieldNames.size1 || "Size 1",
                    value: product[filteredFormData.size1],
                    showInBOtag: true
                },
                {
                    fieldName: customFieldNames.size2 || "Size 2",
                    value: product[filteredFormData.size2],
                    showInBOtag: true
                },
                {
                    fieldName: customFieldNames.size3 || "Size 3",
                    value: product[filteredFormData.size3],
                    showInBOtag: true
                },
                {
                    fieldName: customFieldNames.size4 || "Size 4",
                    value: product[filteredFormData.size4],
                    showInBOtag: true
                }
            ].filter(field => field.value !== undefined && field.value !== null && field.value !== ""),

            colorName: product[filteredFormData.color1Name],
            colorDescription: product[filteredFormData.color1Description],
            colorCodename: product[filteredFormData.color1Code],
            colorCode: rgbToHex(color1Hexacode),
            colorRGB: rgbColor1
                ? {
                      r: { type: rgbColor1.r },
                      g: { type: rgbColor1.g },
                      b: { type: rgbColor1.b }
                  }
                : {},
            colors: [
                {
                    name: {
                        fieldName: customFieldNames.color1Name || "Color 1 Name",
                        value: product[filteredFormData.color1Name],
                        showInBOtag: true
                    },
                    description: {
                        fieldName: customFieldNames.color1Description || "Color 1 Description",
                        value: product[filteredFormData.color1Description],
                        showInBOtag: true
                    },
                    codename: {
                        fieldName: customFieldNames.color1Code || "Color 1 Codename",
                        value: product[filteredFormData.color1Code],
                        showInBOtag: true
                    },
                    hexacode: {
                        fieldName: customFieldNames.color1Hexacode || "Color 1 Hexacode",
                        value: rgbToHex(color1Hexacode),
                        showInBOtag: true
                    },
                    RGB: rgbColor1
                        ? {
                              r: { type: rgbColor1.r },
                              g: { type: rgbColor1.g },
                              b: { type: rgbColor1.b }
                          }
                        : {}
                },
                {
                    name: {
                        fieldName: customFieldNames.color2Name || "Color 2 Name",
                        value: product[filteredFormData.color2Name],
                        showInBOtag: true
                    },
                    description: {
                        fieldName: customFieldNames.color2Description || "Color 2 Description",
                        value: product[filteredFormData.color2Description],
                        showInBOtag: true
                    },
                    codename: {
                        fieldName: customFieldNames.color2Code || "Color 2 Codename",
                        value: product[filteredFormData.color2Code],
                        showInBOtag: true
                    },
                    hexacode: {
                        fieldName: customFieldNames.color2Hexacode || "Color 2 Hexacode",
                        value: rgbToHex(color2Hexacode),
                        showInBOtag: true
                    },
                    RGB: rgbColor2
                        ? {
                              r: { type: rgbColor2.r },
                              g: { type: rgbColor2.g },
                              b: { type: rgbColor2.b }
                          }
                        : {}
                },
                {
                    name: {
                        fieldName: customFieldNames.color3Name || "Color 3 Name",
                        value: product[filteredFormData.color3Name],
                        showInBOtag: true
                    },
                    description: {
                        fieldName: customFieldNames.color3Description || "Color 3 Description",
                        value: product[filteredFormData.color3Description],
                        showInBOtag: true
                    },
                    codename: {
                        fieldName: customFieldNames.color3Code || "Color 3 Codename",
                        value: product[filteredFormData.color3Code],
                        showInBOtag: true
                    },
                    hexacode: {
                        fieldName: customFieldNames.color3Hexacode || "Color 3 Hexacode",
                        value: rgbToHex(color3Hexacode),
                        showInBOtag: true
                    },
                    RGB: rgbColor3
                        ? {
                              r: { type: rgbColor3.r },
                              g: { type: rgbColor3.g },
                              b: { type: rgbColor3.b }
                          }
                        : {}
                },
                {
                    name: {
                        fieldName: customFieldNames.color4Name || "Color 4 Name",
                        value: product[filteredFormData.color4Name],
                        showInBOtag: true
                    },
                    description: {
                        fieldName: customFieldNames.color4Description || "Color 4 Description",
                        value: product[filteredFormData.color4Description],
                        showInBOtag: true
                    },
                    codename: {
                        fieldName: customFieldNames.color4Code || "Color 4 Codename",
                        value: product[filteredFormData.color4Code],
                        showInBOtag: true
                    },
                    hexacode: {
                        fieldName: customFieldNames.color4Hexacode || "Color 4 Hexacode",
                        value: rgbToHex(color4Hexacode),
                        showInBOtag: true
                    },
                    RGB: rgbColor4
                        ? {
                              r: { type: rgbColor4.r },
                              g: { type: rgbColor4.g },
                              b: { type: rgbColor4.b }
                          }
                        : {}
                }
            ]
                .map(removeEmptyFields)
                .filter(color => Object.keys(color).length > 0),

            // Optional fields
            SKU: product[filteredFormData.SKU],
            weightCustomField: {
                fieldName: customFieldNames.weightCustomField || "Weight",
                value: product[filteredFormData.weightCustomField],
                showInBOtag: false
            },
            ...imagesData,

            // Status
            statusEnum: "Active"
        }
    };

    return removeEmptyFields(productData);
};
