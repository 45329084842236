import React, { useState } from "react";
import styled from "styled-components";
import * as Library from "../../../utils/Library";

const SearchContainer = styled.div`
    &.search-container,
    &.search-container * {
        box-sizing: border-box;
    }
    &.search-container {
        position: absolute;
        /* top: -6px; */
        margin-left: 48px;
        margin-top: -12px;
        width: 385px;
    }
    .search-active {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        position: sticky;
        width: 100%;
    }
    .frame-48 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 37px;
        position: relative;
    }
    .re {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        background: none;
        border: none;
        outline: none;
        width: 100%;
    }

    .input-field-dropdown {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px;
        padding: 12px 8px 12px 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        position: sticky;
        box-shadow: var(--drop-shadow-100-box-shadow, 0px 0px 10px 0px rgba(17, 22, 32, 1));
        position: absolute;
        top: 42px;
        width: 100%;
        z-index: 9999999;
        //overflow: hidden;
    }
    .frame-74 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-349 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-348 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-78 {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .replay-ss-001 {
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
    .replay-ss-001-span {
        color: var(--text-and-icons-green-500, #00bb00);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
    }
    .replay-ss-001-span2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
    }
`;

export default function ControlbarSearch({
    style,
    className,
    apiName,
    apiNameField = "name",
    includeInAPIFormData = {},
    useBOcommon = false,
    BOCommonContext,
    onItemSelected
}) {
    /* #region VARS */
    const [searchText, setSearchText] = useState("");

    const [autoCompleteSuggestions, setAutoCompleteSuggestions] = useState([]);
    /* #endregion */

    /* #region METHODS */
    const getAutoCompleteSuggestions = async searchText => {
        const formData = {
            ...includeInAPIFormData,
            searchText: searchText
        };
        let suggestionsRes = undefined;
        if (!useBOcommon) {
            suggestionsRes = await Library.makePostRequest(apiName, formData);
        } else {
            suggestionsRes = await Library.makeCommonPostRequest(BOCommonContext, apiName, formData);
        }
        if (!suggestionsRes || !suggestionsRes.data || suggestionsRes.status !== 200) return [];

        const suggestions = suggestionsRes?.data?.data || [];

        /* const suggestions = data
            ?.map(suggestion => suggestion[apiNameField])
            ?.filter(suggestion => suggestion.length > 0);

        return suggestions;*/
        return suggestions;
    };

    const handleSearchTextChange = async searchText => {
        setSearchText(searchText);

        if (searchText.length === 0) {
            setAutoCompleteSuggestions([]);
            return;
        }

        const suggestions = await getAutoCompleteSuggestions(searchText);
        setAutoCompleteSuggestions(suggestions);
    };

    const onSuggestionItemClick = suggestionObj => {
        setSearchText("");
        setAutoCompleteSuggestions([]);
        onItemSelected(suggestionObj);
    };

    /* #endregion */

    return (
        <SearchContainer className={`search-container ${className ? className : ""}`} style={style}>
            <div className="search-active">
                <div className="frame-48">
                    <input
                        type="text"
                        className="re"
                        value={searchText}
                        onChange={e => handleSearchTextChange(e.target.value)}
                    />
                </div>
            </div>

            <div className={`input-field-dropdown w-100 ov-hidden ${searchText.length > 0 ? "d-block" : "d-none"}`}>
                <div
                    className={`frame-74 w-100 ${autoCompleteSuggestions.length > 0 ? "d-block" : "d-none"} `}
                    style={{ maxHeight: "174px", overflowY: "auto" }}>
                    <div className="frame-349 w-100 ov-hidden">
                        <div className="frame-348">
                            {autoCompleteSuggestions?.map((suggestionObj, index) => {
                                const suggestion = suggestionObj[apiNameField];
                                return (
                                    <div
                                        className="frame-78 cursor-pointer"
                                        key={index}
                                        onClick={() => onSuggestionItemClick(suggestionObj)}>
                                        <div className="replay-ss-001">
                                            <span>
                                                <ReplaceStringWithColor text={suggestion} searchText={searchText} />
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className={`frame-74 w-100 ${autoCompleteSuggestions.length === 0 ? "d-block" : "d-none"} `}>
                    <div className="frame-349 w-100 ov-hidden">
                        <span className="replay-ss-001-span2">No results found</span>
                    </div>
                </div>
            </div>
        </SearchContainer>
    );
}

export const ReplaceStringWithColor = ({ text, searchText }) => {
    if (!searchText || !searchText?.toLowerCase) searchText = "";
    const parts = text?.split(new RegExp(`(${searchText})`, "gi"));

    return (
        <div>
            {parts?.map((part, index) => (
                <span
                    key={index}
                    className={
                        part?.toLowerCase() === searchText?.toLowerCase() ? "replay-ss-001-span" : "replay-ss-001-span2"
                    }>
                    {part}
                </span>
            ))}
        </div>
    );
};
