import countries from "countries-and-timezones";
import { getCountryCallingCode } from "libphonenumber-js";

const formatTimezone = utcOffset => {
    const sign = utcOffset.startsWith("-") ? "-" : "+";
    const [hours, minutes] = utcOffset.slice(1).split(":").map(Number);
    return `GMT${sign}${hours}:${minutes === 0 ? "00" : minutes}`;
};

export const getDropdownData = (optionType = "") => {
    let data = Object.values(countries.getAllCountries())
        .map(country => {
            switch (optionType) {
                case "countryOptions":
                    return {
                        value: country.name,
                        name: country.name,
                        id: country.id
                    };
                case "countryCodeOptions":
                    let callingCode = null;
                    try {
                        callingCode = getCountryCallingCode(country.id);
                    } catch (error) {
                        callingCode = "NA";
                    }
                    return {
                        value: callingCode,
                        name: `(+${callingCode}) ${country.name}`,
                        id: country.id
                    };
                case "timeZoneOptions":
                    const timezoneInfo = countries.getTimezone(country.timezones[0]);
                    return {
                        value: country.timezones[0],
                        name: `${formatTimezone(timezoneInfo.utcOffsetStr)} (${country.timezones[0]})`,
                        id: country.id
                    };
                case "taxNumberOptions":
                    return {
                        value: country.id,
                        name: `${country.id} (${country.name})`
                    };
                default:
                    return [];
            }
        })
        //remove duplicates
        .filter((country, index, self) => index === self.findIndex(t => t.value === country.value));

    if (optionType !== "timeZoneOptions") {
        data = data.sort((a, b) => {
            const countryCode = a.name.replace(/^\(\+\d*\)\s*/, "");
            const country = b.name.replace(/^\(\+\d*\)\s*/, "");
            return countryCode.localeCompare(country);
        });
    } else {
        data = sortTimeZoneOptions(data);
    }

    return data;
};

const sortTimeZoneOptions = data => {
    const sortedData = data.sort((a, b) => {
        //fetch the number of hours from the timezone string and compare them
        const aOffset = Number(a.name.split(":")[0].replace("GMT", ""));
        const bOffset = Number(b.name.split(":")[0].replace("GMT", ""));

        //if the same offset, sort by timezone label inside the parentheses
        if (aOffset === bOffset) {
            const aTimezoneLabel = a.name.match(/\(([^)]+)\)/)[1];
            const bTimezoneLabel = b.name.match(/\(([^)]+)\)/)[1];

            return aTimezoneLabel.localeCompare(bTimezoneLabel);
        }

        return aOffset - bOffset;
    });

    return sortedData;
};
