import styled from "styled-components";
import FormField from "../../../../common/forms/FormField";
import BtnLargeSolid from "../../../../common/buttons/BtnLargeSolid";

/* #region  SCOPED CSS */
const StyledComponent = styled.div`
    & {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 0 auto;
        width: 100%;
        max-width: 750px;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 20px;
    }

    .idCodes-input-container {
        display: flex;
        border-radius: 4px;
        border: 1px solid var(--backgrounds-lines-n-100, #3c485a);
        background: var(--backgrounds-lines-n-300, #1a222d);
        padding: 16px 16px 16px 16px;
        gap: 16px;
        /* width: 100px; */
        /* height: 100%; */
    }

    .idCodes-input-info-container {
        color: #fff;

        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        max-width: 322px;
        flex-shrink: 0;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .custom-field {
        width: 314px;
    }
`;
/* #endregion */

export default function IdCodes({
    TabsItems,
    isDragging,
    formData,
    handleInputChange,
    handleSetEanCode,
    setTabIndex,
    tabIndex,
    handleCheckEmptyLabel
}) {
    return (
        <StyledComponent>
            <div className="idCodes-input-container">
                <FormField
                    className="custom-field"
                    type={"droppable"}
                    isDragging={isDragging && !TabsItems[0].locked}
                    title={"EAN13"}
                    tooltip="Enter the EAN13"
                    requiredType={TabsItems[0].locked ? "locked" : "mandatory"}
                    value={formData.barcode}
                    onTextChange={value => handleSetEanCode(value)}
                    isInvalid={handleCheckEmptyLabel(formData.barcode)}
                />
                <div className="idCodes-input-info-container" style={{ color: TabsItems[0].locked && "#4F5B6D" }}>
                    The EAN13 Code functions as the product identifier for integrating with POS systems in stores
                    affiliated with your brand as fulfillment centers.
                </div>
            </div>
            <div className="idCodes-input-container">
                <FormField
                    className="custom-field"
                    type={"droppable"}
                    isDragging={isDragging && !TabsItems[0].locked}
                    title="Style code"
                    tooltip="Enter the Style code"
                    requiredType={TabsItems[0].locked ? "locked" : "mandatory"}
                    value={formData.referenceCode}
                    onTextChange={value => handleInputChange("referenceCode", value)}
                    isInvalid={handleCheckEmptyLabel(formData.referenceCode)}
                />
                <div className="idCodes-input-info-container" style={{ color: TabsItems[0].locked && "#4F5B6D" }}>
                    The Style Code is the differentiator between products. If the same item has different colors, the
                    Style code should be the same for all colors.
                </div>
            </div>
            <div className="idCodes-input-container">
                <FormField
                    className="custom-field"
                    type={"droppable"}
                    isDragging={isDragging && !TabsItems[0].locked}
                    title="Digital product passport"
                    tooltip="Enter the Digital product passport"
                    requiredType={TabsItems[0].locked ? "locked" : "recommended"}
                    value={formData.digitalProductPassport}
                    onTextChange={value => handleInputChange("digitalProductPassport", value)}
                />
                <div className="idCodes-input-info-container" style={{ color: TabsItems[0].locked && "#4F5B6D" }}>
                    The Digital Product Passport provides detailed product information, enhancing transparency and
                    sustainability across its lifecycle.
                </div>
            </div>
            <div
                style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    padding: "10px 20px 10px 10px",
                    width: "100%"
                }}>
                <BtnLargeSolid
                    className="ml-10px"
                    text={"CONTINUE"}
                    disabled={TabsItems[tabIndex + 1].locked}
                    disabledColor={"#10141B"}
                    onClick={() => setTabIndex(tabIndex + 1)}
                />
            </div>
        </StyledComponent>
    );
}
