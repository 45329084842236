import React from "react";
import FullScreenOverlay from "./overlay/FullScreenOverlay";
import DialogBoxComponent from "./dialogs/DialogBoxComponent";
import BOStageBLogo from "../../assets/images/footer-logologin.png";
//import { useNavigate } from "react-router";
import LayoutContext from "../../LayoutContext";
import AppContext from "../../AppContext";

export default function PopupManager() {
    /* #region VARS */
    //const navigate = useNavigate();
    const { showIsSubscribedPopup, setShowIsSubscribedPopup, isLoadingAccountPage } = React.useContext(LayoutContext);
    const { isLoadingApp } = React.useContext(AppContext);
    /* #endregion */

    return (
        <div>
            <FullScreenOverlay show={showIsSubscribedPopup && !isLoadingApp && !isLoadingAccountPage}>
                <DialogBoxComponent
                    title="Subscribed!"
                    message={
                        "<div style='font-size: 14px; margin: 0 -5px;'>We will send you an email when the trial period starts.<br />You can now continue activating your account.</div>"
                    }
                    onClickYes={() => {
                        setShowIsSubscribedPopup(false);
                    }}
                    useImageHeader={{ src: BOStageBLogo, width: "165px" }}
                    showCancel={false}
                    confirmText="CONTINUE"
                />
            </FullScreenOverlay>
        </div>
    );
}
