const fieldNames = [
    //---- ID Codes ----
    "EAN13",
    "Style code",
    "Digital product passport",
    //---- Item ID ----
    "Style Name",
    "Style code",
    "Short description",
    "Description",
    "Collection",
    "Gender",
    "Category",
    "SKU",
    "Product ID",
    "Country of origin",
    "Specifications",
    "Features",
    "Quality description",
    "Assortment",
    "Assortment code",
    "Comments",
    //---- Item Variations ----
    "Size 1",
    "Size 2",
    "Size 3",
    "Size 4",
    "Color 1 name",
    "Color 1 description",
    "Color 1 code",
    "Color 1 hexacode",
    //---- Material & Care ----
    "Material 1",
    "Material 2",
    "Material 3",
    "Material 4",
    "Care 1",
    "Care 2",
    "Care 3",
    "Care 4",
    //---- Transparency & Sustainability ----
    "Supplier name",
    "Production locations",
    "Production dates",
    "Raw material source",
    "Batch or lot number",
    "Storage information",
    "Certifications and Standards",
    "Labor practices",

    "Carbon footprint",
    "Water usage",
    "Energy consumption",
    "Waste management",
    "Chemical usage",
    "Sustainable sourcing",
    "Packaging",
    "Environmental certifications",
    //---- Other Fields ----
    "Weight",
    "Tariff code",
    "Field 3",
    "Field 4",
    //---- Image Links ----
    "Main Image",
    "Image 2",
    "Image 3",
    "Image 4"
];

//This is will be used to match the field names from the AI labels with the field names from the database
const fieldNamesMatch = {
    //------- ID Codes
    barcode: "EAN13",
    referenceCode: "Style code",
    digitalProductPassport: "Digital product passport",
    //------- Item ID
    name: "Style Name",
    //styleCode: "",
    shortDescription: "Short description",
    description: "Description",
    collectionName: "Collection",
    gender: "Gender",
    productType: "Category",
    SKU: "SKU",

    productID: "Product ID",
    countryOfOrigin: "Country of origin",
    specifications: "Specifications",
    features: "Features",

    qualityDescription: "Quality description",
    assortment: "Assortment",
    assortmentCode: "Assortment code",
    comments: "Comments",
    //------- ITEM VARIATIONS
    size1: "Size 1",
    size2: "Size 2",
    size3: "Size 3",
    size4: "Size 4",
    color1Name: "Color 1 name",
    color1Description: "Color 1 description",
    color1Code: "Color 1 code",
    color1Hexacode: "Color 1 hexacode",
    //------- Material & Care
    material1: "Material 1",
    material2: "Material 2",
    material3: "Material 3",
    material4: "Material 4",
    care1: "Care 1",
    care2: "Care 2",
    care3: "Care 3",
    care4: "Care 4",
    //------- TRANSPARENCY & SUSTAINABILITY
    factoryOrigin: "Supplier name",
    productionLocations: "Production locations",
    productionDates: "Production dates",
    rawMaterialSource: "Raw material source",

    batchOrLotNumber: "Batch or lot number",
    storageInformation: "Storage information",
    certificationsAndStandards: "Certifications and Standards",
    laborPractices: "Labor practices",

    carbonFootprint: "Carbon footprint",
    waterUsage: "Water usage",
    energyConsumption: "Energy consumption",
    waterManagement: "Waste management",

    chemicalUsage: "Chemical usage",
    sustainableSourcing: "Sustainable sourcing",
    packaging: "Packaging",
    environmentalCertifications: "Environmental certifications",
    //------- Other Fields
    weightCustomField: "Weight",
    tariffCode: "Tariff code",
    field3: "Field 3",
    field4: "Field 4",
    //------- Images Links/Files Names
    image1: "Main Image",
    image2: "Image 2",
    image3: "Image 3",
    image4: "Image 4"
};

const mandatoryFields = [
    "barcode",
    "referenceCode",
    "name",
    "shortDescription",
    "description",
    "size1",
    "color1Name",
    "material1",
    "care1",
    "image1"
];

export { fieldNames, fieldNamesMatch, mandatoryFields };
