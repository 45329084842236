import React from "react";
import styled, { css } from "styled-components";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        overflow: auto;
        white-space: nowrap;
    }
`;

const Tab = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
    color: white;
    ${({ locked }) =>
        locked &&
        css`
            cursor: not-allowed;
            opacity: 0.5;
        `}
    ${({ selected }) =>
        selected &&
        css`
            color: #00aeef;
        `}
`;

const TabLabel = styled.div`
    display: flex;
    color: ${({ locked, selected }) => (locked ? "#4f5b6d" : selected ? "#00AEEF" : "#D1D1D1")};
    text-align: center;
    font-family: "Roboto-Medium", sans-serif;
    font-size: 14px;
    letter-spacing: 0.015em;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    width: auto;
`;

const Underline = styled.div`
    background: ${({ locked, selected }) => (locked ? "#4f5b6d" : selected ? "#00AEEF" : "#D1D1D1")};
    border-radius: 500px;
    align-self: stretch;
    flex-shrink: 0;
    height: 3px;
    position: relative;
`;

const Alert = styled.div`
    display: flex;
    width: 6px;
    height: 6px;
    padding: 1px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 34px;
    background: var(--text-icons-red-500, #ff3636);
`;
/* #endregion */

export default function Tabs({ onClick, selectedTab = 0, TabsItems }) {
    const handleOnClick = index => {
        if (TabsItems[index].locked) return;
        onClick(index);
    };

    return (
        <StyledPage>
            {TabsItems.map((tab, index) => (
                <Tab
                    key={index}
                    locked={tab.locked}
                    selected={selectedTab === index}
                    onClick={() => handleOnClick(index)}>
                    <TabLabel locked={tab.locked} selected={selectedTab === index}>
                        {tab.title}
                        {tab?.alert && <Alert />}
                    </TabLabel>

                    {selectedTab === index && <Underline locked={tab.locked} selected={selectedTab === index} />}
                </Tab>
            ))}
        </StyledPage>
    );
}

//----------| Usage |----------//

// import AddProductNavTap from "./AddProductNavTap";

// <AddProductNavTap
//     selectedTab={1}
//     TabsItems={[
//            { title: "ID CODES", locked: true, alert: false },
//            { title: "ITEM ID", locked: true, alert: true },
//            { title: "ITEM VARIATIONS", locked: true, alert: false },
//     ]}
