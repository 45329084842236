import * as Library from "../../../utils/Library";

export const DeleteAllProductsFromCompany = async ({ companyObjID, debugMode = true }) => {
    if (companyObjID?.length !== 24) throw new Error("Invalid Company ID.");

    const response = await Library.makeCommonPostRequest("Scripts", "deleteAllProductsFromCompany", {
        companyObjID,
        debugMode
    });
    return response;
};
