import React from "react";
import styled from "styled-components";
import GridCardComponent from "./generic/GridCardComponent";
import { formatDefaultDateTime, formatDefaultDate } from "../../../utils/Formatter";

/* #region  SCOPED CSS */
const StyledPage = styled.div``;
/* #endregion */

/**
 * Renders a grid card component for displaying company information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The CSS class name for the component.
 * @param {string} props.title - The title of the company.
 * @param {string} [props.titlePrefix="COMPANY"] - The prefix for the title.
 * @param {string} [props.idPrefix="BOTAG ID"] - The prefix for the ID.
 * @param {string} props.id - The ID of the company.
 * @param {boolean} props.hasBorders - Indicates if the card has borders.
 * @param {boolean} props.isCardEnabled - Indicates if the card is enabled.
 * @param {boolean} props.isCardSuspended - Indicates if the card is suspended.
 * @param {string} props.image - The image URL for the company.
 * @param {Object[]} props.details - The array of company details.
 * @param {string} props.details[].key - The key of the detail.
 * @param {string} props.details[].value - The value of the detail.
 * @param {Object} props.edit - The edit button object.
 * @param {Function} props.edit.onClick - The edit button click event handler.
 * @returns {JSX.Element} The rendered CompanyGridCard component.
 */
export default function CompanyGridCard(props) {
    return (
        <StyledPage className={`${props?.className || ""}`}>
            <GridCardComponent
                primaryButton={{ title: "ADD COMPANY", onClick: props?.primaryButton?.onClick }}
                secondaryButton={{ title: "Add Users", onClick: props?.secondaryButton?.onClick }}
                title={props?.title}
                titlePrefix={props?.titlePrefix || "COMPANY"}
                idPrefix={props?.idPrefix || "BOTAG ID"}
                id={props?.id}
                hasBorders={props?.hasBorders}
                isCardEnabled={props?.isCardEnabled}
                isCardSuspended={props?.isCardSuspended}
                image={props?.image}
                imageHasPadding={true}
                details={[
                    { key: "Created by:", value: props?.data?.createdBy },
                    { key: "Created on:", value: formatDefaultDateTime(props?.data?.createdOn) },
                    { key: "Registration nr:", value: props?.data?.registrationNumber },
                    { key: "Registration date:", value: formatDefaultDate(props?.data?.registrationDate) },
                    { key: "Tax nr:", value: props?.data?.taxNr },
                    { key: "Brands:", value: props?.data?.nrOfBrands },
                    { key: "Stores:", value: props?.data?.nrOfStores },
                    { key: "Status:", value: props?.data?.status }
                ]}
                edit={{
                    onClick: props?.edit?.onClick
                }}
            />
        </StyledPage>
    );
}
