import React from "react";
import styled from "styled-components";
import checkEnabled from "../../../assets/icons/globalComponentChecked.png";
import checkDisabled from "../../../assets/icons/globalDropdownCheckbox.png";

/* #region region styles */
const CheckBoxStyles = styled.div`
    .main-checkbox {
        width: 16px;
        height: 16px;
        margin-bottom: 1px;
    }

    &.checkbox-standard {
        cursor: pointer;
    }

    &.checkbox-standard.disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }
`;

/* #endregion */

/**
 * Checkbox component renders a customizable checkbox.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.className=""] - Additional class names for the checkbox.
 * @param {boolean} [props.isChecked=false] - Determines if the checkbox is checked.
 * @param {Function} [props.onCheckboxClick=() => {}] - Callback function to handle checkbox click events.
 * @param {string} [props.disabled=false] - Determines if the checkbox is disabled.
 * @param {Object} [props.style] - Inline styles for the checkbox.
 * @param {string} [props.alternativeCheckSrc] - The source of the alternative check image.
 * @param {string} [props.alternativeUncheckSrc] - The source of the alternative un
 *
 * @returns {JSX.Element} The rendered Checkbox component.
 */
const Checkbox = React.forwardRef((props, ref) => {
    const {
        className = "",
        isChecked = false,
        onCheckboxClick = () => {},
        style = {},
        disabled = false,
        alternativeCheckSrc,
        alternativeUncheckSrc
    } = props;

    return (
        <CheckBoxStyles style={{ ...style }} className={`checkbox-standard ${className} ${disabled ? "disabled" : ""}`}>
            <span className="checkbox-container" onClick={disabled ? () => {} : onCheckboxClick}>
                <img
                    className={`main-checkbox ${!isChecked ? "hidden" : ""}`}
                    src={alternativeCheckSrc || checkEnabled}
                    alt="Checkbox"
                    ref={ref}
                />
                <img
                    className={`main-checkbox ${isChecked ? "hidden" : ""}`}
                    src={alternativeUncheckSrc || checkDisabled}
                    alt="Checkbox"
                />
            </span>
        </CheckBoxStyles>
    );
});

export default Checkbox;
