import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./utils/redux/store";
import { Provider } from "react-redux";
import { AppProvider } from "./AppContext";
import { LayoutProvider } from "./LayoutContext";
import { PopupProvider } from "./PopupContext";
import { LoadScript } from "@react-google-maps/api";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <AppProvider>
            <LayoutProvider>
                <PopupProvider>
                    <LoadScript
                        googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
                        loadingElement={<div style={{ height: "100%" }} />}>
                        <App />
                    </LoadScript>
                </PopupProvider>
            </LayoutProvider>
        </AppProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
