/* eslint-disable jsx-a11y/anchor-is-valid */
import DotsHorizontal from "@untitled-ui/icons-react/build/esm/DotsHorizontal";
import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useQuery } from "react-query";
import styled from "styled-components";
import "../../../assets/css/components/CheckboxDark.css";
import * as Library from "../../../utils/Library";
import CardComponentContainer from "../cardContainers/CardComponentContainer";
import { Dropdown } from "antd";
import TableListActionDropdown from "../menus/TableListActionDropdown";
import ContainerLoader from "../loaders/ContainerLoader";

import Checkbox from "../checkbox/Checkbox";

import { formatDefaultDateTime } from "../../../utils/Formatter";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    //ListComponent CSS

    .ListComponent ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    .ListComponent {
        margin-top: -8px;
        //background: var(--backgrounds-lines-n-200);
        //border-radius: 4px;

        color: var(--text-icons-gray-100, #d1d1d1);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        //padding-right: 16px; //Fixes the horizontal scrollbar
    }

    .ListComponent div,
    .ListComponent .expandable-rows span {
        color: var(--text-icons-gray-100, #d1d1d1);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .rdt_Table {
        background: transparent;
    }

    .rdt_TableHead {
        height: 47px;
    }

    .rdt_TableHeadRow {
        background: var(--backgrounds-lines-n-400);
        border-color: var(--backgrounds-lines-n-100);
    }

    .rdt_TableHeadRow > .rdt_TableCol:first-child,
    .rdt_TableRow > .rdt_TableCell:first-child {
        width: 30px;
        flex: 0 0 30px;
        min-width: 30px;
        margin-left: 8px;
    }

    .table-structure.expandable-rows .rdt_TableHeadRow > div:first-child,
    .table-structure.expandable-rows .rdt_TableRow > div:first-child,
    .table-structure.expandable-rows .rdt_ExpanderRow .nested-row > .nested-col:first-child {
        width: 35px;
        flex: 0 0 35px;
        min-width: 35px;
        margin: 0px;
    }

    .table-structure.expandable-rows .rdt_TableRow button[data-testid="expander-button-undefined"],
    .table-structure.expandable-rows .rdt_TableRow button[data-testid="expander-button-undefined"]:hover {
        background-color: transparent;
    }

    /*.rdt_ExpanderRow .nested-row > .nested-col:first-child {
        width: 48px;
        flex: 0 0 48px;
        min-width: 48px;
        margin: 0px;
    }*/

    .rdt_TableBody {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .rdt_TableRow,
    .nested-row {
        background: transparent;
        border: 0;
    }

    .rdt_TableRow:hover,
    .nested-row:hover {
        border-radius: 4px;
        background: var(--backgrounds-lines-n-600) !important;
    }

    .rdt_TableRow:nth-child(even),
    .nested-row:nth-child(even) {
        border-radius: 4px;
        background: var(--backgrounds-lines-n-300);
    }

    .rdt_TableRow:nth-child(odd) {
        border-radius: 4px;
        background: transparent;
    }

    .rdt_Pagination {
        color: var(--text-icons-gray-100);
        background: transparent;
        border-color: var(--backgrounds-lines-n-100);
    }

    .rdt_TableCol:first-child,
    .rdt_TableCol:last-child,
    .rdt_TableCell:first-child,
    .rdt_TableCell:last-child,
    .nested-col:first-child,
    .nested-col:last-child {
        padding: 0px;
        justify-content: start;
    }

    .rdt_TableCol:first-child,
    .rdt_TableCell:first-child,
    .nested-col:first-child {
        margin-left: 0px;
        margin-right: 8px;
    }

    .rdt_TableCol:not(:first-child),
    .rdt_TableCell:not(:first-child),
    .nested-col:not(:first-child) {
        padding-left: 8px;
        padding-right: 0px;
    }

    .ListComponent.expandedRowsCentered .rdt_ExpanderRow > tr {
        justify-content: center;
        background: rgb(16, 20, 27);
    }

    .table-structure.sticky-actions .rdt_TableHeadRow .rdt_TableCol:last-child,
    .table-structure.sticky-actions .rdt_TableRow .rdt_TableCell:last-child,
    .table-structure.sticky-actions .rdt_ExpanderRow .nested-row .nested-col:last-child {
        position: sticky !important;
        right: 0;
        background-color: var(--backgrounds-lines-n-400);
    }

    .table-structure.sticky-actions .rdt_TableRow:nth-child(even) .rdt_TableCell:last-child {
        //.table-structure.sticky-actions .rdt_ExpanderRow .nested-row:nth-child(even) .nested-col:last-child {
        background: var(--backgrounds-lines-n-300) !important;
    }

    .table-structure.sticky-actions .rdt_TableRow:hover .rdt_TableCell:last-child,
    .table-structure.sticky-actions .rdt_ExpanderRow .nested-row:hover .nested-col:last-child {
        //border-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        background: var(--backgrounds-lines-n-600) !important;
    }

    .table-structure.sticky-status .rdt_TableHeadRow .rdt_TableCol:nth-last-child(2),
    .table-structure.sticky-status .rdt_TableRow .rdt_TableCell:nth-last-child(2),
    .table-structure.sticky-status .rdt_ExpanderRow .nested-row .nested-col:nth-last-child(2) {
        position: sticky !important;
        right: 36px;
        background-color: var(--backgrounds-lines-n-400);
        width: 87px;
    }

    .table-structure.sticky-status .rdt_TableRow:nth-child(even) .rdt_TableCell:nth-last-child(2) {
        //.table-structure.sticky-status .rdt_ExpanderRow .nested-row:nth-child(even) .nested-col:nth-last-child(2) {
        background: var(--backgrounds-lines-n-300) !important;
    }

    .table-structure.sticky-status .rdt_TableRow:hover .rdt_TableCell:nth-last-child(2),
    .table-structure.sticky-status .rdt_ExpanderRow .nested-row:hover .nested-col:nth-last-child(2) {
        background: var(--backgrounds-lines-n-600) !important;
    }

    .table-structure.sticky-status .rdt_TableHeadRow .rdt_TableCol:nth-last-child(2) > div.rdt_tableCol_Sortable,
    .table-structure.sticky-status .rdt_TableRow .rdt_TableCell:nth-last-child(2) > div.rdt_tableCol_Sortable {
        place-content: center;
    }

    // SPECIFIC EXPANDABLE ROWS STYLES
    .rdt_ExpanderRow {
        //padding-left: 40px;
    }

    .nested-row {
        border: 0px !important;
    }

    .nested-col {
        border: 0px !important;
    }

    .CustomExpandedRow .nested-col span {
        font-size: 11px;
    }

    .nested-row.expanded {
        background: var(--backgrounds-lines-n-500);
    }

    .nested-col.expanded {
        background: var(--backgrounds-lines-n-500) !important;
    }
`;

const NestedCols = styled.tr`
    display: flex;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px !important;
    font-weight: 400;
    font-family: Roboto;
    border-bottom: 1px solid #333f50 !important;
    min-height: 48px;

    .nested-col {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;

        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        line-height: normal;
        padding-left: 16px;
        padding-right: 16px;
        word-break: break-word;
        max-width: 100%;
        min-width: 100px;
    }

    .nested-col > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
    }

    /*.nested-col.status-sticky {
        position: sticky !important;
        background-color: var(--backgrounds-lines-n-600);
        padding: 0 !important;
        right: 222px;
        z-index: 99990;
        justify-content: center;
    }

    .nested-col.action-menu {
        position: sticky;
        right: 0;
        padding: 0 !important;
        justify-content: center;
        background-color: var(--backgrounds-lines-n-600);
    }*/
`;
/* #endregion */

/**
 * A reusable list component that fetches data from an API and displays it in a table format.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} [props.title] - The title of the list.
 * @param {string} [props.title2] - The secondary title of the list.
 * @param {Array} props.columns - The columns configuration for the table.
 * @param {boolean} [props.selectableRows=true] - Determines whether the rows in the table are selectable.
 * @param {string} [props.className] - Additional CSS class name for the component.
 * @param {string} [props.tableClassName] - Additional CSS class name for the table.
 * @param {Object} [props.actions] - The configuration for the actions column in the table.
 * @param {boolean} [props.actions.includeActions] - Determines whether to include actions column in the table.
 * @param {boolean} [props.actions.editIsVisible=true] - Determines whether the edit action is visible.
 * @param {Function} [props.actions.onClickEdit] - The callback function for the edit action.
 * @param {boolean} [props.actions.editIsDisabled] - Determines whether the edit action is disabled.
 * @param {boolean} [props.actions.suspendIsVisible=true] - Determines whether the suspend action is visible.
 * @param {Function} [props.actions.onClickSuspend] - The callback function for the suspend action.
 * @param {boolean} [props.actions.suspendIsDisabled] - Determines whether the suspend action is disabled.
 * @param {boolean} [props.actions.removeIsVisible=true] - Determines whether the remove action is visible.
 * @param {Function} [props.actions.onClickRemove] - The callback function for the remove action.
 * @param {boolean} [props.actions.removeIsDelete] - Determines whether the remove action is a delete action.
 * @param {boolean} [props.actions.removeIsDisabled] - Determines whether the remove action is disabled.
 * @param {Array} [props.actions.extraActions] - Additional actions to be displayed in the actions dropdown.
 * @param {boolean} [props.actions.restoreIsVisible=false] - Determines whether the restore action is visible.
 * @param {Function} [props.actions.onClickRestore] - The callback function for the restore action.
 * @param {Object} [props.includeStatus] - The configuration for the status column in the table.
 * @param {string} [props.includeStatus.statusFromPath] - The path to the status value in the data object.
 * @param {string} [props.includeStatus.defaultStatusValue] - The default status value to display if the status value is not found.
 * @param {boolean} [props.includeStatus.stickyStatus] - Determines whether the status column should be sticky.
 * @param {Array} [props.includeStatus.statusColors] - The configuration for the status colors.
 * @param {string[]} props.includeStatus.statusColors[].forValues - The values for which the corresponding color should be applied.
 * @param {string} props.includeStatus.statusColors[].colorClass - The CSS class for the color.
 * @param {number} [props.pageLimit=20] - The number of rows to display per page.
 * @param {Object} props.api - The configuration for the API request.
 * @param {string} props.api.endpoint - The API endpoint to fetch the data from.
 * @param {Object} props.api.formData - The form data to be sent with the API request.
 * @param {boolean} [props.api.useCommonPostRequest] - Determines whether to use the common post request function.
 * @param {Object} [props.api.commonContext] - The common context for the common post request function.
 * @param {Function} [props.api.onSuccess] - The callback function to be called after the API request is successful.
 * @param {boolean} [props.mappedTempData] - When we need to change the data without doing a new API request. When the request is done, this temp data will be replaced by the real data.
 * @param {Array} [props.queryKey] - The query key for the useQuery hook. NOTE: Now needs to be an array, but still can handle a single string.
 * @param {boolean} [props.expandableRows=false] - Determines whether the rows in the table are expandable.
 * @param {Array} [props.expandedRowName="expandItems"] - The name of the field containing the expanded rows in the data object.
 * @param {Array} [props.expandedRowsColumns] - The columns configuration for the expanded rows in the table.
 * @param {boolean} [props.persistTableHead] - Determines whether the table head should be visible when there are no rows.
 * @param {string} [props.defaultSortFieldId] - The default field ID to sort by.
 * @param {boolean} [props.defaultSortAsc] - Determines whether the default sort order is ascending.
 * @param {boolean} [props.persistTableHead] - Determines whether the table head should be persisted when no data is available.
 * @param {ReactNode} [props.middleCardTopComponent] - The middle component to show in the card header.
 * @param {ReactNode} [props.rightCardTopComponent] - The right component to show in the card header.
 * @param {Function} [props.handleSelectedRowsChange] - The callback function for the selected rows change event.
 * @param {boolean} [props.showOnlyLast10] - Determines whether to show only the last 10 rows.
 * @param {boolean} [props.sortByCreatedAt] - Determines whether to sort the rows by the createdAt field.
 * @param {boolean} [props.isLoading=false] - Determines whether the list is loading.
 * @param {function} [props.onListLoading] - The callback function to be called when the list is loading.
 * @param {boolean} [props.requestEnabled=true] - Determines whether the API request is enabled. Useful for temporary disabling the API request until this is set to true.
 * @param {function} [props.onChangeRowsPerPage] - The callback function for the rows per page change event.
 *
 * @returns {JSX.Element} The rendered ListComponent.
 */
export default function ListComponent(props) {
    /* #region STATES */
    const { handleSelectedRowsChange = undefined, setData = undefined, onListLoading, onChangeRowsPerPage } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(props?.pageLimit ?? 20);
    const [totalRows, setTotalRows] = useState(0);
    const [listData, setListData] = useState([]);
    const [currentApiFormData, setCurrentApiFormData] = useState({ isFirstTime: true });
    const requestEnabled = props?.requestEnabled ?? true;

    const fetchApiData = async (currentPage, rowsPerPage) => {
        if (!requestEnabled) return undefined;

        const paginationData = {
            page: currentPage,
            limit: rowsPerPage
        };

        const apiEndpoint = props?.api?.endpoint;
        const apiFormData = { ...paginationData, ...props?.api?.formData };
        const useCommonPostRequest = props?.api?.useCommonPostRequest;
        const apiCommonContext = props?.api?.commonContext;

        let tmp = undefined;
        if (!localStorage.getItem("token")) {
            console.error("Request cancelled, user not logged in.");
            return undefined;
        }
        if (useCommonPostRequest) tmp = await Library.makeCommonPostRequest(apiCommonContext, apiEndpoint, apiFormData);
        else tmp = await Library.makePostRequest(apiEndpoint, apiFormData);

        const res = tmp?.data;
        if (res?.status !== 200) {
            if (![undefined, "ok", "success", ""].includes(res?.message?.toLowerCase())) return undefined;
        }

        let data = res?.data;
        if (props?.api?.onSuccess) data = await props?.api?.onSuccess(data);

        setTotalRows(props?.showOnlyLast10 ? 10 : data.totalCount);
        //setCurrentPage(data.currentPage);

        // Set the current date and time
        if (props?.setLastUpdatedDateTime) {
            const formattedDateTime = formatDefaultDateTime(new Date());
            props?.setLastUpdatedDateTime(formattedDateTime);
        }

        //console.log("returned", data);
        return data?.result ?? data?.data ?? data;
    };

    const computedQueryKeyArray = Array.isArray(props?.queryKey) ? props?.queryKey : [props?.queryKey];

    const { data, /*status, error, */ isFetching, refetch, isPreviousData } = useQuery({
        //queryKey: [props?.queryKey, rowsPerPage, currentPage], //We need to pass the rowsPerPage and currentPage as queryKey to invalidate the query
        queryKey: [...computedQueryKeyArray, rowsPerPage, currentPage], //We need to pass the rowsPerPage and currentPage as queryKey to invalidate the query
        keepPreviousData: true, //Show cached data while fetching new data
        queryFn: async () => fetchApiData(currentPage, rowsPerPage),
        enabled: requestEnabled
    });

    const isFetchingNewData = isFetching && isPreviousData;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!currentPage || !rowsPerPage) return;

        //Checking if the API formData has changed to avoid unnecessary refetching
        if (JSON.stringify(currentApiFormData) === JSON.stringify(props?.api?.formData)) return;
        setCurrentApiFormData(props?.api?.formData);

        refetch();
    }, [currentPage, rowsPerPage, props?.api?.formData]);

    useEffect(() => {
        if (!data) return;
        const sortedData = props?.sortByCreatedAt
            ? data.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
            : data;
        setListData(sortedData);
        if (setData) {
            setData(sortedData);
        }
    }, [data]);

    useEffect(() => {
        if (!props?.mappedTempData) return;
        setListData(props?.mappedTempData);
    }, [props?.mappedTempData]);

    useEffect(() => {
        if (!onListLoading) return;

        onListLoading(isFetching);
    }, [isFetching]);
    /* #endregion */

    /* #region METHODS */

    const getColumns = (columns, includeStatusFromValue, statusColors, includeActions) => {
        const includeStatusWithArrayFields = includeStatusFromValue?.split(".");
        if (includeStatusWithArrayFields && statusColors)
            columns = [
                ...columns,
                {
                    name: "Status",
                    selector: row => getStatusColumn(row, includeStatusWithArrayFields, statusColors),
                    minWidth: props?.includeStatus?.width || "87px",
                    maxWidth: props?.includeStatus?.width || "87px"
                }
            ];

        if (includeActions)
            columns = [
                ...columns,
                {
                    name: "",
                    cell: row => (
                        <Dropdown
                            overlay={TableListActionDropdown({
                                isExpandedRow: false,
                                tableRowData: row,
                                edit: {
                                    visible: props?.actions?.editIsVisible === false ? false : true,
                                    onClick: props?.actions?.onClickEdit
                                        ? () => props?.actions?.onClickEdit(row)
                                        : undefined,
                                    isDisabled: props?.actions?.editIsDisabled
                                        ? () => props?.actions?.editIsDisabled(row, false)
                                        : undefined
                                },
                                suspend: {
                                    visible: props?.actions?.suspendIsVisible === false ? false : true,
                                    onClick: props?.actions?.onClickSuspend
                                        ? () => props?.actions?.onClickSuspend(row)
                                        : undefined,
                                    isReenable: getStatusValue(row)?.toLowerCase() === "suspended" ? true : false,
                                    isDisabled: props?.actions?.suspendIsDisabled
                                        ? () => props?.actions?.suspendIsDisabled(row, false)
                                        : undefined
                                },
                                restore: {
                                    visible: props?.actions?.restoreIsVisible === true ? true : false,
                                    onClick: props?.actions?.onClickRestore
                                        ? () => props?.actions?.onClickRestore(row)
                                        : undefined
                                },
                                remove: {
                                    visible: props?.actions?.removeIsVisible === false ? false : true,
                                    onClick: props?.actions?.onClickRemove
                                        ? () => props?.actions?.onClickRemove(row)
                                        : undefined,
                                    removeIsDelete: props?.actions?.removeIsDelete,
                                    isDisabled: props?.actions?.removeIsDisabled
                                        ? () => props?.actions?.removeIsDisabled(row, false)
                                        : undefined
                                },
                                extraActions: props?.actions?.extraActions
                            })}
                            trigger={["click"]}>
                            <a className="ant-dropdown-link" style={{ color: "white", cursor: "pointer" }}>
                                <DotsHorizontal height="16" color="#D1D1D1" />
                            </a>
                        </Dropdown>
                    ),
                    width: "36px"
                }
            ];

        return columns;
    };

    const getStatusValue = row => {
        const statusFromPath = props?.includeStatus?.statusFromPath;
        const status = getArrayFieldValue(row, statusFromPath?.split(".") || "Unknown");
        //console.log("status", status);
        return status;
    };

    const getStatusColumn = (row, includeStatusWithArrayFields, statusColors) => {
        let statusValue =
            getArrayFieldValue(row, includeStatusWithArrayFields) ||
            props?.includeStatus?.defaultStatusValue ||
            "Unknown";

        //TODO: This is a temporary fix for the "Not Connected" status value
        if (statusValue === "Not Connected") statusValue = "Not connected";

        let colorValue = "text-gray-200";
        let found = false;

        for (const color of statusColors) {
            for (const value of color.forValues) {
                if (value.toLowerCase() === statusValue.toLowerCase()) {
                    found = true;
                    colorValue = color.colorClass;
                    break;
                }
            }
            if (found) break;
        }

        return (
            <div>
                <div className="flex align-items-center">
                    <div className={`status-dot bg-${colorValue}`}></div>
                    <span className={colorValue}>{statusValue}</span>
                </div>
            </div>
        );
    };

    const getArrayFieldValue = (row, array) => {
        if (!Array.isArray(array)) return "Unknown";

        let res = row;
        for (let i = 0; i < array.length; i++) {
            res = res?.[array[i]];
        }
        return res;
    };

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    /* #endregion */

    /* #region COMPONENTS */

    const CheckboxRef = React.forwardRef(({ onClick, checked, disabled, ...rest }, ref) => {
        return (
            <Checkbox
                isChecked={checked}
                onCheckboxClick={onClick}
                style={{ cursor: disabled ? "pointer" : "not-allowed" }}
                {...rest}
                ref={ref}
            />
        );
    });

    createTheme("dark", {
        text: {
            primary: "#D1D1D1",
            secondary: "#D1D1D1"
        },
        background: {
            default: "var(--backgrounds-lines-n-400)"
        },
        context: {
            background: "var(--backgrounds-lines-n-400)",
            text: "#D1D1D1"
        },
        divider: {
            default: "var(--backgrounds-lines-n-400)"
        },
        action: {
            button: "var(--backgrounds-lines-n-400)",
            hover: "var(--backgrounds-lines-n-500)",
            disabled: "var(--backgrounds-lines-n-400)"
        }
    });

    const ExpandedComponent = ({ data }) => {
        const expandedRowName = props?.expandedRowName || "expandItems";

        return (
            <table
                className="CustomExpandedRow"
                style={{
                    width: "100%",
                    maxWidth: "100%",
                    marginBotton: "20px",
                    background: "var(--backgrounds-lines-n-500)",
                    //radius 4px
                    borderRadius: "4px"
                }}>
                <tbody>
                    {data?.[expandedRowName]?.map((row, index) => {
                        return (
                            <NestedCols className="nested-row expanded" key={index}>
                                <td className="nested-col" key={"nested-col-0"} style={{}}></td>

                                {props?.expandedRowsColumns?.map((col, index) => {
                                    return (
                                        <td
                                            className="nested-col"
                                            key={index}
                                            style={{ minWidth: col?.minWidth, maxWidth: col?.maxWidth }}>
                                            <span title={col.selector(row)}>{col.selector(row)}</span>
                                        </td>
                                    );
                                })}

                                {props?.includeStatus && (
                                    <td
                                        className="nested-col expanded"
                                        style={{
                                            minWidth: props?.includeStatus?.width || "87px",
                                            maxWidth: props?.includeStatus?.width || "87px",
                                            background: "var(--backgrounds-lines-n-500)"
                                        }}>
                                        {getStatusColumn(
                                            row,
                                            props?.includeStatus?.statusFromPath.split("."),
                                            props?.includeStatus?.statusColors
                                        )}
                                    </td>
                                )}

                                {props?.actions?.includeActions && (
                                    <td
                                        className="nested-col expanded"
                                        style={{
                                            minWidth: "36px",
                                            maxWidth: "36px",
                                            background: "var(--backgrounds-lines-n-500)"
                                        }}>
                                        <Dropdown
                                            overlay={TableListActionDropdown({
                                                isExpandedRow: true,
                                                tableRowData: row,
                                                edit: {
                                                    visible: props?.actions?.editIsVisible === false ? false : true,
                                                    onClick: props?.actions?.onClickEdit
                                                        ? () => props?.actions?.onClickEdit(row)
                                                        : undefined,
                                                    isDisabled: props?.actions?.editIsDisabled
                                                        ? () => props?.actions?.editIsDisabled(row, true)
                                                        : undefined
                                                },
                                                suspend: {
                                                    visible: props?.actions?.suspendIsVisible === false ? false : true,
                                                    onClick: props?.actions?.onClickSuspend
                                                        ? () => props?.actions?.onClickSuspend(row)
                                                        : undefined,
                                                    isReenable:
                                                        getStatusValue(row)?.toLowerCase() === "suspended"
                                                            ? true
                                                            : false,
                                                    isDisabled: props?.actions?.suspendIsDisabled
                                                        ? () => props?.actions?.suspendIsDisabled(row, true)
                                                        : undefined
                                                },
                                                remove: {
                                                    visible: props?.actions?.removeIsVisible === false ? false : true,
                                                    onClick: props?.actions?.onClickRemove
                                                        ? () => props?.actions?.onClickRemove(row)
                                                        : undefined,
                                                    removeIsDelete: props?.actions?.removeIsDelete,
                                                    isDisabled: props?.actions?.removeIsDisabled
                                                        ? () => props?.actions?.removeIsDisabled(row, true)
                                                        : undefined
                                                },
                                                extraActions: props?.actions?.extraActions
                                            })}
                                            trigger={["click"]}>
                                            <a
                                                className="ant-dropdown-link"
                                                style={{ color: "white", cursor: "pointer" }}>
                                                <DotsHorizontal height="16" color="#D1D1D1" />
                                            </a>
                                        </Dropdown>
                                    </td>
                                )}
                            </NestedCols>
                        );
                    })}
                </tbody>
            </table>
        );
    };
    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <>
                <ContainerLoader
                    isLoading={
                        typeof props?.showLoader !== "undefined"
                            ? props?.showLoader
                            : isFetchingNewData || (isFetching && totalRows === 0)
                    } //TODO: REVIEW IF THIS IS WORKING CORRECTLY
                    isLoadingOverlay={true}
                    addBlurFilters={false}>
                    <CardComponentContainer
                        title={`${props?.title || "--"}`}
                        title2={props?.title2}
                        loading={props?.showLoader === false ? false : isFetching || props?.isLoading}
                        middleComponent={props?.middleCardTopComponent}
                        rightComponent={props?.rightCardTopComponent}>
                        <div
                            className={`table-structure ${props?.actions?.includeActions && "sticky-actions"} ${
                                props?.includeStatus?.stickyStatus && "sticky-status"
                            } ${props?.expandableRows && "expandable-rows"}`}>
                            <DataTable
                                defaultSortFieldId={props?.defaultSortFieldId}
                                defaultSortAsc={props?.defaultSortAsc}
                                columns={getColumns(
                                    props?.columns,
                                    props?.includeStatus?.statusFromPath,
                                    props?.includeStatus?.statusColors,
                                    props?.actions?.includeActions
                                )}
                                persistTableHead={props?.persistTableHead}
                                className={`ListComponent ${props?.tableClassName || ""}`}
                                theme="dark"
                                //columns={props?.columns}
                                data={listData}
                                fixedHeader
                                //fixedHeaderScrollHeight="496px"
                                fixedHeaderScrollHeight="calc(100vh - 450px)"
                                selectableRows={props?.selectableRows ?? true}
                                selectableRowsComponent={CheckboxRef}
                                responsive={true}
                                dataLoading={isFetchingNewData}
                                paginationRowsPerPageOptions={
                                    props?.showOnlyLast10 ? [10] : [props?.pageLimit || 20, 50, 100]
                                }
                                pagination
                                paginationDefaultPage={currentPage}
                                paginationServer={true}
                                onChangePage={handlePageChange}
                                paginationTotalRows={totalRows}
                                paginationPerPage={rowsPerPage}
                                onChangeRowsPerPage={currentRowsPerPage => {
                                    setRowsPerPage(currentRowsPerPage);
                                    setCurrentPage(1);
                                    if (onChangeRowsPerPage) onChangeRowsPerPage(currentRowsPerPage);
                                }}
                                expandableRows={props?.expandableRows ?? false}
                                expandableRowsComponent={props?.customExpandedComponent || ExpandedComponent}
                                selectableRowSelected={row => props?.selectedProducts?.includes(row?.productID)}
                                onSelectedRowsChange={({ selectedRows }) => {
                                    if (handleSelectedRowsChange) {
                                        handleSelectedRowsChange(selectedRows);
                                    }
                                }}
                            />
                        </div>
                    </CardComponentContainer>
                </ContainerLoader>
            </>
        </StyledPage>
    );
}
