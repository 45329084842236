import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import WeekCustomCard from "./WeekCustomCard";
import SimpleGridContainer from "../../../common/forms/SimpleGridContainer";
import CardComponentContainer from "../../../common/cardContainers/CardComponentContainer";

const StyledStoreHours = styled.div`
    &.frame-276,
    &.frame-276 * {
        box-sizing: border-box;
    }
    &.frame-276 {
        display: flex;
        flex-direction: column;
        padding: 16px 0px 0px 0px;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-285 {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-185 {
        color: var(--text-icons-orange-500, #f90);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        height: 14px;
    }
`;

const StoreHours = forwardRef((props, ref) => {
    const { title, onFormChanged, storeHours, afterTitleComponent } = props;

    /* #region VARS */
    const [storeFormData, setStoreFormData] = useState({
        storeHoursData: {
            monday: { hourStart: "", hourEnd: "", closedAllDay: true },
            tuesday: { hourStart: "", hourEnd: "", closedAllDay: true },
            wednesday: { hourStart: "", hourEnd: "", closedAllDay: true },
            thursday: { hourStart: "", hourEnd: "", closedAllDay: true },
            friday: { hourStart: "", hourEnd: "", closedAllDay: true },
            saturday: { hourStart: "", hourEnd: "", closedAllDay: true },
            sunday: { hourStart: "", hourEnd: "", closedAllDay: true }
        }
    });
    const { storeHoursData } = storeFormData;

    const [sameTimeAllWeekDays, setSameTimeAllWeekDays] = useState(false);
    const [sameAsWeekDays, setSameAsWeekDays] = useState(false);
    /* #endregion */

    /* #region METHODS */
    const onClickSameTimeAllWeekDays = () => {
        setSameTimeAllWeekDays(!sameTimeAllWeekDays);

        if (!sameTimeAllWeekDays) {
            setStoreFormData(prevData => ({
                ...prevData,
                storeHoursData: {
                    ...prevData.storeHoursData,
                    tuesday: prevData.storeHoursData.monday,
                    wednesday: prevData.storeHoursData.monday,
                    thursday: prevData.storeHoursData.monday,
                    friday: prevData.storeHoursData.monday
                }
            }));
        }

        if (onFormChanged) onFormChanged(false);
    };

    const onClickWeekendSameAsWeekDays = () => {
        setSameAsWeekDays(!sameAsWeekDays);

        if (!sameAsWeekDays) {
            setStoreFormData(prevData => ({
                ...prevData,
                storeHoursData: {
                    ...prevData.storeHoursData,
                    saturday: prevData.storeHoursData.monday,
                    sunday: prevData.storeHoursData.monday
                }
            }));
        }

        if (onFormChanged) onFormChanged(false);
    };

    const handleCalendarDataChange = (day, data) => {
        setStoreFormData(prevData => ({
            ...prevData,
            storeHoursData: {
                ...prevData.storeHoursData,
                [day]: data
            }
        }));

        if (onFormChanged) onFormChanged(false);
    };

    const checkHasOneDayOpen = () => {
        const data = storeFormData?.storeHoursData;
        const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        return weekdays.reduce((acc, item) => {
            const closedAllDay = data?.[item]?.closedAllDay;
            const hourStart = data?.[item]?.hourStart;
            const hourEnd = data?.[item]?.hourEnd;

            return acc || (!closedAllDay && !!hourStart && !!hourEnd);
        }, false);
    };

    const getAllFieldValues = () => {
        return storeFormData?.storeHoursData;
    };

    const allFieldsAreValid = () => {
        const data = storeFormData?.storeHoursData;

        const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        const isValid = weekdays.reduce((acc, item) => {
            const hourStart = data?.[item]?.hourStart;
            const hourEnd = data?.[item]?.hourEnd;
            const closedAllDay = data?.[item]?.closedAllDay;

            //check if hourStart is previous to hourEnd
            if (!!hourStart && !!hourEnd) {
                let validHours = false;
                const start = new Date(`01/01/2000 ${hourStart}`);
                const end = new Date(`01/01/2000 ${hourEnd}`);
                validHours = start < end;

                if (!validHours) return false;
            }

            return acc && (closedAllDay || (!!hourStart && !!hourEnd));
        }, true);

        const isOpen = checkHasOneDayOpen();

        return isValid && isOpen;
    };

    const getInvalidText = () => {
        const data = storeFormData?.storeHoursData;

        const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        //check if at least one day is open and has valid hours
        const isOpen = checkHasOneDayOpen();
        if (!isOpen) return "Select at least one";

        //check if all days have valid hours
        let invalidDay = "";
        const isValid = weekdays.reduce((acc, item) => {
            const hourStart = data?.[item]?.hourStart;
            const hourEnd = data?.[item]?.hourEnd;
            const closedAllDay = data?.[item]?.closedAllDay;

            //check if hourStart is previous to hourEnd

            if (!!hourStart && !!hourEnd) {
                let validHours = false;
                const start = new Date(`01/01/2000 ${hourStart}`);
                const end = new Date(`01/01/2000 ${hourEnd}`);
                validHours = start < end;

                if (!validHours) {
                    if (!invalidDay) invalidDay = item;
                    return false;
                }
            }

            return acc && (closedAllDay || (!!hourStart && !!hourEnd));
        }, true);

        if (!isValid && invalidDay) return `Invalid hours for ${invalidDay}`;
        else if (!isValid) return "Select valid hours for all days";
        return "";
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!storeHours || !storeHours?.monday) return;

        setStoreFormData({ storeHoursData: storeHours });
    }, [storeHours]);

    useEffect(() => {
        if (onFormChanged) onFormChanged(false);
    }, [storeFormData, sameTimeAllWeekDays, sameAsWeekDays]);
    /* #endregion */

    useImperativeHandle(ref, () => ({
        getAllFieldValues,
        allFieldsAreValid
    }));

    const invalidText = getInvalidText();

    return (
        <CardComponentContainer title={title} afterTitleComponent={afterTitleComponent}>
            <StyledStoreHours className="frame-276 ov-auto2-x">
                <div className="frame-185">{invalidText}</div>
                <SimpleGridContainer className="frame-285 w-100" type="minWidth" cardMinWidth="300px" gap="24px">
                    <WeekCustomCard
                        title="Monday"
                        calendarData={storeHoursData.monday}
                        setCalendarData={data => handleCalendarDataChange("monday", data)}
                        onSpecialClicked={onClickSameTimeAllWeekDays}
                        specialIsEnabled={sameTimeAllWeekDays}
                    />
                    <WeekCustomCard
                        title="Tuesday"
                        calendarData={storeHoursData.tuesday}
                        setCalendarData={data => handleCalendarDataChange("tuesday", data)}
                    />
                    <WeekCustomCard
                        title="Wednesday"
                        calendarData={storeHoursData.wednesday}
                        setCalendarData={data => handleCalendarDataChange("wednesday", data)}
                    />
                    <WeekCustomCard
                        title="Thursday"
                        calendarData={storeHoursData.thursday}
                        setCalendarData={data => handleCalendarDataChange("thursday", data)}
                    />
                    <WeekCustomCard
                        title="Friday"
                        calendarData={storeHoursData.friday}
                        setCalendarData={data => handleCalendarDataChange("friday", data)}
                    />
                    <WeekCustomCard
                        title="Saturday"
                        calendarData={storeHoursData.saturday}
                        setCalendarData={data => handleCalendarDataChange("saturday", data)}
                        onSpecialClicked={onClickWeekendSameAsWeekDays}
                        specialIsEnabled={sameAsWeekDays}
                    />
                    <WeekCustomCard
                        title="Sunday"
                        calendarData={storeHoursData.sunday}
                        setCalendarData={data => handleCalendarDataChange("sunday", data)}
                    />
                </SimpleGridContainer>
            </StyledStoreHours>
        </CardComponentContainer>
    );
});

export default StoreHours;
