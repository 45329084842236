export default function FieldsRightLegends() {
    const Frame186 = {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "4px",
        textTransform: "none"
    };

    const Rectangle7 = {
        width: "6px",
        height: "6px",
        borderRadius: "600px"
    };

    const legendTitle = {
        color: "#7c868b",
        fontSize: "12px",
        fontFamily: "Roboto",
        fontWeight: 400,
        wordWrap: "break-word"
    };
    /* #endregion */

    return (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px" }}>
            <div className="Frame186" style={Frame186}>
                <div className="Rectangle7" style={{ ...Rectangle7, background: "#00AEEF" }} />
                <div className="legendTitle" style={legendTitle}>
                    recommended
                </div>
            </div>
            <div className="Frame186" style={Frame186}>
                <div className="Rectangle7" style={{ ...Rectangle7, background: "#FF9900" }} />
                <div className="legendTitle" style={legendTitle}>
                    mandatory
                </div>
            </div>
            <div className="Frame186" style={Frame186}>
                <div className="Rectangle7" style={{ ...Rectangle7, background: "#4F5B6D" }} />
                <div className="legendTitle" style={legendTitle}>
                    locked
                </div>
            </div>
        </div>
    );
}
