import styled from "styled-components";

import { useEffect, useState } from "react";

//Components
import LabelsList from "./components/LabelsList";
import Tabs from "./components/Tabs";
import Forms from "./components/Forms";
import FormField from "../../../common/forms/FormField";
import FullScreenOverlay from "../../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../../common/dialogs/DialogBoxComponent";
import SimpleGridContainer from "../../../common/forms/SimpleGridContainer";
import Checkbox from "../../../common/checkbox/Checkbox";
import DragDropImages from "../../../common/uploadImages/DragDropImages";
import BtnLargeCustomColors from "../../../common/buttons/BtnLargeCustomColors";
import IdCodes from "./components/IdCodes";
import * as Library from "../../../../utils/Library";

//ASSETs
import check from "../../../../assets/icons/check.png";
import alertTriangle from "../../../../assets/icons/alert-triangle.svg";
import fileQuestion from "../../../../assets/icons/file-question.svg";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    .fc-main-container {
        display: flex;
        gap: 16px;
        width: 100%;
        height: calc(100vh - 394px);
        max-height: calc(100vh - 394px);
        margin-top: 24px;
        overflow: hidden;
    }
    .fc-right-container {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        overflow-y: auto;
    }
    .fc-left-container {
        display: flex;
        height: 100%;
        max-width: 250px;
    }

    .fc-navtab-container {
        margin-top: 32px;
        margin-bottom: 24px;
    }
    .fc-form-container {
        display: flex;
        gap: 10px;
        width: 100%;
    }

    //Top
    .fc-top-container {
        justify-content: space-between;
        align-items: center;
        display: flex;
        width: 100%;
        gap: 16px;
    }

    .checkbox-label {
        display: flex;
        gap: 8px;
        /* margin: auto 16px auto auto; */
        align-items: center;
        position: relative;
        justify-content: flex-end;
    }
    .Icon {
        width: 10px;
        height: 10px;
        font-size: 9px;
        font-weight: 500;
        color: var(--text-icons-gray-200);
        align-items: center;
        justify-content: center;
    }

    .middle-header-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin: 10px auto;
    }

    .btn-icon {
        width: 12px;
        height: 12px;
        align-items: center;
        justify-content: center;
    }

    .painel-btn-info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-size: 10px;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--text-icons-gray-100);
    }

    //================= Image =================//

    .image-state-container {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        display: flex;
        position: absolute;
        width: 40.31px;
        height: 40.31px;
        border-radius: 50%;

        align-items: center;
        justify-content: center;

        flex-shrink: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .image-state {
        width: 22px;
        height: 22px;
    }

    .product-image-container {
        position: relative;
    }

    .product-image {
        width: 100%;
        height: 100%;
    }
`;
/* #endregion */

export default function FormComponent({
    labels,
    isErrorInvalidFormat,
    productList,
    tabsItems,
    tabIndex,
    formData,
    setFormData,
    setTabIndex,
    checkboxState,
    handleSetCheckboxState,
    productImages,
    setProductImages,
    setCustomFormData,
    setProductImagesBase64,
    productImagesBase64,
    selectedBrand,
    customFieldNames,
    setCustomFieldNames,
    setCustomFieldSections,
    customFormData,
    importedCustomFields,
    productImagesStatus,
    setProductImagesStatus,
    setIsEANValid,
    detectedEmptyLabel
}) {
    /* #region STATES */

    const [isOpenEditDialog, setOpenEditDialog] = useState(false);
    const [fieldToEdit, setFieldToEdit] = useState("");
    const [fieldValue, setFieldValue] = useState("");

    const [industryDropdown, setIndustryDropdown] = useState([]);
    const [categoryDropdown, setCategoryDropdown] = useState([]);
    const [subCategoryDropdown, setSubCategoryDropdown] = useState([]);

    const [isDragging, setIsDragging] = useState(false);
    const [isDialogEanError, setIsDialogEanError] = useState("");

    const [eanCodeValue, setEanCodeValue] = useState("");

    const [imagesState, setImagesState] = useState(null);

    //----- Check Box -----
    const [isDialogCheckboxConfirm, setIsDialogCheckboxConfirm] = useState(false);
    //const [checkBoxAvailable, setCheckBoxAvailable] = useState(formData.eanCode && true);
    // ----- Custom Fields state -----
    const [additionalFields, setAdditionalFields] = useState({
        itemColors: [],
        //-----
        itemMaterials: [],
        itemCare: [],
        //-----
        transparency: [],
        sustainability: [],
        //-----
        otherFields: [],
        //-----
        imagesLinks: []
    });

    /* #endregion */

    /* #region METHODS */

    //NOTE: check if the value is on detectedEmptyLabel
    const handleCheckEmptyLabel = value => {
        if (detectedEmptyLabel.includes(value)) {
            return true;
        }
        return false;
    };

    //=========| Handle Edit Field Name |==========//
    const handleOnEditField = value => {
        setFieldToEdit(value);
        setOpenEditDialog(true);
    };

    const handleTextChange = () => {
        setCustomFieldNames(prevState => ({
            ...prevState,
            [fieldToEdit]: fieldValue
        }));

        setFieldValue("");
        setOpenEditDialog(false);
    };
    //===========================================//
    //function to check if string is an image URL
    const isImageURL = url => {
        try {
            new URL(url);
            return true;
        } catch (_) {
            return false;
        }
    };

    const addHttpsToURL = url => {
        if (!url) return "";
        if (url.startsWith("http://") || url.startsWith("https://")) return url;
        return `https://${url}`;
    };

    //TODO: Filter to show only 1 image per "article number"
    const getImages = () => {
        const images = [];
        const imageKeys = Array.from({ length: 12 }, (_, i) => `image${i + 1}`);
        const seenArticleNumbers = new Set();
        const seenColors = {};

        productList?.forEach(item => {
            const articleNumber = item[formData?.referenceCode];
            const mainColor = item[formData?.color1Name];

            if (!seenColors[articleNumber]) {
                seenColors[articleNumber] = new Set();
            }

            if (seenColors[articleNumber].has(mainColor)) {
                return; // Skip this item if we've already seen this color for the article number
            }

            seenColors[articleNumber].add(mainColor);

            imageKeys.forEach(key => {
                const imageUrl = addHttpsToURL(item[formData[key]]);
                let state = "found";

                if (!imageUrl) {
                    state = "notFound";
                } else if (!isImageURL(imageUrl)) {
                    state = "notFound";
                } else if (seenArticleNumbers.has(imageUrl)) {
                    state = "duplicated";
                } else {
                    seenArticleNumbers.add(imageUrl);
                }

                if (formData[key]) {
                    images.push({ name: "-", URL: imageUrl, state: state });
                }
            });
        });

        return images;
    };

    const handleConfirmCheckboxDialog = () => {
        setIsDialogCheckboxConfirm(false);
        handleSetCheckboxState(true);
    };

    const handleOnCheckClick = () => {
        if (!checkBoxEnabled) return;
        if (!checkboxState) {
            setIsDialogCheckboxConfirm(true);
        } else {
            handleSetCheckboxState(false);
        }
    };

    const handleInputChange = (field, value) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };
    const handleCustomInputChange = (field, value) => {
        setCustomFormData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleTabClick = index => {
        setTabIndex(index);
    };

    const handleSetEanCode = value => {
        //TODO: Validate the EAN Codes
        if (!value) {
            handleInputChange("barcode", "");
            return;
        }

        let error = "";

        productList?.forEach(item => {
            const itemValue = item[value];

            if (isNaN(itemValue)) {
                error = "ErrorWrong";
            } else if (itemValue === "") {
                error = "ErrorMissing";
            } else if (itemValue.toString().length < 13) {
                error = "ErrorFormat";
            }
        });

        if (error !== "") {
            setIsDialogEanError(error);
            setEanCodeValue(value);
            setIsEANValid(false);
            return;
        } else {
            handleInputChange("barcode", value);
            setIsEANValid(true);
        }
    };

    //----------- Handle Replace File Dialog ------------//

    const handleConfirmError = () => {
        handleInputChange("barcode", eanCodeValue);
        setIsDialogEanError("");
    };

    const handleCancelError = () => {
        setIsDialogEanError("");
    };

    /* #endregion */

    /* #region EFFECTS */

    useEffect(() => {
        if (!selectedBrand) return;
        const id = selectedBrand.value;
        Library.makePostRequest("getLabelBrand", { labelBrandID: id }).then(res => {
            if (res.data.status === 200) {
                const data = res.data.data;

                //TODO: All data needed is a string but will be an array in the future
                const industry = data.industry ? data.industry : [];
                const category = data.mainProductCategory ? data.mainProductCategory : [];
                //const subCategory = data.mainProductCategory ? data.mainProductCategory : [];

                setIndustryDropdown([{ name: industry, value: industry }]);
                setCategoryDropdown([{ name: category, value: category }]);
                // setSubCategoryDropdown([{ name: subCategory, value: subCategory }]);

                handleInputChange("headerIndustry", industry);
                handleInputChange("headerCategory", category);
            }
        });
    }, [selectedBrand]);

    useEffect(() => {
        const calculateImageStatus = images => {
            return images.reduce(
                (acc, obj) => {
                    if (obj.state === "duplicated") {
                        acc.duplicates++;
                    } else if (obj.state === "found") {
                        acc.found++;
                    } else {
                        acc.notFound++;
                    }
                    return acc;
                },
                { duplicates: 0, found: 0, notFound: 0 }
            );
        };

        if (checkboxState) {
            setProductImagesStatus(calculateImageStatus(productImages));
        } else {
            setProductImagesStatus(calculateImageStatus(productImagesBase64));
        }
    }, [checkboxState, productImages, productImagesBase64]);

    useEffect(() => {
        if (checkboxState) setProductImages(getImages());
    }, [formData, checkboxState]);

    useEffect(() => {
        if (importedCustomFields?.length === 0 || !importedCustomFields) return;

        const updatedFields = { ...additionalFields };

        importedCustomFields?.forEach(field => {
            const category = field.UIsection;
            const fieldName = field.UIfieldName;
            const fieldTitle = field.UIcustomFieldName;
            const fieldIndex = updatedFields[category]?.length + 1 || 1;

            updatedFields[category] = [
                ...(updatedFields[category] || []),
                <FormField
                    key={fieldIndex}
                    type="droppable"
                    isDragging={isDragging && !tabsItems[0]?.locked}
                    title={fieldTitle}
                    tooltip={`Enter the ${fieldTitle}`}
                    requiredType={tabsItems[0]?.locked ? "locked" : "simple"}
                    value={customFormData[fieldName]}
                    onTextChange={value => handleCustomInputChange(fieldName, value)}
                    onEdit={() => handleOnEditField(fieldName)}
                />
            ];
        });

        setAdditionalFields(updatedFields);
    }, [importedCustomFields]);

    /* #endregion */
    const checkBoxEnabled = !!(formData.barcode && formData.referenceCode);

    return (
        <StyledPage>
            <FullScreenOverlay show={isOpenEditDialog}>
                <DialogBoxComponent
                    heading="EDIT FIELD NAME."
                    cancelText="CANCEL"
                    confirmText="SAVE"
                    useAlternativeLayout={
                        <FormField
                            type="text"
                            style={{ width: "100%", marginTop: "16px", marginBottom: "16px" }}
                            requiredType={"simple"}
                            value={fieldValue}
                            onTextChange={value => setFieldValue(value)}
                            placeholder="New field name"
                        />
                    }
                    onClickYes={() => handleTextChange()}
                    onClickNo={() => setOpenEditDialog(false)}
                />
            </FullScreenOverlay>
            <FullScreenOverlay show={isDialogEanError}>
                <DialogBoxComponent
                    heading="ATTENTION..."
                    title={
                        isDialogEanError === "ErrorWrong"
                            ? "Wrong barcode field!"
                            : isDialogEanError === "ErrorMissing"
                            ? "EAN codes missing!"
                            : isDialogEanError === "ErrorFormat" && "Wrong barcodes format!"
                    }
                    onClickYes={handleConfirmError}
                    onClickNo={handleCancelError}
                    confirmText={isDialogEanError === "I’LL REPLACE" ? "" : "CONTINUE"}
                    cancelText={isDialogEanError === "CLOSE" ? "" : "CANCEL"}
                    message={
                        isDialogEanError === "ErrorWrong"
                            ? "Seams like the label you dragged to the Barcode field does not contain barcodes. Please drag the correct field."
                            : isDialogEanError === "ErrorMissing"
                            ? "One or more items in your file are missing EAN codes. Hit CANCEL to fix the file and import again, or CONTINUE to import this file anyways. In this case those items will not be imported."
                            : isDialogEanError === "ErrorFormat" &&
                              "One or more items in your file are not complying with the barcode industry standards. Hit CANCEL to fix the file and import again, or CONTINUE to import this file anyways. In this case those items will not be imported."
                    }
                    headingColor="var(--text-icons-orange-500)"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={isDialogCheckboxConfirm}>
                <DialogBoxComponent
                    heading="ATTENTION..."
                    title={"Images provided by URL"}
                    onClickYes={handleConfirmCheckboxDialog}
                    confirmText={"CONTINUE"}
                    showCancel={false}
                    buttonWidth={"150px"}
                    message={
                        "By checking this box, you confirm that the uploaded file contains columns with links to product images.Please ensure these links are publicly accessible and do not require any tokens or passwords for access."
                    }
                    headingColor="var(--text-icons-orange-500)"
                />
            </FullScreenOverlay>

            <div className="fc-main-container">
                <LabelsList
                    labels={labels}
                    setIsDragging={setIsDragging}
                    isErrorInvalidFormat={isErrorInvalidFormat}
                    formData={{ ...formData, ...customFormData }}
                />

                <div className="fc-right-container">
                    {tabIndex === 7 && (
                        <div className="middle-header-buttons">
                            <BtnLargeCustomColors
                                normalColor={"var(--backgrounds-lines-n-600)"}
                                hoverColor={"var(--backgrounds-lines-n-500)"}
                                text={
                                    <div className="painel-btn-info">
                                        <img className="btn-icon" alt="" src={fileQuestion} />
                                        {productImagesStatus.notFound} NOT FOUND
                                    </div>
                                }
                                onClick={() => setImagesState(imagesState === "notFound" ? null : "notFound")}
                            />
                            <BtnLargeCustomColors
                                normalColor={"var(--backgrounds-lines-n-600)"}
                                hoverColor={"var(--backgrounds-lines-n-500)"}
                                text={
                                    <div className="painel-btn-info">
                                        <img className="btn-icon" alt="" src={alertTriangle} />
                                        {productImagesStatus.duplicates} DUPLICATES
                                    </div>
                                }
                                onClick={() => setImagesState(imagesState === "duplicated" ? null : "duplicated")}
                            />
                            <BtnLargeCustomColors
                                normalColor={"var(--backgrounds-lines-n-600)"}
                                hoverColor={"var(--backgrounds-lines-n-500)"}
                                text={
                                    <div className="painel-btn-info">
                                        <img className="btn-icon" alt="" src={check} />
                                        {productImagesStatus.found} FOUND
                                    </div>
                                }
                                onClick={() => setImagesState(imagesState === "found" ? null : "found")}
                            />
                        </div>
                    )}

                    {tabIndex !== 7 && (
                        <div className="fc-top-container">
                            <FormField
                                type="dropdown"
                                style={{ width: "100%" }}
                                title="Industry"
                                tooltip="Industry"
                                requiredType={industryDropdown.lenght > 1 ? "mandatory" : "locked"}
                                dropdownData={industryDropdown}
                                value={formData?.headerIndustry}
                                onDropdownChange={data => handleInputChange("headerIndustry", data.value)}
                            />
                            <FormField
                                type="dropdown"
                                style={{ width: "100%" }}
                                title="Category"
                                tooltip="Category"
                                requiredType={industryDropdown.lenght > 1 ? "mandatory" : "locked"}
                                dropdownData={categoryDropdown}
                                value={formData?.headerCategory}
                                onDropdownChange={data => handleInputChange("headerCategory", data.value)}
                            />
                            <FormField
                                type="dropdown"
                                style={{ width: "100%" }}
                                title="Sub-category"
                                tooltip="Sub-category"
                                requiredType={industryDropdown.lenght > 1 ? "mandatory" : "locked"}
                                dropdownData={subCategoryDropdown}
                                value={formData?.headerSubCategory}
                                onDropdownChange={data => handleInputChange("headerSubCategory", data.value)}
                            />
                            <div
                                className="checkbox-label"
                                style={{ color: checkBoxEnabled && "#D1D1D1", width: "100%" }}
                                onClick={handleOnCheckClick}>
                                <Checkbox
                                    isChecked={checkboxState}
                                    onCheckboxClick={handleOnCheckClick}
                                    style={{ cursor: checkBoxEnabled ? "pointer" : "not-allowed" }}
                                />
                                <div style={{ cursor: checkBoxEnabled ? "pointer" : "not-allowed" }}>
                                    This file includes links to product images
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="fc-navtab-container">
                        <Tabs selectedTab={tabIndex} TabsItems={tabsItems} onClick={handleTabClick} />
                    </div>

                    {/* // Change to the index of the images tab (7) */}
                    {tabIndex === 7 ? (
                        checkboxState ? (
                            productImages.length > 0 ? (
                                <ImagesGridComponent
                                    images={productImages}
                                    showBase64={false}
                                    imagesState={imagesState}
                                />
                            ) : (
                                <div>No images links found!</div>
                            )
                        ) : productImagesBase64.length === 0 ? (
                            <DragDropImages
                                onNewImagesDrop={setProductImagesBase64}
                                showBase64={checkboxState}
                                getImageState={true}
                                maxSize={300}
                            />
                        ) : (
                            <ImagesGridComponent
                                images={productImagesBase64}
                                showBase64={true}
                                imagesState={imagesState}
                            />
                        )
                    ) : tabIndex === 0 ? (
                        <IdCodes
                            setTabIndex={setTabIndex}
                            tabIndex={tabIndex}
                            handleSetEanCode={handleSetEanCode}
                            TabsItems={tabsItems}
                            isDragging={isDragging}
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleCheckEmptyLabel={handleCheckEmptyLabel}
                        />
                    ) : (
                        <Forms
                            setTabIndex={setTabIndex}
                            index={tabIndex}
                            TabsItems={tabsItems}
                            isDragging={isDragging}
                            formData={formData}
                            handleInputChange={handleInputChange}
                            //--
                            handleOnEditField={handleOnEditField}
                            customFieldNames={customFieldNames}
                            handleCustomInputChange={handleCustomInputChange}
                            setCustomFieldSections={setCustomFieldSections}
                            customFormData={customFormData}
                            setCustomFieldNames={setCustomFieldNames}
                            importedCustomFields={importedCustomFields}
                            additionalFields={additionalFields}
                            setAdditionalFields={setAdditionalFields}
                            handleCheckEmptyLabel={handleCheckEmptyLabel}
                        />
                    )}
                </div>
            </div>
        </StyledPage>
    );
}

const ImagesGridComponent = ({ images, showBase64, imagesState }) => {
    const filtredImages = imagesState === null ? images : images.filter(image => image.state === imagesState);

    return (
        <SimpleGridContainer gap="16px" numOfFieldsPerLine={7}>
            {filtredImages?.map((obj, index) => {
                const image = showBase64 ? obj["base64"] : obj["URL"];
                const stateIcon =
                    obj["state"] === "found" ? check : obj["state"] === "duplicated" ? alertTriangle : fileQuestion;
                return (
                    <div key={index} className="product-image-container">
                        <div className="image-state-container">
                            <img className="image-state" alt={stateIcon} src={stateIcon} />
                        </div>
                        <img src={image} alt={image} className="product-image" />
                    </div>
                );
            })}
        </SimpleGridContainer>
    );
};
