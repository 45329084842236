import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { UploadField } from "./UploadLogosCardStandard";

/**
 * FormFieldImageUpload component allows users to upload and manage images within a form.
 * It supports validation and provides methods to get the current image data and validate the field.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} [props.imageURL] - The initial URL of the image.
 * @param {function} [props.onImageChange] - Callback function to handle image change events.
 * @param {string} [props.requiredType] - Specifies if the image upload is mandatory or optional.
 * @param {string} [props.title] - The label for the image upload field.
 * @param {number} [props.gridColumnSize] - The size of the grid column to span.
 * @param {function} [props.onCurrentValueChange] - (DO NOT USE, use the onImageChange prop instead) Callback function to handle current value change events.
 * @param {React.Ref} ref - The reference object.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <FormFieldImageUpload
 *   title="Upload Image"
 *   imageURL="https://www.w3schools.com/w3images/lights.jpg"
 *   onImageChange={(data) => console.log(data)}
 *   requiredType="mandatory"
 * />
 */
const FormFieldImageUpload = forwardRef((props, ref) => {
    const { imageURL, onImageChange, onFormCardChanges } = props;

    /* #region VARS */
    const [currentImageData, setCurrentImageData] = useState(imageURL ? { url: imageURL } : null);
    const [highlightNotValidFields, setHighlightNotValidFields] = useState(false);
    /* #endregion */

    /* #region METHODS */
    const handleOnImageUpload = imageData => {
        setCurrentImageData(imageData);
        if (onFormCardChanges) onFormCardChanges(true);
    };

    const isValid = (highlightNotValidFields = false) => {
        if (props.requiredType === "mandatory" || props.requiredType === "mandatoryLocked") {
            if (!currentImageData?.base64 && !currentImageData?.url) {
                if (highlightNotValidFields) {
                    setHighlightNotValidFields(true);
                }
                return false;
            }
        }
        return true;
    };

    const getValue = () => {
        return currentImageData;
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (onImageChange) {
            onImageChange(currentImageData);
        }
        if (props.onCurrentValueChange) {
            props.onCurrentValueChange(currentImageData);
        }
    }, [currentImageData]);

    useEffect(() => {
        if (!imageURL) return;
        setCurrentImageData({ url: imageURL });
    }, [imageURL]);
    /* #endregion */

    useImperativeHandle(ref, () => ({
        isValid,
        getValue
    }));

    return (
        <div
            className="FormFieldImageUpload"
            style={{
                gridColumn: props?.gridColumnSize ? `span ${props?.gridColumnSize}` : "span 1"
            }}>
            <UploadField
                handleOnImageUpload={data => handleOnImageUpload(data)}
                fieldData={{
                    imageData: currentImageData,
                    requiredType: props?.requiredType,
                    label: props?.title
                }}
                setImageData={setCurrentImageData}
                highlightNotValidFields={highlightNotValidFields}
            />
        </div>
    );
});

export default FormFieldImageUpload;
