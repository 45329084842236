import AccountPageDetails from "../components/pages/account/AccountPageDetails";
import AccountUsersPage from "../components/pages/account/AccountUsersPage";
import BillingPage from "../components/pages/account/BillingPage";
import AccountEditPage from "../components/pages/account/AccountEditPage";
import AccountUsersInvitePage from "../components/pages/account/AccountUsersInvitePage";

const routes = [
    {
        path: "/account",
        element: <AccountPageDetails />
    },
    {
        path: "/account/edit",
        element: <AccountEditPage />
    },
    {
        path: "/account/create",
        element: <AccountEditPage />
    },
    {
        path: "/account/users",
        element: <AccountUsersPage />
    },
    {
        path: "/account/users/invite",
        element: <AccountUsersInvitePage />
    },
    {
        path: "/billing",
        element: <BillingPage />
    },
    {
        path: "/billing/:id",
        element: <BillingPage />
    }
];

export default routes;
