import { useEffect, useState } from "react";
import GrossMargin from "../dashboardComponents/GrossMargin";
import AnalyticsGraph from "../dashboardComponents/AnalyticsGraph";
import TotalSales from "../dashboardComponents/TotalSales";
import SalesByBrandAndStore from "../dashboardComponents/SalesByBrandAndStore";
import UnitSold from "../dashboardComponents/UnitSold";
import DashboardHeader from "../dashboardComponents/DashboardHeader";
import MapComponent from "../dashboardComponents/MapComponent";
import { useSelector } from "react-redux";
import NDAPopup from "../dashboardComponents/NDAPopup";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import {
    generateRandomValues,
    GrossMarginChartSeriesA,
    GrossMarginChartSeriesB,
    GrossMarginChartSeriesC,
    GrossMarginChartSeriesZero,
    totalSalesChartSeriesA,
    totalSalesChartSeriesB,
    totalSalesChartSeriesC,
    totalSalesChartSeriesZero,
    visitorChartSeriesA,
    visitorChartSeriesB,
    visitorChartSeriesC
} from "../../../utils/utilHelpers";
import { useOutletContext } from "react-router";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";

const StyledPage = styled.div`
    &.dashboard-container {
        padding-bottom: 30px;
    }

    .dashboad-header-container {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 24px;
    }
    .header-first-portion {
        display: flex;
        gap: 16px;
        align-items: center;
    }
    .dashboard-header-data-labels {
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0px 8px;
        border-radius: 4px;
        background-color: #005fcc30;
        color: #00aeef;
        font-size: 12px;
        font-weight: 500;
    }
    .dashboard-header-btn {
        height: 35px;
        width: 120px;
        border-radius: 4px;
        font-size: 10px;
        font-weight: 400;
        background-color: #1e2631;
        outline: none;
        border: none;
        color: #d1d1d1;
    }
    .total-sale-chart {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 24px;
    }
    .three-component-container {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
    }
`;

const DashboardPage = () => {
    document.title = `${APP_TITLE} - Dashboard`;

    /* #region VARS */
    const [visitorChartSeries, setVistorChartSeries] = useState([]);
    const [totalSalesChartSeriesData, setTotalSalesChartSeriesData] = useState(totalSalesChartSeriesZero || []);
    const [GrossMarginChartSeries, setGrossMarginChartSeries] = useState(GrossMarginChartSeriesZero || []);
    const userAppData = useSelector(state => state.userData.userAppData);
    const [showNda, setShowNda] = useState(false);
    const [chartData, setChartData] = useState({
        percentage: 0,
        nokValue: 0,
        other: 0,
        nokPercentage: 0,
        grossPercentage: 0,
        salesPercentage: 0,
        comparedPercentage: 0,
        comparedValue1st: 0,
        comparedValue2nd: 0,
        comparedValue3rd: 0
    });

    const BOstagesAccountID = userAppData?.BOStagesAccount?._id;

    const { MainLayoutProps, layoutType } = useOutletContext();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [store] = useState([]);
    const [brand] = useState([]);

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (userAppData?.NDAaccepted === false && localStorage.getItem("NDAaccepted") !== "true") {
            setShowNda(true);
        }
    }, [userAppData]);

    useEffect(() => {
        if (!props?.layoutType) return;
        setupHeaders(props, updateData);
    }, [props?.layoutType]);
    /* #endregion */
    /* #region METHODS */

    const updateData = () => {
        const data = [visitorChartSeriesA, visitorChartSeriesB, visitorChartSeriesC];
        const totalSalesData = [totalSalesChartSeriesA, totalSalesChartSeriesB, totalSalesChartSeriesC];
        const grossMarginData = [GrossMarginChartSeriesA, GrossMarginChartSeriesB, GrossMarginChartSeriesC];

        const visitorChartSeries = data[Math.floor(Math.random() * 3)];
        const totalSalesChartSeries = totalSalesData[Math.floor(Math.random() * 3)];
        const GrossMarginChartSeries = grossMarginData[Math.floor(Math.random() * 3)];
        const {
            NOK,
            percentage,
            other,
            grossPercentage,
            nokPercentage,
            salesPercentage,
            comparedPercentage,
            comparedValue1st,
            comparedValue2nd,
            comparedValue3rd
        } = generateRandomValues();
        setChartData({
            nokValue: NOK,
            percentage,
            other,
            grossPercentage,
            nokPercentage,
            salesPercentage,
            comparedPercentage,
            comparedValue1st,
            comparedValue2nd,
            comparedValue3rd
        });
        setVistorChartSeries(visitorChartSeries);
        setTotalSalesChartSeriesData(totalSalesChartSeries);
        setGrossMarginChartSeries(GrossMarginChartSeries);
        // const allBrands = userAppData?.retailBrands;

        // setBrand(allBrands?.map(brand => ({ image: brand?.logos?.mainLogoURL, price: 0, name: brand?.name })));

        // setVistorChartSeries(visitorChartSeriesZero);
        // setTotalSalesChartSeriesData(totalSalesChartSeriesZero);
        // setGrossMarginChartSeries(GrossMarginChartSeriesZero);
    };
    /* #endregion */

    return (
        <StyledPage className="dashboard-container">
            <FullScreenOverlay show={showNda}>
                <NDAPopup BOstagesAccountID={BOstagesAccountID} setShowNda={setShowNda} />
            </FullScreenOverlay>

            <div className="total-sale-chart">
                <TotalSales
                    title={"TOTAL SALES"}
                    totalSalesChartSeries={totalSalesChartSeriesData}
                    totalValue={chartData.nokValue || 0}
                    totalValueCurrency={"NOK"}
                    totalOrders={0}
                    avgOrder={0}
                    avgOrderCurrency={"kr"}
                    totalComparisonPercent={chartData?.salesPercentage || 0}
                />

                <GrossMargin
                    title={"OVERALL GROSS MARGIN"}
                    GrossMarginChartSeries={GrossMarginChartSeries}
                    valuePercentage={chartData?.percentage}
                    totalComparisonPercent={chartData?.grossPercentage || 0}
                />

                <TotalSales
                    title={"TOTAL BOTAG SALES"}
                    totalSalesChartSeries={totalSalesChartSeriesData}
                    totalValue={chartData.nokValue || 0}
                    totalValueCurrency={"NOK"}
                    totalOrders={0}
                    avgOrder={0}
                    avgOrderCurrency={"kr"}
                    totalComparisonPercent={chartData?.salesPercentage || 0}
                />

                <GrossMargin
                    title={"OVERALL GROSS MARGIN"}
                    GrossMarginChartSeries={GrossMarginChartSeries}
                    valuePercentage={chartData?.percentage}
                    totalComparisonPercent={chartData?.grossPercentage || 0}
                />
            </div>

            <div className="graph-container-two">
                <AnalyticsGraph
                    activeBOImages={chartData.comparedValue1st}
                    clicksAndScan={chartData.comparedValue2nd}
                    convertedIntoSales={chartData.comparedValue3rd}
                    chartData={visitorChartSeries}
                    conversionRatePercentage={chartData.percentage}
                    activeBOImagesPeriod={chartData.comparedPercentage}
                    clicksAndScanPeriod={chartData.grossPercentage}
                    convertedIntoSalesPeriod={chartData?.nokPercentage}
                    conversionRatePeriod={chartData?.salesPercentage}
                />
                <MapComponent />
            </div>

            <div className="three-component-container">
                <SalesByBrandAndStore
                    type={"BRAND"}
                    brandName={"Replay"}
                    totalValue={chartData.other}
                    totalValueCurrency={"NOK"}
                    data={brand}
                    totalComparisonPercent={chartData?.salesPercentage}
                />

                <UnitSold />

                <SalesByBrandAndStore
                    type={"STORE"}
                    brandName={"Follestad Hamar"}
                    totalValue={chartData.other}
                    totalValueCurrency={"NOK"}
                    data={store}
                    totalComparisonPercent={chartData?.nokPercentage}
                />
            </div>
        </StyledPage>
    );
};

export default DashboardPage;

function setupHeaders(props, updateData) {
    props.setControlBarLeftBtns([]);
    props.setControlBarCenterBtns([{ to: "#", active: true, disabled: false, title: "DASHBOARD" }]);
    props.setControlBarRightBtns([]);

    props.setFilterHeaderProperties({
        customContentHeader: <DashboardHeader onClick={updateData} />
    });
}
