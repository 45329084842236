/* eslint-disable jsx-a11y/anchor-is-valid */
import DotsHorizontal from "@untitled-ui/icons-react/build/esm/DotsHorizontal";
import React, { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import styled from "styled-components";
import "../../../assets/css/components/CheckboxDark.css";
import CardComponentContainer from "../cardContainers/CardComponentContainer";
import { Dropdown } from "antd";
import TableListActionDropdown from "../menus/TableListActionDropdown";
import ContainerLoader from "../loaders/ContainerLoader";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    //ListComponent CSS

    .ListComponent ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    .ListComponent {
        margin-top: -8px;
        //background: var(--backgrounds-lines-n-200);
        //border-radius: 4px;

        color: var(--text-icons-gray-100, #d1d1d1);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        //padding-right: 16px; //Fixes the horizontal scrollbar
    }

    .ListComponent div,
    .ListComponent .expandable-rows span {
        color: var(--text-icons-gray-100, #d1d1d1) !important;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .rdt_Table {
        background: transparent;
    }

    .rdt_TableHead {
        height: 47px;
    }

    .rdt_TableHeadRow {
        background: var(--backgrounds-lines-n-400);
        border-color: var(--backgrounds-lines-n-100);
    }

    .rdt_TableHeadRow > .rdt_TableCol:first-child,
    .rdt_TableRow > .rdt_TableCell:first-child {
        width: 30px;
        flex: 0 0 30px;
        min-width: 30px;
        margin-left: 8px;
    }

    .table-structure.expandable-rows .rdt_TableHeadRow > div:first-child,
    .table-structure.expandable-rows .rdt_TableRow > div:first-child,
    .table-structure.expandable-rows .rdt_ExpanderRow .nested-row > .nested-col:first-child {
        width: 35px;
        flex: 0 0 35px;
        min-width: 35px;
        margin: 0px;
    }

    .table-structure.expandable-rows .rdt_TableRow button[data-testid="expander-button-undefined"],
    .table-structure.expandable-rows .rdt_TableRow button[data-testid="expander-button-undefined"]:hover {
        background-color: transparent;
    }

    /*.rdt_ExpanderRow .nested-row > .nested-col:first-child {
        width: 48px;
        flex: 0 0 48px;
        min-width: 48px;
        margin: 0px;
    }*/

    .rdt_TableBody {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .rdt_TableRow,
    .nested-row {
        background: transparent;
        border: 0;
    }

    .rdt_TableRow:hover,
    .nested-row:hover {
        border-radius: 4px;
        background: var(--backgrounds-lines-n-600) !important;
    }

    .rdt_TableRow:nth-child(even),
    .nested-row:nth-child(even) {
        border-radius: 4px;
        background: var(--backgrounds-lines-n-300);
    }

    .rdt_TableRow:nth-child(odd) {
        border-radius: 4px;
        background: transparent;
    }

    .rdt_Pagination {
        color: var(--text-icons-gray-100);
        background: transparent;
        border-color: var(--backgrounds-lines-n-100);
    }

    .rdt_TableCol:first-child,
    .rdt_TableCol:last-child,
    .rdt_TableCell:first-child,
    .rdt_TableCell:last-child,
    .nested-col:first-child,
    .nested-col:last-child {
        padding: 0px;
        justify-content: start;
    }

    .rdt_TableCol:first-child,
    .rdt_TableCell:first-child,
    .nested-col:first-child {
        margin-left: 0px;
        margin-right: 8px;
    }

    .rdt_TableCol:not(:first-child),
    .rdt_TableCell:not(:first-child),
    .nested-col:not(:first-child) {
        padding-left: 8px;
        padding-right: 0px;
    }

    .table-structure.sticky-actions .rdt_TableHeadRow .rdt_TableCol:last-child,
    .table-structure.sticky-actions .rdt_TableRow .rdt_TableCell:last-child,
    .table-structure.sticky-actions .rdt_ExpanderRow .nested-row .nested-col:last-child {
        position: sticky !important;
        right: 0;
        background-color: var(--backgrounds-lines-n-400);
    }

    .table-structure.sticky-actions .rdt_TableRow:nth-child(even) .rdt_TableCell:last-child,
    .table-structure.sticky-actions .rdt_ExpanderRow .nested-row:nth-child(even) .nested-col:last-child {
        background: var(--backgrounds-lines-n-300) !important;
    }

    .table-structure.sticky-actions .rdt_TableRow:hover .rdt_TableCell:last-child,
    .table-structure.sticky-actions .rdt_ExpanderRow .nested-row:hover .nested-col:last-child {
        //border-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        background: var(--backgrounds-lines-n-600) !important;
    }

    .table-structure.sticky-status .rdt_TableHeadRow .rdt_TableCol:nth-last-child(2),
    .table-structure.sticky-status .rdt_TableRow .rdt_TableCell:nth-last-child(2),
    .table-structure.sticky-status .rdt_ExpanderRow .nested-row .nested-col:nth-last-child(2) {
        position: sticky !important;
        right: 36px;
        background-color: var(--backgrounds-lines-n-400);
        width: 87px;
    }

    .table-structure.sticky-status .rdt_TableRow:nth-child(even) .rdt_TableCell:nth-last-child(2),
    .table-structure.sticky-status .rdt_ExpanderRow .nested-row:nth-child(even) .nested-col:nth-last-child(2) {
        background: var(--backgrounds-lines-n-300) !important;
    }

    .table-structure.sticky-status .rdt_TableRow:hover .rdt_TableCell:nth-last-child(2),
    .table-structure.sticky-status .rdt_ExpanderRow .nested-row:hover .nested-col:nth-last-child(2) {
        background: var(--backgrounds-lines-n-600) !important;
    }

    .table-structure.sticky-status .rdt_TableHeadRow .rdt_TableCol:nth-last-child(2) > div.rdt_tableCol_Sortable,
    .table-structure.sticky-status .rdt_TableRow .rdt_TableCell:nth-last-child(2) > div.rdt_tableCol_Sortable {
        place-content: center;
    }

    // SPECIFIC EXPANDABLE ROWS STYLES
    .rdt_ExpanderRow {
        //padding-left: 40px;
    }

    .nested-row {
        border: 0px !important;
    }

    .nested-col {
        border: 0px !important;
    }
`;

const NestedCols = styled.tr`
    display: flex;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 12px !important;
    font-weight: 400;
    font-family: Roboto;
    border-bottom: 1px solid #333f50 !important;
    min-height: 48px;

    .nested-col {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;

        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        line-height: normal;
        padding-left: 16px;
        padding-right: 16px;
        word-break: break-word;
        max-width: 100%;
        min-width: 100px;
    }

    .nested-col > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
    }

    /*.nested-col.status-sticky {
        position: sticky !important;
        background-color: var(--backgrounds-lines-n-600);
        padding: 0 !important;
        right: 222px;
        z-index: 99990;
        justify-content: center;
    }

    .nested-col.action-menu {
        position: sticky;
        right: 0;
        padding: 0 !important;
        justify-content: center;
        background-color: var(--backgrounds-lines-n-600);
    }*/
`;
/* #endregion */

/**
 * A reusable list component that fetches data from Static Data.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} [props.title] - The title of the list.
 * @param {string} [props.title2] - The secondary title of the list.
 * @param {Array} props.columns - The columns configuration for the table.
 * @param {boolean} [props.selectableRows=true] - Determines whether the rows in the table are selectable.
 * @param {string} [props.className] - Additional CSS class name for the component.
 * @param {string} [props.tableClassName] - Additional CSS class name for the table.
 * @param {Object} [props.actions] - The configuration for the actions column in the table.
 * @param {boolean} [props.actions.includeActions] - Determines whether to include actions column in the table.
 * @param {boolean} [props.actions.editIsVisible=true] - Determines whether the edit action is visible.
 * @param {Function} [props.actions.onClickEdit] - The callback function for the edit action.
 * @param {boolean} [props.actions.suspendIsVisible=true] - Determines whether the suspend action is visible.
 * @param {Function} [props.actions.onClickSuspend] - The callback function for the suspend action.
 * @param {boolean} [props.actions.removeIsVisible=true] - Determines whether the remove action is visible.
 * @param {Function} [props.actions.onClickRemove] - The callback function for the remove action.
 * @param {Array} [props.actions.extraActions] - Additional actions to be displayed in the actions dropdown.
 * @param {Object} [props.includeStatus] - The configuration for the status column in the table.
 * @param {string} [props.includeStatus.statusFromPath] - The path to the status value in the data object.
 * @param {string} [props.includeStatus.defaultStatusValue] - The default status value to display if the status value is not found.
 * @param {boolean} [props.includeStatus.stickyStatus] - Determines whether the status column should be sticky.
 * @param {Array} [props.includeStatus.statusColors] - The configuration for the status colors.
 * @param {string[]} props.includeStatus.statusColors[].forValues - The values for which the corresponding color should be applied.
 * @param {string} props.includeStatus.statusColors[].colorClass - The CSS class for the color.
 * @param {number} [props.pageLimit=20] - The number of rows to display per page.
 * @param {Object} props.listData - The data to be displayed in the list.
 * @param {boolean} [props.isFetching=false] - Determines whether the data is being fetched.
 * @param {string} [props.queryKey] - The query key for the useQuery hook.
 * @param {boolean} [props.expandableRows=false] - Determines whether the rows in the table are expandable.
 * @param {Array} [props.expandedRowName="expandItems"] - The name of the field containing the expanded rows in the data object.
 * @param {Array} [props.expandedRowsColumns] - The columns configuration for the expanded rows in the table.
 * @param {string} [props.defaultSortFieldId] - The default field ID to sort by.
 * @param {boolean} [props.defaultSortAsc] - Determines whether the default sort order is ascending.
 * @param {boolean} [props.persistTableHead] - Determines whether the table head should be persisted when no data is available.
 * @param {ReactNode} [props.middleCardTopComponent] - The middle component to show in the card header.
 * @param {ReactNode} [props.rightCardTopComponent] - The right component to show in the card header.
 *
 * @returns {JSX.Element} The rendered ListCardComponentStaticData.
 */
export default function ListCardComponentStaticData(props) {
    /* #region STATES */
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(props?.pageLimit ?? 20);
    /* #endregion */

    /* #region EFFECTS */
    /* #endregion */

    /* #region METHODS */

    const getColumns = (columns, includeStatusFromValue, statusColors, includeActions) => {
        const includeStatusWithArrayFields = includeStatusFromValue?.split(".");
        if (includeStatusWithArrayFields && statusColors)
            columns = [
                ...columns,
                {
                    name: "Status",
                    selector: row => getStatusColumn(row, includeStatusWithArrayFields, statusColors),
                    minWidth: "87px",
                    maxWidth: "87px"
                }
            ];

        if (includeActions)
            columns = [
                ...columns,
                {
                    name: "",
                    cell: row => (
                        <Dropdown
                            overlay={TableListActionDropdown({
                                tableRowData: row,
                                edit: {
                                    visible: props?.actions?.editIsVisible === false ? false : true,
                                    onClick: props?.actions?.onClickEdit
                                        ? () => props?.actions?.onClickEdit(row)
                                        : undefined
                                },
                                suspend: {
                                    visible: props?.actions?.suspendIsVisible === false ? false : true,
                                    onClick: props?.actions?.onClickSuspend
                                        ? () => props?.actions?.onClickSuspend(row)
                                        : undefined,
                                    isReenable: getStatusValue(row)?.toLowerCase() === "suspended" ? true : false
                                },
                                remove: {
                                    visible: props?.actions?.removeIsVisible === false ? false : true,
                                    onClick: props?.actions?.onClickRemove
                                        ? () => props?.actions?.onClickRemove(row)
                                        : undefined,
                                    removeIsDelete: props?.actions?.removeIsDelete
                                },
                                extraActions: props?.actions?.extraActions
                            })}
                            trigger={["click"]}>
                            <a className="ant-dropdown-link" style={{ color: "white", cursor: "pointer" }}>
                                <DotsHorizontal height="16" color="#D1D1D1" />
                            </a>
                        </Dropdown>
                    ),
                    width: "36px"
                }
            ];

        return columns;
    };

    const getStatusValue = row => {
        const statusFromPath = props?.includeStatus?.statusFromPath;
        const status = getArrayFieldValue(row, statusFromPath?.split(".") || "Unknown");
        //console.log("status", status);
        return status;
    };

    const getStatusColumn = (row, includeStatusWithArrayFields, statusColors) => {
        let statusValue =
            getArrayFieldValue(row, includeStatusWithArrayFields) ||
            props?.includeStatus?.defaultStatusValue ||
            "Unknown";

        //TODO: This is a temporary fix for the "Not Connected" status value
        if (statusValue === "Not Connected") statusValue = "Not connected";

        let colorValue = "text-orange-500";
        let found = false;

        for (const color of statusColors) {
            for (const value of color.forValues) {
                if (value.toLowerCase() === statusValue.toLowerCase()) {
                    found = true;
                    colorValue = color.colorClass;
                    break;
                }
            }
            if (found) break;
        }

        return (
            <div>
                <div className="flex align-items-center">
                    <div className={`status-dot bg-${colorValue}`}></div>
                    <span className={colorValue}>{statusValue}</span>
                </div>
            </div>
        );
    };

    const getArrayFieldValue = (row, array) => {
        if (!Array.isArray(array)) return "Unknown";

        let res = row;
        for (let i = 0; i < array.length; i++) {
            res = res?.[array[i]];
        }
        return res;
    };

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    /* #endregion */

    /* #region COMPONENTS */
    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
        return (
            <>
                <label className="container-checkbox">
                    <input type="checkbox" ref={ref} onClick={onClick} {...rest} />
                    <span className="checkmark"></span>
                </label>
            </>
        );
    });

    createTheme("dark", {
        text: {
            primary: "#D1D1D1",
            secondary: "#D1D1D1"
        },
        background: {
            default: "var(--backgrounds-lines-n-400)"
        },
        context: {
            background: "var(--backgrounds-lines-n-400)",
            text: "#D1D1D1"
        },
        divider: {
            default: "var(--backgrounds-lines-n-400)"
        },
        action: {
            button: "var(--backgrounds-lines-n-400)",
            hover: "var(--backgrounds-lines-n-500)",
            disabled: "var(--backgrounds-lines-n-400)"
        }
    });

    const ExpandedComponent = ({ data }) => {
        const expandedRowName = props?.expandedRowName || "expandItems";

        return (
            <table className="" style={{ width: "100%", maxWidth: "100%", marginBotton: "20px" }}>
                <tbody>
                    {data?.[expandedRowName]?.map((row, index) => {
                        return (
                            <NestedCols className="nested-row" key={index}>
                                <td className="nested-col" key={"nested-col-0"} style={{}}></td>

                                {props?.expandedRowsColumns?.map((col, index) => {
                                    return (
                                        <td
                                            className="nested-col"
                                            key={index}
                                            style={{ minWidth: col?.minWidth, maxWidth: col?.maxWidth }}>
                                            <span title={col.selector(row)}>{col.selector(row)}</span>
                                        </td>
                                    );
                                })}

                                {props?.includeStatus && (
                                    <td className="nested-col" style={{ minWidth: "87px", maxWidth: "87px" }}>
                                        {getStatusColumn(
                                            row,
                                            props?.includeStatus?.statusFromPath.split("."),
                                            props?.includeStatus?.statusColors
                                        )}
                                    </td>
                                )}

                                {props?.actions?.includeActions && (
                                    <td className="nested-col" style={{ minWidth: "36px", maxWidth: "36px" }}>
                                        <Dropdown
                                            overlay={TableListActionDropdown({
                                                tableRowData: row,
                                                edit: {
                                                    visible: props?.actions?.editIsVisible === false ? false : true,
                                                    onClick: props?.actions?.onClickEdit
                                                        ? () => props?.actions?.onClickEdit(row)
                                                        : undefined
                                                },
                                                suspend: {
                                                    visible: props?.actions?.suspendIsVisible === false ? false : true,
                                                    onClick: props?.actions?.onClickSuspend
                                                        ? () => props?.actions?.onClickSuspend(row)
                                                        : undefined,
                                                    isReenable:
                                                        getStatusValue(row)?.toLowerCase() === "suspended"
                                                            ? true
                                                            : false
                                                },
                                                remove: {
                                                    visible: props?.actions?.removeIsVisible === false ? false : true,
                                                    onClick: props?.actions?.onClickRemove
                                                        ? () => props?.actions?.onClickRemove(row)
                                                        : undefined
                                                },
                                                extraActions: props?.actions?.extraActions
                                            })}
                                            trigger={["click"]}>
                                            <a
                                                className="ant-dropdown-link"
                                                style={{ color: "white", cursor: "pointer" }}>
                                                <DotsHorizontal height="16" color="#D1D1D1" />
                                            </a>
                                        </Dropdown>
                                    </td>
                                )}
                            </NestedCols>
                        );
                    })}
                </tbody>
            </table>
        );
    };
    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <>
                <ContainerLoader isLoading={false} isLoadingOverlay={true} addBlurFilters={false}>
                    <CardComponentContainer
                        title={`${props?.title || "--"}`}
                        title2={props?.title2}
                        loading={props?.isFetching || false}
                        middleComponent={props?.middleCardTopComponent}
                        rightComponent={props?.rightCardTopComponent}>
                        <div
                            className={`table-structure ${props?.actions?.includeActions && "sticky-actions"} ${
                                props?.includeStatus?.stickyStatus && "sticky-status"
                            } ${props?.expandableRows && "expandable-rows"}`}>
                            <DataTable
                                //onSort={e => console.log("sort", e)} //TODO: This is not implemented yet
                                defaultSortFieldId={props?.defaultSortFieldId}
                                defaultSortAsc={props?.defaultSortAsc}
                                columns={getColumns(
                                    props?.columns,
                                    props?.includeStatus?.statusFromPath,
                                    props?.includeStatus?.statusColors,
                                    props?.actions?.includeActions
                                )}
                                persistTableHead={props?.persistTableHead}
                                className={`ListComponent ${props?.tableClassName || ""}`}
                                theme="dark"
                                //columns={props?.columns}
                                data={props?.listData}
                                fixedHeader
                                //fixedHeaderScrollHeight="496px"
                                fixedHeaderScrollHeight="calc(100vh - 450px)"
                                selectableRows={props?.selectableRows ?? true}
                                selectableRowsComponent={Checkbox}
                                responsive={true}
                                //dataLoading={isFetchingNewData}
                                paginationRowsPerPageOptions={[20, 50, 100]}
                                pagination
                                paginationDefaultPage={currentPage}
                                //paginationServer={true}
                                onChangePage={handlePageChange}
                                //paginationTotalRows={totalRows}
                                paginationPerPage={rowsPerPage}
                                onChangeRowsPerPage={currentRowsPerPage => {
                                    setRowsPerPage(currentRowsPerPage);
                                    setCurrentPage(1);
                                }}
                                expandableRows={props?.expandableRows ?? false}
                                expandableRowsComponent={ExpandedComponent}
                            />
                        </div>
                    </CardComponentContainer>
                </ContainerLoader>
            </>
        </StyledPage>
    );
}
