const SingleField = ({
    title,
    type,
    value,
    onChange,
    onKeyUp,
    isInvalid,
    isRequiredAsterisk = false,
    errorMessage,
    isDisabled = false,
    limit = 40,
    prefixLabel,
    onClick,
    isLabel = false,
    showDropdownIcon = false
}) => {
    return (
        <div className={`InputField w-100 ${isInvalid ? "error" : ""}`} onClick={onClick}>
            <div className="EmailAddress" onClick={e => e.stopPropagation()} style={{ cursor: "default" }}>
                {title}{" "}
                {isRequiredAsterisk ? (
                    <span
                        className="required-asterisk"
                        style={{
                            color: !value ? "var(--text-and-icons-orange-500)" : "var(--text-and-icons-gray-200)"
                        }}>
                        *
                    </span>
                ) : (
                    ""
                )}
            </div>
            <div className="flex flex-row w-100">
                {prefixLabel && <div className="PrefixLabel">{prefixLabel}</div>}
                <Field
                    isLabel={isLabel}
                    type={type}
                    value={value}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    disabled={isDisabled}
                    className={`Frame313324 ${isInvalid ? "error" : ""} ${isDisabled ? "disabled" : ""}`}
                    style={{
                        borderColor: isInvalid ? "red !important" : "",
                        color: isInvalid ? "red !important" : isDisabled ? "red" : ""
                    }}
                    maxLength={limit}
                />
                {showDropdownIcon && (
                    <div className="droppedRemove" style={{ height: "14px", color: "var(--text-and-icons-gray-200)" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.52864 6.02864C3.78899 5.76829 4.2111 5.76829 4.47145 6.02864L8.00004 9.55723L11.5286 6.02864C11.789 5.76829 12.2111 5.76829 12.4714 6.02864C12.7318 6.28899 12.7318 6.7111 12.4714 6.97145L8.47145 10.9714C8.2111 11.2318 7.78899 11.2318 7.52864 10.9714L3.52864 6.97145C3.26829 6.7111 3.26829 6.28899 3.52864 6.02864Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                )}
            </div>
            <div className="Line2" style={{ marginTop: "6px" }} />
            {isInvalid && <div className="EmailInvalidSection">{errorMessage}</div>}
        </div>
    );
};

const Field = props => {
    const propsRecognized = {
        type: props.type,
        value: props.value,
        onChange: props.onChange,
        onKeyUp: props.onKeyUp,
        disabled: props.disabled,
        className: props.className,
        style: props.style,
        maxLength: props.maxLength
    };
    return !props.isLabel ? <input {...propsRecognized} /> : <label {...propsRecognized}>{props.value}</label>;
};

export default SingleField;
