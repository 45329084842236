import React, { useEffect, useState } from "react";
import InfinityGridBOImagesCards from "../../common/cardContainers/InfinityGridBOImagesCards";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import { formatDefaultDateTime, formatDefaultDate } from "../../../utils/Formatter";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import PublishPopUp from "../../common/PublishPopUp";
import * as Library from "../../../utils/Library";
import { AppQueryClient } from "../../../api/queryClient";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import { useNavigate, useOutletContext } from "react-router";
import AppContext from "../../../AppContext";
import { useSelector } from "react-redux";
import LayoutContext from "../../../LayoutContext";
import { defaultStatusColors } from "../../../utils/CardUtils";
import MainWindowHeader from "../../common/mainApp/mainWindowHeaders/MainWindowHeader";

// #region SCOPED CSS
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
// #endregion

export default function BOImagesPage() {
    document.title = `${APP_TITLE} - BOImages`;

    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);

    const [listNumRows, setListNumRows] = useState(20);

    //---------- Header Filters
    const [allConnectedCollections, setAllConnectedCollections] = useState([]);
    const [allCollectionsList, setAllCollectionsList] = useState([]);
    const [filteredCollections, setFilteredCollections] = useState([]);
    //-----
    const [allLabelBrandsList, setAllLabelBrandsList] = useState([]);
    const [filteredLabelBrand, setFilteredLabelBrand] = useState([]);
    //-----
    const [requestEnabled, setRequestsEnabled] = useState(false);

    const filters = {
        filterLabelBrandIDs: filteredLabelBrand?.map(item => item?.value),
        filterCollectionNames: filteredCollections?.map(item => item?.name)
    };

    const infiniteGridQuerykey = ["BOImagesGrid", filters];
    const listQueryKey = ["BOImagesList", filters];

    //--------------------

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData,
        navigations: navigations
    };

    // #region STATES
    const {
        setSidePanelBOtagProductLink,
        setBOTagsList,
        setSidePanelVisible,
        setSidePanelSection,
        setSidePanelProducts,
        setSidePanelHasNewLinkedProducts,
        setSidePanelLabelBrandID,
        setSidePanelProductSearchName,
        setSidePanelSelectedProductID,
        setSidePanelSelectedProductNumber,
        setBrandNotSelectedShowError
    } = React.useContext(AppContext);

    const { showFirstBOImagePopup, setShowFirstBOImagePopup } = React.useContext(LayoutContext);

    const navigate = useNavigate();
    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    const [isLoading, setIsLoading] = useState(false);
    const [publishPopUp, setPublishPopUp] = useState(false);
    const [publishBOimageID, setPublishBOimageID] = useState(null);

    const [removeID, setRemoveID] = useState(null);
    const [toogleSuspendData, setToogleSuspendData] = useState(null);

    // #endregion

    // #region EFFECTS

    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(
            props,
            navigations,
            onClickRefreshAction,
            allCollectionsList,
            allLabelBrandsList,
            onLabelBrandChange,
            onFiltersLoaded,
            onCollectionChange,
            filteredLabelBrand
        );
    }, [
        props?.layoutType,
        navigations,
        allCollectionsList,
        allLabelBrandsList,
        filteredLabelBrand,
        filteredCollections,
        requestEnabled
    ]);

    useEffect(() => {
        setSidePanelProducts([]);
        setBOTagsList([]);
        setSidePanelVisible(false);
        setSidePanelSection("Products");
        setSidePanelBOtagProductLink(null);
        setSidePanelHasNewLinkedProducts(false);
        setSidePanelProductSearchName("");
        setSidePanelLabelBrandID(null);
        setSidePanelSelectedProductID(undefined);
        setSidePanelSelectedProductNumber(undefined);
        setBrandNotSelectedShowError(false);
    }, []);
    // #endregion

    // #region METHODS
    const onLabelBrandChange = selection => {
        setFilteredLabelBrand([selection]);

        const currentLabelBrandID = selection?.value;
        filterConnectedCollections(allConnectedCollections, currentLabelBrandID);
    };

    const onCollectionChange = (selections, dropdownDataWithSelections) => {
        // if a selection is value == "Not assigned" then remove all other selections
        /*if (selections?.some(item => item?.value === "Not assigned")) {
            // remove all other selections
            const filteredSelections = [];
            setFilteredCollections(filteredSelections);
            setAllCollectionsList(dropdownDataWithSelections);
        } else {*/
        setFilteredCollections(selections);
        setAllCollectionsList(dropdownDataWithSelections);
    };

    const onFiltersLoaded = data => {
        let currentLabelBrandID = null;
        if (data?.labelBrands?.length) {
            const labelBrands = data?.labelBrands?.map((item, index) => {
                const hasCollections = data?.BOimageCollections?.some(
                    collection => collection?.labelBrandID === item?._id
                );
                return {
                    name: item?.name,
                    value: item?._id,
                    selected: index === 0,
                    hasCollections: hasCollections
                };
            });

            // filter out any label brands that have no collections
            //labelBrands = labelBrands?.filter(item => item?.hasCollections);

            setAllLabelBrandsList(labelBrands);
            setFilteredLabelBrand([labelBrands[0]]);
            currentLabelBrandID = labelBrands[0]?.value;
        }

        if (data?.BOimageCollections?.length) {
            const collections = data?.BOimageCollections?.map(item => {
                return {
                    name: item?.name,
                    value: item?.name,
                    selected: true,
                    labelBrandID: item?.labelBrandID
                };
            });

            // for each label brand add "Not assigned" collection with labelBrandID
            /* for (let i = 0; i < data?.labelBrands?.length; i++) {
                const labelBrand = data?.labelBrands[i];
                const labelBrandID = labelBrand?._id;

                //TODO: TEMP fix
                collections.unshift({
                    name: "Not assigned", //Not assigned (${labelBrand?.name})
                    value: "Not assigned",
                    selected: true,
                    labelBrandID
                });
            } */

            setAllConnectedCollections(collections);
            setFilteredCollections(collections?.filter(item => item?.selected));
            filterConnectedCollections(collections, currentLabelBrandID);
        }

        setRequestsEnabled(true);
    };

    const filterConnectedCollections = async (allCollections, currentLabelBrandID) => {
        let res = allCollections?.filter(item => {
            return item?.labelBrandID === currentLabelBrandID;
        });

        // filter out any duplicated retailers
        res = res?.filter((item, index) => {
            return res?.findIndex(item2 => item2?.value === item?.value) === index;
        });

        setAllCollectionsList(res);
        setFilteredCollections(res);
        //setFilteredCollections([]); //TODO: TEMP fix
    };

    const handleEdit = itemData => {
        const itemID = itemData?._id;

        if (!itemID) {
            Library.showErrorMessage("Invalid item ID!");
            return;
        }

        navigate(`/boimages/edit/${itemID}`);
    };

    const handleOnImageClick = itemData => {
        const BOimageID = itemData?.botagID;

        const urlPrefix = process.env.REACT_APP_BOTHEBOT_URL;
        const url = `${urlPrefix}${BOimageID}`;

        window.open(url, "_blank");
    };

    const handleDelete = async itemData => {
        const itemID = itemData?._id;

        if (!itemID) {
            Library.showErrorMessage("Invalid item ID!");
            return;
        }

        setRemoveID(itemID);
    };

    const makeDeleteRequest = itemID => {
        setIsLoading(true);

        /*setTimeout(() => {
        setIsLoading(false);
        setRemoveID(null);
        Library.showSuccessMessage("BOImage deleted successfully!");
    }, 2000);*/

        Library.makeCommonPostRequest("BOimages", "deleteBOimage", { BOimageID: itemID }, false, setIsLoading)
            .then(res => {
                if (res.data.status !== 200) return;
                setIsLoading(false);
                setRemoveID(null);
                onClickRefreshAction();
                Library.showSuccessMessage("BOImage deleted successfully!");
                //props?.getUserDataFunction(localStorage.getItem("token"));
            })
            .catch(err => {
                setIsLoading(false);
                setRemoveID(null);
            });
    };

    const handleToogleSuspend = itemData => {
        const itemID = itemData?._id;

        if (!itemID) {
            Library.showErrorMessage("Invalid item ID!");
            return;
        }

        setToogleSuspendData(itemData);
    };

    const toogleSuspend = itemData => {
        setIsLoading(true);

        /*setTimeout(() => {
        setIsLoading(false);
        setToogleSuspendData(null);
        Library.showSuccessMessage("BOImage suspended successfully!");
    }, 2000);*/

        //const groupCompanyID = localStorage.getItem("currentCompanyID");
        const itemID = itemData?._id;
        const status = itemData?.status === "Active" ? "Suspended" : "Active";

        Library.makeCommonPostRequest(
            "BOimages",
            "updateBOimageStatus",
            {
                BOimageID: itemID,
                status
            },
            false,
            setIsLoading
        )
            .then(res => {
                if (res.data.status !== 200) return;
                setIsLoading(false);
                setToogleSuspendData(null);
                Library.showSuccessMessage(
                    status === "Active" ? "BOImage activated successfully!" : "BOImage suspended successfully!"
                );

                onClickRefreshAction();
                //props?.getUserDataFunction(localStorage.getItem("token"));
            })
            .catch(err => {
                setIsLoading(false);
                setToogleSuspendData(null);
            });
    };

    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };

    const onPublishClick = BOimage => {
        setPublishBOimageID(BOimage?.botagID);
        setPublishPopUp(true);
    };

    const onDetailsClick = BOimage => {
        navigate(`/boimages/${BOimage?._id}`);
    };
    // #endregion

    // #region  LIST COMPONENT PROPS
    const listColumns = [
        {
            name: "ID",
            selector: row => row?.botagID ?? "--"
        },
        {
            name: "Active Period",
            selector: row =>
                row?.activePeriodStart && row?.activePeriodEnd
                    ? `${formatDefaultDate(row?.activePeriodStart)} to ${formatDefaultDate(row?.activePeriodEnd)}`
                    : "--"
        },
        {
            name: "Created by",
            selector: row => row?.createdBy ?? "--"
        },
        {
            name: "Created On",
            selector: row => formatDefaultDateTime(row?.createdOn)
        }
    ];
    // #endregion

    return (
        <StyledPage>
            <FullScreenOverlay show={toogleSuspendData !== null}>
                <DialogBoxComponent
                    title={toogleSuspendData?.status === "Active" ? "Suspend BOImage" : "Activate BOImage"}
                    message={
                        toogleSuspendData?.status === "Active"
                            ? `Are you sure you want to suspend ${toogleSuspendData?.name} BOImage?`
                            : `Are you sure you want to activate ${toogleSuspendData?.name} BOImage?`
                    }
                    onClickYes={() => {
                        toogleSuspend(toogleSuspendData);
                    }}
                    loading={isLoading}
                    loadingHeading={
                        toogleSuspendData?.status === "Active" ? "Suspending BOImage..." : "Activating BOImage..."
                    }
                    onClickNo={() => {
                        setToogleSuspendData(null);
                    }}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={removeID !== null}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-red-500)"
                    title="Removing BOImage"
                    message="Are you sure you want to remove this BOImage?"
                    onClickYes={() => {
                        makeDeleteRequest(removeID);
                    }}
                    loading={isLoading}
                    loadingHeading="Removing BOImage..."
                    onClickNo={() => {
                        setRemoveID(null);
                    }}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showFirstBOImagePopup}>
                <DialogBoxComponent
                    headingFontSize="16px"
                    buttonWidth="151px"
                    buttonFontSize="12px"
                    onClickYes={() => {
                        navigate("/retailers/invite");
                        setShowFirstBOImagePopup(false);
                    }}
                    onClickNo={() => {
                        navigate("/stores/create");
                        setShowFirstBOImagePopup(false);
                    }}
                    title="First BOimage created!"
                    heading="DONE!"
                    //headingColor="#00BB00"
                    message="<div style='font-size: 14px; margin: 0 -5px;'>Before you can publish this BOimage, enroll some stores.</div>"
                    showConfirm={true}
                    showCancel={true}
                    cancelText="ENROLL OWN STORES"
                    confirmText="INVITE OTHER STORES"
                    cancelState="primary"
                    showCloseButton={true}
                    onClickCloseBtn={() => {
                        setShowFirstBOImagePopup(false);
                    }}
                />
            </FullScreenOverlay>

            {publishPopUp && (
                <FullScreenOverlay
                    children={<PublishPopUp setPublishPopUp={setPublishPopUp} BOimageID={publishBOimageID} />}
                />
            )}
            {viewLayoutStyle === "Grid" && (
                <InfinityGridBOImagesCards
                    minColumnWidth="238px"
                    limit={12}
                    primaryButton={{ onClick: item => onDetailsClick(item) }}
                    secondaryButton={{ onClick: item => onPublishClick(item) }}
                    onEditAction={handleEdit}
                    onDeleteAction={handleDelete}
                    onSuspendAction={handleToogleSuspend}
                    onImageClick={handleOnImageClick}
                    queryKey={infiniteGridQuerykey}
                    requestEnabled={requestEnabled}
                    filters={filters}
                />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="BOImages"
                    columns={listColumns}
                    api={{
                        endpoint: "getBOimagesData",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            limit: listNumRows,
                            ...filters
                        },
                        useCommonPostRequest: true,
                        commonContext: "BOImages"
                        //onSuccess: mapListData
                    }}
                    pageLimit={listNumRows}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        includeActions: true,
                        onClickEdit: handleEdit,
                        onClickRemove: handleDelete,
                        onClickSuspend: handleToogleSuspend,
                        //editIsVisible: false,
                        extraActions: [
                            {
                                title: "Details",
                                onClick: item => onDetailsClick(item)
                            },
                            {
                                title: "Publish",
                                onClick: item => onPublishClick(item)
                            }
                        ]
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    requestEnabled={requestEnabled}
                    onChangeRowsPerPage={rowsPerPage => setListNumRows(rowsPerPage)}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(
    props,
    navigations,
    onClickRefreshAction,
    allCollectionsList,
    allLabelBrandsList,
    onLabelBrandChange,
    onFiltersLoaded,
    onCollectionChange,
    filteredLabelBrand
) {
    const createMode = navigations?.navigationAccesses?.BOimages?.createModeOnly;

    props.setControlBarLeftBtns([
        { to: "#", disabled: false, title: "BOImages", icon: "fa-magnifying-glass", isSearchToogle: true }
    ]);
    props.setControlBarCenterBtns([{ to: "/boimages", active: true, disabled: false, title: "BOIMAGES" }]);
    props.setControlBarRightBtns([
        { to: "/boimages", active: true, disabled: createMode, title: "View", icon: "eye" },
        { to: "/boimages/create", active: false, disabled: false, title: "Add", icon: "plus" },
        { to: "/boimages/empty", active: false, disabled: createMode, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <MainWindowHeader
                key={window.location.pathname}
                visible={true}
                layoutTypeOptions={{
                    gridEnabled: true,
                    listEnabled: true
                }}
                onRefreshClick={onClickRefreshAction}
                firstOrganize={{
                    visible: true,
                    dropdownData: allLabelBrandsList,
                    onDropdownChange: onLabelBrandChange,
                    disabled: false,
                    type: "singleSelect",
                    selectedValue: filteredLabelBrand?.[0]?.name
                }}
                secondOrganize={{
                    visible: true,
                    dropdownData: allCollectionsList,
                    onDropdownChange: onCollectionChange,
                    type: "multiSelect",
                    multidropdownOptions: {
                        useDoneButton: true,
                        useSelectAll: true,
                        selectAllText: "Select all collections",
                        selectAllPlaceholderFunction: arrayItems => {
                            if (!arrayItems?.length) return "No collections";

                            const nonLockedCollections = allCollectionsList?.filter(item => !item?.locked);
                            const areAllSelected = arrayItems?.length === nonLockedCollections?.length;
                            if (areAllSelected) return `All collections (${nonLockedCollections?.length})`;

                            let firstTwoItems = arrayItems
                                ?.slice(0, 2)
                                ?.map(item => item?.name)
                                ?.join(", ");
                            if (arrayItems?.length > 2) {
                                firstTwoItems = firstTwoItems + ", and more";
                            }

                            return `Collections (${arrayItems?.length}): ${firstTwoItems}`;
                        }
                    }
                }}
                pageFilters={{
                    useFilters: true,
                    filters: {
                        useLabelBrands: true,
                        useBOImagesCollections: true
                    },
                    onFiltersLoaded: onFiltersLoaded
                }}
            />
        )
    });
}
