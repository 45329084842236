/* #region Privacy Policy */
export const privacyTitle = "BOtag Technology BV Privacy Policy";

export const privacyLastUpdated = "01/09/2024";

export const privacyPolicyDownloadLink = "";

export const privacyPolicyIntroData = [
    "Welcome to <b>BOtag Technology BV</b>. Your privacy and the security of your personal data are of paramount importance to us.",
    "This Privacy Policy identifies the data controller for the processing activities described below, outlines how we collect, use, protect, and share information about our users (“you”) and your rights regarding your personal information.",
    "This Privacy Policy governs how we manage personal information gathered when you engage in transactions with us or navigate our website. It outlines BOtag Technology BV’s practices for processing your personal data and furnishes you with the details you have the right to know under relevant data protection legislation."
];

export const privacyPolicyData = [
    {
        title: "1. Who handles your data",
        html: `Unless specified differently, BOtag Technology BV (hereinafter referred as BOtag) acts as the data controller for the processing activities outlined below. <br />
        <div class='terms-text-tab1'>
        BOtag Technology BV <br />
        Kingsfordweg 151, 1043 GR Amsterdam <br />
        The Netherlands<br />
        VAT: NL855693009B01<br />
        REG: 64500187<br />
        info@botagtechnology.com
        </div>
        `
    },
    {
        title: "2. When we collect your data",
        html: `BOtag may collect your data when you visit any of the following websites owned by BOtag: botagtechnology.com and bostages.com and when you make purchases through botag.no, botag plugin or botag web or mobile applications.<br /><br />
        Botag may also collect your data when you communicate with us through email, sign to our newsletters or use any services or featured offered by Botag.
        `
    },
    {
        title: "3. What data we may collect",
        html: `Botag Technology BV may collect, process and store your personal data such us:<br />
        <ul class='terms-text-ul'>
            <li><b>Personal Identification Information</b>: Name, email address, phone number, address and other information you provide when creating an account.</li>
            <li><b>Transactional Data</b>: Details about purchases, preferences and interests (namely in products or services), and interactions with our platform.</li>
            <li><b>Technical and Usage Data</b>: IP address, browser type, operating system, page views, and navigation paths to understand how our platform is used.</li>
        </ul>`
    },
    {
        title: "4. Data we do not store",
        html: `BOtag does not store any payment method information on our systems. All payment processing is conducted through our trusted payment processors, who are responsible for securely storing and handling your payment details.
        `
    },
    {
        title: "5. How we use your information",
        html: `In order to provide and improve our services and products, your personal data may be processed on: <br />
                <ul class='terms-text-ul'>
                <li><b>Orders handling</b>: Name, email address, phone number, address and other information you provide when creating an account.</li>
                <li><b>Customer relationship management</b>: User account management; marketing communications such as sms, mms, newsletters and advertising in general; customer service; marketing events such us contests and public events; surveys; fulfillment of agreements entered between you and BOtag Technology BV and handling of requests from you.</li>
                <li><b>Legal compliance and security measures</b>: compliance with applicable laws and regulations; establishment, defense, and exercise of legal claims; prevention, detection, investigation, and combat of security breaches, fraud, and other potentially illegal activities.</li>
                <li><b>Research & Development</b>: Development of our products and services.</li>
                </ul>
        `
    },
    {
        title: "6. Legal framework",
        html: `BOtag handles and processes your personal data according to the GDPR legal framework <br />
                <ul class='terms-text-ul'>
                <li><b>Article 6.1.a:</b> Only if you have given prior consent in our platforms..</li>
                <li><b>Article 6.1.b:</b> compliance with applicable laws and regulations; establishment, defense, and exercise of legal claims; prevention, detection, investigation, and combat of security breaches, fraud, and other potentially illegal activities.</li>
                <li><b>Article 6.1.c:</b> To comply with accounting obligations and others.</li>
                <li><b>Article 6.1.f:</b> To conduct marketing activities and/or analytics..</li>
                <li><b>Article 6.1.f and 9.2.f:</b> To prevent fraud or to establish, prosecute or defend legal claims..</li>
                </ul>
        `
    },
    {
        title: "7. Sharing your information",
        html: `To achieve the aforementioned objectives, we may grant access to your personal data: <br />
                <ul class='terms-text-ul'>
                <li>To data processors who, under an agreement with BOtag, assist in our operations, offering pertinent services, including: providers of IT services such as POS systems installed in physical stores; providers of delivery services;</li>
                <li>To data processors providers of stock such as physical stores or warehouses, or to providers of product information such as brands, with the exclusive purpose of: providing you with accurate information about real-time stock and prices; providing you with authentic product information; and handling and fulfilling orders placed by you through BOtag. Both brands and stores operate as independent data controllers of personal data, processing the information for their own purposes.</li>
                <li>To other data processors, under an agreement with BOtag, offer other pertinent services including but not limited to CRM systems providers, billing and payment systems providers, marketing services providers, IT services & support providers, website analytics and hosting services.</li>
                <li>When required by law or to protect the rights and safety of our users and the public.</li>
                <li>With your consent, or as part of a business transfer, merger, or sale of assets.revent fraud or to establish, prosecute or defend legal claims..</li>
                </ul>
        `
    },
    {
        title: "8. Data security",
        html: `We implement robust security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. Our platform is hosted on Microsoft Azure infrastructure, which adheres to high standards of security and data protection compliance. This ensures that your personal data is managed in a secure environment with state-of-the-art technological safeguards.
        `
    },
    {
        title: "9. Storage period",
        html: `
        <ul class='terms-text-ul'>
            <li>Personal data related to your purchases is initially stored for three years following your most recent transaction.</li>
            <li>Data incorporated into financial records is retained for five years beyond the conclusion of the corresponding fiscal year before it is removed.</li>
            <li>Records of your consent to marketing are maintained for one years after you withdraw your agreement to receive direct marketing communications.</li>
        </ul>
        <br />
        Circumstances may necessitate extending the retention period of personal data, such as to comply with legal obligations or to establish, pursue, or defend against legal claims.
        Additionally, data may be processed and preserved in an anonymized format for an extended period.
        `
    },
    {
        title: "10. Your Rights",
        html: `You have the right to access, correct, delete, or limit the use of your personal information. Please contact us if you wish to exercise these rights: info@botagtechnology.com
        `
    },
    {
        title: "11. Children’s Privacy",
        html: `BOtag is not intended for individuals under the age of 13. We do not knowingly collect personal information from children without parental consent.
        `
    },
    {
        title: "12. Changes to this Policy",
        html: `We may update this Privacy Policy from time to time. We will notify you of any significant changes and update the effective date at the top of this policy.
        `
    },
    {
        title: "13. Contact Us",
        html: `If you have any questions about this Privacy Policy, please contact us at:<br />
        <div class='terms-text-tab1'>
            BOtag Technology BV <br />
            Kingsfordweg 151, 1043 GR Amsterdam <br />
            The Netherlands <br />
            VAT: NL855693009B01 <br />
            REG: 64500187 <br />
            info@botagtechnology.com <br />
        </div>
        `
    }
];
/* #endregion */

/* #region Terms and Conditions */

export const termsAndConditionsTitle = "BOtag Technology BV Terms and Conditions";

export const termsAndConditionsLastUpdated = "01/09/2024";

export const termsAndConditionsDownloadLink = null;

export const termsAndConditionsIntroData = [
    /* "Our technology (BOtag technology), is owned by BOtag Technology BV, registered in Amsterdam, Netherlands, under the organization number 64500187, headquartered at Kingsfordweg 151, 1043 GR Amsterdam, Netherlands, and operated in Norway by its subsidiary BOtag Norge AS, registered under organization number 932017814 with our principal place of business at Tordenskiolds Gate 2, 0160 Oslo, Norway.",
    "By using our platforms and technology, you accept these Terms and Conditions in full; accordingly.If you disagree with these terms or any part of these terms, you must not use our platform."
    */
];

export const termsAndConditionsData = [
    {
        title: "1. Introduction",
        html: `Welcome to BOtag Technology! By using our platform, website, and services (the “Platform”), which include BOimages, BOstages, and HeyBO, you agree to the following terms and conditions (the “Terms”). Please read these Terms carefully before accessing or using our services. If you do not agree to these Terms, you must not access or use our services.
        `
    },
    {
        title: "2. Definitions",
        html: `<ul class='terms-text-ul'>
                    <li><b>“Platform”</b>: Refers to the BOtag website, app, BOimages, BOstages (including BOstageB and BOstageR), HeyBO (web, plugin, and mobile versions), and all related services.</li>
                    <li><b>“User”</b>: Refers to any individual or entity using the Platform, including shoppers and merchants.</li>
                    <li><b>“Merchant”</b>: Refers to businesses (brands or retailers) that use BOtag or BOstages to sell products or affiliate their stores.</li>
                    <li><b>“HeyBO”</b>: Refers to the BOtag shopping experience available through web, mobile, and plugin versions. It represents the interface where consumers interact with BOtag technology.</li>
                    <li><b>“BOimages”</b>: Refers to marketing images powered by BOtag technology. They are created and published by Brands and Stores and lead consumers to the HeyBO shopping experience.</li>
                    <li><b>“Stripe”</b>: Refers to the third-party payment service provider that handles all payment processing for transactions on BOtag.</li>
                </ul>
        `
    },
    {
        title: "3. Acceptance of Terms",
        html: `By accessing and using the Platform, including HeyBO and BOimages, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you represent a business, you warrant that you have the authority to bind that entity to these Terms.
        `
    },
    {
        title: "4. Changes to Terms",
        html: `BOtag reserves the right to modify these Terms at any time. Any changes will be effective upon posting on the Platform. Continued use of the Platform constitutes acceptance of the revised Terms.
        `
    },
    {
        title: "5. User Accounts & Registration",
        html: `Users (both shoppers and merchants) are responsible for maintaining the security of their account information. Users must provide accurate and current information during registration and update it when necessary. BOtag reserves the right to suspend or terminate accounts for any violation of these Terms or any suspicious activities.
        `
    },
    {
        title: "6. Product Availability & Pricing",
        html: `Product availability and pricing displayed through BOimages or within the HeyBO experience are based on real-time updates from merchants’ POS systems, including integrations like Front Systems. These prices and availability may fluctuate. Orders are only confirmed once the merchant processes the request. In cases where a product is unavailable after an order is placed, BOtag will attempt to find an alternative store through its SOS feature and notify the shopper accordingly.
        `
    },
    {
        title: "7. Payment and Fees (Stripe Integration)",
        html: `Payments for all orders on BOtag are processed through Stripe, a third-party payment provider. Once a shopper completes a purchase, the payment is handled entirely by Stripe. BOtag is not responsible for any delays, errors, or security issues arising from Stripe's payment processing.
               <br /><br />
               BOtag may charge a commission on sales, which is deducted from the payment before funds are released to the merchant. By using the Platform, you agree to abide by Stripe’s payment terms and conditions, which may include additional fees depending on your payment method and location (<a target="_blank" href="https://stripe.com/legal/payment-terms">Stripe</a>).
        `
    },
    {
        title: "8. Returns, Refunds, and Cancellations",
        html: `BOtag is the seller of record for all transactions made through HeyBO. As such, BOtag is responsible for managing returns, refunds, and cancellations.<br />
        <ul class='terms-text-ul'>
            <li><b>Return Process: </b>If a shopper wishes to return an item, they must notify BOtag through the HeyBO app. Upon receiving the return request, BOtag will validate the purchase and instruct the shopper to send or bring the item to the store from which it was originally fulfilled for inspection.</li>
            <li><b>Store Assistance: </b>The store acts as BOtag’s assistant, confirming whether the item can be accepted as a return through their POS system. Once the store verifies the return, BOtag will process the refund directly to the shopper’s original payment method.</li>
            <li><b>Refunds: </b>BOtag will issue refunds once the return has been validated by the store. Any shipping costs associated with returns may be deducted from the refund amount.</li>
            <li><b>Cancellations: </b>Shoppers may cancel orders before the store has begun processing the order. If the store has already processed the order, the shopper may need to follow the return process outlined above.</li>
        </ul>
        `
    },
    {
        title: "9. Intellectual Property",
        html: `BOtag and its licensors own all rights, titles, and interests in the Platform, HeyBO, BOimages, and its content. Users are granted a limited, non-exclusive license to use the Platform for its intended purposes. Merchants and shoppers may not copy, modify, or distribute content from the Platform without express permission from BOtag.
        `
    },
    {
        title: "10. User Conduct",
        html: `Users are expected to adhere to all laws and regulations while using the Platform, including HeyBO. Merchants are required to provide accurate product listings and comply with applicable commerce laws. Fraudulent activities, misleading product descriptions, or attempts to manipulate BOtag’s systems will result in account suspension or termination.
        `
    },
    {
        title: "11. Privacy and Data Protection",
        html: `The collection, use, and protection of personal data are governed by BOtag’s Privacy Policy. BOtag is committed to ensuring that Users’ personal data is handled responsibly and in compliance with applicable data protection laws.
        `
    },
    {
        title: "12. Liability & Disclaimers",
        html: `BOtag provides the Platform, HeyBO, and BOimages on an "as is" and "as available" basis. While BOtag makes efforts to ensure accurate and reliable information, it makes no warranties regarding the accuracy, availability, or reliability of the Platform or the products sold. BOtag is not liable for any indirect, incidental, or consequential damages arising from the use of the Platform. BOtag is not liable for any issues arising from Stripe's payment services.
        `
    },
    {
        title: "13. Indemnification",
        html: `Users agree to indemnify and hold BOtag harmless from any claims, damages, or losses arising from their use of the Platform or their violation of these Terms. Merchants are responsible for ensuring compliance with applicable laws in their operations.
        `
    },
    {
        title: "14. Termination and Suspension",
        html: `BOtag reserves the right to suspend or terminate any User's account for violating these Terms or engaging in any activity that harms the Platform or its Users. BOtag will provide notice when possible, but in cases of serious violations, immediate termination may occur.
        `
    },
    {
        title: "15. Dispute Resolution",
        html: `In the event of any dispute arising from the use of the Platform, BOtag will seek to resolve the issue through good-faith negotiations. If a resolution cannot be reached, disputes will be resolved through arbitration, governed by the laws of the Netherlands.
        `
    },
    {
        title: "16. Governing Law",
        html: `These Terms are governed by and construed in accordance with the laws of the Netherlands. Any legal disputes will be subject to the exclusive jurisdiction of the courts in the Netherlands.
        `
    },
    {
        title: "17. Modifications to the Platform",
        html: `BOtag reserves the right to modify or discontinue any aspect of the Platform, HeyBO, or BOimages without notice. Users’ continued use of the Platform after any changes will be considered acceptance of those changes.
        `
    }
];

/* #endregion */
