import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import InputInfo from "../productsComponents/InputInfo";
import FormField from "../../common/forms/FormField";
import * as Library from "../../../utils/Library";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import ListCardComponentStaticData from "../../common/lists/ListCardComponentStaticData";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
import moment from "moment";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    & {
        width: 100%;
    }

    .ListComponent {
        max-height: 500px !important;
    }
`;

const StyledPageHeader = styled.div`
    &.page-add-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 24px;
        width: 100%;
        gap: 50px;
    }

    .header-left-content {
        display: flex;
        flex: 1;
        gap: 10px;
    }
    .header-middle-content {
        align-items: center;
        display: flex;
        flex: 1;
        gap: 10px;
        justify-content: center;
    }
    .header-right-content {
        display: flex;
        flex: 1;
        gap: 10px;
        justify-content: flex-end;
    }
`;
/* #endregion */

export default function FFCImporter(props) {
    document.title = `${APP_TITLE} - FFC Importer`;
    const { accessesList } = props;

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const chunkSize = queryParams.get("chunks") ? parseInt(queryParams.get("chunks")) : 50;

    /* #region VARS */
    const navigate = useNavigate();
    const OutletContext = useOutletContext();
    const fileInputRef = useRef();

    const listQueryKey = "FFCImporter";
    const [inputKey, setInputKey] = useState(Date.now());
    const [selectedFile, setSelectedFile] = useState(null);
    const [isErrorInvalidFormat, setIsErrorInvalidFormat] = useState(false);
    const [isImportDisabled, setIsImportDisabled] = useState(true);
    //const [labels, setLabels] = useState(null);
    const [newFile, setNewFile] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [productList, setProductList] = useState([]);
    const [isDialogImportOpen, setIsDialogImportOpen] = useState(false);
    const [isImportingLoading, setIsImportingLoading] = useState(false);
    /* #endregion */

    /* #region LIST DATA */
    const listColumns = [
        {
            name: "LabelBrandID; ",
            selector: row => row?.LabelBrandID ?? "--"
        },
        {
            name: "StoreID",
            selector: row => row?.StoreID ?? "--"
        }
    ];
    /* #endregion */

    /* #region METHODS */
    const handleUploadFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    const mapImportData = () => {
        const mappedData = productList.map(item => {
            return {
                labelBrandOurID: item["LabelBrandID"] ?? "",
                storeOurID: item["StoreID"] ?? ""
            };
        });

        return mappedData;
    };

    const handleImport = async () => {
        setIsDialogImportOpen(false);
        if (isImportDisabled || productList.length === 0) return;

        const mappedData = mapImportData();

        const chunkedData = chunkArray(mappedData, chunkSize);

        try {
            setIsImportingLoading(true);
            const resData = [];

            for (const chunk of chunkedData) {
                const res = await Library.makeCommonPostRequest("Scripts", "importFFCs", chunk);

                if (res && res.data?.status === 200) {
                    let data = res.data.data;
                    //if data is "array" then data = data[0]
                    if (Array.isArray(data)) data = data[0];

                    resData.push(data);
                } else {
                    console.log("Error importing FFCs", res);
                    throw new Error("There was an error importing FFCs!");
                }
            }

            processImportResponse(resData);
        } catch (error) {
            Library.showToastMessage({ type: "error", title: "An error occurred during the import process." });
        } finally {
            setIsImportingLoading(false);
        }
    };

    const processImportResponse = resData => {
        let FFCErrors = 0,
            totalFFCErrors = 0,
            totalFFCsCreated = 0,
            totalFFCsUpdated = 0;

        for (const data of resData) {
            FFCErrors += data?.FFCErrors;
            totalFFCErrors += data?.totalFFCErrors;
            totalFFCsCreated += data?.totalFFCsCreated;
            totalFFCsUpdated += data?.totalFFCsUpdated;
        }

        if (totalFFCErrors > 0) {
            console.log("FFC Errors Log:", FFCErrors);
            Library.showToastMessage({
                type: "error",
                title: "Error importing FFCs!",
                message: `There were ${totalFFCErrors} FFC errors, and ${totalFFCsCreated} FFCs created, and ${totalFFCsUpdated} FFCs updated.`
            });
        } else {
            setProductList([]);
            setSelectedFile(null);
            Library.showToastMessage({
                type: "success",
                title: "FFCs imported successfully!",
                message: `Total FFCs created: ${totalFFCsCreated}, and total FFCs updated: ${totalFFCsUpdated}.`
            });
        }
    };

    const checkIfFileIsSelected = event => {
        if (selectedFile) {
            setNewFile(event);
            setIsDialogOpen(true);
        } else {
            handleFileChange(event);
        }

        setInputKey(Date.now());
    };

    const handleFileChange = event => {
        const file = event.target.files[0];
        if (file) {
            const fileExtension = file.name.split(".").pop().toLowerCase();
            if (fileExtension === "csv" || fileExtension === "xls" || fileExtension === "xlsx") {
                setSelectedFile(file.name);
                extractHeaders(file, fileExtension);
                setIsErrorInvalidFormat(false);
            } else {
                setSelectedFile(file.name);
                Library.showToastMessage({
                    type: "error",
                    title: "Invalid file format!",
                    message: "Please upload a .csv or .xlsx file."
                });
                setIsErrorInvalidFormat(true);
                //setLabels(null);
            }
        }
        setInputKey(Date.now());
    };

    const extractHeaders = (file, fileExtension) => {
        const reader = new FileReader();
        reader.onload = e => {
            const data = e.target.result;

            if (fileExtension === "csv") {
                Papa.parse(data, {
                    header: true,
                    skipEmptyLines: true,
                    complete: results => {
                        const detectedHeaders = results.meta.fields;
                        const detectedData = results.data;

                        //setLabels(detectedHeaders);
                        setProductList(detectedData);

                        if (detectedHeaders.length > 0) {
                            setIsImportDisabled(false);
                        } else {
                            setIsImportDisabled(true);
                        }
                    }
                });
            } else if (fileExtension === "xlsx" || fileExtension === "xls") {
                const workbook = XLSX.read(data, { type: "binary", cellDates: true, cellNF: false, cellText: false });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];

                const detectedData = XLSX.utils.sheet_to_json(worksheet, {
                    header: 1,
                    defval: "",
                    blankrows: false,
                    dateNF: "HH:mm"
                });

                const detectedHeaders = detectedData[0];
                const dataWithoutHeaders = detectedData.slice(1);

                const structuredData = dataWithoutHeaders.map(row => {
                    const product = {};
                    detectedHeaders.forEach((header, index) => {
                        if (header.includes("Open hours") && !header.includes("closedAllDay")) {
                            //read the hours as string instead of number that is being retrieved from the excel
                            const cellValue = row[index];
                            if (!cellValue) {
                                product[header] = "";
                            } else {
                                const parsedDate = new Date(cellValue);
                                const dateStr = moment(parsedDate)?.format("HH:mm");
                                product[header] = dateStr;
                            }
                        } else product[header] = row[index] !== undefined ? row[index] : "";
                    });
                    return product;
                });

                //setLabels(detectedHeaders);
                //console.log("structuredData", structuredData);
                setProductList(structuredData);

                if (detectedHeaders.length > 0) {
                    setIsImportDisabled(false);
                } else {
                    setIsImportDisabled(true);
                }
            } else {
                setIsErrorInvalidFormat(true);
                Library.showToastMessage({
                    type: "error",
                    title: "Invalid file format!",
                    message: "Please upload a .csv or .xlsx file."
                });
                setIsImportDisabled(true);
            }
        };

        if (fileExtension === "csv") {
            reader.readAsText(file);
        } else {
            reader.readAsBinaryString(file);
        }
    };

    const handleConfirmReplaceFile = () => {
        handleFileChange(newFile);
        setNewFile(null);
        setIsDialogOpen(false);
    };

    const handleCancelReplaceFile = () => {
        setNewFile(null);
        setIsDialogOpen(false);
    };

    const importOnClick = () => {
        if (isImportDisabled) return;

        setIsDialogImportOpen(true);
    };

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        const headerProps = {
            selectedFile,
            isErrorInvalidFormat,
            isImportDisabled,
            importOnClick,
            handleUploadFileClick,
            fileInputRef
        };
        setupHeaders(OutletContext, navigate, headerProps, mapImportData);
    }, [selectedFile, isErrorInvalidFormat, isImportDisabled]);

    useEffect(() => {
        //accessesList is an array of emails that have access to this page
        const userEmail = localStorage.getItem("userEmail");

        if (!accessesList.includes(userEmail)) {
            navigate("/404");
            return;
        }
    }, [accessesList]);
    /* #endregion */

    return (
        <>
            <FullScreenLoader isLoading={isImportingLoading} />

            <FullScreenOverlay show={isDialogImportOpen}>
                <DialogBoxComponent
                    loading={isImportingLoading}
                    loadingHeading="IMPORTING..."
                    heading="JUST CHECKING.."
                    title="Ready to import?"
                    onClickYes={handleImport}
                    onClickNo={() => setIsDialogImportOpen(false)}
                    cancelText="NO"
                    confirmText="YES"
                    message={"Are you sure you want to import?"}
                    headingColor="var(--text-icons-green-500)"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={isDialogOpen}>
                <DialogBoxComponent
                    heading="ATTENTION"
                    title="Replacing file?"
                    onClickYes={handleConfirmReplaceFile}
                    onClickNo={handleCancelReplaceFile}
                    confirmText="CONFIRM"
                    cancelText="CANCEL"
                    message="You are about to replace the uploaded file by a new one. You will lose everything you’ve done so far."
                    headingColor="var(--text-icons-orange-500)"
                />
            </FullScreenOverlay>

            <StyledPage>
                <input
                    key={inputKey}
                    ref={fileInputRef}
                    type="file"
                    style={{ display: "none" }}
                    onChange={checkIfFileIsSelected}
                />

                <div className="flex mb-16px">
                    <label className="text-white text-lg ml-16px">Total FFCs: {productList.length}</label>
                </div>

                <ListCardComponentStaticData
                    title="FFCs to import"
                    columns={listColumns}
                    listData={productList}
                    pageLimit={20}
                    currentPage={1}
                    queryKey={listQueryKey}
                />
            </StyledPage>
        </>
    );
}

/* #region SMALL COMPONENTES AND METHODS */
function setupHeaders(OutletContext, navigate, headerProps, mapImportData) {
    if (!localStorage.getItem("token")) {
        navigate("/login");
    }

    if (!OutletContext) return;
    const { MainLayoutProps, layoutType } = OutletContext;

    if (!MainLayoutProps) return;

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    props.setControlBarLeftBtns([]);
    props.setControlBarCenterBtns([{ to: "", active: true, disabled: false, title: "FFCs Importer", icon: "plus" }]);
    props.setControlBarRightBtns([]);

    const { selectedFile, isErrorInvalidFormat, isImportDisabled, importOnClick, handleUploadFileClick } = headerProps;

    props.setFilterHeaderProperties({
        customContentHeader: (
            <PageHeader
                selectedFile={selectedFile}
                isErrorInvalidFormat={isErrorInvalidFormat}
                isImportDisabled={isImportDisabled}
                importOnClick={importOnClick}
                handleUploadFileClick={handleUploadFileClick}
                mapImportData={mapImportData}
            />
        )
    });
}

const PageHeader = props => {
    const {
        selectedFile,
        isErrorInvalidFormat,
        isImportDisabled,
        importOnClick,
        handleUploadFileClick,
        mapImportData
    } = props;

    return (
        <StyledPageHeader className="page-add-header-container">
            <div className="header-left-content">
                <FormField
                    style={{
                        maxWidth: "380px",
                        width: "100%",
                        visibility: "hidden"
                    }}
                    styling={{ backgroundColor: "#1E2631" }}
                />
            </div>
            <div className="header-middle-content">
                <InputInfo onClick={handleUploadFileClick} value={selectedFile || ""} isError={isErrorInvalidFormat} />
                <BtnLargeSolid text="UPLOAD FILE" style={{ width: "110px" }} onClick={handleUploadFileClick} />
            </div>
            <div className="header-right-content">
                <BtnLargeSolid
                    text="LOG DATA"
                    style={{ width: "110px" }}
                    onClick={() => console.log("Mapped Data", mapImportData())}
                />

                <BtnLargeSolid
                    text="IMPORT"
                    disabled={isImportDisabled}
                    style={{ width: "110px" }}
                    onClick={importOnClick}
                />
            </div>
        </StyledPageHeader>
    );
};
/* #endregion */
