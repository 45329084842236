import FullfillmentCentersPage from "../components/pages/fullfillmentCenters/FullfillmentCentersPage";
import FFCManagement from "../components/pages/fullfillmentCenters/FFCManagement";

const routes = [
    {
        path: "/ffcenters",
        element: <FullfillmentCentersPage />
    },
    {
        path: "/ffcenters/management",
        element: <FFCManagement />
    }
];

export default routes;
