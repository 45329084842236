import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useNavigationType } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import ChevronLeft from "@untitled-ui/icons-react/build/esm/ChevronLeft";
import ChevronRight from "@untitled-ui/icons-react/build/esm/ChevronRight";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import { ReactComponent as NotificationBell } from "../../../assets/icons/notification-alert.svg";
import Bell02 from "@untitled-ui/icons-react/build/esm/Bell02";
import Search from "@untitled-ui/icons-react/build/esm/SearchSm";
import Expand06 from "@untitled-ui/icons-react/build/esm/Expand06";
import Power01 from "@untitled-ui/icons-react/build/esm/Power01";
import UserCircle from "@untitled-ui/icons-react/build/esm/UserCircle";
import NotificationsDropdown from "./NotificationsDropdown";
import * as Library from "../../../utils/Library";
import moment from "moment/moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import iconSuccess from "../../../assets/images/icons8-check-64.png";
import DialogBoxComponent from "../dialogs/DialogBoxComponent";
import FullScreenOverlay from "../overlay/FullScreenOverlay";
import AppContext from "../../../AppContext";
import LayoutContext from "../../../LayoutContext";
import { Drawer } from "@mui/material";
import NotificationPanel from "./NotificationPanel";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    /* #region  MEDIA RESPONSIVENESS */
    @media (min-width: 768px) {
        .main-header {
        }
    }

    @media only screen and (max-width: 1200px) {
        .main-header .center-btns {
            display: none;
            flex: 0;
        }

        .main-header .left-btns {
            flex-basis: initial !important;
            min-width: 120px;
        }
    }

    @media only screen and (max-width: 1200px) {
        .content-wrapper,
        .controlbar,
        .main-header.navbar.navbar-expand {
            //margin-left: 0;
        }
    }
    /* #endregion */

    .left-btns,
    .center-btns,
    .right-btns {
        display: flex;
        flex-grow: 1;
        flex-basis: 100%;
        //overflow: hidden;
    }

    /* #region  MAIN-HEADER */
    .main-header.navbar.navbar-expand {
        align-items: center;
        background: var(--backgrounds-lines-n-600);
        border: 0;
        border-radius: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin: 0;
        z-index: 2;
        overflow: hidden;
        height: 70px;
    }

    .main-header .btn.btn-success:hover,
    .left-btns ul li a:hover {
        border-color: var(--App-Accent-Outline);
    }
    /* #endregion */

    /* #region  GROUP SELLECTION AND PREVIOUS NEXT BUTTONS */
    .left-btns {
        padding-left: 24px;
        align-items: center;
        height: 70px;
        justify-content: flex-start;
    }

    .left-btns ul {
        margin: 0;
        padding: 0;
    }

    .left-btns ul li {
        display: inline-block;
        list-style-type: none;
    }

    .left-btns ul li a {
        align-items: center;
        background: var(--Buttons-All-Platforms-Gray-300);
        border: 1px solid transparent;
        border-radius: 4px;
        box-sizing: border-box;
        color: var(--text-icons-gray-100);
        display: flex;
        font-size: 15px;
        height: 37px;
        justify-content: center;
        line-height: 37px;
        padding: 0;
        text-align: center;
        text-decoration: none;
        width: 40px;
    }

    .left-btns ul li a.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    /* #endregion */

    /* #region  HEADER BAR CENTER BUTTONS */
    .main-header .center-btns {
        height: 70px;
    }
    .center-btns {
        align-items: center;
        height: 62px;
        justify-content: center;
    }

    .main-header .center-btns .btn.btn-success {
        height: 35px;
        min-width: 120px;
        padding: 0;
    }
    .center-btns .btn.btn-success {
        height: 35px;
        line-height: 35px;
        padding-left: 0;
        padding-right: 0;
        width: 120px;
    }

    .main-header .btn.btn-success {
        align-items: center;
        color: var(--text-icons-gray-100);
        display: flex;
        font-family: "Roboto";
        font-size: 12px;
        justify-content: center;
        margin: 0 5px;
        text-transform: uppercase;
    }

    .main-header .btn.btn-success {
        background-color: var(--backgrounds-lines-n-400);
        border-color: var(--backgrounds-lines-n-400);
    }
    /* #endregion */

    /* #region  RIGHT PROFILE NAVBAR  */
    .main-header .navbar-nav {
        align-items: center;
        float: right;
        height: 70px;
        justify-content: flex-end;
        list-style: none;
        padding-right: 24px;
        padding-left: 0px;
    }

    .right-btns .nav-item {
        margin: 0 0 0 8px;
    }

    .right-btns .icon {
        width: 40px;
        height: 37px;
    }

    .main-header .nav-item.dropdown a {
        text-decoration: none;
    }

    .nav-item.dropdown.profile-container a {
        align-items: center;
    }

    .nav-item .nav-link {
        color: var(--text-icons-gray-100);
        display: block;
        font-size: 16px;
    }

    .nav-item.notification-bell {
        flex-shrink: 0;
    }

    .nav-item.notification-bell > a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav-item.dropdown.profile-container a {
        display: flex;
        text-decoration: none;
    }

    .profile-text {
        display: grid;
        margin-right: 10px;
        height: 33px;
    }

    .profile-text > span {
        color: var(--text-icons-gray-100);
        display: block;
        font-family: "Roboto";
        line-height: 18px;
        text-align: right;
        text-transform: capitalize;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
    }

    .profile-text .username {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        height: 20px;
    }

    .profile-picture-container {
        display: flex;
    }

    .profile-picture-container > svg > path {
        stroke-width: 0.5;
    }

    .profile-picture {
        border-radius: 50%;
        height: 35px;
        width: 35px;
        object-fit: cover;
    }

    .profile-container {
        margin: 0px 8px !important;
    }

    .profile-container .dropdown-icon {
        margin-left: 10px;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
    }

    .user-designation {
        font-size: 12px;
        font-weight: 300;
    }

    .nav-link i {
        font-size: 17px;
    }

    .nav-link.btn.btn-success {
        align-items: center;
        color: var(--text-icons-gray-100);
        display: flex;
        font-size: 16px;
        height: 37px;
        justify-content: center;
        margin: 0;
        padding: 6px 12px;
        width: 40px;
    }

    .right-btns .nav-item.logout-btn {
        margin-right: 0;
    }

    .nav-item.logout-btn .nav-link {
        color: var(--text-icons-green-500);
        margin-right: 0;
    }

    /* #endregion */

    /* #region  NOTIFICATIONS DROPDOWN */
    .notifications-dropdown {
        position: relative;
        top: 0%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.4s ease, visibility 0.4s ease;
        z-index: 99999;

        position: absolute;
        top: 58px;
        right: 178px;
        z-index: 9999999;
    }

    .notifications-dropdown.show {
        opacity: 1;
        visibility: visible;
    }
    /* #endregion */
`;
/* #endregion */

/** Header component for the main application.
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.className - The CSS class name for the component.
 * @returns {JSX.Element} The JSX element representing the header.
 */
function Header(props) {
    /* #region VARS */
    useRef();
    const navigate = useNavigate();

    const [showNotificationDropdown, setShowNotificationDropdown] = useState(false);
    const [showNotificationPanel, setShowNotificationPanel] = useState(false);
    const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
    const [notificationItems, setNotificationItems] = useState([]);
    const { showLogoutPopup, setShowLogoutPopup } = useContext(LayoutContext);
    const { hasUnsavedChanges } = useContext(AppContext);
    const [groupedUnreadNotifications, setGroupedUnreadNotifications] = useState({});
    const [readNotifications, setReadNotifications] = useState([]);
    const hasSubscriptionPlan = ![null, undefined, "", "undefined", "null"].includes(
        localStorage.getItem("subscriptionPlanID")
    );
    const [hasNextPage, setHasNextPage] = useState(false);
    const hasOrganizationName = ![null, undefined, "", "undefined", "null"].includes(
        localStorage.getItem("currentOrganizationName")
    );
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        const handleClickOutside = event => {
            if (showNotificationDropdown && event.target.closest(".notification-bell")) {
            } else if (showNotificationDropdown && !event.target.closest(".notifications-dropdown")) {
                setShowNotificationDropdown(false);
                if (hasNewNotifications()) {
                    setAllSentNotificationsAsSeen();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showNotificationDropdown]);

    useEffect(() => {
        const interval = setInterval(() => {
            getHeaderNotifications();
        }, 60000);

        getHeaderNotifications();

        return () => {
            clearInterval(interval);
        };
    }, []);

    /* #endregion */

    /* #region METHODS */
    const getHeaderNotifications = (status = ["New"], notificationType = "", limit = 5, page = 1) => {
        const BOstagesAccountID = localStorage.getItem("token");
        Library.makeCommonPostRequest(
            "Global",
            "getNotificationsData",
            {
                BOstagesAccountID,
                platform: "BOstageB",
                status: status,
                page: page,
                limit: limit
            },
            false,
            setIsLoadingNotifications
        ).then(res => {
            const { status, message } = res?.data;
            if (status === 200) {
                const { notifications, hasNextPage } = message;
                setHasNextPage(hasNextPage);
                const result = notifications ?? [];
                const notificationsResult = result
                    ?.map((item, index) => {
                        let title = "You have a new notification";
                        if (item?.type === "InviteRetailerFromRetailBrand") {
                            if (item?.subType === "New") {
                                title = `${item?.retailBrandInfo?.name} sent a request to your brand ${item?.labelBrandInfo?.name}`;
                            } else if (item?.subType === "Updated" || item?.subType === "Update") {
                                title = `${item?.retailBrandInfo?.name} request to your brand ${item?.labelBrandInfo?.name} has updated to: ${item?.inviteInfo?.status?.status}`;
                            }
                        } else if (item?.type === "InviteRetailerFromLabelBrand") {
                            if (item?.subType === "Updated" || item?.subType === "Update") {
                                title = `Your invite to ${item?.retailBrandInfo?.name} from your brand ${item?.labelBrandInfo?.name} has updated to: ${item?.inviteInfo?.status?.status}`;
                            }
                        } else if (item?.type === "FFC") {
                            if (item?.subType === "New") {
                                title = `${item?.storeInfo?.name} activated a fulfillment center with your brand ${item?.labelBrandInfo?.name}`;
                            } else if (item?.subType === "Updated" || item?.subType === "Update") {
                                title = `${item?.storeInfo?.name} fulfillment center to your brand ${item?.labelBrandInfo?.name} has updated to: ${item?.ffcInfo?.status}`;
                            }
                        }
                        const createdAt = moment(item?.createdAt);
                        const now = moment();
                        const isToday = createdAt.isSame(now, "day");

                        const getCustomTimeFormat = () => {
                            const seconds = now.diff(createdAt, "seconds");
                            const minutes = now.diff(createdAt, "minutes");
                            const hours = now.diff(createdAt, "hours");

                            const formatValue = value => (value < 10 ? `0${value}` : value);

                            if (seconds < 60) return `${formatValue(seconds)}s`;
                            if (minutes < 60) return `${formatValue(minutes)}m`;
                            return `${formatValue(hours)}h`;
                        };

                        return {
                            id: item?._id,
                            title: title,
                            description: isToday
                                ? getCustomTimeFormat() // e.g., "5m", "1hr", "23hrs"
                                : createdAt.isSame(moment().subtract(1, "day"), "day")
                                ? "Yesterday"
                                : createdAt.format("DD-MM-YY"), // e.g., "12-03-23"
                            label: isToday ? "Today" : "Before",
                            status: item?.status ?? "unknown",
                            type: item?.type ?? "unknown",
                            createdAt: item?.createdAt,
                            data: item
                        };
                    })
                    .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))); // Sort by createdAt descending
                const grouped = notificationsResult.reduce((acc, notification) => {
                    const { label } = notification;
                    if (!acc[label]) {
                        acc[label] = [];
                    }
                    acc[label].push(notification);
                    return acc;
                }, {});

                if (notificationType === "") {
                    setNotificationItems(notificationsResult);
                }

                if (notificationType === "Unread") {
                    setGroupedUnreadNotifications(prev => {
                        const updated = { ...prev };
                        Object.keys(grouped).forEach(label => {
                            updated[label] = [...(updated[label] || []), ...grouped[label]];
                        });
                        return updated;
                    });
                }

                if (notificationType === "Read") {
                    setReadNotifications(prev => [...prev, ...Object.values(grouped).flat()]);
                }
            }
        });
    };

    const getUnreadNotifications = (page = 1, limit = 20) => {
        if (page === 1) {
            setGroupedUnreadNotifications({});
        }

        getHeaderNotifications(["New", "Sent"], "Unread", limit, page);
    };

    const getReadNotifications = (page = 1, limit = 20) => {
        if (page === 1) {
            setReadNotifications([]);
        }

        getHeaderNotifications(["Seen"], "Read", limit, page);
    };

    const setAllSentNotificationsAsSeen = () => {
        const notificationIDs = notificationItems.filter(item => item.status === "New").map(item => item.id);

        Library.makeCommonPostRequest(
            "Global",
            "updateNotificationsStatus",
            {
                notificationIDs,
                status: "Sent"
            },
            false,
            setIsLoadingNotifications
        ).then(res => {
            const { status } = res?.data;
            if (status === 200) {
                getUnreadNotifications(1, 5);
            }
        });
    };

    const hasNewNotifications = () => {
        return notificationItems.filter(item => item.status === "New" || item.status === "Sent").length > 0;
    };

    const toogleFullscreen = e => {
        e.preventDefault();
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen();
        }
    };

    const handleLogoutBtnClick = () => {
        setShowLogoutPopup(true);
    };

    //TODO: CHECK HOW TO CLEAR REDUX VARIABLE
    const clearCacheData = () => {
        caches.keys().then(names => {
            names.forEach(name => {
                caches.delete(name);
            });
        });
    };
    /* #endregion */

    /* #region HANDLE BACK AND FORWARD BUTTONS */
    const [historyStack, setHistoryStack] = useState([]);
    const { pathname } = useLocation();
    const type = useNavigationType();
    //console.log(historyStack);

    useEffect(() => {
        if (type === "POP") {
            setHistoryStack(prev => prev.slice(0, prev.length - 1));
        } else if (type === "PUSH") {
            setHistoryStack(prev => [...prev, pathname]);
        } else {
            setHistoryStack(prev => [...prev.slice(0, prev.length - 1), pathname]);
        }
    }, [pathname, type]);

    // VERIFIES IF THE PREVIOUS PATH IS WHITIN APP AND IS NOT "/": THAT IS THE ROUTE PATH FOR THE LOGIN PAGE
    const canGoBack =
        window.history.state?.idx !== 0 &&
        (historyStack.length === 1 || (historyStack.length > 1 && historyStack[historyStack.length - 2] !== "/"));
    const canGoForward = window.history.state?.idx < window.history.length - 2;

    function goBack() {
        if (canGoBack) window.history.back();
    }

    function goForward() {
        if (canGoForward) window.history.forward();
    }
    /* #endregion */

    /* #region HANDLE LOGOUT TIMEOUT */
    let timer = 10 * 60 * 1000;

    //INCREASE TIMER TO LOGOUT FOR LOCALHOST
    const isLocalhost = Library.isLocalhost("Logout Timer");
    if (isLocalhost) timer = 1000 * 60 * 1000;

    let logoutTimer;

    const resetLogoutTimer = () => {
        clearTimeout(logoutTimer);
        timer = 10 * 60 * 1000;

        //INCREASE TIMER TO LOGOUT FOR LOCALHOST
        const isLocalhost = Library.isLocalhost("Logout Timer");
        if (isLocalhost) timer = 1000 * 60 * 1000;

        logoutTimer = setTimeout(logout, timer);
    };

    const logout = () => {
        setShowLogoutPopup(false);
        clearCacheData();
        //props.hideHeader();
        localStorage.clear();
        navigate("/login");
    };

    document.addEventListener("mousemove", resetLogoutTimer);
    document.addEventListener("keypress", resetLogoutTimer);
    document.addEventListener("touchstart", resetLogoutTimer);

    // Initial timer start
    resetLogoutTimer();
    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <FullScreenOverlay show={showLogoutPopup && !hasUnsavedChanges}>
                <DialogBoxComponent
                    headingColor="#00AEEF"
                    title="Ready To Logout"
                    message="Are you sure want to logout?"
                    onClickYes={() => {
                        setShowLogoutPopup(false);
                        clearCacheData();
                        localStorage.clear();
                        navigate("/login");
                    }}
                    onClickNo={() => {
                        setShowLogoutPopup(false);
                    }}
                />
            </FullScreenOverlay>

            <nav className="main-header navbar navbar-expand ">
                <div className="left-btns">
                    <ul>
                        <li style={{ marginRight: "6px" }}>
                            <Link
                                to="#"
                                className={!canGoBack ? "disabled" : "enabled"}
                                onClick={e => (canGoBack ? goBack() : e.preventDefault())}>
                                <ChevronLeft className="text-default" height="16px" />
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="#"
                                className={!canGoForward ? "disabled" : "enabled"}
                                onClick={e => (canGoForward ? goForward() : e.preventDefault())}>
                                <ChevronRight className="text-default" height="16px" />
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="center-btns">
                    {props?.centerBtns?.map(btn => {
                        const key = uuidv4();
                        return (
                            <Link
                                key={key}
                                to={
                                    hasSubscriptionPlan && hasOrganizationName && !btn?.disabled && btn?.to
                                        ? btn.to
                                        : ""
                                }
                                className={
                                    btn?.disabled || !hasSubscriptionPlan || !hasOrganizationName
                                        ? "btn btn-success button-not-active"
                                        : "btn btn-success button-active"
                                }
                                onClick={
                                    btn?.disabled || !hasSubscriptionPlan || !hasOrganizationName
                                        ? e => {
                                              e.preventDefault();
                                          }
                                        : undefined
                                }>
                                {btn?.title}
                            </Link>
                        );
                    })}
                </div>

                <div className="right-btns navbar-nav ml-auto">
                    <div className="nav-item dropdown profile-container">
                        <Link
                            className="nav-link"
                            data-toggle="dropdown"
                            to="#"
                            onClick={e => e.preventDefault()}
                            aria-expanded="true">
                            <div className="profile-text hover-not-allowed">
                                <span className="username">{localStorage.getItem("userName")}</span>
                                <span className="user-designation">{localStorage.getItem("currentProfileRole")}</span>
                            </div>
                            <div
                                className="profile-picture-container hover-not-allowed"
                                onClick={e => e.preventDefault()}>
                                {localStorage.getItem("currentProfilePhoto") ? (
                                    <img
                                        className="profile-picture"
                                        src={localStorage.getItem("currentProfilePhoto")}
                                        alt="Profile"
                                    />
                                ) : (
                                    <UserCircle className="text-inactive" height="35px" width="35px" />
                                )}
                            </div>
                            <div className="dropdown-icon text-gray-300 hover-not-allowed">
                                <ChevronDown height="16px" width="16px" />
                            </div>
                        </Link>
                    </div>
                    <div
                        className="nav-item icon notification-bell"
                        onClick={() => setShowNotificationDropdown(!showNotificationDropdown)}>
                        <Link className="nav-link icon" to="#" onClick={e => e.preventDefault()} aria-expanded="true">
                            {notificationItems.filter(item => item.status === "New" || item.status === "Sent").length >
                            0 ? (
                                <NotificationBell className="text-default" height="20px" />
                            ) : (
                                <Bell02 className="text-gray-100" height="16px" />
                            )}
                        </Link>
                    </div>
                    <div className="nav-item icon">
                        <Link
                            className="nav-link btn btn-success button-not-active"
                            data-widget="search"
                            to="#"
                            onClick={e => e.preventDefault()}
                            role="button">
                            <Search className="text-inactive" height="16px" />
                        </Link>
                    </div>
                    <div className="nav-item icon">
                        <Link
                            id=""
                            className="nav-link btn btn-success"
                            to="#"
                            onClick={toogleFullscreen}
                            role="button">
                            <Expand06 className="text-default" height="16px" />
                        </Link>
                    </div>
                    <div className="nav-item icon logout-btn">
                        <Link
                            className="nav-link btn btn-success"
                            data-widget="logout"
                            to="#"
                            onClick={handleLogoutBtnClick}
                            role="button">
                            <Power01 className="text-green-500" height="16px" />
                        </Link>
                    </div>
                </div>
            </nav>

            <div className={`notifications-dropdown ${showNotificationDropdown ? "show" : ""}`}>
                <NotificationsDropdown
                    className=""
                    NotificationItems={notificationItems}
                    isLoadingNotifications={isLoadingNotifications}
                    setShowNotificationPanel={setShowNotificationPanel}
                    setShowNotificationDropdown={setShowNotificationDropdown}
                    getUnreadNotifications={getUnreadNotifications}
                />
            </div>

            <Drawer
                anchor="right"
                open={showNotificationPanel}
                style={{ zIndex: 9999 }}
                onClose={() => setShowNotificationPanel(false)}>
                <NotificationPanel
                    setShowNotificationPanel={setShowNotificationPanel}
                    groupedUnreadNotifications={groupedUnreadNotifications}
                    readNotifications={readNotifications}
                    getReadNotifications={getReadNotifications}
                    getUnreadNotifications={getUnreadNotifications}
                    hasNextPage={hasNextPage}
                />
            </Drawer>
        </StyledPage>
    );
}

export default Header;
