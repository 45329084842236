import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CardGridSkeleton from "../gridCards/animations/CardGridSkeleton";
import { v4 as uuidv4 } from "uuid";
import RetailerInvitationsGridCard from "../gridCards/RetailerInvitationsGridCard";
import * as Library from "../../../utils/Library";
import { maskEmail, maskName } from "../../../utils/valuesMasking";
import { formatDefaultDateTime } from "../../../utils/Formatter";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: scroll;
        height: 100%;
        margin-right: -24px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 17px;
    }
`;
/*  #endregion */

/**
 * Renders an infinite grid of brand cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.className] - The additional CSS class name for the component.
 * @param {number} [props.limit=10] - The maximum number of cards to load per page.
 * @param {string} [props.queryKey] - The query key for fetching data.
 * @param {number} [props.minimumCardsFill] - The minimum number of cards to fill the grid.
 * @param {number} [props.minColumnWidth] - The minimum width of each grid column.
 * @param {Function} [props.onEditAction] - The callback function for the edit action.
 * @param {Function} [props.onDeleteAction] - The callback function for the delete action.
 * @param {Function} [props.onSuspendAction] - The callback function for the suspend action.
 * @param {string} [props.statusFromPath] - The status from the path.
 * @returns {JSX.Element} The rendered component.
 */

export default function InfinityGridRetailersInvitationsCards(props) {
    const pageLimit = props?.limit || 10;

    const gridLayoutStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${props?.minColumnWidth || "395px"}, 1fr))`,
        gap: "24px"
    };

    /* #region STATES */
    const scrollContainerRef = useRef(null);
    const [scroll, setScroll] = useState(true);
    const [minimumCardsFill, setminimumCardsFill] = useState(1); //props?.minimumCardsFill || pageLimit
    const requestEnabled = props?.requestEnabled ?? true;

    const [queryKey, setQueryKey] = useState(props?.queryKey ?? uuidv4());

    useEffect(() => {
        if (props?.queryKey) setQueryKey(props?.queryKey);
    }, [props?.queryKey]);

    const filters = props?.filters;

    const {
        data,
        isFetching: isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = Library.usePostInfiniteQuery(
        queryKey,
        "getInvitedRetailers",
        {
            companyID: localStorage.getItem("currentCompanyID"),
            BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
            limit: pageLimit,
            ...filters
        },
        () => {
            setScroll(true);
        },
        false,
        requestEnabled
    );

    const pageList = data?.pages;
    //const totalCount = data?.pages[0]?.data?.totalCount;
    const nextDataCount = data?.pages[data?.pages?.length - 1].data?.nextDataCount;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        handleScroll();

        setminimumCardsFill(getTotalCards() === 0 ? 1 : getTotalCards());
    }, [data?.pages]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && !isLoading && scroll && !isFetchingNextPage) {
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [scrollContainerRef, isLoading, pageList, scroll, isFetchingNextPage, data?.pages]);
    /* #endregion */

    /* #region METHODS */
    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;

        const isAtBottom =
            Math.floor(scrollContainer.scrollHeight - scrollContainer.scrollTop) <= scrollContainer.clientHeight;

        if (isAtBottom && !isLoading && hasNextPage && !isFetchingNextPage) {
            setScroll(false);
            fetchNextPage();
        }
    };

    const getTotalCards = () => {
        return pageList?.reduce((acc, it) => {
            return acc + it?.data?.result?.length;
        }, 0);
    };

    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`} ref={scrollContainerRef}>
            {isLoading && !isFetchingNextPage ? (
                <div style={gridLayoutStyle}>
                    {Array.from({ length: 8 }, () => (
                        <CardGridSkeleton
                            key={uuidv4()}
                            height="420.28px"
                            minColumnWidth={props?.minColumnWidth || "395px"}
                        />
                    ))}
                </div>
            ) : (
                <div className="mb-24px" style={gridLayoutStyle}>
                    {pageList?.map(it => {
                        return it?.data?.result?.map(item => {
                            const status = item?.status;

                            const canResendInvite = status === "Invited" || status === "Invite-Resent";
                            const canRemoveInvite = ["Accepted", "Declined", "Voided"].includes(status);
                            const canVoidInvite = status === "Invited" || status === "Invite-Resent";

                            const contactEmail =
                                status !== "Accepted" && item?.retailBrand?._id
                                    ? maskEmail(item?.contactEmail)
                                    : item?.contactEmail;

                            const contactPerson =
                                status !== "Accepted" && item?.retailBrand?._id
                                    ? maskName(item?.contactFirstName)
                                    : item?.contactFirstName;

                            return (
                                <RetailerInvitationsGridCard
                                    key={item?._id}
                                    image={item?.retailBrand?.logos?.mainLogoURL || ""}
                                    imageOverlay={item?.labelBrand?.logos?.mainLogoURL || ""}
                                    title={item?.retailBrand?.name || "-"}
                                    titlePrefix="INVITED RETAILER"
                                    id={item?.ourID || "--"}
                                    idPrefix="BOTAG INVITE ID"
                                    isCardEnabled={true}
                                    isCardSuspended={false}
                                    data={{
                                        invitedOn: formatDefaultDateTime(item?.createdAt),
                                        acceptedOn: formatDefaultDateTime(item?.acceptedOn),
                                        contactPerson: contactPerson,
                                        contactEmail: contactEmail,
                                        status: status,
                                        maxStores: item?.retailBrand?._id ? item?.maxStores : "--",
                                        retailerActiveStores: item?.retailBrand?._id ? item?.activeStores : "--",
                                        toBrand: item?.labelBrand?.name
                                    }}
                                    edit={{ hidden: true }}
                                    suspend={{ hidden: true }}
                                    delete={{
                                        onClick: canRemoveInvite
                                            ? () =>
                                                  props?.onDeleteInvitation(
                                                      item?._id,
                                                      item?.retailBrand?.name,
                                                      item?.status === "Accepted"
                                                  )
                                            : undefined
                                    }}
                                    primaryButton={{
                                        title: "VOID",
                                        onClick: canVoidInvite
                                            ? () => {
                                                  props?.onVoidInvitation(
                                                      item?._id,
                                                      item?.retailBrand?.name,
                                                      item?.status === "Accepted"
                                                  );
                                              }
                                            : undefined
                                    }}
                                    secondaryButton={{
                                        title: "RESEND",
                                        onClick: canResendInvite
                                            ? () => {
                                                  props?.onResendInvitation(item?._id);
                                              }
                                            : undefined
                                    }}
                                />
                            );
                        });
                    })}

                    {!isFetchingNextPage &&
                        minimumCardsFill - getTotalCards() > 0 &&
                        Array.from({ length: minimumCardsFill - getTotalCards() }, (_, index) => (
                            <RetailerInvitationsGridCard
                                key={index}
                                isCardEnabled={false}
                                primaryButton={{ title: "Remove" }}
                                secondaryButton={{ title: "Resend" }}
                                idPrefix="BOTAG INVITE ID"
                                titlePrefix="INVITED RETAILER"
                                edit={{ hidden: true }}
                                suspend={{ hidden: true }}
                            />
                        ))}

                    {isFetchingNextPage &&
                        Array.from({ length: nextDataCount }, (_, index) => (
                            <CardGridSkeleton key={index} height="420.28px" />
                        ))}
                </div>
            )}
        </StyledPage>
    );
}
