const maskEmail = email => {
    //Check if the email is valid before masking
    if (
        !email ||
        !email.includes("@") ||
        email.length < 5 ||
        email.split("@").length !== 2 ||
        email.split("@")[1].split(".").length !== 2
    ) {
        return email;
    }

    const [localPart, domain] = email.split("@");
    const maskedLocalPart = localPart[0] + "*".repeat(localPart.length - 1);
    const [domainName, domainExtension] = domain.split(".");
    const maskedDomainName = "*".repeat(domainName.length - 1) + domainName.slice(-1);

    return `${maskedLocalPart}@${maskedDomainName}.${domainExtension}`;
};

const maskPhoneNumber = phoneNumber => {
    //Check if the phone number is valid before masking
    if (!phoneNumber || phoneNumber.length < 5) {
        return phoneNumber;
    }

    return phoneNumber.replace(/(?<=\d{2})\d(?=\d{2})/g, "*");
};

const maskName = name => {
    //Check if the name is valid before masking
    if (!name || name.length < 2) {
        return name;
    }

    if (name.length <= 2) {
        return name[0] + "*".repeat(name.length - 1);
    }

    const res = name[0] + "*".repeat(name.length - 2) + name[name.length - 1];

    return res;
};

export { maskEmail, maskPhoneNumber, maskName };
