import ProductPage from "../components/pages/products/ProductPage";
import AddProductPage from "../components/pages/products/AddProductPage";
import ProductArchive from "../components/pages/products/ProductArchive";

const routes = [
    {
        path: "/products",
        element: <ProductPage />
    },
    {
        path: "/products/add",
        element: <AddProductPage />
    },
    {
        path: "/products/archive",
        element: <ProductArchive />
    }
];

export default routes;
