import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import { useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { AppQueryClient } from "../../../api/queryClient";
import SwitchButton from "../../common/buttons/SwitchButton";
import { defaultStatusColors, getSpanWithColor } from "../../../utils/CardUtils";
import FFCsCustomExpandedComponent, { FloatingDiv } from "./FFCsCustomExpandedComponent";
import MainWindowHeader from "../../common/mainApp/mainWindowHeaders/MainWindowHeader";

// #region  SCOPED CSS
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
// #endregion

export default function FFCManagement() {
    document.title = `${APP_TITLE} - FFCs Management`;

    // #region VARS
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);

    const [onlyAffiliatedBrands, setOnlyAffiliatedBrands] = useState(false);
    const [listData, setListData] = useState([]);

    const [listNumRows, setListNumRows] = useState(20);

    //---------- Header Filters
    const [allConnectedRetailersList, setAllConnectedRetailersList] = useState([]);
    const [allRetailersList, setAllRetailersList] = useState([]);
    const [filteredRetailers, setFilteredRetailers] = useState([]);
    //-----
    const [allLabelBrandsList, setAllLabelBrandsList] = useState([]);
    const [filteredLabelBrand, setFilteredLabelBrand] = useState([]);
    //-----
    const [requestEnabled, setRequestsEnabled] = useState(false);

    const filters = {
        filterLabelBrandID: filteredLabelBrand?.[0]?.value,
        filterRetailBrandIDs: filteredRetailers?.map(item => item?.value)
    };

    const infiniteGridQuerykey = ["FFCGrid", filters];
    const listQueryKey = ["FFCList", filters];

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    // #endregion

    // #region EFFECTS
    useEffect(() => {
        setupHeaders(
            props,
            onClickRefreshAction,
            navigations,
            allRetailersList,
            allLabelBrandsList,
            onLabelBrandChange,
            onRetailersChange,
            onFiltersLoaded
        );
    }, [
        props?.layoutType,
        navigations,
        allRetailersList,
        allLabelBrandsList,
        filteredLabelBrand,
        filteredRetailers,
        requestEnabled
    ]);

    useEffect(() => {
        const interval = setInterval(() => {
            onClickRefreshAction();
        }, 180000);

        return () => clearInterval(interval);
    }, []);
    // #endregion

    // #region METHODS
    const onLabelBrandChange = selection => {
        setFilteredLabelBrand([selection]);

        const currentLabelBrandID = selection?.value;
        filterConnectedRetailers(allConnectedRetailersList, currentLabelBrandID);
    };

    const onRetailersChange = (selections, dropdownDataWithSelections) => {
        setFilteredRetailers(selections);
        setAllRetailersList(dropdownDataWithSelections);
    };

    const onFiltersLoaded = data => {
        let currentLabelBrandID = null;
        if (data?.labelBrands?.length) {
            const labelBrands = data?.labelBrands?.map((item, index) => {
                const hasRetailers = data?.connectedRetailBrands?.some(brand => brand?.labelBrandID === item?._id);

                return {
                    name: item?.name,
                    value: item?._id,
                    selected: index === 0,
                    hasRetailers: hasRetailers
                };
            });

            //filter out label brands that have no retailers
            //labelBrands = labelBrands?.filter(item => item?.hasRetailers);

            setAllLabelBrandsList(labelBrands);
            setFilteredLabelBrand([labelBrands[0]]);
            currentLabelBrandID = labelBrands[0]?.value;
        }

        if (data?.connectedRetailBrands?.length) {
            const connectedRetailBrands = data?.connectedRetailBrands?.map(item => {
                return {
                    name: item?.name,
                    value: item?._id,
                    selected: true,
                    labelBrandID: item?.labelBrandID
                };
            });

            setAllConnectedRetailersList(connectedRetailBrands);
            setFilteredRetailers(connectedRetailBrands?.filter(item => item?.selected));

            filterConnectedRetailers(connectedRetailBrands, currentLabelBrandID);
        }

        setRequestsEnabled(true);
    };

    const filterConnectedRetailers = async (allRetailers, currentLabelBrandID) => {
        let res = allRetailers?.filter(item => {
            return item?.labelBrandID === currentLabelBrandID;
        });

        // filter out any duplicated retailers
        res = res?.filter((item, index) => {
            return res?.findIndex(item2 => item2?.value === item?.value) === index;
        });

        setAllRetailersList(res);
        setFilteredRetailers(res);
    };

    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };
    // #endregion

    // #region LIST COMPONENT PROPS
    const listColumns = [
        {
            name: "Retailer",
            selector: row => (
                <div className="flex align-items-center">
                    <FloatingDiv style={{ flexShrink: 0 }}>New</FloatingDiv>
                    &nbsp;
                    <span>{row?.retailBrandName ?? "--"}</span>
                </div>
            )
        },
        {
            name: "City, Country",
            selector: row =>
                !row?.retailBrandCity && !row?.retailBrandCountry
                    ? "--"
                    : (row?.retailBrandCity || "--") +
                      ", " +
                      (row?.retailBrandCountry || row?.retailBrandCountryValue || "--")
        },
        {
            name: "Stores",
            selector: row => row?.totalStores ?? "--"
        },
        {
            name: "Fulfillment centers",
            selector: row => row?.totalFFCs ?? "--"
        },
        {
            name: "Pending affiliations",
            selector: row => row?.totalRequestsAvailable ?? "--"
        },
        {
            name: "Overall rating",
            selector: row => row?.overallRating ?? "--"
        },
        {
            name: "Active GTINs",
            selector: row => row?.activeGTINs ?? "--"
        },
        {
            name: "POS System",
            selector: row => row?.retailBrandPOSsystem || "--"
        },
        {
            name: "POS Status",
            selector: row =>
                getSpanWithColor(row?.retailBrandPOSstatus, defaultStatusColors, "text-red-500", "Not connected")
        }
    ];
    // #endregion

    // #region RENDER
    return (
        <StyledPage>
            <ListComponent
                rightCardTopComponent={
                    <SwitchButton
                        className="flex justify-end w-100"
                        height="17px"
                        title="All retailers"
                        rightTitle="New retailers"
                        onToogleChanged={value => setOnlyAffiliatedBrands(value)}
                        alwaysGreen={true}
                    />
                }
                title="Fulfillment Centers"
                columns={listColumns}
                pageLimit={listNumRows}
                selectableRows={false}
                currentPage={1}
                queryKey={listQueryKey}
                actions={{
                    includeActions: true,
                    editIsVisible: false,
                    suspendIsVisible: false,
                    removeIsVisible: false,
                    extraActions: [
                        {
                            title: "Details"
                        },
                        {
                            title: "Stock"
                            //onClick: row => navigate(`/stock?storeID=${row?.storeID}&storeName=${row?.storeName}`) //TODO: NOT WORKING
                        }
                    ]
                }}
                expandableRows={true}
                //expandedRowsColumns={expandedRowsColumns}
                expandedRowName="connectedBrands"
                includeStatus={{
                    width: "100px",
                    stickyStatus: true,
                    statusFromPath: "connectedBrandStatus",
                    statusColors: defaultStatusColors
                }}
                api={{
                    endpoint: "getFulfillmentCentersManagementData",
                    formData: {
                        onlyAffiliatedBrands,
                        limit: listNumRows,
                        ...filters
                    },
                    onSuccess: data => {
                        const tmpData = data?.result;

                        const tmp = tmpData?.map(row => {
                            const POSstatus = row?.POSstatus;
                            const POSconnected = POSstatus === "Connected";
                            const parentStatus = !POSconnected ? "Not fulfilling" : row?.status || "Not active";

                            return {
                                ...row,
                                status: parentStatus,
                                POSstatus: POSstatus
                                //storesData: [{}] //TODO: HARDCODED FOR TESTING PURPOSES
                                /*row?.connectedBrands?.map(brand => ({
                                            ...brand,
                                            status: !POSconnected
                                                ? "Not fulfilling"
                                                : translateFFCStoreStatus(brand?.fulfillmentCenterStatus),
                                            parentID: row?._id,
                                            parentPOSstatus: POSstatus
                                        })) */
                            };
                        });

                        setListData(tmp);
                        return tmp;
                    }
                }}
                mappedTempData={listData}
                customExpandedComponent={FFCsCustomExpandedComponent}
                requestEnabled={requestEnabled}
                onChangeRowsPerPage={rowsPerPage => setListNumRows(rowsPerPage)}
            />
        </StyledPage>
    );
    // #endregion
}

function setupHeaders(
    props,
    onClickRefreshAction,
    navigations,
    allRetailersList,
    allLabelBrandsList,
    onLabelBrandChange,
    onRetailersChange,
    onFiltersLoaded
) {
    const createMode = navigations?.navigationAccesses?.fulfillmentCenters?.createModeOnly;

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "FFCenters", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/ffcenters?layoutType=list", active: false, disabled: createMode, title: "FFCENTERS" },
        { to: "/ffcenters/management", active: true, disabled: false, title: "MANAGEMENT" }
        /*{ to: "/stock", active: false, disabled: false, title: "STOCK" }*/
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: createMode, title: "View", icon: "eye" },
        { to: "/retailers/invite", active: false, disabled: createMode, title: "Invite", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <MainWindowHeader
                key={window.location.pathname}
                visible={true}
                layoutTypeOptions={{
                    gridEnabled: false,
                    listEnabled: true
                }}
                onRefreshClick={onClickRefreshAction}
                firstOrganize={{
                    visible: true,
                    dropdownData: allLabelBrandsList,
                    onDropdownChange: onLabelBrandChange,
                    disabled: false,
                    type: "singleSelect"
                }}
                secondOrganize={{
                    visible: true,
                    dropdownData: allRetailersList,
                    onDropdownChange: onRetailersChange,
                    type: "multiSelect",

                    multidropdownOptions: {
                        useDoneButton: true,
                        useSelectAll: true,
                        selectAllText: "Select all retailers",
                        selectAllPlaceholderFunction: arrayItems => {
                            if (!arrayItems?.length) return "No retailers";

                            const nonLockedRetailers = allRetailersList?.filter(item => !item?.locked);
                            const areAllSelected = arrayItems?.length === nonLockedRetailers?.length;
                            if (areAllSelected) return `All retailers (${nonLockedRetailers?.length})`;

                            let firstTwoItems = arrayItems
                                ?.slice(0, 2)
                                ?.map(item => item?.name)
                                ?.join(", ");
                            if (arrayItems?.length > 2) {
                                firstTwoItems = firstTwoItems + ", and more";
                            }

                            return `Retailers (${arrayItems?.length}): ${firstTwoItems}`;
                        }
                    }
                }}
                pageFilters={{
                    useFilters: true,
                    filters: {
                        useLabelBrands: true,
                        useConnectedRetailBrands: true
                    },
                    onFiltersLoaded: onFiltersLoaded
                }}
            />
        )
    });
}
