import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { Tooltip } from "antd";
import styled from "styled-components";
import TagComponent from "./TagComponent";
import { v4 as uuidv4 } from "uuid";
import DropdownOverlay, { OverlayType } from "../dropdownOverlay/DropdownOverlay";
import { getDropdownData } from "../../../utils/dropdownData";
import Mail05Icon from "../../../assets/icons/mail-05.svg";
import * as Library from "../../../utils/Library";
import { maskEmail, maskPhoneNumber, maskName } from "../../../utils/valuesMasking";
import Edit03 from "../../../assets/icons/edit-03.svg";

const StyledElems = styled.div`
    & {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    textarea.autoResize {
        min-height: 37px;
        background: transparent;
        border: 0;
        width: 100%;
        outline: none;
        //add resize button
        //resize: vertical !important;
    }

    .inputFieldContainer {
        padding: 0px;
    }

    .FormComponentContainer {
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;
        display: inline-flex;
        position: relative;
    }

    .Frame51 {
        align-self: stretch;
        padding-left: 5px;
        padding-right: 5px;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        display: inline-flex;
    }

    .Icon {
        width: 10px;
        height: 10px;
        font-size: 9px;
        font-weight: 500;
        color: var(--text-icons-gray-200);
        position: relative;
        top: -1px;
    }

    .Title {
        color: var(--text-icons-gray-200);
        font-size: 0.75rem;
        font-weight: 400;
        word-wrap: break-word;
    }

    .InputSelectorInverted {
        align-self: stretch;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
    }

    .Frame49 {
        width: 5px;
        height: 37px;
        padding-top: 6px;
        padding-bottom: 6px;
        background: var(--text-icons-orange-500);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .Search {
        flex: 1 1 0;
        height: 38px;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: flex;
    }

    .Frame48 {
        width: 100%;
        flex: 1 1 0;
        height: 37px;
        background: #10141b;
        border-radius: 4px;
        background: var(--backgrounds-lines-n-600);
        border: 0;
        border-left: 5px solid var(--backgrounds-lines-n-500);
        outline: none;
        color: var(--text-icons-gray-100);
        gap: 0;
    }

    .Frame48.none {
        border: 0 !important;
        gap: 0;
    }

    .Frame48.none .Frame48Input {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
    }

    .Frame48.simple {
        border-left: 5px solid var(--backgrounds-lines-n-500);
    }

    .Frame48.disabled {
        color: var(--text-icons-gray-300);
        cursor: not-allowed;
    }

    .Frame48.mandatory {
        border-left: 5px solid var(--text-icons-orange-500);
    }

    .Frame48.recommended {
        border-left: 5px solid var(--text-icons-blue-500);
    }

    .Frame48.locked,
    .Frame48.mandatoryLocked {
        border-left: 5px solid var(--text-icons-gray-300);
        cursor: not-allowed !important;
    }

    .Frame48.invalid {
        border-left: 5px solid var(--text-icons-red-500);
    }

    .Frame48.tagAsDropdown {
        cursor: pointer;
    }

    .dragged {
        box-shadow: 0px 0px 0px 1px var(--text-icons-blue-500);
        border-radius: 4px;
    }

    .dragged.drag-over {
        box-shadow: none;
    }

    .dropped {
        box-shadow: 0px 0px 0px 1px var(--text-icons-green-500);
        border-radius: 4px;
    }

    .droppedRemove,
    .dropdownArrow {
        position: absolute;
        right: 12px;
        top: 10px;
        width: 16px;
        height: 16px;
        cursor: pointer;
        color: #d1d1d1;
    }

    .dropdownArrow {
        transition: transform 0.2s;
    }

    .dropdownArrow.active {
        transform: rotate(180deg);
    }

    .dropdownArrow.disabled {
        cursor: not-allowed !important;
        color: var(--text-icons-gray-300) !important;
    }

    .Frame48.tagAsDropdown .dropdownArrow {
        position: unset !important;
        align-self: center;
        margin-right: 12px;
        height: 17px;
        width: 16px;
        flex-shrink: 0;
    }

    .Frame48.tagAsDropdown .Frame48TagsContainer {
        padding-right: 42px;
    }

    .Frame48LeftContent {
        display: flex;
        width: 50px;
        height: 37px;
        padding: 6px 0px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-right: 1px solid var(--backgrounds-lines-n-100);
        flex-shrink: 0;
        overflow: hidden;
    }

    .Frame48RightContent {
        display: flex;
        width: 50px;
        height: 37px;
        padding: 6px 0px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-left: 1px solid var(--backgrounds-lines-n-100);
        flex-shrink: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-shrink: 0;
    }

    .Frame48Input {
        flex: 1 1 0;
        width: 100%;
        height: 100%;

        //padding-left: 12px;
        //padding-right: 12px;
        padding: 0px;

        background: var(--backgrounds-lines-n-600);
        border: 0;
        outline: none;
        color: var(--text-icons-gray-100);

        /* Subtitle 2 */
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    textarea.Frame48Input {
        resize: none;
        padding-top: 10px;
        padding-bottom: 6px;
    }

    textarea.Frame48Input:not(.autoResize) {
        height: auto !important;
    }

    .inputFieldContainer {
        display: flex;
        flex: 1 1 0;
        height: 100%;
        flex-direction: row;
        align-items: center;
        padding-left: 12px;
        padding-right: 12px;
    }

    .textPrefix {
        color: var(--text-icons-gray-300, #4f5b6d);
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }

    //WHEN NUMBER REMOVE THE UP AND DOWN ARROWS
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    //WHEN NUMBER REMOVE THE UP AND DOWN ARROWS (FIREFOX)
    input[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    input[type="date"]::-webkit-calendar-picker-indicator,
    input[type="datetime-local"]::-webkit-calendar-picker-indicator {
        display: none;
    }

    .Frame48Input::placeholder,
    .TagsInput::placeholder,
    label.placeholder {
        color: var(--text-icons-gray-300);
        font-style: italic;
    }

    .Frame48TagsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin: 6px 0px 6px 8px;
        width: 100%;
        align-self: center;
    }

    .TagsInput {
        color: #d1d1d1;
        background: transparent;
        border: 0;
        outline: none;

        /* Subtitle 2 */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame48.disabled .Frame48Input {
        color: var(--text-icons-gray-300);
        cursor: not-allowed;
    }

    .Frame50 {
        align-self: stretch;
        padding-left: 5px;
        padding-right: 5px;
        text-align: right;
    }

    .AssistiveText {
        /* Label/XS - Uppercase */
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.1px;
        text-transform: uppercase;
        height: 9px;
    }

    input.datepicker {
        display: inline-block;
        position: relative;
        color-scheme: dark;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        color-scheme: dark;
    }

    div.no-date {
        background: none;
    }

    div.no-date .placeholder {
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--backgrounds-lines-n-600);
        height: 37px;
        width: 100%;
        line-height: 37px;
        padding-left: 12px;
        padding-right: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    div.has-date .placeholder {
        display: none;
    }

    .ip-placeholder {
        color: var(--text-icons-gray-200, #7c868b);
        font-family: Roboto;
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }

    .field-edit-option {
        cursor: pointer;
    }

    .field-edit-option img {
        width: 10px;
        margin-left: 4px;
        align-items: center;
        justify-content: center;
        display: flex;
    }
`;

/** STYLING PROPS (SO FAR)
 * @param {Object} styling - The styling object for the component.
 * @param {string} styling.backgroundColor - The background color for the component. // TODO: Currently only works with dropdowns, need to implement for other types
 * @param {int} [styling.dropdownOverlayTop="65px"] - The top position for the dropdown overlay.
 * @param {Object} [styling.dropdownOverlayStyle={}] - The style for the dropdown overlay.
 * @param {string} [styling.requiredTypeColor] - The color override for the required type indicator.
 */

/** A simple form component that renders an input field with multiple customizations.
 * for examples check route: /templates/forms and FormsTemplatePage.jsx
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the input field.
 * @param {string} props.varName - The variable name of the input field.
 * @param {string} props.type - The type of the input field. Possible values are "text", "number", "email", "password", "date", "datetime-local", "dropdown", "tags", "droppable", "taxNumber" and "phoneNumber"
 * @param {boolean} [props.editable=false] - The flag to know if the input field is editable.
 * @param {string} [props.requiredType="simple"] - The type of required field indicator. Possible values are "simple", "mandatory", "recommended", "locked", "mandatoryLocked" or "none".
 * @param {string} props.value - The value of the input field. NOTE: If this is an array, this should come from a state variable or else it will trigger a useEffect of data was changed on every view refresh.
 * @param {string} props.valueCover - This will hide the value of the input field and show the valueCover instead.
 * @param {boolean} props.useValueMasking - This will mask the value of the input field.
 * @param {function} props.onTextChange - The callback function to handle text changes in the input field.
 * @param {function} props.onTextBlur - The callback function to handle text blur in the input field.
 * @param {function} props.onDropdownChange - The callback function to handle dropdown changes.
 * @param {string} props.tooltip - The tooltip text for the input field.
 * @param {string} [props.tooltipMaxWidth="200px"] - The maximum width of the tooltip.
 * @param {boolean} [props.disableTooltip=false] - The flag to know if the tooltip should be
 * @param {ReactNode} [props.leftContent] - The icon to display on the left side of the input field.
 * @param {string} [props.rightContent] - The text to display on the right side of the input field.
 * @param {string} [props.tagAddedColor="#1E2631"] - The color of the tag added to the input field.
 * @param {string} [props.tagAddWhenEnterPressed=true] - The flag to know if the tag should be added when the enter key is pressed.
 * @param {function} [props.onTagsChanged] - The callback function to handle tag changes in the input field.
 * @param {function} [props.onTagRemoved] - The callback function to handle tag removal in the input field.
 * @param {string} [props.className=""] - The class name for the component.
 * @param {string} [props.minDate=""] - The minimum date for the date input field.
 * @param {string} [props.maxDate=""] - The maximum date for the date input field.
 * @param {string} [props.assistiveText=""] - The bottom right assistive text for the input field.
 * @param {string} [props.assistiveTextColorOverride=""] - The color of the assistive text.
 * @param {string} [props.invalidAssistiveText="Invalid Text"] - The assistive text to display when the input field is invalid.
 * @param {boolean} [props.disableAssistiveText=false] - The flag to know if the assistive text
 * @param {int} [props.numOfLines=1] - The number of lines for the input field. Only applicable for type "text".
 * @param {int} [props.minLength=0] - The minimum length of the input field. Only applicable for type "text".
 * @param {int} [props.maxLength=500] - The maximum length of the input field. Only applicable for type "text".
 * @param {boolean} [props.isDropdownMultiselect=false] - Whether the dropdown is multi-select. Only applicable for type "dropdown".
 * @param {boolean} [props.isDropdownSearchable=false] - Whether the dropdown is searchable. Only applicable for type "dropdown".
 * @param {Object[]} [props.dropdownData=[]] - The data for the dropdown, only applicable for type "dropdown". values should be composed with fields: value, name, selected(optional) of The selected will be the item that have "selected: true". IMPORTANT: dropdownData should always come from a state variable or else it will trigger a useEffect of data was changed on every view refresh and cause the selection to be reset.
 * @param {function} props.dropdownOnSingleItemClick - The callback function to handle single item click in the dropdown. Only applicable for type "dropdown" and with isDropdownMultiselect set to true.
 * @param {boolean} [props.firstElementIsSelectAll=false] - The flag to know if the first element of the dropdown should be a select all option. Only applicable for type "dropdown" and with isDropdownMultiselect set to true.
 * @param {Object} [props.multidropdownOptions] - The options for the multi dropdown. Only applicable for type "dropdown" and with isDropdownMultiselect set to true.
 * @param {boolean} [props.multidropdownOptions.useSelectAll=false] - The flag to know if the select all option should be used. Only applicable for type "dropdown" and with isDropdownMultiselect set to true.
 * @param {function} [props.multidropdownOptions.selectAllPlaceholderFunction] - The custom function to get the name of the selected items. Only applicable for type "dropdown" and with isDropdownMultiselect set to true.
 * @param {string} [props.multidropdownOptions.selectAllText="Select All"] - The text for the select all option. Only applicable for type "dropdown" and with isDropdownMultiselect set to true.
 * @param {boolean} [props.multidropdownOptions.useDoneButton=false] - The flag to know if the done button should be used. Only applicable for type "dropdown" and with isDropdownMultiselect set to true.
 * @param {function} [props.useDropdownPlaceholderAsTag=false] - The flag to know if the placeholder should be used as a tag/chip. Only applicable for type "dropdown".
 * @param {string} [props.phoneNumberCountryCode=""] - The country code for the phone number input field. Only applicable for type "phoneNumber".
 * @param {string} [props.taxNumberCountryCode=""] - The country code for the tax number input field. Only applicable for type "taxNumber".
 * @param {string} [props.onCountryCodeChange=()=>{}] - This is a function triggers when the country code is changed. Only applicable for type "phoneNumber" and "taxNumber".
 * @param {int} [props.formFieldColumSize=1] - The column count size of the form field from 1 up to 6.
 * @param {string} [props.placeholder=""] - The placeholder text for the input field.
 * @param {string} [props.textPrefix=""] - The text prefix for the input field. Usefull for adding a url prefix before the text.
 * @param {boolean} [props.valueReturnsTextPrefix=true] - This is a flag to know if the value of the input field should also include the text prefix.
 * @param {boolean} [props.resizable=false] - The flag to know if the input field should be resizable. Only applicable for type "text".
 * @param {int} [props.defaultSize] - The default size of the input field when resizable is true.
 * @param {string} [props.styling] - The styling for the component. ALL THE STYLING PROPS DOCS ARE IN THE FORMFIELD COMPONENT HEADER.
 * @param {string} [props.tagType] - The type of tag to display. Possible values are "hashtags" or "tags".
 * @param {Object} [props.isInvalid] - The flag to know if the input field is invalid.
 *
 * @returns {JSX.Element} The rendered FormField component.
 * for examples check route: /templates/forms and FormsTemplatePage.jsx
 */
const FormField = forwardRef((props, ref) => {
    const {
        valueCover = undefined,
        useValueMasking = false,
        style,
        title,
        type,
        onEdit,
        requiredType = "simple",
        value = "",
        onTextChange,
        onTextBlur,
        onFocus,
        onDropdownChange,
        tooltip,
        tooltipMaxWidth = "200px",
        disableTooltip = false,
        rightContent,
        tagAddedColor = "#0155b3",
        tagAddWhenEnterPressed = true,
        onTagsChanged,
        onTagRemoved,
        tagAsDropdown = false, // DO NOT USE THIS PROP (for custom purpose only)
        tagDropdownVisible = false, // DO NOT USE THIS PROP (for custom purpose only)
        className = "",
        minDate = "",
        maxDate = "",
        assistiveTextColorOverride = "",
        invalidAssistiveText = "Invalid Text",
        disableAssistiveText = false,
        multiLine = {
            isMultiLine: false,
            numberOfLines: undefined,
            autoExpand: false,
            maxLines: undefined
        },
        minLength = 0,
        maxLength = 500,
        isDropdownMultiselect = false,
        isDropdownSearchable = false,
        isDragging = true,
        dropdownData = [],
        dropdownOnSingleItemClick,
        firstElementIsSelectAll = false,
        multidropdownOptions = {
            useSelectAll: false,
            selectAllPlaceholderFunction: undefined,
            selectAllText: "Select All",
            useDoneButton: true
        },
        useDropdownPlaceholderAsTag = false,
        phoneNumberCountryCode = "",
        taxNumberCountryCode = "",
        placeholder,
        placeholderWidth = "auto",
        textPrefix = "",
        valueReturnsTextPrefix = true,
        formFieldColumSize = 1,
        onCountryCodeChange = () => {},
        onCurrentValueChange,
        onFormTextChange, // DO NOT USE THIS PROP (for FORM CARD USAGE ONLY), use onTextChange instead
        onFormTextBlur, // DO NOT USE THIS PROP (for FORM CARD USAGE ONLY), use onTextBlur instead
        onFormCardVerifyValidity, // DO NOT USE THIS PROP (for FORM CARD USAGE ONLY)
        onFormCardChanges, // DO NOT USE THIS PROP (for FORM CARD USAGE ONLY)
        resizable = false,
        defaultSize,
        styling,
        tagType,
        isInvalid
    } = props;

    let { assistiveText, leftContent } = props;

    const {
        backgroundColor,
        dropdownOverlayTop = "65px",
        dropdownOverlayStyle = {},
        requiredTypeColor
    } = styling ?? {};

    /* #region STATES */
    const inputRef = useRef();
    const tagInputRef = useRef();
    const [currentValue, setCurrentValue] = useState(
        type !== "dropdown"
            ? value
            : dropdownData
                  ?.filter(item => item.selected)
                  .map(item => item.name)
                  .join(", ")
    );
    const [currentTags, setCurrentTags] = useState([]);
    const [dropdownSelectedData, setDropdownSelectedData] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [inputID] = useState(props?.inputID || uuidv4());
    const [fieldIsInvalid, setFieldIsInvalid] = useState(false);
    const [typeInvalidAssistiveText, setTypeInvalidAssistiveText] = useState("");
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [currentDropdownData, setCurrentDropdownData] = useState(dropdownData);
    const [showDropdown, setShowDropdown] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);
    const [dropdownCheckedItemsNames, setDropdownCheckedItemsNames] = useState([]);

    const [isOutsideInvalid, setIsOutsideInvalid] = useState({
        isInvalid: false,
        invalidAssistiveText: ""
    });
    const [showingMaxLinesWarning, setShowingMaxLinesWarning] = useState(false);

    const [phoneNumberCountryCodeState, setPhoneNumberCountryCodeState] = useState(
        phoneNumberCountryCode.replace("+", "")
    );
    const [taxNumberCountryCodeState, setTaxNumberCountryCode] = useState(taxNumberCountryCode);
    /* #endregion */

    /* #region METHODS */
    const updateTextareaHeight = () => {
        if (!multiLine?.autoExpand) return;
        document
            .querySelectorAll("textarea")
            .forEach(t => t.dispatchEvent(new Event("input", { bubbles: true, cancelable: true })));
    };

    const getDisplayValue = () => {
        if (useValueMasking) {
            //TODO: Check wich method to use
            // if (type === "email") return maskEmail(currentValue);
            // if (type === "phoneNumber") return maskPhoneNumber(currentValue);
            // if (type === "name") return maskName(currentValue);

            switch (type) {
                case "email":
                    return maskEmail(currentValue);
                case "phoneNumber":
                    return maskPhoneNumber(currentValue);
                case "text" || "number":
                    return maskName(currentValue);
                default:
                    return currentValue;
            }
        }
        return valueCover || currentValue;
    };

    const getTextColor = () => {
        if (fieldIsInvalid || isOutsideInvalid.isInvalid || isInvalid) return "var(--text-icons-red-500)";
        if (requiredType === "locked" || requiredType === "mandatoryLocked") return "var(--text-icons-gray-300)";
        return "var(--text-icons-green-500)";
    };

    const getAssistiveTextColor = () => {
        if ((assistiveTextColorOverride && !fieldIsInvalid && !isOutsideInvalid.isInvalid) || isInvalid)
            return assistiveTextColorOverride;
        if (isOutsideInvalid.isInvalid || isInvalid) {
            assistiveText = isOutsideInvalid.invalidAssistiveText;
            return "var(--text-icons-red-500)";
        } else if (fieldIsInvalid) {
            assistiveText = invalidAssistiveText;
            return "var(--text-icons-red-500)";
        }
        //if (requiredType === "locked" || requiredType === "mandatoryLocked") return "var(--text-icons-gray-300)";
        return "var(--text-icons-blue-500)";
    };

    const isValid = (highlightValidation = true) => {
        if (isOutsideInvalid.isInvalid || isInvalid) {
            if (highlightValidation) setFieldIsInvalid(true);
            return false;
        }
        if (
            (requiredType === "mandatory" || requiredType === "mandatoryLocked") &&
            ((type !== "tags" && !currentValue) || (type === "tags" && currentTags.length === 0))
        ) {
            if (highlightValidation) setFieldIsInvalid(true);
            return false;
        }
        if (!isTypeValid(false)) {
            if (highlightValidation) setFieldIsInvalid(true);
            return false;
        }
        if (highlightValidation) setFieldIsInvalid(false);
        return true;
    };

    const isTypeValid = (highlightValidation = true) => {
        if (type === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(currentValue) && currentValue?.length > 0) {
            if (highlightValidation) {
                setTypeInvalidAssistiveText("Invalid Email Format");
                setFieldIsInvalid(true);
            }
            return false;
        }
        if (type === "phoneNumber") {
            if (!/^\+?[0-9]{6,14}$/.test(currentValue) && currentValue?.length > 0) {
                if (highlightValidation) {
                    setTypeInvalidAssistiveText("Invalid Phone Number");
                    setFieldIsInvalid(true);
                }
                return false;
            }
            if (!phoneNumberCountryCodeState && currentValue?.length > 0) {
                if (highlightValidation) {
                    setTypeInvalidAssistiveText("Invalid Country Code");
                    setFieldIsInvalid(true);
                }
                return false;
            }
            if (currentValue?.length > 0 && currentValue?.length < 6) {
                if (highlightValidation) {
                    setTypeInvalidAssistiveText("Invalid Phone Number");
                    setFieldIsInvalid(true);
                }
                return false;
            }
            setTypeInvalidAssistiveText("");
            setFieldIsInvalid(false);
            return true;
        }
        if (type === "taxNumber") {
            const currentValNoSpaces = currentValue?.toString()?.replace(/\s/g, "");
            if (currentValNoSpaces?.length > 0 && currentValNoSpaces?.length !== 9) {
                if (highlightValidation) {
                    setTypeInvalidAssistiveText("Invalid Tax Number");
                    setFieldIsInvalid(true);
                }
                return false;
            }
            if (!taxNumberCountryCodeState && currentValNoSpaces?.length > 0) {
                if (highlightValidation) {
                    setTypeInvalidAssistiveText("Invalid Country Code");
                    setFieldIsInvalid(true);
                }
                return false;
            }
            setTypeInvalidAssistiveText("");
            setFieldIsInvalid(false);
            return true;
        }
        if (minLength > 0 && currentValue?.length < minLength) {
            if (highlightValidation) {
                setTypeInvalidAssistiveText(`Minimum ${minLength} characters`);
                setFieldIsInvalid(true);
            }
            return false;
        } else if (maxLength > 0 && currentValue?.length > maxLength) {
            if (highlightValidation) {
                setTypeInvalidAssistiveText(`Maximum ${maxLength} characters`);
                setFieldIsInvalid(true);
            }
            return false;
        } else {
            //if (highlightValidation) setFieldIsInvalid(false);
            setFieldIsInvalid(false);
        }

        setTypeInvalidAssistiveText("");
        return true;
    };

    const setOutsideInvalid = (isInvalid, invalidAssistiveText) => {
        setIsOutsideInvalid({ isInvalid, invalidAssistiveText: invalidAssistiveText || "" });
    };

    const textChange = e => {
        let currentText = e.target.value;
        if (multiLine?.isMultiLine && multiLine?.maxLines) {
            const lines = currentText.split("\n");
            if (lines.length > multiLine.maxLines) {
                currentText = lines.slice(0, multiLine.maxLines).join("\n");
                setShowingMaxLinesWarning(true);

                setTimeout(updateTextareaHeight, 100);
            }
        }

        if (type === "taxNumber") {
            //remove non-numeric characters but allow space between each 3 digits, ex: 123 456 789
            currentText = currentText
                .replace(/[^0-9 ]/g, "")
                .replace(/(\d{3})(?=\d)/g, "$1 ")
                .trim();
        }

        setIsOutsideInvalid({ isInvalid: false, invalidAssistiveText: "" });
        setCurrentValue(currentText);
        if (onTextChange) onTextChange(currentText);
        if (onFormTextChange) onFormTextChange(true);
        if (onFormCardChanges) onFormCardChanges(true);
    };

    const textBlur = e => {
        if (onTextBlur) onTextBlur(e.target.value);
        if (onFormTextBlur) onFormTextBlur(true);
        isTypeValid(true);
    };

    const textFocus = e => {
        if (onFocus) onFocus(e.target.value);
    };

    const outsideTagAdd = (tagName, value = undefined) => {
        if (type !== "tags") return;

        const event = {
            key: "Enter",
            target: { value: tagName }
        };

        onTagAdd(event, true, value);
    };

    const outsideTagRemove = tagName => {
        if (type !== "tags") return;

        const index = currentTags.findIndex(tag => tag.tagName === tagName);
        if (index >= 0) {
            handleTagRemove(index);
            setCurrentValue("");
        }
    };

    const onTagAdd = (e, isOutsideTagAdd = false, value = undefined) => {
        if (type !== "tags" || (!tagAddWhenEnterPressed && !isOutsideTagAdd)) return;
        if (type === "tags" && tagsChange && e.key === "Enter") {
            const name = e.target.value.trim();
            let formattedText = name.replace(/[^a-zA-Z0-9_]/g, ""); // Remove all special characters except underscores
            formattedText = tagType === "hashtags" ? `#${formattedText}` : name; // Add a single #
            if (!name) return;
            addTag(formattedText, value);
            setCurrentValue("");
            if (onFormCardChanges) onFormCardChanges(true);
        }
        if (fieldIsInvalid || isOutsideInvalid.isInvalid || isInvalid) isValid();
    };

    const onSelectAllClicked = (tagList = []) => {
        if (type !== "tags") return;
        const isAllSelected = tagList.every(tag => currentTags.some(currentTag => currentTag.tagName === tag?.name));

        if (!isAllSelected)
            setCurrentTags(tagList.map(tag => ({ bgColor: tagAddedColor, tagName: tag?.name, value: tag?.value })));
        else setCurrentTags([]);

        if (onFormCardChanges) onFormCardChanges(true);
    };
    const addTag = (tagName, value = undefined) => {
        if (type !== "tags") return;
        const newTag = { bgColor: tagAddedColor, tagName, value };
        const newTags = [...currentTags, newTag];
        tagsChange(newTags);
        if (onTagsChanged) onTagsChanged(newTags);
        if (onFormCardChanges) onFormCardChanges(true);
    };

    const tagsChange = newTags => {
        setCurrentTags(newTags);
    };

    const handleTagRemove = index => {
        const newTags = currentTags.filter((_item, idx) => idx !== index);
        tagsChange(newTags);
        if (onFormCardChanges) onFormCardChanges(true);
        if (onTagsChanged) onTagsChanged(newTags);

        const tag = currentTags[index];
        if (onTagRemoved) onTagRemoved(tag);
    };

    const onDroppedRemove = () => {
        setCurrentValue("");
        if (onTextChange) onTextChange("");
    };

    const handleTagFieldClick = () => {
        if (tagInputRef.current && requiredType !== "locked" && requiredType !== "mandatoryLocked") {
            tagInputRef.current.focus();
            if (onFocus) onFocus();
        }
    };

    const getValue = () => {
        if (type === "tags") return currentTags;
        if (type === "dropdown") return dropdownSelectedData;
        if (type === "phoneNumber") return "+" + phoneNumberCountryCodeState + " " + currentValue;
        if (type === "taxNumber") return currentValue;
        if (textPrefix && valueReturnsTextPrefix) return addTextPrefix(currentValue);
        return currentValue;
    };

    const onDropdownClick = () => {
        if (type !== "dropdown" || requiredType === "locked" || requiredType === "mandatoryLocked") return;
        setDropdownVisible(!dropdownVisible);
    };

    const onDropdownSingleSelect = (data, allSelections) => {
        if (isDropdownMultiselect) {
            const updatedAllSelections = Object.entries(allSelections).map(([key, item]) => {
                if (key !== data.value) return item;
                else return data.selected;
            });
            if (dropdownOnSingleItemClick) dropdownOnSingleItemClick(data, updatedAllSelections);
            return;
        }

        if (type === "phoneNumber") {
            setPhoneNumberCountryCodeState(data.value);
            onCountryCodeChange(data);
            setDropdownVisible(false);
            setShowDropdown(false);
            isTypeValid(true);
            if (onTextChange) onTextChange("+" + data.value + " " + currentValue);
            if (onFormTextChange) onFormTextChange("+" + data.value + " " + currentValue);
            if (onDropdownChange) onDropdownChange(data);
            return;
        }
        if (type === "taxNumber") {
            setTaxNumberCountryCode(data.value);
            onCountryCodeChange(data);
            setDropdownVisible(false);
            setShowDropdown(false);
            isTypeValid(true);
            if (onTextChange) onTextChange(data.value + " " + currentValue);
            if (onFormTextChange) onFormTextChange(data.value + " " + currentValue);
            if (onDropdownChange) onDropdownChange(data);
            return;
        }

        if (onTextChange) onTextChange(data);
        if (onFormTextChange) onFormTextChange(data);
        if (onDropdownChange) onDropdownChange(data);
        if (onFormCardChanges) onFormCardChanges(true);
        setCurrentValue(data.name);
        setDropdownSelectedData(data);
        setDropdownVisible(false);
    };

    const onDropdownMultiSelect = data => {
        if (!isDropdownMultiselect) return;

        const selectedData = data.filter(item => item.selected);

        /* const selectedTags = selectedData.map(item => {
            return { tagName: item.name };
        });
        setCurrentTags(selectedTags); */
        setDropdownSelectedData(selectedData);
        if (onTextChange) onTextChange(selectedData);
        if (onFormTextChange) onFormTextChange(selectedData);
        if (onFormCardChanges) onFormCardChanges(true);
        //if (onDropdownChange) onDropdownChange(data);
        //setCurrentValue(selectedData.map(item => item.name).join(", "));
        setCurrentValue(getDropdownLabelText(selectedData));
    };

    const removeTextPrefix = value => {
        const valueHasPrefix = value?.toLowerCase().startsWith(textPrefix.toLowerCase());

        if (valueHasPrefix) {
            if (!value) return "";
            else return value?.toLowerCase().replace(textPrefix.toLowerCase(), "");
        } else return value;
    };

    const addTextPrefix = value => {
        if (!value) return "";
        else return textPrefix + value;
    };

    const handleTextPrefixClick = () => {
        if (inputRef.current && requiredType !== "locked" && requiredType !== "mandatoryLocked")
            inputRef.current.focus();
    };

    const handleDrop = e => {
        e.preventDefault();
        if (type !== "droppable" || requiredType === "locked" || currentValue) return;
        const data = e.dataTransfer.getData("text/plain");
        textChange({ target: { value: data } });
        setIsDragOver(false);
    };

    const handleDragOver = e => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const getDropdownLabelText = data => {
        if (type !== "dropdown") return;

        let result = data
            ?.filter(item => item.selected)
            .map(item => item.name)
            .join(", ");

        if (isDropdownMultiselect && multidropdownOptions?.selectAllPlaceholderFunction) {
            return multidropdownOptions?.selectAllPlaceholderFunction(data) || result;
        } else if (isDropdownMultiselect && firstElementIsSelectAll) {
            //check if data contains value="selectAll" and is selected
            const selectAll = data.find(item => item.value === "selectAll");
            if (selectAll && selectAll.selected) result = selectAll.name;
        }

        return result;
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!showingMaxLinesWarning) return;

        Library.showToastMessage({
            type: "warning",
            message: "The maximum number of lines has been reached!",
            autoClose: 2000,
            onClose: () => setShowingMaxLinesWarning(false)
        });
        updateTextareaHeight();
    }, [showingMaxLinesWarning]);

    useEffect(() => {
        if (type === "dropdown") {
            if (isDropdownMultiselect) {
                /* TODO: Implement multiselect dropdown value handling */
            } else {
                //const selectedData = dropdownData.map(item => ({ ...item, selected: item.name === value }));
                const selectedData = dropdownData.find(item => item.name === value);
                setDropdownSelectedData(selectedData);
                setCurrentValue(selectedData); //TODO: Or setCurrentValue(selectedData?.name) ?
            }
        }
        if (type === "tags") {
            if (!value) {
                setCurrentTags([]);
                return;
            }

            //check for differences in the tags array
            const isTagNamesDifferent =
                value?.length !== currentTags?.length ||
                value?.some((tag, index) => tag !== currentTags[index]?.tagName);

            if (!isTagNamesDifferent) {
                return;
            }

            try {
                const newTags = value?.map(tag => ({ bgColor: tagAddedColor, tagName: tag }));
                setCurrentValue("");
                setCurrentTags(newTags);
                if (onTagsChanged) onTagsChanged(newTags);
                return;
            } catch (error) {
                console.log("Error setting tags", value);
                return;
            }
        }
        if (textPrefix && valueReturnsTextPrefix) setCurrentValue(removeTextPrefix(value));
        else setCurrentValue(value);
    }, [value]);

    useEffect(() => {
        if (type !== "dropdown") return;

        setCurrentDropdownData(dropdownData);
        if (isDropdownMultiselect) {
            setDropdownSelectedData(dropdownData.filter(item => item.selected));

            //dropdownCheckedItemsNames
            const checkedItemsNames = dropdownData.filter(item => item.selected).map(item => item.name);
            setDropdownCheckedItemsNames(checkedItemsNames);

            return;
        } else setDropdownSelectedData(dropdownData.find(item => item.selected));

        /* setCurrentValue(
            dropdownData
                ?.filter(item => item.selected)
                .map(item => item.name)
                .join(", ")
        ); */
        setCurrentValue(getDropdownLabelText(dropdownData));

        if (!dropdownData.find(item => item.selected) && value) {
            const selected = dropdownData.find(item => item.name === value);
            if (selected) {
                setDropdownSelectedData(selected);
                setCurrentValue(selected.name);
            }
        }
    }, [dropdownData]);

    useEffect(() => {
        //if hidding dropdown and type is dropdown and isMultiselect then notify dropdown changes and form card changes
        if (dropdownVisible === false && type === "dropdown" && isDropdownMultiselect) {
            const dropdownDataWithSelections = dropdownData.map(item => {
                return { ...item, selected: dropdownSelectedData.some(selected => selected.value === item.value) };
            });

            if (onDropdownChange) onDropdownChange(dropdownSelectedData, dropdownDataWithSelections);
            if (onFormCardChanges) onFormCardChanges(true);
        }
    }, [dropdownVisible]);

    useEffect(() => {
        if (type === "phoneNumber") {
            setPhoneNumberCountryCodeState(phoneNumberCountryCode.replace("+", ""));
        }
        if (type === "taxNumber") {
            setTaxNumberCountryCode(taxNumberCountryCode);
        }
    }, [phoneNumberCountryCode, taxNumberCountryCode]);

    useEffect(() => {
        if (onCurrentValueChange && type !== "tags") onCurrentValueChange(currentValue);
        if (multiLine?.isMultiLine && multiLine?.autoExpand) setTimeout(updateTextareaHeight, 100);
    }, [currentValue]);

    useEffect(() => {
        if (onCurrentValueChange) onCurrentValueChange(currentTags);

        //-->if (onFormCardChanges) onFormCardChanges(true);
        if (onFormCardVerifyValidity) onFormCardVerifyValidity();
    }, [currentTags]);

    useEffect(() => {
        if (dropdownVisible) {
            const closeDropdown = event => {
                if (
                    !event.target.closest(".dropdownContainer-" + inputID) &&
                    !event.target.closest(".Frame-" + inputID)
                ) {
                    setDropdownVisible(false);
                }
            };

            document.addEventListener("click", closeDropdown);
            return () => document.removeEventListener("click", closeDropdown);
        }
    }, [dropdownVisible]);

    useEffect(() => {
        if (onFormCardVerifyValidity) onFormCardVerifyValidity();
    }, [isOutsideInvalid]);

    useEffect(() => {
        if (!multiLine?.isMultiLine || !multiLine?.autoExpand) return;

        const textareas = document.querySelectorAll("textarea.autoResize");

        textareas.forEach(textarea => {
            textarea.style.height = textarea.scrollHeight + "px";
            textarea.style.overflowY = "hidden";

            const handleInput = function () {
                this.style.height = "auto";
                this.style.height = this.scrollHeight + "px";
            };

            textarea.addEventListener("input", handleInput);

            // Clean up the event listener on unmount
            return () => {
                textarea.removeEventListener("input", handleInput);
            };
        });
    }, []);

    useEffect(() => {
        // if is invalid, recheck if it is no longer invalid and remove the invalid red state
        if (
            (fieldIsInvalid || isOutsideInvalid.isInvalid || isInvalid) &&
            ["text", "number", "email", "phoneNumber", "taxNumber"].includes(type)
        ) {
            isValid();
        }
    }, [currentValue]);
    /* #endregion */

    /* #region COMPUTED VALUES */
    const required = requiredType === "mandatory" || requiredType === "mandatoryLocked";
    const disabled =
        requiredType === "locked" || requiredType === "mandatoryLocked" || type === "droppable" || type === "dropdown";
    const textColor = getTextColor();

    if (type === "phoneNumber") {
        leftContent = LeftContentPhoneNumber({
            showDropdown,
            setShowDropdown,
            setCurrentDropdownData,
            setDropdownVisible,
            phoneNumberCountryCode: phoneNumberCountryCodeState,
            fieldIsInvalid: fieldIsInvalid || isOutsideInvalid.isInvalid || isInvalid,
            isDisabled: disabled
        });
    }

    if (type === "taxNumber") {
        leftContent = LeftContentTaxNumber({
            taxNumberCountryCodeState,
            fieldIsInvalid: fieldIsInvalid || isOutsideInvalid.isInvalid || isInvalid,
            isDisabled: disabled
        });
    }

    if (type === "email") {
        leftContent = <img className="mailIcon" src={Mail05Icon} alt="" />;
    }

    if (type === "dropdown" && isDropdownMultiselect) {
        multidropdownOptions.onDoneClick = () => {
            setDropdownVisible(false);
        };
    }

    /* #endregion */

    useImperativeHandle(ref, () => ({
        isValid,
        getValue,
        setOutsideInvalid,
        outsideTagAdd,
        outsideTagRemove,
        onSelectAllClicked
    }));

    return (
        <StyledElems
            className={`FormField ${className} ${requiredType}`}
            style={{ ...style, gridColumn: `span ${formFieldColumSize}` }}>
            <div className="FormComponentContainer">
                <div className={`Frame51 ${disableTooltip ? "hidden" : ""}`}>
                    {tooltip && (
                        <Tooltip
                            title={tooltip}
                            color="#10141b"
                            overlayInnerStyle={{ textAlign: "center" }}
                            overlayStyle={{ fontSize: "0.75rem", maxWidth: tooltipMaxWidth, textAlign: "center" }}>
                            <i
                                className={`Icon fas fa-info-circle text-align-center ${
                                    fieldIsInvalid || isOutsideInvalid.isInvalid || isInvalid ? "text-red-500" : ""
                                }`}
                                aria-hidden="true"></i>
                        </Tooltip>
                    )}
                    {!tooltip && <i className="Icon fas fa-info-circle hidden" aria-hidden="true"></i>}
                    <div
                        className={`Title ${
                            fieldIsInvalid || isOutsideInvalid.isInvalid || isInvalid
                                ? "text-red-500"
                                : requiredType === "locked" || requiredType === "mandatoryLocked"
                                ? "text-gray-300"
                                : ""
                        }`}>
                        {title}
                    </div>
                    {onEdit && requiredType !== "locked" && (
                        <div className="field-edit-option" onClick={onEdit}>
                            <img src={Edit03} alt="Edit" />
                        </div>
                    )}
                </div>
                <div
                    className={`InputSelectorInverted ${
                        type !== "droppable"
                            ? ""
                            : currentValue
                            ? "dropped"
                            : isDragOver
                            ? "drag-over"
                            : isDragging && "dragged"
                    } ${resizable ? "resizable-content-horizontal" : ""}`}
                    style={{
                        width: defaultSize ? defaultSize : undefined
                    }}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}>
                    <div
                        className={`Search Frame48 ${
                            fieldIsInvalid || isOutsideInvalid.isInvalid || isInvalid
                                ? "invalid"
                                : (props.type === "tags" && currentTags?.length === 0) ||
                                  (props.type !== "tags" && !currentValue) ||
                                  requiredType === "locked" ||
                                  requiredType === "mandatoryLocked" ||
                                  requiredType === "none"
                                ? requiredType
                                : "simple"
                        } ${
                            disabled && type === "droppable"
                                ? ""
                                : disabled && type === "dropdown"
                                ? "cursor-pointer"
                                : disabled
                                ? "disabled"
                                : ""
                        } ${tagAsDropdown ? "tagAsDropdown" : ""} input-${inputID}`}
                        onClick={handleTagFieldClick}
                        style={{
                            height: "unset",
                            minHeight: "37px",
                            borderLeft: requiredTypeColor ? `5px solid ${requiredTypeColor}` : ""
                        }}>
                        {leftContent !== undefined && (
                            <>
                                <div
                                    className={`Frame48LeftContent Frame-${inputID}`}
                                    style={{
                                        cursor:
                                            requiredType === "locked" || requiredType === "mandatoryLocked"
                                                ? "not-allowed"
                                                : type === "date" || type === "datetime-local"
                                                ? "pointer"
                                                : "default"
                                    }}
                                    onClick={() =>
                                        type === "date" || type === "datetime-local"
                                            ? inputRef.current.showPicker()
                                            : null
                                    }>
                                    {leftContent}
                                </div>
                            </>
                        )}
                        {type === "tags" && (
                            <>
                                <div
                                    className={`Frame48TagsContainer ${
                                        requiredType !== "locked" && requiredType !== "mandatoryLocked"
                                            ? tagAsDropdown
                                                ? "cursor-pointer"
                                                : "cursor-text"
                                            : ""
                                    }`}>
                                    {currentTags?.map((tag, index) => (
                                        <TagComponent
                                            key={uuidv4()}
                                            index={index}
                                            bgColor={tag?.bgColor ?? tagAddedColor}
                                            tagName={tag?.tagName ?? "--"}
                                            hideRemoveButton={tag?.hideRemoveButton}
                                            onRemoveClick={() => handleTagRemove(index)}
                                        />
                                    ))}
                                    <input
                                        type="text"
                                        className={`TagsInput ${tagAsDropdown ? "hidden" : ""}`}
                                        value={currentValue}
                                        onChange={textChange}
                                        onKeyUp={onTagAdd}
                                        onBlur={textBlur}
                                        onFocus={textFocus}
                                        ref={tagInputRef}
                                        placeholder={
                                            placeholder
                                                ? placeholder
                                                : tagAddWhenEnterPressed
                                                ? "Press enter to add tags"
                                                : "Write and select a tag "
                                        }
                                        style={{ width: placeholderWidth }}
                                    />
                                </div>

                                {tagAsDropdown && (
                                    <ChevronDownSVG
                                        className={`dropdownArrow tagAsDropdown ${tagDropdownVisible ? "active" : ""}`}
                                    />
                                )}
                            </>
                        )}
                        {type === "dropdown" && (
                            <>
                                <div
                                    className={`Frame48Input Frame-${inputID}`}
                                    style={{
                                        cursor:
                                            requiredType === "locked" || requiredType === "mandatoryLocked"
                                                ? "not-allowed"
                                                : "pointer",
                                        color: textColor,
                                        height: "37px",
                                        display: "flex",
                                        alignItems: "center",
                                        position: "relative",
                                        background: backgroundColor,
                                        borderTopRightRadius: "4px",
                                        borderBottomRightRadius: "4px"
                                    }}
                                    onClick={onDropdownClick}>
                                    {useDropdownPlaceholderAsTag ? (
                                        <TagComponent
                                            className="ml-12px mr-32px"
                                            bgColor="rgba(0, 187, 0, 0.1)"
                                            textColor="#00BB00"
                                            xTextColor="#D1D1D1"
                                            tagName={currentValue || placeholder}
                                            useRoundedVariant={true}
                                            hideRemoveButton={!isDropdownMultiselect}
                                            onRemoveClick={() => {
                                                if (!isDropdownMultiselect) return;

                                                const data = dropdownData.map(item => {
                                                    return { ...item, selected: !item?.locked };
                                                });

                                                setDropdownSelectedData(data);
                                                onDropdownMultiSelect(data);
                                                setCurrentDropdownData(data);

                                                const checkedItemNamesTmp = data
                                                    .filter(item => item.selected)
                                                    .map(item => item.name);

                                                setDropdownCheckedItemsNames(checkedItemNamesTmp);

                                                const selectedItems = data.filter(item => item.selected);

                                                if (onDropdownChange) onDropdownChange(selectedItems, data);
                                                if (onFormCardChanges) onFormCardChanges(true);

                                                //onDropdownChange(data);
                                            }}
                                        />
                                    ) : (
                                        <label
                                            className={`text-one-line ${!currentValue ? "placeholder" : ""}`}
                                            onClick={onDropdownClick}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                alignContent: "center",
                                                overflow: "hidden",
                                                paddingRight: "30px",
                                                paddingLeft: "12px"
                                            }}>
                                            {currentValue || placeholder}
                                        </label>
                                    )}

                                    <div
                                        className={`dropdownArrow ${dropdownVisible ? "active" : ""} ${
                                            requiredType === "locked" || requiredType === "mandatoryLocked"
                                                ? "disabled"
                                                : ""
                                        }`}>
                                        <ChevronDownSVG />
                                    </div>
                                </div>
                            </>
                        )}
                        {type !== "tags" && type !== "dropdown" && (
                            <div
                                className={`${type === "date" && !currentValue ? "no-date" : "has-date"}`}
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    flexShrink: "1",
                                    overflow: "hidden"
                                }}>
                                <div className={`inputFieldContainer ${multiLine?.autoExpand ? "pr-0" : ""}`}>
                                    {textPrefix && (
                                        <div className="textPrefix" onClick={handleTextPrefixClick}>
                                            {textPrefix}
                                        </div>
                                    )}
                                    <CustomInputField
                                        multilineoptions={multiLine}
                                        id={`myDateInput-${inputID}`}
                                        ref={inputRef}
                                        type={type === "phoneNumber" || type === "taxNumber" ? "text" : type}
                                        min={minDate}
                                        max={maxDate}
                                        placeholder={placeholder}
                                        onBlur={textBlur}
                                        onFocus={textFocus}
                                        disabled={disabled}
                                        required={required}
                                        value={getDisplayValue() || ""}
                                        onChange={textChange}
                                        minLength={minLength}
                                        maxLength={maxLength}
                                        //onKeyUp={onTagAdd}
                                        //onKeyDown={onKeyDown}
                                        className={`Frame48Input ${
                                            type === "date" || type === "datetime-local"
                                                ? !currentValue
                                                    ? "datepicker no-date"
                                                    : "datepicker has-date"
                                                : ""
                                        }`}
                                        style={{
                                            //minWidth: "100px",
                                            height: "37px",
                                            color: textColor,
                                            cursor:
                                                type === "dropdown"
                                                    ? "pointer"
                                                    : type === "droppable"
                                                    ? "default"
                                                    : disabled
                                                    ? "not-allowed"
                                                    : "text",
                                            textOverflow: "ellipsis"
                                        }}
                                    />
                                </div>
                                {(type === "date" || type === "datetime-local") && props?.datePickerPlaceholder && (
                                    <label
                                        htmlFor={`myDateInput-${inputID}`}
                                        className="placeholder"
                                        style={{ display: "none", color: textColor, cursor: "pointer" }}
                                        onClick={() => inputRef.current.showPicker()}>
                                        {props?.datePickerPlaceholder}
                                    </label>
                                )}
                                {type === "droppable" && requiredType !== "locked" && currentValue && (
                                    <div className="droppedRemove" onClick={onDroppedRemove}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="17"
                                            viewBox="0 0 16 17"
                                            fill="none">
                                            <path
                                                d="M11.5286 12.9714C11.7889 13.2318 12.211 13.2318 12.4714 12.9714C12.7317 12.7111 12.7317 12.289 12.4714 12.0286L8.94277 8.50004L12.4714 4.97145C12.7317 4.7111 12.7317 4.28899 12.4714 4.02864C12.211 3.76829 11.7889 3.76829 11.5286 4.02864L7.99996 7.55723L4.47136 4.02864C4.21101 3.76829 3.7889 3.76829 3.52855 4.02864C3.2682 4.28899 3.2682 4.7111 3.52855 4.97144L7.05715 8.50004L3.52855 12.0286C3.2682 12.289 3.2682 12.7111 3.52855 12.9714C3.7889 13.2318 4.21101 13.2318 4.47136 12.9714L7.99996 9.44285L11.5286 12.9714Z"
                                                fill="#D1D1D1"
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        )}
                        {rightContent !== undefined && (
                            <div className="Frame48RightContent" style={{ color: getTextColor() }}>
                                {rightContent}
                            </div>
                        )}
                    </div>
                </div>

                {(type === "dropdown" || showDropdown) && (
                    <div
                        className={`dropdownContainer-${inputID}`}
                        style={{ position: "absolute", width: "100%", zIndex: "9999" }}>
                        <DropdownOverlay
                            isSearchable={isDropdownSearchable || type === "phoneNumber"}
                            data={currentDropdownData}
                            onSingleItemClick={onDropdownSingleSelect}
                            isChangeColor={!isDropdownMultiselect}
                            overlayType={isDropdownMultiselect ? OverlayType.MULTI_SELECT : OverlayType.UNI_SELECT}
                            multiSelectData={onDropdownMultiSelect}
                            isVisible={dropdownVisible}
                            style={{
                                width: "100%",
                                top: dropdownOverlayTop,
                                zIndex: 1000,
                                ...dropdownOverlayStyle
                            }}
                            checkedItemNames={isDropdownMultiselect ? dropdownCheckedItemsNames : undefined}
                            hasDescriptionFields={props?.dropdownSettings?.hasDescriptionFields}
                            hasLockedFields={props?.dropdownSettings?.hasLockedFields}
                            widthPercentage={props?.dropdownSettings?.widthPercentage}
                            multidropdownOptions={multidropdownOptions}
                            scrollableSize={isDropdownMultiselect && isDropdownSearchable ? 324 : 224}
                        />
                    </div>
                )}

                <div
                    className={`Frame50 ${disableAssistiveText ? "hidden" : ""}`}
                    style={{ color: getAssistiveTextColor() }}>
                    <div className="AssistiveText">{typeInvalidAssistiveText || assistiveText}</div>
                </div>
            </div>
        </StyledElems>
    );
});

export default FormField;

/* #region SMALL COMPONENTS */
const CustomInputField = forwardRef((props, ref) => {
    const { type, id, multilineoptions } = props;

    const onKeyDown = e => {
        const type = props.type;

        if (
            ["e", "E", "+", "-", " ", "."].includes(e.key) &&
            (type === "number" || type === "phoneNumber" || type === "taxNumber")
        )
            e.preventDefault();
    };

    return multilineoptions?.isMultiLine && type === "text" ? (
        <textarea
            {...props}
            rows={multilineoptions?.numberOfLines}
            ref={ref}
            onKeyDown={onKeyDown}
            className={`${props?.className} custominput-${id} ${
                multilineoptions?.autoExpand ? "autoResize pt-8px pb-8px" : ""
            }`}
        />
    ) : (
        <input {...props} ref={ref} onKeyDown={onKeyDown} className={`${props?.className} custominput-${id}`} />
    );
});

const LeftContentPhoneNumber = props => {
    const {
        showDropdown,
        setShowDropdown,
        setCurrentDropdownData,
        setDropdownVisible,
        phoneNumberCountryCode,
        fieldIsInvalid,
        isDisabled
    } = props;

    const onPhoneClick = () => {
        if (isDisabled) return;
        setShowDropdown(!showDropdown);
        setCurrentDropdownData(getDropdownData("countryCodeOptions"));
        setDropdownVisible(!showDropdown);
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                height: "calc(100% + 12px)"
            }}
            onClick={onPhoneClick}>
            <div
                style={{
                    width: "30px",
                    fontSize: "13px",
                    color: fieldIsInvalid
                        ? "var(--text-icons-red-500)"
                        : isDisabled
                        ? "var(--text-icons-gray-300)"
                        : "#00BB00",
                    overflow: "hidden",
                    textAlign: "center",
                    marginLeft: "3px"
                }}>
                {phoneNumberCountryCode ? `+${phoneNumberCountryCode}` : ""}
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.70529 3.95541C2.86801 3.79269 3.13183 3.79269 3.29455 3.95541L5.49992 6.16079L7.70529 3.95541C7.86801 3.79269 8.13183 3.79269 8.29455 3.95541C8.45726 4.11813 8.45726 4.38195 8.29455 4.54467L5.79455 7.04467C5.63183 7.20739 5.36801 7.20739 5.20529 7.04467L2.70529 4.54467C2.54257 4.38195 2.54257 4.11813 2.70529 3.95541Z"
                    fill={!isDisabled ? "#D1D1D1" : "var(--text-icons-gray-300)"}
                />
            </svg>
        </div>
    );
};

const LeftContentTaxNumber = props => {
    const { taxNumberCountryCodeState, fieldIsInvalid, isDisabled } = props;

    return (
        <div
            style={{
                width: "27px",
                fontSize: "14px",
                color: fieldIsInvalid
                    ? "var(--text-icons-red-500)"
                    : isDisabled
                    ? "var(--text-icons-gray-300)"
                    : "#00BB00",
                overflow: "hidden",
                textAlign: "center"
            }}>
            {taxNumberCountryCodeState ? `${taxNumberCountryCodeState}` : ""}
        </div>
    );
};

const ChevronDownSVG = props => {
    const { className = "" } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.52864 6.02864C3.78899 5.76829 4.2111 5.76829 4.47145 6.02864L8.00004 9.55723L11.5286 6.02864C11.789 5.76829 12.2111 5.76829 12.4714 6.02864C12.7318 6.28899 12.7318 6.7111 12.4714 6.97145L8.47145 10.9714C8.2111 11.2318 7.78899 11.2318 7.52864 10.9714L3.52864 6.97145C3.26829 6.7111 3.26829 6.28899 3.52864 6.02864Z"
                fill="currentColor"
            />
        </svg>
    );
};
/* #endregion */
