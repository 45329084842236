//Assets
import success from "../../../assets/icons/purple-check.svg";
import error from "../../../assets/icons/x-close-red.svg";
import { mandatoryFields } from "../productsComponents/labelsInfo";

const checkMissingAiLabels = async (data, formData, fieldNamesMatch) => {
    //======| All fields |======//
    const allFields = Object.keys(formData);

    const updatedFormData = {}; //This upadetedFormData have to be already matched

    data?.forEach(item => {
        const { UIfieldName, columnName } = item;
        const databaseField = Object.keys(fieldNamesMatch).find(key => fieldNamesMatch[key] === UIfieldName);
        // This will filter for mandatory fields only
        // if (mandatoryFields.includes(databaseField)) {
        // This will check will make sure that the field exists in the formData
        if (databaseField && allFields.includes(databaseField)) {
            updatedFormData[databaseField] = columnName;
        }
    });
    //==========| Show missing labels popup (only for mandatory fields) |==========//
    const idCodesFields = {
        barcode: updatedFormData.barcode,
        referenceCode: updatedFormData.referenceCode
    };

    const itemIDFields = {
        name: updatedFormData.name,
        shortDescription: updatedFormData.shortDescription,
        description: updatedFormData.description
    };

    const itemVariationsFields = {
        size1: updatedFormData.size1,
        color1Name: updatedFormData.color1Name
    };

    const materialCareFields = {
        material1: updatedFormData.material1,
        care1: updatedFormData.care1
    };

    const transparencySustainability = {};

    const otherFields = {};

    const uploadImages = {
        //This one is mandatory but we are using an auto detect urls
        //image1: updatedFormData.image1
    };

    const fieldsStructure = {
        idCodesFields,
        itemIDFields,
        itemVariationsFields,
        materialCareFields,
        transparencySustainability,
        otherFields,
        uploadImages
    };

    return {
        updatedFormData,
        fieldsStructure
    };
};

const showMissingLabelsPopup = data => {
    const missingFields = Object.keys(data).filter(key => {
        const value = data[key];
        return Object.values(value).some(field => !field);
    });

    if (missingFields.length > 0) {
        return {
            show: true,
            heading: "OOPS!",
            headingColor: "var(--text-icons-red-500)",
            icon: error,
            title: "Missing mandatory fields",
            message:
                "One or more mandatory fields are missing labels. Please check the tabs marked with a red dot to identify the missing fields. You cannot proceed with the import until all mandatory fields have corresponding labels from your file.",
            confirmText: "CLOSE"
        };
    } else {
        return {
            show: true,
            heading: "SUCCESS!",
            headingColor: "#7763FE",
            icon: success,
            title: "All mandatory labels found!",
            message:
                "You can now check each group of fields (tabs on top) and match more labels from your list if any left. When done, click IMPORT to import the file.",
            confirmText: "CONTINUE"
        };
    }
};

export { checkMissingAiLabels, showMissingLabelsPopup };
