import styled from "styled-components";
import InfoCircle from "../../../assets/icons/info-circle-white.svg";
import ChevronUp from "../../../assets/images/invite-cher-up.svg";
import ChevronDown from "../../../assets/images/invite-cher-down.svg";
import { Tooltip } from "antd";

const MaxStoreStyles = styled.div`
    .max-stores {
        font-size: 0.75rem;
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-size: var(--subtitle-3-labels-font-size, 12px);
        font-weight: var(--subtitle-3-labels-font-weight, 400);
        position: relative;
    }
    .Icon {
        width: 10px;
        height: 10px;
        font-size: 9px;
        font-weight: 500;
        color: var(--text-icons-gray-200);
        position: relative;
        top: -1px;
    }
    .Title {
        color: var(--text-icons-gray-200);
        font-size: 0.75rem;
        font-weight: 400;
        word-wrap: break-word;
    }
    .input-selector-inverted4 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 114px;
        height: 125px;
        position: relative;
    }
    .search3 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-485 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: solid;
        //border-color: var(--text-and-icons-orange-500, #ff9900);
        border-color: var(--backgrounds-lines-n-500);
        border-width: 0px 0px 0px 5px;
        padding: 6px 16px 6px 16px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-485.filled-border {
        border-color: #141922;
    }
    ._200 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-size: var(--heading-2-font-size, 24px);
        letter-spacing: var(--heading-2-letter-spacing, -0.005em);
        font-weight: var(--heading-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .chevron-up {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 46px;
        top: calc(50% - 42px);
        overflow: visible;
        cursor: pointer;
    }
    .chevron-down3 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 46px;
        top: calc(50% - -18px);
        overflow: visible;
        cursor: pointer;
    }
    .frame-504 {
        align-self: stretch;
        padding-left: 5px;
        padding-right: 5px;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        display: inline-flex;
    }
    .info-circle7 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .input-text-number {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
`;
const MaxStoreInput = ({
    title,
    setInviteFormData,
    inviteFormData,
    tooltip,
    tooltipMaxWidth,
    fieldIsInvalid = false
}) => {
    const handleMaxStore = type => {
        if (type === "increase") {
            setInviteFormData(prev => ({
                ...prev,
                maxStoreQty: inviteFormData?.maxStoreQty + 1
            }));
            setInviteFormData(prev => ({
                ...prev,
                maxStore: inviteFormData?.maxStoreQty + 1
            }));
        }
        if (type === "decrease") {
            if (inviteFormData?.maxStoreQty > 1) {
                setInviteFormData(prev => ({
                    ...prev,
                    maxStoreQty: inviteFormData?.maxStoreQty - 1
                }));
                setInviteFormData(prev => ({
                    ...prev,

                    maxStore: inviteFormData?.maxStoreQty - 1
                }));
            } else {
                setInviteFormData(prev => ({
                    ...prev,
                    maxStoreQty: 0
                }));
                setInviteFormData(prev => ({
                    ...prev,
                    maxStore: "All"
                }));
            }
        }
    };

    return (
        <MaxStoreStyles>
            <div className="invite-row-two">
                <div className="input-text-number">
                    <div className="frame-504">
                        {/* <i className="Icon fas fa-info-circle" aria-hidden="true"></i> */}
                        {tooltip && (
                            <Tooltip
                                title={tooltip}
                                color="#10141b"
                                overlayInnerStyle={{ textAlign: "center" }}
                                overlayStyle={{ fontSize: "0.75rem", maxWidth: tooltipMaxWidth, textAlign: "center" }}>
                                <i
                                    className={`Icon fas fa-info-circle text-align-center ${
                                        fieldIsInvalid ? "text-red-500" : ""
                                    }`}
                                    aria-hidden="true"></i>
                            </Tooltip>
                        )}
                        {!tooltip && <i className="Icon fas fa-info-circle hidden" aria-hidden="true"></i>}
                        <div className={`max-stores Title ${fieldIsInvalid ? "text-red-500" : ""}`}>{title}</div>
                    </div>
                    <div className="input-selector-inverted4">
                        <div className="search3">
                            <div className={inviteFormData?.maxStoreQty > 0 ? "frame-485 filled-border" : "frame-485"}>
                                <div className="_200">{inviteFormData?.maxStore}</div>
                            </div>
                        </div>
                        <img
                            className="chevron-up"
                            src={ChevronUp}
                            alt="chevron-up0.svg"
                            onClick={() => handleMaxStore("increase")}
                        />
                        <img
                            className="chevron-down3"
                            src={ChevronDown}
                            alt="chevron-down2.svg"
                            onClick={() => handleMaxStore("decrease")}
                        />
                    </div>
                </div>
            </div>
        </MaxStoreStyles>
    );
};

export default MaxStoreInput;
