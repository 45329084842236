const resizeImage = (file, maxSizeKB, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = event => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const MAX_WIDTH = img.width;
            const MAX_HEIGHT = img.height;
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            // Ajust quality of image
            let resizedBase64 = canvas.toDataURL("image/jpeg", 0.9);
            let resizedFileSize = (resizedBase64.length * (3 / 4)) / 1024;
            const minQuality = 0.1; // Min Limit for image quality
            const maxQuality = 0.9; // Max Limit for image quality
            let low = minQuality;
            let high = maxQuality;

            while (resizedFileSize > maxSizeKB && high - low > 0.01) {
                const quality = (low + high) / 2;
                resizedBase64 = canvas.toDataURL("image/jpeg", quality);
                resizedFileSize = (resizedBase64.length * (3 / 4)) / 1024;

                if (resizedFileSize > maxSizeKB) {
                    high = quality;
                } else {
                    low = quality;
                }
            }

            callback(resizedBase64);
        };
    };
};

export default resizeImage;
