import { ConfigProvider, DatePicker } from "antd";
import { rangePresets, onRangeChange } from "../../../utils/utilHelpers";
import "../../../assets/css/components/DatePicker.css";
import { useEffect, useState } from "react";
import moment from "moment";

const { RangePicker } = DatePicker;

const DatePickerComponent = () => {
    const [firstDateString, setFirstDateString] = useState("6 March 2025");
    const [secondDateString, setSecondDateString] = useState("6 March 2025");

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            setFirstDateString(dateStrings[0]);
            setSecondDateString(dateStrings[1]);
        }
    };

    const formattedFirstDate = moment(firstDateString).format("DD MMMM YYYY");
    const formattedSecondDate = moment(secondDateString).format("DD MMMM YYYY");

    return (
        <div>
            <RangePicker
                presets={rangePresets}
                onChange={onRangeChange}
                popupStyle={{
                    position: "absolute",
                    left: "280px"
                }}
                style={{
                    opacity: "0",
                    position: "absolute"
                }}
                popupClassName="range-picker-pop-up"
            />
            <div className="dashboard-custom-date-picker">
                <i className="fa-light fa-calendar"></i>
                <div className="dates-wrapper">
                    <div>{formattedFirstDate}</div>-<div>{formattedSecondDate}</div>
                </div>
                <i className="fa-light fa-chevron-down"></i>
            </div>
        </div>
    );
};

export default DatePickerComponent;
