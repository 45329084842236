import React, { useState } from "react";

const LayoutContext = React.createContext();

export const LayoutProvider = ({ children }) => {
    // LOADINGS STATES
    const [isLoadingAccountPage, setIsLoadingAccountPage] = useState(false);

    // ALL PAGES STATES
    const [layoutType, setLayoutType] = useState("Grid");
    const [controlBarLeftBtns, setControlBarLeftBtns] = useState([]);
    const [controlBarCenterBtns, setControlBarCenterBtns] = useState([]);
    const [controlBarRigthBtns, setControlBarRightBtns] = useState([]);
    const [isMobile, setIsMobile] = useState(false);

    const [filterHeaderProperties, setFilterHeaderProperties] = useState({
        visible: true,
        onFilterRefreshAction: undefined,
        onSearch: undefined,
        onRefreshAction: undefined,
        filterOptions: undefined,
        onFilterAction: undefined,
        onResetAction: undefined,
        className: undefined,
        organizeText: undefined,
        //TODO: All above props are deprecated, we should use only customContentHeader
        customContentHeader: undefined
    });

    // POPUPS STATES
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);
    const [showIsSubscribedPopup, setShowIsSubscribedPopup] = useState(false);
    const [showIsAccountSavedPopup, setShowIsAccountSavedPopup] = useState(false);
    const [showIsBrandSavedPopup, setShowIsBrandSavedPopup] = useState(false);
    const [showIsCompanySavedPopup, setShowIsCompanySavedPopup] = useState(false);
    const [readyToSavePopup, setReadyToSavePopup] = useState(false);
    const [showReadyToRetailerSavePopup, setShowReadyToRetailerSavePopup] = useState(false);
    const [showIsStoreSavedPopup, setShowIsStoreSavedPopup] = useState(false);
    const [productsImportedPopup, setProductsImportedPopup] = useState({
        show: false,
        productsImported: 0,
        productsUpdated: 0,
        productsNotImported: 0,
        gtinsImported: 0,
        gtinsUpdated: 0,
        gtinsNotImported: 0
    });
    const [showFirstBOImagePopup, setShowFirstBOImagePopup] = useState(false);
    const [storeCreatedPopup, setStoreCreatedPopup] = useState({
        ourId: null,
        showPopup: false
    });

    return (
        <LayoutContext.Provider
            value={{
                isLoadingAccountPage,
                setIsLoadingAccountPage,

                layoutType,
                setLayoutType,
                controlBarLeftBtns,
                setControlBarLeftBtns,
                controlBarCenterBtns,
                setControlBarCenterBtns,
                controlBarRigthBtns,
                setControlBarRightBtns,
                isMobile,
                setIsMobile,

                filterHeaderProperties,
                setFilterHeaderProperties,

                showLogoutPopup,
                setShowLogoutPopup,
                showIsSubscribedPopup,
                setShowIsSubscribedPopup,
                showIsAccountSavedPopup,
                setShowIsAccountSavedPopup,
                showIsBrandSavedPopup,
                setShowIsBrandSavedPopup,
                showIsCompanySavedPopup,
                setShowIsCompanySavedPopup,
                readyToSavePopup,
                setReadyToSavePopup,
                showReadyToRetailerSavePopup,
                setShowReadyToRetailerSavePopup,
                showIsStoreSavedPopup,
                setShowIsStoreSavedPopup,
                productsImportedPopup,
                setProductsImportedPopup,
                showFirstBOImagePopup,
                setShowFirstBOImagePopup,
                storeCreatedPopup,
                setStoreCreatedPopup
            }}>
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutContext;
