import { useOutletContext } from "react-router";
import { APP_TITLE } from "../../../config/constant";
import { useEffect, useRef, useState } from "react";
import SimpleButtonContentHeader from "./createCompanyComponents/SimpleButtonContentHeader";
import FormField from "../../common/forms/FormField";
import styled from "styled-components";
import FormCard from "../../common/forms/FormCard";
import { getDropdownData } from "../../../utils/dropdownData";
import CardComponentContainer from "../../common/cardContainers/CardComponentContainer";
import AddPerson from "./KycPolicyComponent/AddPerson";
import FieldsRightLegends from "../../common/forms/FieldsRightLegends";
import PlusIcon from "../../../assets/images/plus.svg";
import * as Library from "../../../utils/Library";
import { fetchAddressFromCoordinates, getLatLngUsingPlusCode } from "../../../utils/LocationLib";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
/* #region SCOPED CSS */
const StyledPage = styled.div`
    .main-card__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .form-card__footer {
        display: flex;
        justify-content: flex-end;
    }
    .add-person-btn {
        height: 25px;
        width: 80px;
        border: 1px solid;
        display: flex;
        justify-content: center;
        gap: 4px;
        border-radius: 4px;
        padding: 10px;
        align-items: center;
        cursor: no-drop;
    }
`;
/* #endregion */
export default function KycPolicyPage() {
    document.title = `${APP_TITLE} - KYC POLICY`;
    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const companyKycLegislation = useRef();
    const formKycPolicy = useRef();
    const formCompanyAddress = useRef();
    const formAddPersonRefs = useRef([]);
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    const companyKycLegislationFieldsRefs = [];
    const formKycPolicyFieldsRefs = [];
    const formAddPersonFieldsRefs = [];
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
    const [countryOptions] = useState(getDropdownData("countryOptions"));
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [country, setCountry] = useState("");
    const [companyCountry, setCompanyCountry] = useState("");
    const [currency, setCurrency] = useState("");
    const [updateForm, setUpdateForm] = useState(false);
    const [persons, setPersons] = useState([
        {
            roles: {
                chairman: false,
                ceo: false,
                director: false,
                representative: false
            },
            responsibilities: {
                responsibilitiesRepresentative: false,
                primaryRepresentative: true,
                owns25Percent: false,
                governingBoardMember: false
            },
            logoFieldsData: [
                {
                    label: "National identity number card (Front)",
                    varName: "nincardFrontData",
                    requiredType: "recommended",
                    imageData: null,
                    gridColumnSize: 2
                },
                {
                    label: "National identity number card (Back)",
                    varName: "nincardBackData",
                    requiredType: "recommended",
                    imageData: null,
                    gridColumnSize: 2
                }
            ],
            locationData: {
                legalBusinessName: "",
                googlePlusCode: "",
                country: "",
                addressCountry: "",
                city: "",
                postalCode: "",
                addressOne: "",
                addressTwo: "",
                latitude: "",
                longitude: ""
            }
        }
    ]);
    const [isLoading, setIsLoading] = useState(false);
    const [fetchingCurrency, setFetchingCurrency] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setShowErrorMessage] = useState("");
    const [highlightNotValidFields, setHighlightNotValidFields] = useState(false);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!props?.layoutType) return;
        setupHeaders(props, saveButtonEnabled, onSaveClicked);
    }, [props?.layoutType, saveButtonEnabled, persons]);
    useEffect(() => {
        const stripeAccountID = localStorage.getItem("stripeAccountID");
        if (!stripeAccountID) {
            return false;
        }
        const companyID = localStorage.getItem("currentCompanyID");
        Library.makeCommonPostRequest(
            "Stripe",
            "getStripeConnectAccountData",
            { companyID },
            false,
            setFetchingCurrency
        )
            .then(response => {
                const { data } = response?.data;
                if (data) {
                    setUpdateForm(true);
                } else {
                    setUpdateForm(false);
                }
                // Pre-fill the form fields with the received data
                const connectedAccount = data?.connectedAccount;
                const personsData = data?.persons?.data;

                // Find the matching country option
                const matchingCompanyCountryOption = countryOptions.find(
                    option => option?.id === connectedAccount?.company?.address?.country
                );

                if (matchingCompanyCountryOption) {
                    setCompanyCountry(matchingCompanyCountryOption?.value);
                }
                const matchingCountryOption = countryOptions.find(option => option?.id === connectedAccount?.country);
                if (matchingCountryOption) {
                    setCountry(matchingCountryOption?.value);
                }
                getCurrency(connectedAccount?.country);
                //setCurrency(connectedAccount?.default_currency?.toUpperCase());
                const updatedPersons = personsData?.map(person => ({
                    roles: {
                        chairman: person?.relationship?.title === "Chairman",
                        ceo: person?.relationship?.title === "CEO",
                        director: person?.relationship?.director,
                        representative: person?.relationship?.representative
                    },
                    responsibilities: {
                        responsibilitiesRepresentative: person?.relationship?.representative,
                        primaryRepresentative: person?.relationship?.executive,
                        owns25Percent: person?.relationship?.owner,
                        governingBoardMember: person?.relationship?.director
                    },
                    logoFieldsData: [
                        {
                            label: "National identity number card (Front)",
                            varName: "nincardFrontData",
                            requiredType: "recommended",
                            imageData: null,
                            gridColumnSize: 2
                        },
                        {
                            label: "National identity number card (Back)",
                            varName: "nincardBackData",
                            requiredType: "recommended",
                            imageData: null,
                            gridColumnSize: 2
                        }
                    ],
                    locationData: {
                        legalBusinessName: connectedAccount?.company?.name,
                        googlePlusCode: "",
                        country: person?.address?.country,
                        addressCountry: person?.address?.country,
                        city: person?.address?.city,
                        postalCode: person?.address?.postal_code,
                        addressOne: person?.address?.line1,
                        addressTwo: person?.address?.line2,
                        latitude: "",
                        longitude: ""
                    },
                    firstName: person?.first_name,
                    middleName: "", // Assuming middle name is not provided in the response
                    lastName: person?.last_name,
                    jobTitle: person?.relationship?.title,
                    dateOfBirth: `${person?.dob?.year}-${String(person?.dob?.month).padStart(2, "0")}-${String(
                        person?.dob?.day
                    ).padStart(2, "0")}`,
                    email: person?.email,
                    phone: person?.phone,
                    nin: person?.id_number_provided ? "Provided" : "" // Assuming "Provided" if id_number_provided is true
                }));

                setPersons(updatedPersons);

                // Pre-fill bank account details
                const bankAccount = connectedAccount?.external_accounts?.data?.[0];
                formKycPolicyFieldsRefs?.iban?.current?.setValue(bankAccount?.account_number);
            })
            .catch(error => {
                console.error("Error fetching Stripe Connect Account Data:", error);
            });
    }, []);
    /* #endregion */
    /* #region METHODS */
    const selectCompanyCountry = data => {
        setCompanyCountry(data?.value);
        if (data?.value !== "Norway") {
            setShowError(true);
            setShowErrorMessage(
                `our company country KYC Legislation ( ${data?.value} ) is not yet integrated. 
                Kindly request BOtag Team to integrate it at support@botag.no.`
            );
        } else {
            setShowError(false);
            setShowErrorMessage("");
        }
    };
    const selectCountry = data => {
        setCountry(data?.value);
        getCurrency(data?.id);
    };
    const formatCurrencyOptions = (currencies, defaultCurrency) => {
        return currencies.map(currency => ({
            name: currency,
            value: currency,
            selected: currency === defaultCurrency
        }));
    };
    const getCurrency = countryCode => {
        try {
            Library.makeCommonPostRequest(
                "Stripe",
                "getStripeCountryBankSpecs",
                { countryCode: countryCode },
                false,
                setFetchingCurrency
            ).then(res => {
                const { data, status } = res?.data;
                if (status === 200) {
                    const formattedCurrencies = formatCurrencyOptions(
                        data?.currencies?.currency,
                        data?.defaultCurrency
                    );
                    setCurrencyOptions(formattedCurrencies);
                    setCurrency(data?.defaultCurrency);
                } else {
                    setCurrencyOptions([]);
                    setCurrency("");
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    const selectCurrency = data => {
        setCurrency(data?.value);
    };
    const handleCheckboxClick = (name, type, index) => {
        setPersons(prev => {
            const updatedPersons = [...prev];
            if (type === "roles") {
                updatedPersons[index].roles[name] = !updatedPersons[index]?.roles[name];
            } else if (type === "responsibilities") {
                updatedPersons[index].responsibilities[name] = !updatedPersons[index]?.responsibilities[name];
            }
            return updatedPersons;
        });
    };

    const checkFormIsValid = (highlightValidation = false) => {
        const allKycPolicysAreValid = formKycPolicy?.current?.allFieldsAreValid(highlightValidation);
        const isKycLegisationValid = companyKycLegislation?.current?.allFieldsAreValid(highlightValidation);
        setSaveButtonEnabled(allKycPolicysAreValid && isKycLegisationValid && !showError);
        return allKycPolicysAreValid && isKycLegisationValid && !showError;
    };
    const onSaveClicked = async () => {
        const isKycLegisationValid = companyKycLegislation?.current?.allFieldsAreValid(true);
        const isKycPolicyValid = formKycPolicy?.current?.allFieldsAreValid(true);
        const allPersonsValid = persons?.every((_, index) =>
            formAddPersonRefs?.current?.[index]?.allFieldsAreValid(true)
        );
        if (isKycPolicyValid && allPersonsValid && isKycLegisationValid) {
            const kycValues = formKycPolicy?.current?.getAllFieldValues();
            const personsData = persons?.map((person, index) => {
                const fieldValues = formAddPersonRefs?.current?.[index]?.getAllFieldValues();
                const [year, month, day] = fieldValues?.dateOfBirth?.split("-") || [];
                return {
                    firstName: fieldValues?.firstName,
                    middleName: fieldValues?.middleName,
                    lastName: fieldValues?.lastName,
                    email: fieldValues?.email,
                    phone: fieldValues?.phone,
                    nationalID: fieldValues?.nin,
                    address: fieldValues?.address,
                    dob: {
                        day: parseInt(day, 10),
                        month: parseInt(month, 10),
                        year: parseInt(year, 10)
                    },
                    isDirector: person?.roles?.director,
                    isOwner: person?.responsibilities?.owns25Percent,
                    isRepresentative: person?.roles?.representative,
                    title: person?.roles?.ceo ? "CEO" : person?.roles?.chairman ? "Chairman" : "Director",
                    isExecutive: person?.roles?.ceo || person?.roles?.chairman,
                    frontDocumentId: null,
                    //fieldValues?.images?.find(img => img.varName === "nincardFrontData")?.imageData || "",
                    backDocumentId: null //fieldValues?.images?.find(img => img.varName === "nincardBackData")?.imageData || ""
                };
            });

            try {
                const jsonPostData = {
                    companyID: localStorage.getItem("currentCompanyID"),
                    mcc: "5611",
                    bankInfo: {
                        country: kycValues?.country?.id,
                        currency: kycValues?.currency?.value,
                        accountNumber: kycValues?.iban,
                        accountHolderName: kycValues?.accountName,
                        accountHolderType: "company"
                    },
                    persons: personsData,
                    tosAcceptance: {
                        ip: "122.161.160.123"
                    },
                    capabilities: {
                        cardPayments: {
                            requested: true
                        },
                        transfers: {
                            requested: true
                        }
                    }
                };
                Library.makeCommonPostRequest(
                    "Stripe",
                    updateForm ? "updateStripeConnectedAccount" : "createStripeConnectedAccount",
                    jsonPostData,
                    false,
                    setIsLoading
                ).then(res => {
                    const { data, status, message } = res?.data;
                    if (status === 200) {
                        Library.showToastMessage({
                            type: "success",
                            message: message,
                            autoClose: 2000
                        });
                        localStorage.setItem("stripeAccountID", data?.connectedAccount?.id || "");
                    }
                });
            } catch (error) {
                console.error("Error fetching IP address:", error);
            }
        }
    };
    const handleOnImageUpload = (type, data, index) => {
        setPersons(prev => {
            const updatedPersons = [...prev];
            const newLogoFieldsData = [...updatedPersons[index].logoFieldsData];
            const fieldIndex = newLogoFieldsData.findIndex(field => field.varName === type);
            newLogoFieldsData[fieldIndex].imageData = data;
            updatedPersons[index].logoFieldsData = newLogoFieldsData;
            return updatedPersons;
        });
    };
    const handleAddPerson = () => {
        setPersons([
            ...persons,
            {
                roles: {
                    chairman: false,
                    ceo: false,
                    director: false,
                    representative: false
                },
                responsibilities: {
                    responsibilitiesRepresentative: false,
                    primaryRepresentative: false,
                    owns25Percent: false,
                    governingBoardMember: false
                },
                logoFieldsData: [
                    {
                        label: "National identity number card (Front)",
                        varName: "nincardFrontData",
                        requiredType: "recommended",
                        imageData: null,
                        gridColumnSize: 2
                    },
                    {
                        label: "National identity number card (Back)",
                        varName: "nincardBackData",
                        requiredType: "recommended",
                        imageData: null,
                        gridColumnSize: 2
                    }
                ],
                locationData: {
                    legalBusinessName: "",
                    googlePlusCode: "",
                    country: "",
                    addressCountry: "",
                    city: "",
                    postalCode: "",
                    addressOne: "",
                    addressTwo: "",
                    latitude: "",
                    longitude: ""
                }
            }
        ]);
    };
    const handleRemovePerson = index => {
        if (index === 0) return; // Prevent removing the first card
        setPersons(prev => prev.filter((_, i) => i !== index));
        formAddPersonRefs.current = formAddPersonRefs.current.filter((_, i) => i !== index);
    };

    async function fetchData(googlePlusCode, index) {
        if (googlePlusCode) {
            const res = await getLatLngUsingPlusCode(googlePlusCode);
            if (res) {
                fetchAddressFromCoordinates(res?.lat, res?.lng).then(res2 => {
                    setPersons(prev => {
                        const updatedPersons = [...prev];
                        updatedPersons[index].locationData = {
                            ...updatedPersons[index].locationData,
                            latitude: res?.lat,
                            longitude: res?.lng,
                            addressOne: res2?.street || "",
                            addressTwo: res2?.streetNumber || "",
                            postalCode: res2?.postCode || "",
                            country: res2?.country || "",
                            city: res2?.city || ""
                        };
                        return updatedPersons;
                    });
                });
            } else {
                setPersons(prev => {
                    const updatedPersons = [...prev];
                    updatedPersons[index].locationData = {
                        ...updatedPersons[index].locationData,
                        latitude: "",
                        longitude: "",
                        addressOne: "",
                        addressTwo: "",
                        postalCode: "",
                        country: "",
                        city: ""
                    };
                    return updatedPersons;
                });
            }
        }
    }
    /* #endregion */
    /* #region FORM FIELDS */
    const companyKycLegislationFields = [
        <FormField
            type="text"
            title="To company"
            tooltip="To company"
            requiredType="locked"
            assistiveText="KYC"
            assistiveTextColorOverride="#4F5B6D"
            varName="accountName"
            value={localStorage.getItem("currentCompanyName")}
            ref={el => (companyKycLegislationFieldsRefs.accountName = el)}
        />,
        <FormField
            type="dropdown"
            dropdownData={countryOptions}
            title="Company country"
            assistiveText="KYC"
            isDropdownSearchable={true}
            tooltip="Company country"
            requiredType="mandatory"
            varName="companyCountry"
            onDropdownChange={selectCompanyCountry}
            value={companyCountry}
            ref={el => (companyKycLegislationFieldsRefs.companyCountry = el)}
        />
    ];
    const formKycPolicyFields = [
        <FormField
            type="dropdown"
            dropdownData={countryOptions}
            title="Country"
            assistiveText="KYC"
            isDropdownSearchable={true}
            tooltip="Select country"
            requiredType="mandatory"
            varName="country"
            onDropdownChange={selectCountry}
            value={country}
            ref={el => (formKycPolicyFieldsRefs.country = el)}
        />,
        <FormField
            type="dropdown"
            dropdownData={currencyOptions}
            title="Currency"
            assistiveText="KYC"
            isDropdownSearchable={true}
            tooltip="Select currency"
            requiredType="mandatory"
            varName="currency"
            onDropdownChange={selectCurrency}
            value={currency}
            ref={el => (formKycPolicyFieldsRefs.currency = el)}
        />,
        <FormField
            type="text"
            title="IBAN"
            leftContent={<span className="fa fa-bank"></span>}
            assistiveText="KYC"
            requiredType="simple"
            varName="iban"
            ref={el => (formKycPolicyFieldsRefs.iban = el)}
        />
    ];
    const formAddPersonFields = index => [
        <FormField
            type="text"
            title="First name"
            tooltip="First name"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="firstName"
            value={persons?.[index]?.firstName || ""}
            ref={el => (formAddPersonFieldsRefs.firstName = el)}
        />,
        <FormField
            type="text"
            title="Middle name"
            tooltip="Middle name"
            requiredType="recommended"
            assistiveText="KYC"
            varName="middleName"
            value={persons?.[index]?.middleName || ""}
            ref={el => (formAddPersonFieldsRefs.middleName = el)}
        />,
        <FormField
            type="text"
            title="Last name"
            tooltip="Last name"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="lastName"
            value={persons?.[index]?.lastName || ""}
            ref={el => (formAddPersonFieldsRefs.lastName = el)}
        />,
        <FormField
            type="text"
            title="Job title"
            tooltip="Job title"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="jobTitle"
            value={persons?.[index]?.jobTitle || ""}
            ref={el => (formAddPersonFieldsRefs.jobTitle = el)}
        />,
        <FormField
            type="date"
            title="Date of birth"
            tooltip="Date of birth"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="dateOfBirth"
            leftContent={<span className="fa fa-calendar"></span>}
            value={persons?.[index]?.dateOfBirth || ""}
            ref={el => (formAddPersonFieldsRefs.dateOfBirth = el)}
        />,
        <FormField
            type="text"
            title="email"
            tooltip="email"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="email"
            leftContent={<span className="fa fa-envelope"></span>}
            value={persons?.[index]?.email || ""}
            ref={el => (formAddPersonFieldsRefs.email = el)}
        />,
        <FormField
            type="phoneNumber"
            title="Phone nr"
            tooltip="Phone nr"
            phoneNumberCountryCode="+351"
            assistiveText="KYC"
            requiredType="mandatory"
            varName="phone"
            value={persons?.[index]?.phone || ""}
            ref={el => (formAddPersonFieldsRefs.phone = el)}
        />,
        <FormField
            type="text"
            title="National identity number"
            tooltip="National identity number"
            requiredType="recommended"
            assistiveText="KYC"
            varName="nin"
            value={persons?.[index]?.nin || ""}
            ref={el => (formAddPersonFieldsRefs.nin = el)}
        />
    ];

    /* #endregion */
    return (
        <StyledPage>
            <FullScreenLoader isLoading={fetchingCurrency} />
            <FullScreenOverlay show={isLoading}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-green-500)"
                    heading="SAVING..."
                    showCancel={false}
                    showConfirm={false}
                    loading={isLoading}
                />
            </FullScreenOverlay>
            <FormCard
                title="COMPANY KYC LEGISLATION"
                ref={companyKycLegislation}
                numOfFieldsPerLine={4}
                showDefaultRightLegends={true}
                formFields={companyKycLegislationFields}
                formFieldRefs={companyKycLegislationFieldsRefs}
                onFormChanged={checkFormIsValid}
                showUnsavedChangesPopup={true}
                errorMessage={errorMessage}
            />
            <FormCard
                title="BANK ACCOUNT DETAILS"
                ref={formKycPolicy}
                numOfFieldsPerLine={4}
                showDefaultRightLegends={true}
                formFields={formKycPolicyFields}
                formFieldRefs={formKycPolicyFieldsRefs}
                onFormChanged={checkFormIsValid}
                showUnsavedChangesPopup={true}
            />
            <CardComponentContainer title="COMPANY MANAGEMENT" rightComponent={<FieldsRightLegends />}>
                <div className="main-card__content">
                    {persons.map((person, index) => (
                        <div key={index} style={{ position: "relative" }}>
                            <AddPerson
                                ref={el => (formAddPersonRefs.current[index] = el)}
                                formAddPersonFields={formAddPersonFields(index)}
                                handleCheckboxClick={(name, type) => handleCheckboxClick(name, type, index)}
                                checkboxValues={person}
                                personIndex={index}
                                documents={{
                                    logoFieldsData: person.logoFieldsData,
                                    setLogoFieldsData: data => {
                                        setPersons(prev => {
                                            const updatedPersons = [...prev];
                                            updatedPersons[index].logoFieldsData = data;
                                            return updatedPersons;
                                        });
                                    },
                                    highlightNotValidFields,
                                    setHighlightNotValidFields,
                                    handleOnImageUpload: (type, data) => handleOnImageUpload(type, data, index)
                                }}
                                address={{
                                    locationData: person.locationData,
                                    setLocationData: data => {
                                        setPersons(prev => {
                                            const updatedPersons = [...prev];
                                            updatedPersons[index].locationData = data;
                                            return updatedPersons;
                                        });
                                    },
                                    onGooglePlusCodeChange: e => {
                                        setPersons(prev => {
                                            const updatedPersons = [...prev];
                                            updatedPersons[index].locationData.googlePlusCode = e;
                                            fetchData(e, index);
                                            return updatedPersons;
                                        });
                                    }
                                }}
                                handleRemovePerson={() => handleRemovePerson(index)}
                            />
                        </div>
                    ))}
                    <div className="form-card__footer">
                        <div className="add-person-btn">
                            <img src={PlusIcon} alt="PlusIcon" />
                            <span style={{ color: "#4f5b6d" }}>PERSON</span>
                        </div>
                    </div>
                </div>
            </CardComponentContainer>
        </StyledPage>
    );
}

/* #region SMALL FUNCTIONS */
function setupHeaders(props, saveButtonEnabled, onSaveClicked) {
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Company", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/companies", active: false, disabled: false, title: "Companies" },
        { to: "/companies/kyc-policy", active: true, disabled: false, title: "KYC POLICY" },
        { to: "/companies/users", active: false, disabled: true, title: "Users" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: false, title: "Add", icon: "plus" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" },
        { to: "#", active: false, disabled: true, title: "Edit", icon: "edit" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader
                btnTitle="SAVE"
                btnEnabled={saveButtonEnabled}
                onClick={() => {
                    onSaveClicked();
                }}
            />
        )
    });
}
/* #endregion */
