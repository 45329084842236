import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import mobile from "../../../../assets/images/mobile.svg";
import { ReactComponent as Battery0 } from "../../../../assets/icons/battery0.svg";
import { ReactComponent as Cellular0 } from "../../../../assets/icons/cellular0.svg";
import { ReactComponent as Time0 } from "../../../../assets/icons/time0.svg";
import { ReactComponent as Wifi0 } from "../../../../assets/icons/wifi0.svg";
import { ReactComponent as BoIkon10 } from "../../../../assets/icons/bo-ikon-10.svg";
import { ReactComponent as Check0 } from "../../../../assets/icons/check0.svg";
import { ReactComponent as Icon0 } from "../../../../assets/icons/shopping-bag0.svg";
import { ReactComponent as EyeOff1 } from "../../../../assets/newicons/eye-off1.svg";
import { ReactComponent as Share071 } from "../../../../assets/newicons/share-071.svg";
import { ReactComponent as Frame291590 } from "../../../../assets/newicons/frame-291590.svg";
import ImageTags from "../../../common/forms/ImageTags";

const BOImageMobilePreviewContainer = styled.div`
    &.b-oimage-emulator,
    &.b-oimage-emulator * {
        box-sizing: border-box;
    }
    &.b-oimage-emulator {
        flex-shrink: 0;
        height: 675px;
        position: relative;
        overflow: hidden;
    }
    .rectangle-1 {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
        overflow: visible;
        background-image: url(${mobile});
    }
    .frame-295922361 {
        //background: #ffffff;
        border-radius: 45px;
        width: 320px;
        height: 649.15px;
        position: absolute;
        left: 12px;
        top: 14.36px;
        overflow: hidden;
    }
    .frame-313260 {
        width: 320px;
        height: 546px;
        position: absolute;
        left: 0px;
        top: 103.64px;
        overflow: hidden;
    }
    .group-3577 {
        position: absolute;
        inset: 0;
    }
    .mobile-background {
        background: #212121;
        width: 100%;
        height: 89.01%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 10.99%;
        top: 0%;
    }
    .mobile-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        //object-position: 50% 0%;
    }

    .footer {
        background: var(--surface-surface-secondary, #121212);
        border-style: solid;
        border-color: var(--border-border-primary-10, rgba(166, 166, 166, 0.1));
        border-width: 1px 0px 0px 0px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        width: 320px;
        height: 60px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        overflow: hidden;
    }
    .frame-12 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-14 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313666 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex: 1;
        height: 20px;
        position: relative;
    }
    .samsoe-samsoe-new-white-2 {
        background: var(--surface-surface-tertiary, #212121);
        border-radius: 40px;
        flex-shrink: 0;
        width: 86px;
        height: 20px;
        position: relative;
    }
    .frame-15 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-2 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29153 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }
    .eye-off {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .frame-29155 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }
    .share-07 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .brand-logo-class {
        flex-shrink: 0;
        width: 107px;
        height: 20px;
        position: relative;
        object-fit: contain;
    }
    .eye-off2 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .share-072 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .status-bar {
        background: var(--surface-surface-secondary, #121212);
        padding: 25px 14px 15px 34px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        width: 320px;
        position: absolute;
        left: 0px;
        top: -0.36px;
    }
    .time {
        flex-shrink: 0;
        width: 26.62px;
        height: 10.36px;
        position: relative;
        overflow: visible;
    }
    .stack {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 12px;
        position: relative;
    }
    .cellular {
        flex-shrink: 0;
        width: 17px;
        height: 10.67px;
        position: relative;
        overflow: visible;
    }
    .wifi {
        flex-shrink: 0;
        width: 15.27px;
        height: 10.97px;
        position: relative;
        overflow: visible;
    }
    .battery {
        flex-shrink: 0;
        width: 24.33px;
        height: 11.33px;
        position: relative;
        overflow: visible;
    }
    .header {
        background: var(--surface-surface-secondary, #121212);
        border-style: solid;
        border-color: var(--border-border-primary-10, rgba(166, 166, 166, 0.1));
        border-width: 0px 0px 1px 0px;
        padding: 24px 16px 24px 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 320px;
        height: 52px;
        position: absolute;
        left: 0px;
        top: 51.64px;
    }
    .frame-8 {
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .bo-ikon-1 {
        flex-shrink: 0;
        width: 33.78px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .frame-29219 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29517 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }
    .shopping-bag-03 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: hidden;
    }
    .icon {
        width: 76.65%;
        height: 75%;
        position: absolute;
        right: 11.68%;
        left: 11.67%;
        bottom: 12.5%;
        top: 12.5%;
        overflow: visible;
    }
    .check {
        border-radius: 31px;
        width: 14px;
        height: 58.33%;
        position: absolute;
        left: calc(50% - 1px);
        bottom: 0%;
        top: 41.67%;
        overflow: visible;
    }
    .frame-29159 {
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
        overflow: visible;
    }
`;

export default function BOImageMobilePreview(props) {
    const { brandLogoURL, className, imageURL } = props;

    /* #region VARS */
    const scrollRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    /* #endregion */

    /* #region METHODS */
    const handleMouseDown = e => {
        setIsDragging(true);
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = e => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Adjust the scroll speed
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const centerImage = () => {
        if (!scrollRef.current) return;
        scrollRef.current.scrollLeft = scrollRef.current.scrollWidth / 2 - scrollRef.current.clientWidth / 2;
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        const centerAttempts = [10, 100, 1000];

        centerAttempts.forEach(delay => {
            setTimeout(centerImage, delay);
        });
    }, [imageURL]);
    /* #endregion */

    return (
        <BOImageMobilePreviewContainer className={`b-oimage-emulator ${className}`}>
            <div className="rectangle-1" src="rectangle-10.svg" />
            <div className="frame-295922361">
                <div className="frame-313260">
                    <div
                        className="mobile-background"
                        ref={scrollRef}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                        style={{ overflow: "auto", cursor: isDragging ? "grabbing" : "grab" }}>
                        {imageURL && (
                            <ImageTags
                                src={imageURL}
                                alt="mobile-background"
                                allowEdit={false}
                                imageExtraScale={0.71383}
                                useLayout2={true}
                                allowScroll={true}
                            />
                        )}
                    </div>
                    <div className="footer">
                        <div className="frame-12">
                            <div className="frame-14">
                                {brandLogoURL && (
                                    <img className="brand-logo-class" src={brandLogoURL} alt="brand-logo" />
                                )}
                            </div>
                            <div className="frame-15">
                                <div className="frame-2">
                                    <div className="frame-29153">
                                        <EyeOff1 className="eye-off1" alt="eye-off" />
                                    </div>
                                    <div className="frame-29155">
                                        <Share071 className="share-072" alt="share" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="status-bar">
                    <Time0 className="time" />
                    <div className="stack">
                        <Cellular0 className="cellular" />
                        <Wifi0 className="wifi" />
                        <Battery0 className="battery" />
                    </div>
                </div>
                <div className="header">
                    <div className="frame-8">
                        <BoIkon10 className="bo-ikon-1" />
                    </div>
                    <div className="frame-29219">
                        <div className="frame-29517">
                            <div className="shopping-bag-03">
                                <Icon0 className="icon" />
                                <Check0 className="check" />
                            </div>
                        </div>
                        <Frame291590 className="frame-29159" />
                    </div>
                </div>
            </div>
        </BOImageMobilePreviewContainer>
    );
}
