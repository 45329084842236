import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CardGridSkeleton from "../gridCards/animations/CardGridSkeleton";
import { v4 as uuidv4 } from "uuid";
import RetailerRequestsGridCard from "../gridCards/RetailerRequestsGridCard";
import * as Library from "../../../utils/Library";
import moment from "moment";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: scroll;
        height: 100%;
        margin-right: -24px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 17px;
    }
`;
/*  #endregion */

/**
 * Renders an infinite grid of brand cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.className] - The additional CSS class name for the component.
 * @param {number} [props.limit=10] - The maximum number of cards to load per page.
 * @param {string} [props.queryKey] - The query key for fetching data.
 * @param {number} [props.minimumCardsFill] - The minimum number of cards to fill the grid.
 * @param {number} [props.minColumnWidth] - The minimum width of each grid column.
 * @param {Function} [props.onEditAction] - The callback function for the edit action.
 * @param {Function} [props.onDeleteAction] - The callback function for the delete action.
 * @param {Function} [props.onSuspendAction] - The callback function for the suspend action.
 * * @param {string} [props.statusFromPath] - The status from the path.
 * @returns {JSX.Element} The rendered component.
 */

export default function InfinityGridRetailerRequestsCard(props) {
    const pageLimit = props?.limit || 10;

    const gridLayoutStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${props?.minColumnWidth || "395px"}, 1fr))`,
        gap: "24px"
    };

    /* #region STATES */
    const scrollContainerRef = useRef(null);
    const [scroll, setScroll] = useState(true);
    const [minimumCardsFill, setminimumCardsFill] = useState(1); //props?.minimumCardsFill || pageLimit
    const requestEnabled = props?.requestEnabled ?? true;

    const [queryKey, setQueryKey] = useState(props?.queryKey ?? uuidv4());

    useEffect(() => {
        if (props?.queryKey) setQueryKey(props?.queryKey);
    }, [props?.queryKey]);

    const filters = props?.filters;

    const {
        data,
        isFetching: isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = Library.usePostInfiniteQuery(
        queryKey,
        "getRetailerRequests",
        {
            companyID: localStorage.getItem("currentCompanyID"),
            BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
            limit: pageLimit,
            ...filters
        },
        () => {
            setScroll(true);
        },
        false,
        requestEnabled
    );

    const pageList = data?.pages;
    //const totalCount = data?.pages[0]?.data?.totalCount;
    const nextDataCount = data?.pages[data?.pages?.length - 1].data?.nextDataCount;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        handleScroll();

        setminimumCardsFill(getTotalCards() > 0 ? getTotalCards() : 1);
    }, [data?.pages]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && !isLoading && scroll && !isFetchingNextPage) {
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [scrollContainerRef, isLoading, pageList, scroll, isFetchingNextPage, data?.pages]);
    /* #endregion */

    /* #region METHODS */
    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;

        const isAtBottom =
            Math.floor(scrollContainer.scrollHeight - scrollContainer.scrollTop) <= scrollContainer.clientHeight;

        if (isAtBottom && !isLoading && hasNextPage && !isFetchingNextPage) {
            setScroll(false);
            fetchNextPage();
        }
    };

    const getTotalCards = () => {
        return pageList?.reduce((acc, it) => {
            return acc + it?.data?.result?.length;
        }, 0);
    };

    const formatIsoToDateTime = isoDate => {
        if (!isoDate) return "--";
        const res = moment(isoDate).format("DD/MM/YYYY HH:mm");
        if (res === "Invalid date") return "--";
        return res;
    };

    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`} ref={scrollContainerRef}>
            {isLoading && !isFetchingNextPage ? (
                <div style={gridLayoutStyle}>
                    {Array.from({ length: 8 }, () => (
                        <CardGridSkeleton
                            key={uuidv4()}
                            height="420.28px"
                            minColumnWidth={props?.minColumnWidth || "395px"}
                        />
                    ))}
                </div>
            ) : (
                <div className="mb-24px" style={gridLayoutStyle}>
                    {pageList?.map(it => {
                        return it?.data?.result?.map(item => {
                            const status = item?.status;

                            const canAcceptInvite =
                                status === "Request" || status === "Invited" || status === "Enrolled";

                            // const contactEmail =
                            //     status !== "Active"
                            //         ? maskEmail(item?.invite?.contactEmail)
                            //         : item?.invite?.contactEmail;

                            // const contactPerson =
                            //     status !== "Active"
                            //         ? maskName(item?.invite?.contactFirstName)
                            //         : item?.invite?.contactFirstName;

                            return (
                                <RetailerRequestsGridCard
                                    key={item?._id}
                                    image={item?.retailBrand?.logos?.mainLogoURL || ""}
                                    imageOverlay={item?.retailBrand?.logos?.mainLogoURL || ""}
                                    title={item?.labelBrand?.name || "--"}
                                    titlePrefix="TO LABEL BRAND"
                                    id={item?.ourID || "BOTAG ID"}
                                    isCardEnabled={true}
                                    isCardSuspended={false}
                                    data={{
                                        requestedBy: item?.retailBrand?.name || "--",
                                        requestedOn: formatIsoToDateTime(item?.createdAt) || "--",
                                        acceptedOn:
                                            item?.statusLog && item?.statusLog?.find(x => x.status === "Accepted")
                                                ? formatIsoToDateTime(
                                                      item?.statusLog?.find(x => x.status === "Accepted")?.date
                                                  )
                                                : "--",

                                        requestedNumberOfStores: item?.maxStores || "--",
                                        status: item?.status || "--"
                                    }}
                                    edit={{ hidden: true }}
                                    suspend={{ hidden: true }}
                                    delete={{
                                        onClick:
                                            item?.status === "Accepted" || item?.status === "Declined"
                                                ? props.onDeleteInvitation.bind(this, item?._id)
                                                : undefined
                                    }}
                                    primaryButton={{
                                        title: "Decline",
                                        onClick: canAcceptInvite
                                            ? props.onDeclineInvitation.bind(this, item)
                                            : undefined
                                    }}
                                    secondaryButton={{
                                        title: "Accept",
                                        onClick: canAcceptInvite
                                            ? props.onAcceptInvitation.bind(
                                                  this,
                                                  item?._id,
                                                  item?.labelBrand?.name,
                                                  item?.retailBrand?.name
                                              )
                                            : undefined
                                    }}
                                />
                            );
                        });
                    })}

                    {!isFetchingNextPage &&
                        minimumCardsFill - getTotalCards() > 0 &&
                        Array.from({ length: minimumCardsFill - getTotalCards() }, (_, index) => (
                            <RetailerRequestsGridCard
                                key={index}
                                isCardEnabled={false}
                                primaryButton={{ title: "Decline" }}
                                secondaryButton={{ title: "Accept" }}
                                edit={{ hidden: true }}
                                suspend={{ hidden: true }}
                            />
                        ))}

                    {isFetchingNextPage &&
                        Array.from({ length: nextDataCount }, (_, index) => (
                            <CardGridSkeleton key={index} height="420.28px" />
                        ))}
                </div>
            )}
        </StyledPage>
    );
}
