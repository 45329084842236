import FormField from "../../../../common/forms/FormField";
import SimpleGridContainer from "../../../../common/forms/SimpleGridContainer";
import styled from "styled-components";
import React, { useEffect, useRef, useCallback } from "react";
import BtnLargeSolid from "../../../../common/buttons/BtnLargeSolid";

//Assets
import Plus from "../../../../../assets/icons/plus.svg";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .fc-form-container {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid var(--backgrounds-lines-n-100, #3c485a);
        background: var(--backgrounds-lines-n-300, #1a222d);
        padding: 16px;
        align-items: flex-start;
        gap: 16px;
        width: 100%;
        height: auto;
    }
    .form-label-button {
        display: flex;
        gap: 4px;
        color: var(--text-icons-gray-100, #d1d1d1);
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .form-label-button-tag {
        color: var(--text-icons-blue-500, #00aeef);
    }
`;
/* #endregion */

export default function Forms({
    index = 0,
    TabsItems,
    isDragging,
    formData,
    customFormData,
    handleInputChange,
    setTabIndex,
    handleOnEditField,
    customFieldNames,
    handleCustomInputChange,
    setCustomFieldSections,
    setCustomFieldNames,
    importedCustomFields,
    additionalFields,
    setAdditionalFields,
    handleCheckEmptyLabel
}) {
    //==========| Additional Fields |==========//

    const addField = category => {
        const fieldIndex = additionalFields[category].length + 1;
        //--
        const fieldName = `${category}CustomField${fieldIndex}`;
        const fieldTitle = customFieldNames[fieldName] || `Field ${fieldIndex}`;

        setAdditionalFields(prevState => ({
            ...prevState,
            [category]: [
                ...prevState[category],
                <FormField
                    key={fieldIndex}
                    type="droppable"
                    isDragging={isDragging && !TabsItems[0].locked}
                    title={fieldTitle}
                    tooltip={`Enter the ${fieldTitle}`}
                    requiredType={TabsItems[0].locked ? "locked" : "simple"}
                    value={formData[fieldName]}
                    onTextChange={value => handleCustomInputChange(fieldName, value)}
                    onEdit={() => handleOnEditField(fieldName)}
                />
            ]
        }));

        //Update custom field names
        setCustomFieldNames(prevState => ({
            ...prevState,
            [fieldName]: fieldTitle
        }));
        //Update custom field sections
        setCustomFieldSections(prevSections => ({
            ...prevSections,
            [fieldName]: category
        }));
    };
    //========================================//

    /* #region EFFECTS */
    const updateAdditionalFields = useCallback(() => {
        const updatedFields = {};
        for (const category in additionalFields) {
            updatedFields[category] = additionalFields[category].map((field, index) => {
                const fieldName = `${category}CustomField${index + 1}`;
                const fieldTitle = customFieldNames[fieldName] || `Field ${index + 1}`;
                return (
                    <FormField
                        key={index}
                        type={"droppable"}
                        isDragging={isDragging && !TabsItems[0].locked}
                        title={fieldTitle}
                        tooltip={`Enter the ${fieldTitle}`}
                        requiredType={TabsItems[0].locked ? "locked" : "simple"}
                        value={customFormData[fieldName]}
                        onTextChange={value => handleCustomInputChange(fieldName, value)}
                        onEdit={() => handleOnEditField(fieldName)}
                    />
                );
            });
        }
        setAdditionalFields(updatedFields);
    }, [customFieldNames, isDragging, customFormData]);

    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            updateAdditionalFields();
        } else {
            isMounted.current = true;
        }
    }, [isDragging, updateAdditionalFields]);
    /* #endregion */

    /* #region  SCOPED Form Tabs */
    //---- Sub
    const itemSizes = [
        <FormField
            //Editable field
            onEdit={() => handleOnEditField("size1")}
            title={customFieldNames?.size1 ? customFieldNames?.size1 : "Size 1"}
            //---
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Size 1"
            requiredType={TabsItems[0].locked ? "locked" : "mandatory"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.size1}
            onTextChange={value => handleInputChange("size1", value)}
            isInvalid={handleCheckEmptyLabel(formData.size1)}
        />,
        <FormField
            //Editable field
            onEdit={() => handleOnEditField("size2")}
            title={customFieldNames?.size2 ? customFieldNames?.size2 : "Size 2"}
            //---
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Size 2"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.size2}
            onTextChange={value => handleInputChange("size2", value)}
        />,
        <FormField
            //Editable field
            onEdit={() => handleOnEditField("size3")}
            title={customFieldNames?.size3 ? customFieldNames?.size3 : "Size 3"}
            //---
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Size 3"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.size3}
            onTextChange={value => handleInputChange("size3", value)}
        />,
        <FormField
            //Editable field
            onEdit={() => handleOnEditField("size4")}
            title={customFieldNames?.size4 ? customFieldNames?.size4 : "Size 4"}
            //---
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Size 4"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.size4}
            onTextChange={value => handleInputChange("size4", value)}
        />
    ];
    const itemColors = [
        <FormField
            onEdit={() => handleOnEditField("color1Name")}
            title={customFieldNames?.color1Name ? customFieldNames?.color1Name : "Color 1 name"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Color 1 name"
            requiredType={TabsItems[0].locked ? "locked" : "mandatory"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.color1Name}
            onTextChange={value => handleInputChange("color1Name", value)}
            isInvalid={handleCheckEmptyLabel(formData.color1Name)}
        />,
        <FormField
            onEdit={() => handleOnEditField("color1Description")}
            title={customFieldNames?.color1Description ? customFieldNames?.color1Description : "Color 1 description"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Color 1 description"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.color1Description}
            onTextChange={value => handleInputChange("color1Description", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("color1Code")}
            title={customFieldNames?.color1Code ? customFieldNames?.color1Code : "Color 1 code"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Color 1 code"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.color1Code}
            onTextChange={value => handleInputChange("color1Code", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("color1Hexacode")}
            title={customFieldNames?.color1Hexacode ? customFieldNames?.color1Hexacode : "Color 1 hexacode"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Color 1 hexacode"
            requiredType={TabsItems[0].locked ? "locked" : "recommended"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.color1Hexacode}
            onTextChange={value => handleInputChange("color1Hexacode", value)}
        />,
        ...additionalFields.itemColors,
        <AddFieldBtn key="addFieldBtn" onClick={() => addField("itemColors")} />
    ];
    //---
    const itemMaterials = [
        <FormField
            onEdit={() => handleOnEditField("material1")}
            title={customFieldNames?.material1 ? customFieldNames?.material1 : "Material 1"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Material 1"
            requiredType={TabsItems[0].locked ? "locked" : "mandatory"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.material1}
            onTextChange={value => handleInputChange("material1", value)}
            isInvalid={handleCheckEmptyLabel(formData.material1)}
        />,
        <FormField
            onEdit={() => handleOnEditField("material2")}
            title={customFieldNames?.material2 ? customFieldNames?.material2 : "Material 2"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Material 2"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.material2}
            onTextChange={value => handleInputChange("material2", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("material3")}
            title={customFieldNames?.material3 ? customFieldNames?.material3 : "Material 3"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Material 3"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.material3}
            onTextChange={value => handleInputChange("material3", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("material4")}
            title={customFieldNames?.material4 ? customFieldNames?.material4 : "Material 4"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Material 4"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.material4}
            onTextChange={value => handleInputChange("material4", value)}
        />,
        ...additionalFields.itemMaterials,
        <AddFieldBtn key="addFieldBtn" onClick={() => addField("itemMaterials")} />
    ];
    const itemCare = [
        <FormField
            onEdit={() => handleOnEditField("care1")}
            title={customFieldNames?.care1 ? customFieldNames?.care1 : "Care 1"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Care 1"
            requiredType={TabsItems[0].locked ? "locked" : "mandatory"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.care1}
            onTextChange={value => handleInputChange("care1", value)}
            isInvalid={handleCheckEmptyLabel(formData.care1)}
        />,
        <FormField
            onEdit={() => handleOnEditField("care2")}
            title={customFieldNames?.care2 ? customFieldNames?.care2 : "Care 2"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Care 2"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.care2}
            onTextChange={value => handleInputChange("care2", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("care3")}
            title={customFieldNames?.care3 ? customFieldNames?.care3 : "Care 3"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Care 3"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.care3}
            onTextChange={value => handleInputChange("care3", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("care4")}
            title={customFieldNames?.care4 ? customFieldNames?.care4 : "Care 4"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Care 4"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.care4}
            onTextChange={value => handleInputChange("care4", value)}
        />,
        ...additionalFields.itemCare,
        <AddFieldBtn key="addFieldBtn" onClick={() => addField("itemCare")} />
    ];
    //----
    const transparency = [
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Supplier name"
            tooltip="Enter the Supplier name"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.factoryOrigin}
            onTextChange={value => handleInputChange("factoryOrigin", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Production locations"
            tooltip="Enter the Production locations"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.productionLocations}
            onTextChange={value => handleInputChange("productionLocations", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Production dates"
            tooltip="Enter the Production dates"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.productionDates}
            onTextChange={value => handleInputChange("productionDates", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Raw material source"
            tooltip="Enter the Raw material source"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.rawMaterialSource}
            onTextChange={value => handleInputChange("rawMaterialSource", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Batch or lot number"
            tooltip="Enter the Batch or lot number"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.batchOrLotNumber}
            onTextChange={value => handleInputChange("batchOrLotNumber", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Storage information"
            tooltip="Enter the Storage information"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.storageInformation}
            onTextChange={value => handleInputChange("storageInformation", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Certifications and Standards"
            tooltip="Enter the Certifications and Standards"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.certificationsAndStandards}
            onTextChange={value => handleInputChange("certificationsAndStandards", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Labor practices"
            tooltip="Enter the Labor practices"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.laborPractices}
            onTextChange={value => handleInputChange("laborPractices", value)}
        />,
        ...additionalFields.transparency,
        <AddFieldBtn key="addFieldBtn" onClick={() => addField("transparency")} />
    ];
    const sustainability = [
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Carbon footprin"
            tooltip="Enter the Carbon footprin"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.carbonFootprint}
            onTextChange={value => handleInputChange("carbonFootprint", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Water usage"
            tooltip="Enter the Water usage"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.waterUsage}
            onTextChange={value => handleInputChange("waterUsage", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Energy consumption"
            tooltip="Enter the Energy consumption"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.energyConsumption}
            onTextChange={value => handleInputChange("energyConsumption", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Waste management"
            tooltip="Enter the Waste management"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.waterManagement}
            onTextChange={value => handleInputChange("waterManagement", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Chemical usage"
            tooltip="Enter the Chemical usage"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.chemicalUsage}
            onTextChange={value => handleInputChange("chemicalUsage", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Sustainable sourcing"
            tooltip="Enter the Sustainable sourcing"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.sustainableSourcing}
            onTextChange={value => handleInputChange("sustainableSourcing", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Packaging"
            tooltip="Enter the Packaging"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.packaging}
            onTextChange={value => handleInputChange("packaging", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Environmental certifications"
            tooltip="Enter the Environmental certifications"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.environmentalCertifications}
            onTextChange={value => handleInputChange("environmentalCertifications", value)}
        />,
        ...additionalFields.sustainability,
        <AddFieldBtn key="addFieldBtn" onClick={() => addField("sustainability")} />
    ];

    //---- Main
    const idCodes = "Custom Component";
    const itemID = [
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Style Name"
            tooltip="Enter the Style Name"
            requiredType={TabsItems[0].locked ? "locked" : "mandatory"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.name}
            onTextChange={value => handleInputChange("name", value)}
            isInvalid={handleCheckEmptyLabel(formData.name)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Style code"
            tooltip="Enter the Style code"
            requiredType="locked"
            // assistiveText="BOTAG"
            // assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.referenceCode}
            //onTextChange={value => handleInputChange("styleCode", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Short description"
            tooltip="Enter the Short description"
            requiredType={TabsItems[0].locked ? "locked" : "mandatory"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.shortDescription}
            onTextChange={value => handleInputChange("shortDescription", value)}
            isInvalid={handleCheckEmptyLabel(formData.shortDescription)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Description"
            tooltip="Enter the Description"
            requiredType={TabsItems[0].locked ? "locked" : "mandatory"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.description}
            onTextChange={value => handleInputChange("description", value)}
            isInvalid={handleCheckEmptyLabel(formData.description)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Collection"
            tooltip="Enter the Collection"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.collectionName}
            onTextChange={value => handleInputChange("collectionName", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Gender"
            tooltip="Enter the Gender"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.gender}
            onTextChange={value => handleInputChange("gender", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Product Type"
            tooltip="Enter the Product Type"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.productType}
            onTextChange={value => handleInputChange("productType", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Category"
            tooltip="Enter the Category"
            requiredType={TabsItems[0].locked ? "locked" : "recommended"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.category}
            onTextChange={value => handleInputChange("category", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="SKU"
            tooltip="Enter the SKU"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.SKU}
            onTextChange={value => handleInputChange("SKU", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("productID")}
            title={customFieldNames?.productID ? customFieldNames?.productID : "Product ID"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Product ID"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.productID}
            onTextChange={value => handleInputChange("productID", value)}
        />,
        <FormField
            title="Country of origin"
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Country of origin"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.countryOfOrigin}
            onTextChange={value => handleInputChange("countryOfOrigin", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("specifications")}
            title={customFieldNames?.specifications ? customFieldNames?.specifications : "Specifications"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Specifications"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.specifications}
            onTextChange={value => handleInputChange("specifications", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("features")}
            title={customFieldNames?.features ? customFieldNames?.features : "Features"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Features"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.features}
            onTextChange={value => handleInputChange("features", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("qualityDescription")}
            title={customFieldNames?.qualityDescription ? customFieldNames?.qualityDescription : "Quality description"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Quality description"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.qualityDescription}
            onTextChange={value => handleInputChange("qualityDescription", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("assortment")}
            title={customFieldNames?.assortment ? customFieldNames?.assortment : "Assortment"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Assortment"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.assortment}
            onTextChange={value => handleInputChange("assortment", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("assortmentCode")}
            title={customFieldNames?.assortmentCode ? customFieldNames?.assortmentCode : "Assortment code"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Assortment code"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.assortmentCode}
            onTextChange={value => handleInputChange("assortmentCode", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("comments")}
            title={customFieldNames?.comments ? customFieldNames?.comments : "Comments"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Comments"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            assistiveText="BOTAG"
            assistiveTextColorOverride={TabsItems[0].locked ? "#4F5B6D" : "#00AEEF"}
            value={formData.comments}
            onTextChange={value => handleInputChange("comments", value)}
        />
    ];
    const itemVariations = [itemSizes, itemColors];
    const materialCare = [itemMaterials, itemCare];
    const transparencySustainability = [transparency, sustainability];
    const otherFields = [
        <FormField
            onEdit={() => handleOnEditField("weightCustomField")}
            title={customFieldNames?.weightCustomField ? customFieldNames?.weightCustomField : "Weight"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Weight"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.weightCustomField}
            onTextChange={value => handleInputChange("weightCustomField", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("tariffCode")}
            title={customFieldNames?.tariffCode ? customFieldNames?.tariffCode : "Tariff code"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Tariff code"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.tariffCode}
            onTextChange={value => handleInputChange("tariffCode", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("field3")}
            title={customFieldNames?.field3 ? customFieldNames?.field3 : "Field 3"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Field 3"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.field3}
            onTextChange={value => handleInputChange("field3", value)}
        />,
        <FormField
            onEdit={() => handleOnEditField("field4")}
            title={customFieldNames?.field4 ? customFieldNames?.field4 : "Field 4"}
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            tooltip="Enter the Field 4"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.field4}
            onTextChange={value => handleInputChange("field4", value)}
        />,
        ...additionalFields.otherFields,
        <AddFieldBtn key="addFieldBtn" onClick={() => addField("otherFields")} />
    ];
    const imagesLinks = [
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Main Image"
            tooltip="Enter the Main Image"
            requiredType={TabsItems[0].locked ? "locked" : "mandatory"}
            value={formData.image1}
            onTextChange={value => handleInputChange("image1", value)}
            isInvalid={handleCheckEmptyLabel(formData.image1)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Image 2"
            tooltip="Enter the Image 2"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.image2}
            onTextChange={value => handleInputChange("image2", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Image 3"
            tooltip="Enter the Image 3"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.image3}
            onTextChange={value => handleInputChange("image3", value)}
        />,
        <FormField
            type={"droppable"}
            isDragging={isDragging && !TabsItems[0].locked}
            title="Image 4"
            tooltip="Enter the Image 4"
            requiredType={TabsItems[0].locked ? "locked" : "simple"}
            value={formData.image4}
            onTextChange={value => handleInputChange("image4", value)}
        />,
        ...additionalFields.imagesLinks,
        <AddFieldBtn key="addFieldBtn" onClick={() => addField("imagesLinks")} />
    ];
    /* #endregion */
    const FormsCombiner = [
        idCodes,
        itemID,
        itemVariations,
        materialCare,
        transparencySustainability,
        otherFields,
        imagesLinks
    ];

    //====| Divider titles |====//
    itemSizes.title = "SIZES/DIMENSIONS";
    itemColors.title = "COLORS";

    itemMaterials.title = "MATERIALS";
    itemCare.title = "CARE";

    transparency.title = "TRANSPARENCY";
    sustainability.title = "SUSTAINABILITY";

    return (
        <StyledPage>
            <div className="fc-form-container">
                {Array.isArray(FormsCombiner[index][0]) ? (
                    <>
                        {FormsCombiner[index].map((fieldsArray, arrayIndex) => (
                            <React.Fragment key={`array-${arrayIndex}`}>
                                <FormDivider title={fieldsArray.title} />
                                <SimpleGridContainer gap="16px" numOfFieldsPerLine={4} style={{ width: "100%" }}>
                                    {fieldsArray.map((field, ind) => (
                                        <div key={`${ind}${index}`}>{field}</div>
                                    ))}
                                </SimpleGridContainer>
                            </React.Fragment>
                        ))}
                    </>
                ) : (
                    <SimpleGridContainer gap="16px" numOfFieldsPerLine={4} style={{ width: "100%" }}>
                        {FormsCombiner[index].map((field, ind) => {
                            return <div key={`${ind}${index}`}>{field}</div>;
                        })}
                    </SimpleGridContainer>
                )}
            </div>
            <div
                style={{
                    display: "flex",
                    padding: "10px 10px 10px 10px",
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                <div className="form-label-button">
                    <div className="form-label-button-tag">BOTAG</div>
                    <div>- This information will be displayed on the BOtag or on HeyBO product description</div>
                </div>
                <BtnLargeSolid
                    className="ml-10px"
                    text={"CONTINUE"}
                    disabled={TabsItems[index + 1].locked}
                    disabledColor={"#10141B"}
                    onClick={() => setTabIndex(index + 1)}
                />
            </div>
        </StyledPage>
    );
}

/* #region  SCOPED CSS */
const StyledDivider = styled.div`
    & {
        display: flex;
        width: 100%;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }

    .divider-title {
        color: var(--texts-primary-n-100, #d1d1d1);
        font-size: 8px;
        white-space: nowrap;
        text-align: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.21px;
        text-transform: uppercase;
    }

    .divider-line {
        width: 100%;
        height: 1px;
        background: var(--backgrounds-lines-n-100, #3c485a);
    }
`;
/* #endregion */

const FormDivider = props => {
    return (
        <StyledDivider>
            <div className="divider-title">{props.title}</div>
            <div className="divider-line"></div>
        </StyledDivider>
    );
};

/* #region  SCOPED CSS */
const StyledBtn = styled.div`
    .add-field-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #10141b;
        border-radius: 4px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        transition: 0.5s;
        margin-top: 20px;
    }

    .add-field-button:hover {
        background-color: #12171f;
    }

    img {
        width: 16px;
        height: 16px;
    }
`;
/* #endregion */

const AddFieldBtn = ({ onClick }) => {
    return (
        <StyledBtn>
            <div className="add-field-button" onClick={onClick}>
                <img src={Plus} alt="alt" />
            </div>
        </StyledBtn>
    );
};
