import React, { useEffect, useState } from "react";
import InfinityGridRetailerRequestsCard from "../../common/cardContainers/InfinityGridRetailerRequestsCard";
import { APP_TITLE } from "../../../config/constant";
import { useNavigate, useOutletContext } from "react-router";
import ListComponent from "../../common/lists/ListComponent";
import { useSelector } from "react-redux";
import * as Library from "../../../utils/Library";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import { AppQueryClient } from "../../../api/queryClient";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import Checkbox from "../../common/checkbox/Checkbox";
import styled from "styled-components";
import { defaultStatusColors } from "../../../utils/CardUtils";
import MainWindowHeader from "../../common/mainApp/mainWindowHeaders/MainWindowHeader";

// #region SCOPED CSS
const DialogLayoutStyled = styled.div`
    .frame-258,
    .frame-258 * {
        box-sizing: border-box;
    }
    .frame-258 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        margin-bottom: 32px;
    }
    .frame-285 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-160 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-286 {
        padding: 0px 32px 0px 32px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .declining-invitation {
        color: #ffffff;
        text-align: center;
        font-family: "Roboto-Bold", sans-serif;
        font-size: 18px;
        font-weight: 700;
        position: relative;
        flex: 1;
    }
    .please-confirm-you-want-to-decline-the-product-invitation {
        color: var(--text-icons-gray-100, #d1d1d1);
        text-align: center;

        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .do-you-mind-sharing-the-reason {
        color: #ffffff;
        text-align: left;
        font-family: "Roboto-Bold", sans-serif;
        font-size: 14px;
        letter-spacing: 0.0025em;
        font-weight: 700;
        position: relative;
        align-self: stretch;
    }
    .frame-1000011146 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .checkbox-label {
        display: flex;
        flex-direction: row;
        gap: 8px;
        height: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }

    .text {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .check2 {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .check3 {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .check4 {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .simple {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-selector-inverted {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 76px;
        position: relative;
    }
    .search {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-48 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        padding: 10px 12px 10px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        border-style: none;
        //noresize?
        resize: none;
    }
    .frame-48.disabled {
        opacity: 0.8;
        cursor: not-allowed;
    }

    .please-specify {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Italic", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        font-style: var(--subtitle-2-font-style, italic);
        position: relative;
        flex: 1;
    }
`;
// #endregion

export default function RetailersRequestsPage() {
    document.title = `${APP_TITLE} - Retailers Requests`;

    // #region VARS
    const [isLoading, setIsLoading] = useState(false);
    const [isDialogImportOpen, setIsDialogImportOpen] = useState(false);
    const [invitationConfirmationDialog, setInvitationConfirmationDialog] = useState({
        show: false,
        toLabelBrand: "--",
        toRetailBrand: "--"
    });

    const [listNumRows, setListNumRows] = useState(10);

    const [declineRetailBrand, setDeclineRetailBrand] = useState("");
    const [declineInviteID, setDeclineInviteID] = useState("");
    const [reasonObj, setReasonObj] = useState({
        reason1: false,
        reason2: false,
        reason3: false,
        reason4: false,
        otherReasonText: ""
    });

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);
    const navigate = useNavigate();

    const listColumns = [
        {
            name: "To Label Brand",
            selector: row => row?.labelBrand?.name ?? "--"
        },
        {
            name: "BOtag ID",
            selector: row => row?.ourID ?? "--"
        },
        {
            name: "Requested on",
            selector: row => formatDefaultDateTime(row?.createdAt) ?? "--"
        },
        {
            name: "Accepted on",
            selector: row =>
                row?.statusLog && row?.statusLog?.find(x => x.status === "Accepted")
                    ? formatDefaultDateTime(row?.statusLog?.find(x => x.status === "Accepted")?.date)
                    : "--"
        },
        {
            name: "Requested number of stores",
            selector: row => row?.maxStores ?? "--"
        }
    ];

    //---------- Header Filters
    const [allConnectedRetailBrandsList, setAllConnectedRetailBrandsList] = useState([]);
    const [allRetailersList, setAllRetailersList] = useState([]);
    const [filteredRetailers, setFilteredRetailers] = useState([]);
    //-----
    const [allLabelBrandsList, setAllLabelBrandsList] = useState([]);
    const [filteredLabelBrand, setFilteredLabelBrand] = useState([]);
    //-----
    const [requestEnabled, setRequestsEnabled] = useState(false);

    const filters = {
        filterLabelBrandIDs: filteredLabelBrand?.map(item => item?.value),
        filterRetailBrandIDs: filteredRetailers?.map(item => item?.value)
    };

    const infiniteGridQuerykey = ["RetailersRequestsGrid", filters];
    const listQueryKey = ["RetailersRequestsList", filters];

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    // #endregion

    // #region METHODS
    const onLabelBrandChange = selection => {
        setFilteredLabelBrand([selection]);

        const currentLabelBrandID = selection?.value;
        filterConnectedRetailers(allConnectedRetailBrandsList, currentLabelBrandID);
    };

    const onRetailersChange = (selections, dropdownDataWithSelections) => {
        setFilteredRetailers(selections);
        setAllRetailersList(dropdownDataWithSelections);
    };

    const onFiltersLoaded = data => {
        let currentLabelBrandID = null;
        if (data?.labelBrands?.length) {
            const labelBrands = data?.labelBrands?.map((item, index) => {
                return {
                    name: item?.name,
                    value: item?._id,
                    selected: index === 0
                };
            });

            setAllLabelBrandsList(labelBrands);
            setFilteredLabelBrand([labelBrands[0]]);
            currentLabelBrandID = labelBrands[0]?.value;
        }

        if (data?.connectedRetailBrands?.length) {
            const connectedRetailBrands = data?.connectedRetailBrands?.map(item => {
                return {
                    name: item?.name,
                    value: item?._id,
                    selected: true,
                    labelBrandID: item?.labelBrandID
                };
            });

            setAllConnectedRetailBrandsList(connectedRetailBrands);
            setFilteredRetailers(connectedRetailBrands?.filter(item => item?.selected));

            filterConnectedRetailers(connectedRetailBrands, currentLabelBrandID);
        }

        setRequestsEnabled(true);
    };

    const filterConnectedRetailers = async (allRetailBrands, currentLabelBrandID) => {
        let res = allRetailBrands?.filter(item => {
            return item?.labelBrandID === currentLabelBrandID;
        });

        // filter out any duplicated retailers
        res = res?.filter((item, index) => {
            return res?.findIndex(item2 => item2?.value === item?.value) === index;
        });

        setAllRetailersList(res);
        setFilteredRetailers([]);
    };

    const handleAcceptInvitation = async (inviteID, toLabelBrand, toRetailBrand) => {
        const res = await Library.makeCommonPostRequest(
            "Invites",
            "acceptRetailerInvite",

            {
                inviteID: inviteID,
                BOstagesAccountID: localStorage.getItem("BOstagesAccountID")
            },
            false,
            setIsLoading
        );

        if (res?.data?.status === 200) {
            setInvitationConfirmationDialog({
                show: true,
                toLabelBrand: toLabelBrand,
                toRetailBrand: toRetailBrand
            });

            onClickRefreshAction();
        }
    };

    const handleDeclineInvitation = async (inviteID, declineReason) => {
        onCloseDeclineDialog();
        await Library.makeCommonPostRequest(
            "Invites",
            "declineRetailerInvite",
            {
                inviteID: inviteID,
                BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
                declineReason: declineReason
            },
            false,
            setIsLoading
        ).then(res => {
            if (res?.data?.status === 200) {
                Library.showToastMessage({
                    type: "success",
                    title: "Invitation declined!",
                    message: "Invitation declined successfully!"
                });

                onClickRefreshAction();
            }
        });
    };

    const handleDeleteInvitation = async inviteID => {
        const res = await Library.makeCommonPostRequest(
            "Invites",
            "hideInvite",
            {
                inviteID: inviteID,
                BOstagesAccountID: localStorage.getItem("BOstagesAccountID")
            },
            false,
            setIsLoading
        );

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                type: "success",
                title: "Invitation deleted!",
                message: "Invitation deleted successfully!"
            });

            onClickRefreshAction();
        }
    };

    const onOpenDeclineDialog = item => {
        setIsDialogImportOpen(true);
        setDeclineInviteID(item?._id);
        setDeclineRetailBrand(item?.retailBrand?.name);
    };

    const onCloseDeclineDialog = () => {
        setIsDialogImportOpen(false);
        setDeclineInviteID(null);
        setReasonObj({
            reason1: false,
            reason2: false,
            reason3: false,
            reason4: false,
            otherReasonText: ""
        });
        setDeclineRetailBrand("");
    };

    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };

    // #endregion Methods

    // #region EFFECTS

    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(
            props,
            onClickRefreshAction,
            navigations,
            //----
            allRetailersList,
            allLabelBrandsList,
            onLabelBrandChange,
            onRetailersChange,
            onFiltersLoaded,
            requestEnabled,
            filteredLabelBrand,
            filteredRetailers
        );
    }, [
        props?.layoutType,
        navigations,
        allRetailersList,
        allLabelBrandsList,
        filteredLabelBrand,
        filteredRetailers,
        requestEnabled
    ]);
    // #endregion EFFECTS */

    // #region RENDER
    return (
        <>
            <FullScreenLoader isLoading={isLoading} />

            <FullScreenOverlay show={isDialogImportOpen}>
                <DialogBoxComponent
                    title="Declining request?"
                    useAlternativeLayout={
                        <DeclineReasonLayout
                            reasonObj={reasonObj}
                            setReasonObj={setReasonObj}
                            declineRetailBrand={declineRetailBrand}
                        />
                    }
                    confirmText="CONFIRM"
                    cancelText="CANCEL"
                    heading="DECLINING..."
                    headingColor="var(--text-icons-red-500)"
                    onClickYes={() => {
                        let declineReason = "";
                        if (reasonObj.reason1) declineReason = "I don’t know this retailer";
                        if (reasonObj.reason2) declineReason = "I don’t want to work with this retailer";
                        if (reasonObj.reason3) declineReason = "I’ll stop working with this retailer";
                        if (reasonObj.reason4) declineReason = reasonObj.otherReasonText;

                        if (reasonObj.reason4 && reasonObj.otherReasonText === "") {
                            Library.showToastMessage({
                                type: "error",
                                title: "Please specify the reason for declining the invitation."
                            });
                            return;
                        }
                        const inviteID = declineInviteID;
                        handleDeclineInvitation(inviteID, declineReason);
                    }}
                    onClickNo={() => {
                        onCloseDeclineDialog();
                    }}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={invitationConfirmationDialog?.show}>
                <DialogBoxComponent
                    heading="SUCCESS"
                    title="Request accepted!"
                    //message="You can now proceed with affiliating your brand to these retail brand stores"
                    message={`${invitationConfirmationDialog?.toRetailBrand} can now affiliate their stores as ${invitationConfirmationDialog?.toLabelBrand} fulfillment centers. You can follow the affiliation process in MANAGEMENT page under Fulfillment centers menu.`}
                    confirmText="GO TO MANAGEMENT"
                    headingColor="var(--text-icons-green-500)"
                    onClickYes={() => {
                        navigate("/ffcenters/management");
                        setInvitationConfirmationDialog({
                            show: false,
                            toLabelBrand: "--",
                            toRetailBrand: "--"
                        });
                    }}
                    onClickNo={() => {
                        setInvitationConfirmationDialog({
                            show: false,
                            toLabelBrand: "--",
                            toRetailBrand: "--"
                        });
                    }}
                    showCancel={true}
                    cancelText="CLOSE"
                    cancelWidth="50%"
                    confirmWidth="60%"
                />
            </FullScreenOverlay>

            {viewLayoutStyle === "Grid" && (
                <InfinityGridRetailerRequestsCard
                    minColumnWidth="238px"
                    limit={10}
                    queryKey={infiniteGridQuerykey}
                    statusFromPath="status"
                    onAcceptInvitation={handleAcceptInvitation}
                    onDeclineInvitation={onOpenDeclineDialog}
                    onDeleteInvitation={handleDeleteInvitation}
                    requestEnabled={requestEnabled}
                    filters={filters}
                />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="RETAILERS"
                    // title2="(LAST 10 INVITED)"
                    columns={listColumns}
                    api={{
                        endpoint: "getRetailerRequests",

                        useCommonPostRequest: false,
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
                            limit: listNumRows,
                            ...filters
                        }
                    }}
                    pageLimit={listNumRows}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        onClickRemove: row => {
                            handleDeleteInvitation(row?._id);
                        },
                        removeIsDisabled: row => {
                            return row?.status !== "Declined" && row?.status !== "Accepted";
                        },

                        includeActions: true,
                        editIsVisible: false,
                        suspendIsVisible: false,
                        extraActions: [
                            {
                                title: "Decline",
                                onClick: row => {
                                    if (row?.status !== "Request" && row?.status !== "Invited") return undefined;
                                    onOpenDeclineDialog(row);
                                },
                                isDisabled: row => {
                                    return row?.status !== "Request" && row?.status !== "Invited";
                                }
                            },
                            {
                                title: "Accept",
                                onClick: row => {
                                    if (
                                        row?.status !== "Request" &&
                                        row?.status !== "Invited" &&
                                        row?.status !== "Enrolled"
                                    )
                                        return undefined;
                                    handleAcceptInvitation(row?._id, row?.labelBrand?.name, row?.retailBrand?.name);
                                },
                                isDisabled: row => {
                                    return row?.status !== "Request" && row?.status !== "Invited";
                                }
                            }
                        ]
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    requestEnabled={requestEnabled}
                    onChangeRowsPerPage={rowsPerPage => setListNumRows(rowsPerPage)}
                />
            )}
        </>
    );
    // #endregion RENDER
}

// #region FUNCTIONS
function setupHeaders(
    props,
    onClickRefreshAction,
    navigations,
    allRetailersList,
    allLabelBrandsList,
    onLabelBrandChange,
    onRetailersChange,
    onFiltersLoaded,
    requestEnabled,
    filteredLabelBrand,
    filteredRetailers
) {
    //const createMode = navigations?.navigationAccesses?.retailers?.createModeOnly;

    props.setControlBarLeftBtns([
        //{ to: "#", disabled: true, title: "Retailers", icon: "fa-magnifying-glass", isSearchToogle: false }
    ]);
    props.setControlBarCenterBtns([
        { to: "/retailers/connections", active: false, disabled: false, title: "connections" },
        { to: "/retailers/invitations", active: false, disabled: false, title: "Invitations" },
        { to: "/retailers/requests", active: true, disabled: false, title: "Requests" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: false, title: "View", icon: "eye" },
        { to: "/retailers/invite", active: false, disabled: false, title: "Invite", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <MainWindowHeader
                key={window.location.pathname}
                visible={true}
                layoutTypeOptions={{
                    gridEnabled: true,
                    listEnabled: true
                }}
                onRefreshClick={onClickRefreshAction}
                firstOrganize={{
                    visible: true,
                    dropdownData: allLabelBrandsList,
                    onDropdownChange: onLabelBrandChange,
                    disabled: false,
                    type: "singleSelect",
                    selectedValue: filteredLabelBrand?.[0]?.name
                }}
                secondOrganize={{
                    visible: true,
                    dropdownData: allRetailersList,
                    onDropdownChange: onRetailersChange,
                    disabled: false,
                    type: "multiSelect",

                    multidropdownOptions: {
                        useDoneButton: true,
                        useSelectAll: true,
                        selectAllText: "Select all retailers",
                        selectAllPlaceholderFunction: arrayItems => {
                            if (!arrayItems?.length) return "No retailers";

                            const nonLockedRetailers = allRetailersList?.filter(item => !item?.locked);
                            const areAllSelected = arrayItems?.length === nonLockedRetailers?.length;
                            if (areAllSelected) return `All retailers (${nonLockedRetailers?.length})`;

                            let firstTwoItems = arrayItems
                                ?.slice(0, 2)
                                ?.map(item => item?.name)
                                ?.join(", ");
                            if (arrayItems?.length > 2) {
                                firstTwoItems = firstTwoItems + ", and more";
                            }

                            return `Retailers (${arrayItems?.length}): ${firstTwoItems}`;
                        }
                    }
                }}
                pageFilters={{
                    useFilters: true,
                    filters: {
                        useLabelBrands: true,
                        useConnectedRetailBrands: true
                    },
                    onFiltersLoaded: onFiltersLoaded
                }}
            />
        )
    });
}

const DeclineReasonLayout = props => {
    const { reasonObj, setReasonObj, declineRetailBrand } = props;

    const otherReasonRef = React.createRef();

    useEffect(() => {
        if (reasonObj.reason4) {
            otherReasonRef.current.focus();
        }
    }, [reasonObj.reason4]);

    return (
        <DialogLayoutStyled className="DialogLayout">
            <div className="frame-258">
                <div className="frame-285">
                    <div className="frame-160">
                        <div className="please-confirm-you-want-to-decline-the-product-invitation">
                            {`Please confirm you want to decline ${declineRetailBrand} invitation.`}
                            <br />
                        </div>
                        <div className="do-you-mind-sharing-the-reason">Do you mind sharing the reason?</div>
                        <div className="frame-1000011146">
                            <div className="checkbox-label">
                                <Checkbox
                                    className="checkbox"
                                    isChecked={reasonObj.reason1}
                                    onCheckboxClick={() => {
                                        setReasonObj({
                                            reason1: !reasonObj.reason1,
                                            reason2: false,
                                            reason3: false,
                                            reason4: false,
                                            otherReasonText: ""
                                        });
                                    }}
                                />
                                <div className="text">I don’t know this retailer</div>
                            </div>
                            <div className="checkbox-label">
                                <Checkbox
                                    className="checkbox"
                                    isChecked={reasonObj.reason2}
                                    onCheckboxClick={() => {
                                        setReasonObj({
                                            reason1: false,
                                            reason2: !reasonObj.reason2,
                                            reason3: false,
                                            reason4: false,
                                            otherReasonText: ""
                                        });
                                    }}
                                />{" "}
                                <div className="text">I don’t want to work with this retailer</div>
                            </div>
                            <div className="checkbox-label">
                                <Checkbox
                                    className="checkbox"
                                    isChecked={reasonObj.reason3}
                                    onCheckboxClick={() => {
                                        setReasonObj({
                                            reason1: false,
                                            reason2: false,
                                            reason3: !reasonObj.reason3,
                                            reason4: false,
                                            otherReasonText: ""
                                        });
                                    }}
                                />{" "}
                                <div className="text">I’ll stop working with this retailer</div>
                            </div>
                            <div className="checkbox-label">
                                <Checkbox
                                    className="checkbox"
                                    isChecked={reasonObj.reason4}
                                    onCheckboxClick={() => {
                                        setReasonObj({
                                            reason1: false,
                                            reason2: false,
                                            reason3: false,
                                            reason4: !reasonObj.reason4,
                                            otherReasonText: ""
                                        });
                                    }}
                                />{" "}
                                <div className="text">Other reasons:</div>
                            </div>
                            <div className="simple">
                                <div className="input-selector-inverted">
                                    <div className="search">
                                        <textarea
                                            ref={otherReasonRef}
                                            className={`frame-48 ${reasonObj.reason4 ? "" : "disabled"}`}
                                            placeholder="Please specify"
                                            value={reasonObj.otherReasonText}
                                            disabled={!reasonObj.reason4}
                                            onChange={e => {
                                                setReasonObj({ ...reasonObj, otherReasonText: e.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DialogLayoutStyled>
    );
};
// #endregion FUNCTIONS
