import RetailersInvitationsPage from "../components/pages/retailers/RetailersInvitationsPage";
import RetailersConnectionsPage from "../components/pages/retailers/RetailersConnectionsPage";
import RetailersRequestsPage from "../components/pages/retailers/RetailersRequestsPage";
import RetailersReuestsInvitePage from "../components/pages/retailers/RetailersInvitePage";
const routes = [
    {
        path: "/retailers/connections",
        element: <RetailersConnectionsPage />
    },
    {
        path: "/retailers/invitations",
        element: <RetailersInvitationsPage />
    },
    {
        path: "/retailers/requests",
        element: <RetailersRequestsPage />
    },
    {
        path: "/retailers/invite",
        element: <RetailersReuestsInvitePage />
    }
];
export default routes;
