/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "../../../assets/css/components/CheckboxDark.css";
import { defaultStatusColors } from "../../../utils/CardUtils";

/* #region Styled Components */
const CustomNestedCols = styled.tr`
    & {
        display: flex;
        align-items: stretch;
        width: 100%;
        box-sizing: border-box;
        font-size: 12px !important;
        font-weight: 400;
        font-family: Roboto;
        border-bottom: 1px solid #333f50 !important;
        min-height: 36px;
        border-bottom: none !important;
    }

    td {
        min-height: 36px;
        line-height: 30px;

        //one line ellipsis
    }
    td > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.nested-row-tr-header > td {
        color: var(--text-icons-gray-200, #7c868b) !important;
        font-size: 11px !important;
    }

    button.three-btn-class {
        width: calc(100% / 3 - 8px / 3);
        text-overflow: ellipsis;
        overflow: hidden;
        align-items: center;
    }

    .td-bottom-border {
        border-bottom: 1px solid #333f50 !important;
    }

    .nested-col-first {
        width: 35px;
        flex: 0 0 35px;
        min-width: 35px;
        margin: 0px;
        opacity: 0;
    }

    .nested-col-last {
    }

    .nested-col-default {
        padding-left: 8px;
        padding-right: 0px;

        -webkit-box-flex: 1;
        flex: 1 0 0px;
        max-width: 100%;
        min-width: 100px;

        position: relative;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        box-sizing: border-box;
    }
`;

const CustomButton = styled.button`
    & {
        display: flex;
        padding: 0px 8px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;
        height: 23px;

        border-radius: 4px;
        border: 1px solid var(--backgrounds-lines-n-200, #262e3e);
        background-color: transparent;

        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &.disabled {
        color: var(--text-icons-gray-300, #4f5b6d);
    }
`;

export const FloatingDiv = styled.div`
    & {
        display: flex;
        padding: 0px 3px;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        width: fit-content;

        border-radius: 2px;
        background: var(--Buttons-All-Platforms-btn-action-green, #080);

        color: var(--text-icons-gray-100, #d1d1d1);
        font-family: Roboto;
        font-size: 8px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
/* #endregion */

export default function FFCsCustomExpandedComponent(props) {
    const { data } = props;
    const expandedRowName = "storesData";

    const expandedRowsColumns = [
        {
            name: "Store",
            selector: row => (
                <div className="flex align-items-center">
                    <FloatingDiv
                        style={{
                            position: "absolute",
                            left: "-21px"
                        }}>
                        New
                    </FloatingDiv>
                    <span>{row?.name ?? "--"}</span>
                </div>
            )
        },
        {
            name: "",
            hideHeaderName: true,
            selector: row => ""
        },
        {
            name: "City, Country",
            selector: row =>
                !row?.city && !row?.country
                    ? "--"
                    : (row?.city || "--") + ", " + (row?.country || row?.countryValue || "--")
        },
        {
            name: "PK/CO/SH",
            selector: row => (
                <div className="flex align-items-center">
                    <span>
                        {row?.pickupEnabled
                            ? getSpanWithColor("Yes", "text-green-500")
                            : getSpanWithColor("No", "text-red-500")}
                    </span>
                    <span>&nbsp;&nbsp;</span>
                    <span>
                        {row?.courierEnabled
                            ? getSpanWithColor("Yes", "text-green-500")
                            : getSpanWithColor("No", "text-red-500")}
                    </span>
                    <span>&nbsp;&nbsp;</span>
                    <span>
                        {row?.shippingEnabled
                            ? getSpanWithColor("Yes", "text-green-500")
                            : getSpanWithColor("No", "text-red-500")}
                    </span>
                </div>
            )
        },
        {
            name: "Rating",
            selector: row => row?.rating || "--"
        },
        {
            name: "Check stock",
            hideHeaderName: true,
            selector: row => "Check stock"
        },
        {
            name: "Status",
            //selector: row => getStatusColumn(row?.FFCdata, "status", defaultStatusColors)
            selector: row => translateStatus(row)
        },
        {
            name: "Actions",
            hideHeaderName: true,
            selector: row => "Actions"
        }
    ];

    /* #region METHODS */
    const translateStatusText = row => {
        if (!row?.FFCdata) return "Not active";

        if (row?.FFCdata && row?.POS?.status !== "Connected") return "Not fulfilling";
        else if (row?.FFCdata?.status === "Active") return "Fulfilling";
        else if (row?.FFCdata?.status === "Suspended") return "Suspended";
        else return row?.FFCdata?.status || "Not active";
    };

    const translateStatus = row => {
        const newRow = { status: "" };
        newRow.status = translateStatusText(row);

        return getStatusColumn(newRow, "status", defaultStatusColors);
    };

    const getSpanWithColor = (text, color) => {
        return <span className={color}>{text}</span>;
    };

    const getStatusColumn = (row, includeStatusWithArrayFields, statusColors, defaultNotFound = "Unknown") => {
        let statusValue = "";
        if (!row) row = {};

        if (typeof row === "object") {
            statusValue = row?.status || defaultNotFound;
        } else if (Array.isArray(row)) {
            statusValue = getArrayFieldValue(row, includeStatusWithArrayFields) || defaultNotFound;
        }

        //TODO: This is a temporary fix for the "Not Connected" status value
        if (statusValue === "Not Connected") statusValue = "Not connected";

        let colorValue = "text-orange-500";
        let found = false;

        for (const color of statusColors) {
            for (const value of color.forValues) {
                if (value.toLowerCase() === statusValue.toLowerCase()) {
                    found = true;
                    colorValue = color.colorClass;
                    break;
                }
            }
            if (found) break;
        }

        return (
            <div>
                <div className="flex align-items-center">
                    <div className={`status-dot bg-${colorValue}`}></div>
                    <span className={colorValue}>{statusValue}</span>
                </div>
            </div>
        );
    };

    const getArrayFieldValue = (row, array) => {
        if (!Array.isArray(array)) return "Unknown";

        let res = row;
        for (let i = 0; i < array.length; i++) {
            res = res?.[array[i]];
        }
        return res;
    };
    /* #endregion */

    if (false) return <pre>{JSON.stringify(data, null, 2)}</pre>;

    return (
        <table
            className="CustomExpandedRow"
            style={{
                width: "100%",
                maxWidth: "100%",
                marginBotton: "20px",
                background: "var(--backgrounds-lines-n-500)",
                //radius 4px
                borderRadius: "4px"
            }}>
            <tbody>
                <CustomNestedCols className="nested-row-tr-header">
                    <td className="nested-col-first" key="nested-col-0"></td>

                    <td className="nested-col-default" key="nested-col-1"></td>
                    {expandedRowsColumns?.map((col, index) => {
                        return (
                            <td
                                className="nested-col-default td-bottom-border"
                                key={index}
                                style={{ minWidth: col?.minWidth, maxWidth: col?.maxWidth }}>
                                {col?.hideHeaderName ? null : <span title={col.name}>{col.name}</span>}
                            </td>
                        );
                    })}

                    <td className="nested-col-default d-none" key="nested-col-country"></td>

                    <td
                        className="nested-col-default"
                        key="nested-col-status"
                        style={{ minWidth: "100px", maxWidth: "100px" }}>
                        {/*getStatusColumn(row, "status", defaultStatusColors) */}
                    </td>

                    <td
                        className="nested-col-default"
                        key="nested-col-action"
                        style={{ minWidth: "36px", maxWidth: "36px" }}></td>
                </CustomNestedCols>
                {data?.[expandedRowName]?.length === 0 ? (
                    <CustomNestedCols className="nested-row-tr">
                        <td className="nested-col-first"></td>
                        <td className="nested-col-default"></td>

                        <td className="nested-col-default">No data available</td>
                        {Array.from({ length: expandedRowsColumns.length - 1 }).map((_, index) => (
                            <td className="nested-col-default" key={index}></td>
                        ))}

                        <td className="nested-col-default"></td>
                        <td className="nested-col-default" style={{ minWidth: "100px", maxWidth: "100px" }}></td>
                        <td className="nested-col-default" style={{ minWidth: "36px", maxWidth: "36px" }}></td>
                    </CustomNestedCols>
                ) : (
                    data?.[expandedRowName]?.map((row, index) => {
                        return (
                            <CustomNestedCols className="nested-row-tr" key={index}>
                                <td className="nested-col-first" key="nested-col-0"></td>

                                <td className="nested-col-default" key="nested-col-1"></td>

                                {expandedRowsColumns?.map((col, index) => {
                                    return (
                                        <td
                                            className="nested-col-default w-100"
                                            key={index}
                                            style={{
                                                minWidth: col?.minWidth,
                                                maxWidth: col?.maxWidth
                                            }}>
                                            {index === 5 ? (
                                                translateStatusText(row) === "Fulfilling" ? (
                                                    <Link
                                                        to={`/stock?storeID=${row?._id}&storeName=${row?.name}`}
                                                        className="no-underline">
                                                        <CustomButton className="cursor-pointer">
                                                            {col.selector(row)}
                                                        </CustomButton>
                                                    </Link>
                                                ) : (
                                                    <CustomButton className="disabled hover-not-allowed">
                                                        {col.selector(row)}
                                                    </CustomButton>
                                                )
                                            ) : index === 7 ? (
                                                <div className="flex gap-4px w-100">
                                                    <CustomButton className="three-btn-class disabled hover-not-allowed d-none">
                                                        Affiliate
                                                    </CustomButton>
                                                    <CustomButton
                                                        className="three-btn-class disabled hover-not-allowed"
                                                        style={{ width: "auto" }}>
                                                        Suspend
                                                    </CustomButton>
                                                    <CustomButton className="three-btn-class disabled hover-not-allowed d-none">
                                                        Remove
                                                    </CustomButton>
                                                </div>
                                            ) : (
                                                <span title={col.selector(row)}>{col.selector(row)}</span>
                                            )}
                                        </td>
                                    );
                                })}

                                <td className="nested-col-default d-none" key="nested-col-country"></td>

                                <td
                                    className="nested-col-default"
                                    key="nested-col-status"
                                    style={{ minWidth: "100px", maxWidth: "100px" }}>
                                    {/*getStatusColumn(row, "status", defaultStatusColors) */}
                                </td>

                                <td
                                    className="nested-col-default"
                                    key="nested-col-action"
                                    style={{ minWidth: "36px", maxWidth: "36px" }}></td>
                            </CustomNestedCols>
                        );
                    })
                )}
            </tbody>
        </table>
    );
}
