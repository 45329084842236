import { Tooltip } from "antd";
import styled from "styled-components";
import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import Checkbox from "../../../common/checkbox/Checkbox";
import { UploadField } from "../../../common/forms/UploadLogosCardStandard";
import SimpleGridContainer from "../../../common/forms/SimpleGridContainer";
import AddressFormCardStandard from "../../../common/forms/AddressFormCardStandard";
import ThreeDotIcon from "../../../../assets/icons/three-dots.svg";
import DeleteIcon from "../../../../assets/icons/delete.png";
/* #region SCOPED CSS */
const StyledPage = styled.div`
    &.main-add-person-container {
        border: 1px solid;
        border-radius: 3px;
        padding: 8px;
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .frame-296 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        position: relative;
    }
    .frame-296-address {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .person-1 {
        color: #ffffff;
        text-align: left;
        font-family: var(--overline-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--overline-font-size, 14px);
        letter-spacing: var(--overline-letter-spacing, 0.015em);
        font-weight: var(--overline-font-weight, 500);
        text-transform: uppercase;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
    .info-circle {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        color: var(--text-icons-gray-200);
        font-weight: 500;
        position: relative;
        top: -1px;
    }
    .role-responsibilities {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-160 {
        padding: 10px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-291 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-171 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-144 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .roles {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--label-xs-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-xs-uppercase-font-size, 8px);
        letter-spacing: var(--label-xs-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-xs-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .line-1 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
    }
    .frame-158 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313347 {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-289 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
        gap: 8px;
    }
    .checkbox-label {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .checkbox {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
    }
    .rectangle-1 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--text-and-icons-gray-200, #7c868b);
        border-width: 1px;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .check {
        opacity: 0;
    }
    .text {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }

    .frame-290 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
        gap: 8px;
    }

    .line-12 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px 0 0 0;
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
    }
    .table-list-action-dropdown {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        width: 130px;
        box-shadow: var(--drop-shadow-100-box-shadow, 0px 0px 10px 0px rgba(17, 22, 32, 1));
        position: absolute;
        top: 20px;
        z-index: 9;
        cursor: pointer;
    }
    .frame-74 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-81 {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 32px;
        position: relative;
    }
    .trash-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .remove {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
    .delete-main-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end;
    }
    .delete-container {
        cursor: pointer;
    }
    .text.text-disabled {
        color: #4f5b6d !important;
    }
`;

/* #endregion */
const AddPerson = forwardRef(
    (
        {
            formAddPersonFields,
            checkboxValues,
            handleCheckboxClick,
            personIndex,
            documents,
            address,
            handleRemovePerson
        },
        ref
    ) => {
        const formFieldRefs = useRef({});
        const [showDelete, setShowDelete] = useState(false);
        const [localLocationData, setLocalLocationData] = useState(address.locationData);

        useEffect(() => {
            setLocalLocationData(address.locationData);
        }, [address.locationData]);

        useImperativeHandle(ref, () => ({
            getAllFieldValues: () => {
                const fieldValues = {};
                formAddPersonFields?.forEach(field => {
                    const varName = field?.props?.varName;
                    fieldValues[varName] = formFieldRefs.current[varName]?.getValue();
                });
                fieldValues.address = localLocationData;
                fieldValues.images = documents.logoFieldsData.map(field => ({
                    varName: field.varName,
                    imageData: field.imageData
                }));
                return fieldValues;
            },
            allFieldsAreValid: highlightValidation => {
                return formAddPersonFields.every(field => {
                    const varName = field.props.varName;
                    return formFieldRefs.current[varName]?.isValid(highlightValidation);
                });
            }
        }));

        return (
            <StyledPage className="main-add-person-container">
                <div className="frame-296">
                    <div className="person-1">
                        <div className="">PERSON {personIndex + 1}</div>
                        <Tooltip
                            title={`PERSON ${personIndex + 1}`}
                            color="#10141b"
                            overlayInnerStyle={{ textAlign: "center" }}
                            overlayStyle={{ fontSize: "0.75rem", textAlign: "center" }}>
                            <i className="info-circle fas fa-info-circle" aria-hidden="true"></i>
                        </Tooltip>
                    </div>
                    {personIndex > 0 && (
                        <div className="delete-main-container">
                            <div className="delete-container" onClick={() => setShowDelete(!showDelete)}>
                                <img src={ThreeDotIcon} alt="three-dots" />
                            </div>
                            {showDelete && (
                                <div className="table-list-action-dropdown" onClick={handleRemovePerson}>
                                    <div className="frame-74">
                                        <div className="frame-81">
                                            <img className="trash-01" src={DeleteIcon} alt="trash-010.svg" />
                                            <div className="remove">Remove</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="role-responsibilities">
                    <div className="frame-160">
                        <div className="frame-291">
                            <div className="frame-171">
                                <div className="frame-144">
                                    <div className="roles">ROLES</div>
                                    <div className="line-1"></div>
                                </div>
                            </div>
                        </div>
                        <div className="frame-158">
                            <div className="frame-313347">
                                <div className="frame-289">
                                    <div className="checkbox-label">
                                        <Checkbox
                                            isChecked={checkboxValues?.roles?.chairman}
                                            onCheckboxClick={() => handleCheckboxClick("chairman", "roles")}
                                        />
                                        <div className="text">Chairman</div>
                                    </div>
                                    <div className="checkbox-label">
                                        <Checkbox
                                            isChecked={checkboxValues?.roles?.ceo}
                                            onCheckboxClick={() => handleCheckboxClick("ceo", "roles")}
                                        />
                                        <div className="text">CEO</div>
                                    </div>
                                </div>
                                <div className="frame-290">
                                    <div className="checkbox-label">
                                        <Checkbox
                                            isChecked={checkboxValues?.roles?.director}
                                            onCheckboxClick={() => handleCheckboxClick("director", "roles")}
                                        />
                                        <div className="text">Director</div>
                                    </div>
                                    <div className="checkbox-label">
                                        <Checkbox
                                            isChecked={checkboxValues?.roles?.representative}
                                            onCheckboxClick={() => handleCheckboxClick("representative", "roles")}
                                        />
                                        <div className="text">Representative</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="line-12"></div>
                    </div>
                    <div className="frame-160">
                        <div className="frame-291">
                            <div className="frame-171">
                                <div className="frame-144">
                                    <div className="roles">Responsibilities</div>
                                    <div className="line-1"></div>
                                </div>
                            </div>
                        </div>
                        <div className="frame-158">
                            <div className="frame-313347">
                                <div className="frame-289">
                                    <div className="checkbox-label">
                                        <Checkbox
                                            isChecked={personIndex > 0 ? false : true}
                                            // onCheckboxClick={() =>
                                            //     handleCheckboxClick("primaryRepresentative", "responsibilities")
                                            // }
                                        />
                                        <div className="text text-disabled">
                                            This person is authorized as primary representative of the company
                                        </div>
                                    </div>
                                    <div className="checkbox-label">
                                        <Checkbox
                                            isChecked={checkboxValues?.responsibilities?.owns25Percent}
                                            onCheckboxClick={() =>
                                                handleCheckboxClick("owns25Percent", "responsibilities")
                                            }
                                        />
                                        <div className="text">This person owns 25% or more of the company</div>
                                    </div>
                                </div>
                                <div className="frame-290">
                                    <div className="checkbox-label">
                                        <Checkbox
                                            isChecked={checkboxValues?.responsibilities?.governingBoardMember}
                                            onCheckboxClick={() =>
                                                handleCheckboxClick("governingBoardMember", "responsibilities")
                                            }
                                        />
                                        <div className="text">
                                            This person is a member of the governing board of the company
                                        </div>
                                    </div>
                                    <div className="checkbox-label">
                                        <Checkbox
                                            isChecked={checkboxValues?.responsibilities?.responsibilitiesRepresentative}
                                            onCheckboxClick={() =>
                                                handleCheckboxClick(
                                                    "responsibilitiesRepresentative",
                                                    "responsibilities"
                                                )
                                            }
                                        />
                                        <div className="text">Representative</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="line-12"></div>
                    </div>
                </div>
                <div style={{ display: "flex", gap: "16px" }}>
                    <div
                        className="FormFieldsContainer"
                        style={{
                            display: "inline-grid",
                            width: "100%",
                            gap: "16px",
                            gridTemplateColumns: "repeat(4, minmax(0, 1fr))"
                        }}>
                        {formAddPersonFields.map((field, index) => {
                            //TODO: try to see if is possible to use the ref directly in the field component instead of using the formFieldRefs
                            const newField = {
                                ...field,
                                key: index,
                                ref: el => (formFieldRefs.current[field?.props?.varName] = el),
                                props: {
                                    ...field?.props
                                }
                            };
                            //console.log("newField:", newField);
                            return newField;
                        })}
                    </div>
                </div>
                <SimpleGridContainer gap="16px" numOfFieldsPerLine={4}>
                    {!documents?.logoFieldsData || documents?.logoFieldsData.length === 0 ? (
                        <div>No logo fields provided!</div>
                    ) : (
                        documents?.logoFieldsData.map((field, index) => {
                            return (
                                <UploadField
                                    key={index}
                                    handleOnImageUpload={data => documents?.handleOnImageUpload(field.varName, data)}
                                    fieldData={field}
                                    setImageData={data => {
                                        const newLogoFieldsData = [...documents?.logoFieldsData];
                                        newLogoFieldsData[index].imageData = data;
                                        documents?.setLogoFieldsData(newLogoFieldsData);
                                    }}
                                    highlightNotValidFields={documents?.highlightNotValidFields}
                                    style={{
                                        gridColumn: field.gridColumnSize ? `span ${field.gridColumnSize}` : "span 1"
                                    }}
                                />
                            );
                        })
                    )}
                </SimpleGridContainer>
                <div className="frame-296-address">
                    <div className="person-1">PERSON ADDRESS</div>
                    <Tooltip
                        title="PERSON ADDRESS"
                        color="#10141b"
                        overlayInnerStyle={{ textAlign: "center" }}
                        overlayStyle={{ fontSize: "0.75rem", textAlign: "center" }}>
                        <i className="info-circle fas fa-info-circle" aria-hidden="true"></i>
                    </Tooltip>
                </div>
                <AddressFormCardStandard
                    cardTitle="PERSON ADDRESS"
                    showDefaultRightLegends={false}
                    hideFormContainer={true}
                    style={{ padding: "0px" }}
                    ref={address.formCompanyAddress}
                    pinLabel={localLocationData.legalBusinessName}
                    unnamedPinLabel={"Unnamed Person"}
                    useGoogleMapsDarkMode={true}
                    hasKYCAssistiveText={true}
                    onFormChanged={address.checkFormIsValid}
                    locationDetails={localLocationData}
                    onLocationDetailsChange={locationDetails => {
                        setLocalLocationData(locationDetails);
                        address.setLocationData(locationDetails);
                    }}
                />
            </StyledPage>
        );
    }
);

export default AddPerson;
