import React from "react";
import styled from "styled-components";

/* #region  SCOPED CSS */

/* #endregion */

/**
 * Button component with large solid inverted 01 style.
 *
 * @component
 * @example
 * // Usage:
 * <BtnLargeSolidInverted01 text="Click me" disabled="false" />
 *
 * @param {Object} props - The component props.
 * @param {string} props.text - The text to display on the button.
 * @param {string} props.isActive - Whether the button is active or not.
 * @param {string} props.disabled - Whether the button is disabled or not.
 * @param {string} props.className - Additional CSS class name for the component.
 * @param {function} props.onClick - The function to call when the button is clicked.
 * @param {string} props.style - Additional CSS style for the component.
 * @param {string} props.normalColor - The normal color for the button.
 * @param {string} props.hoverColor - The hover color for the button.
 * @param {string} props.disabledColor - The disabled color for the button.
 *
 * @returns {JSX.Element} The rendered BtnLargeSolidInverted01 component.
 */
export default function BtnLargeCustomColors(props) {
    const StyledPage = styled.div`
        & {
            display: flex;
            width: 120px;
            height: 35px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            //flex: 1 0 0;
            //flex-shrink: 0;

            border-radius: 4px;
            background: ${props?.normalColor
                ? props.normalColor
                : "var(--Buttons-All-Platforms-btn-action-green, #080)"};
        }

        &.enabled {
            // Added Border to fix small issue
            border: 1px solid
                ${props?.normalColor ? props.normalColor : "var(--Buttons-All-Platforms-btn-action-green, #080)"};
        }

        .Button-Text-Large {
            color: var(--text-icons-gray-100, #d1d1d1);

            /* Button-Text-Large */
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.15px;
            text-transform: uppercase;
        }

        // HOVER CHANGES
        &.enabled:hover {
            background: ${props?.hoverColor
                ? props.hoverColor
                : "var(--Buttons-All-Platforms-btn-action-green-hover, #090)"};
            border: 1px solid
                ${props?.hoverColor ? props.hoverColor : "var(--Buttons-All-Platforms-btn-action-green, #080)"};
            cursor: pointer;
        }

        // ACTIVE CHANGES
        &.enabled.active {
            border: 1px solid ${props?.hoverColor ? props.hoverColor : "var(--Buttons-Green-Outline, #0b0)"};
            background: ${props?.hoverColor ? props.hoverColor : "var(--Buttons-Green-Outline, #0b0)"};
        }

        // DISABLED CHANGES
        &.disabled {
            background: ${props?.disabledColor
                ? props.disabledColor
                : "var(--Buttons-All-Platforms-Gray-500, #10141b)"};
            cursor: not-allowed;
        }

        &.disabled .Button-Text-Large {
            color: var(--text-icons-gray-300, #d1d1d1);
            cursor: not-allowed;
        }

        /* TODO: DISABLED HOVER CSS NON EXISTENT
        &.disabled:hover {
             border: 1px solid var(--App-Accent-Outline);
        } */
    `;
    return (
        <StyledPage
            className={`btn-large ${props?.disabled === true ? "disabled" : "enabled"} ${props?.className ?? ""} ${
                props?.isActive ? "active" : ""
            }`}
            onClick={props?.disabled ? undefined : props?.onClick}
            style={props?.style}>
            <label className="Button-Text-Large text-one-line">{props?.text || "--"}</label>
        </StyledPage>
    );
}
