import Color from "color";

const isHex = color => /^([0-9A-F]{3}){1,2}$/i.test(color);
const isRgb = color => /^rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)$/i.test(color);

const hexToRgb = hex => {
    if (!hex) return "";
    if (!isHex(hex)) return hex; // Return the input if it's not a valid HEX
    try {
        const color = Color(hex.startsWith("#") ? hex : `#${hex}`);
        return color.rgb().string();
    } catch (error) {
        throw new Error("hexToRgb -> Invalid HEX color:", hex);
    }
};

const hexToRgbSplit = hex => {
    if (typeof hex === "number") {
        hex = hex.toString(16).padStart(6, "0");
    }
    if (!hex || typeof hex !== "string" || !isHex(hex)) {
        //console.error("hexToRgbSplit -> Invalid HEX color:", hex);
        return null;
    }
    try {
        const color = Color(hex.startsWith("#") ? hex : `#${hex}`);
        const { r, g, b } = color.rgb().object();
        return { r, g, b };
    } catch (error) {
        console.error("hexToRgbSplit -> Error processing HEX color:", hex, error);
        return null;
    }
};

const rgbToHex = rgb => {
    if (rgb === 0 || rgb === "0") return "000000";
    if (!rgb) return "";
    if (!isRgb(rgb)) return rgb; // Return the input if it's not a valid RGB
    try {
        const color = Color(rgb);
        return color.hex();
    } catch (error) {
        throw new Error("Invalid RGB color:", rgb);
    }
};

export { hexToRgb, hexToRgbSplit, rgbToHex };
