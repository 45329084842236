import { useEffect } from "react";

const useUnsavedChangesWarning = (hasUnsavedChanges, message) => {
    //const navigate = useNavigate(); -> not being used

    useEffect(() => {
        const handleBeforeUnload = event => {
            if (hasUnsavedChanges) {
                console.log("hasUnsavedChanges", hasUnsavedChanges, !!localStorage.getItem("token"));
                event.preventDefault();
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [hasUnsavedChanges, message]);
};

export default useUnsavedChangesWarning;
