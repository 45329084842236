import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { APP_TITLE } from "../../../config/constant";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import _, { set } from "lodash";
import { createProductData } from "../productsComponents/productFactory";
import * as Library from "../../../utils/Library";

import error from "../../../assets/icons/x-close-red.svg";

//Assets
import aiLogo from "../../../assets/icons/ai-logo.svg";

import FullScreenLoader from "../../../components/common/loaders/FullScreenLoader";

import ContainerLoader from "../../../components/common/loaders/ContainerLoader";
import animationData from "./../../../assets/animations/purple-loader.json";

import LayoutContext from "../../../LayoutContext";
import AppContext from "../../../AppContext";

import FormContainer from "../../common/forms/FormContainer";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import SimpleListComponent from "../../common/lists/SimpleListComponent";

import ProductPageAddHeader from "../productsComponents/ProductPageAddHeader";
import FormComponent from "../productsComponents/AddProductFormComponent";
import CustomLoader from "../productsComponents/CustomLoaderProductsPage";

import { fieldNames, fieldNamesMatch } from "../productsComponents/labelsInfo";

import { checkMissingAiLabels, showMissingLabelsPopup } from "../productsComponents/aiBasicRoutine";

export default function AddProductPage() {
    document.title = `${APP_TITLE} - Products Add`;

    const { setProductsImportedPopup } = useContext(LayoutContext);
    const { boImageProductsImporting, setBOImageProductsImporting, setHasUnsavedChanges } = useContext(AppContext);

    const navigate = useNavigate();
    const navigations = useSelector(state => state.navigationAccesses);
    const createMode = navigations?.navigationAccesses?.products?.createModeOnly;

    const fileInputRef = useRef(null);

    //TODO: Check a better way to do this
    const [inputKey, setInputKey] = useState(Date.now());

    // const [aiResult, setAiResult] = useState(null);
    const [aiLoading, setAiLoading] = useState(false);

    const [savedConfiguration, setSavedConfiguration] = useState(null);
    const [showErrorInvalidFormat, setShowErrorInvalidFormat] = useState(false);

    const [emptyLabels, setEmptyLabels] = useState([]);
    const [emptyLabelsPopup, setEmptyLabelsPopup] = useState(false);

    const [popup, setPopup] = useState({
        show: false,
        heading: "",
        title: "",
        message: "",
        confirmText: ""
    });

    const handleFileChange = event => {
        const file = event.target.files[0];
        if (file) {
            const fileExtension = file.name.split(".").pop().toLowerCase();
            if (fileExtension === "csv" || fileExtension === "xls" || fileExtension === "xlsx") {
                setSelectedFile(file.name);
                extractHeaders(file, fileExtension);
                setIsErrorInvalidFormat(false);
            } else {
                setSelectedFile(file.name);
                setShowErrorInvalidFormat(true);
                // Library.showErrorMessage(
                //     "Double check the file format. If it’s .csv or .xlsx and you see this message, please contact us at support@botag.no"
                // );
                setIsErrorInvalidFormat(true);
                setLabels(null);
            }
        }
        setInputKey(Date.now());
    };

    /* #region Form Data Cfg */
    const getConfiguration = async () => {
        const labelBrandID = selectedBrand?.value;
        const formData = { labelBrandID };

        const savedConfiguration = await Library.makePostRequest("getLabelBrand", formData).then(res => {
            if (res.data?.status === 200) {
                return res.data.data.productsImporterConfiguration;
            }
        });
        return savedConfiguration;
    };

    const updateFormDataWithConfiguration = savedConfiguration => {
        const newFormData = { ...formData };
        const newCustomFieldNames = { ...customFieldNames };
        const newCustomFieldSections = { ...customFieldSections };
        const newCustomFormData = { ...customFormData };

        savedConfiguration?.forEach(item => {
            //Verify if the config item exists on "labels" array
            if (!labels.includes(item.columnName)) {
                return;
            }

            const { UIfieldName, UIsection, UIcustomFieldName, columnName } = item;

            if (!UIsection) {
                newFormData[UIfieldName] = columnName;
            }

            if (UIcustomFieldName) {
                newCustomFieldNames[UIfieldName] = UIcustomFieldName;
            }
            if (UIsection) {
                newCustomFieldSections[UIfieldName] = UIsection;
                newCustomFormData[UIfieldName] = columnName;
            }
        });

        setFormData(newFormData);
        setCustomFieldNames(newCustomFieldNames);
        setCustomFieldSections(newCustomFieldSections);
        setCustomFormData(newCustomFormData);
        //----
        const savedCustomFields = savedConfiguration?.filter(item => item.UIsection);
        setImportedCustomFields(savedCustomFields);
    };

    const searchProductColumnNamesWithAI = async (columnNames, fieldNames) => {
        const UIfieldNames = fieldNames;
        const postData = { columnNames, UIfieldNames };

        try {
            const res = await Library.makePostRequest("searchProductColumnNamesWithAI", postData);
            const data = res?.data?.data;

            //NOTE: This will Choose only 1 UIfieldName for each columnName if there are duplicates
            if (res.data.status === 200) {
                const uniqueColumnNames = new Set();
                const uniqueData = data.filter(item => {
                    if (!uniqueColumnNames.has(item.columnName)) {
                        uniqueColumnNames.add(item.columnName);
                        return true;
                    }
                    return false;
                });
                return uniqueData;
            }
        } catch (error) {
            console.error(error);
        }
        return null;
    };

    //This will check if the product list has valid URLs and will return the URLs and the image keys
    const processImages = productList => {
        const isImageURL = url => {
            try {
                new URL(url);
                return true;
            } catch (_) {
                return false;
            }
        };

        //This will return the URLs and the image keys
        const urls = productList.map(item => {
            const validURLs = Object.values(item).filter(value => typeof value === "string" && isImageURL(value));
            return { ...item, validURLs };
        });

        const imageKeysSet = new Set();

        //This will get the image keys
        productList?.forEach(item => {
            Object.keys(item).forEach((key, index) => {
                if (typeof item[key] === "string" && isImageURL(item[key])) {
                    imageKeysSet.add(key);
                }
            });
        });

        //NOTE: max is 4 images, because the form data has only 4 image fields
        const imageKeys = Array.from(imageKeysSet).slice(0, 4);

        return { urls, imageKeys };
    };

    //===========================| Update Form Data |===========================//
    //This will handle all the form data updates asynchronusly
    const updateFormData = async (labels, productList) => {
        setIsLoadingLabels(true);
        // Process the images automatically checking for URLs
        const { urls, imageKeys } = processImages(productList);

        // Use Saved Configuration if available

        const savedConfiguration = await getConfiguration();
        //const savedConfiguration = [];

        setSavedConfiguration(savedConfiguration);
        updateFormDataWithConfiguration(savedConfiguration);

        let aiResultStructure = null;
        setIsLoadingLabels(false);
        // Search for the product column names with AI (for the first page)
        if (!savedConfiguration || savedConfiguration?.length < 1) {
            setAiLoading(true);
            const columnNames = labels;
            const aiResult = await searchProductColumnNamesWithAI(columnNames, fieldNames);

            // Check if there are missing labels and show a popup
            aiResultStructure = await checkMissingAiLabels(aiResult, formData, fieldNamesMatch);

            const aiFormData = aiResultStructure?.updatedFormData;

            setFormData(prevState => {
                const newFormData = { ...prevState };

                // Set the image keys to the form data
                imageKeys?.forEach((key, index) => {
                    //NOTE: Verify if the key is already in the form data, if it is, skip
                    if (Object.values(aiFormData).includes(key)) {
                        return;
                    }

                    //NOTE: max is 4 images, because the form data has only 4 image fields
                    // if (index > 3) return;
                    newFormData[`image${index + 1}`] = key;
                });

                if (aiFormData) {
                    Object.assign(newFormData, aiFormData);
                }

                return newFormData;
            });
            //setAiResult(aiResultStructure?.fieldsStructure);

            if (aiResultStructure?.fieldsStructure) {
                const popupData = showMissingLabelsPopup(aiResultStructure?.fieldsStructure);

                setPopup(popupData);
            }
            setAiLoading(false);
        }

        // handleSetCheckboxState(true) if there are valid URLs
        if (urls.some(item => item.validURLs.length > 0)) {
            handleSetCheckboxState(true);
        }

        return { aiResultStructure, imageKeys };
    };

    /* #endregion */

    /* #region STATES */
    const [isLoadingLabels, setIsLoadingLabels] = useState(false);

    const [isEANValid, setIsEANValid] = useState(true);
    const [detectedEmptyLabel, setDetectedEmptyLabel] = useState([]);

    const [openSuccessLabels, setOpenSuccessLabels] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [openSaveConfiguration, setOpenSaveConfiguration] = useState(false);

    const [isImportBTNEnabled, setIsImportBTNEnabled] = useState(true);
    const [progress, setProgress] = useState(0);

    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedFile, setSelectedFile] = useState("");

    const [labels, setLabels] = useState(null);
    const [productList, setProductList] = useState([]);

    const [newFile, setNewFile] = useState(null);

    const [isErrorInvalidFormat, setIsErrorInvalidFormat] = useState(false);
    const [isReplaceDialogOpen, setIsReplaceDialogOpen] = useState(false);

    const [checkboxState, setCheckboxState] = useState(false);

    const [productImages, setProductImages] = useState([]);
    const [productImagesBase64, setProductImagesBase64] = useState([]);

    const [isNotValidImages, setIsNotValidImages] = useState(false);
    const [isDialogImportOpen, setIsDialogImportOpen] = useState(false);
    const [isImportingLoading, setIsImportingLoading] = useState(false);

    const [tabIndex, setTabIndex] = useState(0);
    const [labelBrands, setLabelBrands] = useState([]);

    const [productImagesStatus, setProductImagesStatus] = useState({
        duplicates: 0,
        found: 0,
        notFound: 0
    });

    const [tabsItems, setTabs] = useState([
        { title: "ID CODES", locked: true, alert: false },
        { title: "ITEM ID", locked: true, alert: false },
        { title: "ITEM VARIATIONS", locked: true, alert: false },
        { title: "MATERIAL & CARE", locked: true, alert: false },
        { title: "TRANSPARENCY & SUSTAINABILITY", locked: true, alert: false },
        { title: "OTHER FIELDS", locked: true, alert: false },
        { title: "IMAGES FILE NAMES", locked: true, alert: false },
        { title: "IMAGES", locked: true, alert: false }
    ]);
    //----

    //==================| Fields Configuration |==================//
    const [customFieldNames, setCustomFieldNames] = useState({});
    const [customFieldSections, setCustomFieldSections] = useState({});
    //========| Form Keys |========//
    const [customFormData, setCustomFormData] = useState({});
    const [formData, setFormData] = useState({
        //------- Header
        headerIndustry: "",
        headerCategory: "",
        headerSubCategory: "",
        //------- ID Codes
        barcode: "",
        referenceCode: "",
        digitalProductPassport: "",
        //------- Item ID
        name: "",
        //styleCode: "",
        shortDescription: "",
        description: "",

        collectionName: "",
        gender: "",
        productType: "",
        category: "",
        SKU: "",

        productID: "",
        countryOfOrigin: "",
        specifications: "",
        features: "",

        qualityDescription: "",
        assortment: "",
        assortmentCode: "",
        comments: "",
        //------- ITEM VARIATIONS
        size1: "",
        size2: "",
        size3: "",
        size4: "",
        color1Name: "",
        color1Description: "",
        color1Code: "",
        color1Hexacode: "",
        //------- Material & Care
        material1: "",
        material2: "",
        material3: "",
        material4: "",
        care1: "",
        care2: "",
        care3: "",
        care4: "",
        //------- TRANSPARENCY & SUSTAINABILITY
        factoryOrigin: "",
        productionLocations: "",
        productionDates: "",
        rawMaterialSource: "",

        batchOrLotNumber: "",
        storageInformation: "",
        certificationsAndStandards: "",
        laborPractices: "",

        carbonFootprint: "",
        waterUsage: "",
        energyConsumption: "",
        waterManagement: "",

        chemicalUsage: "",
        sustainableSourcing: "",
        packaging: "",
        environmentalCertifications: "",
        //------- Other Fields
        weightCustomField: "",
        tariffCode: "",
        field3: "",
        field4: "",
        //------- Images Links/Files Names
        image1: "",
        image2: "",
        image3: "",
        image4: ""
    });

    const [importedCustomFields, setImportedCustomFields] = useState([]);

    const { MainLayoutProps, layoutType } = useOutletContext();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData,
        //----
        selectedFile,
        setSelectedBrand,
        selectedBrand,
        setIsReplaceDialogOpen,
        labels,
        handleFileChange,
        isErrorInvalidFormat,
        setNewFile,
        newFile,
        tabsItems,
        setIsDialogImportOpen,
        createMode,
        fileInputRef,
        setOpenPreview,
        isImportBTNEnabled,
        labelBrands
    };

    const executedTabs = useRef(new Set());
    /* #endregion */

    /* #region EFFECTS */

    useEffect(() => {
        const storedLabelBrands = JSON.parse(localStorage.getItem("labelBrands"));
        if (storedLabelBrands) {
            const formattedLabelBrands = storedLabelBrands.map(item => ({
                value: item._id,
                name: item.name
            }));

            setLabelBrands(formattedLabelBrands);
        }
    }, []);

    useEffect(() => {
        //===============| This will enable the import button when the last tab is not locked and has images
        const lastIndex = tabsItems.length - 1;
        const isLastTabLocked = tabsItems[lastIndex] && tabsItems[lastIndex].locked;
        const isAllTabsWithNoAlert = tabsItems.every(tab => !tab.alert);

        const hasImages = checkboxState ? productImages.length > 0 : productImagesBase64.length > 0;

        setIsImportBTNEnabled(!isLastTabLocked && hasImages && isAllTabsWithNoAlert && isEANValid);
    }, [checkboxState, productImages, productImagesBase64, tabsItems]);

    useEffect(() => {
        if (!selectedBrand || !labels) return;
        // Uncomment the Mandatory fields
        //====================| Mandatory fields |====================//
        const idCodesFields = [
            "barcode",
            "referenceCode"
            // "digitalProductPassport"
        ];

        const itemIDFields = [
            "name",
            //"styleCode",
            "shortDescription",
            "description"
            // "collectionName",
            // "productType",
            // "category",
            // "SKU",
            // "productID",
            // "countryOfOrigin",
            // "specifications",
            // "features",
            // "qualityDescription",
            // "assortment"
            // "assortmentCode",
            // "comments"
        ];

        const itemVaritationsFields = [
            "size1",
            // "size2",
            // "size3",
            // "size4",
            "color1Name"
            // "color1Description",
            // "color1Code"
            // "color1Hexacode",
        ];

        const materialCareFields = [
            "material1",
            // "material2",
            // "material3",
            // "material4",
            "care1"
            // "care2",
            // "care3",
            // "care4",
        ];

        const transparencySustainability = [];

        const otherFields = [];

        const uploadImages = [
            "image1"
            // "image2",
            // "image3",
            // "image4",
        ];

        const imagesGrid = [];

        const allRequiredFields = [
            idCodesFields,
            itemIDFields,
            itemVaritationsFields,
            materialCareFields,
            transparencySustainability,
            otherFields,
            uploadImages,
            imagesGrid
        ];

        let lastValidIndex = labels ? 0 : -1;

        if (formData.headerIndustry !== "" && formData.headerCategory !== "" && formData.headerSubCategory !== "") {
            lastValidIndex = 0;
        }

        for (let i = 0; i < allRequiredFields.length; i++) {
            const allFieldsFilled = allRequiredFields[i].every(field => formData[field] !== "");

            //Check if all fields are filled, if it is, unlock the next tab
            if (allFieldsFilled) {
                lastValidIndex = i + 1;
            } else {
                break;
            }
        }

        const updatedTabs = tabsItems.map((tab, i) => {
            const allFieldsFilled = allRequiredFields[i].every(field => formData[field] !== "");

            const allFieldsHasData = allRequiredFields[i].every(field => {
                const labels = findEmptyLabels([formData[field]]);

                const hasData = !labels.includes(formData[field]);

                if (!hasData) {
                    if (formData[field] && !detectedEmptyLabel.includes(formData[field])) {
                        setDetectedEmptyLabel([...detectedEmptyLabel, formData[field]]);
                    }
                }

                return hasData;
            });

            if (allFieldsFilled && allFieldsHasData) {
                return {
                    ...tab,
                    alert: false,
                    locked: !(i === lastValidIndex || i < lastValidIndex)
                };
            } else {
                return {
                    ...tab,
                    alert: true,
                    locked: !(i === lastValidIndex || i < lastValidIndex)
                };
            }
        });

        setTabs(updatedTabs);

        // handleTabUnlock(lastValidIndex);
    }, [formData, labels]);

    useEffect(() => {
        setupHeaders(props);
    }, [
        props?.layoutType,
        props?.selectedBrand,
        props?.selectedFile,
        props?.labels,
        props?.isErrorInvalidFormat,
        props?.newFile,
        props?.tabsItems,
        props?.createMode,
        props?.isImportBTNEnabled
    ]);

    /* #endregion */

    /* #region METHODS */
    const handleSetCheckboxState = flag => {
        const updatedTabs = tabsItems.map((tab, index) => {
            if (index === 6) {
                return { ...tab, title: flag ? "IMAGES LINKS" : "IMAGES FILE NAMES" };
            }
            return tab;
        });
        setTabs(updatedTabs);
        setCheckboxState(flag);
    };

    const extractHeaders = (file, fileExtension) => {
        const reader = new FileReader();
        reader.onload = e => {
            const data = e.target.result;

            if (fileExtension === "csv") {
                Papa.parse(data, {
                    header: true,
                    skipEmptyLines: true,
                    complete: results => {
                        const detectedHeaders = results.meta.fields;
                        const detectedData = results.data;

                        setLabels(detectedHeaders);
                        setProductList(detectedData);
                    }
                });
            } else if (fileExtension === "xlsx" || fileExtension === "xls") {
                const workbook = XLSX.read(data, { type: "binary", cellText: true, cellDates: true, raw: true });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];

                const detectedData = XLSX.utils.sheet_to_json(worksheet, {
                    header: 1,
                    defval: "",
                    blankrows: false
                });

                const detectedHeaders = detectedData[0];
                const dataWithoutHeaders = detectedData.slice(1);

                const structuredData = dataWithoutHeaders.map(row => {
                    const product = {};
                    detectedHeaders?.forEach((header, index) => {
                        product[header] = row[index] !== undefined ? row[index].toString() : "";
                    });
                    return product;
                });

                setLabels(detectedHeaders);
                setProductList(structuredData);
                setTabIndex(0);
                setOpenSuccessLabels(true);
            } else {
                setIsErrorInvalidFormat(true);
                Library.showErrorMessage(
                    "Double check the file format. If it’s .csv or .xlsx and you see this message, please contact us at support@botag.no"
                );
            }
        };

        if (fileExtension === "csv") {
            reader.readAsText(file);
        } else {
            reader.readAsBinaryString(file);
        }
    };

    //------- ------- Hadle Tabs Lock/Unlock ------- -------//
    const handleTabUnlock = index => {
        setTabs(
            tabsItems.map((tab, i) => {
                if (i === index || i < index) {
                    return { ...tab, locked: false };
                } else {
                    return { ...tab, locked: true };
                }
            })
        );
    };

    //------- ------- Handle Form Resets ------- -------//
    const handleResetForm = () => {
        const resetedFormData = Object.keys(formData).reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});
        setFormData(resetedFormData);
        setProductImages([]);
        setProductImagesBase64([]);
        handleSetCheckboxState(false);
        handleTabUnlock(-1);
        setTabIndex(0);
        setHasUnsavedChanges(false);
        executedTabs.current = new Set();
    };

    const handleFullReset = () => {
        handleResetForm();
        setSelectedFile("");
        setLabels(null);
        setProductList([]);
        setHasUnsavedChanges(false);
        setEmptyLabels([]);
        setDetectedEmptyLabel([]);
    };

    //-------
    const handleConfirmReplaceFile = () => {
        handleResetForm();
        handleFileChange(newFile);
        setNewFile(null);
        setIsReplaceDialogOpen(false);
    };

    const handleCancelReplaceFile = () => {
        setNewFile(null);
        setIsReplaceDialogOpen(false);
    };

    const checkIfFileIsSelected = event => {
        setHasUnsavedChanges(true);
        if (selectedFile && !isErrorInvalidFormat) {
            setNewFile(event);
            setIsReplaceDialogOpen(true);
        } else {
            handleFileChange(event);
        }

        setInputKey(Date.now());
    };

    const handleConfigurations = () => {
        //Normal Fields
        const filteredFields = Object.entries(formData).filter(
            ([key, value]) =>
                value !== "" && key !== "headerIndustry" && key !== "headerCategory" && key !== "headerSubCategory"
        );

        //Custom Fields
        const filteredCustomFields = Object.entries(customFormData).filter(([key, value]) => value !== "");

        const filteredData = [...filteredFields, ...filteredCustomFields];

        const configuration = filteredData.map(([key, value], index) => {
            return {
                UIfieldName: key,
                UIsection: customFieldSections[key] || "",
                UIcustomFieldName: customFieldNames[key] || "",
                columnName: value,
                columnIndex: index
            };
        });

        return configuration;
    };

    const updateLabelBrandProductsImporter = async () => {
        const labelBrandID = selectedBrand?.value;

        const configuration = handleConfigurations();
        //const configuration = [];

        const data = { labelBrandID, configuration };

        try {
            await Library.makePostRequest("updateLabelBrandProductsImporter", data);
            // if (res.data?.status === 200) {
            //     Library.showSuccessMessage("Label Brand Products Importer updated successfully.");
            //     // handleImport();
            // }
        } catch (error) {
            //TODO: Update this showErrorMessage to a toastMessage
            Library.showErrorMessage("An error occurred while updating the Label Brand Products Importer.");
            // setOpenSaveConfiguration(false);
        }
    };

    const saveConfigurationAndImport = () => {
        updateLabelBrandProductsImporter();
        handleImport();
    };

    //======================| Import Products |======================//
    const handleImport = async () => {
        setIsImportingLoading(true);

        //TODO: Create here a verefication to check labels with no data
        // verififyMandatoryValues(aiData?.updatedFormData);

        //INFO: Verefication to check if the file has invalid images
        if (productImagesStatus.notFound > 0) {
            setIsNotValidImages(true);
            setIsImportingLoading(false);
            setOpenSaveConfiguration(false);
            handleFullReset();
            return;
        }

        // Remove empty fields from the form data
        const filteredFormData = Object.fromEntries(Object.entries(formData).filter(([key, value]) => value !== ""));

        const labelBrandID = selectedBrand?.value;

        const productsData = productList.map(product => {
            return createProductData(
                product,
                filteredFormData,
                labelBrandID,
                productImagesBase64,
                checkboxState,
                customFormData,
                customFieldNames
            );
        });

        const paramsData = { labelBrandID };
        const context = "Scripts";
        const endpoint = "importProducts";

        const chunkArray = (array, size) => {
            const result = [];
            for (let i = 0; i < array.length; i += size) {
                result.push(array.slice(i, i + size));
            }
            return result;
        };

        const chunkSize = checkboxState ? 100 : 10; // Chunk Size 100 for images links and 10 for images files
        const productChunks = chunkArray(productsData, chunkSize);

        const maxConcurrentRequests = 5; // Number of concurrent requests allowed
        const delayBetweenRequests = 300; // Delay between request

        const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

        // Values accumulator
        let totalGTINsCreated = 0;
        let totalGTINsUpdated = 0;
        let totalGTINsError = 0;
        let totalProductsCreated = 0;
        let totalProductsError = 0;
        let totalProductsUpdated = 0;
        let totalProcessed = 0;

        const processChunk = async (chunk, index) => {
            const chunkParamsData = { ...paramsData, productsData: chunk };
            const res = await Library.makeCommonPostRequest(context, endpoint, chunkParamsData);

            if (res.data?.status === 200) {
                const resData = res.data?.data;

                //TODO: Check why this is showing values above 10 when the chunk size is 10
                totalGTINsCreated += resData?.totalGTINsCreated || 0;
                totalGTINsUpdated += resData?.totalGTINsUpdated || 0;
                totalGTINsError += resData?.totalGTINsError || 0;
                totalProductsCreated += resData?.totalProductsCreated || 0;
                totalProductsError += resData?.totalProductsError || 0;
                totalProductsUpdated += resData?.totalProductsUpdated || 0;
                totalProcessed += chunk.length;

                // Progress
                const progress = ((totalProcessed / productsData.length) * 100).toFixed(2);

                setProgress(progress);
                console.log(`Importing products - ${totalProcessed}/${productsData.length} Ready, ${progress}%`);
            }
        };

        try {
            for (let i = 0; i < productChunks.length; i += maxConcurrentRequests) {
                const chunkPromises = productChunks
                    .slice(i, i + maxConcurrentRequests)
                    .map((chunk, index) => processChunk(chunk, index));

                await Promise.all(chunkPromises);
                await delay(delayBetweenRequests);
            }
            await delay(1000); //this will give time to the progress bar to reach 100%

            // Process the accumulated results after all requests are completed
            //TODO: Update this to show not only the GTINs but also the products
            if (totalGTINsCreated === 0 && totalGTINsUpdated === 0 && totalGTINsError > 0) {
                Library.showErrorMessage(` ${totalGTINsError} Products were not imported.`);
                setOpenSaveConfiguration(false);
                setIsDialogImportOpen(false);
                return;
            }

            // If user is onboarding in the boimages page, it will redirect to that page after products are imported
            if (boImageProductsImporting === 2) {
                setBOImageProductsImporting(false);
                setProductsImportedPopup({
                    show: true,
                    productsImported: totalProductsCreated,
                    productsUpdated: totalProductsUpdated,
                    productsNotImported: totalProductsError,

                    gtinsImported: totalGTINsCreated,
                    gtinsUpdated: totalGTINsUpdated,
                    gtinsNotImported: totalGTINsError
                });

                setTimeout(() => {
                    navigate("/boimages/create?continue=true");
                }, 200);
            } else {
                Library.showSuccessMessage(
                    <>
                        {totalGTINsCreated > 0 && <p> {totalGTINsCreated} GTINs were imported.</p>}
                        {totalGTINsUpdated > 0 && <p> {totalGTINsUpdated} GTINs were updated.</p>}
                        {totalGTINsError > 0 && <p> {totalGTINsError} GTINs were not imported.</p>}
                        {/* ------ */}
                        {totalProductsCreated > 0 && <p> {totalProductsCreated} Products were created.</p>}
                        {totalProductsUpdated > 0 && <p> {totalProductsUpdated} Products were updated.</p>}
                        {totalProductsError > 0 && <p> {totalProductsError} Products were not imported.</p>}
                    </>
                );
                setOpenSaveConfiguration(false);
                setIsDialogImportOpen(false);
            }
        } catch (error) {
            Library.showErrorMessage("An error occurred during the import process.");
        } finally {
            setIsImportingLoading(false);
            setProgress(0);
            setOpenSaveConfiguration(false);
            setIsDialogImportOpen(false);
            handleResetForm();
            setSelectedFile("");
            setLabels(null);
            setProductList([]);

            setHasUnsavedChanges(false);
        }
    };

    const columns = labels?.map(label => {
        return {
            name: label,
            selector: row => row[label],
            sortable: true,
            minWidth: "150px",
            maxWidth: "150px"
        };
    });

    //======================| Check if the Configuration is the same |======================//
    const removeIdProperty = array => {
        return array?.map(({ _id, ...rest }) => rest);
    };

    const arraysAreEqual = (arr1, arr2) => {
        const arr1WithoutId = removeIdProperty(arr1);
        const arr2WithoutId = removeIdProperty(arr2);
        return _.isEqual(arr1WithoutId, arr2WithoutId);
    };

    // Exemplo de uso
    const handleImportConfirm = () => {
        const configuration = handleConfigurations();

        const newFile = configuration;
        const productList = savedConfiguration;

        if (arraysAreEqual(productList, newFile)) {
            handleImport();
            //setIsDialogImportOpen(false);
        } else {
            setOpenSaveConfiguration(true);
            setIsDialogImportOpen(false);
        }
    };

    //NOTE: This will find the labels with no data
    const findEmptyLabels = labels => {
        const emptyLabels = labels?.filter(label => {
            return productList?.every(product => {
                const value = product[label];
                return value === "" || value === null || value === undefined;
            });
        });

        return emptyLabels;
    };

    const verififyMandatoryValues = data => {
        const newData = { ...data?.aiResultStructure?.fieldsStructure, uploadImages: { image1: data?.imageKeys[0] } };

        //NOTE: This will get all the fields that are mandatory using the data from the "AIResultStructure"
        const availableFields = Object.keys(newData)
            .map(key => {
                const value = newData[key];

                return Object.values(value).filter(field => field !== "" && field !== null && field !== undefined);
            })
            .flat();

        const emptyLabels = findEmptyLabels(availableFields);
        return emptyLabels;
    };

    const handleContinueSuccessLabels = async () => {
        setDetectedEmptyLabel([]);
        setOpenSuccessLabels(false);
        const updateData = await updateFormData(labels, productList);

        const emptyLabels = verififyMandatoryValues(updateData);
        setEmptyLabels(emptyLabels);

        //=======| This will add alert "true" to the tabs that have empty values or values that have empty data |=======//
        setTabs(
            tabsItems.map((tab, i) => {
                const fields = Object.values(updateData?.fieldsStructure)?.[i];
                const hasEmptyValues = fields && Object.values(fields).some(value => !value);
                //NOTA:Check if the label has no data
                const labelHasNoData = fields && Object.values(fields).some(value => emptyLabels.includes(value));

                return { ...tab, alert: hasEmptyValues || labelHasNoData };
            })
        );
    };

    const checkForBarCodes = productList => {
        const barCodes = productList.map(item => {
            const barCode = Object.values(item).filter(value => typeof value === "string" && value.length === 13);
            return barCode;
        });
        return barCodes.length;
    };

    const handleConfirmPopupLabels = () => {
        setPopup({ ...popup, show: false });
        if (emptyLabels.length > 0) setEmptyLabelsPopup(true);
    };

    const handleCancelImport = () => {
        setEmptyLabelsPopup(false);
        handleFullReset();
    };

    const handleProceedManually = () => {
        setEmptyLabelsPopup(false);

        //setTabs();
    };

    /* #endregion */

    return (
        <>
            <FullScreenLoader isLoading={isLoadingLabels} />

            <FullScreenOverlay show={emptyLabelsPopup}>
                <DialogBoxComponent
                    heading="OOPS!"
                    headingColor="var(--text-icons-red-500)"
                    title={
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    gap: "24px",
                                    marginTop: "16px"
                                }}>
                                <img style={{ width: "46px" }} src={aiLogo} alt="AI Logo" />
                                <img style={{ width: "46px" }} src={error} alt="AI Logo" />
                            </div>

                            <h3 className="ready-delete" style={{ fontSize: "18px", margin: "16px 28px" }}>
                                Mandatory fields are empty!
                            </h3>
                        </div>
                    }
                    useAlternativeLayout={
                        <div>
                            The following labels have no data in your file
                            {emptyLabels.map(label => (
                                <p key={label}>{`[${label}]`}</p>
                            ))}
                            <p />
                            ...
                            <p />
                            You can fix the file and import again or you can proceed and we will add ‘Not provided’ to
                            the field.
                            <br /> <br />
                            NOTE: this is relevant information and may be missed by your consumers on HeyBO!
                            <br />
                            <br />
                        </div>
                    }
                    onClickNo={handleCancelImport}
                    onClickYes={handleProceedManually}
                    cancelText="Cancel import"
                    confirmText="Proceed manually"
                    buttonWidth="150px"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={isNotValidImages}>
                <DialogBoxComponent
                    heading="OOPS!"
                    headingColor="var(--text-icons-red-500)"
                    title={
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    gap: "24px",
                                    marginTop: "16px"
                                }}>
                                <img style={{ width: "46px" }} src={aiLogo} alt="AI Logo" />
                                <img style={{ width: "46px" }} src={error} alt="AI Logo" />
                            </div>

                            <h3 className="ready-delete" style={{ fontSize: "18px", margin: "16px 28px" }}>
                                Images url not valid!
                            </h3>
                        </div>
                    }
                    useAlternativeLayout={
                        <div>
                            One or more links provided to access product images may not work due to wrong format.
                            <br /> <br />
                            Check if links in the imported file are working (try clicking in a few of them and confirm
                            they lead to the product image). If everything looks fine with the link the problem may be
                            on our side.
                            <br /> <br />
                            Please contact support@botag.no.
                            <br />
                            <br />
                        </div>
                    }
                    onClickNo={() => setIsNotValidImages(false)}
                    cancelText="CLOSE"
                    showConfirm={false}
                    buttonWidth="150px"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showErrorInvalidFormat}>
                <DialogBoxComponent
                    heading="ATTENTION!"
                    headingColor="var(--text-icons-red-500)"
                    title="File type not valid!"
                    message="Make sure you’re using an .xls, .xlsx or .cvs file to import your products."
                    onClickNo={() => setShowErrorInvalidFormat(false)}
                    cancelText="CONTINUE"
                    showConfirm={false}
                    buttonWidth="150px"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={openSuccessLabels}>
                <DialogBoxComponent
                    heading="SUCCESS!"
                    headingColor="var(--text-icons-green-500)"
                    title="Import Summary"
                    onClickNo={handleContinueSuccessLabels}
                    cancelText="CONTINUE"
                    useAlternativeLayout={
                        <div
                            style={{
                                fontSize: "14px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                                marginBottom: "32px"
                            }}>
                            <p>
                                The import process has been successfully completed. Below is a summary of the data
                                that’s being imported.
                            </p>

                            <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                                <p>Total Products Imported: [{productList?.length}]</p>
                                <p>Total Barcodes Processed: [{checkForBarCodes(productList)}]</p>
                            </div>
                        </div>
                    }
                    showConfirm={false}
                    buttonWidth="150px"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={aiLoading || popup.show}>
                <DialogBoxComponent
                    heading={popup.heading}
                    loadingHeading="CHECKING..."
                    loading={aiLoading}
                    customLoader={
                        <div
                            style={{
                                margin: "16px 24px 32px 24px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "32px"
                            }}>
                            <img style={{ width: "46px" }} src={aiLogo} alt="AI Logo" />
                            <ContainerLoader loaderHeight="10px" loaderAnimation={animationData} />
                            <div>BOtag AI is analyzing your data...</div>
                        </div>
                    }
                    title={
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    gap: "24px",
                                    marginTop: "16px"
                                }}>
                                <img style={{ width: "46px" }} src={aiLogo} alt="AI Logo" />
                                <img style={{ width: "46px" }} src={popup.icon} alt="AI Logo" />
                            </div>

                            <h3 className="ready-delete" style={{ fontSize: "18px", margin: "16px 28px" }}>
                                {popup.title}
                            </h3>
                        </div>
                    }
                    message={popup.message}
                    cancelText={popup.confirmText}
                    onClickNo={handleConfirmPopupLabels}
                    headingColor={aiLoading ? "#7763FE" : popup.headingColor || "var(--text-icons-green-500)"}
                    showConfirm={false}
                    buttonWidth="150px"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={openPreview}>
                <SimpleListComponent
                    columns={columns}
                    listData={productList}
                    pagination={false}
                    selectableRows={false}
                    title="PRODUCTS FILE PREVIEW"
                    onClose={() => setOpenPreview(false)}
                />
            </FullScreenOverlay>
            <FullScreenOverlay show={openSaveConfiguration}>
                <DialogBoxComponent
                    loading={isImportingLoading}
                    customLoader={<CustomLoader process={progress} fileName={selectedFile} />}
                    loadingHeading="IMPORTING..."
                    heading="JUST CHECKING.."
                    title="Save configuration?"
                    onClickYes={saveConfigurationAndImport}
                    onClickNo={handleImport}
                    cancelText="NO"
                    confirmText="YES"
                    message="If pressed YES, all fields filled will be auto-filled for the next product uploads"
                    headingColor="var(--text-icons-green-500)"
                />
            </FullScreenOverlay>
            <FullScreenOverlay show={isDialogImportOpen}>
                <DialogBoxComponent
                    loading={isImportingLoading}
                    customLoader={<CustomLoader process={progress} fileName={selectedFile} />}
                    loadingHeading="IMPORTING..."
                    heading="JUST CHECKING.."
                    title="Ready to import?"
                    onClickYes={handleImportConfirm}
                    onClickNo={() => setIsDialogImportOpen(false)}
                    cancelText="NO"
                    confirmText="YES"
                    message="You can still change it later. No worries:)"
                    headingColor="var(--text-icons-green-500)"
                />
            </FullScreenOverlay>
            <FullScreenOverlay show={isReplaceDialogOpen}>
                <DialogBoxComponent
                    heading="ATTENTION"
                    title="Replacing file?"
                    onClickYes={handleConfirmReplaceFile}
                    onClickNo={handleCancelReplaceFile}
                    confirmText="CONFIRM"
                    cancelText="CANCEL"
                    message="You are about to replace the uploaded file by a new one. You will lose everything you’ve done so far."
                    headingColor="var(--text-icons-orange-500)"
                />
            </FullScreenOverlay>
            <FormContainer title="PRODUCTS FILE" showDefaultRightLegends={true}>
                <FormComponent
                    labels={labels}
                    isErrorInvalidFormat={isErrorInvalidFormat}
                    productList={productList}
                    tabsItems={tabsItems}
                    tabIndex={tabIndex}
                    formData={formData}
                    setFormData={setFormData}
                    setTabIndex={setTabIndex}
                    checkboxState={checkboxState}
                    handleSetCheckboxState={handleSetCheckboxState}
                    productImages={productImages}
                    setProductImages={setProductImages}
                    setCustomFormData={setCustomFormData}
                    setProductImagesBase64={setProductImagesBase64}
                    productImagesBase64={productImagesBase64}
                    selectedBrand={selectedBrand}
                    customFieldNames={customFieldNames}
                    setCustomFieldNames={setCustomFieldNames}
                    setCustomFieldSections={setCustomFieldSections}
                    customFormData={customFormData}
                    importedCustomFields={importedCustomFields}
                    setProductImagesStatus={setProductImagesStatus}
                    productImagesStatus={productImagesStatus}
                    setIsEANValid={setIsEANValid}
                    detectedEmptyLabel={detectedEmptyLabel}
                />
            </FormContainer>
            <input
                key={inputKey}
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
                onChange={checkIfFileIsSelected}
            />
        </>
    );
}

function setupHeaders(props) {
    const productsToRoute = props.createMode ? "#" : "/products";

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Products", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: productsToRoute, active: true, disabled: false, title: "Products" },
        /*{ to: "#", active: false, disabled: true, title: "Stock" },*/
        { to: "/products/archive", active: false, disabled: false, title: "Archive" }
    ]);
    props.setControlBarRightBtns([
        { to: "/products", active: false, disabled: props.createMode, title: "View", icon: "eye" },
        { to: "/products/add", active: true, disabled: false, title: "Add", icon: "plus" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <ProductPageAddHeader
                setSelectedBrand={props.setSelectedBrand}
                selectedBrand={props.selectedBrand}
                selectedFile={props.selectedFile}
                labels={props.labels}
                isErrorInvalidFormat={props.isErrorInvalidFormat}
                setIsDialogImportOpen={props.setIsDialogImportOpen}
                fileInputRef={props.fileInputRef}
                setOpenPreview={props.setOpenPreview}
                isImportBTNEnabled={props.isImportBTNEnabled}
                labelBrands={props.labelBrands}
            />
        )
    });
}
