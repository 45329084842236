import styled from "styled-components";
import Lottie from "react-lottie";
import animationData from "./../../../assets/animations/loader-animation.json";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
    }

    .loader-container {
        border-radius: 4px;
        background: var(--backgrounds-lines-n-400);
        display: inline-flex;
        padding: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: center;
        gap: 10px;
        width: 84px;
        height: 84px;
        position: absolute;
        z-index: 99999;
    }

    .use-position-top .loader-container {
        position: absolute;
        top: 200px;
    }

    .blur-filter {
        filter: blur(2px);
    }

    .child-container {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        width: 100%;
    }

    .overlay-filter {
        background: rgb(16 20 27 / 80%);
        z-index: 9999;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    /*.background-overlay {
        background: rgb(16 20 27 / 80%);
        z-index: 9999;
        height: 100%;
        width: 100%;
        position: absolute;
    }*/

    div[aria-label="animation"] {
        align-self: center;
    }

    .align-center-y {
        position: absolute;
        top: calc(50% - 88px);
    }
`;
/* #endregion */

/**
 * ContainerLoader component, to display a loader over the children elements.
 *
 * @param {Object} props - The component props.
 * @param {string} props.className - Additional CSS class name for the component.
 * @param {boolean} props.isLoading - The flag to enable or disable the loader.
 * @param {boolean} props.isLoadingOverlay - When enabled the loader will be displayed over the children elements.
 * @param {boolean} props.addBlurFilters - When enabled the loader will add blur filters to the children elements.
 * @param {string} props.loadingPositionFromTop - The loading position from top in pixels. When used, it won't center loading vertically.
 * @param {JSX.Element} props.children - The component children. Should not be used as a prop, but to wrap children components. See example.
 * @param {boolean} props.alignPageCenter - When enabled the loader will be displayed in the center of the page vertically.
 * @param {string} props.loaderHeight - The loader height.
 * @param {string} props.loaderAnimation - The loader animation data.
 * @param {string} props.loaderWidth - The loader width.
 * @returns {JSX.Element} The ContainerLoader component.
 * @example <ContainerLoader isLoading={true} >
 *    <div className="children-container"> (...) </div>
 * </ContainerLoader>
 */
export default function ContainerLoader(props) {
    const isLoadingOverlay = props?.isLoadingOverlay ?? true;
    const isLoading = props?.isLoading ?? true;
    const addBlurFilters = props?.addBlurFilters ?? true;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: props?.loaderAnimation || animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    /* TODO: Add blur filters to the children elements
    props?.children.map(item => {
        console.log(item.props);
        if (isLoading && props?.addBlurFilters) {
            if (item?.props?.className) item.props.className += " loading-filters";
            //else item.props.className = "loading-filters";
        }
        return item; // Add return statement
    });*/

    return (
        <StyledPage
            className={`${props?.className || ""} ${props?.loadingPositionFromTop ? "use-position-top" : ""}`}
            style={props?.alignPageCenter && isLoading ? { height: "calc(100vh - 200px)" } : undefined}>
            {isLoading && (
                <div
                    className={`loader-container ${props?.alignPageCenter && isLoading ? "align-center-y" : ""}`}
                    style={{
                        top: props?.loadingPositionFromTop,
                        height: props?.loaderHeight,
                        width: props?.loaderWidth
                    }}>
                    <Lottie options={defaultOptions} height={120} width={120} />
                </div>
            )}
            {isLoading && isLoadingOverlay && !addBlurFilters && <div className="overlay-filter" />}
            <div className={`child-container ${isLoading && isLoadingOverlay && addBlurFilters ? "blur-filter" : ""}`}>
                {(!isLoading || (isLoading && isLoadingOverlay)) && props?.children}
            </div>
        </StyledPage>
    );
}
