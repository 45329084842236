import React, { useEffect, useState } from "react";
import styled from "styled-components";

const InputTimePickerContainer = styled.div`
    &.input-field-dropdown,
    &.input-field-dropdown * {
        box-sizing: border-box;
    }
    &.input-field-dropdown {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px;
        padding: 12px 8px 12px 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        min-width: 65px;
        position: absolute;
        box-shadow: var(--drop-shadow-100-box-shadow, 0px 0px 10px 0px rgba(17, 22, 32, 1));
        z-index: 9999;
        height: 310px;
        flex-wrap: wrap;
    }

    .frame-74::-webkit-scrollbar {
        border-radius: 500px;
        flex-shrink: 0;
        width: 3px;
    }
    .frame-74 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;

        align-items: flex-start;
        justify-content: flex-start;
        height: 300px;
        flex-wrap: wrap;
    }
    .frame-75 {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    ._10-30 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
    .frame-350 {
        padding: 0px 0px 0px 4px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
`;

export default function InputTimePicker({ className, style, show = false, setShow, onTimeChange, uniqueID }) {
    /* #region VARS */
    const [allHours, setAllHours] = useState([]);
    const width = style?.width || "106px";
    /* #endregion */

    /* #region METHODS */
    const generateAllHours = () => {
        const hours = [];
        for (let i = 0; i <= 24; i++) {
            const hour = i < 10 ? `0${i}` : `${i}`;
            const minute = "00";
            // const hour = i < 10 ? `0${i}` : `${i}`;
            // for (let j = 0; j < 60; j += 30) {
            //     const minute = j < 10 ? `0${j}` : `${j}`;
            //     hours.push(`${hour}:${minute}`);
            // }
            hours.push(`${hour}:${minute}`);
        }
        setAllHours(hours);
    };

    const onSelectedHour = hour => {
        if (onTimeChange) {
            onTimeChange(hour);
        }
        setShow(false);
    };

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        generateAllHours();
    }, []);

    useEffect(() => {
        // Close the dropdown when the user clicks outside of it
        const onClick = e => {
            if (e.target.closest(`.${uniqueID}`)) return;
            setShow(false);
        };
        document.addEventListener("click", onClick);
        return () => document.removeEventListener("click", onClick);
    }, [uniqueID]);

    /* #endregion */

    return !show ? (
        <></>
    ) : (
        <InputTimePickerContainer
            className={`input-field-dropdown ${className ? className : ""}`}
            style={{ ...style, width }}>
            <div className="frame-74">
                {allHours.map((hour, index) => (
                    <div key={index} className="frame-75" onClick={() => onSelectedHour(hour)}>
                        <div className="_10-30">{hour}</div>
                    </div>
                ))}
            </div>
        </InputTimePickerContainer>
    );
}
