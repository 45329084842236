import React, { useEffect, useState } from "react";
import InfinityGridRetailersConnectionsCard from "../../common/cardContainers/InfinityGridRetailersConnectionsCard";
import { APP_TITLE } from "../../../config/constant";
import { useOutletContext } from "react-router";
import ListComponent from "../../common/lists/ListComponent";
import { useSelector } from "react-redux";
import * as Library from "../../../utils/Library";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import { AppQueryClient } from "../../../api/queryClient";
import { maskEmail, maskName } from "../../../utils/valuesMasking";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import { defaultStatusColors } from "../../../utils/CardUtils";
import MainWindowHeader from "../../common/mainApp/mainWindowHeaders/MainWindowHeader";

export default function RetailersConnectionsPage() {
    document.title = `${APP_TITLE} - Retailers Connections`;

    // #region VARS
    const [isLoading, setIsLoading] = useState(false);
    const [isDialogImportOpen, setIsDialogImportOpen] = useState(false);
    const [inviteID, setInviteID] = useState(null);

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);

    const [listNumRows, setListNumRows] = useState(10);

    const listColumns = [
        {
            name: "Retail Brand",
            selector: row => row?.retailBrand?.name || "--"
        },
        {
            name: "To Brand",
            selector: row => row?.labelBrand?.name || "--"
        },
        {
            name: "Invited on",
            selector: row => formatDefaultDateTime(row?.createdAt) || "--"
        },
        {
            name: "Accepted on",
            selector: row => formatDefaultDateTime(row?.acceptedOn) || "--"
        },
        {
            name: "Contact person",
            selector: row =>
                row?.status !== "Active" ? maskName(row?.contactFirstName) : row?.contactFirstName || "--"
        },
        {
            name: "Contact email",
            selector: row => (row?.status !== "Active" ? maskEmail(row?.contactEmail) : row?.contactEmail || "--")
        },
        {
            name: "Affiliated Stores",
            selector: row => (row?.retailBrand?._id ? row?.maxStores : "--")
        },
        {
            name: "Retailer Active Stores",
            selector: row => row?.retailerActiveStores || "--"
        }
    ];

    //---------- Header Filters
    const [allConnectedRetailBrandsList, setAllConnectedRetailBrandsList] = useState([]);
    const [allRetailersList, setAllRetailersList] = useState([]);
    const [filteredRetailers, setFilteredRetailers] = useState([]);
    //-----
    const [allLabelBrandsList, setAllLabelBrandsList] = useState([]);
    const [filteredLabelBrand, setFilteredLabelBrand] = useState([]);
    //-----
    const [requestEnabled, setRequestsEnabled] = useState(false);
    const filters = {
        filterLabelBrandIDs: filteredLabelBrand?.map(item => item?.value),
        filterRetailBrandIDs: filteredRetailers?.map(item => item?.value)
    };

    const infiniteGridQuerykey = ["RetailersConnectionsGrid", filters];
    const listQueryKey = ["RetailersConnectionsList", filters];

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    // #endregion

    // #region METHODS
    const onLabelBrandChange = selection => {
        setFilteredLabelBrand([selection]);

        const currentLabelBrandID = selection?.value;
        filterConnectedRetailers(allConnectedRetailBrandsList, currentLabelBrandID);
    };

    const onRetailersChange = (selections, dropdownDataWithSelections) => {
        setFilteredRetailers(selections);
        setAllRetailersList(dropdownDataWithSelections);
    };

    const onFiltersLoaded = data => {
        let currentLabelBrandID = null;
        if (data?.labelBrands?.length) {
            const labelBrands = data?.labelBrands?.map((item, index) => {
                return {
                    name: item?.name,
                    value: item?._id,
                    selected: index === 0
                };
            });

            setAllLabelBrandsList(labelBrands);
            setFilteredLabelBrand([labelBrands[0]]);
            currentLabelBrandID = labelBrands[0]?.value;
        }

        if (data?.connectedRetailBrands?.length) {
            const connectedRetailBrands = data?.connectedRetailBrands?.map(item => {
                return {
                    name: item?.name,
                    value: item?._id,
                    selected: true,
                    labelBrandID: item?.labelBrandID
                };
            });

            setAllConnectedRetailBrandsList(connectedRetailBrands);
            setFilteredRetailers(connectedRetailBrands?.filter(item => item?.selected));

            filterConnectedRetailers(connectedRetailBrands, currentLabelBrandID);
        }

        setRequestsEnabled(true);
    };

    const filterConnectedRetailers = async (allRetailBrands, currentLabelBrandID) => {
        let res = allRetailBrands?.filter(item => {
            return item?.labelBrandID === currentLabelBrandID;
        });

        // filter out any duplicated retailers
        res = res?.filter((item, index) => {
            return res?.findIndex(item2 => item2?.value === item?.value) === index;
        });

        setAllRetailersList(res);
        setFilteredRetailers([]);
    };

    const handleAcceptInvitation = async inviteID => {
        const res = await Library.makeCommonPostRequest(
            "Invites",
            "acceptRetailerInvite",
            {
                inviteID: inviteID,
                BOstagesAccountID: localStorage.getItem("BOstagesAccountID")
            },
            false,
            setIsLoading
        );

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                type: "success",
                title: "Invitation accepted!",
                message: "Invitation accepted successfully!"
            });

            onClickRefreshAction();
        }
    };
    const handleDeclineInvitation = async inviteID => {
        const res = await Library.makeCommonPostRequest(
            "Invites",
            "declineRetailerInvite",
            {
                inviteID: inviteID,
                BOstagesAccountID: localStorage.getItem("BOstagesAccountID")
            },
            false,
            setIsLoading
        );

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                type: "success",
                title: "Invitation declined!",
                message: "Invitation declined successfully!"
            });

            onClickRefreshAction();
        }
    };

    const handleDeleteInvitation = async inviteID => {
        const res = await Library.makeCommonPostRequest(
            "Invites",
            "hideInvite",
            {
                inviteID: inviteID,
                BOstagesAccountID: localStorage.getItem("BOstagesAccountID")
            },
            false,
            setIsLoading
        );

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                type: "success",
                title: "Invitation deleted!",
                message: "Invitation deleted successfully!"
            });

            onClickRefreshAction();
        }
    };

    const onOpenDeclineDialog = inviteID => {
        setIsDialogImportOpen(true);
        setInviteID(inviteID);
    };

    const onCloseDeclineDialog = () => {
        setIsDialogImportOpen(false);
        setInviteID(null);
    };

    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };

    // #endregion Methods

    // #region EFFECTS
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(
            props,
            onClickRefreshAction,
            navigations,
            //----
            allRetailersList,
            allLabelBrandsList,
            onLabelBrandChange,
            onRetailersChange,
            onFiltersLoaded,
            requestEnabled,
            filteredLabelBrand,
            filteredRetailers
        );
    }, [
        props?.layoutType,
        requestEnabled,
        allRetailersList,
        allLabelBrandsList,
        filteredLabelBrand,
        filteredRetailers,
        requestEnabled
    ]);
    // #endregion EFFECTS

    // #region RENDER
    return (
        <>
            <FullScreenLoader isLoading={isLoading} />

            <FullScreenOverlay show={isDialogImportOpen}>
                <DialogBoxComponent
                    // loading={isImportingLoading}
                    loadingHeading="DECLINING..."
                    heading="DECLINING..."
                    title="Declining invitation?"
                    message={"Please confirm you want to decline The Product invitation."} //TODO: SEND COMPONENT OR CREATE A PROP
                    onClickYes={() => console.log("Yes")}
                    onClickNo={onCloseDeclineDialog}
                    cancelText="NO"
                    confirmText="YES"
                    headingColor="var(--text-icons-red-500)"
                />
            </FullScreenOverlay>

            {viewLayoutStyle === "Grid" && (
                <InfinityGridRetailersConnectionsCard
                    minColumnWidth="238px"
                    limit={10}
                    queryKey={infiniteGridQuerykey}
                    statusFromPath="status"
                    onAcceptInvitation={handleAcceptInvitation}
                    onDeclineInvitation={onOpenDeclineDialog}
                    onDeleteInvitation={handleDeleteInvitation}
                    requestEnabled={requestEnabled}
                    filters={filters}
                />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="RETAILERS"
                    //title2="(LAST 10 INVITED)"
                    columns={listColumns}
                    api={{
                        endpoint: "getConnectedRetailers",
                        useCommonPostRequest: false,
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            limit: listNumRows,
                            ...filters
                        }
                    }}
                    pageLimit={listNumRows}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        onClickRemove: row => {
                            handleDeleteInvitation(row?._id);
                        },

                        includeActions: true,
                        extraActions: [
                            {
                                title: "Decline",
                                onClick: row => {
                                    if (row?.status !== "Request" && row?.status !== "Invited") return undefined;
                                    handleDeclineInvitation(row?._id);
                                },
                                isDisabled: row => {
                                    return row?.status !== "Request" && row?.status !== "Invited";
                                }
                            },
                            {
                                title: "Accept",
                                onClick: row => {
                                    if (row?.status !== "Request" && row?.status !== "Invited") return undefined;
                                    handleAcceptInvitation(row?._id);
                                },
                                isDisabled: row => {
                                    return row?.status !== "Request" && row?.status !== "Invited";
                                }
                            }
                        ]
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    requestEnabled={requestEnabled}
                    onChangeRowsPerPage={rowsPerPage => setListNumRows(rowsPerPage)}
                />
            )}
        </>
    );
    // #endregion RENDER
}

// #region FUNCTIONS
function setupHeaders(
    props,
    onClickRefreshAction,
    navigations,
    allRetailersList,
    allLabelBrandsList,
    onLabelBrandChange,
    onRetailersChange,
    onFiltersLoaded,
    requestEnabled,
    filteredLabelBrand,
    filteredRetailers
) {
    const createMode = navigations?.navigationAccesses?.retailers?.createModeOnly;

    props.setControlBarLeftBtns([
        //{ to: "#", disabled: true, title: "Retailers", icon: "fa-magnifying-glass", isSearchToogle: false }
    ]);
    props.setControlBarCenterBtns([
        { to: "/retailers/connections", active: true, disabled: false, title: "connections" },
        { to: "/retailers/invitations", active: false, disabled: false, title: "Invitations" },
        { to: "/retailers/requests", active: false, disabled: false, title: "Requests" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: createMode, title: "View", icon: "eye" },
        { to: "/retailers/invite", active: false, disabled: false, title: "Invite", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <MainWindowHeader
                key={window.location.pathname}
                visible={true}
                layoutTypeOptions={{
                    gridEnabled: true,
                    listEnabled: true
                }}
                onRefreshClick={onClickRefreshAction}
                firstOrganize={{
                    visible: true,
                    dropdownData: allLabelBrandsList,
                    onDropdownChange: onLabelBrandChange,
                    disabled: false,
                    type: "singleSelect",
                    selectedValue: filteredLabelBrand?.[0]?.name,

                    multidropdownOptions: {
                        useDoneButton: true,
                        useSelectAll: true,
                        selectAllText: "Select all Label Brands",
                        selectAllPlaceholderFunction: arrayItems => {
                            if (!arrayItems?.length) return "No Label Brands";

                            const nonLockedLabelBrands = allLabelBrandsList?.filter(item => !item?.locked);
                            const areAllSelected = arrayItems?.length === nonLockedLabelBrands?.length;
                            if (areAllSelected) return `All Label Brands (${nonLockedLabelBrands?.length})`;

                            let firstTwoItems = arrayItems
                                ?.slice(0, 2)
                                ?.map(item => item?.name)
                                ?.join(", ");
                            if (arrayItems?.length > 2) {
                                firstTwoItems = firstTwoItems + ", and more";
                            }

                            return `Label Brands (${arrayItems?.length}): ${firstTwoItems}`;
                        }
                    }
                }}
                secondOrganize={{
                    visible: true,
                    dropdownData: allRetailersList,
                    onDropdownChange: onRetailersChange,
                    type: "multiSelect",

                    multidropdownOptions: {
                        useDoneButton: true,
                        useSelectAll: true,
                        selectAllText: "Select all retailers",
                        selectAllPlaceholderFunction: arrayItems => {
                            if (!arrayItems?.length) return "No retailers";

                            const nonLockedRetailers = allRetailersList?.filter(item => !item?.locked);
                            const areAllSelected = arrayItems?.length === nonLockedRetailers?.length;
                            if (areAllSelected) return `All retailers (${nonLockedRetailers?.length})`;

                            let firstTwoItems = arrayItems
                                ?.slice(0, 2)
                                ?.map(item => item?.name)
                                ?.join(", ");
                            if (arrayItems?.length > 2) {
                                firstTwoItems = firstTwoItems + ", and more";
                            }

                            return `Retailers (${arrayItems?.length}): ${firstTwoItems}`;
                        }
                    }
                }}
                pageFilters={{
                    useFilters: true,
                    filters: {
                        useLabelBrands: true,
                        useConnectedRetailBrands: true
                    },
                    onFiltersLoaded: onFiltersLoaded
                }}
            />
        )
    });
}
// #endregion FUNCTIONS
