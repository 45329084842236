import React, { useContext } from "react";
import AppContext from "../../../AppContext";
import ProductCardSidePanelUI from "./ProductCardSidePanelUI";

/**
 * Renders a side panel for a BOTag card that is already linked to a product.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.BOTagNumber - The BO tag number.
 * @returns {JSX.Element} The rendered side panel component.
 */
export default function SidePanelLinkedCard(props) {
    /* #region VARS */
    const { BOTagNumber, BOTag } = props;
    const { BOTagsList, setBOTagsList } = useContext(AppContext);
    /* #endregion */

    /* #region METHODS */
    /*const handleColorChange = color => {
        const newBOTagsList = BOTagsList.map(tag => {
            if (tag.number === BOTagNumber) {
                tag.data.color = color;
                return tag;
            } else return tag;
        });
        setBOTagsList(newBOTagsList);
    };

    const handleSizeChange = size => {
        const newBOTagsList = BOTagsList.map(tag => {
            if (tag.number === BOTagNumber) {
                tag.data.size = size;
                return tag;
            } else return tag;
        });
        setBOTagsList(newBOTagsList);
    };*/

    const handleTagUnlink = () => {
        const number = BOTagNumber;
        if (!number) return;

        // Set the tag as not completed
        const newTags = BOTagsList.map(tag => {
            if (tag.number === number) {
                tag.data = { color: "", size: "", productObjID: "" };
                tag.completed = false;
                tag.selected = false;
                return tag;
            } else return tag;
        });

        setBOTagsList(newTags);
    };
    /* #endregion */

    const key = BOTag?.data?.productObjID + BOTag?.number;
    return (
        <div key={key} className="w-100">
            <ProductCardSidePanelUI
                image={BOTag?.image}
                category={BOTag?.category}
                number={BOTag?.number}
                productName={BOTag?.productName ?? "Product Info loading..."}
                colors={BOTag?.colors}
                colorNames={BOTag?.colorNames}
                selectedColor={BOTag?.data?.color}
                sizes={BOTag?.sizes}
                selectedSize={BOTag?.data?.size}
                buttonText="Unlink"
                onButtonClick={handleTagUnlink}
                buttonEnabled={true}
                isCompletedCard={true}
                //onColorChange={handleColorChange}
                //onSizeChange={handleSizeChange}
            />
        </div>
    );
}
