import React, { useEffect, useState } from "react";
import InfinityGridFFCsCards from "../../common/cardContainers/InfinityGridFFCsCards";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import { useNavigate, useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { AppQueryClient } from "../../../api/queryClient";
import { defaultStatusColors } from "../../../utils/CardUtils";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import MainWindowHeader from "../../common/mainApp/mainWindowHeaders/MainWindowHeader";

// #region SCOPED CSS
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
// #endregion

export default function FullfillmentCentersPage() {
    document.title = `${APP_TITLE} - Fullfillment Centers`;

    // #region VARS
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigate = useNavigate();
    const navigations = useSelector(state => state.navigationAccesses);

    const [listNumRows, setListNumRows] = useState(20);
    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");

    //---------- Header Filters
    const [allConnectedRetailersList, setAllConnectedRetailersList] = useState([]);
    const [allRetailersList, setAllRetailersList] = useState([]);
    const [filteredRetailers, setFilteredRetailers] = useState([]);
    //-----
    const [allLabelBrandsList, setAllLabelBrandsList] = useState([]);
    const [filteredLabelBrand, setFilteredLabelBrand] = useState([]);
    //-----
    const [requestEnabled, setRequestsEnabled] = useState(false);

    const filters = {
        filterLabelBrandIDs: filteredLabelBrand?.map(item => item?.value),
        filterRetailBrandIDs: filteredRetailers?.map(item => item?.value)
    };

    const infiniteGridQuerykey = ["FFCGrid", filters];
    const listQueryKey = ["FFCList", filters];

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    // #endregion

    // #region EFFECTS
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);

        setupHeaders(
            props,
            onClickRefreshAction,
            navigations,
            allRetailersList,
            allLabelBrandsList,
            onLabelBrandChange,
            onRetailersChange,
            onFiltersLoaded
        );
    }, [
        props?.layoutType,
        navigations,
        allRetailersList,
        allLabelBrandsList,
        filteredLabelBrand,
        filteredRetailers,
        requestEnabled
    ]);

    useEffect(() => {
        const interval = setInterval(() => {
            onClickRefreshAction();
        }, 180000);

        return () => clearInterval(interval);
    }, []);
    // #endregion

    // #region METHODS
    const onLabelBrandChange = selection => {
        setFilteredLabelBrand([selection]);

        const currentLabelBrandID = selection?.value;
        filterConnectedRetailers(allConnectedRetailersList, currentLabelBrandID);
    };

    const onRetailersChange = (selections, dropdownDataWithSelections) => {
        setFilteredRetailers(selections);
        setAllRetailersList(dropdownDataWithSelections);
    };

    const onFiltersLoaded = data => {
        let currentLabelBrandID = null;
        if (data?.labelBrands?.length) {
            const labelBrands = data?.labelBrands?.map((item, index) => {
                const hasRetailers = data?.connectedRetailBrands?.some(brand => brand?.labelBrandID === item?._id);

                return {
                    name: item?.name,
                    value: item?._id,
                    selected: index === 0,
                    hasRetailers: hasRetailers
                };
            });

            //filter out label brands that have no retailers
            //labelBrands = labelBrands?.filter(item => item?.hasRetailers);

            setAllLabelBrandsList(labelBrands);
            setFilteredLabelBrand([labelBrands[0]]);
            currentLabelBrandID = labelBrands[0]?.value;
        }

        if (data?.connectedRetailBrands?.length) {
            const connectedRetailBrands = data?.connectedRetailBrands?.map(item => {
                return {
                    name: item?.name,
                    value: item?._id,
                    selected: true,
                    labelBrandID: item?.labelBrandID
                };
            });

            setAllConnectedRetailersList(connectedRetailBrands);
            setFilteredRetailers(connectedRetailBrands?.filter(item => item?.selected));

            filterConnectedRetailers(connectedRetailBrands, currentLabelBrandID);
        }

        setRequestsEnabled(true);
    };

    const filterConnectedRetailers = async (allRetailers, currentLabelBrandID) => {
        let res = allRetailers?.filter(item => {
            return item?.labelBrandID === currentLabelBrandID;
        });

        // filter out any duplicated retailers
        res = res?.filter((item, index) => {
            return res?.findIndex(item2 => item2?.value === item?.value) === index;
        });

        setAllRetailersList(res);
        setFilteredRetailers(res);
    };

    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };
    // #endregion

    // #region LIST COMPONENT PROPS
    const listColumns = [
        {
            name: "Store Name",
            selector: row => row?.storeName ?? "--"
        },
        {
            name: "Retail Brand",
            selector: row => row?.retailBrand ?? "--"
        },
        {
            name: "Affiliated to",
            selector: row => row?.affiliatedTo ?? "--"
        },
        {
            name: "Accepted on",
            selector: row => formatDefaultDateTime(row?.acceptedOn)
        }
    ];
    // #endregion

    // #region RENDER
    return (
        <StyledPage>
            {viewLayoutStyle === "Grid" && (
                <InfinityGridFFCsCards
                    minColumnWidth="238px"
                    limit={12}
                    queryKey={infiniteGridQuerykey}
                    statusFromPath="status"
                    requestEnabled={requestEnabled}
                    filters={filters}
                />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="Fulfillment Centers"
                    columns={listColumns}
                    pageLimit={listNumRows}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        includeActions: true,
                        editIsVisible: false,
                        suspendIsVisible: false,
                        removeIsVisible: false,
                        extraActions: [
                            {
                                title: "Details"
                            },
                            {
                                title: "Stock",
                                onClick: row => navigate(`/stock?storeID=${row?.storeID}&storeName=${row?.storeName}`),
                                isDisabled: () => false
                            }
                        ]
                    }}
                    includeStatus={{
                        width: "100px",
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    api={{
                        endpoint: "getFulfillmentCentersData",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            limit: listNumRows,
                            ...filters
                        }
                    }}
                    requestEnabled={requestEnabled}
                    onChangeRowsPerPage={rowsPerPage => setListNumRows(rowsPerPage)}
                />
            )}
        </StyledPage>
    );
    // #endregion
}

// #region FUNCTIONS
function setupHeaders(
    props,
    onClickRefreshAction,
    navigations,
    allRetailersList,
    allLabelBrandsList,
    onLabelBrandChange,
    onRetailersChange,
    onFiltersLoaded
) {
    const createMode = navigations?.navigationAccesses?.fulfillmentCenters?.createModeOnly;

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "FFCenters", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/ffcenters?layoutType=list", active: true, disabled: createMode, title: "FFCENTERS" },
        { to: "/ffcenters/management", active: false, disabled: false, title: "MANAGEMENT" }
        /*{ to: "/stock", active: false, disabled: false, title: "STOCK" }*/
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: createMode, title: "View", icon: "eye" },
        { to: "/retailers/invite", active: false, disabled: createMode, title: "Invite", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <MainWindowHeader
                key={window.location.pathname}
                visible={true}
                layoutTypeOptions={{
                    gridEnabled: true,
                    listEnabled: true
                }}
                onRefreshClick={onClickRefreshAction}
                firstOrganize={{
                    visible: true,
                    dropdownData: allLabelBrandsList,
                    onDropdownChange: onLabelBrandChange,
                    disabled: false,
                    type: "singleSelect"
                }}
                secondOrganize={{
                    visible: true,
                    dropdownData: allRetailersList,
                    onDropdownChange: onRetailersChange,
                    type: "multiSelect",

                    multidropdownOptions: {
                        useDoneButton: true,
                        useSelectAll: true,
                        selectAllText: "Select all retailers",
                        selectAllPlaceholderFunction: arrayItems => {
                            if (!arrayItems?.length) return "No retailers";

                            const nonLockedRetailers = allRetailersList?.filter(item => !item?.locked);
                            const areAllSelected = arrayItems?.length === nonLockedRetailers?.length;
                            if (areAllSelected) return `All retailers (${nonLockedRetailers?.length})`;

                            let firstTwoItems = arrayItems
                                ?.slice(0, 2)
                                ?.map(item => item?.name)
                                ?.join(", ");
                            if (arrayItems?.length > 2) {
                                firstTwoItems = firstTwoItems + ", and more";
                            }

                            return `Retailers (${arrayItems?.length}): ${firstTwoItems}`;
                        }
                    }
                }}
                pageFilters={{
                    useFilters: true,
                    filters: {
                        useLabelBrands: true,
                        useConnectedRetailBrands: true
                    },
                    onFiltersLoaded: onFiltersLoaded
                }}
            />
        )
    });
}
// #endregion
