import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router";
import * as Library from "../../../utils/Library";
import { useDispatch, useSelector } from "react-redux";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import LayoutContext from "../../../LayoutContext";
import { setNavigations } from "../../../utils/redux/navigationAccessSlice";
import FormCard from "../../common/forms/FormCard";
import FormField from "../../common/forms/FormField";
import SimpleButtonContentHeader from "../companies/createCompanyComponents/SimpleButtonContentHeader";
import UploadLogosCardStandard from "../../common/forms/UploadLogosCardStandard";
import AddressFormCardStandard from "../../common/forms/AddressFormCardStandard";
import { userIsOnBoardingInPage } from "../../../utils/navigationHelper";
import AppContext from "../../../AppContext";
import FormFieldTagsDropdown from "../../common/forms/FormFieldTagsDropdown";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    .input-remove-default {
        border: none;
        outline: none;
        background: none;
    }

    .selects {
        background: var(--backgrounds-and-lines-n-600, #10141b);
    }

    .selects.mandatory {
        border: 1px solid transparent;
        border-left: 5px solid var(--text-icons-orange-500);
        border-radius: 4px;
        outline: none;
    }

    .selects.mandatory > div {
        border: none;
        cursor: pointer;
    }

    .selects.mandatory.error {
        border: 1px solid var(--text-icons-red-500);
        border-left: 5px solid var(--text-icons-red-500);
    }

    .main-window-header {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        display: flex;
        flex-direction: row;
        row-gap: 0px;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        height: 68px;
        position: relative;
    }
    .frame-40 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .btn-large-solid {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 120px;
        height: 35px;
        position: relative;
    }
    .button {
        color: var(--buttons-all-platforms-gray-200, #4f5b6d);
        text-align: left;
        font-family: var(--button-text-small-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 10px);
        letter-spacing: var(--button-text-small-letter-spacing, 0.0125em);
        font-weight: var(--button-text-small-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
`;

const CardTitleButtonStyled = styled.div`
    &.btn-sm-inverted-card-header,
    &.btn-sm-inverted-card-header * {
        box-sizing: border-box;
    }
    &.btn-sm-inverted-card-header {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 16px;
        position: relative;
        cursor: pointer;
    }
    .button {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 8px;
        letter-spacing: 0.0125em;
        font-weight: 400;
        text-transform: uppercase;
        position: relative;
    }
`;
/* #endregion SCOPED CSS */

export default function CreateBrandPage() {
    const location = useLocation();
    const isCreatePage = location.pathname.includes("/create" || "/new" || "/add");
    document.title = `${APP_TITLE} - Brands ${isCreatePage ? "Create" : "Edit"}`;

    /* #region VARS */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const { showIsCompanySavedPopup, setShowIsCompanySavedPopup, setShowIsBrandSavedPopup } = useContext(LayoutContext);
    const { setHasUnsavedChanges } = useContext(AppContext);
    const dispatch = useDispatch();
    const navigations = useSelector(state => state.navigationAccesses);
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData,
        navigations: navigations
    };

    const { brandID } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingLogoData, setIsFetchingLogoData] = useState(false);
    const [isFetchingAddressData, setIsFetchingAddressData] = useState(false);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);

    const [brandFormValues, setBrandFormValues] = useState({
        brand: "",
        industry: "",
        toCompany: "",
        lifestyle: "",
        productCategory: "",
        website: "",
        instagram: "",
        facebook: "",
        mainLogo: null,
        compactLogo: null,
        mainLogoWhite: null,
        mainLogoBlack: null,
        compactLogoWhite: null,
        compactLogoBlack: null,
        googlePlusCode: "",
        brandCountry: "",
        brandCity: "",
        brandPostalCode: "",
        brandAddressOne: "",
        brandAddressTwo: "",
        brandLatitude: "",
        brandLongitude: "",
        genderTags: [],
        ageGroupTags: [],
        // lifestyleTags: [], comment because can be used in future
        // majorMarkets: [],
        hashtags: ""
    });

    const [logoFieldsState, setLogoFieldsState] = useState();
    const isUserOnboarding = userIsOnBoardingInPage(navigations, "labelBrands");
    const navigate = useNavigate();
    const formBrandID = useRef();
    const formBrandFieldsRefs = [];
    const brandAccountLogosRef = useRef();
    const formTags = useRef();
    const formTagsRef = [];
    const formBrandAddress = useRef();

    const [industryOptions, setIndustryOptions] = useState([]);
    const [designlifeOption, setDesignlifeOption] = useState([]);
    const [mainProductOptions, setMainProductOptions] = useState([]);
    const [filteredDesignlifeOption, setFilteredDesignlifeOption] = useState([]);
    const [hashtags, setHashtags] = useState([]);
    const [dropdownData, setDropDownData] = useState({});

    const toCompanyOptions = useMemo(
        () => [
            {
                name: localStorage.getItem("currentCompanyName"),
                value: localStorage.getItem("currentOrganizationID"),
                selected: isUserOnboarding ? true : false
            }
        ],
        []
    );
    const dropDownData = useMemo(
        () => ({
            industryOptions,
            designlifeOption,
            mainProductOptions,
            toCompanyOptions
        }),
        [industryOptions, designlifeOption, mainProductOptions, toCompanyOptions]
    );

    const [readyToSave, setShowReadyToSave] = useState(false);
    const [showConfirmNewLabelBrand, setShowConfirmNewLabelBrand] = useState(false);
    const labelBrandData = JSON.parse(localStorage.getItem("labelBrands"))?.[0];
    const [labelBrandDetails, setLabelBrandDetails] = useState({
        labelBrandName: isUserOnboarding ? labelBrandData?.name || "" : "",
        labelBrandWebsite: isUserOnboarding ? labelBrandData?.socialMedia?.website || "" : "",
        labelBrandInstagram: isUserOnboarding ? labelBrandData?.socialMedia?.instagram || "" : ""
    });

    const formFieldBrands = [
        <FormField
            type="text"
            title="Brand"
            tooltip="Brand"
            requiredType={isUserOnboarding ? "mandatoryLocked" : "mandatory"}
            assistiveText=""
            varName="brand"
            ref={el => (formBrandFieldsRefs.brand = el)}
            value={labelBrandDetails?.labelBrandName}
            onTextBlur={value => addHashtags(value)}
        />,
        <FormField
            type="dropdown"
            dropdownData={dropDownData?.toCompanyOptions || []}
            title="To Company"
            tooltip="To Company"
            requiredType={isUserOnboarding ? "mandatoryLocked" : "mandatory"}
            assistiveText=""
            varName="toCompany"
            ref={el => (formBrandFieldsRefs.toCompany = el)}
            value={brandFormValues?.toCompany}
        />,
        <FormField
            type="dropdown"
            dropdownData={dropDownData?.industryOptions || []}
            title="Industry"
            tooltip="Industry"
            requiredType="mandatory"
            varName="industry"
            ref={el => (formBrandFieldsRefs.industry = el)}
            onTextChange={value => {
                filterDesignlifeOptions(value);
                const removeOldTag = formBrandID?.current?.getAllFieldValues()?.industry?.name;
                addHashtags(value?.name, removeOldTag);
            }}
            value={brandFormValues?.industry}
        />,
        <FormField
            type="dropdown"
            dropdownData={filteredDesignlifeOption || []}
            title="Design/Lifestyle"
            tooltip="Design/Lifestyle"
            requiredType="mandatory"
            varName="lifestyle"
            ref={el => (formBrandFieldsRefs.lifestyle = el)}
            onTextChange={value => {
                const removeOldTag = formBrandID?.current?.getAllFieldValues()?.lifestyle?.name;
                addHashtags(value?.name, removeOldTag);
            }}
            value={brandFormValues?.lifestyle}
        />,
        <FormField
            type="dropdown"
            dropdownData={dropDownData?.mainProductOptions || []}
            title="Main product category"
            tooltip="Main product category"
            requiredType="mandatory"
            varName="productCategory"
            ref={el => (formBrandFieldsRefs.productCategory = el)}
            onTextChange={value => {
                const removeOldTag = formBrandID?.current?.getAllFieldValues()?.productCategory?.name;
                addHashtags(value?.name, removeOldTag);
            }}
            value={brandFormValues?.productCategory}
        />,

        <FormField
            type="text"
            title="Website"
            tooltip="Website"
            assistiveText=""
            varName="website"
            requiredType={isUserOnboarding ? "locked" : "simple"}
            textPrefix={!isUserOnboarding ? "https://" : ""}
            ref={el => (formBrandFieldsRefs.website = el)}
            value={labelBrandDetails?.labelBrandWebsite}
        />,
        <FormField
            type="text"
            title="Instagram"
            tooltip="Instagram"
            assistiveText=""
            requiredType={isUserOnboarding ? "locked" : "simple"}
            varName="instagram"
            textPrefix={!isUserOnboarding ? "https://www.instagram.com/" : ""}
            ref={el => (formBrandFieldsRefs.instagram = el)}
            value={labelBrandDetails?.labelBrandInstagram}
        />,
        <FormField
            type="text"
            title="Facebook"
            tooltip="Facebook"
            assistiveText=""
            varName="facebook"
            textPrefix="https://www.facebook.com/"
            ref={el => (formBrandFieldsRefs.facebook = el)}
            value={brandFormValues?.facebook}
        />
    ];

    const formTagFields = [
        <FormFieldTagsDropdown
            title="Genders"
            tooltip="Genders"
            requiredType="mandatory"
            formFieldColumSize={1}
            autoCompleteStaticData={dropdownData.genders || []}
            varName="Genders"
            ref={el => (formTagsRef.Genders = el)}
            allowNewTags={true}
            noSearchResultsHideMessage={true}
            value={brandFormValues?.genderTags}
        />,
        <FormFieldTagsDropdown
            type="tags"
            title="Age groups"
            tooltip="Age groups"
            requiredType="mandatory"
            formFieldColumSize={1}
            autoCompleteStaticData={dropdownData.ageGroups || []}
            varName="Agegroups"
            ref={el => (formTagsRef.Agegroups = el)}
            allowNewTags={true}
            noSearchResultsHideMessage={true}
            value={brandFormValues?.ageGroupTags}
        />,
        <FormFieldTagsDropdown
            type="tags"
            title="Major markets"
            tooltip="Major markets"
            requiredType="mandatory"
            formFieldColumSize={1}
            autoCompleteStaticData={dropdownData.markets || []}
            varName="MajorMarkets"
            ref={el => (formTagsRef.MajorMarkets = el)}
            allowNewTags={true}
            noSearchResultsHideMessage={true}
            value={brandFormValues?.majorMarkets}
        />,
        <FormField
            type="tags"
            title="Hashtags"
            formFieldColumSize={1}
            tooltip="Hashtags"
            placeholder="Press enter to add hashtags"
            placeholderWidth="185px"
            requiredType="recommended"
            value={hashtags}
            varName="Hashtags"
            tagType="hashtags"
            ref={el => (formTagsRef.Hashtags = el)}
            onTagsChanged={tags => {
                const tagNames = tags?.map(item => item?.tagName);
                setHashtags(tagNames);
            }}
        />
    ];
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        getLabelBrandFormFieldsRequest();

        getAllTargetsDataRequest();

        generateLogoFields(null, null, null, null, null, null);
        setLabelBrandDetails({
            labelBrandName: isUserOnboarding ? labelBrandData?.name || "" : "",
            labelBrandWebsite: isUserOnboarding ? labelBrandData?.socialMedia?.website || "" : "",
            labelBrandInstagram: isUserOnboarding ? labelBrandData?.socialMedia?.instagram || "" : ""
        });

        if (brandID) {
            getBrandData(brandID);
        }
    }, []);

    useEffect(() => {
        const saveIsEnabled = !saveBtnDisabled && !isLoading;

        setupHeaders(props, saveIsEnabled, handleReadyToShowBox, isCreatePage, isUserOnboarding);
    }, [props?.layoutType, saveBtnDisabled, isLoading]);

    useEffect(() => {
        checkFormIsValid(false);
    }, [brandFormValues]);

    useEffect(() => {
        if (brandFormValues?.industry) {
            const industryItem = industryOptions.find(item => item.name === brandFormValues?.industry);
            if (!industryItem) return;

            filterDesignlifeOptions(industryItem);
        }
    }, [brandFormValues?.industry, industryOptions]);
    /* #endregion */

    /* #region METHODS */
    const handleReadyToShowBox = () => {
        isUserOnboarding || !isCreatePage ? setShowReadyToSave(true) : setShowConfirmNewLabelBrand(true);
    };

    const filterDesignlifeOptions = value => {
        if (value && value.designAndLifestylesIDs) {
            setFilteredDesignlifeOption(
                designlifeOption.filter(item => value.designAndLifestylesIDs.includes(item.value))
            );
        } else {
            setFilteredDesignlifeOption(designlifeOption);
        }
    };

    const checkFormIsValid = (highlightValidation = false) => {
        const allBrandIdFieldsAreValid = formBrandID?.current?.allFieldsAreValid(highlightValidation);
        const allBrandLogosAreValid = brandAccountLogosRef?.current?.allFieldsAreValid(highlightValidation);
        const allAddressFieldsAreValid = formBrandAddress?.current?.allFieldsAreValid(highlightValidation);
        const allTagsFieldsAreValid = formTags?.current?.allFieldsAreValid(highlightValidation);

        const allFieldsAreValid =
            allBrandIdFieldsAreValid && allTagsFieldsAreValid && allAddressFieldsAreValid && allBrandLogosAreValid;

        setSaveBtnDisabled(!allFieldsAreValid);
        return allFieldsAreValid;
    };

    const onSaveClicked = async () => {
        const formBrandIDformValues = formBrandID?.current?.getAllFieldValues();
        const formTagValues = formTags?.current?.getAllFieldValues();
        const brandAccountLogoValues = brandAccountLogosRef?.current?.getAllFieldValues();
        const formBrandAddressValues = formBrandAddress?.current?.getAllFieldValues();
        const extractTagNames = arr => arr.map(item => item.tagName);

        //TODO: Eventually we should have and use the currentLabelBrandID in the localStorage
        const labelBrandID = isUserOnboarding
            ? JSON.parse(localStorage.getItem("labelBrands"))[0]?._id || ""
            : brandID || "";

        const jsonPostData = {
            labelBrandID: labelBrandID || "",
            name: formBrandIDformValues?.brand || "",
            industry: formBrandIDformValues?.industry?.name || "",
            designAndLifestyle: formBrandIDformValues?.lifestyle?.name || "",
            mainProductCategory: formBrandIDformValues?.productCategory?.name || "",
            location: {
                latitude: formBrandAddressValues?.latitude || "",
                longitude: formBrandAddressValues?.longitude || "",
                googlePlusCode: formBrandAddressValues?.googlePlusCode || "",
                countryValue: formBrandAddressValues?.country?.name || "",
                city: formBrandAddressValues?.city?.name || "",
                postalCode: formBrandAddressValues?.postalCode || "",
                addressLine1: formBrandAddressValues?.addressOne || "",
                addressLine2: formBrandAddressValues?.addressTwo || "",
                addressNumber: ""
            },
            socialMedia: {
                website: formBrandIDformValues?.website || "",
                facebook: formBrandIDformValues?.facebook || "",
                instagram: formBrandIDformValues?.instagram || ""
            },
            logos: {
                mainLogoData: brandAccountLogoValues?.mainLogoData || "",
                mainLogoWhiteData: brandAccountLogoValues?.mainLogoInWhite || "",
                mainLogoBlackData: brandAccountLogoValues?.mainLogoInBlack || "",
                alternativeLogoData: brandAccountLogoValues?.compactLogoData || "",
                alternativeLogoWhiteData: brandAccountLogoValues?.compactLogoInWhite || "",
                alternativeLogoBlackData: brandAccountLogoValues?.compactLogoInBlack || ""
            },
            /* images: {
                BOtagHomeData: brandAccountLogoValues?.mainLogoData || ""
            },*/
            targets: {
                genders: extractTagNames(formTagValues?.Genders) || [],
                /*lifestyles: [],*/ //extractTagNames(formTagValues?.LifestyleSocial) || [],
                ageGroups: extractTagNames(formTagValues?.Agegroups) || [],
                majorMarkets: extractTagNames(formTagValues?.MajorMarkets) || [],
                hashTags: extractTagNames(formTagValues?.Hashtags) || []
            }
        };

        setHasUnsavedChanges(false);

        if (isUserOnboarding) updateOnboardingLabelBrand(jsonPostData);
        else if (isCreatePage) createNewLabelBrand(jsonPostData);
        else updateLabelBrand(jsonPostData);
    };

    const updateOnboardingLabelBrand = async jsonPostData => {
        const res = await Library.makePostRequest("updateLabelBrand", jsonPostData, false, setIsLoading);

        if (res?.data?.status === 200) {
            const updatedNavigations = {
                ...navigations.navigationAccesses,
                labelBrands: {
                    ...navigations.navigationAccesses.companies,
                    createModeOnly: false
                },
                BOimages: {
                    ...navigations.navigationAccesses.labelBrands,
                    createModeOnly: true,
                    navigationPanel: true
                }
            };
            dispatch(setNavigations({ navigationAccesses: updatedNavigations }));

            const brandLogos = res.data?.data?.logos;
            const brandDataWithLogos = {
                ...labelBrandData,
                logos: brandLogos
            };

            localStorage.setItem("labelBrands", JSON.stringify([brandDataWithLogos]));

            if (navigations?.navigationAccesses?.organization?.status === "Active") navigate("/dashboard");
            else navigate("/boimages/create");
            localStorage.setItem("activeBrands", 1);
            setShowIsBrandSavedPopup(true);
        } else setHasUnsavedChanges(true);
    };

    const createNewLabelBrand = async jsonPostData => {
        const formData = { ...jsonPostData, companyID: localStorage.getItem("currentCompanyID") };
        const res = await Library.makePostRequest("createLabelBrand", formData, false, setIsLoading);

        if (res?.data?.status === 200) {
            const currentLabelBrands = JSON.parse(localStorage.getItem("labelBrands")) ?? [];
            currentLabelBrands.push(res?.data?.data);
            localStorage.setItem("labelBrands", JSON.stringify(currentLabelBrands));

            navigate("/brands");
            localStorage.setItem("activeBrands", parseInt(localStorage.getItem("activeBrands")) + 1);

            Library.showToastMessage({
                type: "success",
                title: `Label brand ${jsonPostData.name} added successfully`
            });
        } else setHasUnsavedChanges(true);
    };

    const updateLabelBrand = async jsonPostData => {
        const res = await Library.makePostRequest("updateLabelBrand", jsonPostData, false, setIsLoading);

        if (res?.data?.status === 200) {
            navigate("/brands");

            const currentLabelBrands = JSON.parse(localStorage.getItem("labelBrands"));
            const updatedLabelBrands = currentLabelBrands.map(item =>
                item._id === jsonPostData.labelBrandID ? { ...item, ...jsonPostData } : item
            );

            localStorage.setItem("labelBrands", JSON.stringify(updatedLabelBrands));

            Library.showToastMessage({
                type: "success",
                title: `Label brand ${jsonPostData.name} updated successfully`
            });
        } else setHasUnsavedChanges(true);
    };

    const generateLogoFields = (
        largeLogoImageData,
        compactLogoData,
        mainLogoInWhiteData,
        mainLogoInBlackData,
        compactLogoInWhiteData,
        compactLogoInBlackData
    ) => {
        const logosState = [
            {
                label: "Main logo",
                varName: "mainLogoData",
                requiredType: "mandatory",
                imageData: largeLogoImageData,
                gridColumnSize: 2
            },
            {
                label: "Compact logo",
                varName: "compactLogoData",
                requiredType: "recommended",
                imageData: compactLogoData,
                gridColumnSize: 2
            },
            {
                label: "Main logo in white",
                varName: "mainLogoInWhite",
                requiredType: "recommended",
                imageData: mainLogoInWhiteData,
                gridColumnSize: 1
            },
            {
                label: "Main logo in black",
                varName: "mainLogoInBlack",
                requiredType: "recommended",
                imageData: mainLogoInBlackData,
                gridColumnSize: 1
            },
            {
                label: "Compact logo in white",
                varName: "compactLogoInWhite",
                requiredType: "recommended",
                imageData: compactLogoInWhiteData,
                gridColumnSize: 1
            },
            {
                label: "Compact logo in black",
                varName: "compactLogoInBlack",
                requiredType: "recommended",
                imageData: compactLogoInBlackData,
                gridColumnSize: 1
            }
        ];

        setLogoFieldsState(logosState);
    };

    const addHashtags = (value, removeOldTag) => {
        try {
            if (!value) return;
            const newHashtag = `#${value.replace(/\s/g, "-")}`;

            const hashtagsTmp = [...hashtags];
            if (removeOldTag) {
                // remove old tag from hashtagsTMP
                const oldHashtag = `#${removeOldTag.replace(/\s/g, "-")}`;
                hashtagsTmp.splice(hashtagsTmp.indexOf(oldHashtag), 1);
            }

            setHashtags([...hashtagsTmp, newHashtag]);
        } catch (error) {
            console.log(value, error);
        }
    };

    // eslint-disable-next-line no-unused-vars
    const removeHashtags = value => {
        if (!value) return;

        const newHashtagsArray = hashtags.filter(item => item !== value);
        setHashtags(newHashtagsArray);
    };

    // eslint-disable-next-line no-unused-vars
    const removeNotFormattedHashtags = value => {
        if (!value) return;

        const formattedValue = `#${value?.trim()?.replace(/\s/g, "-")}`;
        const newHashtagsArray = hashtags.filter(item => item !== formattedValue);
        setHashtags(newHashtagsArray);
    };

    const getLabelBrandFormFieldsRequest = async () => {
        try {
            const response = await Library.makePostRequest("getLabelBrandFormFields");
            const { designAndLifestyles, industries, productCategories } = response.data.data;

            setIndustryOptions(
                industries.map(item => {
                    return {
                        value: item._id,
                        name: item.name,
                        designAndLifestylesIDs: item.designAndLifestylesIDs
                    };
                })
            );

            setDesignlifeOption(
                designAndLifestyles.map(item => {
                    return { value: item._id, name: item.name };
                })
            );

            setMainProductOptions(
                productCategories.map(item => {
                    return { value: item._id, name: item.name };
                })
            );
        } catch (error) {
            console.log(error);
        }
    };

    const getAllTargetsDataRequest = async () => {
        try {
            const res = await Library.makeCommonPostRequest("Company", "getAllTargetsData");
            const data = res.data.data;
            // console.log(data);

            const transformedData = Object.entries(data).reduce((acc, [key, value]) => {
                acc[key] = value.map(item => ({
                    value: item._id,
                    name: item.name
                }));
                return acc;
            }, {});

            // console.log("transformedData", transformedData);
            setDropDownData(transformedData);
        } catch (error) {
            console.error("Error getting data", error);
        }
    };

    const getCompanyData = async type => {
        const res = await Library.makeCommonPostRequest(
            "Company",
            "getCompany",
            { companyID: localStorage.getItem("currentCompanyID") },
            false,
            type === "logos" ? setIsFetchingLogoData : setIsFetchingAddressData
        );

        if (res?.data?.status !== 200) return;

        const companyData = res?.data?.data;
        return companyData;
    };

    const logosSameAsCompany = async () => {
        const companyData = await getCompanyData("logos");
        if (!companyData) return;

        const {
            mainLogoURL,
            compactLogoURL,
            mainLogoWhiteURL,
            mainLogoBlackURL,
            compactLogoWhiteURL,
            compactLogoBlackURL
        } = companyData;

        generateLogoFields(
            mainLogoURL ? { url: mainLogoURL, name: "mainLogo" } : null,
            compactLogoURL ? { url: compactLogoURL, name: "compactLogo" } : null,
            mainLogoWhiteURL ? { url: mainLogoWhiteURL, name: "mainLogoWhite" } : null,
            mainLogoBlackURL ? { url: mainLogoBlackURL, name: "mainLogoBlack" } : null,
            compactLogoWhiteURL ? { url: compactLogoWhiteURL, name: "compactLogoWhite" } : null,
            compactLogoBlackURL ? { url: compactLogoBlackURL, name: "compactLogoBlack" } : null
        );
    };

    const addressSameAsCompany = async () => {
        const companyData = await getCompanyData("address");
        if (!companyData) return;

        const { addressLine1, addressLine2, city, countryValue, googlePlusCode, latitude, longitude, postalCode } =
            companyData?.location;

        setBrandFormValues({
            ...brandFormValues,
            googlePlusCode,
            brandCountry: countryValue,
            brandCity: city,
            brandPostalCode: postalCode,
            brandAddressOne: addressLine1,
            brandAddressTwo: addressLine2,
            brandLatitude: latitude,
            brandLongitude: longitude
        });
    };

    const getBrandData = async labelBrandID => {
        const res = await Library.makePostRequest("getLabelBrand", { labelBrandID }, false, setIsLoading);

        if (res?.data?.status !== 200) return;
        const data = res?.data?.data;

        const {
            name,
            companyName,
            industry,
            designAndLifestyle,
            mainProductCategory,
            socialMedia,
            logos,
            location,
            targets
        } = data;

        const { website, facebook, instagram } = socialMedia;

        const {
            mainLogoURL,
            alternativeLogoURL,
            mainLogoWhiteURL,
            mainLogoBlackURL,
            alternativeLogoWhiteURL,
            alternativeLogoBlackURL
        } = logos;

        const { latitude, longitude, googlePlusCode, countryValue, city, postalCode, addressLine1, addressLine2 } =
            location;

        setBrandFormValues({
            brand: name,
            industry: industry,
            toCompany: companyName,
            lifestyle: designAndLifestyle,
            productCategory: mainProductCategory,
            website,
            instagram,
            facebook,
            mainLogo: mainLogoURL ? { url: mainLogoURL, name: "mainLogo" } : null,
            compactLogo: alternativeLogoURL ? { url: alternativeLogoURL, name: "compactLogo" } : null,
            mainLogoWhite: mainLogoWhiteURL ? { url: mainLogoWhiteURL, name: "mainLogoWhite" } : null,
            mainLogoBlack: mainLogoBlackURL ? { url: mainLogoBlackURL, name: "mainLogoBlack" } : null,
            compactLogoWhite: alternativeLogoWhiteURL
                ? { url: alternativeLogoWhiteURL, name: "compactLogoWhite" }
                : null,
            compactLogoBlack: alternativeLogoBlackURL
                ? { url: alternativeLogoBlackURL, name: "compactLogoBlack" }
                : null,
            googlePlusCode,
            brandCountry: countryValue,
            brandCity: city,
            brandPostalCode: postalCode,
            brandAddressOne: addressLine1,
            brandAddressTwo: addressLine2,
            brandLatitude: latitude,
            brandLongitude: longitude,
            genderTags: targets?.genders || [],
            ageGroupTags: targets?.ageGroups || [],
            majorMarkets: targets?.majorMarkets || []
        });

        setHashtags(targets?.hashTags || []);

        setLabelBrandDetails({
            labelBrandName: name,
            labelBrandWebsite: website,
            labelBrandInstagram: instagram
        });

        generateLogoFields(
            mainLogoURL ? { url: mainLogoURL, name: "mainLogo" } : null,
            alternativeLogoURL ? { url: alternativeLogoURL, name: "compactLogo" } : null,
            mainLogoWhiteURL ? { url: mainLogoWhiteURL, name: "mainLogoWhite" } : null,
            mainLogoBlackURL ? { url: mainLogoBlackURL, name: "mainLogoBlack" } : null,
            alternativeLogoWhiteURL ? { url: alternativeLogoWhiteURL, name: "compactLogoWhite" } : null,
            alternativeLogoBlackURL ? { url: alternativeLogoBlackURL, name: "compactLogoBlack" } : null
        );
    };

    /* #endregion METHODS */

    return (
        <StyledPage>
            <FullScreenOverlay show={showIsCompanySavedPopup}>
                <DialogBoxComponent
                    buttonWidth={"50%"}
                    onClickYes={() => setShowIsCompanySavedPopup(false)}
                    title="Company is now saved!"
                    heading="Success!"
                    headingColor="#00BB00"
                    message={
                        "<div style='font-size: 14px; margin: 0 -5px;'>You can now proceed with enrolling your brand</div>"
                    }
                    // useImageHeader={{ src: BOStageBLogo, width: "165px" }}
                    showConfirm={() => setShowIsCompanySavedPopup(false)}
                    showCancel={false}
                    confirmText="CONTINUE"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={readyToSave}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-blue-500)"
                    title="Ready to save"
                    message="You can still change it later. No worries:)"
                    onClickYes={() => {
                        setShowReadyToSave(false);
                        onSaveClicked();
                    }}
                    loading={isLoading}
                    loadingHeading={"CREATING BRAND.."}
                    onClickNo={() => {
                        setShowReadyToSave(false);
                    }}
                    cancelText="No"
                    confirmText="Yes"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showConfirmNewLabelBrand}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-blue-500)"
                    title="ENROLLING A NEW BRAND"
                    message={
                        "<div style>Do you agree to create a new Brand account? 2.000kr will be added monthly to your subscription. This month you'll be charged partially.</div>"
                    }
                    onClickYes={() => {
                        setShowConfirmNewLabelBrand(false);
                        onSaveClicked();
                    }}
                    loading={isLoading}
                    loadingHeading={"CREATING BRAND.."}
                    onClickNo={() => {
                        setShowConfirmNewLabelBrand(false);
                    }}
                    cancelText="No"
                    confirmText="Yes"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={isLoading}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-green-500)"
                    heading="SAVING..."
                    showCancel={false}
                    showConfirm={false}
                    loading={isLoading}
                />
            </FullScreenOverlay>

            <div className="flex flex-dir-column gap-16 w-100">
                <FormCard
                    title="BRAND ID"
                    ref={formBrandID}
                    numOfFieldsPerLine={4}
                    showDefaultRightLegends={true}
                    formFields={formFieldBrands}
                    formFieldRefs={formBrandFieldsRefs}
                    onFormChanged={checkFormIsValid}
                    showUnsavedChangesPopup={true}
                />

                <UploadLogosCardStandard
                    cardTitle="BRAND LOGOS"
                    key="brandLogos"
                    ref={brandAccountLogosRef}
                    logoFields={logoFieldsState}
                    onLogoFieldsChanged={() => checkFormIsValid(false)}
                    afterTitleComponent={
                        <CardTitleButton
                            title="SAME AS COMPANY"
                            onClick={logosSameAsCompany}
                            isFetchingData={isFetchingLogoData}
                        />
                    }
                />

                <AddressFormCardStandard
                    cardTitle="BRAND MAIN OFFICE"
                    ref={formBrandAddress}
                    pinLabel={labelBrandDetails?.labelBrandName}
                    unnamedPinLabel={"Unnamed Brand"}
                    useGoogleMapsDarkMode={true}
                    hasKYCAssistiveText={false}
                    onFormChanged={checkFormIsValid}
                    locationDetails={{
                        googlePlusCode: brandFormValues.googlePlusCode,
                        country: brandFormValues.brandCountry,
                        city: brandFormValues.brandCity,
                        postalCode: brandFormValues.brandPostalCode,
                        addressOne: brandFormValues.brandAddressOne,
                        addressTwo: brandFormValues.brandAddressTwo,
                        latitude: brandFormValues.brandLatitude,
                        longitude: brandFormValues.brandLongitude
                    }}
                    afterTitleComponent={
                        <CardTitleButton
                            title="SAME AS COMPANY"
                            onClick={addressSameAsCompany}
                            isFetchingData={isFetchingAddressData}
                        />
                    }
                    onLocationDetailsChange={locationDetails => {
                        const {
                            googlePlusCode,
                            country,
                            city,
                            postalCode,
                            addressOne,
                            addressTwo,
                            latitude,
                            longitude
                        } = locationDetails;

                        setBrandFormValues({
                            ...brandFormValues,
                            googlePlusCode,
                            brandCountry: country,
                            brandCity: city,
                            brandPostalCode: postalCode,
                            brandAddressOne: addressOne,
                            brandAddressTwo: addressTwo,
                            brandLatitude: latitude,
                            brandLongitude: longitude
                        });
                    }}
                />

                <FormCard
                    ref={formTags}
                    title="TARGETS & HASHTAGS"
                    numOfFieldsPerLine={2}
                    showDefaultRightLegends={true}
                    formFields={formTagFields}
                    formFieldRefs={formTagsRef}
                    onFormChanged={() => {
                        checkFormIsValid(false);
                    }}
                    showUnsavedChangesPopup={true}
                />
            </div>
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS AND METHODS */
function setupHeaders(props, saveButtonEnabled, onBtnClicked, isCreatePage, userIsOnboarding) {
    const createMode = props?.navigations?.navigationAccesses?.labelBrands?.createModeOnly;

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Brands", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/brands/create", active: true, disabled: false, title: "Brands" },
        { to: "/brands/users", active: false, disabled: true, title: "Users" }
    ]);

    if (isCreatePage || userIsOnboarding) {
        props.setControlBarRightBtns([
            { to: "/brands", active: false, disabled: createMode, title: "View", icon: "eye" },
            { to: "/brands/create", active: true, disabled: false, title: "Add", icon: "plus" },
            { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
        ]);
    } else {
        props.setControlBarRightBtns([
            { to: "/brands", active: false, disabled: false, title: "Back", icon: "chevron-left" },
            { to: "#", active: true, disabled: false, title: "Edit", icon: "pencil-line" }
        ]);
    }

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader btnTitle="SAVE" btnEnabled={saveButtonEnabled} onClick={onBtnClicked} />
        )
    });
}

export const CardTitleButton = ({ title, onClick, isFetchingData = false }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "8px"
            }}>
            <CardTitleButtonStyled className="btn-sm-inverted-card-header" onClick={onClick}>
                <div className="button">{title}</div>
            </CardTitleButtonStyled>
            {isFetchingData && <i className="fa fa-spinner fa-spin" style={{ color: "var(--text-icons-green-500)" }} />}
        </div>
    );
};
/* #endregion */
