import React from "react";

/**
 * A container component that arranges its children in a grid layout evenly spaced.
 * If a child component needs to occupy more than one grid cell, it should be use the style {gridColumn: "span 2"} for example.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child elements to be displayed within the grid.
 * @param {string} [props.type="fieldsPerLine"] - The type of grid we want. Can be "fieldsPerLine" or "minWidth". The first will always use a specific number and second will do a minimum card size.
 * @param {string} [props.cardMinWidth="300px"] - The minimum width of the card when using "minWidth" type.
 * @param {number} [props.numOfFieldsPerLine=4] - The number of fields to display per line in the grid.
 * @param {string} [props.className=""] - Additional CSS class names to apply to the container.
 * @param {Object} [props.style={}] - Additional inline styles to apply to the container.
 * @param {string} [props.gap="16px"] - The gap between the grid items.
 *
 * @returns {JSX.Element} The rendered grid container component.
 */
export default function SimpleGridContainer({
    children,
    type = "fieldsPerLine",
    numOfFieldsPerLine = 4,
    cardMinWidth = "300px",
    className = "",
    style = {},
    gap = "16px"
}) {
    return (
        <div className={className} style={{ display: "flex", gap, ...style }}>
            <div
                className="FormFieldsContainer"
                style={{
                    display: "inline-grid",
                    width: "100%",
                    gap: "16px",
                    gridTemplateColumns:
                        type === "fieldsPerLine"
                            ? `repeat(${numOfFieldsPerLine}, minmax(0, 1fr))`
                            : `repeat(auto-fill, minmax(${cardMinWidth}, 1fr))`
                }}>
                {children}
            </div>
        </div>
    );
}
