import "../../../assets/css/components/SalesByBrandAndStore.css";

const SalesByBrandAndStore = ({ type, totalValue, currency = "NOK", data, totalComparisonPercent = "--" }) => {
    return (
        <div className="sales-by-brand-and-store-container">
            <div className="header">
                <p>
                    SALES BY {type} &nbsp; <i className="fa-light fa-circle-info"></i>
                </p>
                <p>SEE MORE...</p>
            </div>
            <div className="content">
                <p>
                    {totalValue || 0} <span style={{ fontSize: "18px" }}>{currency}</span>
                </p>
                <p>{totalComparisonPercent} % compared to set period</p>
            </div>
            <div className="hr-container">
                <div></div>
            </div>
            <div className="main-data">
                {data?.map((item, index) => {
                    return (
                        <div className="main-data-single-item" key={index}>
                            <div className="image-portion-container">
                                <div>
                                    <div className="image-holder" style={{ overflow: "hidden" }}>
                                        <img src={item?.image} alt="" srcSet="" />
                                    </div>
                                </div>
                                <p>{item?.name}</p>
                            </div>
                            <p>{item?.price} NOK</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SalesByBrandAndStore;
