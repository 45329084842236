import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import { useNavigate, useOutletContext } from "react-router";
import ListComponent from "../../common/lists/ListComponent";
import SimpleButtonContentHeader from "../companies/createCompanyComponents/SimpleButtonContentHeader";
import * as Library from "../../../utils/Library";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import LayoutContext from "../../../LayoutContext";
import FormCard from "../../common/forms/FormCard";
import FormField from "../../common/forms/FormField";
import MaxStoreInput from "../../common/forms/MaxStoreInput";
import UrlIcon from "../../../assets/images/url-icon.svg";
import { getDropdownData } from "../../../utils/dropdownData";
import { userIsOnBoardingInPage } from "../../../utils/navigationHelper";
import { useSelector } from "react-redux";
import AppContext from "../../../AppContext";
import FormFieldTagsDropdown from "../../common/forms/FormFieldTagsDropdown";
import FormFieldTextAutocomplete from "../../common/forms/FormFieldTextAutocomplete";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import { maskEmail, maskName } from "../../../utils/valuesMasking";
import { defaultStatusColors } from "../../../utils/CardUtils";
import { AppQueryClient } from "../../../api/queryClient";

// #region SCOPED CSS
const StyledPage = styled.div`
    .input-remove-default {
        border: none;
        outline: none;
        background: none;
    }

    .selects {
        background: var(--backgrounds-and-lines-n-600, #10141b);
    }

    .selects.mandatory {
        border: 1px solid transparent;
        border-left: 5px solid var(--text-icons-orange-500);
        border-radius: 4px;
        outline: none;
    }

    .selects.mandatory > div {
        border: none;
        cursor: pointer;
    }

    .selects.mandatory.error {
        border: 1px solid var(--text-icons-red-500);
        border-left: 5px solid var(--text-icons-red-500);
    }

    .main-window-header {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        display: flex;
        flex-direction: row;
        row-gap: 0px;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        height: 68px;
        position: relative;
    }
    .frame-40 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .btn-large-solid {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 120px;
        height: 35px;
        position: relative;
    }
    .button {
        color: var(--buttons-all-platforms-gray-200, #4f5b6d);
        text-align: left;
        font-family: var(--button-text-small-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 10px);
        letter-spacing: var(--button-text-small-letter-spacing, 0.0125em);
        font-weight: var(--button-text-small-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
`;
// #endregion SCOPED CSS

export default function RetailersInvitePage() {
    document.title = `${APP_TITLE} - Retailers`;

    // #region STATES
    const formRetailerID = useRef();
    const formRetailerFieldsRefs = [];
    const navigate = useNavigate();
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);
    const getFormValues = formRetailerID?.current?.getAllFieldValues();
    const brandsInviting = getFormValues?.toBrand?.map(i => i?.tagName)?.join(", ");
    const { showReadyToRetailerSavePopup, setShowReadyToRetailerSavePopup } = useContext(LayoutContext);
    const { setHasUnsavedChanges } = useContext(AppContext);

    const listQueryKey = "RetailersInviteList";

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState({
        show: false,
        inviteID: "",
        brandName: "",
        wasAccepted: false
    });

    const [showVoidConfirmationDialog, setShowVoidConfirmationDialog] = useState({
        show: false,
        inviteID: "",
        brandName: "",
        wasAccepted: false
    });

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const isUserOnboarding = userIsOnBoardingInPage(navigations, "retailers");
    const [isLoading, setIsLoading] = useState(false);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const labelBrandName = JSON.parse(localStorage.getItem("labelBrands"))?.[0]?.name;
    const [countryOptions] = useState(getDropdownData("countryOptions"));
    const [selectedTaxCode, setSelectedTaxCode] = useState("NO");
    const [inviteFormData, setInviteFormData] = useState({
        brand: [
            {
                value: labelBrandName,
                name: labelBrandName,
                selected: true //selected true becuase we just have a single label brand
            }
        ],
        retailBrand: "",
        name: "",
        email: "",
        maxStore: "All",
        maxStoreQty: 0
    });

    const [listNumRows, setListNumRows] = useState(10);

    const getAllToBrands = () => {
        const toBrands = [];
        const labelBrands = JSON.parse(localStorage.getItem("labelBrands"));
        labelBrands.forEach(i => {
            toBrands.push({ value: i?._id, name: i?.name });
        });
        return toBrands;
    };
    const [allBrands] = useState(getAllToBrands());
    const [allBrandsNames] = useState(allBrands?.map(i => i?.name));
    const [defaultToBrand] = useState(allBrandsNames?.length === 1 ? [allBrands[0]?.name] : undefined);

    const [formRetailerPrefillFields, setFormRetailerPrefillFields] = useState({
        contactName: "",
        email: "",
        country: "Norway",
        taxNumber: ""
    });
    const [retailBrandExistsLockFields, setRetailBrandExistsLockFields] = useState(false);
    const [retailBrandSentToEntityID, setRetailBrandSentToEntityID] = useState(undefined);
    const [showInviteResendPopup, setShowInviteResendPopup] = useState({
        show: false,
        previousStatus: "--"
    });

    // #endregion STATES

    // #region METHODS
    const selectTimezoneAndPhoneNumber = data => {
        const selectedCountryID = data?.id;
        const taxIdData = getDropdownData("taxNumberOptions");
        const selectedTaxCode = taxIdData?.find(country => country.value === selectedCountryID);
        setSelectedTaxCode(selectedTaxCode?.value ?? "");
    };

    const checkFormIsValid = () => {
        const isStoreQtyValid = true || inviteFormData?.maxStoreQty > 0;
        const allCompanyIdFieldsAreValid = formRetailerID.current.allFieldsAreValid(false);
        // console.log("formRetailerID", formRetailerID.current);
        // console.log("allCompanyIdFieldsAreValid", allCompanyIdFieldsAreValid);

        const allFieldsAreValid = isStoreQtyValid && allCompanyIdFieldsAreValid;

        setSaveBtnDisabled(allFieldsAreValid);
    };

    const extractLabelBrandData = labelBrandNames => {
        const labelBrandData = [];
        const allLabelBrands = JSON.parse(localStorage.getItem("labelBrands"));
        allLabelBrands.forEach(i => {
            if (labelBrandNames.includes(i?.name))
                labelBrandData.push({
                    id: i?._id,
                    name: i?.name,
                    logo: i?.logos?.mainLogoURL
                });
        });
        return labelBrandData;
    };

    const onInviteClicked = async (forced = false) => {
        const { maxStore } = inviteFormData;

        const BOstagesAccountID = localStorage.getItem("BOstagesAccountID");
        const userName = localStorage.getItem("userName");

        const { email, retailBrand, toBrand, contactName, country, taxNumber } = getFormValues || {};

        const labelBrandNames = toBrand?.map(tags => tags?.tagName);
        const labelBrandsData = extractLabelBrandData(labelBrandNames);

        let newRetailBrandName = undefined;
        let sentToEntityID = undefined;

        //Retail Brand exists if the value is selected from the dropdown
        if (retailBrandSentToEntityID) {
            newRetailBrandName = undefined;
            sentToEntityID = retailBrandSentToEntityID;

            if (!retailBrandSentToEntityID) {
                Library.showToastMessage({
                    type: "warning",
                    title: "Something went wrong. Please refresh the page and try again."
                });
                return;
            }
        } else {
            newRetailBrandName = retailBrand?.name;
            sentToEntityID = undefined;
        }

        const postData = {
            retailerEmail: email,
            retailerContactName: contactName,

            labelBrandsData: labelBrandsData,
            maxStores: maxStore,
            BOstagesAccountID: BOstagesAccountID,
            newRetailBrandName: newRetailBrandName,
            sentToEntityID: sentToEntityID,
            sentToEntityCountry: country?.value,
            BOstagesAccountName: userName,
            //retailerCountry: country?.value, // OLD
            retailerTaxNumber: taxNumber,
            forced: forced
        };

        setHasUnsavedChanges(false);

        const retailName = getFormValues?.retailBrand?.name?.split(",")?.[0];

        const res = await Library.makePostRequest(
            "inviteRetailer",
            postData,
            false,
            setIsLoading,
            handleInvitationError,
            null,
            false
        );
        if (res?.data?.status === 200) {
            navigate("/retailers/invitations");

            Library.showToastMessage({
                type: "success",
                title: "Invitation Sent!",
                description: `The retail brand ${retailName} will get an invite by email with the guidelines to affiliate their stores as fulfillment centers for ${brandsInviting}`
            });
        } else setHasUnsavedChanges(true);
    };

    const handleInvitationError = error => {
        const errorArray = error?.data?.data?.errorsFound;
        const firstError = errorArray?.[0];
        const invite = firstError?.invite;

        Library.showToastMessage({
            type: "warning",
            title: "Failed to invite retailer",
            description: firstError?.message
        });

        if (invite?.status?.status === "Declined" || invite?.status?.status === "Voided") {
            setShowInviteResendPopup({
                show: true,
                previousStatus: invite?.status?.status
            });
        }
    };

    const prefillRetailBrandData = async data => {
        if (!data || !data?.value) return;
        const { value } = data;

        const res = await Library.makePostRequest("getRetailBrandData", { retailBrandID: value }, false, null);
        if (res?.data?.status === 200) {
            const retailBrandData = res?.data?.data;
            const companyData = retailBrandData?.companyData;

            const { taxNumber, taxPreLabel } = companyData;
            const accountOwnerEmail = retailBrandData?.accountOwnerEmail;
            const accountOwnerName = retailBrandData?.accountOwnerName;
            const country = retailBrandData?.location?.country
                ? retailBrandData?.location?.country
                : retailBrandData?.location?.countryValue;

            let taxCountryHasError = false;

            if (!country || !taxNumber || !taxPreLabel || taxPreLabel.length > 9) {
                /*Library.showToastMessage({
                    type: "warning",
                    title: "Warning",
                    description:
                        "The selected retail brand has broken data and unfortunately cannot be invited. Please proceed with selecting another one to invite."
                });
                return;*/
                taxCountryHasError = true;
            }

            if (accountOwnerEmail && accountOwnerName) {
                setFormRetailerPrefillFields({
                    contactName: accountOwnerName,
                    email: accountOwnerEmail,
                    country: !taxCountryHasError ? country : "",
                    taxNumber: !taxCountryHasError ? taxNumber : ""
                });

                setSelectedTaxCode(taxPreLabel);
                setRetailBrandExistsLockFields(true);
                setRetailBrandSentToEntityID(value); // setRetailBrandSentToEntityID when a valid retail brand is selected
            } else {
                Library.showToastMessage({
                    type: "warning",
                    title: "Warning",
                    description:
                        "This Retail Brand does not have valid account owner data, please fill in the details manually."
                });

                setRetailBrandSentToEntityID(value);
            }
        }
    };

    const handleRetailBrandChange = () => {
        setRetailBrandExistsLockFields(false);
        setRetailBrandSentToEntityID(undefined); // setRetailBrandSentToEntityID to undefined when the retail brand value changes

        //reset the prefill fields
        setFormRetailerPrefillFields({
            contactName: "",
            email: "",
            country: "Norway",
            taxNumber: ""
        });
    };

    const handleResendInvitation = async inviteID => {
        const res = await Library.makePostRequest(
            "resendInviteToRetailer",
            {
                inviteID: inviteID
            },
            false,
            setIsLoading
        );

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                type: "success",
                title: "Resend Invitation",
                message: "Invitation has been resent successfully."
            });

            onClickRefreshAction();
        }
    };

    const handleVoidInvitation = async (inviteID, brandName, wasAccepted) => {
        setShowVoidConfirmationDialog({
            show: true,
            inviteID: inviteID,
            brandName: brandName,
            wasAccepted: wasAccepted
        });
    };

    const handleVoidInvitationRequest = async inviteID => {
        const res = await Library.makeCommonPostRequest(
            "Invites",
            "updateInvite",
            {
                inviteID: inviteID,
                BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
                status: "Voided"
            },
            false,
            setIsLoading
        );

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                type: "success",
                title: "Invitation voided!",
                message: "This invitation is no longer valid."
            });

            onClickRefreshAction();
        }
    };

    const handleDeleteInvitation = async (inviteID, brandName, wasAccepted) => {
        setShowDeleteConfirmationDialog({
            show: true,
            inviteID: inviteID,
            brandName: brandName,
            wasAccepted: wasAccepted
        });
    };

    const handleDeleteInvitationRequest = async inviteID => {
        const res = await Library.makeCommonPostRequest(
            "Invites",
            "hideInvite",
            {
                inviteID: inviteID,
                BOstagesAccountID: localStorage.getItem("BOstagesAccountID")
            },
            false,
            setIsLoading
        );

        if (res?.data?.status === 200) {
            Library.showToastMessage({
                type: "success",
                title: "Invitation deleted!",
                message: "Invitation deleted successfully!"
            });

            onClickRefreshAction();
        }
    };

    const onClickRefreshAction = () => {
        AppQueryClient.invalidateQueries(listQueryKey);
    };
    // #endregion METHODS

    // #region FORM FIELDS
    const formRetailerFields = [
        <FormFieldTagsDropdown
            type="text"
            autoCompleteStaticData={allBrands || []}
            title="To Brand"
            tooltip="Enter the brand you want to send the invite from. This is your brand. If you only have one brand enrolled, the field will be locked."
            tooltipMaxWidth="125px"
            requiredType={isUserOnboarding ? "mandatoryLocked" : "mandatory"}
            allowNewTags={false}
            varName="toBrand"
            value={defaultToBrand}
            ref={el => (formRetailerFieldsRefs.toBrand = el)}
            //onSelectedItemChange={e => console.log(e)}
        />,
        <FormFieldTextAutocomplete
            type="text"
            title="Retail Brand"
            tooltip="Enter the retail brand you want to invite. If the retail brand is already in BOtag network, it will show in the dropdown."
            tooltipMaxWidth="125px"
            requiredType="mandatory"
            varName="retailBrand"
            autoCompleteAPIData={{
                apiName: "searchRetailBrandsForFFC",
                apiSearchFieldName: "retailBrandName",
                returnArrayName: "data",
                returnFieldIDName: "_id",
                returnFieldValueName: "name",
                customMapping: data => {
                    return data?.map(i => {
                        let name = i?.name;
                        if (i?.location?.city) name += `, ${i?.location?.city}`;
                        if (i?.location?.countryValue) name += `, ${i?.location?.countryValue}`;

                        return { name: name, value: i?._id };
                    });
                }
            }}
            ref={el => (formRetailerFieldsRefs.retailBrand = el)}
            itemNotSelectedWillClear={false}
            noSearchResultsHideMessage={true}
            onSelectedItemChange={prefillRetailBrandData}
            onTextChange={handleRetailBrandChange}
        />,
        <FormField
            type="text"
            title="Contact Name"
            tooltip="Enter the first and last names of the person from the retail brand that will receive the invitation email. If the retail brand is already in BOtag network, this field will be locked."
            tooltipMaxWidth="180px"
            requiredType={retailBrandExistsLockFields ? "locked" : "mandatory"}
            assistiveText=""
            varName="contactName"
            ref={el => (formRetailerFieldsRefs.contactName = el)}
            value={formRetailerPrefillFields.contactName}
            useValueMasking={retailBrandExistsLockFields && true}
        />,
        <FormField
            type="email"
            title="Email"
            tooltip="Enter the email address to send the invitation to. The invitation will be addressed to the contact person provided in the previous field. If the retail brand is already in the BOtag network, this field will be locked."
            tooltipMaxWidth="224px"
            requiredType={retailBrandExistsLockFields ? "locked" : "mandatory"}
            assistiveText=""
            varName="email"
            leftContent={<img src={UrlIcon} alt="glob-icon" />}
            ref={el => (formRetailerFieldsRefs.email = el)}
            value={formRetailerPrefillFields.email}
            useValueMasking={retailBrandExistsLockFields && true}
        />,
        <FormField
            type="dropdown"
            dropdownData={countryOptions}
            title="Country"
            assistiveText=""
            isDropdownSearchable={true}
            onDropdownChange={selectTimezoneAndPhoneNumber}
            tooltip="Enter the country where this retail brand is from. This field is not mandatory."
            tooltipMaxWidth="166px"
            // requiredType={retailBrandExists ? "locked" : "mandatory"}
            requiredType={"mandatoryLocked"}
            varName="country"
            ref={el => (formRetailerFieldsRefs.country = el)}
            value={formRetailerPrefillFields.country}
        />,

        <FormField
            type="taxNumber"
            title="Tax Number"
            tooltip="Enter this retailer’s tax/VAT number. This field is not mandatory."
            tooltipMaxWidth="166px"
            taxNumberCountryCode={selectedTaxCode}
            rightContent={selectedTaxCode === "NO" ? "MVA" : ""}
            varName="taxNumber"
            requiredType={retailBrandExistsLockFields ? "locked" : "simple"}
            assistiveText=""
            ref={el => (formRetailerFieldsRefs.taxNumber = el)}
            value={formRetailerPrefillFields.taxNumber}
        />
    ];
    // #endregion

    // #region EFFECTS
    useEffect(() => {
        checkFormIsValid();
    }, [inviteFormData, formRetailerPrefillFields, selectedTaxCode]);

    useEffect(() => {
        const saveIsEnabled = saveBtnDisabled && !isLoading;

        setupHeaders(props, saveIsEnabled, setShowReadyToRetailerSavePopup, navigations);
    }, [props?.layoutType, saveBtnDisabled, isLoading]);
    // #endregion EFFECTS

    // #region LIST COMPONENT PROPS
    const listColumns = [
        {
            name: "Retail Brand",
            selector: row => row?.retailBrand?.name || "--"
        },
        {
            name: "To Brand",
            selector: row => row?.labelBrand?.name || "--"
        },
        {
            name: "Invited on",
            selector: row => formatDefaultDateTime(row?.createdAt) || "--"
        },
        {
            name: "Accepted on",
            selector: row =>
                row?.statusLog && row?.statusLog[0]?.status === "Accepted"
                    ? formatDefaultDateTime(row?.statusLog[0]?.date)
                    : "--"
        },
        {
            name: "Contact person",
            selector: row =>
                row?.status !== "Accepted" && row?.retailBrand?._id
                    ? maskName(row?.contactFirstName)
                    : row?.contactFirstName || "--"
        },
        {
            name: "Contact email",
            selector: row =>
                row?.status !== "Accepted" && row?.retailBrand?._id
                    ? maskEmail(row?.contactEmail)
                    : row?.contactEmail || "--"
        },
        {
            name: "Max Stores",
            selector: row => (row?.retailBrand?._id ? row?.maxStores : "--")
        },
        {
            name: "Retailer Active Stores",
            selector: row => (row?.retailBrand?._id ? row?.activeStores : "--")
        }
    ];
    // #endregion

    return (
        <StyledPage>
            <FullScreenOverlay show={showDeleteConfirmationDialog.show}>
                <DialogBoxComponent
                    heading="ATTENTION"
                    headingColor="var(--text-icons-red-500)"
                    title="Ready to remove?"
                    message={
                        showDeleteConfirmationDialog?.wasAccepted
                            ? `Do you want to remove ${showDeleteConfirmationDialog?.brandName} invitation card? This will not remove the connection with the retailer. You can find this retailer in the retailers menu, selecting CONNECTIONS in the control bar.`
                            : `Do you want to remove ${showDeleteConfirmationDialog?.brandName} invitation card?`
                    }
                    onClickYes={() => {
                        handleDeleteInvitationRequest(showDeleteConfirmationDialog?.inviteID);
                        setShowDeleteConfirmationDialog({ show: false, inviteID: "", brandName: "" });
                    }}
                    loading={isLoading}
                    loadingHeading={"Removing..."}
                    onClickNo={() => {
                        setShowDeleteConfirmationDialog({ show: false, inviteID: "", brandName: "" });
                    }}
                    cancelText="No"
                    confirmText="Yes"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showInviteResendPopup.show}>
                <DialogBoxComponent
                    heading="ATTENTION"
                    headingColor="var(--text-icons-red-500)"
                    title="Ready to invite again?"
                    message={`You previously invited this retailer and the invitation was ${showInviteResendPopup?.previousStatus?.toLowerCase()}. Do you want to invite ${
                        showDeleteConfirmationDialog?.brandName
                    } again?`}
                    onClickYes={() => {
                        onInviteClicked(true);
                        setShowInviteResendPopup({ show: false, previousStatus: "--" });
                    }}
                    loading={isLoading}
                    loadingHeading={"Inviting..."}
                    onClickNo={() => {
                        setShowInviteResendPopup({ show: false, previousStatus: "--" });
                    }}
                    cancelText="No"
                    confirmText="Yes"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showVoidConfirmationDialog.show}>
                <DialogBoxComponent
                    heading="JUST CHECKING"
                    headingColor="var(--text-icons-red-500)"
                    title="Ready to void invitation?"
                    message="Do you want the invitation to be canceled? The retailer will receive a 'This invitation is no longer valid' notification."
                    onClickYes={() => {
                        handleVoidInvitationRequest(showVoidConfirmationDialog?.inviteID);
                        setShowVoidConfirmationDialog({ show: false, inviteID: "", brandName: "" });
                    }}
                    loading={isLoading}
                    loadingHeading={"Voiding..."}
                    onClickNo={() => {
                        setShowVoidConfirmationDialog({ show: false, inviteID: "", brandName: "" });
                    }}
                    cancelText="No"
                    confirmText="Yes"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={showReadyToRetailerSavePopup}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-blue-500)"
                    title="Ready to invite?"
                    message={`Ready to invite the retail brand <b>${
                        getFormValues?.retailBrand?.name
                    }</b> to affiliate ${
                        inviteFormData?.maxStoreQty === 0 ? "all" : inviteFormData?.maxStoreQty + " of"
                    } its stores as fulfillment centers for your brand <b>${brandsInviting}</b>?`}
                    onClickYes={() => {
                        onInviteClicked();
                        setShowReadyToRetailerSavePopup(false);
                    }}
                    loading={isLoading}
                    loadingHeading={"SUBSCRIBING.."}
                    onClickNo={() => {
                        setShowReadyToRetailerSavePopup(false);
                    }}
                    cancelText="No"
                    confirmText="Yes"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={isLoading}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-green-500)"
                    heading="INVITING..."
                    showCancel={false}
                    showConfirm={false}
                    loading={isLoading}
                />
            </FullScreenOverlay>

            <FormCard
                title="INVITE RETAILER"
                ref={formRetailerID}
                numOfFieldsPerLine={3}
                showDefaultRightLegends={true}
                formFields={formRetailerFields}
                formFieldRefs={formRetailerFieldsRefs}
                onFormChanged={checkFormIsValid}
                rightContent={
                    <MaxStoreInput
                        inviteFormData={inviteFormData}
                        setInviteFormData={setInviteFormData}
                        tooltip="Enter the max number of stores you allow the retail brand to affiliate to your brand. If no limit select ‘ALL’."
                        tooltipMaxWidth="125px"
                        title="Max Stores"
                        fieldIsInvalid={false}
                    />
                }
                showUnsavedChangesPopup={true}
            />

            <div className="flex flex-dir-column gap-16 w-100">
                <ListComponent
                    title="RETAILERS"
                    title2="(LAST 10 INVITED)"
                    columns={listColumns}
                    api={{
                        endpoint: "getInvitedRetailers",
                        useCommonPostRequest: false,
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
                            limit: listNumRows
                        }
                    }}
                    pageLimit={listNumRows}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        includeActions: true,
                        extraActions: [
                            {
                                title: "Void",
                                onClick: row => {
                                    if (row?.status === "Invited" || row?.status === "Invited-Resent")
                                        handleVoidInvitation(
                                            row?._id,
                                            row?.retailBrand?.name,
                                            row?.status === "Accepted"
                                        );
                                    return undefined;
                                },
                                isDisabled: row => {
                                    return row?.status !== "Invited" && row?.status !== "Invited-Resent";
                                }
                            },
                            {
                                title: "Resend",
                                onClick: row => {
                                    if (row?.status === "Invited" || row?.status === "Invited-Resent")
                                        handleResendInvitation(row?._id);
                                    return undefined;
                                },
                                isDisabled: row => {
                                    return row?.status !== "Invited" && row?.status !== "Invited-Resent";
                                }
                            }
                        ],
                        editIsVisible: false,
                        suspendIsVisible: false,
                        onClickRemove: row => {
                            handleDeleteInvitation(row?._id, row?.retailBrand?.name, row?.status === "Accepted");
                        },
                        removeIsDisabled: row => {
                            return ["Accepted", "Declined", "Voided"].includes(row?.status);
                        }
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    onChangeRowsPerPage={rowsPerPage => setListNumRows(rowsPerPage)}
                />
            </div>
        </StyledPage>
    );
}

// #region SMALL COMPONENTS AND METHODS
function setupHeaders(props, saveIsEnabled, onBtnClicked, navigations) {
    //const createMode = navigations?.navigationAccesses?.retailers?.createModeOnly;

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Retailers", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/retailers/connections", active: false, disabled: false, title: "connections" },
        { to: "/retailers/invitations", active: true, disabled: false, title: "Invitations" },
        { to: "/retailers/requests", active: false, disabled: false, title: "Requests" }
    ]);
    props.setControlBarRightBtns([
        { to: "/retailers/invitations", active: false, disabled: false, title: "View", icon: "eye" },
        { to: "/retailers/invite", active: true, disabled: false, title: "Invite", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader btnTitle="INVITE" btnEnabled={saveIsEnabled} onClick={onBtnClicked} />
        )
    });
}
// #endregion
