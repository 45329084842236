import React, { useEffect, useState } from "react";
import styled from "styled-components";

/* #region  SCOPED CSS */
const StyledElem = styled.div`
    .control-description {
        color: var(--text-icons-gray-100);
        font-size: 10px;
    }

    .switch {
        display: flex;
        align-self: center;
        height: 17px;
        margin: 0;
        position: relative;
        width: 45px;
    }

    .switch input {
        display: none;
    }

    .slider.round {
        border-radius: 34px;
        outline: none;
    }

    .slider {
        background-color: var(--backgrounds-lines-n-600);
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.4s;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .slider:before {
        background-color: var(--text-icons-gray-300);
        border: 5px solid var(--backgrounds-lines-n-400);
        bottom: -1px;
        content: "";
        height: 22px;
        left: 0;
        position: absolute;
        transition: 0.4s;
        width: 22px;
        filter: drop-shadow(0px 0px 10px var(--backgrounds-lines-n-600));
    }

    input:checked + .slider {
        background-color: var(--backgrounds-lines-n-600);
    }

    .slider.round {
        border-radius: 34px;
        outline: none;
    }

    input:checked + .slider.round:before {
        background-color: var(--text-icons-green-500) !important;
        -webkit-transform: translateX(25px);
        transform: translateX(25px);
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .slider.round:before {
        background-color: var(--text-icons-orange-500) !important;
    }

    .switch.always-green .slider.round:before {
        background-color: var(--text-icons-green-500) !important;
    }

    .switch.disable .slider.round:before {
        background-color: var(--text-icons-gray-300) !important;
    }
`;
/* #endregion */

/** SimpleToggle Component
 *
 * @param {Object} props
 * @param {string} props.className - The class name of the toggle.
 * @param {string} [props.height="19px"] - The height of the toggle.
 * @param {string} props.title - The title of the toggle.
 * @param {string} props.rightTitle - The right title of the toggle.
 * @param {function} props.onToogleChanged - The function to be called when the toggle is changed.
 * @param {boolean} props.isEnabled - The toggle status.
 * @param {boolean} props.isDisabled - The toggle status.
 * @param {boolean} [props.alwaysGreen=false] - The toggle status.
 *
 *
 * @returns {React.Component} - The SimpleToggle component.
 * @example
 * <SimpleToggle title="All Brands"
 */
export default function SwitchButton(props) {
    const {
        className = "",
        height = "19px",
        title,
        rightTitle,
        onToogleChanged,
        isEnabled = false,
        isDisabled,
        alwaysGreen = false
    } = props;

    /* #region STATES */
    const [isToggled, setIsToggled] = useState(isEnabled);
    /* #endregion */

    /* #region METHODS */
    const handleToggle = () => {
        setIsToggled(!isToggled);
        onToogleChanged && onToogleChanged(!isToggled);
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setIsToggled(isEnabled);
    }, [isEnabled]);
    /* #endregion */

    return (
        <StyledElem
            className={`switch-button flex gap-8px ${isDisabled ? "disabled" : ""} ${className}`}
            style={{ height }}>
            {title && <span className="control-description">{title}</span>}

            <label className={`switch ${isDisabled ? "disable" : ""} ${alwaysGreen ? "always-green" : ""}`}>
                <input type="checkbox" disabled={isDisabled} defaultChecked={isToggled} onChange={handleToggle} />
                <span className="slider round"></span>
            </label>

            {rightTitle && <span className="control-description">{rightTitle}</span>}
        </StyledElem>
    );
}
