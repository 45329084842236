import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import DotsGrid from "@untitled-ui/icons-react/build/esm/DotsGrid";
import List from "@untitled-ui/icons-react/build/esm/List";
import RefreshCcw01 from "@untitled-ui/icons-react/build/esm/RefreshCcw01";
import RefreshCcw05 from "@untitled-ui/icons-react/build/esm/RefreshCcw05";
import { ReactComponent as IconSearch } from "../../../../assets/icons/icon-search.svg";
import { ReactComponent as ChevronDown } from "../../../../assets/icons/chevron-down.svg";
import { ReactComponent as IconTrash } from "../../../../assets/icons/trash-01.svg";
import { ReactComponent as IconHand } from "../../../../assets/icons/hand.svg";
import { ReactComponent as IconClose } from "../../../../assets/icons/x-close1.svg";
import { ReactComponent as IconUpload } from "../../../../assets/icons/upload-01.svg";
import LayoutContext from "../../../../LayoutContext";
import * as Library from "../../../../utils/Library";
import Checkbox from "../../checkbox/Checkbox";
import { useLocation } from "react-router";
import FullScreenOverlay from "../../overlay/FullScreenOverlay";
import AppContext from "../../../../AppContext";
import FormField from "../../forms/FormField";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    &.main-window-header {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px 24px;
    }

    &.main-window-header,
    &.main-window-header * {
        box-sizing: border-box;
    }
    .property-1-default-locked {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        //padding: 15px 24px 15px 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 37px;
        position: relative;
    }
    .frame-1000011372 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313369 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3133692 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313371 {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .checkbox {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        top: -2px;
    }
    .rectangle-1 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .check {
        opacity: 0;
        width: 62.5%;
        height: 62.5%;
        position: absolute;
        right: 18.75%;
        left: 18.75%;
        bottom: 18.75%;
        top: 18.75%;
        overflow: visible;
    }
    .select-all {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .frame-313372 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313368 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        padding: 8px 10px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3133712 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    ._0-selected {
        text-align: left;
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .x-close {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-259 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .f-group-selections {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .icon-search {
        flex-shrink: 0;
        width: 40px;
        height: 37px;
        position: relative;
        //transform-origin: 0 0;
        //transform: rotate(0deg) scale(-1, 1);
    }
    .frame-29 {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .trash-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .hand {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .upload-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-260 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        //width: 1170px;
        position: relative;
    }
    .frame-1000011374 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;
        max-width: 283px;
        position: relative;
    }
    .frame-1000011374.responsive {
        width: auto !important;
        max-width: 357px;
        position: relative;
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 120px;
    }

    .frame-51 {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 37px;
        position: relative;
        overflow: hidden;
    }
    .frame-48 {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        height: 37px;
        position: relative;
        overflow: hidden;
    }
    .search {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        font-style: italic;
        position: relative;
    }
    .search-sm {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .input-selector-inverted {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 288px;
        height: 37px;
        position: relative;
    }
    .frame-50 {
        background: var(--text-and-icons-gray-300, #4f5b6d);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 37px;
        position: relative;
        overflow: hidden;
    }
    .search2 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 37px;
        position: relative;
    }
    .frame-482 {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 37px;
        position: relative;
        overflow: hidden;
    }
    .div {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
    .chevron-right {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .chevron-right2 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-1000011379 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313361 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        width: 288px;
        position: relative;
    }
    .frame-3133682 {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3133713 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .organize {
        //color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .chevron-down {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .icon-list {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .refresh-ccw-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .refresh-ccw-05 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .icon-toggler {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .frame-292 {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        background: var(--bostageb-green-active, #008800);
        border-radius: 4px 0px 0px 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 37px;
        position: relative;
    }
    .dots-grid {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-293 {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 0px 4px 4px 0px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 37px;
        position: relative;
    }

    .list {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-1000011379 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-1000011377 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }

    .button-disabled {
        cursor: not-allowed !important;
        color: var(--text-and-icons-gray-300) !important;
    }
    .button-disabled::placeholder {
        color: var(--text-icons-gray-300) !important;
    }

    .button-enabled {
        cursor: pointer;
        color: var(--text-and-icons-gray-100) !important;
    }

    .button-enabled-trash {
        cursor: pointer;
        color: var(--bostageb-red-active, #ff2d55) !important;
    }

    .button-enabled-hand {
        cursor: pointer;
        color: var(--bostageb-orange-active, #f90) !important;
    }

    .button-enabled-upload {
        cursor: pointer;
        color: var(--bostageb-green-active, #008800) !important;
    }

    .btn-group .filter-btn:hover,
    .btn-group .filter-btn:focus,
    .btn-group .filter-btn:active {
        border-color: var(--App-Accent-Active) !important;
        background-color: var(--App-Accent-Active) !important;
        outline: none !important;
    }

    .btn-group .filter-btn.disabled:hover,
    .btn-group .filter-btn.disabled:focus {
        background-color: var(--backgrounds-lines-n-400) !important;
        color: var(--text-icons-gray-100) !important;
    }

    .btn-group .filter-btn.active,
    .btn-group .filter-btn:hover,
    .btn-group .filter-btn:focus {
        color: var(--text-and-icons-gray-100) !important;
    }

    .btn-group .filter-btn.active {
        background: var(--App-Accent-Active);
        border: 1px solid var(--App-Accent-Active);
    }

    .filter-btn {
        height: 37px !important;
    }

    .filter-btn:focus {
        outline: none !important;
    }

    .filter-btn.disabled:focus {
        background-color: var(--backgrounds-lines-n-400) !important;
    }

    .organize-btn-container {
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        gap: 4px;
        background: var(--backgrounds-lines-n-500, #141922);
        border-radius: 4px;
        padding: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        min-width: 245px;
        min-height: 50px;
    }

    .product-name-ean-code {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: "Roboto-Italic", sans-serif;
        font-size: 12px;
        font-weight: 400;
        font-style: italic;
        position: relative;
        flex: 1;
        background: transparent;
        border: none;
        outline: none;
        min-width: 100px;
    }
    .product-name-ean-code.has-value {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: "Roboto", -apple-system, BlinkMacSystemFont, sans-serif;
        font-style: normal;
    }
`;

const FilterHeaderStyles = styled.div`
    .filter-section .form-control {
        //width: 348px;
        border-color: var(--backgrounds-lines-n-400);
        background: var(--backgrounds-lines-n-400);
        border-width: 1px;
        height: 37px;
        border-radius: 4px;
        max-width: none;
        font-style: italic;
        display: inline-flex;
        color: var(--text-icons-gray-300);

        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        transition: none;
        color: var(--text-icons-gray-200);
    }

    .filter-section .form-control::placeholder {
        color: var(--text-icons-gray-300);
    }

    .filter-section .form-control:hover,
    .filter-section btn:hover {
        border-color: var(--App-Accent-Outline);
    }

    .filter-section .form-control:focus {
        outline: none;
        box-shadow: none;
    }

    .filter-btn {
        width: 40px;
        height: 37px;
        font-size: 16px;
        background-color: var(--backgrounds-lines-n-400);
        border-color: var(--backgrounds-lines-n-400);
        color: var(--text-icons-gray-100);
    }

    .filter-btn-with-label {
        display: inline-grid;
        width: auto;
        text-align: center;
        font-size: 10px;
        margin-left: 0px;
        position: relative;
    }

    .filter-btn-with-label > span {
        text-align: center;
        font-size: 10px;
        color: var (--text-icons-gray-200);
    }

    .filter-btn-with-label > .btn,
    .filter-btn-with-label > .btn:hover,
    .filter-btn-with-label.disabled > .btn,
    .filter-btn-with-label.disabled > .btn:hover {
        opacity: 1;
        background-color: var(--backgrounds-lines-n-400) !important;
        color: var (--text-icons-gray-100);
        border-color: var(--backgrounds-lines-n-400);
    }

    .filter-btn-with-label.disabled > .btn,
    .filter-btn-with-label.disabled > .btn:hover {
        color: var(--text-icons-gray-300);
        cursor: not-allowed;
    }

    .filter-btn-with-label > .btn:hover,
    .filter-btn-with-label.disabled > .btn:hover {
        border-color: var(--App-Accent-Outline);
    }

    .filter-section {
        display: flex;
        flex-basis: unset !important;
    }

    .filter-section.left {
        float: left;
    }

    .filter-section.right {
        float: right;
    }

    .organize-select {
        width: 100px;
        //min-width: 290px;
    }

    .organize-select .placeholder {
        font-style: normal;
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .organize-select.disabled .placeholder {
        color: var(--text-icons-gray-300);
    }

    .new-organize-select,
    .new-organize-select * {
        box-sizing: border-box;
    }
    .new-organize-select {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .new-organize-select.disabled .organize-text,
    .new-organize-select.disabled .chevron-down {
        color: var(--text-icons-gray-300) !important;
    }
    .new-organize-select.disabled path {
        stroke: var(--text-icons-gray-300) !important;
    }
    .frame-313371 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .organize-text {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .chevron-down {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }

    .not-allowed {
        color: var(--text-icons-gray-300);
        cursor: not-allowed;
    }

    .filter-section > p {
        margin-right: 10px;
        padding: 0px 0px;
        color: var(--text-icons-gray-100);
        font-family: "Roboto";
        font-size: 12px;
        line-height: 37px;
        margin-bottom: 0;
    }

    .filter-section span {
        color: var(--text-icons-gray-200);
    }

    .showItems .sc-hHLeRK,
    .showItems .sc-hHLeRK.fhIruZ {
        display: block !important;
    }

    .filter-container {
        display: flex;
        gap: 6px;
    }

    .view-type-select .active {
        border-color: var(--App-Accent-Active);
        background-color: var(--App-Accent-Active);
    }

    .btn-group .filter-btn:hover,
    .btn-group .filter-btn:focus,
    .btn-group .filter-btn:active {
        border-color: var(--App-Accent-Active) !important;
        background-color: var(--App-Accent-Active) !important;
        outline: none !important;
    }

    .btn-group .filter-btn.disabled:hover {
        background-color: var(--backgrounds-lines-n-400) !important;
    }

    .filter-btn:focus {
        outline: none !important;
    }

    .filter-btn.disabled:focus {
        background-color: var(--backgrounds-lines-n-400) !important;
    }

    .organize-select-field .Frame-50 {
        display: none !important;
    }
`;

const OrganizeButtonStyles = styled.div`
    &.frame-349,
    &.frame-349 * {
        box-sizing: border-box;
    }
    &.frame-349 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-348 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-75 {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        z-index: 99999;
    }
    .frame-75:hover {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
    }
    .label {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
`;
/* #endregion */

/** Main Window Header provides a header with search and organize options for the main window of the application.
 *
 * @param {Object} props
 * @param {string} [props.className] - Additional classes to be added to the component.
 * @param {Object} [props.selections] - Selections object containing selection related properties.
 * @param {boolean} [props.selections.enabled=false] - If true, selection related properties will be enabled.
 * @param {boolean} [props.selections.allSelected=false] - If true, all items are selected.
 * @param {number} [props.selections.selectedCount=0] - Number of selected items.
 * @param {function} [props.selections.onClearSelection] - Function to be called when clear selection button is clicked.
 * @param {function} [props.selections.onSelectAll] - Function to be called when select all button is clicked.
 * @param {function} [props.selections.onDeleteClick] - Function to be called when delete button is clicked.
 * @param {function} [props.selections.onSuspendClick] - Function to be called when suspend button is clicked.
 * @param {function} [props.selections.onUploadClick] - Function to be called when upload button is clicked.
 * @param {Object} [props.firstOrganize] - First organize object containing organize related properties.
 * @param {boolean} [props.firstOrganize.visible=true] - If true, first organize related properties will be visible.
 * @param {string} [props.firstOrganize.usePlaceholderText="--"] - Placeholder text to be used in the dropdown.
 * @param {Array} [props.firstOrganize.dropdownData] - Data to be used in the dropdown.
 * @param {string} [props.firstOrganize.selectedValue] - Selected value in the dropdown.
 * @param {function} [props.firstOrganize.onDropdownChange] - Function to be called when dropdown value is changed.
 * @param {boolean} [props.firstOrganize.disabled=true] - If true, dropdown will be disabled.
 * @param {string} [props.firstOrganize.type="singleSelect"] - Type of the dropdown. (singleSelect or multiSelect)
 * @param {Object} [props.firstOrganize.multidropdownOptions] - Options for the multi select dropdown. View the FormField multidropdownOptions prop for more details.
 * @param {boolean} [props.firstOrganize.onlyOneDisableDropdown=true] - If true, if there is only one element, dropdown will be disabled. (ONLY APPLICABLE FOR SINGLE SELECT!)
 * @param {Object} [props.secondOrganize] - Second organize object containing organize related properties.
 * @param {boolean} [props.secondOrganize.visible=false] - If true, second organize related properties will be visible.
 * @param {string} [props.secondOrganize.usePlaceholderText="--"] - Placeholder text to be used in the dropdown.
 * @param {Array} [props.secondOrganize.dropdownData] - Data to be used in the dropdown.
 * @param {string} [props.secondOrganize.selectedValue] - Selected value in the dropdown.
 * @param {function} [props.secondOrganize.onDropdownChange] - Function to be called when dropdown value is changed.
 * @param {boolean} [props.secondOrganize.disabled=true] - If true, dropdown will be disabled.
 * @param {string} [props.secondOrganize.type="singleSelect"] - Type of the dropdown. (singleSelect or multiSelect)
 * @param {Object} [props.secondOrganize.multidropdownOptions] - Options for the multi select dropdown. View the FormField multidropdownOptions prop for more details.
 * @param {boolean} [props.secondOrganize.onlyOneDisableDropdown=true] - If true, if there is only one element, dropdown will be disabled. (ONLY APPLICABLE FOR SINGLE SELECT!))
 * @param {Object} [props.organizeButton] - Organize button object containing organize button related properties.
 * @param {boolean} [props.organizeButton.enabled=false] - If true, organize button will be enabled.
 * @param {React.Component} [props.organizeButton.layoutComponent] - Layout component to be used in the organize button.
 * @param {string} [props.organizeButton.className] - Additional classes to be added to the organize button container.
 * @param {Object} [props.organizeButton.style] - Additional styles to be added to the organize button container.
 * @param {function} [props.onResetClick] - Function to be called when reset button is clicked.
 * @param {function} [props.onRefreshClick] - Function to be called when refresh button is clicked.
 * @param {Object} [props.layoutTypeOptions] - Layout type options object containing layout type related properties.
 * @param {boolean} [props.layoutTypeOptions.hideGridList=false] - If true, grid and list buttons will be hidden.
 * @param {boolean} [props.layoutTypeOptions.gridEnabled=true] - If true, grid button will be enabled.
 * @param {boolean} [props.layoutTypeOptions.listEnabled=true] - If true, list button will be enabled.
 * @param {function} [props.layoutTypeOptions.onLayoutTypeChange] - Function to be called when layout type is changed.
 * @param {Object} [props.search] - Search object containing search related properties.
 * @param {string} [props.search.searchPlaceholder="Search"] - Placeholder text to be used in the search input.
 * @param {function} [props.search.onSearch] - Function to be called when search input value is changed.
 * @param {Object} [props.pageFilters] - Page filters object containing page filters related properties.
 * @param {boolean} [props.pageFilters.useFilters=false] - If true, page filters will be used.
 * @param {function} [props.pageFilters.onFiltersLoaded] - Function to be called when filters are loaded.
 * @param {Object} [props.pageFilters.filters] - Filters object containing filter related properties.
 * @param {boolean} [props.pageFilters.filters.useLabelBrands=false] - If true, label brands filter will be used.
 * @param {boolean} [props.pageFilters.filters.useRetailBrands=false] - If true, retail brands filter will be used.
 * @param {boolean} [props.pageFilters.filters.useStores=false] - If true, stores filter will be used.
 * @param {boolean} [props.pageFilters.filters.useProductsCollections=false] - If true, product collections filter will be used.
 * @param {boolean} [props.pageFilters.filters.useBOImagesCollections=false] - If true, BOImages collections filter will be used.
 * @param {boolean} [props.pageFilters.filters.useConnectedLabelBrands=false] - If true, connected label brands filter will be used.
 * @param {boolean} [props.pageFilters.filters.useConnectedRetailBrands=false] - If true, connected retail brands filter will be used.
 * @param {boolean} [props.pageFilters.filters.useConnectedStores=false] - If true, connected stores filter will be used.
 * @param {boolean} [props.pageFilters.filters.useConnectedProductCollections=false] - If true, connected product collections filter will be used.
 * @returns {JSX.Element} - Rendered MainWindowHeader component.
 */
export default function MainWindowHeader(props) {
    const {
        className = "",
        selections = {
            enabled: false,
            allSelected: false,
            selectedCount: 0,
            onClearSelection: undefined,
            onSelectAll: undefined,
            onDeleteClick: undefined,
            onSuspendClick: undefined,
            onUploadClick: undefined
        },
        firstOrganize = {
            visible: true,
            usePlaceholderText: "--",
            dropdownData: undefined,
            selectedValue: undefined,
            onDropdownChange: undefined,
            disabled: true,
            type: "singleSelect", //singleSelect or multiSelect
            multidropdownOptions: {},
            onlyOneDisableDropdown: true
        },
        secondOrganize = {
            visible: false,
            usePlaceholderText: "--",
            dropdownData: undefined,
            selectedValue: undefined,
            onDropdownChange: undefined,
            disabled: true,
            type: "singleSelect", //singleSelect or multiSelect
            multidropdownOptions: {},
            onlyOneDisableDropdown: true
        },
        organizeButton = {
            enabled: false,
            layoutComponent: undefined,
            className: "",
            style: {}
        },
        onResetClick,
        onRefreshClick,
        layoutTypeOptions = {
            hideGridList: false,
            gridEnabled: true,
            listEnabled: true,
            onLayoutTypeChange: undefined
        },
        search = {
            searchPlaceholder: "Search",
            onSearch: undefined
        },
        pageFilters = {
            useFilters: false,
            onFiltersLoaded: undefined,
            filters: {
                useLabelBrands: false,
                useRetailBrands: false,
                useStores: false,
                useProductsCollections: false,
                useBOImagesCollections: false,
                useConnectedLabelBrands: false,
                useConnectedRetailBrands: false,
                useConnectedStores: false,
                useConnectedProductCollections: false
            }
        }
    } = props;

    /* #region VARS */
    //contexts and misc:
    const { layoutType, setLayoutType } = useContext(LayoutContext);
    const { isLoadingApp } = useContext(AppContext);
    const location = useLocation();

    //states:
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [organizeContainerOpen, setOrganizeContainerOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [loadingFilters, setLoadingFilters] = useState(false);
    const [filtersData, setFiltersData] = useState(undefined);

    //computed vars: (constants calculated based on other vars)
    const widthLessThan1800 = windowWidth < 1800;
    const widthLessThan1506 = windowWidth < 1506;
    const widthLessThan1143 = windowWidth < 1143;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        //Set layout type to default on component mount (Grid by default)
        if (layoutTypeOptions?.listEnabled && !layoutTypeOptions?.gridEnabled) setLayoutType("List");
        else setLayoutType("Grid");
    }, [location]);

    useEffect(() => {
        if (!filtersData) return;

        if (pageFilters?.onFiltersLoaded) pageFilters.onFiltersLoaded(filtersData);
    }, [filtersData]);

    useEffect(() => {
        if (!pageFilters?.useFilters) return;

        getStockPageFilters();
    }, [pageFilters?.useFilters]);

    useEffect(() => {
        const handleClickOutside = e => {
            if (
                organizeContainerOpen &&
                !e.target.closest(".organize-btn-container") &&
                !e.target.closest(".frame-3133682")
            ) {
                setOrganizeContainerOpen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);

        return () => document.removeEventListener("click", handleClickOutside);
    }, [organizeContainerOpen]);
    /* #endregion */

    /* #region METHODS */
    const handleLayoutChange = layout => {
        if (layout === "List" && !layoutTypeOptions?.listEnabled) return;
        if (layout === "Grid" && !layoutTypeOptions?.gridEnabled) return;

        setLayoutType(layout);
        if (layoutTypeOptions?.onLayoutTypeChange) layoutTypeOptions.onLayoutTypeChange(layout);
    };

    const handleSearchChange = e => {
        setSearchInput(e.target.value);
        if (search?.onSearch) search.onSearch(e.target.value);
    };

    const getStockPageFilters = async () => {
        const filtersArray = [];

        if (pageFilters?.filters?.useLabelBrands) filtersArray.push("Label Brands");
        if (pageFilters?.filters?.useRetailBrands) filtersArray.push("Retail Brands");
        if (pageFilters?.filters?.useStores) filtersArray.push("Stores");
        if (pageFilters?.filters?.useProductsCollections) filtersArray.push("Product Collections");
        if (pageFilters?.filters?.useBOImagesCollections) filtersArray.push("BOimage Collections");
        if (pageFilters?.filters?.useConnectedLabelBrands) filtersArray.push("Connected Label Brands");
        if (pageFilters?.filters?.useConnectedRetailBrands) filtersArray.push("Connected Retail Brands");
        if (pageFilters?.filters?.useConnectedStores) filtersArray.push("Connected Stores");
        if (pageFilters?.filters?.useConnectedProductCollections) filtersArray.push("Connected Product Collections");

        const res = await Library.makeCommonPostRequest(
            "Global",
            "getFilters",
            {
                companyID: localStorage.getItem("currentCompanyID"),
                filters: filtersArray
            },
            false,
            setLoadingFilters
        );

        const data = res?.data?.data;
        if (res?.status !== 200) setFiltersData([]);

        setFiltersData(data);
    };

    const handleSelectAllClick = () => {
        if (selections?.allSelected) selections?.onClearSelection();
        else selections?.onSelectAll(true);
    };
    /* #endregion */

    return (
        <>
            <FullScreenOverlay isLoading={loadingFilters && !isLoadingApp} />

            <StyledPage className={`main-window-header ${className}`}>
                <div className="property-1-default-locked">
                    <div className="frame-1000011372">
                        <div className="frame-313369">
                            <div className="frame-3133692">
                                <div className="frame-313371">
                                    <Checkbox
                                        className="checkbox"
                                        disabled={!selections?.enabled}
                                        isChecked={selections?.allSelected}
                                        onCheckboxClick={handleSelectAllClick}
                                    />
                                    <div
                                        className={`select-all ${
                                            selections?.enabled && selections?.selectedCount
                                                ? "text-active"
                                                : "text-inactive"
                                        }`}>
                                        Select all
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="frame-313372">
                            <div className="frame-313368">
                                <div className="frame-3133692">
                                    <div
                                        className={`frame-3133712 ${
                                            selections?.selectedCount > 0 ? "text-active" : "text-inactive"
                                        }`}>
                                        <div className="_0-selected">{`${selections?.selectedCount} selected`}</div>
                                        <IconClose className="x-close" onClick={selections?.onClearSelection} />
                                    </div>
                                </div>
                            </div>

                            <div className="frame-259">
                                <div className="f-group-selections">
                                    <div
                                        className={`icon-search ${
                                            selections?.onDeleteClick && selections?.selectedCount > 0
                                                ? "button-enabled-trash"
                                                : "button-disabled"
                                        }`}
                                        onClick={selections?.onDeleteClick}>
                                        <div className="frame-29">
                                            <IconTrash className="trash-01" />
                                        </div>
                                    </div>
                                </div>

                                <div className="f-group-selections">
                                    <div
                                        className={`icon-search ${
                                            selections?.onSuspendClick && selections?.selectedCount > 0
                                                ? "button-enabled-hand"
                                                : "button-disabled"
                                        }`}
                                        onClick={selections?.onSuspendClick}>
                                        <div className="frame-29">
                                            <IconHand className="hand" />
                                        </div>
                                    </div>
                                </div>

                                <div className="f-group-selections">
                                    <div
                                        className={`icon-search ${
                                            selections?.onUploadClick && selections?.selectedCount > 0
                                                ? "button-enabled-upload"
                                                : "button-disabled"
                                        }`}
                                        onClick={selections?.onUploadClick}>
                                        <div className="frame-29">
                                            <IconUpload className="upload-01" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="frame-260">
                        <SearchComponent
                            visible={!widthLessThan1800}
                            searchData={search}
                            searchInput={searchInput}
                            handleSearchChange={handleSearchChange}
                        />

                        <OrganizeDropdownSelector
                            visible={!widthLessThan1506}
                            organize={firstOrganize}
                            type={firstOrganize?.type}
                        />

                        <OrganizeDropdownSelector
                            visible={!widthLessThan1506}
                            organize={secondOrganize}
                            type={secondOrganize?.type}
                        />

                        <div className="frame-1000011379">
                            <div className="frame-313361">
                                <div
                                    className={`frame-313372 ${
                                        organizeButton?.enabled ? "button-enabled" : "button-disabled"
                                    }`}
                                    onClick={() =>
                                        organizeButton?.enabled && setOrganizeContainerOpen(!organizeContainerOpen)
                                    }>
                                    <div
                                        className={`frame-3133682 ${
                                            organizeButton?.enabled ? "button-enabled" : "button-disabled"
                                        }`}>
                                        <div className="frame-3133692">
                                            <div className="frame-3133713">
                                                <div className="organize">Organize</div>
                                                <ChevronDown
                                                    className={`chevron-right2 ${
                                                        organizeButton?.enabled ? "button-enabled" : "button-disabled"
                                                    }`}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className={`organize-btn-container ${organizeButton?.className} ${
                                                organizeContainerOpen ? "open" : "d-none"
                                            }`}
                                            style={organizeButton?.style}>
                                            {organizeButton?.layoutComponent}
                                        </div>
                                    </div>

                                    <div className="frame-259">
                                        <div className="icon-list">
                                            <div
                                                className={`icon-search ${
                                                    onResetClick ? "button-enabled" : "button-disabled"
                                                }`}
                                                onClick={onResetClick}>
                                                <div className="frame-29">
                                                    <RefreshCcw01
                                                        className={`refresh-ccw-01 ${
                                                            onResetClick ? "button-enabled" : "button-disabled"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icon-list">
                                            <div
                                                className={`icon-search ${
                                                    onRefreshClick ? "button-enabled" : "button-disabled"
                                                }`}
                                                onClick={onRefreshClick}>
                                                <div className="frame-29">
                                                    <RefreshCcw05
                                                        className={`refresh-ccw-05 ${
                                                            onRefreshClick ? "button-enabled" : "button-disabled"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <span
                                    className="view-type-select"
                                    style={{ display: layoutTypeOptions?.hideGridList ? "none" : "block" }}>
                                    <div className="btn-group flex">
                                        <button
                                            type="button"
                                            className={`btn filter-btn grid-btn ${
                                                layoutType === "Grid" && layoutTypeOptions?.gridEnabled ? "active" : ""
                                            } ${!layoutTypeOptions?.gridEnabled ? "disabled" : ""}`}
                                            onClick={() => handleLayoutChange("Grid")}>
                                            <DotsGrid width={"16px"} />
                                        </button>

                                        <button
                                            type="button"
                                            className={`btn filter-btn list-btn ${
                                                layoutType === "List" && layoutTypeOptions?.listEnabled ? "active" : ""
                                            } ${!layoutTypeOptions?.listEnabled ? "disabled" : ""}`}
                                            onClick={() => handleLayoutChange("List")}>
                                            <List width={"16px"} />
                                        </button>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {widthLessThan1800 && (
                    <div
                        className="frame-1000011379"
                        style={{
                            justifyContent: !widthLessThan1143 ? "space-between" : "right"
                        }}>
                        <SearchComponent
                            visible={widthLessThan1800 /* && !widthLessThan1143 */}
                            responsive={true}
                            searchData={search}
                            searchInput={searchInput}
                            handleSearchChange={handleSearchChange}
                        />

                        <div className="frame-1000011377">
                            <OrganizeDropdownSelector
                                visible={widthLessThan1506}
                                organize={firstOrganize}
                                type={firstOrganize?.type}
                                dropdownStyle={{ right: firstOrganize?.type === "multiSelect" ? "69%" : "" }}
                            />

                            <OrganizeDropdownSelector
                                visible={widthLessThan1506}
                                organize={secondOrganize}
                                type={secondOrganize?.type}
                                dropdownStyle={{ right: secondOrganize?.type === "multiSelect" ? "69%" : "" }}
                            />
                        </div>
                    </div>
                )}
            </StyledPage>
        </>
    );
}

/* #region SMALL COMPONENTS */
const SearchComponent = props => {
    const { visible = true, responsive = false, searchData, searchInput, handleSearchChange } = props;
    const { searchPlaceholder, onSearch } = searchData;

    return (
        <div className={`frame-1000011374 ${responsive ? "responsive" : ""} ${visible ? "" : "d-none"}`}>
            <div className="frame-51"></div>
            <div className="frame-48">
                {/*<div className="search">Search</div>*/}
                <input
                    type="text"
                    disabled={!onSearch}
                    className={`product-name-ean-code ${onSearch ? "" : "button-disabled cursor-disabled"} ${
                        searchInput ? "has-value" : ""
                    }`}
                    placeholder={searchPlaceholder}
                    value={searchInput}
                    onChange={handleSearchChange}
                />
                <IconSearch className={`search-sm ${onSearch ? "text-active" : "text-inactive"}`} />
            </div>
        </div>
    );
};

const OrganizeDropdownSelector = props => {
    const type = props?.type || "singleSelect";
    const visible = props?.visible && props?.organize?.visible;

    return (
        <div className={`input-selector-inverted ${visible ? "visible" : "d-none"}`}>
            {type === "singleSelect" ? (
                <OrganizeSingleDropdown {...props} />
            ) : type === "multiSelect" ? (
                <OrganizeMultiDropdown {...props} />
            ) : (
                <div>ERROR</div>
            )}
        </div>
    );
};

const OrganizeSingleDropdown = props => {
    const [defaultDropdownData] = useState([{ value: "", name: "--", id: "", selected: true }]);
    const {
        dropdownData,
        selectedValue,
        onDropdownChange,
        disabled,
        onlyOneDisableDropdown = true
    } = props?.organize || {};
    const { dropdownStyle } = props;

    const isDisabled = disabled || (onlyOneDisableDropdown && dropdownData?.length === 1);

    return (
        <FilterHeaderStyles className={"input-selector-inverted2 button-disabled w-100"}>
            <FormField
                className="w-100"
                type="dropdown"
                dropdownData={dropdownData || defaultDropdownData}
                value={selectedValue}
                onDropdownChange={onDropdownChange}
                varName="dropdownSingleItem"
                isDropdownMultiselect={false}
                isDropdownSearchable={false}
                styling={{
                    backgroundColor: "var(--backgrounds-lines-n-400)",
                    requiredTypeColor: "var(--backgrounds-lines-n-500, #141922)",
                    dropdownOverlayTop: "45px",
                    dropdownOverlayStyle: dropdownStyle
                }}
                requiredType={!isDisabled ? "simple" : "locked"}
                disableTooltip={true}
            />
        </FilterHeaderStyles>
    );
};

const OrganizeMultiDropdown = props => {
    const [defaultDropdownData] = useState([{ value: "", name: "--", id: "", selected: true }]);
    const { dropdownData, selectedValue, onDropdownChange, disabled, dropdownOnSingleItemClick, multidropdownOptions } =
        props?.organize || {};
    const { dropdownStyle } = props;

    return (
        <FilterHeaderStyles className={"input-selector-inverted2 button-disabled w-100"}>
            <FormField
                className="w-100"
                type="dropdown"
                dropdownData={dropdownData || defaultDropdownData}
                value={selectedValue}
                onDropdownChange={onDropdownChange}
                varName="dropdownSingleItem"
                isDropdownMultiselect={true}
                isDropdownSearchable={true}
                styling={{
                    backgroundColor: "var(--backgrounds-lines-n-400)",
                    requiredTypeColor: "var(--backgrounds-lines-n-500, #141922)",
                    dropdownOverlayTop: "45px",
                    dropdownOverlayStyle: dropdownStyle
                }}
                requiredType={!disabled ? "simple" : "locked"}
                disableTooltip={true}
                dropdownOnSingleItemClick={dropdownOnSingleItemClick}
                firstElementIsSelectAll={true}
                dropdownSettings={{
                    hasDescriptionFields: true,
                    hasLockedFields: true,
                    widthPercentage: "169%"
                    //widthPercentage: "100%"
                }}
                multidropdownOptions={multidropdownOptions}
                useDropdownPlaceholderAsTag={true}
            />
        </FilterHeaderStyles>
    );
};
/* #endregion */

/* #region EXPORTED COMPONENTS */

/** Simple menu to be used for Organize button.
 * @param {Object} props
 * @param {string} [props.className] - Additional classes to be added to the component.
 * @param {Object} [props.style] - Additional styles to be added to the component.
 * @param {Array} [props.items] - Items to be displayed in the menu.
 * @param {function} [props.onItemClick] - Function to be called when an item is clicked.
 * @returns {JSX.Element} - Rendered OrganizeBtnMenu component.
 */
export const OrganizeBtnMenu = props => {
    const { className = "", style = {}, items = [], onItemClick } = props;

    return (
        <OrganizeButtonStyles className={`frame-349 ${className}`} style={style}>
            <div className="frame-348">
                {items.map((item, index) => (
                    <div
                        className="frame-75"
                        id={`organize-item-${index}`}
                        onClick={() => onItemClick(item?.value)}
                        key={index}>
                        <div className="label">{item?.name}</div>
                    </div>
                ))}
            </div>
        </OrganizeButtonStyles>
    );
};
/* #endregion */
