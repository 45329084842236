import React from "react";
import styled from "styled-components";
import icon_pos_traze from "../../../../assets/images/icon_pos_traze.png";
import icon_pos_front_systems from "../../../../assets/images/icon_pos_front_systems.png";
import icon_pos_shopify from "../../../../assets/images/icon_pos_shopify.png";
import BtnLargeSolidInverted02 from "../../../common/buttons/BtnLargeSolidInverted02";
import x_close from "../../../../assets/icons/x-close1.svg";

const StyledPage = styled.div`
    &.applet-picker-dlalog-card,
    &.applet-picker-dlalog-card * {
        box-sizing: border-box;
    }
    &.applet-picker-dlalog-card {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 4px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        position: sticky;
        box-shadow: var(--drop-shadow-200-box-shadow, 0px 0px 48.1px 0px rgba(17, 22, 32, 0.51));
    }
    .frame-160 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 672px;
        position: relative;
    }
    .frame-158 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-161 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .select-your-server {
        color: #ffffff;
        text-align: center;
        font-size: var(--heading-4-font-size, 18px);
        font-weight: var(--heading-4-font-weight, 300);
        position: relative;
        flex: 1;
    }
    .frame-144 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
    }
    .frame-313313 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 672px;
        position: relative;
    }
    .frame-299 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .applet-card {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-1602 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313312 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-270 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .rectangle-8 {
        border-radius: 4px;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
        object-fit: cover;
    }
    .frame-296 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .traze {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: var(--overline-font-size, 14px);
        letter-spacing: var(--overline-letter-spacing, 0.015em);
        font-weight: var(--overline-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .line-1 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px 0 0 0;
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
    }
    .effortless-transactions-elevated-experiences-simplify-operations-and-delight-customers-with-traze-pos {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .btn-large-solid-inverted-02 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 35px;
        position: relative;
    }
    .btn-small-filled {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 35px;
        position: relative;
    }
    .share {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: var(--button-text-large-font-size, 12px);
        letter-spacing: var(--button-text-large-letter-spacing, 0.0125em);
        font-weight: var(--button-text-large-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
    .frame-274 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .front-systems {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: var(--overline-font-size, 14px);
        letter-spacing: var(--overline-letter-spacing, 0.015em);
        font-weight: var(--overline-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .frame-272 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .sitoo {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: var(--overline-font-size, 14px);
        letter-spacing: var(--overline-letter-spacing, 0.015em);
        font-weight: var(--overline-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .frame-313314 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 672px;
        position: relative;
    }
    .frame-1442 {
        display: flex;
        flex-direction: row;
        background: #262e3e;
        height: 1px;
        width: 100%;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;

        position: relative;
        overflow: visible;
    }
    .none-of-the-above {
        color: #ffffff;
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        position: relative;
        flex: 1;
    }
    .frame-313315 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 672px;
        position: relative;
    }
    .frame-2992 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .applet-card2 {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 213px;
        position: relative;
    }
    .rectangle-6 {
        border-radius: 0px;
        flex-shrink: 0;
        width: 3px;
        height: 120px;
        position: absolute;
        left: 709px;
        top: 77px;
        overflow: visible;
    }
    .frame-162 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .x-close {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
`;

/**
 * AppletPicker component renders a selection of applet cards for different systems.
 * Each card's opacity and cursor style are determined by the corresponding enabled prop.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.trazeEnabled - Determines if the Traze applet card is enabled.
 * @param {boolean} props.frontSystemsEnabled - Determines if the Front Systems applet card is enabled.
 * @param {boolean} props.shopifyEnabled - Determines if the Shopify applet card is enabled.
 * @param {function} props.onClose - Callback function to handle the close action.
 * @param {function} props.onContactUs - Callback function to handle the contact us action.
 *
 * @example
 * <AppletPicker
 *   trazeEnabled={true}
 *   frontSystemsEnabled={false}
 *   shopifyEnabled={true}
 *   onClose={() => console.log('Close clicked')}
 *   onContactUs={() => console.log('Contact Us clicked')}
 * />
 */
export default function AppletPicker(props) {
    const { trazeEnabled, frontSystemsEnabled, shopifyEnabled, onClose, onContactUs } = props;

    const email = process.env.REACT_APP_SUPPORT_EMAIL;

    const handleContactUs = () => {
        if (onContactUs) {
            onContactUs();
            return;
        }

        const subject = "Integration request";
        const body = "Hello Botag team,\n\nI would like to request an integration with the following system(s):";

        window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    };

    return (
        <StyledPage className="applet-picker-dlalog-card">
            <div className="frame-160">
                <div className="frame-158">
                    <div className="frame-161">
                        <div className="select-your-server">Select your server</div>
                    </div>
                </div>
                <div className="frame-1442" />
            </div>
            <div className="frame-313313">
                <div className="frame-299">
                    <div
                        className="applet-card"
                        style={{ opacity: trazeEnabled ? 1 : 0.4, cursor: trazeEnabled ? "pointer" : "not-allowed" }}>
                        <div className="frame-1602">
                            <div className="frame-313312">
                                <div className="frame-270">
                                    <div className="frame-161">
                                        <img className="rectangle-8" src={icon_pos_traze} alt="TRAZE" />
                                        <div className="frame-296">
                                            <div className="traze">TRAZE</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="line-1"></div>
                            </div>
                            <div className="effortless-transactions-elevated-experiences-simplify-operations-and-delight-customers-with-traze-pos">
                                Effortless Transactions, Elevated Experiences: Simplify Operations and Delight Customers
                                with Traze POS
                            </div>
                            <div className="btn-large-solid-inverted-02">
                                <div className="btn-small-filled">
                                    <div className="share">
                                        {trazeEnabled ? "Configure integration" : "Coming soon"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="applet-card"
                        style={{
                            opacity: frontSystemsEnabled ? 1 : 0.4,
                            cursor: frontSystemsEnabled ? "pointer" : "not-allowed"
                        }}>
                        <div className="frame-1602">
                            <div className="frame-313312">
                                <div className="frame-274">
                                    <div className="frame-161">
                                        <img className="rectangle-8" src={icon_pos_front_systems} alt="FRONT SYSTEMS" />
                                        <div className="frame-296">
                                            <div className="front-systems">Front systems</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="line-1"></div>
                            </div>
                            <div className="effortless-transactions-elevated-experiences-simplify-operations-and-delight-customers-with-traze-pos">
                                Effortless Transactions, Elevated Experiences: Simplify Operations and Delight Customers
                                with Traze POS
                            </div>
                            <div className="btn-large-solid-inverted-02">
                                <div className="btn-small-filled">
                                    <div className="share">
                                        {frontSystemsEnabled ? "Configure integration" : "Coming soon"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="applet-card"
                        style={{
                            opacity: shopifyEnabled ? 1 : 0.4,
                            cursor: shopifyEnabled ? "pointer" : "not-allowed"
                        }}>
                        <div className="frame-1602">
                            <div className="frame-313312">
                                <div className="frame-272">
                                    <div className="frame-161">
                                        <img className="rectangle-8" src={icon_pos_shopify} alt="SHOPIFY" />
                                        <div className="frame-296">
                                            <div className="sitoo">SHOPIFY</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="line-1"></div>
                            </div>
                            <div className="effortless-transactions-elevated-experiences-simplify-operations-and-delight-customers-with-traze-pos">
                                Effortless Transactions, Elevated Experiences: Simplify Operations and Delight Customers
                                with Traze POS
                            </div>
                            <div className="btn-large-solid-inverted-02">
                                <div className="btn-small-filled">
                                    <div className="share">
                                        {shopifyEnabled ? "Configure integration" : "Coming soon"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="frame-313314">
                <div className="frame-1442" />
                <div className="frame-158">
                    <div className="frame-161">
                        <div className="none-of-the-above">None of the above?</div>
                    </div>
                </div>
            </div>
            <div className="frame-313315">
                <div className="frame-2992">
                    <div className="applet-card2">
                        <div className="frame-1602">
                            <BtnLargeSolidInverted02
                                text="CONTACT US"
                                style={{ width: "100%" }}
                                onClick={handleContactUs}
                                disabled={email === undefined}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="frame-162">
                <img className="x-close cursor-pointer" src={x_close} alt="Close" onClick={onClose} />
            </div>
        </StyledPage>
    );
}
