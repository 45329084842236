import styled from "styled-components";
import CardGridSkeleton from "../gridCards/animations/CardGridSkeleton";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useRef, useState } from "react";
import UsersGridCard from "../gridCards/UsersGridCard";
import * as Library from "../../../utils/Library";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: scroll;
        height: 100%;
        margin-right: -24px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 17px;
    }
`;
/* #endregion */

export default function InfinityGridAccountUsersCards(props) {
    const gridLayoutStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${props?.minColumnWidth || "395px"}, 1fr))`,
        gap: "24px"
    };

    /* #region STATES */
    const scrollContainerRef = useRef(null);
    const [scroll, setScroll] = useState(true);
    const pageLimit = props?.limit || 12;
    const [uid] = useState(uuidv4());
    const [minimumCardsFill, setminimumCardsFill] = useState(1); //props?.minimumCardsFill || pageLimit

    const {
        data,
        isFetching: isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = Library.usePostInfiniteQuery(
        uid,
        "getUsersData",
        {
            organizationID: localStorage.getItem("currentOrganizationID"),
            limit: pageLimit
        },
        () => {
            setScroll(true);
        },
        true,
        "Users"
    );

    const pageList = data?.pages;
    //const totalCount = data?.pages[0]?.data?.totalCount;
    const nextDataCount = data?.pages[data?.pages.length - 1].data?.nextDataCount;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        handleScroll();

        setminimumCardsFill(getTotalCards() + 1);
    }, [data?.pages]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && !isLoading && scroll && !isFetchingNextPage) {
            //console.log("scroll event added");
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            //console.log("scroll event removed");
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [scrollContainerRef, isLoading, pageList, scroll, isFetchingNextPage, data?.pages]);
    /* #endregion */

    /* #region METHODS */
    const handleScroll = () => {
        //console.log("scrolling");
        const scrollContainer = scrollContainerRef.current;

        const isAtBottom =
            Math.floor(scrollContainer.scrollHeight - scrollContainer.scrollTop) <= scrollContainer.clientHeight;

        if (isAtBottom && !isLoading && hasNextPage && !isFetchingNextPage) {
            setScroll(false);
            //console.log("fetching next page");
            fetchNextPage();
        }
    };

    const displayName = (firstName, lastName, defaultValue = "--") => {
        let result = defaultValue;
        if (firstName) result = firstName + " " + (lastName || "");

        return result;
    };

    const getTotalCards = () => {
        return pageList?.reduce((acc, it) => {
            return acc + it?.data?.result?.length;
        }, 0);
    };

    /* #endregion */
    return (
        <StyledPage className={`${props?.className || ""}`} ref={scrollContainerRef}>
            {isLoading && !isFetchingNextPage ? (
                <div style={gridLayoutStyle}>
                    {Array.from({ length: 12 }, () => (
                        <CardGridSkeleton
                            key={uuidv4()}
                            height="420.28px"
                            minColumnWidth={props?.minColumnWidth || "395px"}
                        />
                    ))}
                </div>
            ) : (
                <div className="mb-24px" style={gridLayoutStyle}>
                    {pageList?.map(it => {
                        return it?.data?.result?.map(item => {
                            return (
                                <UsersGridCard
                                    key={item?.id}
                                    className="w-100"
                                    image={item?.profilePhotoUrl}
                                    title={displayName(item?.firstName, item?.lastName)}
                                    id={item?.id}
                                    isCardEnabled={item?.status?.toLowerCase() !== "suspended"}
                                    isCardSuspended={item?.status?.toLowerCase() === "suspended"}
                                    data={{
                                        role: item?.role,
                                        labelBrandName: item?.labelBrandName,
                                        companyName: item?.companyName,
                                        organizationName: item?.organizationName,
                                        enrolledOn: item?.createdOn,
                                        currentStatus: item?.status
                                    }}
                                    defaultSecondTitle={props?.defaultSecondTitle}
                                />
                            );
                        });
                    })}

                    {!isFetchingNextPage &&
                        minimumCardsFill - getTotalCards() > 0 &&
                        Array.from({ length: minimumCardsFill - getTotalCards() }, (_, index) => (
                            <UsersGridCard
                                key={index}
                                isCardEnabled={false}
                                defaultSecondTitle={props?.defaultSecondTitle}
                            />
                        ))}

                    {isFetchingNextPage &&
                        Array.from({ length: nextDataCount }, (_, index) => (
                            <CardGridSkeleton
                                key={index}
                                height="420.28px"
                                minColumnWidth={props?.minColumnWidth || "395px"}
                            />
                        ))}
                </div>
            )}
        </StyledPage>
    );
}
