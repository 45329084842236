import React, { forwardRef, useContext, useImperativeHandle } from "react";
import CardComponentContainer from "../cardContainers/CardComponentContainer";
import AppContext from "../../../AppContext";
import FullScreenLoader from "../loaders/FullScreenLoader";
import styled from "styled-components";
import InfoCircle from "./../../../assets/icons/info-circle-orange.svg";
/**
 * Renders a form container consisting of multiple form fields with a number of fields per line.
 * Note: The formFields prop is an array of FormField.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the form container.
 * @param {boolean} [props.showDefaultRightLegends=true] - Whether to show the default right legends: (recommended, mandatory, locked).
 * @param {number} [props.numOfFieldsPerLine=4] - The number of fields per line.
 * @param {Object[]} [props.formFields=[]] - The form fields to render. So far can be FormField.jsx, FormFieldTextAutocomplete.jsx and FormFieldImageUpload.jsx.
 * @param {Object[]} [props.formFieldRefs=[]] - The form field refs.
 * @param {Function} [props.onFormChanged] - The function to call when the form changes.
 * @param {Object} [props.rightContent] - The right content to render.
 * @param {boolean} [props.showUnsavedChangesPopup=false] - Whether to show the unsaved changes popup when before leaving the page and there are unsaved changes.
 * @param {boolean} [props.setIsloading=false] - Whether to show the loading spinner.
 * @param {Object} [props.afterTitleComponent] - The component to render after the title.
 * @param {boolean} [props.showError=false] - Whether to show the error message.
 * @param {string} [props.errorMessage=""] - The error message to show.
 * @param {boolean} [props.hideFormContainer] - Whether to hide the form container.
 * @returns {JSX.Element} The rendered form container.
 */
const FormCard = forwardRef((props, ref) => {
    const {
        title,
        showDefaultRightLegends,
        numOfFieldsPerLine = 4,
        formFields = [],
        formFieldRefs = [],
        rightContent = undefined,
        showUnsavedChangesPopup = false,
        setIsloading = false,
        afterTitleComponent = undefined,
        errorMessage = "",
        hideFormContainer,
        style
    } = props;

    const { setHasUnsavedChanges } = useContext(AppContext);

    /* #region METHODS */
    const getAllFieldValues = () => {
        const fieldValues = {};

        for (let i = 0; i < formFields.length; i++) {
            const varName = formFields[i].props.varName;
            const fieldRef = formFieldRefs[varName];

            if (fieldRef) {
                fieldValues[varName] = fieldRef.getValue();
            } else {
                console.error(`FieldRef not found for '${varName}'`);
                return null;
            }
        }
        return fieldValues;
    };

    const allFieldsAreValid = highlightValidation => {
        let allFieldsValid = true;
        for (let i = 0; i < formFields.length; i++) {
            const varName = formFields[i].props.varName;
            const fieldRef = formFieldRefs[varName];
            if (fieldRef) {
                const isValid = fieldRef.isValid(highlightValidation);
                if (!isValid) {
                    //console.log("Field is not valid:", varName);
                    allFieldsValid = false;
                    break;
                }
            } else {
                console.error("FieldRef not found for varName:", varName, formFields);
                return null;
            }
        }
        return allFieldsValid;
    };

    const onFormChanged = () => {
        if (props.onFormChanged) {
            props.onFormChanged(false);
        }
    };

    const onFormCardHasUnsavedChanges = unsavedChanges => {
        if (showUnsavedChangesPopup) {
            setHasUnsavedChanges(unsavedChanges);
            //console.log("unsavedChanges:", unsavedChanges);
        }
    };
    /* #endregion */

    useImperativeHandle(ref, () => ({
        getAllFieldValues,
        allFieldsAreValid
    }));

    return (
        <div>
            <FullScreenLoader isLoading={setIsloading} />

            <CardComponentContainer
                title={title}
                hideFormContainer={hideFormContainer}
                afterTitleComponent={afterTitleComponent}
                spacing="16px"
                style={style}
                rightComponent={showDefaultRightLegends ? <FieldsRightLegends /> : undefined}>
                {errorMessage && <ErrorComponent errorMessage={errorMessage} />}
                <div style={{ display: "flex", gap: "16px" }}>
                    <div
                        className="FormFieldsContainer"
                        style={{
                            display: "inline-grid",
                            width: "100%",
                            gap: "16px",
                            gridTemplateColumns: `repeat(${numOfFieldsPerLine}, minmax(0, 1fr))`
                        }}>
                        {formFields.map((field, index) => {
                            //TODO: try to see if is possible to use the ref directly in the field component instead of using the formFieldRefs
                            const newField = {
                                ...field,
                                key: index,
                                props: {
                                    ...field.props,
                                    //onFormTextChange: onFormChanged,
                                    //onFormTextBlur: onFormChanged,
                                    onCurrentValueChange: onFormChanged,
                                    onFormCardVerifyValidity: onFormChanged,
                                    onFormCardChanges: onFormCardHasUnsavedChanges
                                }
                            };
                            //console.log("newField:", newField);
                            return newField;
                        })}
                    </div>
                    {rightContent && <div className="rightContentContainer">{rightContent}</div>}
                </div>
            </CardComponentContainer>
        </div>
    );
});

export default FormCard;

/* #region SMALL COMPONENTS */
const FieldsRightLegends = () => {
    /* #region VARS */
    const Frame186 = {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "4px",
        textTransform: "none"
    };

    const Rectangle7 = {
        width: "6px",
        height: "6px",
        borderRadius: "600px"
    };

    const legendTitle = {
        color: "#7c868b",
        fontSize: "12px",
        fontFamily: "Roboto",
        fontWeight: 400,
        wordWrap: "break-word"
    };
    /* #endregion */

    return (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px" }}>
            <div className="Frame186" style={Frame186}>
                <div className="Rectangle7" style={{ ...Rectangle7, background: "#00AEEF" }} />
                <div className="legendTitle" style={legendTitle}>
                    recommended
                </div>
            </div>
            <div className="Frame186" style={Frame186}>
                <div className="Rectangle7" style={{ ...Rectangle7, background: "#FF9900" }} />
                <div className="legendTitle" style={legendTitle}>
                    mandatory
                </div>
            </div>
            <div className="Frame186" style={Frame186}>
                <div className="Rectangle7" style={{ ...Rectangle7, background: "#4F5B6D" }} />
                <div className="legendTitle" style={legendTitle}>
                    locked
                </div>
            </div>
        </div>
    );
};
/* #endregion */
/* #region SMALL COMPONENTS */
const ErrorComponent = ({ errorMessage }) => {
    const StyleErrorDiv = styled.div`
        &.frame-1000011223 {
            background: var(--backgrounds-and-lines-n-500, #141922);
            border-radius: 4px;
            padding: 8px;
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            justify-content: center;
            flex: 1;
            position: relative;
            margin-bottom: 24px;
        }
        .info-circle {
            flex-shrink: 0;
            width: 12px;
            height: 12px;
            position: relative;
            overflow: visible;
        }
        .your-company {
            color: #ffffff;
            text-align: left;
            font-family: var(--subtitle-2-font-family, "-", sans-serif);
            font-size: var(--subtitle-2-font-size, 12px);
            font-weight: var(--subtitle-2-font-weight, 400);
            position: relative;
            flex: 1;
        }
        .your-company-span {
            color: #ffffff;
            font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
            font-size: var(--subtitle-2-font-size, 12px);
            font-weight: var(--subtitle-2-font-weight, 400);
        }
    `;
    return (
        <StyleErrorDiv className="frame-1000011223">
            <img className="info-circle" src={InfoCircle} alt="info-circle0.svg" />
            <div className="your-company">
                <span>
                    <span className="your-company-span">{errorMessage}</span>
                </span>
            </div>
        </StyleErrorDiv>
    );
};
/* #endregion */
