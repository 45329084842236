import styled from "styled-components";
import Lottie from "react-lottie";
import animationData from "./../../../assets/animations/loader-animation.json";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .fullscreen-loader {
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background: rgb(16 20 27 / 80%);
        z-index: 99999;
    }

    .loader-container {
        border-radius: 4px;
        background: var(--backgrounds-lines-n-400);
        display: inline-flex;
        padding: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: center;
        gap: 10px;
        width: 84px;
        height: 84px;
    }

    div[aria-label="animation"] {
        align-self: center;
    }
`;
/* #endregion */

/**
 * FullScreenLoader component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.className - Additional CSS class name for the component.
 * @param {boolean} props.isLoading - The flag to enable or disable the loader.
 * @param {boolean} props.isLoadingOverlay - When enabled the loader will be displayed over the children elements.
 * @param {JSX.Element} props.children - The component children. Should not be used as a prop, but to wrap children components. See example.
 * @returns {JSX.Element} The FullScreenLoader component.
 * @example <FullScreenLoader isLoading={true} >
 *   <div className="children-container"> (...) </div>
 * </FullScreenLoader>
 */
export default function FullScreenLoader(props) {
    const isLoadingOverlay = props?.isLoadingOverlay ?? true;

    const isLoading = props?.isLoading ?? true;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <StyledPage className={`${props?.className || ""}`}>
            {isLoading && (
                <div className="fullscreen-loader pre-enrollment pre-loader">
                    <div className="loader-container">
                        <Lottie options={defaultOptions} height={120} width={120} />
                    </div>
                </div>
            )}
            {(!isLoading || (isLoading && isLoadingOverlay)) && props?.children}
        </StyledPage>
    );
}
