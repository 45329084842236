import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CloseIcon from "../../../assets/icons/x-close0.svg";
import Bell02 from "@untitled-ui/icons-react/build/esm/Bell02";
import * as Library from "../../../utils/Library";
import { Tooltip } from "antd";

//#region Styled Component
const StyledNotificationPanel = styled.div`
    &.items-panel {
        background: var(--backgrounds-and-lines-n-500, #141922);
        padding: 16.5px 16px 16.5px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100vh;
        position: sticky;
        width: 360px;
    }
    .frame-313273 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        overflow: hidden;
    }
    .frame-313271 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-end;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313329 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .all-notifications {
        color: var(--buttons-all-platforms-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--heading-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-3-font-size, 20px);
        font-weight: var(--heading-3-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .x-close {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
        cursor: pointer;
    }
    .frame-313276 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
    }
    .frame-313330 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .search {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-48 {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 4px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 37px;
        position: relative;
    }
    .search-list {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: "Roboto-Italic", sans-serif;
        font-size: 12px;
        font-weight: 400;
        font-style: italic;
        position: relative;
        height: 100%;
        width: 100%;
        background: transparent;
        border: none;
        outline: none;
    }
    .frame-313333 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313347 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-tabs {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
        cursor: pointer;
    }
    .frame-309 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .label {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Medium", sans-serif;
        font-size: 14px;
        letter-spacing: 0.015em;
        font-weight: 500;
        text-transform: uppercase;
        position: relative;
    }
    .frame-309.active {
        border-bottom: 3px solid #00aeef;
    }
    .frame-309.active .label {
        color: var(--text-and-icons-blue-500, #00aeef);
    }
    .frame-313311 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 81vh;
        overflow-y: auto;
    }
    .frame-1000011390 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .today-1100028 {
        color: var(--buttons-all-platforms-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--heading-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-3-font-size, 20px);
        font-weight: var(--heading-3-font-weight, 400);
        position: relative;
        width: 296px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 8px;
    }
    .frame-1000011389 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
    }
    .frame-92 {
        border-radius: 4px;
        padding: 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        &:hover {
            background-color: #1e2631;
        }
        &:hover .action-group-btn {
            display: flex;
        }
    }
    .frame-92:hover {
        background-color: #1e2631;
    }
    .frame-1000011384 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .ellipse-9 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 50%;
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: absolute;
        right: 0px;
        top: 0.5px;
    }
    .frame-1000011386 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .retailer-just-sent-a-reorder-check-it-out {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
    ._2-h {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: right;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
    }
    .frame-1000011389--s {
        color: #fff;
    }
    .action-group-btn {
        height: 24px;
        width: 44px;
        background-color: #141922;
        border-radius: 5px;
        padding: 6px;
        display: none; /* Initially hidden */
        flex-direction: row;
        gap: 8px;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0px;
    }
    .action-group-btn div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .action-group-btn div.delete:hover svg path {
        stroke: #ff3636; /* Change stroke color on hover */
    }
    .action-group-btn div.read:hover svg path {
        stroke: #00bb00; /* Change stroke color on hover */
    }
`;
//#endregion

export default function NotificationPanel({
    setShowNotificationPanel, // Function to toggle visibility of the notification panel
    groupedUnreadNotifications, // Object containing grouped unread notifications
    readNotifications, // Array of read notifications
    getReadNotifications, // Function to fetch read notifications
    getUnreadNotifications, // Function to fetch unread notifications
    hasNextPage // Boolean indicating if there are more notifications to load
}) {
    //#region State Management
    const [activeTab, setActiveTab] = useState("UNREAD"); // State to track the active tab
    const [page, setPage] = useState(1); // State to track the current page for pagination
    //#endregion

    //#region Handlers
    const handleTabClick = tab => {
        setActiveTab(tab);
        setPage(1); // Reset page when switching tabs
    };

    const loadMoreNotifications = () => {
        if (hasNextPage) {
            const nextPage = page + 1;
            setPage(nextPage);
            if (activeTab === "UNREAD") {
                getUnreadNotifications(nextPage, 20);
            } else {
                getReadNotifications(nextPage, 20);
            }
        }
    };

    const handleDeleteNotification = notificationID => {
        Library.makeCommonPostRequest(
            "Global",
            "deleteNotifications",
            {
                notificationIDs: [notificationID]
            },
            false,
            null
        ).then(res => {
            const { data, status } = res?.data;
            if (status === 200) {
                Library.showToastMessage({ message: "Notification deleted successfully!", type: "success" });
            } else {
                Library.showToastMessage({ message: "Error: Unable to delete notification.", type: "error" });
            }
            if (activeTab === "UNREAD") {
                getUnreadNotifications(1, 20);
            } else {
                getReadNotifications(1, 20);
            }
        });
    };

    const handleReadNotification = notificationID => {
        Library.makeCommonPostRequest(
            "Global",
            "updateNotificationsStatus",
            {
                notificationIDs: [notificationID],
                status: "Seen"
            },
            false,
            null
        ).then(res => {
            const { data, status } = res?.data;
            if (status === 200) {
                Library.showToastMessage({ message: "Notification marked as read successfully!", type: "success" });
            } else {
                Library.showToastMessage({ message: "Error: Unable to mark notification as read.", type: "error" });
            }
            if (activeTab === "UNREAD") {
                getUnreadNotifications(1, 20);
            } else {
                getReadNotifications(1, 20);
            }
        });
    };

    const handleScroll = e => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollHeight - scrollTop === clientHeight) {
            loadMoreNotifications();
        }
    };
    //#endregion

    //#region Effects
    useEffect(() => {
        const container = document.querySelector(".frame-1000011390");
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, [page, hasNextPage]);
    //#endregion

    //#region Calculations
    const unreadCount =
        (groupedUnreadNotifications?.Today?.length || 0) + (groupedUnreadNotifications?.Before?.length || 0);

    const sortedKeys = groupedUnreadNotifications["Today"]
        ? ["Today", ...Object.keys(groupedUnreadNotifications).filter(key => key !== "Today")]
        : Object.keys(groupedUnreadNotifications);
    //#endregion

    //#region Render
    return (
        <StyledNotificationPanel className="items-panel">
            {/* Header section */}
            <div className="frame-313273">
                <div className="frame-313271">
                    <div className="frame-313329">
                        <div className="all-notifications">All Notifications</div>
                        <img
                            className="x-close"
                            src={CloseIcon}
                            alt="CloseIcon"
                            onClick={() => setShowNotificationPanel(false)} // Close the notification panel
                        />
                    </div>
                </div>
                {/* Search and tab section */}
                <div className="frame-313276">
                    <div className="frame-313330">
                        <div className="search">
                            <div className="frame-48">
                                <input type="text" className="search-list" placeholder="Search notifications" />
                            </div>
                        </div>
                    </div>
                    <div className="frame-313333">
                        <div className="frame-313347">
                            {/* UNREAD tab */}
                            <div
                                className="btn-tabs"
                                onClick={() => {
                                    handleTabClick("UNREAD");
                                    getUnreadNotifications(1, 20);
                                }}>
                                <div className={`frame-309 ${activeTab === "UNREAD" ? "active" : ""}`}>
                                    <div className="label">UNREAD ({unreadCount ? unreadCount : 0})</div>
                                </div>
                            </div>
                            {/* READ tab */}
                            <div
                                className="btn-tabs"
                                onClick={() => {
                                    handleTabClick("READ");
                                    getReadNotifications(1, 20);
                                }}>
                                <div className={`frame-309 ${activeTab === "READ" ? "active" : ""}`}>
                                    <div className="label">READ</div>
                                </div>
                            </div>
                        </div>
                        {/* Notification list */}
                        {activeTab === "UNREAD" ? (
                            <div className="frame-313311">
                                {sortedKeys.map(label => (
                                    <div key={label} className="frame-1000011390">
                                        <div className="today-1100028">{label}</div>
                                        {groupedUnreadNotifications[label].map(notification => (
                                            <div key={notification.id} className="frame-1000011389">
                                                <div className="frame-92">
                                                    <div className="frame-1000011384">
                                                        <Bell02 className="text-gray-100" height="16px" />
                                                        <div className="ellipse-9"></div>
                                                    </div>
                                                    <div className="frame-1000011386">
                                                        <div className="retailer-just-sent-a-reorder-check-it-out">
                                                            {notification.title}
                                                        </div>
                                                        <div className="_2-h">{notification.description}</div>
                                                        <div className="action-group-btn">
                                                            {/* Delete button */}
                                                            <Tooltip
                                                                placement="top"
                                                                title={"DELETE"}
                                                                color="#212121"
                                                                overlayClassName="custom-tool-tip">
                                                                <div
                                                                    className="delete"
                                                                    onClick={() =>
                                                                        handleDeleteNotification(notification.id)
                                                                    }>
                                                                    <svg
                                                                        width="12"
                                                                        height="12"
                                                                        viewBox="0 0 12 12"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M4.5 1.5H7.5M1.5 3H10.5M9.5 3L9.14936 8.25964C9.09675 9.04877 9.07044 9.44333 8.90001 9.7425C8.74996 10.0059 8.52362 10.2176 8.25083 10.3498C7.94098 10.5 7.54555 10.5 6.75467 10.5H5.24533C4.45445 10.5 4.05902 10.5 3.74917 10.3498C3.47638 10.2176 3.25004 10.0059 3.09999 9.7425C2.92956 9.44333 2.90325 9.04877 2.85064 8.25965L2.5 3"
                                                                            stroke="#D1D1D1"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </Tooltip>
                                                            {/* Mark as read button */}
                                                            <Tooltip
                                                                placement="top"
                                                                title={"MARK AS READ"}
                                                                color="#212121"
                                                                overlayClassName="custom-tool-tip">
                                                                <div
                                                                    className="read"
                                                                    onClick={() =>
                                                                        handleReadNotification(notification.id)
                                                                    }>
                                                                    <svg
                                                                        width="12"
                                                                        height="12"
                                                                        viewBox="0 0 12 12"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <g clip-path="url(#clip0_12404_21163)">
                                                                            <path
                                                                                d="M3.75 6L5.25 7.5L8.25 4.5M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
                                                                                stroke="#D1D1D1"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                            />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_12404_21163">
                                                                                <rect
                                                                                    width="12"
                                                                                    height="12"
                                                                                    fill="white"
                                                                                />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="frame-313311">
                                <div className="frame-1000011390">
                                    {readNotifications.map(notification => {
                                        return (
                                            <div key={notification.id} className="frame-1000011389">
                                                <div className="frame-92">
                                                    <div className="frame-1000011384">
                                                        <Bell02 className="text-gray-100" height="16px" />
                                                    </div>
                                                    <div className="frame-1000011386">
                                                        <div className="retailer-just-sent-a-reorder-check-it-out">
                                                            {notification.title}
                                                        </div>
                                                        <div className="_2-h">{notification.description}</div>
                                                        <div className="action-group-btn">
                                                            {/* Delete button */}
                                                            <Tooltip
                                                                placement="top"
                                                                title={"DELETE"}
                                                                color="#212121"
                                                                overlayClassName="custom-tool-tip">
                                                                <div
                                                                    className="delete"
                                                                    onClick={() =>
                                                                        handleDeleteNotification(notification.id)
                                                                    }>
                                                                    <svg
                                                                        width="12"
                                                                        height="12"
                                                                        viewBox="0 0 12 12"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M4.5 1.5H7.5M1.5 3H10.5M9.5 3L9.14936 8.25964C9.09675 9.04877 9.07044 9.44333 8.90001 9.7425C8.74996 10.0059 8.52362 10.2176 8.25083 10.3498C7.94098 10.5 7.54555 10.5 6.75467 10.5H5.24533C4.45445 10.5 4.05902 10.5 3.74917 10.3498C3.47638 10.2176 3.25004 10.0059 3.09999 9.7425C2.92956 9.44333 2.90325 9.04877 2.85064 8.25965L2.5 3"
                                                                            stroke="#D1D1D1"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </StyledNotificationPanel>
    );
    //#endregion
}
