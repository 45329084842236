import React, { useEffect, useState } from "react";
import InfinityGridStoresCards from "../../common/cardContainers/InfinityGridStoresCards";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import * as Library from "../../../utils/Library";
import { AppQueryClient } from "../../../api/queryClient";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import { useNavigate, useOutletContext } from "react-router";
import LayoutContext from "../../../LayoutContext";
import { useSelector } from "react-redux";
import { defaultStatusColors, getSpanWithColor } from "../../../utils/CardUtils";
import MainWindowHeader from "../../common/mainApp/mainWindowHeaders/MainWindowHeader";

// #region  SCOPED CSS
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
// #endregion

export default function StoresPage() {
    document.title = `${APP_TITLE} - Stores`;

    // #region STATES
    const { MainLayoutProps, layoutType } = useOutletContext();
    const navigations = useSelector(state => state.navigationAccesses);
    const { showIsStoreSavedPopup, setShowIsStoreSavedPopup, storeCreatedPopup, setStoreCreatedPopup } =
        React.useContext(LayoutContext);

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    const [isLoading, setIsLoading] = useState(false);
    const [lastUpdatedDateTime, setLastUpdatedDateTime] = useState("--");
    const [removeID, setRemoveID] = useState(null);
    const [toogleSuspendData, setToogleSuspendData] = useState(null);
    const navigate = useNavigate();
    const labelBrandData = JSON.parse(localStorage.getItem("labelBrands"))?.[0];
    const currentStores = localStorage.getItem("activeStores");

    const [listNumRows, setListNumRows] = useState(20);

    //---------- Header Filters

    const [allLabelBrandsList, setAllLabelBrandsList] = useState([]);
    const [filteredLabelBrand, setFilteredLabelBrand] = useState([]);
    //-----
    const [requestEnabled, setRequestsEnabled] = useState(false);

    const filters = {
        filterLabelBrandIDs: filteredLabelBrand?.map(item => item?.value)
    };

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const infiniteGridQuerykey = ["StoresGrid", filters];
    const listQueryKey = ["StoresList", filters];
    // #endregion

    // #region EFFECTS

    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(
            props,
            navigations,
            //----
            onClickRefreshAction,
            allLabelBrandsList,
            onLabelBrandChange,
            onFiltersLoaded,
            filteredLabelBrand
        );
    }, [props?.layoutType, navigations, allLabelBrandsList, filteredLabelBrand, requestEnabled]);
    // #endregion

    // #region LIST COMPONENT PROPS
    const listColumns = [
        {
            name: "Store",
            selector: row => row?.name ?? "--"
        },
        {
            name: "Created by",
            selector: row => row?.createdBy ?? "--"
        },
        {
            name: "Created on",
            selector: row => formatDefaultDateTime(row?.createdOn) ?? "--"
        },
        {
            name: "Company",
            selector: row => row?.companyName ?? "--"
        },
        {
            name: "Retail brand",
            selector: row => row?.retailBrandName ?? "--"
        },
        {
            name: "POS",
            selector: row => row?.POSsystem ?? "--"
        },
        {
            name: "POS status",
            selector: row => getSpanWithColor(row?.POSstatus ?? "--", defaultStatusColors)
        },
        {
            name: "Deliveries",
            selector: row => deliveriesStatus(row) ?? "--"
        }
    ];
    // #endregion

    // #region METHODS
    const onLabelBrandChange = selection => {
        setFilteredLabelBrand([selection]);
    };

    const onFiltersLoaded = data => {
        if (data?.labelBrands?.length) {
            const labelBrands = data?.labelBrands?.map((item, index) => {
                return {
                    name: item?.name,
                    value: item?._id,
                    selected: index === 0
                };
            });

            setAllLabelBrandsList(labelBrands);
            setFilteredLabelBrand([labelBrands[0]]);
        }
        setRequestsEnabled(true);
    };
    //--------------------

    /*const handleDelete = async itemData => {
        const itemID = itemData?.id;

        if (!itemID) {
            Library.showErrorMessage("Invalid item ID!");
            return;
        }

        setRemoveID(itemID);
    }; */
    const deliveriesStatus = data => {
        const deliveries = [];
        if (data?.pickup) deliveries.push("PK");
        if (data?.courier) deliveries.push("CO");
        if (data?.shipping) deliveries.push("SH");

        const deliveriesText = deliveries.join(", ");
        return deliveriesText ? deliveriesText : "-";
    };
    const makeDeleteRequest = itemID => {
        setIsLoading(true);

        /*setTimeout(() => {
            setIsLoading(false);
            setRemoveID(null);
            Library.showSuccessMessage("Store deleted successfully!");
        }, 2000);*/

        Library.makePostRequest("deleteGroupStore", { storeID: itemID }, false, setIsLoading)
            .then(res => {
                if (res.data.status !== 200) return;
                setIsLoading(false);
                setRemoveID(null);
                Library.showSuccessMessage("Store deleted successfully!");
                props?.getUserDataFunction(localStorage.getItem("token"));
                onClickRefreshAction();
            })
            .catch(err => {
                setIsLoading(false);
                setRemoveID(null);
            });
    };

    /*const handleToogleSuspend = itemData => {
        const itemID = itemData?.id;

        if (!itemID) {
            Library.showErrorMessage("Invalid item ID!");
            return;
        }

        setToogleSuspendData(itemData);
    };*/

    const toogleSuspend = itemData => {
        setIsLoading(true);

        /*setTimeout(() => {
            setIsLoading(false);
            setToogleSuspendData(null);
            Library.showSuccessMessage("Store suspended successfully!");
        }, 2000);*/

        const groupCompanyID = localStorage.getItem("currentCompanyID");
        const itemID = itemData?.id;
        const status = itemData?.status === "Active" ? "Suspended" : "Active";

        Library.makePostRequest(
            "updateGroupStoreStatus",
            {
                groupCompanyID,
                storeID: itemID,
                status
            },
            false,
            setIsLoading
        )
            .then(res => {
                if (res.data.status !== 200) return;
                setIsLoading(false);
                setToogleSuspendData(null);
                Library.showSuccessMessage(
                    status === "Active" ? "Store activated successfully!" : "Store suspended successfully!"
                );
                const token = localStorage.getItem("token");
                props?.getUserDataFunction(token);
                onClickRefreshAction();
            })
            .catch(err => {
                setIsLoading(false);
                setToogleSuspendData(null);
            });
    };

    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };

    // #endregion

    return (
        <StyledPage>
            <FullScreenOverlay show={storeCreatedPopup?.showPopup}>
                <DialogBoxComponent
                    onClickYes={() => {
                        setStoreCreatedPopup({
                            ourId: null,
                            showPopup: false
                        });
                        window.open(
                            `${process.env.REACT_APP_BOSTAGE_R_URL}stores/edit/${storeCreatedPopup?.ourId}`,
                            "_blank"
                        );
                    }}
                    onClickNo={() => {
                        setStoreCreatedPopup({
                            ourId: null,
                            showPopup: false
                        });
                        if (currentStores === 1) setShowIsStoreSavedPopup(true);
                    }}
                    title="Store created succesfully!"
                    heading="Success!"
                    headingColor="#00BB00"
                    message={
                        "<div style='font-size: 14px; margin: 0 -5px;'>Your store has been created successfully! To turn this store as a fulfillment center, please setup your POS through your BOstageR account.<br> <br>Use same credentials as BOstageB account.</div>"
                    }
                    confirmText="GO TO BOSTAGER"
                    cancelText="NOT NOW"
                    confirmState="secondary"
                />
            </FullScreenOverlay>
            <FullScreenOverlay show={showIsStoreSavedPopup}>
                <DialogBoxComponent
                    buttonWidth={"50%"}
                    onClickYes={() => setShowIsStoreSavedPopup(false)}
                    title="You enrolled your first store!"
                    heading="CONGRATS..."
                    headingColor="#00AEEF"
                    message={`<div style= "font-size: 14px; margin: 0; line-height:14.06px">You can now continue enrolling more of your own stores or invite wholesale stores to also
                            become fulfillment centers for <span style='font-weight:600'>${labelBrandData?.name}</span>.<br> Use the menu or the left to pick your next
                            action.
                        </div>`}
                    // useImageHeader={{ src: BOStageBLogo, width: "165px" }}
                    showConfirm={() => setShowIsStoreSavedPopup(false)}
                    showCancel={false}
                    confirmText="CONTINUE"
                    confirmState="secondary"
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={toogleSuspendData !== null}>
                <DialogBoxComponent
                    title={toogleSuspendData?.status === "Active" ? "Suspend Store" : "Activate Store"}
                    message={
                        toogleSuspendData?.status === "Active"
                            ? `Are you sure you want to suspend ${toogleSuspendData?.name} store?`
                            : `Are you sure you want to activate ${toogleSuspendData?.name} store?`
                    }
                    //message={`${toogleSuspendData?.storeName} status will become SUSPENDED until you reactivate the account. ${toogleSuspendData?.storeName} will be logged out of their account. This may take up to 30 minutes to take effect. No email will be sent.`}
                    onClickYes={() => {
                        toogleSuspend(toogleSuspendData);
                    }}
                    loading={isLoading}
                    loadingHeading={
                        toogleSuspendData?.status === "Active" ? "Suspending Store..." : "Activating Store..."
                    }
                    onClickNo={() => {
                        setToogleSuspendData(null);
                    }}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={removeID !== null}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-red-500)"
                    title="Removing Store"
                    message="Are you sure you want to remove this store from your account?"
                    onClickYes={() => {
                        makeDeleteRequest(removeID);
                    }}
                    loading={isLoading}
                    loadingHeading="Removing Store..."
                    onClickNo={() => {
                        setRemoveID(null);
                    }}
                />
            </FullScreenOverlay>

            {viewLayoutStyle === "Grid" && (
                <InfinityGridStoresCards
                    minColumnWidth="370px"
                    limit="8"
                    //onDeleteAction={handleDelete}
                    //onSuspendAction={handleToogleSuspend}
                    queryKey={infiniteGridQuerykey}
                    statusFromPath="status"
                    onEditAction={e => {
                        navigate(`/stores/edit/${e?.ourID}`);
                    }}
                    requestEnabled={requestEnabled}
                    filters={filters}
                />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="Stores"
                    columns={listColumns}
                    api={{
                        endpoint: "getStoresData",
                        useCommonPostRequest: true,
                        commonContext: "Stores",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            platform: "BOstageB",
                            limit: listNumRows,
                            ...filters
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={listNumRows}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        onClickEdit: e => {
                            navigate(`/stores/edit/${e?.ourID}`);
                        },
                        includeActions: true,
                        extraActions: [
                            {
                                title: "Details"
                            },
                            {
                                title: "Add staff"
                            }
                        ],
                        editIsVisible: true
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: defaultStatusColors
                    }}
                    setLastUpdatedDateTime={setLastUpdatedDateTime}
                    rightCardTopComponent={<RightComponent lastUpdatedDateTime={lastUpdatedDateTime} />}
                    requestEnabled={requestEnabled}
                    onChangeRowsPerPage={rowsPerPage => setListNumRows(rowsPerPage)}
                />
            )}
        </StyledPage>
    );
}
// #region Small components
const RightComponent = ({ lastUpdatedDateTime }) => {
    return (
        <div
            style={{
                display: "Flex",
                gap: "16px",
                fontSize: "12px",
                textTransform: "none",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <div>Total FFcenters to Replay: --</div>
            <div>Last update: {lastUpdatedDateTime}</div>
        </div>
    );
};
/* endregion */
function setupHeaders(
    props,
    navigations,
    //----
    onClickRefreshAction,
    allLabelBrandsList,
    onLabelBrandChange,
    onFiltersLoaded,
    filteredLabelBrand
) {
    const createMode = navigations?.navigationAccesses?.stores?.createModeOnly;
    //const hasStockAccess = hasUserCompletedOnboarding(navigations);

    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Stores", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/stores", active: true, disabled: false, title: "Stores" },
        { to: "/stores/staff", active: false, disabled: true, title: "Staff" }
        //{ to: "/stores/pos", active: false, disabled: false, title: "POS" },
        /*{ to: "/stock", active: false, disabled: !hasStockAccess, title: "Stock" }*/
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: createMode, title: "View", icon: "eye" },
        { to: "/stores/create", active: false, disabled: false, title: "Add", icon: "plus" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" },
        { to: "#", active: false, disabled: true, title: "Edit", icon: "edit" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <MainWindowHeader
                key={window.location.pathname}
                visible={true}
                layoutTypeOptions={{
                    gridEnabled: true,
                    listEnabled: true
                }}
                onRefreshClick={onClickRefreshAction}
                firstOrganize={{
                    visible: true,
                    dropdownData: allLabelBrandsList,
                    onDropdownChange: onLabelBrandChange,
                    disabled: false,
                    type: "singleSelect",
                    selectedValue: filteredLabelBrand?.[0]?.name
                }}
                secondOrganize={{
                    visible: true,
                    // dropdownData: allRetailersList,
                    // onDropdownChange: onRetailersChange,
                    disabled: true
                }}
                pageFilters={{
                    useFilters: true,
                    filters: {
                        useLabelBrands: true
                        // useRetailBrands: true
                    },
                    onFiltersLoaded: onFiltersLoaded
                }}
            />
        )
    });
}
// #endregion
